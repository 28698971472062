import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
