import { Component, OnInit } from '@angular/core';
import { AppSettings } from 'src/app-settings';
import { LanguageService } from '../services/language.service';
import { ForgotPasswordTarget } from './login.service';

@Component({
  selector: 'app-login-layout-sofa',
  templateUrl: './login-layout-sofa.component.html',
  styleUrls: ['./login-layout-sofa.component.scss'],
})
export class LoginLayoutSofaComponent implements OnInit {
  public ForgotPasswordTarget = ForgotPasswordTarget;
  public readonly trans = (key: string) => this.ls.get(key);
  public readonly title = AppSettings.title;
  constructor(private ls: LanguageService) {}

  ngOnInit(): void {}
}
