import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GoogleAuthService } from 'src/app/api/google-auth.service';
import { RequestService } from 'src/app/api/request.service';
import { LanguageService } from 'src/app/services/language.service';
import { emailValidator } from '../../../components/login-email/login-email.component';
import { LoginService } from '../../../login.service';
@Component({
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss'],
})
export class EmailComponent implements OnInit {
  public val = emailValidator.build();
  public readonly trans = (key: string) => this.ls.get(key);
  constructor(public login: LoginService, private req: RequestService, private router: Router, private ls: LanguageService, public googleAuth: GoogleAuthService) {}

  ngOnInit(): void {}

  public async next() {
    if (!(await this.val.validate(this.login))) return;
    const res = await this.login.checkEmail(this.login.email).toPromise();
    if (res.result === 'local') {
      if (res.noPassword) {
        this.val.pushError('email', 'NO_PASSWORD');
      }
      this.val.pushError('email', 'LOCAL_USER');
      return;
    } else if (res.result === 'disabled') {
      this.val.pushError('email', 'DISABLED_USER');
      return;
    } else if (res.result === 'social') {
      switch (res.type) {
        case 'google':
          this.val.pushError('email', 'SOCIAL_GOOGLE');
          break;
        default:
          this.val.pushError('email', 'SOCIAL');
          break;
      }
      return;
    } else if (res.result === 'no_user') {
      // Fallthrough
    } else {
      this.val.pushError('email', 'Unknown error');
      throw new Error('Unknown result: ' + (res as any).result);
    }
    const resReg = await this.req.login.register(this.login.email).toPromise();
    if (resReg.success === false) {
      this.val.pushError('email', resReg.error);
      return;
    }
    this.login.activationToken = resReg.activation_token;
    this.router.navigate(['login', 'register', 'code'], { replaceUrl: true });
  }
}
