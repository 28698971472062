<div id="ndf_icon" class="{{deviceCount === 1 ? 'ndf_icon_single' : 'ndf_icon_multi'}}">
    <div [inlineSVG]="'assets/images/success.svg'"></div>
</div>
<div id="ndf_icon_text" *ngIf="deviceCount === 1">{{trans('systems.labels.foundSingleDevice') + ' ' + ds.getDeviceNameById()}}</div>
<div id="ndf_icon_text" *ngIf="deviceCount > 1">{{trans('systems.labels.foundMultipleDevices') + ' ' + ds.getDeviceNameById()}}</div>
<div id="ndf_single_device" *ngIf="deviceCount === 1">{{singleDeviceWifiAP}}</div>
<div id="ndf_multi" *ngIf="deviceCount > 1">
    <div class="bold_header"></div>
    <div id="ndf_list">
        <app-button-single-line-arrow [icon]="'assets/images/wifi.svg'" *ngFor="let iDevice of wfs.getDeviceNetworks()" (click)="deviceSelected(iDevice.ssid)">{{iDevice.ssid}}</app-button-single-line-arrow>
    </div>
</div>
<app-disable-mobile-data-popup #mobileData></app-disable-mobile-data-popup>