import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-reread',
  templateUrl: './reread.component.html',
  styleUrls: ['./reread.component.scss'],
})
export class RereadComponent extends LanguageAware implements OnInit, OnDestroy {
  constructor(cdRef: ChangeDetectorRef, private router: Router, private ds: DeviceService) {
    super(cdRef);
    this.tag = 'SystemReread';
    this.headerBar.showHeader({
      backUrl: '/system-information',
      headerText: this.trans('systems.titles.syncSystem'),
    });
    const that = this;
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.footerBar.onButton1Click = () => {
      that.router.navigate(['/system-information']);
    };
    this.background.setGray();
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.miniStatus.hide();
    this.ds.stopReRead();
  }

  public reReadClick() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }

    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    this.ds.performReRead(
      this.systems.activeSystem.id,
      (m) => {
        that.toaster.postSuccess(m);
        that.miniStatus.hide();
      },
      (e) => {
        if (e !== '') {
          that.toaster.postError(e);
        }
        that.miniStatus.hide();
      },
      (s, p) => {
        that.miniStatus.show(s);
      }
    );
  }
}
