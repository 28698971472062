<div class="page">
  <div class="loginPage">
    <div class="loginSection">
      <div class="protegusLogo">
        <img src="../../../../assets/images/company/protegus.svg" />
      </div>
      <span>Sign into your account</span>
      <app-string-input
        [(value)]="email"
        [ngClass]="['loginInput', 'email']"
        [disabled]="state.state.hasTag('disableEmail')"
        label="Email"
        type="email"
        (focus)="state.send({ type: 'EMAIL_CHANGED' })"
      >
      </app-string-input>
      <app-string-input
        [(value)]="password"
        [ngClass]="['loginInput', 'email']"
        label="Password"
        type="password"
        [disabled]="state.state.hasTag('disablePassword')"
        (Focus)="state.send({ type: 'FOCUS' })"
        (Keydown.enter)="state.send({ type: 'LOGIN' })"
      ></app-string-input>
      <div class="loginFormBottom">
        <a routerLink="/" class="forgotPassword">Forgot password?</a>
        <div *ngIf="state.state.hasTag('loading')">Loading...</div>
        <div *ngIf="state.state.hasTag('hasError')" class="tmperror">
          {{ state.state.context.message }}
        </div>
        <app-button class="loginButton" (click)="state.send({ type: 'LOGIN' })"> Sign in </app-button>
      </div>
    </div>
    <div class="getAccountSection">
      <span>Don't have an account?</span>
      <a class="contactSales" routerLink="/">Contact our Sales team</a>
    </div>
  </div>
</div>
