<div class="white_round padding16">
  <app-text-edit [value]="reactionItem.name" (valueChanged)="setName($event)">{{ trans('settings.reactions.labels.nameReaction') }}</app-text-edit>
  <div id="cpegr_logo_container">
    <input id="cperl_input" type="file" name="icon" accept="image/svg" (change)="handleNewFile($event.target.files)" />
    <label for="cperl_input">
      <div id="cperl_upload">
        <div id="cperl_preview"><img [src]="fileToUploadUrl" *ngIf="iconChanged" (load)="imageLoaded($event.target)" /></div>
        <div id="cperl_icon" *ngIf="!iconChanged" [inlineSVG]="reactionItem.image"></div>
        <div id="cperl_text">{{ trans('settings.reactions.labels.uploadNewIcon') }}</div>
        <div id="cperl_arrow">
          <svg viewBox="0 0 8.402 14.697">
            <path
              d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z"
              transform="translate(19.653 20.891) rotate(180)"
            />
          </svg>
        </div>
      </div>
    </label>
  </div>
  <app-name-value [nameText]="trans('general.enabled')">
    <app-toggle-button [initialState]="reactionItem.active" (toggled)="reactionItem.active = $event.checked" [buttonId]="'cper_active'"></app-toggle-button>
  </app-name-value>
  <app-name-value [nameText]="trans('settings.reactions.labels.notificationSoundIsAlert')">
    <app-toggle-button [initialState]="reactionItem.custom_sound" (toggled)="reactionItem.custom_sound = $event.checked" [buttonId]="'cper_sound'"></app-toggle-button>
  </app-name-value>
  <app-name-value [nameText]="trans('settings.reactions.labels.group')" (click)="changeGroup()">{{ groupText }}</app-name-value>
</div>
<div class="bold_header" id="cper_header">
  <div id="cper_text">{{ trans('settings.reactions.labels.assignedEvents') }}</div>
  <div id="cper_plus" (click)="addEvent()">
    <span
      ><svg xmlns="http://www.w3.org/2000/svg" width="15.851" height="15.851" viewBox="0 0 15.851 15.851">
        <g transform="translate(-6.5 -6.5)">
          <path d="M18,7.5V21.351" transform="translate(-3.575)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
          <path d="M7.5,18H21.351" transform="translate(0 -3.575)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
        </g></svg
    ></span>
  </div>
</div>
<div class="white_round">
  <div *ngFor="let iDescription of us.eventDescriptions" class="cperd_item">
    <div class="cperd_check">
      <app-checkbox
        [isSecondaryColor]="true"
        [checkValue]="iDescription.reaction_id === reactionItem.id"
        [isRound]="false"
        (valueChanged)="eventChecked(iDescription)"
      ></app-checkbox>
    </div>
    <div class="cperd_name" (click)="openEvent(iDescription)">{{ iDescription.default_name }}</div>
    <div class="cperd_status" (click)="openEvent(iDescription)">{{ iDescription.active === 1 ? trans('general.enabled') : trans('general.disabled') }}</div>
    <div class="cperd_cid" (click)="openEvent(iDescription)">
      <span>{{ iDescription.qualifier }}-{{ iDescription.event_code }}</span>
    </div>
    <div class="cperd_arrow" (click)="openEvent(iDescription)">
      <svg viewBox="0 0 8.402 14.697">
        <path
          d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z"
          transform="translate(19.653 20.891) rotate(180)"
        />
      </svg>
    </div>
  </div>
</div>
<app-popup></app-popup>
