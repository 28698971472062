<app-login-layout>
  <div class="mainContent">
    <div>
      <h2>{{ trans('auth.labels.verifyEmail') }}</h2>
      <p>{{ trans('auth.labels.verifyEmailPrompt') }}</p>
    </div>
    <form (submit)="next()">
      <input
        type="number"
        name="code"
        [value]="code"
        (input)="code = $event.target.value"
        autocomplete="off"
        [attr.aria-label]="trans('auth.fields.emailVerificationCode')"
        maxlength="3"
        [ngClass]="'len' + code.length"
      />`
      <app-form-error *ngIf="val.hasError('code')">{{ val.getError('code') }}</app-form-error>
      <p>{{ trans('auth.labels.checkSpam') }}</p>
      <app-button class="mainButton">{{ trans('auth.loginNext') }}</app-button>
    </form>
  </div>
</app-login-layout>
