import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, ErrorHandler } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './general/login/login.component';
import { TokenResolver } from './services/token.resolve';
import { LoadingScreenComponent } from './general/loading-screen/loading-screen.component';
import { HttpClientModule } from '@angular/common/http';
import { ProgressComponent } from './general/progress/progress.component';
import { HeaderComponent } from './general/header/header.component';
import { FooterComponent } from './general/footer/footer.component';
import { LocatorService } from './services/locator.service';
import { InlineSVGModule } from 'ng-inline-svg';
import { EmailComponent } from './general/email/email.component';
import { AutofocusDirective } from './directives/autofocus.directive';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PasswordLoginComponent } from './general/password-login/password-login.component';
import { HomeComponent } from './general/home/home.component';
import { AddSystemUidComponent } from './general/add-system-uid/add-system-uid.component';
import { ActivateEmailComponent } from './general/activate-email/activate-email.component';
import { NoPasswordComponent } from './general/no-password/no-password.component';
import { PasswordNewComponent } from './general/password-new/password-new.component';
import { NameRegisterComponent } from './general/name-register/name-register.component';
import { SidemenuComponent } from './general/sidemenu/sidemenu.component';
import { AddSystemDetailsComponent } from './general/add-system-details/add-system-details.component';
import { HomeBackgroundSelectionComponent } from './general/home-background-selection/home-background-selection.component';
import { SystemLocationComponent } from './general/system-location/system-location.component';
import { WiringComponent } from './general/wiring/wiring.component';
import { AddSystemIndirectComponent } from './general/add-system-indirect/add-system-indirect.component';
import { AddSystemAreaComponent } from './general/add-system-area/add-system-area.component';
import { AddSystemAreaListComponent } from './general/add-system-area-list/add-system-area-list.component';
import { AddSystemZoneListComponent } from './general/add-system-zone-list/add-system-zone-list.component';
import { AddSystemCompleteComponent } from './general/add-system-complete/add-system-complete.component';
import { ContextMenuComponent } from './general/context-menu/context-menu.component';
import { SystemConfigurationComponent } from './system/system-configuration/system-configuration.component';
import { SystemInformationComponent } from './system/system-information/system-information.component';
import { TextEditComponent } from './components/text-edit/text-edit.component';
import { ButtonDoubleLineArrowComponent } from './components/button-double-line-arrow/button-double-line-arrow.component';
import { SystemNotificationsComponent } from './system/system-notifications/system-notifications.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { AreaListComponent } from './system/area-list/area-list.component';
import { AreaComponent } from './system/area/area.component';
import { AreaPgmsComponent } from './general/area-pgms/area-pgms.component';
import { ZoneListComponent } from './system/zone-list/zone-list.component';
import { SystemZoneComponent } from './general/system-zone/system-zone.component';
import { SystemPgmListComponent } from './system/system-pgm-list/system-pgm-list.component';
import { SystemPgmComponent } from './system/system-pgm/system-pgm.component';
import { TransferComponent } from './system/transfer/transfer.component';
import { DeleteComponent } from './system/delete/delete.component';
import { AreaViewComponent } from './system/area-view/area-view.component';
import { EventsComponent } from './system/events/events.component';
import { ProfileComponent } from './general/profile/profile.component';
import { PasswordChangeComponent } from './general/password-change/password-change.component';
import { AccountDeleteComponent } from './general/account-delete/account-delete.component';
import { UserListComponent } from './system/user-list/user-list.component';
import { UserComponent } from './system/user/user.component';
import { CustomizeAreasComponent } from './general/customize-areas/customize-areas.component';
import { CustomizeZonesComponent } from './general/customize-zones/customize-zones.component';
import { TimeZoneSelectComponent } from './components/time-zone-select/time-zone-select.component';
import { DropdownSelectComponent } from './components/dropdown-select/dropdown-select.component';
import { ZoneStatusListComponent } from './system/zone-status-list/zone-status-list.component';
import { LogViewComponent } from './general/log-view/log-view.component';
import { PgmButtonComponent } from './components/pgm-button/pgm-button.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { PgmIconSelectComponent } from './system/pgm-icon-select/pgm-icon-select.component';
import { HomeEventsComponent } from './general/home/home-events/home-events.component';
import { HomeAreasComponent } from './general/home/home-areas/home-areas.component';
import { HomeOutputsComponent } from './general/home/home-outputs/home-outputs.component';
import { HomeConfigurationComponent } from './general/home-configuration/home-configuration.component';
import { HomeSensorsComponent } from './general/home/home-sensors/home-sensors.component';
import { SensorsListComponent } from './system/sensors-list/sensors-list.component';
import { SystemSensorComponent } from './system/system-sensor/system-sensor.component';
import { EventControlComponent } from './system/event-control/event-control.component';
import { LoaderDotsComponent } from './components/loader-dots/loader-dots.component';
import { ShowZonesComponent } from './system/show-zones/show-zones.component';
import { SignalLevelComponent } from './components/signal-level/signal-level.component';
import { RefreshComponent } from './components/refresh/refresh.component';
import { TransferFromListComponent } from './system/transfer-from-list/transfer-from-list.component';
import { SignalLevelV2Component } from './components/signal-level-v2/signal-level-v2.component';
import { LoaderDotsV2Component } from './components/loader-dots-v2/loader-dots-v2.component';
import { AreaAlarmPopupComponent } from './system/area-alarm-popup/area-alarm-popup.component';
import { LanguageListComponent } from './general/language-list/language-list.component';
import { HelpComponent } from './general/help/help.component';
import { SystemNotificationsItemComponent } from './system/system-notifications-item/system-notifications-item.component';
import { CameraListComponent } from './system/camera-list/camera-list.component';
import { PlusButtonComponent } from './components/plus-button/plus-button.component';
import { CameraComponent } from './system/camera-list/camera/camera.component';
import { HomeCamerasComponent } from './general/home/home-cameras/home-cameras.component';
import { SystemListItemComponent } from './components/system-list-item/system-list-item.component';
import { RereadComponent } from './system/reread/reread.component';
import { SystemThermostatComponent } from './system/system-thermostat/system-thermostat.component';
import { HomeThermostatsComponent } from './general/home/home-thermostats/home-thermostats.component';
import { ArcSliderComponent } from './components/arc-slider/arc-slider.component';
import { HomeThermostatItemComponent } from './general/home/home-thermostat-item/home-thermostat-item.component';
import { AdvancedSettingsComponent } from './general/advanced-settings/advanced-settings.component';
import { LoaderRoundComponent } from './components/loader-round/loader-round.component';
import { TicketComponent } from './general/help/ticket/ticket.component';
import { CpHomeComponent } from './mobile-company/cp-home/cp-home.component';
import { CpSystemListItemComponent } from './mobile-company/cp-system-list-item/cp-system-list-item.component';
import { CpAllEventsComponent } from './mobile-company/cp-all-events/cp-all-events.component';
import { SystemFindPopupComponent } from './popups/system-find-popup/system-find-popup.component';
import { BasePopupComponent } from './popups/base-popup/base-popup.component';
import { CpFilteredSystemsComponent } from './mobile-company/cp-filtered-systems/cp-filtered-systems.component';
import { DashGroupItemComponent } from './mobile-company/dash-group-item/dash-group-item.component';
import { CpEditEventsComponent } from './mobile-company/cp-edit-events/cp-edit-events.component';
import { CpEditEventComponent } from './mobile-company/cp-edit-event/cp-edit-event.component';
import { NameValueComponent } from './components/name-value/name-value.component';
import { PopupComponent } from './popups/popup/popup.component';
import { CpAllUsersComponent } from './mobile-company/cp-all-users/cp-all-users.component';
import { CpEditUserComponent } from './mobile-company/cp-edit-user/cp-edit-user.component';
import { CpEditReactionsComponent } from './mobile-company/cp-edit-reactions/cp-edit-reactions.component';
import { CpEditReactionComponent } from './mobile-company/cp-edit-reaction/cp-edit-reaction.component';
import { EventFilterPopupComponent } from './popups/event-filter-popup/event-filter-popup.component';
import { CpEditGroupsComponent } from './mobile-company/cp-edit-groups/cp-edit-groups.component';
import { CpEditGroupComponent } from './mobile-company/cp-edit-group/cp-edit-group.component';
import { CpInstallerAcceptComponent } from './mobile-company/cp-installer-accept/cp-installer-accept.component';
import { SuAllUsersComponent } from './mobile-company/su-all-users/su-all-users.component';
import { CpFilteredUsersComponent } from './mobile-company/cp-filtered-users/cp-filtered-users.component';
import { UserFindPopupComponent } from './popups/user-find-popup/user-find-popup.component';
import { CpPanicButtonComponent } from './mobile-company/cp-panic-button/cp-panic-button.component';
import { SuIpcomsComponent } from './mobile-company/su-ipcoms/su-ipcoms.component';
import { SuIpcomComponent } from './mobile-company/su-ipcom/su-ipcom.component';
import { SuGlobalSettingsComponent } from './mobile-company/su-global-settings/su-global-settings.component';
import { StatusMessageComponent } from './popups/status-message/status-message.component';
import { RequestAssistanceComponent } from './system/request-assistance/request-assistance.component';
import { ChangeInstallerComponent } from './system/change-installer/change-installer.component';
import { AssistingInstallerComponent } from './system/assisting-installer/assisting-installer.component';
import { HistorySystemsComponent } from './installer/history-systems/history-systems.component';
import { MessageboxComponent } from './popups/messagebox/messagebox.component';
import { NewSystemOfflineComponent } from './system/new-system-offline/new-system-offline.component';
import { ScanNearbyDevicesComponent } from './general/scan-nearby-devices/scan-nearby-devices.component';
import { NearbyDeviceFoundComponent } from './general/nearby-device-found/nearby-device-found.component';
import { DisableMobileDataPopupComponent } from './popups/disable-mobile-data-popup/disable-mobile-data-popup.component';
import { DeviceApConnectComponent } from './general/device-ap-connect/device-ap-connect.component';
import { SuRegionsComponent } from './mobile-company/su-regions/su-regions.component';
import { SuRegionComponent } from './mobile-company/su-region/su-region.component';
import { TakeActionButtonComponent } from './components/take-action-button/take-action-button.component';
import { ResetFireSensorsComponent } from './system/reset-fire-sensors/reset-fire-sensors.component';
import { LongPressMenuComponent } from './general/long-press-menu/long-press-menu.component';
import { ElementResizePopupComponent } from './popups/element-resize-popup/element-resize-popup.component';
import { QuickCamerasComponent } from './general/quick-cameras/quick-cameras.component';
import { QuickOutputsComponent } from './general/quick-outputs/quick-outputs.component';
import { OwnerPrivacyComponent } from './system/owner-privacy/owner-privacy.component';
import { ReplaceDeviceAdminComponent } from './system/replace-device-admin/replace-device-admin.component';
import { SectionWithFilterComponent } from './components/section-with-filter/section-with-filter.component';
import { PopupMenuComponent } from './components/popup-menu/popup-menu.component';
import { FragmenterComponent } from './components/fragmenter/fragmenter.component';
import { ConfirmDeviceTransferComponent } from './general/confirm-device-transfer/confirm-device-transfer.component';
import { SuTransferDeviceComponent } from './mobile-company/su-transfer-device/su-transfer-device.component';
import { TransferTypeComponent } from './system/transfer/transfer-type/transfer-type.component';
import { TransferDeviceComponent } from './system/transfer/transfer-device/transfer-device.component';
import { SettingsComponent } from './sadmin/settings/settings.component';
import { GroupboxComponent } from './components/groupbox/groupbox.component';
import { MobileAppComponent } from './mobile-app.component';
import { AppCommonModule } from './app-common/app-common.module';
import { TeleportModule } from './teleport/teleport.module';
import { RtcService } from './api/rtc/rtc.service';
import { rtcFactory } from './api/rtc/rtc-factory';
import { PlatformService } from './api/platform.service';
import { ConsentComponent } from './general/consent/consent.component';
import { googleAuthFactory, GoogleAuthService } from './api/google-auth.service';
import { LottieModule, LottieCacheModule } from 'ngx-lottie';
import { WifiDetailsComponent } from './general/wifi-details/wifi-details.component';
import { GlobalErrorCatcher } from './global-error-catcher';
import { sendError } from './services/error-reporter';
import { LoginModule } from './login/login.module';

// const googleConfig = new AuthServiceConfig([
//   {
//     id: GoogleLoginProvider.PROVIDER_ID,
//     provider: new GoogleLoginProvider(
//       '131091336687-8kg1fhrbstoakft532hajc6kmke10ft3.apps.googleusercontent.com'
//     ),
//   },
// ]);

export const playerFactory = () => {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoadingScreenComponent,
    ProgressComponent,
    HeaderComponent,
    FooterComponent,
    EmailComponent,
    AutofocusDirective,
    PasswordLoginComponent,
    HomeComponent,
    AddSystemUidComponent,
    ActivateEmailComponent,
    NoPasswordComponent,
    PasswordNewComponent,
    NameRegisterComponent,
    SidemenuComponent,
    AddSystemDetailsComponent,
    HomeBackgroundSelectionComponent,
    SystemLocationComponent,
    WiringComponent,
    AddSystemIndirectComponent,
    AreaPgmsComponent,
    AddSystemAreaComponent,
    AddSystemAreaListComponent,
    SystemZoneComponent,
    AddSystemZoneListComponent,
    AddSystemCompleteComponent,
    ContextMenuComponent,
    SystemConfigurationComponent,
    SystemInformationComponent,
    TextEditComponent,
    ButtonDoubleLineArrowComponent,
    SystemNotificationsComponent,
    ToggleButtonComponent,
    RadioButtonComponent,
    AreaListComponent,
    AreaComponent,
    ZoneListComponent,
    SystemPgmListComponent,
    SystemPgmComponent,
    TransferComponent,
    DeleteComponent,
    AreaViewComponent,
    EventsComponent,
    ProfileComponent,
    PasswordChangeComponent,
    AccountDeleteComponent,
    UserListComponent,
    UserComponent,
    CustomizeAreasComponent,
    CustomizeZonesComponent,
    TimeZoneSelectComponent,
    DropdownSelectComponent,
    ZoneStatusListComponent,
    LogViewComponent,
    PgmButtonComponent,
    PgmIconSelectComponent,
    HomeEventsComponent,
    HomeAreasComponent,
    HomeOutputsComponent,
    HomeConfigurationComponent,
    HomeSensorsComponent,
    SensorsListComponent,
    SystemSensorComponent,
    EventControlComponent,
    LoaderDotsComponent,
    ShowZonesComponent,
    SignalLevelComponent,
    RefreshComponent,
    TransferFromListComponent,
    SignalLevelV2Component,
    LoaderDotsV2Component,
    AreaAlarmPopupComponent,
    LanguageListComponent,
    HelpComponent,
    SystemNotificationsItemComponent,
    CameraListComponent,
    PlusButtonComponent,
    CameraComponent,
    HomeCamerasComponent,
    SystemListItemComponent,
    RereadComponent,
    SystemThermostatComponent,
    HomeThermostatsComponent,
    ArcSliderComponent,
    HomeThermostatItemComponent,
    AdvancedSettingsComponent,
    LoaderRoundComponent,
    TicketComponent,
    CpHomeComponent,
    DashGroupItemComponent,
    CpSystemListItemComponent,
    CpAllEventsComponent,
    SystemFindPopupComponent,
    BasePopupComponent,
    CpFilteredSystemsComponent,
    CpEditEventsComponent,
    CpEditEventComponent,
    NameValueComponent,
    PopupComponent,
    CpAllUsersComponent,
    CpEditUserComponent,
    CpEditReactionsComponent,
    CpEditReactionComponent,
    EventFilterPopupComponent,
    CpEditGroupsComponent,
    CpEditGroupComponent,
    CpInstallerAcceptComponent,
    SuAllUsersComponent,
    CpFilteredUsersComponent,
    UserFindPopupComponent,
    CpPanicButtonComponent,
    SuIpcomsComponent,
    SuIpcomComponent,
    SuGlobalSettingsComponent,
    StatusMessageComponent,
    RequestAssistanceComponent,
    ChangeInstallerComponent,
    AssistingInstallerComponent,
    HistorySystemsComponent,
    MessageboxComponent,
    NewSystemOfflineComponent,
    ScanNearbyDevicesComponent,
    NearbyDeviceFoundComponent,
    DisableMobileDataPopupComponent,
    DeviceApConnectComponent,
    SuRegionsComponent,
    SuRegionComponent,
    TakeActionButtonComponent,
    ResetFireSensorsComponent,
    LongPressMenuComponent,
    ElementResizePopupComponent,
    QuickCamerasComponent,
    QuickOutputsComponent,
    OwnerPrivacyComponent,
    ReplaceDeviceAdminComponent,
    SectionWithFilterComponent,
    PopupMenuComponent,
    FragmenterComponent,
    ConfirmDeviceTransferComponent,
    SuTransferDeviceComponent,
    TransferTypeComponent,
    TransferDeviceComponent,
    SettingsComponent,
    GroupboxComponent,
    MobileAppComponent,
    ConsentComponent,
    WifiDetailsComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    InlineSVGModule.forRoot(),
    FormsModule,
    BrowserAnimationsModule,
    NgCircleProgressModule.forRoot({
      responsive: true,
      animation: true,
      animationDuration: 350,
      outerStrokeColor: '#EF476F',
      backgroundColor: 'white',
      showInnerStroke: false,
      outerStrokeWidth: 20,
      titleFontSize: '60px',
      showSubtitle: false,
      showUnits: false,
    }),
    [LottieModule.forRoot({ player: playerFactory }), LottieCacheModule.forRoot()],
    TeleportModule,
    AppCommonModule,
    LoginModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    { provide: RtcService, useFactory: rtcFactory, deps: [PlatformService, Injector] },
    { provide: GoogleAuthService, useFactory: googleAuthFactory, deps: [PlatformService, Injector] },
    { provide: ErrorHandler, useClass: GlobalErrorCatcher },
  ],
})
export class AppModule {
  constructor(private injector: Injector) {
    LocatorService.injector = this.injector;
    window.addEventListener('error', (ev: ErrorEvent) => {
      sendError(ev.error);
    });
  }
}
export const tokenFactory = (provider: TokenResolver) => () => provider.resolve();
