import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TUser } from 'src/app/models/user';
import { WebSocketService } from 'src/app/services/websocket.service';
import { DateService } from 'src/app/services/date.service';
import { PopupService } from 'src/app/services/popup.service';
import { SlideoutItem } from 'src/app/models/slideout-item';
import { PopupType } from 'src/app/models/popup-type';
import { DomSanitizer } from '@angular/platform-browser';
import { GoogleAuthService } from 'src/app/api/google-auth.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ProfileComponent extends LanguageAware implements OnInit {
  public userDataChanged = false;
  public logoChanged = false;
  public userToUse: TUser = null;
  public dateTimeFormats: SlideoutItem[] = [];
  private oldSettings = {
    name: '',
    phone: '',
    format: 0,
    company: '',
  };
  public dateFormatText = '';
  private fileToUpload: File | null = null;
  public fileToUploadUrl: any = null;
  private loadedLogoSize = null;

  constructor(
    cdRef: ChangeDetectorRef,
    public router: Router,
    public es: EditSystemService,
    private ws: WebSocketService,
    private dt: DateService,
    private google: GoogleAuthService,
    private pp: PopupService,
    private sanitizer: DomSanitizer
  ) {
    super(cdRef);
    this.tag = 'Profile';
    this.background.setGray();
    this.populateDateTimeFormats();
    if (this.myOwnProfile()) {
      this.userToUse = this.us.currentUser;
    } else {
      this.userToUse = this.es.currentUserObj;
    }
    this.headerBar.showHeader({
      headerText: this.trans('users.titles.profileHeader'),
      backUrl: es.currentUserObj === null ? this.g.getHomeUrl() : '/users',
    });
    if (this.es.currentUserObj === null) {
      this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    }
    const that = this;
    this.oldSettings.name = this.us.currentUser.name;
    this.oldSettings.phone = this.us.currentUser.phone;
    this.oldSettings.format = this.us.currentUser.DateFormat;
    this.oldSettings.company = this.us.currentUser.company;
    this.footerBar.onButton1Click = () => {
      that.revertChanges(JSON.stringify(this.oldSettings));
      that.router.navigate([this.g.getHomeUrl()]);
    };
    this.footerBar.onButton2Click = () => {
      let canNavigate = !that.userDataChanged && !that.logoChanged;
      if (that.userDataChanged) {
        canNavigate = that.saveUserSettings();
      }
      if (that.logoChanged) {
        canNavigate = that.saveUserLogo();
      }
      if (canNavigate) {
        that.router.navigate([this.g.getHomeUrl()]);
      }
    };
    this.headerBar.onBackClicked = () => {
      if (es.currentUserObj === null) {
        that.revertChanges(JSON.stringify(this.oldSettings));
      }
    };
    let fmt = this.dateTimeFormats.find((f) => f.value === this.userToUse.DateFormat);
    if (fmt === undefined) {
      fmt = this.dateTimeFormats[0];
    }
    this.dateFormatText = fmt.text;
  }

  ngOnInit(): void {}

  public myOwnProfile(): boolean {
    return this.es.currentUserObj === null || this.es.currentUserObj.id === this.us.currentUser.id;
  }

  public doLogout() {
    this.systems.clearSystems();
    this.api.setToken('');
    this.ws.close();
    if (this.platform.isDesktop()) {
      const loginType = this.us.getLoginType();
      if (loginType !== null && loginType === 'google') {
        this.google.signOut(true);
      }
    } else if (this.platform.isAndroid()) {
      this.platform.androidHandler().doLogOutGoogle();
    }
    this.router.navigate(['/login']);
  }

  public saveUserSettings(): boolean {
    if (!this.myOwnProfile()) {
      return false;
    }
    if (this.userToUse.name !== null) {
      this.userToUse.name = this.userToUse.name.trim();
    }
    if (this.userToUse.phone !== null) {
      this.userToUse.phone = this.userToUse.phone.trim();
    }
    if (this.userToUse.phone !== null && this.userToUse.phone !== '' && !/[0-9-+]/.test(this.userToUse.phone)) {
      this.toaster.postError(this.trans('validation.phoneInvalid'));
      return false;
    }
    if (this.userToUse.company !== null) {
      this.userToUse.company = this.userToUse.company.trim();
    }
    this.log('laikas saugoti nustatymus.');
    const that = this;
    const old = JSON.stringify(this.oldSettings);
    this.api
      .post(
        '/profile',
        {
          name: this.userToUse.name,
          phone: this.userToUse.phone,
          dateFormat: this.userToUse.DateFormat,
          company: this.userToUse.company,
        },
        true
      )
      .subscribe(
        (result) => {
          if (!result.success) {
            that.toaster.postError(result.error);
            that.revertChanges(old);
            return;
          }
          that.us.saveCurrentUser();
          if (that.platform.isAndroid()) {
            that.platform.androidHandler().setDateTimeFormat(that.us.currentUser.DateFormat);
          } else if (that.platform.isApple()) {
            that.platform.appleHandler().setDateTimeFormat.postMessage(that.us.currentUser.DateFormat);
          }
        },
        (error) => {
          that.toaster.postError(this.trans('auth.errors.serverSideError'));
          that.revertChanges(old);
        }
      );

    return true;
  }

  public saveUserLogo(): boolean {
    if (!this.myOwnProfile()) {
      return false;
    }
    if (this.loadedLogoSize !== null) {
      if (this.loadedLogoSize.width > 140 || this.loadedLogoSize.height > 40) {
        this.toaster.postError(this.trans('settings.errors.incorrectImageSize'));
        return false;
      }
    }

    const that = this;
    const headers = this.api.getAuthorizationHeader();
    headers.append('Content-type', 'multipart/form-data');
    const formData = new FormData();
    formData.append('logo_image', this.fileToUpload, this.fileToUpload.name);
    this.api
      .httpSender()
      .post(this.api.getUrl('/profile/logo'), formData, { headers })
      .subscribe(
        (result) => {
          if ((result as any).success) {
            that.us.currentUser.logo = (result as any).logoUrl;
            that.us.saveCurrentUser();
          }
        },
        (error) => {
          that.toaster.postError(that.trans('auth.error.serverSideError'));
        }
      );
    return true;
  }

  private populateDateTimeFormats() {
    this.dateTimeFormats = [
      { value: 1, text: this.dt.formatDateTime(new Date(), false, 1) },
      { value: 2, text: this.dt.formatDateTime(new Date(), false, 2) },
      { value: 3, text: this.dt.formatDateTime(new Date(), false, 3) },
      { value: 4, text: this.dt.formatDateTime(new Date(), false, 4) },
    ];
  }

  private revertChanges(oldSettings: string) {
    if (this.us.currentUser.id === 0) {
      return;
    }
    const settings = JSON.parse(oldSettings);
    this.us.currentUser.name = settings.name;
    this.us.currentUser.phone = settings.phone;
    this.us.currentUser.DateFormat = settings.format;
    this.us.currentUser.company = settings.company;
    this.us.saveCurrentUser();
  }

  public goChangePass() {
    this.revertChanges(JSON.stringify(this.oldSettings));
    this.router.navigate(['/password-change']);
  }

  public goDeleteAccount() {
    this.revertChanges(JSON.stringify(this.oldSettings));
    this.router.navigate(['/profile/delete']);
  }

  public languageClick() {
    this.router.navigate(['/select-language']);
  }

  public doDownloadAccount() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('general.download.generating'));
    this.api.post('/download-account', {}, true).subscribe(
      (result) => {
        if (result.success) {
          that.getTheFile();
        } else {
          that.miniStatus.hide();
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }

  private getTheFile() {
    document.location.href = this.api.getBaseUrl() + '/download-account-file?u=' + this.api.getToken() + (this.platform.isAndroid() ? '&an=1' : '');
    if (this.platform.isDesktop()) {
      this.miniStatus.show(this.trans('general.download.started'));
      setTimeout(() => {
        this.miniStatus.hide();
      }, 2000);
    } else {
      this.miniStatus.hide();
    }
  }

  public getLangSubCode(): string {
    if (this.ls.selectedLanguage.value === null) {
      this.ls.selectedLanguage.value = 'en';
    }
    const minusPos = this.ls.selectedLanguage.value.lastIndexOf('-');
    const dashPos = this.ls.selectedLanguage.value.lastIndexOf('_');
    const lastPos = Math.max(minusPos, dashPos);
    const subCodePart = lastPos === -1 ? this.ls.selectedLanguage.value : this.ls.selectedLanguage.value.substr(lastPos + 1);
    return subCodePart.toUpperCase();
  }

  public changeDateFormat() {
    const that = this;
    this.pp.showSlideout(
      {
        headerText: this.trans('users.labels.dateTimeFormat'),
        items: this.dateTimeFormats,
        onSubmit: (res) => {
          const fmt = that.dateTimeFormats.find((f) => f.value === res.value);
          that.dateFormatText = fmt.text;
          that.userToUse.DateFormat = fmt.value;
          that.userDataChanged = true;
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public handleNewFile(fileArray: FileList) {
    const file = fileArray.item(0);
    const mimeType = file.type;
    if (!mimeType.startsWith('image/svg') && mimeType !== 'image/png') {
      this.toaster.postError(this.trans('settings.errors.notAnImage'));
      return;
    }
    const that = this;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ($event) => {
      if (mimeType.startsWith('image/svg')) {
        that.fileToUploadUrl = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      } else {
        that.fileToUploadUrl = reader.result;
      }
    };
    this.fileToUpload = file;
    this.logoChanged = true;
  }

  public imageLoaded(img) {
    this.loadedLogoSize = {
      width: img.naturalWidth,
      height: img.naturalHeight,
    };
  }
}
