import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { NewSystemService } from 'src/app/services/new-system.service';
import { Router } from '@angular/router';
import { TZoneData } from 'src/app/models/zone-data';
import { TAreaData } from 'src/app/models/area-data';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { ZoneService } from 'src/app/services/zone.service';
import { TEditableComponent } from 'src/app/models/editable-component';

@Component({
  selector: 'app-add-system-zone',
  templateUrl: './system-zone.component.html',
  styleUrls: ['./system-zone.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SystemZoneComponent extends LanguageAware implements OnInit {
  public workingWithActive = false;
  public showKeypad = false;
  private areaToUse: TAreaData = null;
  public maxZoneLen = 100;
  public visibleAreas: TAreaData[] = [];
  public canAssignZone = true;

  public localZone: TZoneData = null;

  constructor(cdRef: ChangeDetectorRef, private es: EditSystemService, private router: Router, private zs: ZoneService) {
    super(cdRef);
    this.tag = 'Zone';
    const ref = localStorage.getItem('ref');
    this.workingWithActive = ref === null || ref !== 'new';
    const backUrl = this.workingWithActive ? '/system-zone-list' : '/add-system-zone-list';
    const isEditing = this.es.getId(TEditableComponent.Zone) !== -1;
    const headerText = isEditing ? this.trans('systems.titles.editZone') : this.trans('systems.buttons.addZone');
    this.headerBar.showHeader({
      headerText,
      backUrl,
    });
    this.visibleAreas = this.getAreas();
    this.localZone = this.es.getEditableComponent(TEditableComponent.Zone);
    if (!isEditing) {
      let maxZone = 0;
      const zones = this.systems.activeSystem.zones;
      for (const iZone of zones) {
        if (iZone.queue_no > maxZone) {
          maxZone = iZone.queue_no;
        }
      }
      this.localZone = {
        alarmed: false,
        areas: [],
        bypassed: false,
        enabled: true,
        failed: false,
        name: this.trans('systems.titles.zone').replace(':no', (maxZone + 1).toString()),
        queue_no: maxZone + 1,
        visible: true,
        native: false,
      };
    }
    const that = this;
    if (this.systems.activeSystemHelper.can.edit.zones() || !this.workingWithActive) {
      if (isEditing) {
        this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
        this.footerBar.onButton1Click = () => {
          this.router.navigate([backUrl]);
        };
        this.footerBar.onButton2Click = () => {
          that.onNext();
        };
      } else {
        this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.add'), true, false);
        this.footerBar.onButton1Click = () => {
          this.router.navigate([backUrl]);
        };
        this.footerBar.onButton2Click = () => {
          that.onNext();
        };
      }
    }
    this.background.setGray();
    if (this.workingWithActive && this.systems.activeSystem.hwType === 'SP3') {
      this.maxZoneLen = 23;
    }
    this.canAssignZone = this.systems.activeSystem.hwType !== 'SP3' && !this.systems.activeSystem.directControl;
    if (!isEditing && this.canAssignZone && this.systems.activeSystem.areas.length === 1) {
      this.localZone.areas.push(this.systems.activeSystem.areas[0].queue_no);
    }
  }

  ngOnInit(): void {}

  public onNext() {
    if (this.localZone.queue_no < 1 || this.localZone.queue_no > 999) {
      this.toaster.postError(this.trans('systems.errors.badZoneNumber'));
      return;
    }
    if (this.localZone.areas.length === 0) {
      this.toaster.postError(this.trans('systems.errors.mustAssignZone'));
      return;
    }
    let zone: TZoneData = this.systems.activeSystem.zones.find((z) => z.queue_no === this.localZone.queue_no);
    const isNew = zone === null || zone === undefined;
    if (this.localZone.name === '') {
      this.toaster.postError(this.trans('validation.required').replace(':attribute', this.trans('systems.labels.zoneName')));
      return;
    }
    if (isNew) {
      zone = this.localZone;
    } else {
      zone.name = this.localZone.name;
      zone.queue_no = this.localZone.queue_no;
      zone.areas = this.localZone.areas;
      zone.visible = true;
    }

    if (isNew) {
      this.addZone(zone);
    } else {
      this.editZone(zone);
    }
    this.router.navigate([this.workingWithActive ? '/system-zone-list' : '/add-system-zone-list']);
  }

  private getAreas(): TAreaData[] {
    return this.systems.activeSystem.areas;
  }

  public zoneHasArea(value: number): boolean {
    return this.localZone.areas.filter((a) => a === value).length > 0;
  }

  public areaClick(value: number) {
    if (this.zoneHasArea(value)) {
      this.localZone.areas = this.localZone.areas.filter((a) => a !== value);
    } else {
      this.localZone.areas.push(value);
    }
  }

  public getAssignedPgmName(forArea: TAreaData): string {
    const pgms = this.systems.activeSystem.pgms;
    for (const iPgm of pgms) {
      if (iPgm.control_area && iPgm.area_id === forArea.id) {
        return this.trans('systems.labels.controlledWith') + ': ' + iPgm.name;
      }
    }

    return '';
  }

  private addZone(zone: TZoneData) {
    this.systems.activeSystem.zones.push(zone);
    const activeSystemId = this.systems.activeSystem.id;
    const that = this;
    this.api.post('/add/zone', { system_id: activeSystemId, zone }, true).subscribe(
      (result) => {
        if (result.success) {
          if (that.systems.activeSystem.id === activeSystemId) {
            that.systems.setCurrentSystem(that.systems.activeSystem);
          }
        } else {
          that.revertAddition(activeSystemId, zone.queue_no);
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.revertAddition(activeSystemId, zone.queue_no);
        that.toaster.postError(that.trans('systems.errors.zoneSaveFailed'));
      }
    );
  }

  private revertAddition(activeSystemId: number, zoneNumber: number) {
    const system = this.systems.getSystem(activeSystemId);
    if (system === undefined) {
      return;
    }
    system.zones = system.zones.filter((z) => z.queue_no !== zoneNumber);
    if (this.systems.activeSystem.id === system.id) {
      this.systems.setCurrentSystem(system);
    }
  }

  private editZone(zone: TZoneData) {
    const activeSystemId = this.systems.activeSystem.id;
    const zoneBefore = this.es.getComponentBeforeModification(TEditableComponent.Zone);
    const that = this;
    this.api.post('/edit/zone', { system_id: activeSystemId, zone }, true).subscribe(
      (result) => {
        if (result.success) {
          if (that.systems.activeSystem.id === activeSystemId) {
            that.systems.setCurrentSystem(that.systems.activeSystem);
          }
        } else {
          that.revertChanges(activeSystemId, zoneBefore);
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.revertChanges(activeSystemId, zoneBefore);
        that.toaster.postError(that.trans('systems.errors.zoneSaveFailed'));
      }
    );
  }

  private revertChanges(systemId: number, zoneBefore: TZoneData) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }

    const zone = system.zones.find((z) => z.queue_no === zoneBefore.queue_no);
    if (zone === undefined) {
      return;
    } // Privalo egzistuot. O jeigu nėr, tai vadinasi buvo pašalintas kitos operacijos metu. Nepridedam.

    zone.name = zoneBefore.name;
    if (this.systems.activeSystem.id === systemId) {
      this.systems.setCurrentSystem(system);
    }
  }

  public queueNumberIsReadOnly(): boolean {
    return this.es.getComponentBeforeModification(TEditableComponent.Zone) !== null && this.workingWithActive;
  }

  public bypassFunctionAvailable(): boolean {
    if (!this.workingWithActive || this.systems.activeSystem === null || !this.systems.activeSystem.directControl) {
      return false;
    }
    let atLeastOneAreaHasBypass = false;
    for (const iZoneArea of this.localZone.areas) {
      const area = this.systems.activeSystem.areas.find((a) => a.queue_no === iZoneArea);
      if (area !== undefined && area.canBypass) {
        atLeastOneAreaHasBypass = true;
        break;
      }
    }

    return this.systems.activeSystemHelper.can.bypassZone() && atLeastOneAreaHasBypass;
  }

  public performBypass() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    if (this.localZone.bypassed && !this.systems.activeSystemHelper.can.unbypassZone()) {
      this.toaster.postError(this.trans('systems.errors.unbypassNotSupported'));
      return;
    }

    const area = this.systems.activeSystem.areas.find((a) => a.queue_no === this.localZone.areas[0]);
    if (area === undefined) {
      this.toaster.postError(this.trans('systems.errors.areaDoesNotExist'));
      return;
    }
    if (area.showKeypad) {
      this.areaToUse = area;
      this.showKeypad = true;
    } else {
      const that = this;
      this.miniStatus.show(this.trans('general.pleaseWait'));
      this.zs.performBypass(this.localZone, undefined, undefined, undefined, () => {
        that.onBypassOk();
      });
    }
  }

  public keypadCancelled() {
    this.showKeypad = false;
  }

  public pinConfirmed(pin?: string, remember?: boolean) {
    this.showKeypad = false;
    const that = this;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.zs.performBypass(this.localZone, pin, remember, this.areaToUse, () => {
      that.onBypassOk();
    });
  }

  private onBypassOk() {
    const zone = this.systems.activeSystem.zones.find((z) => z.queue_no === this.es.getId(TEditableComponent.Zone));

    if (this.systems.activeSystem.panel >= 0x10 && this.systems.activeSystem.panel < 0x20) {
      zone.bypassed = false;
      return;
    }
    zone.bypassed = !zone.bypassed;
    this.log('nauja zonos busena', zone);
  }

  public setZoneNumber(value: number) {
    if (isNaN(value)) {
      return;
    }
    if (typeof value === 'string') {
      if ((value as string) === '') {
        return;
      }
      value = parseInt(value, 10);
    }
    this.localZone.queue_no = value;
  }
}
