import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { BasePopupComponent } from 'src/app/popups/base-popup/base-popup.component';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-element-resize-popup',
  templateUrl: './element-resize-popup.component.html',
  styleUrls: ['./element-resize-popup.component.scss'],
})
export class ElementResizePopupComponent implements OnInit {
  @ViewChild('basePopup') basePopup: BasePopupComponent;
  @Input() elementName = '';
  @Input() elementId = 0;
  @Output() sizeSelected: EventEmitter<any> = new EventEmitter();

  constructor(public lang: LanguageService) {}

  ngOnInit(): void {}

  public show() {
    this.basePopup.show();
  }

  public doSizeSelect(width, height: number) {
    this.sizeSelected.emit({ width, height });
    this.basePopup.hidePopup();
  }
}
