import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { PopupOptions } from '../models/popup-options';
import { PopupType } from '../models/popup-type';
import { SlideoutOptions } from '../models/slideout-options';

@Injectable({
  providedIn: 'root',
})
export class PopupService {
  private popupOpenSource = new Subject<any>();
  public onShowPopup = this.popupOpenSource.asObservable();
  private popupCloseSource = new Subject<any>();
  public onHidePopup = this.popupCloseSource.asObservable();
  private slideoutOpenSource = new Subject<any>();
  public onShowSlideout = this.slideoutOpenSource.asObservable();
  private slideoutCloseSource = new Subject<any>();
  public onHideSlideout = this.slideoutCloseSource.asObservable();

  constructor() {}

  public showPopup(options: PopupOptions, popupType: PopupType) {
    this.popupOpenSource.next({ options, popupType });
  }

  public hidePopup() {
    this.popupCloseSource.next();
  }

  public showSlideout(options: SlideoutOptions, popupType: PopupType) {
    this.slideoutOpenSource.next({ options, popupType });
  }

  public hideSlideout() {
    this.slideoutCloseSource.next();
  }
}
