import { Injectable } from '@angular/core';
import { UserRole } from '../models/user-role';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/user.service';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserRoleGuardService implements CanActivate {
  constructor(private us: UserService, private router: Router, private g: GlobalService) {}

  canActivate() {
    if (this.us.currentUser.role !== UserRole.SimpleUser) {
      this.router.navigate([this.g.getHomeUrl()]);
      return false;
    }
    return true;
  }
}
