<div class="grid" id='help-grid'>
    <div class="group-background" id='help-back1'></div>
    <app-button-single-line-arrow id='help-ticket' [icon]="'assets/images/help.svg'" (click)="clickTicket()">{{trans('help.supportTicket')}}</app-button-single-line-arrow>
    <div id='help-contact-header'>{{trans('general.contact')}}</div>
    <div class="group-background" id='help-back2'></div>
    <app-button-single-line-arrow id='help-address' [icon]="'assets/images/location.svg'" [showArrow]="false">{{getAddress()}}</app-button-single-line-arrow>
    <app-button-single-line-arrow id='help-email' [icon]="'assets/images/email2.svg'" (click)='clickEmail()'>{{getEmail()}}</app-button-single-line-arrow>
    <app-button-single-line-arrow id='help-phone' [icon]="'assets/images/phone.svg'" (click)='clickPhone()'>{{getPhone()}}</app-button-single-line-arrow>
    <app-button-single-line-arrow id='help-www' [icon]="'assets/images/www.svg'" (click)='clickUrl()'>{{getUrl()}}</app-button-single-line-arrow>
</div>
<div *ngIf='shouldShowLoggerbutton()' id='log-icon-container' (click)='l.showLogView = !l.showLogView; logResolution()'>
    <div id='log-icon' [inlineSVG]="'assets/images/log.svg'"></div>
</div>