<div class="grid add-system-zone-list-grid">
    <div id='aszl-background' class="group-background"></div>
    <div id='aszl-zone-list'>
        <div class="list-item-v1" *ngFor='let zone of getZones()' (click)='onZoneSelect(zone)'>
            <div [inlineSVG]="'assets/images/zone-general-icon.svg'"></div>
            <div >{{zone.queue_no}}</div>
            <div>
                <div>{{zone.name}}</div>
                <div>{{getAssignedAreas(zone.queue_no)}}&nbsp;</div>
            </div>
            <div [inlineSVG]="'assets/images/delete.svg'" (click)='onZoneDelete($event, zone.queue_no)'></div>
        </div>
        <div *ngIf='helper.fakeElementIsVisibleNow(windowName)' class="list-item-v1" id='aszl-fake'>
            <div></div>
            <div></div>
            <div>
                <div></div>
                <div></div>
            </div>
            <div></div>
        </div>
    </div>
</div>
<div [ngClass]=" { 'aszl-add-zone-container': true, 'aszl-add-zone-container-middle': !helper.plusIsAtBottomNow(windowName), 'aszl-add-zone-container-bottom': helper.plusIsAtBottomNow(windowName) }">
    <div id='aszl-add-zone-button' [ngClass]="{ 'aszl-add-zone-button': true, 'aszl-add-zone-button-bottom': helper.plusIsAtBottomNow(windowName) }" (click)='onAddZone()'><div [inlineSVG]="'assets/images/plus.svg'"></div></div>
    <div *ngIf='!helper.plusIsAtBottomNow(windowName)' class='plus-button-subtext'>{{trans('systems.titles.clickPlusZone')}}</div>
</div>