import http from '../http';
import { BasicSuccessResponse, ErrorResponse, objectToFormData } from './common';

type EditReactionRequest = {
  id: number | 0;
  name: string;
  active: boolean;
  custom_sound: boolean;
  group_id: number;
  assignedDescriptions?: number[];
};
type EditReactionResponse = ErrorResponse | (BasicSuccessResponse & { id: number });

export const editReaction = (req: EditReactionRequest) => http.post<EditReactionResponse, EditReactionRequest>('/v3/api/edit/reaction', req);

type DeleteReactionRequest = { reaction_id: number };
type DeleteReactionResponse = ErrorResponse | BasicSuccessResponse;

export const deleteReaction = (req: DeleteReactionRequest) => http.post<DeleteReactionResponse, DeleteReactionRequest>('/v3/api/delete/reaction', req);

type EditReactionGroupRequest = { id: number | 0; name: string };
type EditReactionGroupResponse = ErrorResponse | (BasicSuccessResponse & { id: number });

export const editReactionGroup = (req: EditReactionGroupRequest) => http.post<EditReactionGroupResponse, EditReactionGroupRequest>('/v3/api/edit/reaction-group', req);

type DeleteReactionGroupRequest = { group_id: number };
type DeleteReactionGroupResponse = ErrorResponse | BasicSuccessResponse;

export const deleteReactionGroup = (req: DeleteReactionGroupRequest) => http.post<DeleteReactionGroupResponse, DeleteReactionGroupRequest>('/v3/api/delete/reaction-group', req);

type SetReactionGroupIconRequest = { id: number; image: Blob };
type SetReactionGroupIconResponse = ErrorResponse | (BasicSuccessResponse & { icon: string });

export const setReactionGroupIcon = (req: SetReactionGroupIconRequest) => http.post<SetReactionGroupIconResponse, FormData>('/v3/api/reaction-group/icon', objectToFormData(req));

type SetReactionIconRequest = { id: number; image: Blob };
type SetReactionIconResponse = ErrorResponse | (BasicSuccessResponse & { icon: string });

export const setReactionIcon = (req: SetReactionIconRequest) => http.post<SetReactionIconResponse, FormData>('/v3/api/reaction/icon', objectToFormData(req));
