<app-base-popup #basePopup [canCancel]="false">
<div class="white_round padding16" id="dmdp_container">
    <div id="dmdp_icon"><svg viewBox="0 0 141 132" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M96.613,39.296l-0,88.437c-0,1.93 1.587,3.517 3.516,3.517l16.409,-0c1.929,0 3.516,-1.587 3.516,-3.517l0,-88.458l13.492,-0c3.848,-0.014 7.008,-3.185 7.008,-7.033c-0,-1.854 -0.733,-3.634 -2.037,-4.95l-25.213,-25.213c-1.318,-1.318 -3.107,-2.059 -4.971,-2.059c-1.863,-0 -3.653,0.741 -4.97,2.059l-25.213,25.213c-1.317,1.318 -2.058,3.107 -2.058,4.971c0,3.856 3.172,7.03 7.029,7.033l13.492,-0Zm-56.06,-39.295l-0.09,-0.001l-16.409,-0c-1.929,-0 -3.516,1.587 -3.516,3.517l-0,88.458l-13.492,0c-3.849,0.014 -7.008,3.185 -7.008,7.033c0,1.854 0.733,3.634 2.037,4.95l25.213,25.213c1.317,1.318 3.107,2.059 4.97,2.059c1.864,0 3.653,-0.741 4.971,-2.059l25.213,-25.213c1.317,-1.318 2.057,-3.107 2.057,-4.971c0,-3.856 -3.172,-7.03 -7.028,-7.033l-13.492,0l0,-88.437c0,-1.899 -1.538,-3.467 -3.426,-3.516Z" /></svg></div>
    <div id="dmdp_mini_icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 35.519 35.519">
        <g transform="translate(-19.345 -19.345)">
          <path d="M37.1,19.345A17.76,17.76,0,1,0,54.864,37.1,17.766,17.766,0,0,0,37.1,19.345ZM38.88,45.984H35.328V42.432H38.88Zm0-7.1H35.328V28.224H38.88Z"/>
        </g>
      </svg>
    </div>
    <div id="dmdp_header">{{l.get('systems.titles.disableMobileData')}}</div>
    <div id="dmdp_text">{{l.get('systems.labels.mobileDataWarning')}}</div>
    <div id="dmdp_cancel" (click)="doCancel()" *ngIf="!isReminder">{{l.get('general.cancel')}}</div>
    <div id="dmdp_cancel" (click)="doOk()" *ngIf="isReminder">{{l.get('general.ok')}}</div>
</div>
</app-base-popup>