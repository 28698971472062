import { Injectable } from '@angular/core';
import { SystemsService } from './systems.service';

@Injectable({
  providedIn: 'root',
})
export class FilteringService {
  private filteredLists = {};
  private filterPhrases = {};

  constructor(private systems: SystemsService) {
    const that = this;
    this.systems.onActiveSystemChange.subscribe(() => {
      that.filteredLists = {};
      that.filterPhrases = {};
    });
  }

  /**
   * Atliekamas filtravimas pagal pateiktą paieškos frazę.
   *
   * @param name Filtro pavadinimas
   * @param phrase Paieškos frazė.
   * @param sourceList Sąrašas, kuriame bus filtruojama.
   * @param propertiesToCheck Laukai, kuriuose bus bandoma ieškoti paieškos frazės.
   */
  public performFilter(name: string, phrase: string, sourceList: any[], propertiesToCheck: string[]) {
    this.filterPhrases[name] = phrase;
    this.filteredLists[name] = [];
    if (phrase === '') {
      this.filteredLists[name] = sourceList;
    } else {
      this.filteredLists[name] = sourceList.filter((i) => this.containsInAnyOfProperties(propertiesToCheck, i, phrase));
    }
  }

  private containsInAnyOfProperties(properties: string[], item: any, phrase: string): boolean {
    for (const iProperty of properties) {
      if (typeof item[iProperty] !== 'string') {
        continue;
      }
      if ((item[iProperty] as string).toLocaleLowerCase().indexOf(phrase.toLocaleLowerCase()) > -1) {
        return true;
      }
    }
    return false;
  }

  public getFilteredItemsFor(name: string): any[] {
    return this.filteredLists[name] ?? [];
  }

  public getFilterPhraseFor(name: string): string {
    return this.filterPhrases[name] ?? '';
  }

  public hasFilterFor(name: string): boolean {
    return this.filteredLists[name] !== undefined && this.filterPhrases[name] !== undefined && this.filterPhrases[name] !== '';
  }

  public clearFilterFor(name: string) {
    this.filteredLists[name] = undefined;
    this.filterPhrases[name] = undefined;
  }
}
