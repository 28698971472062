<div class="white_round padding16">
    <app-text-edit [value]="groupItem.name" (valueChanged)="changeName($event)">{{trans('settings.reactions.labels.groupName')}}</app-text-edit>
    <div id="cpegr_logo_container">
        <input id="cppegrl_input" type="file" name="icon" accept="image/svg" (change)="handleNewFile($event.target.files)">
        <label for="cppegrl_input">
            <div id="cppegrl_upload">
                <div id="cppegrl_preview"><img [src]="fileToUploadUrl" *ngIf="iconChanged" (load)="imageLoaded($event.target)"/></div>
                <div id="cppegrl_icon" *ngIf="!iconChanged" [inlineSVG]="groupItem.icon"></div>
                <div id="cppegrl_text">{{trans('settings.reactions.labels.uploadNewIcon')}}</div>
                <div id="cppegrl_arrow"><svg viewBox="0 0 8.402 14.697"><path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/></svg></div>
            </div>
        </label>
    </div>
</div>
<div class="bold_header" id="cpegr_header">
    <div id="cpegr_text">{{trans('settings.reactions.labels.assignedReactions')}}</div>
    <div id='cpegr_plus' (click)="addReaction()"><span><svg xmlns="http://www.w3.org/2000/svg" width="15.851" height="15.851" viewBox="0 0 15.851 15.851"><g transform="translate(-6.5 -6.5)"><path d="M18,7.5V21.351" transform="translate(-3.575)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/><path d="M7.5,18H21.351" transform="translate(0 -3.575)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/></g></svg></span></div>
</div>
<div class="white_round">
    <div *ngFor="let iReaction of us.currentUser.reactions" class="cpegrr_item">
        <div class="cpegrr_check">
            <app-checkbox [isSecondaryColor]='true' [checkValue]="iReaction.group_id === groupItem.id" [isRound]='false' (valueChanged)="reactionChecked(iReaction)"></app-checkbox>
        </div>
        <div class="cpegrr_name" (click)="openReaction(iReaction)">{{iReaction.default_name ? trans('reactionNames.reactions.' + iReaction.name) : iReaction.name}}</div>
        <div class="cpegrr_status" (click)="openReaction(iReaction)">{{iReaction.active ? trans('general.enabled') : trans('general.disabled')}}</div>
        <div class="cpegrr_arrow" (click)="openReaction(iReaction)"><svg viewBox="0 0 8.402 14.697"><path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/></svg></div>
    </div>
</div>