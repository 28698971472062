import { Component, OnInit } from '@angular/core';
import { ItemConfiguration } from '../../components/company-columns/company-columns.component';

@Component({
  templateUrl: './company-users.component.html',
  styleUrls: ['./company-users.component.scss'],
})
export class CompanyUsersComponent implements OnInit {
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: 'Icon', value: 'fake:icon' },
    { name: 'Name', value: 'name' },
    { name: 'Email', value: 'email' },
    { name: 'Phone', value: 'phone' },
    { name: 'Role', value: 'role' },
  ];
  public columns = ['fake:icon', 'name', 'email', 'phone', 'role'];
  constructor() {}

  ngOnInit(): void {}
}
