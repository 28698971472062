<div class="sli_grid {{ss.activeSystem !== null && ss.activeSystem.id === systemItem.id ? 'sli_grid_selected' : ''}}">
    <div *ngIf='!shouldShowControls(); then statusBlock else controlsBlock'></div>
    <ng-template #statusBlock>
        <div *ngIf="systemItem.theme.fullBackground === '' && !areaService.loadingIsShown(systemItem.areas[0])" class='sli_icon' [ngStyle]="{ background: 'linear-gradient(180deg, ' + systemItem.theme.startColor + ' 0%, ' + systemItem.theme.endColor + ' 100%)' }" (click)="$event.stopPropagation(); toggleSystemControls()">
            <div *ngIf='systemItem.areas.length === 1 && supportsAreas()' [ngClass]="{ 'sli_subicon': true, 'sli_subicon_disarmed': isSystemDisarmed(), 'sli_subicon_alarmed': isSystemAlarmed()}">
                <div [inlineSVG]="getStatusIconForSystem()"></div>
            </div>
        </div>
        <div *ngIf="systemItem.theme.fullBackground !== '' && !areaService.loadingIsShown(systemItem.areas[0])" class='sli_icon' [ngStyle]="{ background: systemItem.theme.fullBackground }" (click)="$event.stopPropagation(); toggleSystemControls()">
            <div *ngIf='systemItem.areas.length === 1 && supportsAreas()' class="sli_subicon {{isSystemDisarmed() ? 'sli_subicon_disarmed' : ''}}">
                <div [inlineSVG]="getStatusIconForSystem()"></div>
            </div>
        </div>
        <div class="sli_ld sli_icon"  *ngIf='areaService.loadingIsShown(systemItem.areas[0])'>
            <div><app-loader-dots [width]="'28px'" [height]="'28px'"></app-loader-dots></div>
        </div>
        <div class="sli_name">{{systemItem.name}}</div>
    </ng-template>

    <ng-template #controlsBlock>
        <div class="sli_controls_back {{supportsAllStates() ? 'slicb_full' : 'slicb_half'}}">
            <div [@backSlide]="backSlideState" class='slicb_filler'></div>
        </div>
        <div class="sli_cancel" (click)="$event.stopPropagation(); toggleSystemControls()">
            <div [inlineSVG]="'assets/images/close_fillable.svg'"></div>
        </div>
        <div class="sli_arm sli_button" [@buttonFade]="buttonFadeState" (click)='$event.stopPropagation(); setAreaState(2)'>
            <div [inlineSVG]="'assets/images/arm.svg'"></div>
        </div>
        <div class="sli_disarm sli_button" [@buttonFade]="buttonFadeState" (click)='$event.stopPropagation(); setAreaState(1)'>
            <div [inlineSVG]="'assets/images/disarm.svg'"></div>
        </div>
        <div *ngIf="supportsAllStates()" [@buttonFade]="buttonFadeState" class="sli_stay sli_button" (click)='$event.stopPropagation(); setAreaState(3)'>
            <div [inlineSVG]="'assets/images/stay.svg'"></div>
        </div>
        <div *ngIf="supportsAllStates()" [@buttonFade]="buttonFadeState" class="sli_sleep sli_button" (click)='$event.stopPropagation(); setAreaState(4)'>
            <div [inlineSVG]="'assets/images/sleep.svg'"></div>
        </div>
    </ng-template>
</div>