<app-login-layout-sofa>
  <div class="main-section">
    <div class="login-box">
      <h2>{{ trans('auth.labels.hello1') }}</h2>
      <a *ngIf="auth.hasAccounts" routerLink="/login/accounts" [attr.aria-label]="trans('auth.labels.useAnotherAccount')" role="button">
        <div class="emailPill">{{ login.email }}<i class="arrow"></i></div
      ></a>
      <div *ngIf="!auth.hasAccounts" class="emailPill">{{ login.email }}</div>
      <p>{{ trans('auth.labels.hello1') }}</p>
      <form (submit)="$event.preventDefault(); next()">
        <input style="display: none" type="email" name="email" id="email" [(value)]="login.email" autocomplete="email" />
        <app-string-input
          [label]="trans('auth.form.password')"
          name="password"
          type="password"
          autocomplete="current-password"
          [(value)]="login.password"
          [hasError]="val.hasError('password')"
        >
          <app-form-error *ngIf="val.hasError('password')">
            {{ val.getError('password') }} <a routerLink="/login/password-reset/email">>{{ trans('auth.forgotPassword') }}.</a>
          </app-form-error>
        </app-string-input>
        <app-button>{{ trans('auth.loginFinish') }}</app-button>
      </form>
    </div>
  </div>
</app-login-layout-sofa>
<ng-template [appTeleport]="ForgotPasswordTarget">
  <a class="forgotPasswordLink" routerLink="/login/password-reset/email">{{ trans('auth.forgotPassword') }}</a>
</ng-template>
