import { Injectable, Injector } from '@angular/core';
import { Settings } from 'luxon';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { autoinject } from 'src/shim';
import { LoggerService } from './logger.service';
import { RequestService } from './request.service';

const snowflakeCountries: Record<string, string> = {
  CT: 'Canton and Enderbury Islands',
  NQ: 'Dronning Maud Land',
  DD: 'East Germany',
  FQ: 'French Southern and Antarctic Territories',
  JT: 'Johnston Island',
  FX: 'Metropolitan France',
  MI: 'Midway Islands',
  AN: 'Netherlands Antilles',
  NT: 'Neutral Zone',
  VD: 'North Vietnam',
  PC: 'Pacific Islands Trust Territory',
  PZ: 'Panama Canal Zone',
  YD: "People's Democratic Republic of Yemen",
  CS: 'Serbia and Montenegro',
  PU: 'U.S. Miscellaneous Pacific Islands',
  SU: 'Union of Soviet Socialist Republics',
  WK: 'Wake Island',
};

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private _locale = localStorage.getItem('lang') ?? 'en';
  private localeChange = new Subject<string>();
  public onLocaleChange = this.localeChange.asObservable();
  public onLocaleChangeWithDescription = this.localeChange.pipe(map((value) => ({ value, description: this.getLocaleName(value) })));
  private displayNameCache: Record<string, Intl.DisplayNames> = {};
  private countryDisplayName = new Intl.DisplayNames([this.locale], { type: 'region' });

  private languageList = ['en'];
  private hasFetchedLanguageList = false;

  private req = autoinject(this.injector, RequestService);

  constructor(private l: LoggerService, private injector: Injector) {}

  public getLocaleName(code: string): string {
    this.displayNameCache[code] = this.displayNameCache[code] ?? new Intl.DisplayNames([code], { type: 'language' });
    return this.fixNameCase(code, this.displayNameCache[code].of(code) ?? 'Unknown');
  }

  private fixNameCase(code: string, name: string): string {
    return name.charAt(0).toLocaleUpperCase(code) + name.slice(1);
  }

  public get locale(): string {
    return this._locale;
  }
  public set locale(value: string) {
    const oldLocale = this._locale;
    this._locale = value;
    Settings.defaultLocale = value;
    if (oldLocale !== value) {
      this.onLocaleChangeDo(value);
    }
  }

  private onLocaleChangeDo(newLocale: string): void {
    this.localeChange.next(newLocale);
    this.countryDisplayName = new Intl.DisplayNames([newLocale], { type: 'region' });
  }

  public getLanguageList(): string[] {
    if (!this.hasFetchedLanguageList) {
      this.hasFetchedLanguageList = true;
      this.fetchLanguageList();
    }
    return this.languageList;
  }

  private async fetchLanguageList(): Promise<void> {
    const res = await this.req().misc.languages().toPromise();
    if (!res.success) {
      this.l.log('Failed to fetch language list', 'LocaleService', { res });
      return;
    }
    this.languageList = Object.keys(res.list);
  }

  public getCountryName(code: string): string {
    const name = this.countryDisplayName.of(code);
    // TODO: Remove unknwon countries or add there name transaltion to be handled here.
    const snowflakeName = snowflakeCountries[code];
    if (name === code && !snowflakeName) this.l.log('Unknown country code', 'LocaleService', { code });
    return snowflakeName ?? name;
  }
}
