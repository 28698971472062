<div class="grid system-notifications-grid">
    <div class="group-background" id='system-notifications-globals-back'></div>
    <div id='system-notifications-globals'>
        <div class="system-notifications-globals-item">
            <div>{{trans('systems.labels.allowNotifications')}}</div>
            <app-toggle-button [initialState]='systems.activeSystem.notificationsEnabled' (toggled)="toggleGlobal($event.checked)" buttonId='global-notif'></app-toggle-button>
        </div>
        <div class="system-notifications-globals-item">
            <div>{{trans('users.settings.menu.alert')}}</div>
            <app-toggle-button [initialState]='systems.activeSystem.notificationSoundsEnabled' (toggled)="toggleGlobalSound($event.checked)" buttonId='global-sound'></app-toggle-button>
        </div>
    </div>
    <div id='system-notifications-reactions'>
        <div class="system-notifications-item" *ngFor='let iNotificationSetting of systems.activeSystem.notifications'>
            <app-system-notifications-item [notificationItem]='iNotificationSetting' [isEnabled]='systems.activeSystem.notificationsEnabled'></app-system-notifications-item>
        </div>
    </div>
</div>