import http from '../http';
import { BasicSuccessResponse, ErrorResponse, ExErrorResponse } from './common';
import { GetSystemInfoNewResponse } from './system';

type RereadStartRequest = { systemId: number };
type RereadStartResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      nextStatus: string;
      rereadId: number; // What's the point of this?
      progress: { current: number; max: number };
    });
export const rereadStart = (req: RereadStartRequest) => http.post<RereadStartResponse, RereadStartRequest>('/v3/api/reread/start', req);

type RereadCheckRequest = { systemId: number };
type RereadCheckResponse =
  | ErrorResponse
  | { success: false; nextStatus: string; completed: true; progress: null }
  | {
      success: boolean;
      nextStatus: string;
      completed: boolean;
      progress: { current: number; max: number };
    };

export const rereadCheck = (req: RereadCheckRequest) => http.post<RereadCheckResponse, RereadCheckRequest>('/v3/api/reread/check', req);

type GetSystemInfoForConfigRequest = { systemUid: string; mpass?: string };
type GetSystemInfoForConfigResponse = GetSystemInfoNewResponse;

export const getSystemInfoForConfig = (req: GetSystemInfoForConfigRequest) => http.post<GetSystemInfoForConfigResponse, GetSystemInfoForConfigRequest>('/v3/api/config/info', req);

type ReadConfigurationRequest = {
  systemUid: string;
  mpass: string;
  adminCode: string;
};
type ReadConfigurationResponse =
  | ErrorResponse
  | ExErrorResponse
  | (BasicSuccessResponse & {
      data: object; // TODO: better type
    });

export const readConfiguration = (req: ReadConfigurationRequest) => http.post<ReadConfigurationResponse, ReadConfigurationRequest>('/v3/api/config/read', req);

type WriteConfigurationRequest = {
  systemUid: string;
  mpass: string;
  adminCode: string;
  config: object; // TODO: better type
};
type WriteConfigurationResponse = ErrorResponse | ExErrorResponse | BasicSuccessResponse;

export const writeConfiguration = (req: WriteConfigurationRequest) => http.post<WriteConfigurationResponse, WriteConfigurationRequest>('/v3/api/config/write', req);

type ReadEventLogRequest = ReadConfigurationRequest & { packet?: number };
type ReadEventLogResponse = ErrorResponse | ExErrorResponse | (BasicSuccessResponse & { data: string[] });

export const readEventLog = (req: ReadEventLogRequest) => http.post<ReadEventLogResponse, ReadEventLogRequest>('/v3/api/config/eventlog/read', req);

type ClearEventLogRequest = ReadConfigurationRequest;
type ClearEventLogResponse = ErrorResponse | ExErrorResponse | BasicSuccessResponse;

export const clearEventLog = (req: ClearEventLogRequest) => http.post<ClearEventLogResponse, ClearEventLogRequest>('/v3/api/config/eventlog/clear', req);

type GetDeviceTimeRequest = { systemUid: string; mpass: string };
type GetDeviceTimeResponse = ErrorResponse | ExErrorResponse | (BasicSuccessResponse & { time: string });

/** SP3 ONLY */
export const getDeviceTime = (req: GetDeviceTimeRequest) => http.post<GetDeviceTimeResponse, GetDeviceTimeRequest>('/v3/api/config/time/read', req);

type SetDeviceTimeRequest = GetDeviceTimeRequest & { time: string };
type SetDeviceTimeResponse = GetDeviceTimeResponse;

/** SP3 ONLY */
export const setDeviceTime = (req: SetDeviceTimeRequest) => http.post<SetDeviceTimeResponse, SetDeviceTimeRequest>('/v3/api/config/time/write', req);

type LearnSensorsRequest = GetDeviceTimeRequest & {
  action: 'start' | 'check' | 'stop';
};
type LearnSensorsResponse =
  | ErrorResponse
  | ExErrorResponse
  | (BasicSuccessResponse & {
      data:
        | ''
        | {
            queueNo: number;
            registered: boolean;
            group: number;
            type: number;
            sn: number;
          }[];
    });
export const learnSensors = (req: LearnSensorsRequest) => http.post<LearnSensorsResponse, LearnSensorsRequest>('/v3/api/config/learn-sensors', req);

type UpdateSensorsInfoRequest = GetDeviceTimeRequest;
type UpdateSensorsInfoResponse = ErrorResponse | ExErrorResponse | (BasicSuccessResponse & { data: string });

export const updateSensorsInfo = (req: UpdateSensorsInfoRequest) => http.post<UpdateSensorsInfoResponse, UpdateSensorsInfoRequest>('/v3/api/config/update-sensors', req);

type ReadDeviceUserListRequest = ReadEventLogRequest;
type ReadDeviceUserListResponse =
  | ErrorResponse
  | ExErrorResponse
  | (BasicSuccessResponse & {
      data: {
        queueNo: number;
        enabled: boolean;
        validFromEnabled: boolean;
        validToEnabled: boolean;
        counterEnabled: boolean;
        counter: number;
        currentCounter: number;
        greet: boolean;
        schedule: number;
        canControl: {
          [key: string]: boolean;
        };
        validFrom: number;
        validUntil: number;
        phone: string;
        email: string;
        code: string;
        name: string;
        presentInDevice: boolean;
        modified: boolean;
      }[];
    });

/** GV17 ONLY */
export const readDeviceUserList = (req: ReadDeviceUserListRequest) => http.post<ReadDeviceUserListResponse, ReadDeviceUserListRequest>('/v3/api/config/users/read', req);
