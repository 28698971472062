<div class="grid system-config-grid">
    <div id='system-config-main-background' class="group-background"></div>
    <div id='system-config-main'>
        <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.information()' routerLink='/system-information'>{{trans('systems.menu.systemInformation')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf="[1, 2].includes(us.currentUser.role)" routerLink='/system-notifications'>{{trans('systems.menu.notifications')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.areas()' routerLink='/system-area-list'>{{trans('systems.titles.partitions')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.supports.zones() && systems.activeSystemHelper.can.see.zones()' routerLink='/system-zone-list'>{{trans('systems.titles.zones')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.outputs()' routerLink='/system-pgm-list'>{{trans('systems.menu.outputs')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.sensors()' routerLink='/system-sensor-list'>{{trans('systems.titles.sensors')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.resetFireSensors()' routerLink='/reset-fire-sensors'>{{trans('systems.titles.resetFireSensors')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.see.users()' routerLink='/users'>{{trans('systems.titles.users')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.edit.cameras()' routerLink='/camera-list'>{{trans('systems.menu.myCameras')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.supports.advancedSettings() && showAdvanced()' routerLink='/advanced-settings'>{{trans('systems.menu.advancedSettings')}}</app-button-single-line-arrow>
    </div>
    <div *ngIf='systems.activeSystemHelper.can.transfer()' id='system-config-transfer-background' class="group-background"></div>
    <div *ngIf='systems.activeSystemHelper.can.transfer()' id='system-config-transfer'>
        <app-button-single-line-arrow (click)="goToTransfer()">{{trans('systems.menu.transferSystem')}}</app-button-single-line-arrow>
    </div>
    <div *ngIf='systems.activeSystemHelper.can.delete.system()' id='system-config-delete-background' class="group-background"></div>
    <div *ngIf='systems.activeSystemHelper.can.delete.system()' id='system-config-delete'>
        <app-button-single-line-arrow *ngIf='systems.activeSystemHelper.can.delete.system()' routerLink='/system-delete'>{{trans('systems.titles.removeSystem')}}</app-button-single-line-arrow>
    </div>
</div>