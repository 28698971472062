import { Directive, Input, OnChanges, OnDestroy, SimpleChanges, TemplateRef } from '@angular/core';
import { TeleportService } from './teleport.service';

@Directive({
  selector: 'ng-template[appTeleport]',
})
export class TeleportDirective implements OnDestroy, OnChanges {
  @Input() appTeleport: any;
  constructor(private template: TemplateRef<any>, private teleport: TeleportService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const target = changes.appTeleport;
    if (!target) return;

    if (target.previousValue) this.teleport.deactivateTeleport(this.template, target.previousValue);
    if (target.currentValue) this.teleport.activateTeleport(this.template, target.currentValue);
  }

  ngOnDestroy(): void {
    this.teleport.deactivateTeleport(this.template, this.appTeleport);
  }
}
