import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SystemListService {
  private currentlyVisibleControlsForSystem = 0;
  private onHideSystemControlsSource = new Subject<any>();
  /**
   * Nurodo jog sistemos valdymo mygtukai turi buti paslėpiami
   *
   * @param systemId number Sistema kurios mygtukai paslėpiami
   */
  public onHideSystemControls = this.onHideSystemControlsSource.asObservable();

  private onShowSystemControlsSource = new Subject<any>();
  /**
   * Nurodo jog sistemos valdymo mygtukai turi buti parodomi
   *
   * @param systemId number Sistema kurios mygtukai parodomi
   */
  public onShowSystemControls = this.onShowSystemControlsSource.asObservable();

  constructor() {}

  public showSystemControlsFor(systemId: number) {
    if (this.currentlyVisibleControlsForSystem !== 0) {
      this.onHideSystemControlsSource.next(this.currentlyVisibleControlsForSystem);
    }
    this.currentlyVisibleControlsForSystem = systemId;
    this.onShowSystemControlsSource.next(systemId);
  }

  public hideSystemControlsFor(systemId: number) {
    if (this.currentlyVisibleControlsForSystem === 0) {
      return;
    }
    if (this.currentlyVisibleControlsForSystem !== systemId) {
      return;
    }
    this.currentlyVisibleControlsForSystem = 0;
    this.onHideSystemControlsSource.next(systemId);
  }
}
