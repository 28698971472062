import { Component, OnInit } from '@angular/core';
import { LocaleService } from 'src/app/api/locale.service';
import { LanguageService } from 'src/app/services/language.service';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { ValidatorBuilder } from 'src/app/ui/validator';
import { countryList } from 'src/environments/countrylist';
type FormData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  companyName: string;
  companyVat: string;
  companyAddress: string;
  companyCity: string;
  companyCountry: string;
};
const companyRegisterValidator = new ValidatorBuilder<FormData>()
  .required('firstName', (_, c: CompanyRegisterComponent) => c.trans('auth.companyRegister.error.firstName'))
  .required('lastName', (_, c: CompanyRegisterComponent) => c.trans('auth.companyRegister.error.lastName'))
  .required('email', (_, c: CompanyRegisterComponent) => c.trans('auth.companyRegister.error.email'))
  .regex('email', /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/, (_, c: CompanyRegisterComponent) => c.trans('auth.companyRegister.error.invalidEmail'))
  .required('phone', (_, c: CompanyRegisterComponent) => c.trans('auth.companyRegister.error.phone'))
  .required('companyName', (_, c: CompanyRegisterComponent) => c.trans('auth.companyRegister.error.companyName'))
  .required('companyAddress', (_, c: CompanyRegisterComponent) => c.trans('auth.companyRegister.error.companyAddress'))
  .required('companyCity', (_, c: CompanyRegisterComponent) => c.trans('auth.companyRegister.error.companyCity'))
  .required('companyCountry', (_, c: CompanyRegisterComponent) => c.trans('auth.companyRegister.error.companyCountry'));

@Component({
  templateUrl: './company-register.component.html',
  styleUrls: ['./company-register.component.scss'],
})
export class CompanyRegisterComponent implements OnInit {
  public val = companyRegisterValidator.build().bindContext(this);
  public data: FormData = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    companyName: '',
    companyVat: '',
    companyAddress: '',
    companyCity: '',
    companyCountry: '',
  };
  public step = 0;
  public countryList: DropdownItem<string>[];
  public readonly trans = (key: string) => this.ls.get(key);

  constructor(private locale: LocaleService, private ls: LanguageService) {
    const labaler = (code: string) => this.locale.getCountryName(code);
    this.countryList = countryList.map((code) => ({
      value: code,
      label: labaler,
    }));
    this.countryList.unshift({
      value: '',
      label: 'Select country',
      default: true,
    });
  }

  ngOnInit(): void {}

  public async onNext() {
    if (!(await this.val.validate(this.data, ['firstName', 'lastName', 'email', 'phone']))) return;
    this.step++;
  }

  public async submit() {
    if (!(await this.val.validate(this.data))) return;
    // TODO: Send email via API
    const uri = new URL('mailto:karolis@trikdis.lt');
    uri.searchParams.append('subject', 'New company registration');
    uri.searchParams.append('body', this.generateEmail().replace(' ', '%20'));
    console.log(uri.toString());
    window.location.assign(uri.toString());
  }

  private generateEmail() {
    return `===DO NOT EDIT BELOW THIS LINE===
Hello Trikdis team,
I would like to register a new company.
Here are the details:
  First name: ${this.data.firstName}
  Last name: ${this.data.lastName}
  Email: ${this.data.email}
  Phone: ${this.data.phone}
  Company name: ${this.data.companyName}
  Company VAT: ${this.data.companyVat}
  Company address: ${this.data.companyAddress}
  Company city: ${this.data.companyCity}
  Company country: ${this.data.companyCountry}
===DO NOT EDIT ABOVE THIS LINE===

`;
  }
}
