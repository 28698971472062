import { Component, Input, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
})
export class IconButtonComponent implements OnInit {
  @Input() icon: string | SafeUrl;
  @Input() label?: string;

  public get iconUrl(): string {
    return `url('${this.icon}')`;
  }

  constructor() {}

  ngOnInit(): void {}
}
