import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { BasePopupComponent } from '../base-popup/base-popup.component';

@Component({
  selector: 'app-disable-mobile-data-popup',
  templateUrl: './disable-mobile-data-popup.component.html',
  styleUrls: ['./disable-mobile-data-popup.component.scss'],
})
export class DisableMobileDataPopupComponent implements OnInit {
  @ViewChild('basePopup') basePopup: BasePopupComponent;
  @Input() isReminder = false;
  public onCancel = undefined;
  public onOk = undefined;

  constructor(public l: LanguageService) {}

  ngOnInit(): void {}

  public show() {
    this.basePopup.show();
  }

  public close() {
    this.basePopup.hidePopup();
  }

  public doCancel() {
    if (this.onCancel !== undefined) {
      this.onCancel();
    }
  }

  public doOk() {
    if (this.onOk !== undefined) {
      this.onOk();
    }
  }
}
