import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customize-zones',
  templateUrl: './customize-zones.component.html',
  styleUrls: ['./customize-zones.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomizeZonesComponent extends LanguageAware implements OnInit {
  public maxNameLen = 100;

  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    this.tag = 'CustomZone';
    this.headerBar.showHeader({
      headerText: this.trans('systems.labels.nameTheZones'),
      backUrl: '/customize-areas',
    });
    this.footerBar.showFooter(this.trans('general.skip'), this.trans('general.save'), false, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.onSkip();
    };
    this.footerBar.onButton2Click = () => {
      that.onNext();
    };
    this.background.setGray();
    this.progressBar.showProgress();
    if (this.systems.activeSystem.hwType === 'SP3') {
      this.maxNameLen = 23;
    }
  }

  ngOnInit(): void {
    this.progressBar.setProgressData(95, 100, 2);
  }

  private onSkip() {
    this.router.navigate([this.g.getHomeUrl()]);
  }

  private onNext() {
    this.router.navigate([this.g.getHomeUrl()]);
  }

  public submitNewName(zoneQueueNumber: number, zoneName: string) {
    if (zoneName === this.systems.activeSystem.zones.find((z) => z.queue_no === zoneQueueNumber).name) {
      return;
    }
    if (zoneName === '') {
      this.toaster.postError(this.trans('systems.errors.noZoneName'));
      return;
    }

    const that = this;
    const systemId = this.systems.activeSystem.id;
    const oldName = this.systems.activeSystem.zones.find((z) => z.queue_no === zoneQueueNumber).name;
    this.api
      .post(
        '/change-zone-name',
        {
          system_id: systemId,
          zone_number: zoneQueueNumber,
          zone_name: zoneName,
        },
        true
      )
      .subscribe(
        (result) => {
          if (!result.success) {
            that.toaster.postError(result.error);
            that.revertNameChange(systemId, zoneQueueNumber, oldName);
            return;
          }
          that.saveName(systemId, zoneQueueNumber, zoneName);
          that.log('gut');
        },
        (error) => {
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
          that.revertNameChange(systemId, zoneQueueNumber, oldName);
        }
      );
  }

  private revertNameChange(systemId: number, zoneNumber: number, oldName: string) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const zone = system.zones.find((z) => z.queue_no === zoneNumber);
    if (zone === undefined) {
      return;
    }
    zone.name = oldName;
    this.systems.saveActiveSystem();
  }

  private saveName(systemId: number, zoneNumber: number, newName: string) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const zone = system.zones.find((z) => z.queue_no === zoneNumber);
    if (zone === undefined) {
      return;
    }
    zone.name = newName;
  }
}
