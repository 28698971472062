<div class="white_round" id="cpgs_container">
    <app-name-value [nameText]="trans('settings.global.label.show_zones')">
        <app-toggle-button [buttonId]="'cpgs_sz'"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('settings.global.label.main_user_edit_zones')">
        <app-toggle-button [buttonId]="'cpgs_muez'"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('settings.global.label.main_user_add_zones')">
        <app-toggle-button [buttonId]="'cpgs_muaz'"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('settings.global.label.main_user_enable_view_users')">
        <app-toggle-button [buttonId]="'cpgs_muevu'"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('settings.global.label.main_user_enable_edit_users')">
        <app-toggle-button [buttonId]="'cpgs_mueeu'"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('settings.global.label.g10_pgm_output_as_area')">
        <app-toggle-button [buttonId]="'cpgs_ggoaa'"></app-toggle-button>
    </app-name-value>
</div>