import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { LanguageService } from './language.service';
import { LoggerService } from '../api/logger.service';
import { SystemsService } from './systems.service';

@Injectable({
  providedIn: 'root',
})
export class SensorService {
  private tag = 'SensorService';
  private watcher = null;
  private timeOut = null;
  private watchingSystemId = 0;
  public isWorking = false;

  constructor(private ss: SystemsService, private rs: ApiRequestService, private lang: LanguageService, private l: LoggerService) {
    this.l.log('+', this.tag);
    const that = this;
    this.ss.onActiveSystemChange.subscribe(() => {
      that.handleSystemChange();
    });
  }

  public watchSensors(singleTime?: boolean) {
    if (this.watcher !== null && (singleTime === undefined || !singleTime)) {
      this.l.log('Bandyta paleisti watcher, bet jis jau aktyvus.');
      return;
    }
    if (this.ss.activeSystem === null) {
      this.l.log('Kažkodėl nėra aktyvios sistemos, nors norima startuoti sensorių stebėjimą.', this.tag);
      return;
    }
    if (singleTime === undefined || !singleTime) {
      const shouldAutoRefresh = localStorage.getItem('sensor_auto_refresh_' + this.ss.activeSystem.id);
      if (shouldAutoRefresh !== null && shouldAutoRefresh === '0') {
        this.l.log('Nustatymuose pažymėta, kad sensorių nereikia automatiškai atnaujinti. Stabdom.', this.tag);
        this.stopSensorWatch();
        return;
      }
    }
    const that = this;
    this.watchingSystemId = this.ss.activeSystem.id;
    this.l.log('Pradedam sensorių stebijimą sistemai: ', this.tag, this.watchingSystemId);
    this.timeOut = setTimeout(() => {
      that.performSensorRead(singleTime);
      that.timeOut = null;
    }, 1000);
    if (!singleTime) {
      this.watcher = setInterval(() => {
        that.performSensorRead();
      }, 60000); // Kas minutę tikrinam sensorių duomenis.
    }
  }

  public stopSensorWatch() {
    this.isWorking = false;
    if (this.timeOut !== null) {
      clearTimeout(this.timeOut);
      this.timeOut = null;
    }
    if (this.watcher === null) {
      return;
    }
    clearInterval(this.watcher);
    this.watcher = null;
    this.l.log('Sensorių stebėjimas sustabdytas.', this.tag);
  }

  private handleSystemChange() {
    if (this.watcher !== null) {
      if (this.ss.activeSystem === null || this.ss.activeSystem.id !== this.watchingSystemId) {
        this.l.log('Pasikeitė sistema. Stabdom watcher.', this.tag);
        this.stopSensorWatch();
      }
    }
  }

  private performSensorRead(singleTime?: boolean) {
    const shouldAutoRefresh = localStorage.getItem('sensor_auto_refresh_' + this.ss.activeSystem.id);
    if (singleTime === undefined || !singleTime) {
      if (shouldAutoRefresh !== null && shouldAutoRefresh === '0') {
        this.l.log('Nustatymuose pažymėta, kad sensorių nereikia automatiškai atnaujinti. Stabdom.', this.tag);
        this.stopSensorWatch();
        return;
      }
    }
    this.isWorking = true;
    this.l.log('Užklausiam sensorių duomenų sistemai: ', this.tag, this.watchingSystemId);
    const that = this;
    const systemId = this.watchingSystemId;
    this.rs.post('/get-sensor-values', { systemId: this.watchingSystemId }, true).subscribe(
      (result) => {
        that.isWorking = false;
        if (result.success) {
          that.l.log('Sensorių duomenys gauti.', that.tag, result);
          if (that.watchingSystemId !== systemId) {
            that.l.log('Jau visai kita sistema. Todėl nieko nedarom su rezultatu.', that.tag);
            return;
          }
          that.applySensorValues(result.sensors, systemId);
        } else {
          that.l.log('Nepavyko gauti sensorių duomenų. ' + result.error, that.tag);
        }
      },
      (error) => {
        that.isWorking = false;
        that.l.log('Klaida gaunant sensorių duomenis.', that.tag);
      }
    );
  }

  private applySensorValues(sensors: any, systemId: number) {
    const system = this.ss.getSystem(systemId);
    if (system === undefined) {
      return; // Ištrinta jau.
    }
    for (const iSensor of sensors) {
      for (const iSystemSensor of system.sensors) {
        if (iSystemSensor.queue_no === iSensor.queue_no) {
          const isBad = Math.abs(iSensor.status) > 9999;
          iSystemSensor.enabled = iSensor.enabled === 1;
          iSystemSensor.temperature = isBad ? '' : iSensor.status.toString();
          if (isBad) {
            this.l.log('Kažkokia negera reikšmė sensoriui. ', this.tag, {
              sensorius: iSystemSensor.queue_no,
              val: iSensor.status,
            });
          }
        }
      }
    }
  }
}
