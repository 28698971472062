import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { PopupOptions } from '../../models/popup-options';
import { PopupType } from '../../models/popup-type';
import { SlideoutItem } from '../../models/slideout-item';
import { PopupService } from '../../services/popup.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
  animations: [
    trigger('showSlideout', [
      state('hidden', style({ opacity: 0, transform: 'translateY(100%)' })),
      state('visible', style({ opacity: 1, transform: 'translateY(0px)' })),
      transition('hidden => visible', animate('0.15s linear')),
    ]),
  ],
})
export class PopupComponent implements OnInit, OnDestroy {
  private hexKeys = '0123456789abcdefABCDEF';
  private numKeys = '0123456789';
  public timeZoneHours = [
    '-12',
    '-11',
    '-10',
    '-9',
    '-8',
    '-7',
    '-6',
    '-5',
    '-4',
    '-3',
    '-2',
    '-1',
    '+0',
    '+1',
    '+2',
    '+3',
    '+4',
    '+5',
    '+6',
    '+7',
    '+8',
    '+9',
    '+10',
    '+11',
    '+12',
  ];
  public currentPopup = PopupType.None;
  public currentSlideout = PopupType.None;
  public PopupType = PopupType;
  private subscribers = {
    popup: {
      show: null,
      hide: null,
    },
    slideOut: {
      show: null,
      hide: null,
    },
  };
  public popupOptions: PopupOptions = null;
  public slideOutOptions = null;
  public popupVisible = false;
  public slideOutVisible = false;
  public slideoutState = 'hidden';

  public passwordInClearText = false;

  public field1Value;
  public field2Value;
  public toggleValue;
  public field1IsFocused = false;
  public field2IsFocused = false;
  public timeZoneHourValue = '';

  constructor(private pp: PopupService, public l: LanguageService) {
    const that = this;
    this.subscribers.popup.show = this.pp.onShowPopup.subscribe((opt) => {
      that.showPopup(opt.options, opt.popupType);
    });
    this.subscribers.popup.hide = this.pp.onHidePopup.subscribe(() => {
      that.hidePopup();
    });
    this.subscribers.slideOut.show = this.pp.onShowSlideout.subscribe((opt) => {
      that.showSlideout(opt.options, opt.popupType);
    });
    this.subscribers.slideOut.hide = this.pp.onHideSlideout.subscribe(() => {
      that.hideSlideout();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    this.subscribers.popup.show.unsubscribe();
    this.subscribers.popup.hide.unsubscribe();
    this.subscribers.slideOut.show.unsubscribe();
    this.subscribers.slideOut.hide.unsubscribe();
  }

  public cancelPopup() {
    if (this.popupOptions === null) {
      return;
    }
    if (this.popupOptions.onCancel === undefined) {
      this.hidePopup();
      return;
    }
    this.popupOptions.onCancel();
    this.hidePopup();
  }

  public submitPopup() {
    if (this.popupOptions === null) {
      return;
    }
    if (this.popupOptions.onSubmit === undefined) {
      this.hidePopup();
      return;
    }
    const type: PopupType = this.currentPopup;
    const submitCallback = this.popupOptions.onSubmit;
    if (this.popupOptions.field1 !== undefined && this.popupOptions.field1.isHex !== undefined && this.popupOptions.field1.isHex) {
      this.field1Value = this.field1Value.toUpperCase();
    }
    if (this.popupOptions.field2 !== undefined && this.popupOptions.field2.isHex !== undefined && this.popupOptions.field2.isHex) {
      this.field2Value = this.field2Value.toUpperCase();
    }
    this.hidePopup();

    if (type === PopupType.Password || type === PopupType.String || type === PopupType.IPAddress) {
      submitCallback(this.field1Value);
    } else if (type === PopupType.Number || type === PopupType.NumberWithUnitList || type === PopupType.TimeZone) {
      submitCallback({
        toggle: this.toggleValue,
        value1: this.field1Value,
        value2: this.field2Value,
      });
    }
  }

  private showPopup(options: PopupOptions, type: PopupType) {
    this.popupOptions = options;
    this.currentPopup = type;
    this.popupVisible = true;
    if (options.field1.oldValue !== undefined) {
      this.field1Value = options.field1.oldValue;
      if (options.field1.maxLen !== undefined && this.field1Value.length > options.field1.maxLen) {
        this.field1Value = this.field1Value.substr(0, options.field1.maxLen);
      }
    }
    if (options.field2 !== undefined && options.field2.oldValue !== undefined) {
      this.field2Value = options.field2.oldValue;
    }
    if (options.toggle !== undefined) {
      this.toggleValue = options.toggle.oldValue;
    }
  }

  private hidePopup() {
    this.currentPopup = PopupType.None;
    this.popupVisible = false;
    this.popupOptions = null;
  }

  private showSlideout(options: any, type: PopupType) {
    this.slideOutVisible = true;
    this.slideOutOptions = options;
    this.currentSlideout = type;
    setTimeout(() => {
      this.slideoutState = 'visible';
    }, 50);
  }

  private hideSlideout() {
    this.slideOutVisible = false;
    this.slideOutOptions = null;
    this.slideoutState = 'hidden';
  }

  public analyzeKeyPressField(event, field, whichField: number) {
    if (field.isHex !== undefined && field.isHex) {
      if (!this.hexKeys.includes(event.key)) {
        event.preventDefault();
      }
    } else if (this.currentPopup === PopupType.Number || this.currentPopup === PopupType.NumberWithUnitList || this.currentPopup === PopupType.TimeZone) {
      if (!this.numKeys.includes(event.key)) {
        event.preventDefault();
        return;
      }
      let combinedValue = '';
      if (whichField === 1) {
        combinedValue = this.field1Value + event.key;
      } else if (whichField === 2) {
        combinedValue = this.field2Value + event.key;
      }
      if (combinedValue !== '') {
        let intValue = parseInt(combinedValue, 10);
        if (field.minValue !== undefined && intValue < field.minValue) {
          intValue = field.minValue;
          if (whichField === 1) {
            this.field1Value = intValue.toString();
          } else if (whichField === 2) {
            this.field2Value = intValue.toString();
          }
          event.preventDefault();
        } else if (field.maxValue !== undefined && intValue > field.maxValue) {
          intValue = field.maxValue;
          if (whichField === 1) {
            this.field1Value = intValue.toString();
          } else if (whichField === 2) {
            this.field2Value = intValue.toString();
          }
          event.preventDefault();
        }
      }
    } else if (this.currentPopup === PopupType.IPAddress) {
      if (!this.numKeys.includes(event.key) && event.key !== '.' && event.key !== ',') {
        event.preventDefault();
        return;
      }
      let pressedKey = event.key;
      let addPressedkeyOnSuccess = false;
      if (pressedKey === ',') {
        event.preventDefault();
        pressedKey = '.';
        addPressedkeyOnSuccess = true;
      }
      if (this.field1Value[this.field1Value.length - 1] === '.' && pressedKey === '.') {
        event.preventDefault();
        return;
      }
      const ipParts = this.field1Value.split('.');
      if (ipParts.length >= 4 && pressedKey === '.') {
        event.preventDefault();
        return;
      }

      if (addPressedkeyOnSuccess) {
        this.field1Value = this.field1Value + pressedKey;
      }
    }
  }

  public submitSlideout(selection: number) {
    if (this.slideOutOptions === null) {
      return;
    }
    if (this.slideOutOptions.onSubmit === undefined) {
      this.hideSlideout();
      return;
    }
    const submitCallback = this.slideOutOptions.onSubmit;
    this.hideSlideout();
    submitCallback(selection);
  }

  public submitSlideoutValue(selection: SlideoutItem) {
    if (this.slideOutOptions === null) {
      return;
    }
    if (this.slideOutOptions.onSubmit === undefined) {
      this.hideSlideout();
      return;
    }
    const submitCallback = this.slideOutOptions.onSubmit;
    this.hideSlideout();
    submitCallback(selection);
  }

  public cancelSlideout() {
    if (this.slideOutOptions === null) {
      return;
    }
    if (this.slideOutOptions.onCancel === undefined) {
      this.hideSlideout();
      return;
    }
    this.slideOutOptions.onCancel();
    this.hideSlideout();
  }

  public selectTimeZoneHour() {
    const that = this;
    this.pp.showSlideout(
      {
        headerText: this.l.get('configurators.labels.timeZone'),
        items: this.timeZoneHours,
        onSubmit: (res) => {
          that.field1Value = res;
        },
      },
      PopupType.Slideout
    );
  }
}
