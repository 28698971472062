import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/api/request.service';
import { LoginService } from 'src/app/login/login.service';
import { LanguageService } from 'src/app/services/language.service';
import { ValidatorBuilder } from 'src/app/ui/validator';

const nameValidator = new ValidatorBuilder<{ name: string }>().regex('name', /^.{1,23}$/, (_, c: SetNameComponent) => c.trans('auth.error.nameTooLong'));

@Component({
  templateUrl: './set-name.component.html',
  styleUrls: ['./set-name.component.scss'],
})
export class SetNameComponent implements OnInit {
  public val = nameValidator.build().bindContext(this);
  public name = '';
  public readonly trans = (key: string) => this.ls.get(key);
  constructor(private req: RequestService, private router: Router, private login: LoginService, private ls: LanguageService) {}

  ngOnInit(): void {}

  public async next() {
    if (!(await this.val.validate(this))) return;
    const geoInfo = await this.req.ip.getGeoInfo().toPromise();
    await this.req.user
      .updateProfile({
        name: this.name,
        country: geoInfo.country_code,
      })
      .toPromise();
    this.router.navigate(this.login.determineLoginDestination(), { replaceUrl: true });
  }
}
