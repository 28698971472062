import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { IPCom } from 'src/app/models/ipcom';
import { PopupType } from 'src/app/models/popup-type';
import { SlideoutItem } from 'src/app/models/slideout-item';
import { UserRole } from 'src/app/models/user-role';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-su-ipcom',
  templateUrl: './su-ipcom.component.html',
  styleUrls: ['./su-ipcom.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SuIpcomComponent extends LanguageAware implements OnInit {
  @ViewChild('statusMessage') statusMessage;
  public ipcomDataChanged = false;
  public ipcomItem: IPCom = null;
  private companyList: SlideoutItem[] = [];
  public companyText = '';
  private fileToUpload: File | null = null;
  public fileToUploadUrl: any = null;
  public logoChanged = false;
  private loadedLogoSize = null;
  public regionText = '';

  constructor(cdRef: ChangeDetectorRef, private es: EditSystemService, private sanitizer: DomSanitizer, private pp: PopupService, private router: Router) {
    super(cdRef);
    this.ipcomItem = this.es.getEditableComponent(TEditableComponent.IPcom);
    this.headerBar.showHeader({
      headerText: this.ipcomItem.id === 0 ? this.trans('systems.titles.addNewIpComServer') : this.trans('systems.titles.editIpComServer'),
      backUrl: '/sadmin/ipcoms',
      actionButtonText: this.ipcomItem.id === 0 ? '' : this.trans('general.delete'),
    });
    this.background.setGray();
    if (this.us.companies.length === 0) {
      this.loadCompanies();
    } else {
      this.generateCompanyList();
    }
    const that = this;
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    this.headerBar.onBackClicked = () => {
      that.revert();
    };
    this.footerBar.onButton1Click = () => {
      that.revert();
      that.router.navigate(['/sadmin/ipcoms']);
    };
    this.footerBar.onButton2Click = () => {
      if (that.ipcomDataChanged) {
        that.doSave();
      } else if (that.logoChanged) {
        that.saveLogo();
      }
    };
    this.headerBar.onActionButtonClicked = () => {
      that.delete();
    };
    const reg = this.us.currentUser.regions.find((r) => r.id === this.ipcomItem.region);
    if (reg !== undefined) {
      this.regionText = reg.name;
    } else {
      this.regionText = this.trans('general.none');
    }
  }

  ngOnInit(): void {}

  private rebuildCompanyText() {
    const company = this.companyList.find((c) => c.value === this.ipcomItem.company_id);
    if (company === undefined) {
      this.companyText = this.trans('settings.ipcom.companyNotAssigned');
    } else {
      this.companyText = company.text;
    }
  }

  private loadCompanies() {
    const that = this;
    this.api.post('/all-users', {}, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          that.us.companies = result.users.filter((u) => u.role === UserRole.Company);
          that.generateCompanyList();
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }

  private generateCompanyList() {
    this.companyList = [
      {
        text: this.trans('settings.ipcom.companyNotAssigned'),
        value: 0,
      },
    ];
    for (const iCompany of this.us.companies) {
      this.companyList.push({
        text: iCompany.name,
        value: iCompany.id,
      });
    }
    this.rebuildCompanyText();
  }

  public changeCompany() {
    const that = this;
    this.pp.showSlideout(
      {
        headerText: this.trans('settings.ipcom.companySelect'),
        items: this.companyList,
        onSubmit: (res) => {
          that.ipcomItem.company_id = res.value;
          that.rebuildCompanyText();
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public handleNewFile(fileArray: FileList) {
    const file = fileArray.item(0);
    const mimeType = file.type;
    if (!mimeType.startsWith('image/svg') && mimeType !== 'image/png') {
      this.toaster.postError(this.trans('settings.errors.notAnImage'));
      return;
    }
    const that = this;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ($event) => {
      if (mimeType.startsWith('image/svg')) {
        that.fileToUploadUrl = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
      } else {
        that.fileToUploadUrl = reader.result;
      }
    };
    this.fileToUpload = file;
    this.logoChanged = true;
  }

  public imageLoaded(img) {
    this.loadedLogoSize = {
      width: img.naturalWidth,
      height: img.naturalHeight,
    };
  }

  private revert() {
    if (this.ipcomItem.id === 0) {
      return;
    }
    const original = this.es.getComponentBeforeModification(TEditableComponent.IPcom);
    this.ipcomItem.ipcom_logo = original.ipcom_logo;
    if (this.ipcomItem.ipcom_logo !== null) {
      this.ipcomItem.ipcom_logo = this.sanitizer.bypassSecurityTrustUrl(this.ipcomItem.logoDataForStorage);
    }
    this.ipcomItem.peer_name = original.peer_name;
    this.ipcomItem.host = original.host;
    this.ipcomItem.port = original.port;
    this.ipcomItem.user_name = original.user_name;
    this.ipcomItem.password = original.password;
    this.ipcomItem.company_id = original.company_id;
    this.ipcomItem.name = original.name;
    this.ipcomItem.description = original.description;
    this.ipcomItem.region = original.region;
    this.ipcomItem.own_ipcom = original.own_ipcom;
  }

  private doSave() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/edit/ipcom', this.ipcomItem, true).subscribe(
      (result) => {
        if (result.success) {
          if (that.ipcomItem.id === 0) {
            that.ipcomItem.id = result.id;
            that.us.currentUser.ipcoms.push(that.ipcomItem);
          }
          if (that.logoChanged) {
            that.saveLogo();
          } else {
            that.miniStatus.hide();
            that.router.navigate(['/sadmin/ipcoms']);
          }
        } else {
          that.miniStatus.hide();
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }

  public saveLogo() {
    const that = this;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const headers = this.api.getAuthorizationHeader();
    headers.append('Content-type', 'multipart/form-data');
    const formData = new FormData();
    formData.append('id', this.ipcomItem.id.toString());
    formData.append('logo_image', this.fileToUpload, this.fileToUpload.name);
    this.api
      .httpSender()
      .post(this.api.getUrl('/edit/ipcom/logo'), formData, { headers })
      .subscribe(
        (result) => {
          that.miniStatus.hide();
          if ((result as any).success) {
            that.ipcomItem.ipcom_logo = (result as any).logoUrl;
            that.router.navigate(['/sadmin/ipcoms']);
          } else {
            that.toaster.postError((result as any).error);
          }
        },
        (error) => {
          that.toaster.postError(that.trans('auth.error.serverSideError'));
          that.miniStatus.hide();
        }
      );
  }

  public testConnection() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.statusMessage.showLoader(this.trans('settings.ipcom.testing'));
    const that = this;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/test-ipcom', this.ipcomItem, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          if (result.status) {
            that.statusMessage.showSuccess(that.trans('settings.ipcom.testComplete'), that.trans('settings.connected'));
          } else {
            that.statusMessage.showFailure(that.trans('settings.ipcom.testComplete'), that.trans('settings.notConnected'));
          }
        } else {
          that.statusMessage.showFailure(that.trans('settings.ipcom.testComplete'), result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
        that.statusMessage.showFailure(that.trans('settings.ipcom.testComplete'), that.trans('auth.errors.serverSideError'));
      }
    );
  }

  public removeLogo() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    if (this.ipcomItem.id === 0 || (this.ipcomItem.ipcom_logo === null && this.fileToUploadUrl !== null)) {
      this.ipcomItem.ipcom_logo = null;
      this.fileToUpload = null;
      this.fileToUploadUrl = null;
      this.loadedLogoSize = null;
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/delete/ipcom/logo', { id: this.ipcomItem.id }, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          that.ipcomItem.ipcom_logo = null;
          that.fileToUpload = null;
          that.fileToUploadUrl = null;
          that.loadedLogoSize = null;
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }

  public delete() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/delete/ipcom', { id: this.ipcomItem.id }, true).subscribe(
      (result) => {
        if (result.success) {
          that.us.currentUser.ipcoms = that.us.currentUser.ipcoms.filter((ipc) => ipc.id !== that.ipcomItem.id);
          that.miniStatus.hide();
          that.router.navigate(['/sadmin/ipcoms']);
        } else {
          that.miniStatus.hide();
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }

  public changeRegion() {
    const that = this;
    const regions = [];
    for (const iRegion of this.us.currentUser.regions) {
      regions.push({
        value: iRegion.id,
        text: iRegion.name,
      });
    }
    this.pp.showSlideout(
      {
        headerText: this.trans('settings.ipcom.region'),
        items: regions,
        onSubmit: (res) => {
          that.ipcomItem.region = res.value;
          that.regionText = res.text;
          that.ipcomDataChanged = true;
        },
      },
      PopupType.SlideoutWithValue
    );
  }
}
