import { Injectable, OnDestroy } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ReactionBasicData, ReactionData, ReactionGroupData } from 'src/api/v3/common';
import { RegionService } from '../region.service';
import { RequestService } from '../request.service';

@Injectable({
  providedIn: 'root',
})
export class ReactionService implements OnDestroy {
  public readonly reactions = new Map<number, ReactionData>();
  public readonly basicReactions = new Map<number, ReactionBasicData>();
  public readonly reactionGroups = new Map<number, ReactionGroupData>();
  public readonly reactionGroupReactions = new Map<number, Set<number>>();
  private regionChangeSubscribtion = this.region.onRegionChanged.subscribe(() => {
    this.reactions.clear();
    this.basicReactions.clear();
    this.reactionGroups.clear();
    this.reactionGroupReactions.clear();
  });
  private isLoadingReactions = false;
  constructor(private req: RequestService, private region: RegionService, private sanitizer: DomSanitizer) {}

  ngOnDestroy(): void {
    this.regionChangeSubscribtion.unsubscribe();
  }

  public ingestReaction(reaction?: ReactionData): ReactionData | undefined {
    if (!reaction) return;
    this.reactions.set(reaction.id, reaction);
    if (!this.reactionGroupReactions.has(reaction.group_id)) {
      this.reactionGroupReactions.set(reaction.group_id, new Set());
    }
    this.reactionGroupReactions.get(reaction.group_id).add(reaction.id);
    return reaction;
  }

  public ingestBasicReaction(reaction?: ReactionBasicData): ReactionBasicData | undefined {
    if (!reaction) return;
    this.basicReactions.set(reaction.id, reaction);
    return reaction;
  }

  public ingestReactionGroup(reactionGroup?: ReactionGroupData): ReactionGroupData | undefined {
    if (!reactionGroup) return;
    this.reactionGroups.set(reactionGroup.id, reactionGroup);
    return reactionGroup;
  }

  public async getReactions() {
    if (this.isLoadingReactions) return;
    this.isLoadingReactions = true;
    const result = await this.req.misc.getReactions().toPromise();
    if (result.success) {
      result.icons.map((reaction) => this.ingestBasicReaction(reaction));
    }
    this.isLoadingReactions = false;
  }

  public getReactionIcon(reactionId: number): SafeUrl {
    if (this.reactions.has(reactionId)) {
      const reaction = this.reactions.get(reactionId);
      const url = new URL(reaction.image, this.region.regionBaseUrl);
      // url.search = 'fix';
      return this.sanitizer.bypassSecurityTrustUrl(url.href);
    }
    if (this.basicReactions.has(reactionId)) {
      const reaction = this.basicReactions.get(reactionId);
      const url = new URL(reaction.iconPath, this.region.regionBaseUrl);
      // url.search = 'fix';
      return this.sanitizer.bypassSecurityTrustUrl(url.href);
    }
    this.getReactions();
    return '';
  }
}
