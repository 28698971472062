import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, ViewChild } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { NewSystemService } from 'src/app/services/new-system.service';
import { TDeviceUser } from 'src/app/models/device-user';
import { THomeTheme } from 'src/app/models/home-theme';
import { MessageboxComponent } from 'src/app/popups/messagebox/messagebox.component';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-add-system-details',
  templateUrl: './add-system-details.component.html',
  styleUrls: ['./add-system-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddSystemDetailsComponent extends LanguageAware implements OnInit {
  @ViewChild('messageBox') messageBox: MessageboxComponent;
  public systemName = '';
  public systemAddress = '';
  public theme: THomeTheme = null;
  public mpass = '';
  private controlsEnabled = true;
  public foreignRegionName = '';
  private deviceTransferInProgress = false;
  private deviceTransferTimeout = null;
  private transferWaitingCounter = 10;

  constructor(cdRef: ChangeDetectorRef, private router: Router, public ns: NewSystemService, private ds: DeviceService) {
    super(cdRef);
    this.tag = 'Details';
    this.progressBar.showProgress();
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.addSystem'),
      backUrl: '/add-system',
    });
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('auth.buttons.next'), true, false);
    this.background.setGray();
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.cancelClick();
    };
    this.footerBar.onButton2Click = () => {
      that.nextClick();
    };
    this.systemName = this.ns.getName();
    if (this.systemName === '') {
      this.systemName = this.trans('systems.labels.system') + ' ' + (this.systems.getSystemCount() + 1);
      this.ns.setName(this.systemName);
    }
    this.systemAddress = this.ns.getAddress();
    this.mpass = this.ns.getMpass();
    this.theme = this.ns.getTheme();
  }

  ngOnInit() {
    this.progressBar.setProgressData(2, 7);
    const icon: HTMLElement = document.querySelector('#details-background-icon');
    if (icon === null) {
      this.log('neradom ikonos komponento.');
    }
    if (this.theme.fullBackground !== '') {
      icon.style.background = this.theme.fullBackground;
    } else {
      icon.style.background = 'linear-gradient(180deg, ' + this.theme.startColor + ' 0%, ' + this.theme.endColor + ' 100%)';
    }
  }

  private cancelClick() {
    if (this.deviceTransferTimeout !== null) {
      clearTimeout(this.deviceTransferTimeout);
    }
    this.toaster.clear();
    this.ns.setUID('');
    this.miniStatus.hide();
    this.router.navigate([this.g.getHomeUrl()]);
  }

  public nextClick() {
    this.toaster.clear();
    if (!this.controlsEnabled) {
      this.miniStatus.flash();
      return;
    }
    if (this.systemName === '') {
      this.toaster.postError(this.trans('systems.errors.noSystemName'));
      return;
    }
    this.ns.setName(this.systemName);
    this.ns.setMpass(this.mpass);
    this.deviceTransferInProgress = false;
    this.transferWaitingCounter = 10;
    this.beginCommunication();
  }

  private beginCommunication() {
    this.controlsEnabled = false;
    this.miniStatus.show(this.trans('systems.statuses.communicating'));
    const that = this;
    this.api
      .post(
        '/get-system-info-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass() === '' ? '123456' : this.ns.getMpass(),
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          that.log(result);
          if (result.success) {
            that.progressBar.setProgressData(1, 8, 1);
            that.ns.setDeviceInfo(result.data);
            if (result.data.isInForeignRegion && !that.deviceTransferInProgress) {
              that.log('Modulis kitame regione. Bandom peradresuoti.');
              that.foreignRegionName = result.data.foreignRegion;
              if (result.data.supported_commands.indexOf('.18.') !== -1 || result.data.supported_commands.endsWith('.18')) {
                that.moveDevice();
              } else if (result.data.supported_commands.indexOf('.22.') !== -1 || result.data.supported_commands.endsWith('.22')) {
                that.moveDevice();
              } else {
                that.log('Modulis nepalaiko reikiamos komandos');
                that.controlsEnabled = true;
                that.miniStatus.hide();
                that.messageBox.show();
                that.progressBar.setProgressData(2, 7);
              }
            } else if (result.data.isInForeignRegion && that.deviceTransferInProgress) {
              this.transferWaitingCounter--;
              that.log('Modulis vis dar kitame regione. Laukiame kol persijungs.');
              if (this.transferWaitingCounter === 0) {
                that.controlsEnabled = true;
                that.miniStatus.hide();
                that.progressBar.setProgressData(2, 7);
                return;
              }
              that.deviceTransferTimeout = setTimeout(() => {
                that.beginCommunication();
              }, 10000);
            } else {
              that.ns.setIpcom(result.srv);
              that.getDeviceStatus();
            }
          } else {
            if (this.deviceTransferInProgress) {
              this.transferWaitingCounter--;
              if (this.transferWaitingCounter > 0) {
                that.deviceTransferTimeout = setTimeout(() => {
                  that.beginCommunication();
                }, 10000);
                return;
              }
            }
            that.controlsEnabled = true;
            that.miniStatus.hide();
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.controlsEnabled = true;
          that.miniStatus.hide();
        }
      );
  }

  public backgroundClick() {
    this.ns.setName(this.systemName);
    this.ns.setMpass(this.mpass);
    this.router.navigate(['/home-background-selection']);
  }

  public locationClick() {
    this.ns.setName(this.systemName);
    this.ns.setMpass(this.mpass);
    this.router.navigate(['/system-location']);
  }

  private getDeviceStatus() {
    const that = this;
    this.api
      .post(
        '/get-system-status-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass() === '' ? '123456' : this.ns.getMpass(),
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (response) => {
          that.log(response);
          if (response.success) {
            that.progressBar.setProgressData(2, 8, 1);
            that.ns.setDeviceStatus(response.data);
            const info = that.ns.getDeviceInfo();
            const hwIdInt = parseInt(info.hwId, 16);
            if (Object.keys(that.ns.getDeviceStatus().areas).length === 0) {
              if ([0x18, 0x19, 0x1c, 0x27, 0x2e, 0x30, 0x31, 0x38, 0x39, 0x3a, 0x3c, 0x3e, 0x3f, 0x41, 0x42, 0x43, 0x44, 0x45, 0x46, 0x47, 0x48].includes(hwIdInt)) {
                that.miniStatus.hide();
                that.ns.setDirectControl(false);
                that.writeSystemToDb('/add-system-indirect');
              } else if ([0x21, 0x24, 0x2a, 0x2c, 0x37].includes(hwIdInt)) {
                // kiti moduliai kurie gali netureti sriciu
                that.ns.setDirectControl(false);
                that.writeSystemToDb('/add-system-complete');
              } else {
                that.controlsEnabled = true;
                that.miniStatus.hide();
                that.toaster.postError(that.trans('systems.errors.deviceHasNoAreas'));
              }
            } else {
              that.ns.setDirectControl(true);
              that.getUsers();
            }
          } else {
            that.controlsEnabled = true;
            that.miniStatus.hide();
            /** Paradox centrales */
            if (that.ns.getDeviceInfo().panel >= 0x20 && that.ns.getDeviceInfo().panel < 0x30) {
              that.toaster.postError(that.trans('systems.errors.badPcDownloadCode'));
              that.router.navigate(['/wiring-diagrams']);
            } else {
              that.progressBar.setProgressData(2, 7);
              that.toaster.postError(response.error);
            }
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.controlsEnabled = true;
          that.miniStatus.hide();
        }
      );
  }

  private getUsers() {
    // Praleidziam jeigu modulis nepalaiko vartotoju duomenu nuskaitymo.
    if (this.ns.getDeviceInfo().supported_commands.indexOf('.14.') === -1) {
      this.getAreaZones(); // einam prie zonu nuskaitymo.
      return;
    }

    const that = this;
    this.api
      .post(
        '/get-system-users-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass() === '' ? '123456' : this.ns.getMpass(),
          supportedCommands: this.ns.getDeviceInfo().supported_commands,
          hwId: this.ns.getDeviceInfo().hwId,
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            that.progressBar.setProgressData(3, 8, 1);
            if (result.charset !== undefined) {
              that.ns.systemCharset = result.charset;
            }
            if (result.maxUsers !== undefined) {
              that.ns.systemMaxUsers = result.maxUsers;
            }
            if (that.ns.getDeviceInfo().supported_commands.indexOf('.14.') !== -1 && result.users !== undefined) {
              // CG17 arba SP3
              const users: TDeviceUser[] = [];
              for (const iUser of result.users) {
                // Iš modulio atejo sritys HEX formate
                // O DB mes laikom ilga string, kuriame kiekvienas simbolis tai bitas, o pozicija, tai srities numeris.
                // Todėl konvertuojam HEX į bit string
                // CG17, SP3 moduliuose max 8 sritys, bet G moduliai gali dirbti su centralėmis, kurios turi šimtus sričių
                let intAreas = parseInt(iUser.areas, 16);
                let strAreas = '';
                for (let i = 1; i < 9; i++) {
                  /* eslint-disable no-bitwise */
                  strAreas += (intAreas & 0x01) === 1 ? '1' : '0';
                  intAreas >>= 1;
                  /* eslint-enable no-bitwise */
                }
                users.push({
                  id: 0,
                  name: iUser.name,
                  phone: iUser.phone,
                  code: iUser.code,
                  zone_number: iUser.id,
                  areas: strAreas,
                  email: iUser.email !== undefined ? iUser.email : '',
                  can_edit_users: false,
                  enable_data: 0,
                  pgms: -1,
                  present: false,
                  protegus_user_id: 0,
                  schedule_no: 0,
                  isOwner: false,
                  ownerPermissions: {},
                  canSeeEvents: false,
                });
                that.ns.cgUsers = users;
              }
              that.ns.userListRead = true;
            } else if (that.ns.getDeviceInfo().supported_commands.indexOf('.11.') && result.users !== undefined) {
              // GV17, Gator
            }
            that.log(result);
            that.getAreaZones();
          } else {
            that.progressBar.setProgressData(2, 7);
            that.controlsEnabled = true;
            that.miniStatus.hide();
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.controlsEnabled = true;
          that.miniStatus.hide();
        }
      );
  }

  private writeSystemToDb(completionUrl: string) {
    const that = this;
    this.api.post('/create-system', this.ns.get(), true).subscribe(
      (result) => {
        if (result.success) {
          that.systems.setCurrentSystemFromRaw(result.system);
          if (that.ns.getDeviceInfo().supported_commands.indexOf('.11.') !== -1) {
            this.ds.performReRead(
              this.systems.activeSystem.id,
              (m) => {
                that.miniStatus.hide();
                that.controlsEnabled = true;
                that.progressBar.hideProgress();
                setTimeout(() => {
                  that.router.navigate([completionUrl]);
                }, 0);
              },
              (e) => {
                if (e !== '') {
                  that.toaster.postError(e);
                }
                that.miniStatus.hide();
                that.progressBar.setProgressData(2, 7);
                that.controlsEnabled = true;
              },
              (s, p) => {
                if (p === null || p.max === 0) {
                  return;
                }
                // Reikia padaryti kad tilptu i 6.
                const ratio = 6 / p.max;
                const current = Math.ceil(ratio * p.current);
                console.log('ratio, current, p.current', ratio, current, p.current);
                that.progressBar.setProgressData(2 + current, 8, 1);
              }
            );
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.hideProgress();
            setTimeout(() => {
              that.router.navigate([completionUrl]);
            }, 0);
          }
        } else {
          that.progressBar.setProgressData(2, 7);
          that.toaster.postError(result.error);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      },
      (error) => {
        that.progressBar.setProgressData(2, 7);
        that.miniStatus.hide();
        that.controlsEnabled = true;
      }
    );
  }

  private getAreaZones() {
    if (this.ns.getDeviceInfo().supported_commands.indexOf('.E.') === -1) {
      this.writeSystemToDb('/add-system-complete'); // Nepalaiko komandos, tai nieko nelieka kaip tik viską įrašyti į db
      return;
    }
    const areaCount = this.ns.getAreas().length;
    let totalProgress = 8 + areaCount;
    let currentProgress = Math.floor(totalProgress * 0.4);
    totalProgress++;
    this.progressBar.setProgressData(currentProgress, totalProgress, 1);
    currentProgress++;

    const that = this;
    let areaToRead = -1;
    const readOneArea = () => {
      const areaBefore = areaToRead;
      for (const iArea of that.ns.getAreas()) {
        if (iArea.queue_no > areaToRead) {
          areaToRead = iArea.queue_no;
          break;
        }
      }
      if (areaToRead === areaBefore) {
        that.getZoneNames();
        return;
      }
      that.log('skaitom srities zonas', areaToRead);

      that.api.get(`/area-zones?system_id=0&area_number=${areaToRead}&imei=${that.ns.getUID()}&mpass=${that.ns.getMpass()}&srv=${this.ns.getIpcom()}`, true).subscribe(
        (result) => {
          if (result.success) {
            that.log(result);
            const zoneNumbers = Object.keys(result.zones);
            for (const iZoneNumber of zoneNumbers) {
              const zoneWeHave = that.ns.getZone(parseInt(iZoneNumber, 10));
              if (zoneWeHave !== undefined) {
                zoneWeHave.areas.push(areaToRead);
              }
            }
            that.progressBar.setProgressData(currentProgress, totalProgress, 1);
            currentProgress++;
            readOneArea();
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.setProgressData(2, 7);
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
    };

    readOneArea();
  }

  private getZoneNames() {
    const info = this.ns.getDeviceInfo();
    const hwIdInt = parseInt(info.hwId, 16);
    if (hwIdInt !== 0x3b) {
      // Nepalaiko zonų pavadinimų komandos.
      this.writeSystemToDb('/add-system-complete');
      return;
    }
    this.progressBar.setProgressData(4, 8, 1);

    this.log('Skaitom zonų pavadinimus.');
    const that = this;
    this.api
      .post(
        '/get-system-zone-names-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass(),
          hwId: info.hwId,
          charset: this.ns.systemCharset,
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            for (let i = 0; i < result.data.length; i++) {
              if (result.data[i] === '') {
                continue;
              }
              const zone = this.ns.getZone(i + 1);
              if (zone !== undefined) {
                zone.name = result.data[i];
              }
            }
            that.getAreaNames();
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.setProgressData(2, 7);
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
  }

  private getAreaNames() {
    const info = this.ns.getDeviceInfo();
    const hwIdInt = parseInt(info.hwId, 16);
    if (hwIdInt !== 0x3b) {
      // Nepalaiko sričių pavadinimų komandos.
      this.writeSystemToDb('/add-system-complete');
      return;
    }
    this.progressBar.setProgressData(5, 8, 1);

    this.log('Skaitom sričių pavadinimus.');
    const that = this;
    this.api
      .post(
        '/get-system-area-names-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass(),
          hwId: info.hwId,
          charset: this.ns.systemCharset,
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            for (let i = 0; i < result.data.length; i++) {
              if (result.data[i] === '') {
                continue;
              }
              const area = this.ns.getArea(i + 1);
              if (area !== undefined) {
                area.name = result.data[i];
              }
            }
            that.getSensorNames();
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.setProgressData(2, 7);
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
  }

  private getSensorNames() {
    const info = this.ns.getDeviceInfo();
    const hwIdInt = parseInt(info.hwId, 16);
    if (hwIdInt !== 0x3b) {
      // Nepalaiko sričių pavadinimų komandos.
      this.writeSystemToDb('/add-system-complete');
      return;
    }
    this.progressBar.setProgressData(6, 8, 1);

    this.log('Skaitom sensorių pavadinimus.');
    const that = this;
    this.api
      .post(
        '/get-system-sensor-names-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass(),
          hwId: info.hwId,
          charset: this.ns.systemCharset,
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            for (let i = 0; i < result.data.length; i++) {
              if (result.data[i] === '') {
                continue;
              }
              const sensor = this.ns.getDeviceStatus().sensors.find((s) => s.queue_no === i + 1);
              if (sensor !== undefined) {
                sensor.name = result.data[i];
              }
            }
            that.getOutputNames();
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.setProgressData(2, 7);
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
  }

  private getOutputNames() {
    const info = this.ns.getDeviceInfo();
    const hwIdInt = parseInt(info.hwId, 16);
    if (hwIdInt !== 0x3b) {
      // Nepalaiko sričių pavadinimų komandos.
      this.writeSystemToDb('/add-system-complete');
      return;
    }
    this.progressBar.setProgressData(7, 8, 1);

    this.log('Skaitom pgm pavadinimus.');
    const that = this;
    this.api
      .post(
        '/get-system-output-names-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass(),
          hwId: info.hwId,
          charset: this.ns.systemCharset,
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            for (let i = 0; i < result.data.length; i++) {
              if (result.data[i] === '') {
                continue;
              }
              const pgm = this.ns.getPgm(i + 1);
              if (pgm !== null) {
                pgm.name = result.data[i];
              }
            }
            that.writeSystemToDb('/add-system-complete');
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.setProgressData(2, 7);
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
  }

  private moveDevice() {
    const that = this;
    this.api
      .post(
        '/transfer-device',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass(),
          srv: this.ns.getIpcom(),
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            that.ns.setIpcom(0);
            that.deviceTransferInProgress = true;
            that.deviceTransferTimeout = setTimeout(() => {
              that.beginCommunication();
            }, 10000);
          } else {
            that.miniStatus.hide();
            that.controlsEnabled = true;
            that.progressBar.setProgressData(2, 7);
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.progressBar.setProgressData(2, 7);
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
  }
}
