import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-reset-fire-sensors',
  templateUrl: './reset-fire-sensors.component.html',
  styleUrls: ['./reset-fire-sensors.component.scss'],
})
export class ResetFireSensorsComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
    const refType = localStorage.getItem('ref');
    const backUrl = refType === null || refType === 'home' ? this.g.getHomeUrl() : refType === 'conf' ? '/system-configuration' : this.g.getHomeUrl();
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.resetFireSensors'),
      backUrl,
    });
    this.background.setGray();
  }

  ngOnInit(): void {}

  public performReset() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('systems.statuses.resetingFireSensors'));
    const that = this;
    this.api.post('/reset-fire-sensors', { systemId: this.systems.activeSystem.id }, true).subscribe(
      (result) => {
        if (result.success) {
          that.toaster.postSuccess(that.trans('general.titleSuccess'));
        } else {
          that.toaster.postError(result.error);
        }
        that.miniStatus.hide();
      },
      (error) => {
        that.miniStatus.hide();
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
      }
    );
  }
}
