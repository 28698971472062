import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class BackBehaviourService {
  /**
   * Nurodo, jog vietoj įprasto _back_ mygtuko funkcionalumo, reikia naudoti konkrečia nuorodą, nurodytą __backUrl__ lauke.
   */
  public overrideBackBehaviour = false;
  /**
   * URL į kurį bus einama, kai vartotojas paspaus _back_ mygtuką. Veikia tik kai __overrideBackBehaviour__ yra __true__.
   */
  public backUrl = '';
  // /**
  //  * Jeigu lange yra _Cancel_ mygtukas, tai jo paspaudimas nuves į langą, kurį nurodo __backUrl__. Šio serviso laukas __overrideBackBehaviour__ privalo būti __true__.
  //  */
  // public affectCancellation = true;
  // /**
  //  * Jeigu lange yra _Save_, _Confirm_ arba _Done_ mygtukai,
  //  * tai jų paspaudimas nuves į langą, kurį nurodo __backUrl__. Šio serviso laukas __overrideBackBehaviour__ privalo būti __true__.
  //  */
  // public affectSave = true;

  constructor() {}

  public reset() {
    this.overrideBackBehaviour = false;
    this.backUrl = '';
    // this.affectCancellation = true;
    // this.affectSave = true;
  }
}
