import { Injectable } from '@angular/core';
import { ToastType } from '../models/toaster-type';
import { LanguageService } from './language.service';
import { LoggerService } from '../api/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  private show = false;
  private currentType = ToastType.None;
  private currentText = '';
  private currentTitle = '';
  private currentCloseVisibility = false;
  private messages: {
    message: string;
    title?: string;
    type: ToastType;
    showClose: boolean;
    timeout: number;
  }[] = [];
  private timer = null;
  constructor(private ls: LanguageService, private l: LoggerService) {}

  public bind() {}

  /** Parodo sėkmės pranešimą, kuris nusileidžia iš ekrano viršaus. */
  /** @param message Pranešimas, kuris bus rodomas. */
  /**> Pagal nutylėjimą nebus rodomas X mygtukas, o pranešimo rodymo trukmė __3__ sekundės
   *
   * > Norint nustatyti kitokią trukmę arba nerodyti X mygtuko, reikia naudoti funkciją __post__
   */
  public postSuccess(message: string) {
    this.post(message, ToastType.Success, true);
  }

  /** Parodo klaidos pranešimą, kuris nusileidžia iš ekrano viršaus. */
  /** @param message Pranešimas, kuris bus rodomas. */
  /**> Pagal nutylėjimą bus rodomas X mygtukas, o pranešimo rodymo trukmė __20__ sekundžių
   *
   * > Norint nustatyti kitokią trukmę arba nerodyti X mygtuko, reikia naudoti funkciją __post__
   */
  public postError(message: string, eid?: string) {
    this.post(message, ToastType.Error, true, 20000, eid);
  }

  /** Parodo pranešimą, kuris nusileidžia iš ekrano viršaus. */
  /** @param message Pranešimas, kuris bus rodomas. */
  /** @param type _(nebūtinas)_ Pranešimo tipas. Pagal nutylėjimą __ToastType.Info__ */
  /** @param closable _(nebūtinas)_ Nurodo ar pranešime reikia rodyti X mygtuką. Pagal nutylėjimą __false__ */
  /** @param time _(nebūtinas)_ Pranešimo rodymo trukmė milisekundėmis. Pagal nutylėjimą __9000__ ms */
  public post(message: string, type?: ToastType, closable?: boolean, time?: number, title?: string) {
    if (type === undefined) {
      type = ToastType.Info;
    }
    if (closable === undefined) {
      closable = false;
    }
    if (time === undefined) {
      time = 9000;
    }

    this.messages.push({
      message,
      type,
      showClose: closable,
      timeout: time,
      title,
    });
    this.l.log('+1 = ' + this.messages.length, 'Toaster');

    this.startWorker();
  }

  private startWorker() {
    if (this.messages.length === 0) {
      this.l.log('Neliko toaster zinuciu.', 'Toaster');
      return;
    }

    if (this.timer !== null) {
      // this.l.log('Siuo metu vis dar rodomas pranesimas, todel naujas nebus rodomas, kol nedings esamas.', 'Toaster');
      return;
    }

    const toastItem = this.messages.pop();
    this.currentCloseVisibility = toastItem.showClose;
    this.currentText = toastItem.message;
    this.l.log('Rodoma: ' + this.currentText, 'Toaster');
    this.currentType = toastItem.type;
    if (this.currentType === ToastType.Info) {
      this.currentTitle = this.ls.get('general.titleInfo');
    } else if (this.currentType === ToastType.Error) {
      this.currentTitle = this.ls.get('general.titleError');
    } else if (this.currentType === ToastType.Success) {
      this.currentTitle = this.ls.get('general.titleSuccess');
    } else if (this.currentType === ToastType.Warning) {
      this.currentTitle = this.ls.get('general.titleWarning');
    }

    if (toastItem.title) {
      this.currentTitle += ' - ' + toastItem.title;
    }
    if (this.currentType !== ToastType.None) {
      this.show = true;
    }
    this.timer = setTimeout(() => {
      this.currentType = ToastType.None;
      this.show = false;

      setTimeout(() => {
        this.timer = null;
        this.startWorker();
      }, 250);
    }, toastItem.timeout);
  }

  /** Uždaro šiuo metu rodomą pranešimą */
  public close() {
    if (this.timer !== null) {
      clearTimeout(this.timer);

      setTimeout(() => {
        this.currentType = ToastType.None;
        this.show = false;
        this.timer = null;
        this.startWorker();
      }, 250);
    }
  }

  /** Uždaro šiuo metu rodomą pranešimą ir pašalina visus kitus susikaupusius */
  public clear() {
    if (this.timer !== null) {
      this.messages = [];
      clearTimeout(this.timer);
      this.currentType = ToastType.None;
      this.show = false;
      this.timer = null;
    }
  }

  public isVisible(): boolean {
    return this.show;
  }

  public isInfo(): boolean {
    return this.currentType === ToastType.Info;
  }

  public isError(): boolean {
    return this.currentType === ToastType.Error;
  }

  public isWarning(): boolean {
    return this.currentType === ToastType.Warning;
  }

  public isSuccess(): boolean {
    return this.currentType === ToastType.Success;
  }

  public getText(): string {
    return this.currentText;
  }

  public getTitle(): string {
    return this.currentTitle;
  }

  public closeVisible(): boolean {
    return this.currentCloseVisibility;
  }

  public getMessageCount(): number {
    return this.messages.length;
  }

  public showCounter(): boolean {
    return this.messages.length > 0;
  }
}
