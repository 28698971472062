import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { IconsService } from 'src/app/services/icons.service';
import { FcmService } from 'src/app/services/fcm.service';

@Component({
  selector: 'app-password-login',
  templateUrl: './password-login.component.html',
  styleUrls: ['./password-login.component.scss'],
})
export class PasswordLoginComponent extends LanguageAware implements OnInit {
  public controlsEnabled = true;
  public password = '';

  constructor(cdRef: ChangeDetectorRef, private router: Router, private fcm: FcmService, private ic: IconsService) {
    super(cdRef);
    this.tag = 'PassLogin';
    this.progressBar.showProgress();
    this.headerBar.showHeader({
      backUrl: '/email',
      showLogo: true,
    });
    this.footerBar.showFooter(this.trans('auth.buttons.next'), '', true, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.passwordSubmit();
    };
  }

  ngOnInit() {
    this.progressBar.setProgressData(3, 4);
  }

  public passwordSubmit() {
    if (!this.controlsEnabled) {
      return;
    }
    this.log('Password submit');

    this.miniStatus.show(this.trans('auth.loggingIn'));
    this.controlsEnabled = false;
    this.password = this.password.trim();
    if (this.password === null || this.password === '') {
      this.toaster.postError(this.trans('validation.required').replace(':attribute', this.trans('auth.form.password')));
      this.controlsEnabled = true;
      this.miniStatus.hide();
      return;
    }

    const lastSystemId = localStorage.getItem('last_system');
    const that = this;
    this.api
      .post(
        '/login',
        {
          email: this.us.currentUser.email,
          password: this.password,
          remember: false,
          last_system: lastSystemId,
        },
        false
      )
      .subscribe(
        (response) => {
          if (response.success) {
            that.l.log('Login pavyko', that.tag);
            that.api.setToken(response.token);
            that.us.setActiveUser(response);
            that.us.setLoginType('default');
            that.us.userPassword = that.password;
            if (that.us.currentUser.name === '') {
              that.router.navigate(['/name-register']);
            } else {
              if (response.lastSystem !== undefined) {
                that.systems.setCurrentSystemFromRaw(response.lastSystem);
              }
              that.systems.loadSystems();
              that.router.navigate([this.g.getHomeUrl()]);
              that.ic.checkIcons();
            }
            that.fcm.requestForToken();
          } else {
            that.l.log('Login nepavyko', that.tag);
            that.toaster.postError(response.error);
          }
          that.controlsEnabled = true;
          that.miniStatus.hide();
        },
        (error) => {
          that.controlsEnabled = true;
          that.log('', error);
          that.toaster.postError(that.trans('auth.failed'));
          that.miniStatus.hide();
        }
      );
  }

  public resetPassword() {
    if (!this.controlsEnabled) {
      if (this.miniStatus.isVisible()) {
        this.miniStatus.flash();
      }
      return;
    }
    this.controlsEnabled = false;
    const that = this;
    this.miniStatus.show(this.trans('general.sending'));
    this.api
      .post(
        '/reset',
        {
          lang: this.ls.selectedLanguage.value,
          email: this.us.currentUser.email,
        },
        false
      )
      .subscribe(
        (result) => {
          that.miniStatus.hide();
          if (result.success) {
            that.toaster.postSuccess(that.trans('auth.passwordResetSent'));
            that.router.navigate(['/email']);
          } else {
            that.toaster.postError(result.error);
          }
          that.controlsEnabled = true;
        },
        (error) => {
          this.log('', error);
          that.controlsEnabled = true;
          that.miniStatus.hide();
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
        }
      );
  }
}
