import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-base-popup',
  templateUrl: './base-popup.component.html',
  styleUrls: ['./base-popup.component.scss'],
})
export class BasePopupComponent implements OnInit {
  public popupVisible = false;
  @Input() canCancel = true;
  @Output() popupCancelled: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public cancelPopup() {
    if (!this.canCancel) {
      return;
    }
    this.popupCancelled.emit();
    this.hidePopup();
  }

  public hidePopup() {
    this.popupVisible = false;
  }

  public show() {
    this.popupVisible = true;
  }
}
