import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-cp-filtered-users',
  templateUrl: './cp-filtered-users.component.html',
  styleUrls: ['./cp-filtered-users.component.scss'],
})
export class CpFilteredUsersComponent extends LanguageAware implements OnInit {
  @ViewChild('searchPopup') userFind;

  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    this.headerBar.showHeader({
      showLogo: true,
      backUrl: this.g.getHomeUrl() + '/all-users',
    });
    this.background.setGray();
    const that = this;
    this.headerBar.onBackClicked = () => {
      that.us.filteredUsers = [];
    };
  }

  ngOnInit(): void {}

  public openFind() {
    this.userFind.show();
  }

  public close() {
    this.us.filteredUsers = [];
    this.router.navigate([this.g.getHomeUrl() + '/all-users']);
  }

  public editUser(userId: number) {
    this.router.navigate([this.g.getHomeUrl() + '/edit-user/' + userId + '/filter']);
  }
}
