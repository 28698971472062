import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { TUser } from 'src/app/models/user';
import { UserRole } from 'src/app/models/user-role';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-owner-privacy',
  templateUrl: './owner-privacy.component.html',
  styleUrls: ['./owner-privacy.component.scss'],
})
export class OwnerPrivacyComponent extends LanguageAware implements OnInit {
  public localUser: TUser;
  public privacySettings: any;

  constructor(cdRef: ChangeDetectorRef, private es: EditSystemService, private router: Router) {
    super(cdRef);
    this.localUser = this.es.getEditableComponent(TEditableComponent.User);
    if (this.localUser === null || (this.us.currentUser.role !== UserRole.SuperAdmin && this.us.currentUser.id !== this.localUser.id)) {
      this.router.navigate(['/users']);
      return;
    }
    this.background.setGray();
    this.headerBar.showHeader({
      headerText: this.trans('users.labels.privacySettings'),
      backUrl: '/users',
    });
    this.privacySettings = this.systems.activeSystem.privacyOfOwners.find((p) => p.user_id === this.localUser.id);
    this.es.beginComponentEdit(TEditableComponent.OwnerPrivacy, this.localUser.id, this.privacySettings);
    if (this.privacySettings === undefined) {
      this.privacySettings = {
        user_id: this.localUser.id,
        system_id: this.systems.activeSystem.id,
        show_email_admins: true,
        show_name_admins: true,
        show_phone_admins: true,
        show_email_users: true,
        show_name_users: true,
        show_phone_users: true,
      };
    }
    const that = this;
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    this.footerBar.onButton1Click = () => {
      that.router.navigate(['/users']);
    };
    this.footerBar.onButton2Click = () => {
      that.doSave();
    };
  }

  ngOnInit(): void {}

  private doSave() {
    const that = this;
    const before = this.es.getComponentBeforeModification(TEditableComponent.OwnerPrivacy);
    const userId = this.localUser.id;
    const systemId = this.systems.activeSystem.id;
    if (before === null) {
      this.systems.activeSystem.privacyOfOwners.push(this.privacySettings);
    }
    this.api
      .post(
        '/owner-privacy',
        {
          user_id: userId,
          system_id: systemId,
          privacy_settings: this.privacySettings,
        },
        true
      )
      .subscribe(
        (result) => {
          if (!result.success) {
            that.toaster.postError(result.error);
            that.revertChanges(userId, systemId, before);
          }
        },
        (error) => {
          that.revertChanges(userId, systemId, before);
        }
      );
    this.router.navigate(['/users']);
  }

  private revertChanges(userId: number, systemId: number, before: any) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const privacySetting = system.privacyOfOwners.find((p) => (p.user_id = userId));
    if (privacySetting === undefined && before !== null) {
      system.privacyOfOwners.push(before);
    } else if (privacySetting !== undefined && before === null) {
      system.privacyOfOwners = system.privacyOfOwners.filter((p) => p.user_id !== userId);
    } else if (privacySetting !== undefined && before !== null) {
      privacySetting.show_email_admins = before.show_email_admins;
      privacySetting.show_name_admins = before.show_name_admins;
      privacySetting.show_phone_admins = before.show_phone_admins;
      privacySetting.show_email_users = before.show_email_users;
      privacySetting.show_name_users = before.show_name_users;
      privacySetting.show_phone_users = before.show_phone_users;
    }
  }
}
