import { Injectable } from '@angular/core';
import { THomeTheme } from '../models/home-theme';

@Injectable({
  providedIn: 'root',
})
export class ThemesService {
  constructor() {}

  public resetToDefault() {
    document.documentElement.style.setProperty('--home-background-start', '#0070A7');
    document.documentElement.style.setProperty('--home-background-end', '#79BAD9');
  }

  public setHomeTheme(theme: THomeTheme) {
    if (theme.fullBackground !== '') {
      document.documentElement.style.background = theme.fullBackground;
    } else {
      document.documentElement.style.setProperty('--home-background-start', theme.startColor);
      document.documentElement.style.setProperty('--home-background-end', theme.endColor);
    }
  }
}
