import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { UserRole } from 'src/app/models/user-role';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-cp-edit-events',
  templateUrl: './cp-edit-events.component.html',
  styleUrls: ['./cp-edit-events.component.scss'],
})
export class CpEditEventsComponent extends LanguageAware implements OnInit {
  public reactionTexts = [];

  constructor(cdRef: ChangeDetectorRef, private router: Router, private es: EditSystemService) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.eventDescriptions'),
      backUrl: this.g.getHomeUrl(),
      showPlus: true,
    });
    this.background.setGray();
    const that = this;
    this.headerBar.onActionButtonClicked = () => {
      that.es.beginComponentEdit(TEditableComponent.EventDescription, 0, {
        default_name: '',
        id: 0,
        qualifier: 'E',
        event_code: '000',
        reaction_id: this.us.currentUser.reactions[0].id,
        active: false,
        company_id: this.us.currentUser.role === UserRole.Company ? this.us.currentUser.id : 0,
        area_event: true,
      });
      that.router.navigate([this.g.getHomeUrl() + '/add-event']);
    };
    this.loadDescriptions();
    this.es.endComponentEdit(TEditableComponent.EventDescription);
  }

  ngOnInit(): void {}

  private loadDescriptions() {
    if (this.us.eventDescriptions.length > 0) {
      this.buildReactionTexts();
      return;
    }
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/get-event-descriptions', {}, true).subscribe(
      (result) => {
        if (result.success) {
          that.us.eventDescriptions = result.list;
          that.buildReactionTexts();
        } else {
          that.toaster.postError(result.error);
        }
        that.miniStatus.hide();
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }

  public openEventForEditing(event: any) {
    this.es.beginComponentEdit(TEditableComponent.EventDescription, event.id, event);
    this.router.navigate([this.g.getHomeUrl() + '/edit-event']);
  }

  private buildReactionTexts() {
    this.reactionTexts = [];
    for (const iDesc of this.us.eventDescriptions) {
      const reaction = this.us.currentUser.reactions.find((r) => r.id === iDesc.reaction_id);
      if (reaction === undefined) {
        this.reactionTexts.push(this.trans('general.none'));
      } else {
        this.reactionTexts.push(reaction.default_name ? this.trans('reactionNames.reactions.' + reaction.name) : reaction.name);
      }
    }
  }
}
