import {
  AfterContentInit,
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';

const collapseLabel = <TValue>(label: string | ((value: TValue) => string), value: TValue) => (typeof label === 'function' ? label(value) : label);

export type DropdownItem<TValue = any> = {
  label: string | ((value: TValue) => string);
  value: TValue;
  default?: boolean;
};

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent<TValue = any> implements AfterViewInit, AfterViewChecked, OnDestroy {
  public collapseLabel = collapseLabel;
  @Input() label: string;
  private _value: TValue;
  public get value(): TValue {
    return this._value;
  }
  @Input()
  public set value(value: TValue) {
    this._value = value;
    // eslint-disable-next-line eqeqeq
    const itm = this.items.find((item) => item.value == value) ?? this.items.find((item) => item.default);
    this._textBox = collapseLabel(itm?.label, itm?.value);
  }
  @Input() items: DropdownItem<TValue>[] = [];
  @Input() hasError = false;
  @Output() valueChange = new EventEmitter<TValue>();

  private _textBox = '';
  private unlisten: (() => void) | null = null;
  public get textBox() {
    return this._textBox;
  }
  public set textBox(value) {
    this._textBox = value;
    this.updateCurrentActive();
  }
  public currentActive = 0;
  public shown = false;
  public scrollDirty = false;
  constructor(private element: ElementRef, private zone: NgZone, private render: Renderer2) {}

  public get shownItems() {
    if (!this.textBox) return this.items;
    const items = this.items.filter((item) => {
      const label = collapseLabel(item.label, item.value);
      return label.toLowerCase().startsWith(this.textBox.toLowerCase());
    });
    items.sort((a, b) => {
      const aLabel = collapseLabel(a.label, a.value);
      const bLabel = collapseLabel(b.label, b.value);
      if (aLabel.toLowerCase().startsWith(this.textBox.toLowerCase())) return -1;
      if (bLabel.toLowerCase().startsWith(this.textBox.toLowerCase())) return 1;
      return 0;
    });
    if (items[0]?.default) return this.items;
    return items;
  }

  public updateCurrentActive(change?: number) {
    if (change) {
      this.currentActive += change;
      this.scrollDirty = true;
      this.shown = true;
    }
    if (this.currentActive < 0) {
      this.currentActive = this.shownItems.length - 1;
      this.scrollDirty = true;
    }
    if (this.currentActive >= this.shownItems.length) {
      this.currentActive = 0;
      this.scrollDirty = true;
    }
  }

  ngAfterViewChecked(): void {
    if (!this.scrollDirty) return;
    this.scrollDirty = false;
    const el = this.element.nativeElement.querySelector('.item.active') as HTMLElement | null;
    if (!el) return;
    el.scrollIntoView({ block: 'nearest' });
  }

  public selectItem(itemIndex: number) {
    const val = this.shownItems[itemIndex];
    this.valueChange.emit(val.value);
    this.textBox = collapseLabel(val.label, val.value);
    this.shown = false;
  }

  public onFocus() {
    this.shown = true;
    const input = this.element.nativeElement.querySelector('input.input') as HTMLInputElement;
    input.setSelectionRange(0, input.value.length);
  }

  ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      const input = this.element.nativeElement.querySelector('input.input') as HTMLInputElement;
      this.unlisten = this.render.listen(input, 'blur', (event: FocusEvent) => {
        setTimeout(() => {
          this.zone.run(() => {
            const found = this.items.find((item) => item.value === this.value);
            if (found && this.textBox.toLowerCase() !== collapseLabel(found.label, found.value).toLowerCase()) {
              if (this.shownItems.length === 0) {
                const item = this.items.find((i) => i.default);
                this.textBox = collapseLabel(item.label, item.value);
                this.valueChange.emit(item.value);
              } else {
                this.selectItem(this.currentActive);
              }
            }
            this.shown = false;
          });
        }, 150);
      });
    });
  }

  ngOnDestroy(): void {
    this.unlisten?.();
  }
}
