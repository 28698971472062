import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TEventData } from '../models/event-data';
import { ApiRequestService } from './api-request.service';
import { SystemsService } from './systems.service';
import { UserService } from './user.service';
import { WebSocketService } from './websocket.service';

@Injectable({
  providedIn: 'root',
})
export class CompanyEventsService {
  private eventSubscriber = null;
  private eventForwardSource = new Subject<any>();
  public onEventForward = this.eventForwardSource.asObservable();

  constructor(ws: WebSocketService, private us: UserService, rs: ApiRequestService, private ss: SystemsService) {
    const that = this;
    this.eventSubscriber = ws.onEventReceived.subscribe((event: TEventData) => {
      that.handleEvent(event);
    });
    rs.onTokenReset.subscribe(() => {
      if (that.eventSubscriber !== null) {
        that.eventSubscriber.unsubscribe();
      }
    });
  }

  private handleEvent(event: TEventData) {
    const found = this.us.currentUser.lastSeenReactions.find((lsr) => lsr.reaction_id === event.reaction);
    if (found === undefined) {
      return;
    }
    found.unseen_events++;

    const foundReaction = this.us.currentUser.reactions.find((ur) => ur.id === event.reaction);
    if (foundReaction === undefined) {
      return;
    }
    if (foundReaction.group_id === 0) {
      return;
    }

    const foundGroup = this.us.currentUser.reactionGroups.find((g) => g.id === foundReaction.group_id);
    if (foundGroup === undefined) {
      return;
    }

    const system = this.ss.getSystem(event.systemId);
    if (system !== undefined && foundGroup.icon !== '' && !system.hasOwnProperty('activeGroupIcon')) {
      system.activeGroupIcon = foundGroup.icon;
      system.activeGroupId = foundGroup.id;

      this.ss.systems.sort((a, b) => (a.hasOwnProperty('activeGroupId') && !b.hasOwnProperty('activeGroupId') ? -1 : 1));
    }
    this.eventForwardSource.next({
      receivedEvent: event,
      reactionGroup: foundGroup,
    });
  }
}
