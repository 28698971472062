import { Injectable, Injector, OnDestroy } from '@angular/core';
import { SystemAreaData } from 'src/api/v3/common';
import { TAreaData } from 'src/app/models/area-data';
import { autoinject } from 'src/shim';
import { AuthService } from '../auth.service';
import { ZoneService } from './zone.service';

@Injectable({
  providedIn: 'root',
})
export class AreaService implements OnDestroy {
  public readonly areas = new Map<number, TAreaData>();
  public readonly areaZones = new Map<number, Set<number>>();
  public readonly systemAreas = new Map<number, Set<number>>();

  private zoneService = autoinject(this.injector, ZoneService);

  private cleanUpSubscription = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.areas.clear();
    this.areaZones.clear();
    this.systemAreas.clear();
  });
  constructor(private injector: Injector, private auth: AuthService) {}
  ngOnDestroy(): void {
    this.cleanUpSubscription.unsubscribe();
  }

  public ingestArea(area?: SystemAreaData | null): TAreaData | undefined {
    if (!area) return;

    const {
      show_keypad: showKeypad,
      can_bypass: canBypass,
      status_time: statusTime,
      last_person_name: lastPerson,
      alarm_in_memory: alarmed,
      alarm_time: alarmTime,
      alarm_type: alarmType,
      zones,
      ...rest
    } = area;

    zones?.map((zone) => this.zoneService().ingestZone(zone));

    const processedArea: TAreaData = {
      showKeypad,
      canBypass,
      statusTime,
      lastPerson,
      alarmed,
      alarmTime,
      alarmType,
      zones: [],
      ...rest,
    };

    Object.defineProperty(processedArea, 'zones', {
      get: () => [...this.areaZones.get(area.id).values()].map((id) => this.zoneService().zones.get(id)),
    });

    if (processedArea.status === 5 && statusTime) {
      // TODO: Add area status work
    }

    if (!this.areaZones.has(area.id)) {
      this.areaZones.set(area.id, new Set());
    }
    zones?.forEach((zone) => this.areaZones.get(area.id)?.add(zone.id));
    this.areas.set(area.id, processedArea);
    if (!this.systemAreas.has(area.system_id)) {
      this.systemAreas.set(area.system_id, new Set());
    }
    this.systemAreas.get(area.system_id)?.add(area.id);
    return processedArea;
  }
}
