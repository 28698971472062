import { Component, Injector } from '@angular/core';
import { ActivatedRouteSnapshot, ChildrenOutletContexts } from '@angular/router';
import { pageTransitionAnimation } from 'src/animations';
import { AppSettings } from 'src/app-settings';
import { AuthService } from './api/auth.service';
import { LoggerService } from './api/logger.service';
import { PhoneStateService } from './api/phone-state.service';
import { PlatformService } from './api/platform.service';
import { RegionService } from './api/region.service';
import { RequestService } from './api/request.service';
import { UserService } from './api/user.service';
import { UserService as USO } from './services/user.service';
import { UResolvable } from './guards/UResolvable';
import { AreaService } from './services/area.service';
import { FcmService } from './services/fcm.service';
import { FooterService } from './services/footer.service';
import { GlobalService } from './services/global.service';
import { HeaderService } from './services/header.service';
import { LanguageService } from './services/language.service';
import { ProgressService } from './services/progress.service';
import { RefreshService } from './services/refresh.service';
import { SystemsService } from './services/systems.service';
import { TimezoneService } from './services/timezone.service';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss'],
  animations: [pageTransitionAnimation],
})
export class MobileAppComponent implements UResolvable<typeof MobileAppComponent> {
  constructor(
    public progress: ProgressService,
    public header: HeaderService,
    public footer: FooterService,
    public logger: LoggerService,
    public areaService: AreaService,
    private g: GlobalService,
    ps: PlatformService,
    region: RegionService,
    psJsi: PhoneStateService, // Tik tam, kad uzkrautu.
    fcmS: FcmService, // Tik tam, kad uzkrautu.
    rf: RefreshService,
    private contexts: ChildrenOutletContexts
  ) {
    if (ps.isDesktop()) {
      document.body.onmousedown = (e) => {
        g.onSwipeStartedSource.next({
          x: e.clientX,
          y: e.clientY,
          leftPercent: (e.clientX / window.innerWidth) * 100,
          topPercent: (e.clientY / window.innerHeight) * 100,
        });
      };
      document.body.onmousemove = (e) => {
        g.onSwipeContinueSource.next({
          x: e.clientX,
          y: e.clientY,
          leftPercent: (e.clientX / window.innerWidth) * 100,
          topPercent: (e.clientY / window.innerHeight) * 100,
        });
      };
      document.body.onmouseup = (e) => {
        g.onSwipeEndedSource.next({
          x: e.clientX,
          y: e.clientY,
          leftPercent: (e.clientX / window.innerWidth) * 100,
          topPercent: (e.clientY / window.innerHeight) * 100,
        });
      };
    } else {
      document.body.ontouchstart = (e) => {
        g.onSwipeStartedSource.next({
          x: e.touches.item(0).clientX,
          y: e.touches.item(0).clientY,
          leftPercent: (e.touches.item(0).clientX / window.innerWidth) * 100,
          topPercent: (e.touches.item(0).clientY / window.innerHeight) * 100,
        });
      };
      document.body.ontouchend = (e) => {
        g.onSwipeEndedSource.next({
          x: e.changedTouches.item(0).clientX,
          y: e.changedTouches.item(0).clientY,
          leftPercent: (e.changedTouches.item(0).clientX / window.innerWidth) * 100,
          topPercent: (e.changedTouches.item(0).clientY / window.innerHeight) * 100,
        });
      };
      document.body.ontouchmove = (e) => {
        g.onSwipeContinueSource.next({
          x: e.touches.item(0).clientX,
          y: e.touches.item(0).clientY,
          leftPercent: (e.touches.item(0).clientX / window.innerWidth) * 100,
          topPercent: (e.touches.item(0).clientY / window.innerHeight) * 100,
        });
      };
    }
    document.documentElement.style.setProperty('--height-progress', '10px');
    document.documentElement.style.setProperty('--height-header', '77px');
    document.documentElement.style.setProperty('--height-footer', '52px');
    document.documentElement.style.setProperty('--color-primary', AppSettings.primaryColor);
    document.documentElement.style.setProperty('--color-secondary', AppSettings.secondaryColor);
    psJsi.onPhoneResumed.subscribe(() => {
      rf.doSilentRefresh();
    });

    if (AppSettings.regionsEnabled) {
      region.autoSelectRegion();
    }
  }
  public contentIsScrolling(e) {
    const elementHeight = (e.target as HTMLElement).clientHeight;
    const elementInnerHeight = (e.target as HTMLElement).scrollHeight;
    const scrolledSoFar = (e.target as HTMLElement).scrollTop;
    if (elementHeight + scrolledSoFar >= elementInnerHeight - 50) {
      this.g.onScrolledToBottomSource.next();
    }
  }

  public getPageTransitionAnimationData(): string {
    const primaryContext = this.contexts.getContext('primary');
    return primaryContext?.route?.snapshot?.data?.animation ?? primaryContext?.route?.snapshot?.routeConfig?.path ?? 'default';
  }

  public static async resolve(injector: Injector, route: ActivatedRouteSnapshot) {
    const g = injector.get(GlobalService);
    const l = injector.get(LoggerService);
    const promises: Promise<void>[] = [];
    promises.push(this.loadStage2(injector));
    await Promise.all(promises);
  }

  public static async loadStage2(injector: Injector) {
    const auth = injector.get(AuthService);
    const user = injector.get(UserService);
    const userOld = injector.get(USO);
    const ss = injector.get(SystemsService);
    const l = injector.get(LoggerService);
    if (user.user) return;
    if (auth.hasToken()) {
      const userData = await auth.loadUserData();
      userOld.currentUser = userData;
      await new Promise<void>((resolve) => ss.loadSystems(undefined, resolve));
    }
  }
}
