import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-cp-installer-accept',
  templateUrl: './cp-installer-accept.component.html',
  styleUrls: ['./cp-installer-accept.component.scss'],
})
export class CpInstallerAcceptComponent extends LanguageAware implements OnInit {
  public centerText = '';

  constructor(cdRef: ChangeDetectorRef, private router: Router, ar: ActivatedRoute) {
    super(cdRef);
    const hash = ar.snapshot.paramMap.get('hash');
    this.headerBar.showHeader({
      headerText: this.trans('users.labels.installerInvitation'),
    });
    this.background.setGray();
    this.footerBar.showFooter(this.trans('general.ok'), '', true, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.okClicked();
    };
    if (hash === null || hash === '') {
      this.centerText = this.trans('users.errors.incorrectInvitationData');
    } else {
      this.centerText = this.trans('general.pleaseWait');
      this.checkHash(hash);
    }
  }

  ngOnInit(): void {}

  private okClicked() {
    this.router.navigate(['/']);
  }

  private checkHash(hash: string) {
    const that = this;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/accept-invitation', { hash }, false).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          that.centerText = result.text;
        } else {
          that.toaster.postError(result.error);
          that.centerText = result.error;
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.centerText = that.trans('auth.errors.serverSideError');
        that.miniStatus.hide();
      }
    );
  }
}
