import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { RequestService } from 'src/app/api/request.service';
import { LoginService } from '../login.service';

@Injectable({
  providedIn: 'root',
})
export class ReactivateGuard implements CanActivate {
  registerEmailUrl = this.router.parseUrl('/login/register/email');
  constructor(private login: LoginService, private router: Router, private req: RequestService) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.login.email) {
      return this.registerEmailUrl;
    }
    const res = await this.req.login.resendActivation({ email: this.login.email }).toPromise();
    if (res.success === false) {
      // TODO: Show error message somewhere
      return this.registerEmailUrl;
    }
    this.login.activationToken = res.hash;
    return this.router.parseUrl('/login/register/code');
  }
}
