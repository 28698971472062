import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TPgmData } from 'src/app/models/pgm-data';
import { UserRole } from 'src/app/models/user-role';
import { LanguageAware } from '../../language-aware';

@Component({
  selector: 'app-home-outputs',
  templateUrl: './home-outputs.component.html',
  styleUrls: ['./home-outputs.component.scss'],
})
export class HomeOutputsComponent extends LanguageAware implements OnInit, OnDestroy {
  @Input() nameTextColor = '';
  @Input() flatButton = false;
  public visibleOutputs: TPgmData[] = [];
  private systemChangedListener = null;
  public changingWorkStatus = false;
  public canShowWorkButton = false;

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef, true, false);

    this.getVisibleOutputs();
    this.checkIsCanShowWorkButton();
    const that = this;
    this.systemChangedListener = this.systems.onActiveSystemChange.subscribe(() => {
      that.getVisibleOutputs();
      that.checkIsCanShowWorkButton();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.systemChangedListener !== null) {
      this.systemChangedListener.unsubscribe();
      this.systemChangedListener = null;
    }
  }

  private getVisibleOutputs() {
    const systemConfig = this.us.currentUser.homeConfigurations.find((c) => c.systemId === this.systems.activeSystem.id);
    if (systemConfig === undefined || systemConfig.visibleOutputs.length === 0) {
      this.visibleOutputs = this.systems.activeSystem.pgms.filter((o) => !o.control_area && o.enabled);
      return;
    }
    this.visibleOutputs = [];
    for (const iPgm of this.systems.activeSystem.pgms) {
      if (iPgm.enabled && !iPgm.control_area && systemConfig.visibleOutputs.indexOf(iPgm.queue_no) !== -1) {
        this.visibleOutputs.push(iPgm);
      }
    }
  }

  public getButtonSize(pgmId: number): string {
    const setting = localStorage.getItem('home_pgm_button_size_' + pgmId);
    if (setting === null) {
      return '11';
    }

    return setting;
  }

  public changeWorkStatus() {
    if (this.changingWorkStatus) {
      return;
    }
    this.changingWorkStatus = true;
    const that = this;
    const newStatus = !this.systems.activeSystem.amIWorking;
    this.api
      .post(
        '/set-user-access',
        {
          system_id: this.systems.activeSystem.id,
          protegus_user_id: this.us.currentUser.id,
          present: newStatus,
        },
        true
      )
      .subscribe(
        (result) => {
          if (!result.success) {
            that.toaster.postError(result.error);
          } else {
            that.systems.activeSystem.amIWorking = newStatus;
          }
          that.changingWorkStatus = false;
        },
        (error) => {
          that.changingWorkStatus = false;
        }
      );
  }

  private checkIsCanShowWorkButton() {
    const user = this.systems.activeSystem.device_users.find((du) => du.protegus_user_id === this.us.currentUser.id);
    this.canShowWorkButton = user !== undefined && user.id > 10; // Modulio adminai negali naudotis sita funkcija.
  }
}
