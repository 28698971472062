<app-base-popup #basePopup [canCancel]="canCloseByOverlay" (popupCancelled)="basePopupCancelled()">
    <div id="msgb_close_button" (click)="clickCancel()" *ngIf="showCloseButton"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 13.426 13.423"><path d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z" transform="translate(-11.285 -11.289)" fill="#000000"/></svg></div>
    <div id="msgb_header_text" *ngIf="headerText !== ''">{{headerText}}</div>
    <div id="msgb_text">{{messageContent}}</div>
    <div id="msgb_button_container">
        <div *ngIf="[MessageBoxButtons.Ok, MessageBoxButtons.OkCancel].includes(buttons)"><div class="msgb_button button" (click)="clickOk()">{{l.get('general.ok')}}</div></div>
        <div *ngIf="[MessageBoxButtons.YesNo, MessageBoxButtons.YesNoCancel].includes(buttons)"><div class="msgb_button button" (click)="clickYes()">{{l.get('general.yes')}}</div></div>
        <div *ngIf="[MessageBoxButtons.YesNo, MessageBoxButtons.YesNoCancel].includes(buttons)"><div class="msgb_button button" (click)="clickNo()">{{l.get('general.no')}}</div></div>
        <div *ngIf="[MessageBoxButtons.CustomButton1, MessageBoxButtons.CustomButton1Button2, MessageBoxButtons.CustomButton1Cancel].includes(buttons)"><div class="msgb_button button" (click)="clickButton1()">{{button1Text}}</div></div>
        <div *ngIf="[MessageBoxButtons.CustomButton1Button2].includes(buttons)"><div class="msgb_button button" (click)="clickButton2()">{{button2Text}}</div></div>
    </div>
    <div id="msgb_cancel" *ngIf="[MessageBoxButtons.OkCancel, MessageBoxButtons.YesNoCancel, MessageBoxButtons.CustomButton1Cancel].includes(buttons)"><div (click)="clickCancel()">{{l.get('general.cancel')}}</div></div>
</app-base-popup>