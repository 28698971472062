import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/app-settings';
import { AppRegion } from 'src/environments/environment.types';
import { LoggerService } from '../api/logger.service';
import { PlatformService } from './platform.service';

@Injectable({
  providedIn: 'root',
})
export class RegionService {
  private activeRegion: AppRegion;
  private _regionChangedSubject = new Subject<AppRegion>();
  public onRegionChanged = this._regionChangedSubject.asObservable();

  constructor(private logger: LoggerService, private ps: PlatformService) {
    this.activeRegion = environment.regions[0];
    this.autoSelectRegion();
    if (!environment.production) {
      (window as any).region = this;
    }
  }

  public get ActiveRegion(): AppRegion {
    return this.activeRegion;
  }

  public get regionId(): string {
    return this.activeRegion.id ?? '';
  }

  public get currentRegion(): AppRegion | string {
    return this.regionId ? this.regionId : this.activeRegion;
  }

  public get regionBaseUrl(): string {
    return this.activeRegion.protocol + this.activeRegion.backEndHost + (this.activeRegion.port ? ':' + this.activeRegion.port : '');
  }

  /**
   * @deprecated
   */
  public get regionApiUrl(): string {
    return this.regionBaseUrl + '/v3/api';
  }

  public autoSelectRegion() {
    const offset = new Date().getTimezoneOffset() * -1;
    const customRegion = localStorage.getItem('currentRegion') ? (JSON.parse(localStorage.getItem('currentRegion') ?? 'null') as AppRegion | string) : null;
    if (customRegion !== null) {
      this.useRegion(customRegion);
      return;
    }
    for (const iRegion of environment.regions) {
      if (iRegion.gmtOffsetStart === iRegion.gmtOffsetEnd) {
        this.useRegion(iRegion);
        break;
      } else if (iRegion.gmtOffsetStart > iRegion.gmtOffsetEnd) {
        if ((offset >= iRegion.gmtOffsetStart && offset <= 720) || (offset >= -720 && offset <= iRegion.gmtOffsetEnd)) {
          this.useRegion(iRegion);
          break;
        }
      } else if (offset >= iRegion.gmtOffsetStart && offset <= iRegion.gmtOffsetEnd) {
        this.useRegion(iRegion);
        break;
      }
    }
  }

  public lockRegion() {
    localStorage.setItem('currentRegion', JSON.stringify(this.currentRegion));
  }

  public unlockRegion() {
    localStorage.removeItem('currentRegion');
    this.autoSelectRegion();
  }

  public useRegion(region: AppRegion | string) {
    const prevousRegion = this.activeRegion;
    const initialRegion = region;
    localStorage.setItem('currentRegion', JSON.stringify(region));
    if (typeof region === 'string') {
      const resolvedRegion = environment.regions.find((r) => r.id === region);
      if (!resolvedRegion) {
        this.logger.log('Region not found: ' + initialRegion, 'Region');
        return;
      }
      region = resolvedRegion;
    }
    const isSameRegion = region.id === prevousRegion.id && !!region.id;
    this.logger.log('Nustatomas regionas: ' + region.backEndHost, 'Region', { isSameRegion, region });
    this.activeRegion = region;
    if (this.ps.isAndroid()) {
      this.ps.androidHandler().setRegion(region.backEndHost);
    } else if (this.ps.isApple()) {
      this.ps.appleHandler().setRegion.postMessage(region.backEndHost);
    }
    if (!isSameRegion) this._regionChangedSubject.next(region);
  }
}
