import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TSystemData } from '../models/system-data';
import { ApiRequestService } from './api-request.service';
import * as CryptoJS from 'crypto-js';
import { TAreaData } from '../models/area-data';
import { TNotificationSetting } from '../models/notification-setting';
import { ThemesService } from './themes.service';
import { TPgmData } from '../models/pgm-data';
import { TZoneData } from '../models/zone-data';
import { TEventData } from '../models/event-data';
import { TUser } from '../models/user';
import { TDeviceUser } from '../models/device-user';
import { EventsService } from './events.service';
import { UserService } from './user.service';
import { LoggerService } from '../api/logger.service';
import { HomeConfiguration } from '../models/home-configuration';
import { TSensorData } from '../models/sensor-data';
import { TCamera } from '../models/camera';
import { TThermostatData } from '../models/thermostat-data';
import { UserRole } from '../models/user-role';
import { DomSanitizer } from '@angular/platform-browser';
import { AppSettings } from 'src/app-settings';

@Injectable({
  providedIn: 'root',
})
export class SystemsService {
  public systems: TSystemData[] = [];
  public systemBookmarks = [];
  public filteredSystems: TSystemData[] = [];
  public activeSystem: TSystemData = null;
  public activeArea: TAreaData = null;
  private onActiveSystemChangeSource = new Subject<void>();
  public onActiveSystemChange = this.onActiveSystemChangeSource.asObservable();
  private onSystemsLoadedSource = new Subject<void>();
  public onSystemsLoaded = this.onSystemsLoadedSource.asObservable();
  private storeKey = 'a8oiiXKC9Q4VSbOj';
  public addAreaStatusWork = null;
  private tag = 'SystemsService';
  private virtualSystemCount = 0;

  public activeSystemHelper = {
    supports: {
      areas: () => {
        if (this.activeSystem === null) {
          return false;
        }
        if (this.activeSystem.supported_commands.indexOf('.9.') !== -1) {
          return true;
        }
        return !this.activeSystem.directControl && this.activeSystem.hwType !== 'G17F' && this.activeSystem.hwType !== 'GV17' && this.activeSystem.hwType !== 'CWI';
      },
      events: () => {
        if (this.activeSystem === null) {
          return false;
        }
        if (this.us.currentUser.role !== UserRole.SimpleUser) {
          return true;
        }
        if (this.activeSystem.amIMaster) {
          return true;
        }
        const found = this.activeSystem.device_users.find((u) => u.protegus_user_id === this.us.currentUser.id);
        return found !== undefined && found.canSeeEvents;
      },
      sleepState: () => {
        // return true; // naudojam kai reikia testuoti
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.directControl && !this.activeSystem.noSleepStay;
      },
      stayState: () => {
        // return true; // naudojam kai reikia testuoti
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.directControl && !this.activeSystem.noSleepStay;
      },
      gvTypeUsers: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.supported_commands.indexOf('.11.') !== -1;
      },
      cgTypeUsers: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.supported_commands.indexOf('.14.') !== -1;
      },
      simpleUsers: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.hwType !== 'CG17';
      },
      sensors: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.supported_commands.indexOf('.8.') !== -1;
      },
      outputs: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.supported_commands.indexOf('.3.') !== -1;
      },
      away: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.panel >= 0x10 && this.activeSystem.panel < 0x20; // DSC centralems.
      },
      areaControlByPgm: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return !this.activeSystem.directControl;
      },
      silenceSiren: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.supported_commands.indexOf('.23.') !== -1;
      },
      cancelAlarm: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.supported_commands.indexOf('.23.') !== -1;
      },
      sensorNotifications: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.hwType === 'SP3';
      },
      thermostats: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.hwType === 'SP3';
      },
      advancedSettings: () => {
        if (this.activeSystem === null) {
          return false;
        }
        // toks pats sarasas turi buti advanced-settings komponente.
        // 0x00 tik del to, kad DB gali buti senas irasas be hwid duomenu, todel naujus duomenis gaus per info komanda
        return [0x00, 0x3b, 0x24, 0x37, 0x3a, 0x3c, 0x42, 0x43, 0x44, 0x45, 0x46, 0x47, 0x48].includes(this.activeSystem.deviceId);
      },
      workMode: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.supported_commands.indexOf('.1E.') !== -1;
      },
      zones: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.hwType !== 'GV17' && this.activeSystem.hwType !== 'CWI';
      },
    },
    can: {
      see: {
        information: () => {
          if (this.activeSystem === null) {
            return false;
          }
          return true;
        },
        outputs: () => {
          if (this.activeSystem === null) {
            return false;
          }
          return this.activeSystemHelper.supports.outputs();
        },
        zones: () => {
          if (this.activeSystem === null) {
            return false;
          }
          return true;
        },
        areas: () => {
          if (this.activeSystem === null) {
            return false;
          }
          return this.activeSystemHelper.supports.areas();
        },
        events: () => {
          if (this.activeSystem === null) {
            return false;
          }
          return this.activeSystemHelper.supports.events();
        },
        users: () => {
          if (this.activeSystem === null) {
            return false;
          }
          return this.activeSystem.amIMaster || this.activeSystem.canEditUsers || [UserRole.Company, UserRole.Installer, UserRole.SuperAdmin].includes(this.us.currentUser.role);
        },
        sensors: () => {
          if (this.activeSystem === null) {
            return false;
          }
          return this.activeSystemHelper.supports.sensors();
        },
        cameras: () => {
          if (this.activeSystem === null) {
            return false;
          }
          return this.us.currentUser.role === UserRole.SimpleUser;
        },
      },
      edit: {
        information: () =>
          this.activeSystemHelper.can.see.information() &&
          (this.activeSystem.amIMaster || [UserRole.Installer, UserRole.Company, UserRole.SuperAdmin].includes(this.us.currentUser.role)),
        outputs: () =>
          this.activeSystemHelper.can.see.outputs() &&
          (this.activeSystem.amIMaster || [UserRole.Installer, UserRole.Company, UserRole.SuperAdmin].includes(this.us.currentUser.role)),
        zones: () => {
          if (!this.activeSystemHelper.can.see.zones()) {
            return false;
          }
          if ([UserRole.Installer, UserRole.Company, UserRole.SuperAdmin].includes(this.us.currentUser.role)) {
            return true;
          }
          if (!this.activeSystem.amIMaster) {
            return false;
          }

          return true;
        },
        areas: () =>
          this.activeSystemHelper.can.see.areas() &&
          (this.activeSystem.amIMaster || [UserRole.Installer, UserRole.Company, UserRole.SuperAdmin].includes(this.us.currentUser.role)),
        users: () => {
          if (this.activeSystem === null) {
            return false;
          }
          return this.activeSystem.amIMaster || this.activeSystem.canEditUsers || [UserRole.Installer, UserRole.Company, UserRole.SuperAdmin].includes(this.us.currentUser.role);
        },
        userKeyboardCode: () => (this.activeSystem.directControl && this.activeSystemHelper.supports.cgTypeUsers()) || this.activeSystemHelper.supports.gvTypeUsers(),
        sensors: () => this.activeSystemHelper.can.see.sensors(),
        cameras: () => {
          if (this.activeSystem === null) {
            return false;
          }
          return this.activeSystem.amIMaster && this.us.currentUser.role === UserRole.SimpleUser;
        },
      },
      control: {
        area: (areaQueueNumber: number) => {
          if (this.activeSystemHelper.supports.gvTypeUsers()) {
            return false;
          }
          if (this.activeSystem.amIMaster) {
            return true;
          }
          if (this.us.currentUser.role !== UserRole.SimpleUser) {
            return true;
          }
          const user = this.activeSystem.device_users.find((u) => u.protegus_user_id === this.us.currentUser.id);
          if (user === undefined) {
            return false;
          }
          if (user.areas === '') {
            return false;
          }
          if (areaQueueNumber > user.areas.length) {
            return false;
          }
          return user.areas.charAt(areaQueueNumber - 1) === '1';
        },
      },
      delete: {
        system: () => {
          if (this.activeSystem === null) {
            return false;
          }
          if (this.us.currentUser.role === UserRole.SimpleUser && this.activeSystem.company_id !== 0 && this.activeSystem.amIMaster) {
            return false;
          }
          return true;
        },
        areas: () => {
          if (this.activeSystem === null) {
            return false;
          }
          return this.activeSystemHelper.can.edit.areas() && !this.activeSystem.directControl;
        },
        zones: () => {
          if (!this.activeSystemHelper.can.edit.zones()) {
            return false;
          }
          return this.activeSystem.hwType !== 'SP3' && this.activeSystem.hwType !== 'G17F';
        },
        outputs: () => this.activeSystemHelper.can.add.outputs(),
        users: () => {
          if (!this.activeSystemHelper.can.edit.users()) {
            return false;
          }
          if (AppSettings.onlySUcanAddRemoveUsers && this.us.currentUser.role !== UserRole.SuperAdmin) {
            return false;
          }
          return true;
        },
      },
      add: {
        zones: () => {
          if (this.activeSystem === null) {
            return false;
          }
          if (this.activeSystem.hwType === 'G17F') {
            return false;
          }
          if (!this.activeSystem.directControl && this.activeSystem.amIMaster) {
            return true;
          }
          if (!this.activeSystem.amIMaster) {
            return false;
          }
          // toliau dirbam su direct control sistemomis.
          const invisibleZones = this.activeSystem.zones.filter((z) => z.enabled && !z.visible && z.native);
          return invisibleZones.length > 0;
        },
        users: () => {
          if (!this.activeSystemHelper.can.edit.users()) {
            return false;
          }
          if (AppSettings.onlySUcanAddRemoveUsers && this.us.currentUser.role !== UserRole.SuperAdmin) {
            return false;
          }
          if (this.activeSystem.maxDeviceUsers === 0) {
            return true;
          }
          return true; // this.activeSystem.device_users.length <= this.activeSystem.maxDeviceUsers;
        },
        outputs: () => {
          if (!this.activeSystemHelper.can.edit.outputs()) {
            return false;
          }
          if (this.activeSystem.hwType !== 'GV17' && this.activeSystem.hwType !== 'CWI') {
            return false;
          }
          return this.activeSystem.supported_commands.indexOf('.19.') !== -1;
        },
      },
      transfer: () => {
        if (this.activeSystem === null) {
          return false;
        }
        if (this.activeSystem.company_id !== 0 && this.us.currentUser.role === UserRole.SimpleUser) {
          return false;
        }
        if (this.activeSystem.company_id !== 0 && this.us.currentUser.role === UserRole.Company) {
          return true;
        }
        return this.activeSystem.amIMaster || this.us.currentUser.role === UserRole.SuperAdmin;
      },
      bypassZone: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.canBypassZone && this.activeSystem.hwType !== 'G16T' && this.activeSystem.hwType !== 'E16T';
      },
      unbypassZone: () => {
        if (this.activeSystem === null) {
          return false;
        }
        return this.activeSystem.canUnbypassZone;
      },
      resetFireSensors: () => {
        if (this.activeSystem === null) {
          return false;
        }
        if (!this.activeSystem.amIMaster && this.us.currentUser.role !== UserRole.SuperAdmin && this.us.currentUser.role !== UserRole.Company) {
          return false;
        }
        if (!this.activeSystemHelper.can.edit.sensors()) {
          return false;
        }
        return this.activeSystem.supportsFireReset;
      },
    },
    hasEnabledOutputs: () => {
      const found = this.activeSystem.pgms.find((o) => o.enabled);
      return found !== undefined;
    },
  };

  constructor(
    private rs: ApiRequestService,
    private themes: ThemesService,
    private es: EventsService,
    private us: UserService,
    private l: LoggerService,
    private sanitizer: DomSanitizer
  ) {}

  /** Gauna duomenis apie visas vartotojui priklausancias sistema. */
  public loadSystems(nextFragment?: boolean, callback?: any) {
    const that = this;
    let lastSystemName = '';
    if (nextFragment !== undefined && nextFragment) {
      lastSystemName = this.systems[this.systems.length - 1].name;
    }
    this.rs
      .get('/systems', true, {
        returnSystemCount: this.us.currentUser.role === UserRole.SuperAdmin,
        all: this.us.currentUser.role === UserRole.SuperAdmin && AppSettings.loadAllSystemsForSU,
        offsetName: lastSystemName ?? '',
      })
      .subscribe(
        (result) => {
          if (result.success) {
            if (nextFragment === undefined || !nextFragment) {
              that.systems = [];
              that.filteredSystems = [];
            }
            for (const iSystem of result.systems) {
              if (iSystem.hwType === '') {
                continue;
              }
              const converted = that.convertSystemFromRaw(iSystem);
              if (that.activeSystem !== null && converted.id === that.activeSystem.id) {
                that.setCurrentSystem(converted);
              } else if (that.activeSystem === null && that.us.currentUser.role === UserRole.SimpleUser) {
                that.setCurrentSystem(converted);
              } else {
                // that.systems.push(that.getFakeSystem('Fake'));
                // that.systems.push(that.getFakeSystem('Fake 2'));
                that.systems.push(converted);
              }
            }
            if (result.totalSystems !== undefined) {
              this.virtualSystemCount = result.totalSystems;
            }
            if (result.bookmarks !== undefined) {
              that.systemBookmarks = result.bookmarks;
            }
            if (nextFragment === undefined || !nextFragment) {
              that.onActiveSystemChangeSource.next();
              that.onSystemsLoadedSource.next();
            }
          } else {
            that.l.log('Nepavyko gauti sistemu duomenu.', that.tag);
          }
          if (callback !== undefined) {
            callback();
          }
        },
        (error) => {
          that.l.log('', that.tag, error);
          if (callback !== undefined) {
            callback();
          }
        }
      );
  }

  public clearSystems() {
    this.systems = [];
    this.activeSystem = null;
    this.activeArea = null;
  }

  public hasSystems(): boolean {
    return this.systems.length > 0;
  }

  public convertSystemFromRaw(systemData: any): TSystemData {
    if (systemData === null) {
      return null;
    }

    const areasFromDb: TAreaData[] = [];
    for (const iArea of systemData.areas) {
      areasFromDb.push({
        name: iArea.name,
        queue_no: iArea.queue_no,
        zones: [],
        id: iArea.id,
        canBypass: iArea.canBypass,
        showKeypad: iArea.showKeypad,
        status: iArea.status,
        statusTime: iArea.status_time === null ? 0 : iArea.status_time,
        alarmed: iArea.alarm_in_memory,
        lastPerson: iArea.last_person_name,
        alarmTime: iArea.alarm_time === null ? 0 : iArea.alarm_time,
        alarmType: iArea.alarm_type,
      });
      if (iArea.status === 5 && iArea.status_time !== null) {
        if (this.addAreaStatusWork !== null) {
          this.addAreaStatusWork(systemData.id, iArea.id, iArea.status_time);
        }
      }
    }
    const notificationsFromDb: TNotificationSetting[] = [];
    let notificationsFromDbEnabled = false;
    let notificationsFromDbSoundsEnabled = false;
    if (systemData.notifications !== null) {
      notificationsFromDbEnabled = systemData.notifications.global_value;
      notificationsFromDbSoundsEnabled = systemData.notifications.sounds_on;
      for (const iNotif of systemData.notifications.notifications) {
        notificationsFromDb.push({
          enabled: iNotif.on,
          id: iNotif.id,
          name: iNotif.name,
          useAlertSound: iNotif.alert_sound_on,
        });
      }
    }
    const pgmsFromDb: TPgmData[] = [];
    for (const iPgm of systemData.pgms) {
      if (iPgm.io_ability === 'W' && iPgm.io_type === 3) {
        continue;
      }
      const pgmToAlter: TPgmData = {
        id: iPgm.id,
        area_no: 0,
        control_area: iPgm.pgm_as_area_ck === 1,
        enabled: iPgm.enabled === 1,
        icon: iPgm.icon_number,
        name: iPgm.name,
        on: iPgm.on === 1,
        pulseTime: iPgm.pulse_time_in_seconds,
        queue_no: iPgm.queue_no,
        type: iPgm.type,
        area_id: iPgm.area_to_arm,
        pulse_activated_at: iPgm.pulse_start_time === null ? 0 : iPgm.pulse_start_time * 1000,
        io_type: iPgm.io_type,
        io_ability: iPgm.io_ability,
      };
      if (pgmToAlter.control_area) {
        const found = areasFromDb.find((a) => a.id === pgmToAlter.area_id);
        if (found === undefined) {
          pgmToAlter.control_area = false;
          pgmToAlter.area_id = 0;
        } else {
          pgmToAlter.area_no = found.queue_no;
        }
      }
      pgmsFromDb.push(pgmToAlter);
    }

    const zonesFromDb: TZoneData[] = [];
    for (const iZone of systemData.zones) {
      let found = zonesFromDb.find((z) => z.queue_no === iZone.queue_no);
      const areaFound = areasFromDb.find((a) => a.id === iZone.area_id);
      if (areaFound === undefined) {
        continue;
      }
      if (found === undefined) {
        found = {
          alarmed: false,
          areas: [],
          bypassed: false,
          enabled: true,
          failed: false,
          name: iZone.name,
          native: iZone.native,
          queue_no: iZone.queue_no,
          visible: iZone.visible,
        };
        zonesFromDb.push(found);
      }
      found.areas.push(areaFound.queue_no);
    }

    const eventsFromDb: TEventData[] = [];
    for (const iEvent of systemData.events.events) {
      if (iEvent === null) {
        continue;
      }
      eventsFromDb.push(this.es.convertFromRaw(iEvent));
    }

    const usersFromDb: TUser[] = [];
    for (const iProtegusUser of systemData.protegus_users) {
      usersFromDb.push({
        ActivationToken: '',
        DateFormat: 0,
        email: iProtegusUser.email,
        id: iProtegusUser.id,
        IsSocialAccount: false,
        name: iProtegusUser.name,
        phone: iProtegusUser.phone,
        role: iProtegusUser.role,
        SocketPort: 0,
        SocketToken: '',
        isMaster: iProtegusUser.master,
        passwordToken: '',
        isPasswordSet: false,
        homeConfigurations: [],
        lastSeenReactions: [],
        reactions: [],
        reactionGroups: [],
        active: iProtegusUser.active,
        country: iProtegusUser.country,
        company: iProtegusUser.company,
        logo: '',
        company_id: iProtegusUser.company_id,
        panicSettings: null,
        regions: [],
        limits: iProtegusUser.limits,
        canTransferDevice: false,
      });
    }

    const deviceUsersFromDb: TDeviceUser[] = [];
    for (const iDeviceUser of systemData.device_users) {
      deviceUsersFromDb.push(
        new TDeviceUser({
          id: iDeviceUser.id,
          areas: iDeviceUser.areas !== undefined ? iDeviceUser.areas : '',
          code: iDeviceUser.keyboard_code !== undefined ? iDeviceUser.keyboard_code : iDeviceUser.pin !== undefined ? iDeviceUser.pin : '',
          email: iDeviceUser.email,
          enable_data: iDeviceUser.enable_data !== undefined ? iDeviceUser.enable_data : 0,
          name: iDeviceUser.name,
          pgms: iDeviceUser.pgms !== undefined ? iDeviceUser.pgms : -1,
          phone: iDeviceUser.phone,
          present: iDeviceUser.present !== undefined ? iDeviceUser.present : false,
          protegus_user_id: iDeviceUser.protegus_user_id,
          schedule_no: iDeviceUser.schedule_no !== undefined ? iDeviceUser.schedule_no : 0,
          zone_number: iDeviceUser.system_code_no !== undefined ? iDeviceUser.system_code_no : iDeviceUser.user_id !== undefined ? iDeviceUser.user_id : 0,
          can_edit_users: iDeviceUser.canEditUsers,
          isOwner: false,
          ownerPermissions: null,
          canSeeEvents: iDeviceUser.can_see_events !== undefined ? iDeviceUser.can_see_events : false,
        })
      );
    }
    deviceUsersFromDb.sort((du1, du2) => du1.zone_number - du2.zone_number);

    const sensorsFromDb: TSensorData[] = [];
    for (const iSensor of systemData.sensors) {
      sensorsFromDb.push({
        active: iSensor.enabled === 1,
        alarmHigh: false,
        alarmLow: false,
        enabled: iSensor.enabled === 1,
        name: iSensor.name,
        queue_no: iSensor.queue_no,
        temperature: '',
        type: iSensor.type,
        notificationAboutHigh: iSensor.alarm_high,
        notificationAboutLow: iSensor.alarm_low,
        highValue: iSensor.high_value,
        lowValue: iSensor.low_value,
      });
    }

    const camerasFromDb: TCamera[] = [];
    for (const iCamera of systemData.cameras) {
      camerasFromDb.push({
        fullUrl: iCamera.full_url,
        host: iCamera.host,
        port: iCamera.port,
        id: iCamera.id,
        name: iCamera.name,
        pass: iCamera.pass,
        path: iCamera.path,
        user: iCamera.user,
        assignedPgms: iCamera.assigned_pgms ?? [],
      });
    }

    const thremostatsFromDb: TThermostatData[] = [];
    for (const iThermostat of systemData.thermostats) {
      thremostatsFromDb.push({
        id: iThermostat.id,
        activeSensor: iThermostat.active_sensor,
        assignedOutput: iThermostat.assigned_output,
        assignedSensors: JSON.parse(iThermostat.assigned_sensors),
        name: iThermostat.name,
        queueNo: iThermostat.queue_no,
        systemId: iThermostat.system_id,
        temperatures: JSON.parse(iThermostat.temperatures),
        action: iThermostat.action,
      });
    }

    let visiblePgms: TPgmData[] = null;
    const deviceUser = deviceUsersFromDb.find((u) => u.protegus_user_id === this.us.currentUser.id);
    let userPgms = this.us.currentUser.role !== UserRole.SimpleUser ? -1 : 0;
    if (systemData.amIMaster) {
      userPgms = -1;
    } else if (deviceUser !== undefined) {
      userPgms = deviceUser.pgms;
    }
    if (userPgms > -1) {
      visiblePgms = [];
      for (const iPgm of pgmsFromDb) {
        let value = false;
        /* eslint-disable no-bitwise */
        if (iPgm.queue_no > 2) {
          // GV moduliuose 3 4 5 1 2
          value = (userPgms & (0x01 << (iPgm.queue_no - 3))) > 0;
        } else {
          value = (userPgms & (0x01 << (iPgm.queue_no + 2))) > 0;
        }
        /* eslint-enable no-bitwise */
        if (value) {
          visiblePgms.push(iPgm);
        }
      }
    }

    const localSystemData: TSystemData = {
      id: systemData.id,
      areas: areasFromDb,
      name: systemData.name,
      pgms: visiblePgms ?? pgmsFromDb,
      online: systemData.online,
      supported_commands: systemData.supported_commands,
      amIMaster: systemData.amIMaster,
      mpass: systemData.mpass,
      address: systemData.address,
      timeZone: systemData.timeZone,
      imei: systemData.imei,
      notificationSoundsEnabled: notificationsFromDbSoundsEnabled,
      notificationsEnabled: notificationsFromDbEnabled,
      notifications: notificationsFromDb,
      sensors: sensorsFromDb,
      signalLevel: systemData.signalLevel,
      zones: zonesFromDb,
      hwType: systemData.hwType,
      canBypassZone: systemData.canBypassZone,
      canUnbypassZone: systemData.canUnbypassZone,
      directControl: systemData.direct,
      events: eventsFromDb,
      noSleepStay: systemData.noSleepStay,
      protegus_users: usersFromDb,
      device_users: deviceUsersFromDb,
      maxDeviceUsers: systemData.maxDeviceUsers,
      panel: systemData.centralPanel,
      coordinates: systemData.coordinates,
      eventConfiguration: JSON.parse(systemData.eventConfiguration),
      canEditUsers: systemData.canEditUsers,
      theme: {
        startColor: systemData.theme.background_start,
        endColor: systemData.theme.background_end,
        fullBackground: systemData.theme.full_background,
      },
      cameras: camerasFromDb,
      thermostats: thremostatsFromDb,
      installerId: systemData.installer_id,
      installerEmail: systemData.installerEmail !== undefined ? systemData.installerEmail : '',
      installerName: systemData.installerName !== undefined ? systemData.installerName : '',
      logo: systemData.logo !== undefined ? (this.sanitizer.bypassSecurityTrustUrl(systemData.logo) as string) : '',
      company_id: systemData.company_id,
      assistedById: systemData.assistedById,
      assistedByEmail: systemData.assistedByEmail,
      supportsFireReset: systemData.supportsFireReset,
      amIWorking: systemData.amIWorking ?? false,
      privacyOfOwners: systemData.privacyOfOwners ?? [],
      owners: systemData.owners ?? [],
      deviceId: systemData.device_id ?? 0,
    };
    for (const iOwner of localSystemData.owners) {
      const found = deviceUsersFromDb.find((du) => du.protegus_user_id === iOwner.id);
      if (found === undefined) {
        continue;
      }
      const foundPrivacy = localSystemData.privacyOfOwners.find((po) => po.user_id === iOwner.id);
      found.isOwner = true;
      found.ownerPermissions =
        foundPrivacy === undefined
          ? {
              show_email_admins: true,
              show_name_admins: true,
              show_phone_admins: true,
              show_email_users: true,
              show_name_users: true,
              show_phone_users: true,
            }
          : foundPrivacy;
    }
    if (systemData.logo !== undefined) {
      localSystemData.logoDataForStorage = systemData.logo;
    }

    return localSystemData;
  }

  public setCurrentSystemFromRaw(systemData: any) {
    this.l.log('', this.tag, systemData);
    this.setCurrentSystem(this.convertSystemFromRaw(systemData));
  }

  public setCurrentSystem(systemData: TSystemData) {
    try {
      if (systemData === null) {
        localStorage.removeItem('last_system_data');
        localStorage.removeItem('last_system');
        this.activeSystem = null;
        this.activeArea = null;
        return;
      }

      // Sistema ta pati, perkeliam laikinus duomenis (sensorių būsenos, zonų būsenos)
      if (this.activeSystem !== null && this.activeSystem.id === systemData.id) {
        if (systemData.sensors.length > 0 && this.activeSystem.sensors.length > 0) {
          for (const iSensor of systemData.sensors) {
            const foundSensor = this.activeSystem.sensors.find((s) => s.queue_no === iSensor.queue_no);
            if (foundSensor !== undefined) {
              iSensor.alarmHigh = foundSensor.alarmHigh;
              iSensor.alarmLow = foundSensor.alarmLow;
              iSensor.temperature = foundSensor.temperature;
            }
          }
        }
        if (systemData.zones.length > 0 && this.activeSystem.zones.length > 0) {
          for (const iZone of systemData.zones) {
            const foundZone = this.activeSystem.zones.find((z) => z.queue_no === iZone.queue_no);
            if (foundZone !== undefined) {
              iZone.alarmed = foundZone.alarmed;
              iZone.bypassed = foundZone.bypassed;
              iZone.failed = foundZone.failed;
            }
          }
        }
      }
      const data = CryptoJS.AES.encrypt(JSON.stringify(systemData), this.storeKey).toString();
      localStorage.setItem('last_system_data', data);
      localStorage.setItem('last_system', systemData.id.toString());
      this.activeSystem = systemData;
      if (this.activeArea !== null) {
        this.setCurrentArea(this.activeArea.id);
      }
      this.themes.setHomeTheme(systemData.theme);
      const found = this.systems.find((fs) => fs.imei === this.activeSystem.imei);
      if (found !== undefined) {
        this.systems = this.systems.filter((sf) => sf.imei !== found.imei);
      }
      this.systems.push(this.activeSystem);
      this.systems.sort((s1, s2) => s1.name.localeCompare(s2.name));
    } catch (e) {
      this.l.log('', this.tag, e);
    }
    this.onActiveSystemChangeSource.next();
  }

  public getLastSystemData(): TSystemData {
    try {
      const systemData = localStorage.getItem('last_system_data');
      if (systemData === null) {
        return null;
      }
      const bytes = CryptoJS.AES.decrypt(systemData, this.storeKey);
      if (bytes.toString()) {
        const systemObject = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        if (systemObject.logoDataForStorage !== undefined && systemObject.logoDataForStorage !== '') {
          systemObject.logo = this.sanitizer.bypassSecurityTrustUrl(systemObject.logoDataForStorage) as string;
        }
        return systemObject;
      }
    } catch (e) {
      this.l.log('', this.tag, e);
    }
    return null;
  }

  public getSystem(systemId: number) {
    return this.systems.find((s) => s.id === systemId);
  }

  public removeSystem(systemId: number) {
    this.systems = this.systems.filter((s) => s.id !== systemId);
    this.filteredSystems = this.filteredSystems.filter((s) => s.id !== systemId);
    if (this.us.currentUser.role === UserRole.SuperAdmin) {
      this.virtualSystemCount--;
    }
    if (this.activeSystem !== null && this.activeSystem.id === systemId) {
      this.setCurrentSystemFromInternalList(true);
    }
  }

  public setCurrentSystemFromInternalList(takeFirst: boolean) {
    if (this.systems.length === 0) {
      this.setCurrentSystem(null);
      return;
    }

    if (takeFirst) {
      this.setCurrentSystem(this.systems[0]);
    } else {
      this.setCurrentSystem(this.systems[this.systems.length - 1]);
    }
  }

  public addSystem(system: TSystemData) {
    const found = this.systems.find((s) => s.id === system.id);
    if (found !== undefined) {
      return;
    }
    this.systems.push(system);
    if (this.us.currentUser.role === UserRole.SuperAdmin) {
      this.virtualSystemCount++;
    }
    if (this.activeSystem === null && this.us.currentUser.role !== UserRole.Company && this.us.currentUser.role !== UserRole.SuperAdmin) {
      this.setCurrentSystemFromInternalList(true);
    }
  }

  public setCurrentArea(areaId: number) {
    if (areaId === 0) {
      this.activeArea = null;
      return;
    }

    const area = this.activeSystem.areas.find((a) => a.id === areaId);
    if (area === undefined) {
      this.activeArea = null;
      return;
    }

    this.activeArea = area;
  }

  /**
   * Į local storage išsaugo aktyvios sistemos duomenis.
   * Dažniausiai naudojama po to, kai pakeičiami kažkokie duomenys.
   *
   * @param systemId Nebūtinas. Nurodo patikrinti ar aktyvios sistemos ID toks pats. Tada saugo duomenis.
   */
  public saveActiveSystem(systemId?: number) {
    if (this.activeSystem === null) {
      return;
    }
    if (systemId !== undefined && this.activeSystem.id !== systemId) {
      return;
    }
    this.setCurrentSystem(this.activeSystem);
  }

  public getDefaultHomeConfigurationForActiveSystem(): HomeConfiguration {
    const def = this.us.getDefaultHomeConfiguration();
    if (this.activeSystem === null) {
      return def;
    }
    def.systemId = this.activeSystem.id;

    return def;
  }

  public updateOrAddSystem(systemData: TSystemData) {
    const systemsFound: TSystemData[] = [];
    let foundSystem = this.filteredSystems.find((sf) => sf.id === systemData.id);
    if (foundSystem !== undefined) {
      systemsFound.push(foundSystem);
    }
    foundSystem = this.systems.find((s) => s.id === systemData.id);
    if (foundSystem !== undefined) {
      systemsFound.push(foundSystem);
    }
    if (foundSystem === undefined) {
      this.addSystem(systemData);
      return;
    }

    for (const found of systemsFound) {
      found.name = systemData.name;
      found.pgms = systemData.pgms;
      found.areas = systemData.areas;
      found.online = systemData.online;
      found.supported_commands = systemData.supported_commands;
      found.amIMaster = systemData.amIMaster;
      found.mpass = systemData.mpass;
      found.address = systemData.address;
      found.timeZone = systemData.timeZone;
      found.notificationsEnabled = systemData.notificationsEnabled;
      found.notificationSoundsEnabled = systemData.notificationSoundsEnabled;
      found.notifications = systemData.notifications;
      found.sensors = systemData.sensors;
      found.signalLevel = systemData.signalLevel;
      found.zones = systemData.zones;
      found.canBypassZone = systemData.canBypassZone;
      found.canUnbypassZone = systemData.canUnbypassZone;
      found.directControl = systemData.directControl;
      found.events = systemData.events;
      found.noSleepStay = systemData.noSleepStay;
      found.protegus_users = systemData.protegus_users;
      found.device_users = systemData.device_users;
      found.maxDeviceUsers = systemData.maxDeviceUsers;
      found.panel = systemData.panel;
      found.coordinates = systemData.coordinates;
      found.eventConfiguration = systemData.eventConfiguration;
      found.canEditUsers = systemData.canEditUsers;
      found.theme = systemData.theme;
      found.cameras = systemData.cameras;
      found.thermostats = systemData.thermostats;
      found.installerId = systemData.installerId;
      found.installerName = systemData.installerName;
      found.installerEmail = systemData.installerEmail;
      found.logo = systemData.logo;
      found.company_id = systemData.company_id;
      found.assistedById = systemData.assistedById;
      found.assistedByEmail = systemData.assistedByEmail;
      found.supportsFireReset = systemData.supportsFireReset;
      found.amIWorking = systemData.amIWorking;
      found.privacyOfOwners = systemData.privacyOfOwners;
      found.owners = systemData.owners;
      found.deviceId = systemData.deviceId;
    }

    this.saveActiveSystem(systemData.id);
  }

  private getFakeSystem(systemName: string) {
    let generatedImei = '';
    const values = '0123456789';
    for (let i = 0; i < 15; i++) {
      const randomDigit = Math.floor(Math.random() * values.length);
      generatedImei += values[randomDigit];
    }
    return {
      id: Math.floor(Math.random() * 100),
      areas: [],
      name: systemName,
      pgms: [],
      online: false,
      supported_commands: '',
      amIMaster: true,
      mpass: '',
      address: 'Addr',
      timeZone: 'UTC',
      imei: generatedImei,
      notificationSoundsEnabled: true,
      notificationsEnabled: true,
      notifications: [],
      sensors: [],
      signalLevel: 9,
      zones: [],
      hwType: 'SP3',
      canBypassZone: true,
      canUnbypassZone: true,
      directControl: true,
      events: [],
      noSleepStay: false,
      protegus_users: [],
      device_users: [],
      maxDeviceUsers: 999,
      panel: 0,
      coordinates: '',
      eventConfiguration: null,
      canEditUsers: true,
      theme: {
        startColor: '#123456',
        endColor: '#654321',
        fullBackground: '',
      },
      cameras: [],
      thermostats: [],
      installerId: 0,
      installerEmail: '',
      installerName: '',
      logo: '',
      company_id: this.us.currentUser.id,
      assistedById: undefined,
      assistedByEmail: undefined,
      supportsFireReset: false,
      amIWorking: false,
      privacyOfOwners: [],
      owners: [],
    };
  }

  public getSystemCount(): number {
    if (this.us.currentUser.role === UserRole.SuperAdmin) {
      return this.virtualSystemCount;
    }

    return this.systems.length;
  }

  public updateAccessData(newData) {
    const system = this.getSystem(newData.system_id);
    if (system === undefined) {
      return;
    }
    const user = system.device_users.find((du) => du.protegus_user_id === newData.protegus_user_id);
    if (user === undefined) {
      return;
    }
    user.present = newData.present;
    if (user.protegus_user_id === this.us.currentUser.id) {
      system.amIWorking = newData.present;
    }
  }
}
