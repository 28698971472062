import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RequestService } from 'src/app/api/request.service';
import { LoginService } from 'src/app/login/login.service';
import { LanguageService } from 'src/app/services/language.service';
import { customPasswordValidatorBuilder, ValidatorBuilder } from 'src/app/ui/validator';

const newPasswordValidator = new ValidatorBuilder<{ password: string }>().custom(
  'password',
  customPasswordValidatorBuilder({
    tooShort: (_, c: SetPasswordComponent) => c.trans('auth.error.passwordTooShort'),
    noLowerCase: (_, c: SetPasswordComponent) => c.trans('auth.error.passwordNoLowerCase'),
    noUpperCase: (_, c: SetPasswordComponent) => c.trans('auth.error.passwordNoUpperCase'),
    noNumber: (_, c: SetPasswordComponent) => c.trans('auth.error.passwordNoNumber'),
  })
);

@Component({
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss'],
})
export class SetPasswordComponent implements OnInit {
  public val = newPasswordValidator.build();
  public readonly trans = (key: string) => this.ls.get(key);
  constructor(public login: LoginService, private router: Router, private req: RequestService, private ls: LanguageService) {}

  ngOnInit(): void {
    if (!this.login.passwordToken) {
      this.router.navigate(['login', 'password-reset', 'email']);
    }
  }

  public async next() {
    if (!(await this.val.validate(this.login))) return;
    const res = await this.req.login.passwordResetComplete({ password: this.login.password, token: this.login.passwordToken }).toPromise();
    if (res.success === false) {
      this.val.pushError('password', 'Error: ' + res.error);
      return;
    }
    this.login.passwordToken = undefined;
    this.login.password = '';
    this.router.navigate(['login'], { replaceUrl: true });
  }
}
