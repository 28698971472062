import { Injectable } from '@angular/core';
import { AndroidHandler, AppleHandler } from 'src/window';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  constructor() {}
  /**
   * isDesktop
   */
  public isDesktop() {
    return !this.isMobile();
  }

  /**
   * isMobile
   */
  public isMobile() {
    return this.isAndroid() || this.isApple();
  }

  /**
   * Pasako ar šiuo metu dirbama Android aplikacijoje.
   */
  public isAndroid() {
    return window.jsi !== undefined;
  }

  /**
   * Pasako ar šiuo metu dirbama iOS aplikacijoje.
   */
  public isApple() {
    return window.webkit !== undefined;
  }

  /**
   * Gražina Android aplikacijos sukurtą JavascriptInterface objektą.
   * Per šį objektą galima bendrauti su Android Native dalimi.
   */
  public androidHandler(): AndroidHandler {
    if (!window.jsi) throw new Error('Not an Android app');
    return window.jsi;
  }

  /**
   * Gražina Apple aplikacijos sukurtą MessageHandler objektą.
   * Per šį objektą galima bendrauti su iOS Native dalimi.
   */
  public appleHandler(): AppleHandler {
    if (!window.webkit) throw new Error('Not an Apple app');
    return window.webkit.messageHandlers;
  }
}
