/* eslint-disable no-shadow */
export enum TEditableComponent {
  Sensor = 'sensor',
  Camera = 'camera',
  Area = 'area',
  Output = 'output',
  Thermostat = 'thermostat',
  Reaction = 'reaction',
  EventDescription = 'event-desc',
  ReactionGroup = 'reaction-group',
  IPcom = 'ipcom',
  Region = 'region',
  OwnerPrivacy = 'owner-privacy',
  User = 'user',
  Helper1 = 'helper-1',
  Zone = 'zone',
}
