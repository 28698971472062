<div class="white_round padding16">
    <app-text-edit [value]="userItem.email" (valueChanged)="userItem.email = $event" [inputType]="'email'">{{trans('auth.form.email')}}</app-text-edit>
    <app-text-edit [value]="userItem.name" (valueChanged)="userItem.name = $event" [maxCharacters]="23">{{trans('auth.form.nameAuth')}}</app-text-edit>
    <app-text-edit [value]="userItem.phone" (valueChanged)="userItem.phone = $event">{{trans('auth.form.phoneField')}}</app-text-edit>
    <app-name-value [nameText]="trans('users.labels.country')" (clicked)="changeCountry()">{{countryText}}</app-name-value>
    <app-name-value *ngIf="us.currentUser.role === 0" [nameText]="trans('users.labels.role')" (clicked)="changeRole()">{{trans('users.roles.' + userItem.role)}}</app-name-value>
    <app-name-value [nameText]="trans('users.status.active')">
        <app-toggle-button [initialState]="userItem.active === 1" (toggled)="userItem.active = $event.checked ? 1 : 0"></app-toggle-button>
    </app-name-value>
    <app-name-value *ngIf="userItem.id === 0" [nameText]="trans('auth.form.password')" (clicked)="changePassword()">{{newPassword === '' ? '' : '&#9679;&#9679;&#9679;&#9679;&#9679;&#9679;'}}</app-name-value>
    <div id="cpeu_pass" *ngIf="userItem.id !== 0" (click)="changePassword()">{{trans('users.buttons.changePassword')}}</div>
</div>
<app-base-popup #pickInstaller (popupCancelled)="selectedInstallerId = -1">
    <div class="white_round padding16">
        <div id="cpeu_inst_header">{{trans('users.labels.selectInstaller')}}</div>
        <div id="cpeu_inst_desc" [innerHtml]="trans('users.labels.selectInstallerDesc')" *ngIf='us.currentUser.role === 0'></div>
        <div id="cpeu_inst_desc" *ngIf='us.currentUser.role === 3'>{{trans('users.labels.selectInstallerDescCompany')}}</div>
        <div>
            <div class="cpeu_installer_item" *ngFor="let iInstaller of installersToPickFrom; let i = index" (click)="selectedInstallerId = iInstaller.id">
                <app-radio-button class="cpeu_insta_radio" [groupName]="'selectInstaller'" [checkValue]="selectedInstallerId === iInstaller.id"></app-radio-button>
                <div class="cpeu_inst_name">{{iInstaller.name === '' ? trans('users.labels.noName') : iInstaller.name}}</div>
                <div class="cpeu_inst_email">{{iInstaller.email}}&nbsp;</div>
            </div>
        </div>
        <div id="cpeu_inst_confirm" class="button" (click)="installlerSelectionConfirmed()">{{trans('users.buttons.confirm')}}</div>
    </div>
</app-base-popup>