<app-login-layout-sofa>
  <div class="main-section">
    <div class="login-box">
      <h4 *ngIf="!forgetMode">{{ trans('auth.labels.chooseAccount') }}</h4>
      <h4 *ngIf="forgetMode">{{ trans('auth.labels.chooseAccountToRemove') }}</h4>
      <app-account-switcher [forgetMode]="forgetMode"></app-account-switcher>
      <a *ngIf="!forgetMode" routerLink="/login/email"
        ><app-button>{{ trans('auth.addAccount') }}</app-button></a
      >
      <a *ngIf="!forgetMode" href="javascript:void(0)" (click)="forgetMode = !forgetMode">{{ trans('auth.forgetAccounts') }}</a>
      <a *ngIf="forgetMode" class="backLink" href="javascript:void(0)" (click)="forgetMode = !forgetMode">{{ trans('auth.loginBack') }}</a>
    </div>
  </div>
</app-login-layout-sofa>
