import { Component, OnInit } from '@angular/core';
import { LocaleService } from 'src/app/api/locale.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-language-switcher',
  templateUrl: './language-switcher.component.html',
  styleUrls: ['./language-switcher.component.scss'],
})
export class LanguageSwitcherComponent implements OnInit {
  public show = false;
  public get LanguageList() {
    return this.locale.getLanguageList();
  }
  public readonly trans = (key: string) => this.ls.get(key);
  constructor(public locale: LocaleService, private ls: LanguageService) {}

  ngOnInit(): void {}

  public selectLanguage(lang: string) {
    this.ls.setLanguage(lang);
    this.show = false;
  }
}
