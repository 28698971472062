import { Injectable, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { HomeConfiguration } from '../models/home-configuration';
import { TUser } from '../models/user';
import { UserRole } from '../models/user-role';
import { HelperService } from './helper.service';
import { LoggerService } from '../api/logger.service';
import { PlatformService } from '../api/platform.service';
import { AuthService } from '../api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  public currentUser: TUser;
  private onUserDataChangedSource = new Subject<TUser>();
  public onUserDataChanged = this.onUserDataChangedSource.asObservable();
  public eventDescriptions = [];
  public companyInstallers = [];
  public simpleUsers = [];
  public countries = [];
  public superAdmins = [];
  public companies = [];
  public filteredUsers: any[] = [];
  public userPassword = '';
  public appVersionDetails = null;
  public eventPerPage = 30;
  public installerAssistance:
    | {
        time: number;
        issue: string;
      }
    | undefined = undefined;
  private registerCurrentUserDataSubscription: Subscription;

  constructor(private l: LoggerService, private p: PlatformService, private sanitizer: DomSanitizer, private helper: HelperService, private auth: AuthService) {
    this.currentUser = {
      ActivationToken: '',
      email: '',
      name: '',
      role: UserRole.SimpleUser,
      IsSocialAccount: false,
      SocketPort: 0,
      SocketToken: '',
      id: 0,
      phone: '',
      DateFormat: 0,
      passwordToken: '',
      isMaster: false,
      isPasswordSet: false,
      homeConfigurations: [],
      lastSeenReactions: [],
      reactions: [],
      reactionGroups: [],
      active: 0,
      country: '',
      company: '',
      logo: '',
      company_id: 0,
      panicSettings: null,
      regions: [],
      limits: null,
      canTransferDevice: false,
    };
    this.registerCurrentUserDataSubscription = this.onUserDataChanged.subscribe(this.auth.registerCurrentUserData);
  }

  ngOnDestroy(): void {
    this.registerCurrentUserDataSubscription.unsubscribe();
  }

  setActiveUser(userObject) {
    this.l.log('Gauti vartotojo duomenys:', 'UserService', userObject);
    if (userObject === null) {
      this.currentUser.ActivationToken = '';
      this.currentUser.email = '';
      this.currentUser.name = '';
      this.currentUser.role = UserRole.SimpleUser;
      this.currentUser.IsSocialAccount = false;
      this.currentUser.SocketPort = 0;
      this.currentUser.SocketToken = '';
      this.currentUser.id = 0;
      this.currentUser.phone = '';
      this.currentUser.DateFormat = 0;
      this.currentUser.passwordToken = '';
      this.currentUser.isPasswordSet = false;
      this.convertRawToHomeConfigurations(undefined);
      this.currentUser.lastSeenReactions = [];
      this.currentUser.reactions = [];
      this.currentUser.reactionGroups = [];
      this.currentUser.active = 0;
      this.currentUser.country = 'US';
      this.currentUser.company = '';
      this.currentUser.logo = '';
      this.currentUser.company_id = 0;
      this.currentUser.panicSettings = null;
      this.companyInstallers = [];
      this.eventDescriptions = [];
      this.simpleUsers = [];
      this.superAdmins = [];
      this.companies = [];
      this.userPassword = '';
      this.filteredUsers = [];
      this.currentUser.regions = [];
      this.currentUser.limits = null;
      this.currentUser.canTransferDevice = false;
    } else {
      if (userObject.name !== undefined) {
        this.currentUser.name = userObject.name;
      }
      if (userObject.activation_token !== undefined) {
        // reikalinga Register metu
        this.currentUser.ActivationToken = userObject.activation_token;
      }
      if (userObject.email !== undefined) {
        this.currentUser.email = userObject.email;
      }
      if (userObject.role !== undefined) {
        this.currentUser.role = userObject.role;
      }
      if (userObject.is_social_account !== undefined) {
        this.currentUser.IsSocialAccount = userObject.is_social_account;
      }
      if (userObject.socket_token !== undefined) {
        this.currentUser.SocketToken = userObject.socket_token;
      }
      if (userObject.socket_port !== undefined) {
        this.currentUser.SocketPort = userObject.socket_port;
      }
      if (userObject.id !== undefined) {
        this.currentUser.id = userObject.id;
      }
      if (userObject.phone !== undefined) {
        this.currentUser.phone = userObject.phone;
      }
      if (userObject.date_format !== undefined) {
        this.currentUser.DateFormat = userObject.date_format;
        if (this.p.isAndroid()) {
          this.p.androidHandler().setDateTimeFormat(this.currentUser.DateFormat);
        } else if (this.p.isApple()) {
          this.p.appleHandler().setDateTimeFormat.postMessage(this.currentUser.DateFormat);
        }
      }
      if (userObject.isPasswordSet !== undefined) {
        this.currentUser.isPasswordSet = userObject.isPasswordSet;
      }
      this.convertRawToHomeConfigurations(userObject.homeConfigurations);
      if (userObject.lastSeenReactions !== undefined) {
        this.currentUser.lastSeenReactions = userObject.lastSeenReactions;
      }
      if (userObject.reactions !== undefined) {
        this.currentUser.reactions = userObject.reactions;
        for (const iReaction of this.currentUser.reactions) {
          if (iReaction.image !== '' && !iReaction.image.startsWith('assets')) {
            iReaction.image = this.helper.getBaseNoPath() + '/' + iReaction.image;
          }
        }
      }
      if (userObject.reactionGroups !== undefined) {
        this.currentUser.reactionGroups = userObject.reactionGroups;
        for (const iGroup of this.currentUser.reactionGroups) {
          if (iGroup.icon !== '' && !iGroup.icon.startsWith('assets')) {
            iGroup.icon = this.helper.getBaseNoPath() + '/' + iGroup.icon;
          }
        }
      }
      if (userObject.active !== undefined) {
        this.currentUser.active = userObject.active;
      }
      if (userObject.country !== undefined) {
        this.currentUser.country = userObject.country;
      }
      if (userObject.company !== undefined) {
        this.currentUser.company = userObject.company;
      }
      if (userObject.logo !== undefined) {
        this.currentUser.logoDataForStorage = userObject.logo;
        this.currentUser.logo = userObject.logo !== '' ? this.sanitizer.bypassSecurityTrustUrl(userObject.logo) : '';
      }
      if (userObject.company_id !== undefined) {
        this.currentUser.company_id = userObject.company_id;
      }
      if (userObject.panic_settings !== undefined) {
        this.currentUser.panicSettings = userObject.panic_settings;
      }
      if (userObject.ipcoms !== undefined) {
        for (const iIpcom of userObject.ipcoms) {
          if (iIpcom.ipcom_logo === null) {
            continue;
          }
          iIpcom.logoDataForStorage = iIpcom.ipcom_logo;
          iIpcom.ipcom_logo = this.sanitizer.bypassSecurityTrustUrl(iIpcom.ipcom_logo);
        }
        this.currentUser.ipcoms = userObject.ipcoms;
      }
      if (userObject.regions !== undefined) {
        this.currentUser.regions = userObject.regions;
        for (const iRegion of this.currentUser.regions) {
          const n = (iRegion.api_host as string).indexOf('://');
          if (n !== -1) {
            iRegion.api_host_to_use = iRegion.api_host.substr(n + 3);
          } else {
            iRegion.api_host_to_use = iRegion.api_host;
          }
        }
      }
      this.currentUser.historySystems = userObject.historySystems;
      if (userObject.limits !== undefined) {
        this.currentUser.limits = userObject.limits;
      }
      if (userObject.appVersion !== undefined) {
        this.appVersionDetails = userObject.appVersion;
      }
      if (userObject.eventsPerPage !== undefined) {
        this.eventPerPage = userObject.eventsPerPage;
      }
      if (userObject.user_can_transfer_device !== undefined) {
        this.currentUser.canTransferDevice = userObject.user_can_transfer_device === 1;
      }
      if (userObject.settings !== undefined) {
        this.currentUser.settings = userObject.settings;
      }
    }

    this.onUserDataChangedSource.next(this.currentUser);
    this.saveCurrentUser();
  }

  public loadUserData() {
    const userString = localStorage.getItem('user_data');
    if (userString !== null) {
      const temp = JSON.parse(userString);
      if (temp.hasOwnProperty('Id')) {
        temp.id = temp.Id;
        delete temp.Id;
      }
      if (temp.hasOwnProperty('Email')) {
        temp.email = temp.Email;
        delete temp.Email;
      }
      if (temp.hasOwnProperty('Name')) {
        temp.name = temp.Name;
        delete temp.Name;
      }
      if (temp.hasOwnProperty('Role')) {
        temp.role = temp.Role;
        delete temp.Role;
      }
      if (temp.hasOwnProperty('Phone')) {
        temp.phone = temp.Phone;
        delete temp.Phone;
      }
      if (temp.hasOwnProperty('ipcoms') && temp.role !== UserRole.SuperAdmin) {
        delete temp.ipcoms;
      }
      if (temp.hasOwnProperty('settings') && temp.role !== UserRole.SuperAdmin) {
        delete temp.settings;
      }
      this.currentUser = temp;
      if (this.currentUser.logoDataForStorage !== undefined && this.currentUser.logoDataForStorage !== '') {
        this.currentUser.logo = this.sanitizer.bypassSecurityTrustUrl(this.currentUser.logoDataForStorage);
      }
      if (this.currentUser.hasOwnProperty('ipcoms')) {
        for (const iIpcom of this.currentUser.ipcoms) {
          if (iIpcom.logoDataForStorage !== undefined && iIpcom.logoDataForStorage !== null) {
            iIpcom.ipcom_logo = this.sanitizer.bypassSecurityTrustUrl(iIpcom.logoDataForStorage) as string;
          }
        }
      }
      this.l.log('Užkraunami išsaugoti vartotojo duomenys: ', 'UserService', this.currentUser);
      if (this.currentUser === null) {
        this.setActiveUser(null);
      }
      this.change();
    }
  }

  public saveCurrentUser() {
    const userString = JSON.stringify(this.currentUser);
    localStorage.setItem('user_data', userString);
  }

  private convertRawToHomeConfigurations(data: any) {
    this.currentUser.homeConfigurations = [];
    if (data === undefined || data.length === 0) {
      this.currentUser.homeConfigurations.push(this.getDefaultHomeConfiguration());
      return;
    }
    for (const iRow of data) {
      const config: HomeConfiguration = {
        elementOrder: JSON.parse(iRow.element_order),
        systemId: iRow.system_id,
        visibleAreas: JSON.parse(iRow.areas),
        visibleOutputs: JSON.parse(iRow.outputs),
        visibleSensors: JSON.parse(iRow.sensors),
        visibleCameras: JSON.parse(iRow.cameras),
        visibleThermostats: iRow.thermostats === undefined ? [] : JSON.parse(iRow.thermostats),
      };

      if (config.elementOrder.find((eo) => eo.type === 'cameras') === undefined) {
        config.elementOrder.push({
          position: 5,
          type: 'cameras',
          visible: true,
        });
      }
      if (config.elementOrder.find((eo) => eo.type === 'thermostats') === undefined) {
        config.elementOrder.push({
          position: 6,
          type: 'thermostats',
          visible: true,
        });
      }
      this.currentUser.homeConfigurations.push(config);
    }
  }

  public getDefaultHomeConfiguration(): HomeConfiguration {
    return {
      elementOrder: [
        { position: 1, type: 'events', visible: true },
        { position: 2, type: 'areas', visible: true },
        { position: 3, type: 'outputs', visible: true },
        { position: 4, type: 'sensors', visible: true },
        { position: 5, type: 'cameras', visible: true },
        { position: 6, type: 'thermostats', visible: true },
      ],
      systemId: 0,
      visibleAreas: [],
      visibleOutputs: [],
      visibleSensors: [],
      visibleCameras: [],
      visibleThermostats: [],
    };
  }

  public clearUserData() {
    localStorage.removeItem('user_data');
    localStorage.removeItem('last_system');
    localStorage.removeItem('last_system_data');
    localStorage.removeItem('login_type');
    this.setActiveUser(null);
  }

  public setLoginType(type: string) {
    localStorage.setItem('login_type', type);
  }

  public getLoginType(): string {
    return localStorage.getItem('login_type');
  }

  /** Iššaukia onUserDataChanged event */
  public change() {
    this.onUserDataChangedSource.next(this.currentUser);
  }
}
