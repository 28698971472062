import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BasePopupComponent } from 'src/app/popups/base-popup/base-popup.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { TAreaData } from 'src/app/models/area-data';
import { AreaService } from 'src/app/services/area.service';
import { RefreshService } from 'src/app/services/refresh.service';

@Component({
  selector: 'app-area-alarm-popup',
  templateUrl: './area-alarm-popup.component.html',
  styleUrls: ['./area-alarm-popup.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AreaAlarmPopupComponent extends LanguageAware implements OnInit {
  private reenableRefresher = false;
  public showPinKeypad = false;
  private actionPerformed = '';
  private pinToUse = '';
  private rememberToUse = false;
  @Input() activeArea: TAreaData = null;
  @ViewChild('basePopup') basePopup: BasePopupComponent;
  @Output() windowClosing: EventEmitter<any> = new EventEmitter();
  @Output() disarmClicked: EventEmitter<any> = new EventEmitter();
  @Output() actionStarted: EventEmitter<any> = new EventEmitter();
  @Output() actionCompleted: EventEmitter<any> = new EventEmitter();

  constructor(cdRef: ChangeDetectorRef, public areaService: AreaService, private r: RefreshService, private router: Router) {
    super(cdRef, true, false);
  }

  ngOnInit(): void {}

  public closeWindow() {
    if (this.reenableRefresher) {
      this.r.enableRefresher();
    }
    this.basePopup.hidePopup();
    this.windowClosing.emit();
  }

  public showWindow() {
    this.reenableRefresher = this.r.refresherIsEnabled;
    this.r.disableRefresher();
    this.basePopup.show();
  }

  public doDisarm() {
    this.disarmClicked.emit();
  }

  private doPerform() {
    const that = this;
    this.closeWindow();
    this.areaService.alarmedActionInProgress = true;
    this.actionStarted.emit();
    const url = this.actionPerformed === 'silence' ? '/silence-siren' : '/cancel-alarm';
    const systemId = this.systems.activeSystem.id;
    const remember = this.rememberToUse;
    const areaId = this.activeArea.id;
    this.api
      .post(
        url,
        {
          system_id: this.systems.activeSystem.id,
          area_id: this.activeArea.id,
          pin: this.pinToUse,
          remember_pin: this.rememberToUse,
        },
        true
      )
      .subscribe(
        (result) => {
          that.areaService.alarmedActionInProgress = false;
          that.actionCompleted.emit();
          if (!result.success) {
            that.toaster.postError(result.error);
          } else if (remember) {
            that.saveRemember(systemId, areaId);
          }
        },
        (error) => {
          that.actionCompleted.emit();
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
        }
      );
  }

  public doSilence() {
    this.actionPerformed = 'silence';
    if (this.activeArea.showKeypad) {
      this.showPinKeypad = true;
    } else {
      this.doPerform();
    }
  }

  public doCancel() {
    this.actionPerformed = 'cancel';
    if (this.activeArea.showKeypad) {
      this.showPinKeypad = true;
    } else {
      this.doPerform();
    }
  }

  public keypadCancelled() {
    this.showPinKeypad = false;
    this.pinToUse = '';
    this.rememberToUse = false;
    this.actionPerformed = '';
  }

  public pinConfirmed(pin: string, remember: boolean) {
    this.showPinKeypad = false;
    this.pinToUse = pin;
    this.rememberToUse = remember;
    this.doPerform();
  }

  private saveRemember(systemId: number, areaId: number) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const area = system.areas.find((a) => a.id === areaId);
    if (area === undefined) {
      return;
    }
    area.showKeypad = false;
    this.systems.saveActiveSystem(systemId);
  }

  public gotoFireReset() {
    this.router.navigate(['/reset-fire-sensors']);
  }
}
