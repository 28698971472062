import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LoggerService } from 'src/app/api/logger.service';
import { PlatformService } from 'src/app/api/platform.service';

@Component({
  selector: 'app-popup-menu',
  templateUrl: './popup-menu.component.html',
  styleUrls: ['./popup-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PopupMenuComponent implements OnInit, OnDestroy {
  private overlayItem: Element = null;
  private longPressStarted = false;
  private longPressTimer = null;
  public shouldFadeIn = false;
  private routerSubscriber = null;
  private longPressComfirmed = false;
  /**
   * Identifikatorius, kad keli popup nesusimaišytų
   */
  @Input() id = '';
  @Input() topAdjustment = 0;

  constructor(private l: LoggerService, private pl: PlatformService, private router: Router) {
    const that = this;
    this.routerSubscriber = this.router.events.subscribe(() => {
      that.hideMenu();
      that.routerSubscriber.unsubscribe();
      that.routerSubscriber = null;
    });
  }

  ngOnDestroy() {
    if (this.routerSubscriber !== null) {
      this.routerSubscriber.unsubscribe();
    }
  }

  ngOnInit(): void {}

  public showMenu() {
    this.overlayItem = document.querySelector('#popup_menu_overlay_' + this.id);
    this.overlayItem.parentNode.removeChild(this.overlayItem);
    document.body.appendChild(this.overlayItem);
    const contentElement = document.querySelector('#popup_menu_button_' + this.id) as HTMLElement;
    const thePopup = document.querySelector('#popup_menu_popup_' + this.id) as HTMLElement;
    const buttonContainer = document.querySelector('#popup_menu_tooltip_bottom_' + this.id) as HTMLElement;
    const masterContentCoontainer = document.querySelector('#content') as HTMLElement;

    let offsetTop = contentElement.offsetTop - masterContentCoontainer.scrollTop;
    let offsetLeft = 0; // contentElement.offsetLeft;
    const contentHeight = contentElement.offsetHeight;
    const contentWidth = contentElement.offsetWidth;
    this.l.log('Context ' + this.id + ' top:left', 'ContextMenu', {
      top: offsetTop,
      left: offsetLeft,
    });
    // offsetTop += contentHeight;
    offsetTop += this.topAdjustment;
    (this.overlayItem as HTMLElement).style.display = 'block';
    const availableWidth = this.pl.isDesktop() ? (this.overlayItem as HTMLElement).offsetWidth : screen.availWidth;
    const availableHeight = this.pl.isDesktop() ? (this.overlayItem as HTMLElement).offsetHeight : screen.availHeight;
    offsetLeft += (contentWidth - buttonContainer.offsetWidth) / 2;
    const rightSide = contentElement.offsetLeft + contentWidth;
    if (rightSide + (buttonContainer.offsetWidth - contentWidth) / 2 > availableWidth) {
      const moveLeftBy = rightSide + (buttonContainer.offsetWidth - contentWidth) / 2 - availableWidth;
      this.l.log('Popup iseina uz ekrano desinio krasto ribu', 'ContextMenu');
      offsetLeft -= moveLeftBy;
    }
    const actualOffsetLeft = contentElement.offsetLeft + (contentWidth - buttonContainer.offsetWidth) / 2;
    if (actualOffsetLeft + offsetLeft < 0) {
      this.l.log('Popup iseina uz ekrano kairio krasto ribu', 'ContextMenu');
      offsetLeft = -contentElement.offsetLeft;
    }
    if (offsetTop + thePopup.offsetHeight > availableHeight) {
      this.l.log('Popup iseina uz ekrano apacios ribu', 'ContextMenu');
      offsetTop = availableHeight - thePopup.offsetHeight;
      offsetTop -= 30; // Dar atimam tai, kas buvo prideta.
      offsetTop -= 5; // Dar papildomai atimam, kad butu mazas tarpelis apazioj nuo popup iki tritaskio mygtuko.
    }
    if (offsetTop < 0) {
      this.l.log('Popup iseins uz ekrano virsutines ribos', 'ContextMenu');
      // dar kazka reik del to padaryt
    }
    this.l.log('offsetTop', 'ContextMenu', {
      offsetTop,
      tt: masterContentCoontainer.scrollTop,
    });
    thePopup.style.left = contentElement.offsetLeft + 'px';
    thePopup.style.top = offsetTop + 'px';
    buttonContainer.style.left = offsetLeft + 'px';
    const that = this;
    setTimeout(() => {
      that.shouldFadeIn = true;
      that.longPressComfirmed = true;
    }, 50);
  }

  public hideMenu() {
    if (!this.longPressComfirmed) {
      return;
    }
    this.longPressComfirmed = false;
    this.longPressStarted = false;
    this.shouldFadeIn = false;
    this.l.log('hide menu', 'ContextMenu');
    (document.querySelector('#popup_menu_overlay_' + this.id) as HTMLElement).style.display = 'none';
    this.overlayItem.parentNode.removeChild(this.overlayItem);
    const container = document.querySelector('#popup_menu_' + this.id);
    container.appendChild(this.overlayItem);
  }
}
