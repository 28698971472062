import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app-settings';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss'],
})
export class HelpComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    this.tag = 'Help';
    this.background.setGray();
    this.headerBar.showHeader({
      backUrl: '/home',
      headerText: this.trans('general.help'),
    });
  }

  ngOnInit(): void {}

  public getAddress(): string {
    return AppSettings.address;
  }

  public getEmail(): string {
    return AppSettings.email;
  }

  public getPhone(): string {
    return AppSettings.phone;
  }

  public getUrl(): string {
    return AppSettings.companyUrl;
  }

  public clickEmail() {
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().openExternalUrl('mailto:' + AppSettings.email);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().openExternalUrl.postMessage('mailto:' + AppSettings.email);
    } else {
      window.open('mailto:' + AppSettings.email, '_blank');
    }
  }

  public clickPhone() {
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().openExternalUrl('tel:' + AppSettings.phone);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().openExternalUrl.postMessage('tel:' + AppSettings.phone);
    }
  }

  public clickUrl() {
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().openExternalUrl('http://' + AppSettings.companyUrl);
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().openExternalUrl.postMessage('http://' + AppSettings.companyUrl);
    } else {
      window.open('http://' + AppSettings.companyUrl, '_blank');
    }
  }

  public clickTicket() {
    this.router.navigate(['/help/ticket']);
  }

  public shouldShowLoggerbutton(): boolean {
    return AppSettings.showLogger;
  }

  public logResolution() {
    if (this.l.showLogView) {
      this.l.log('Ekrano rezoliucija: ', '', {
        plotis: screen.availWidth,
        aukstis: screen.availHeight,
      });
      this.l.log('Lango rezoliucija: ', '', {
        plotis: window.innerWidth,
        aukstis: window.innerHeight,
      });
    }
  }
}
