import { Injectable, Injector } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanyUserData, InstallerUserData, SuperUserData, SystemDeviceUserData, SystemProtegusUserData, UserData, UserRole, WithReactionsData } from 'src/api/v3/common';
import { AllUsersUserData } from 'src/api/v3/user';
import { autoinject } from 'src/shim';
import { DataTableGetter } from '../company/components/data-table/data-table.component';
import { TDeviceUser } from '../models/device-user';
import { HomeConfiguration } from '../models/home-configuration';
import { TUser } from '../models/user';
import { AuthService } from './auth.service';
import { LoggerService } from './logger.service';
import { PlatformService } from './platform.service';
import { RequestService } from './request.service';
import { ReactionService } from './system/reaction.service';

const defaultHomeConfiguration: HomeConfiguration = {
  elementOrder: [
    { position: 1, type: 'events', visible: true },
    { position: 2, type: 'areas', visible: true },
    { position: 3, type: 'outputs', visible: true },
    { position: 4, type: 'sensors', visible: true },
    { position: 5, type: 'cameras', visible: true },
    { position: 6, type: 'thermostats', visible: true },
  ],
  systemId: 0,
  visibleAreas: [],
  visibleOutputs: [],
  visibleSensors: [],
  visibleCameras: [],
  visibleThermostats: [],
};

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public get user(): TUser | undefined {
    const userId = this.auth.GetUserId();
    if (userId && this.users.has(userId)) {
      return this.users.get(userId);
    }
  }
  public readonly users = new Map<number, TUser>();
  public readonly systemUsers = new Map<number, Set<number>>();
  public readonly deviceUsers = new Map<number, TDeviceUser>();
  public readonly systemDeviceUsers = new Map<number, Set<number>>();
  constructor(
    private auth: AuthService,
    private platform: PlatformService,
    private sanitizer: DomSanitizer,
    private req: RequestService,
    private injector: Injector,
    private l: LoggerService
  ) {
    this.auth.onAccountOrRegionChnage.subscribe(() => {
      this.users.clear();
    });
    setTimeout(() => {
      if (this.auth.hasToken()) {
        this.auth.loadUserData().catch((e) => this.l.log('Napavyko gauti user duomenų', 'UserService', e.message));
      }
    }, 0);
  }

  private readonly reactions = autoinject(this.injector, ReactionService);

  public ingestUser(user?: UserData): TUser | undefined {
    this.l.log('Ingesting user', 'UserService', { user });
    if (!user) return;
    const {
      is_social_account: IsSocialAccount,
      socket_token: SocketToken,
      socket_port: SocketPort,
      date_format: DateFormat,
      user_can_transfer_device,
      homeConfigurations,
      ...rest
    } = user as UserData;

    if (this.platform.isAndroid()) this.platform.androidHandler().setDateTimeFormat(DateFormat);
    if (this.platform.isApple()) this.platform.appleHandler().setDateTimeFormat.postMessage(DateFormat);

    const processedUser: TUser = {
      IsSocialAccount,
      SocketToken,
      SocketPort,
      ActivationToken: '',
      DateFormat,
      reactions: [],
      reactionGroups: [],
      lastSeenReactions: [],
      passwordToken: '',
      canTransferDevice: !!user_can_transfer_device,
      company: '',
      logo: undefined,
      company_id: 0,
      panicSettings: undefined,
      regions: [],
      isMaster: false,
      ...rest,
      ipcoms: undefined,
      homeConfigurations: this.processHomeConfigurations(homeConfigurations),
    };

    if (user.role === UserRole.Company) {
      const { company, logo, panic_settings: panicSettings, reactionGroups, reactions, lastSeenReactions } = user as CompanyUserData;
      Object.assign(processedUser, { company, logo, panicSettings, lastSeenReactions } as Partial<TUser>);
      reactions.forEach((reaction) => this.reactions().ingestReaction(reaction));
      reactionGroups.forEach((group) => this.reactions().ingestReactionGroup(group));
    }
    if (user.role === UserRole.Installer) {
      const { company, company_id, logo } = user as InstallerUserData;
      Object.assign(processedUser, { company, company_id, logo: this.sanitizer.bypassSecurityTrustUrl(logo), logoDataForStorage: logo } as Partial<TUser>);
    }
    if (user.role === UserRole.SuperAdmin) {
      const { panic_settings: panicSettings, ipcoms, regions, settings, reactionGroups, reactions } = user as SuperUserData;
      Object.assign(processedUser, {
        panicSettings,
        ipcoms: ipcoms.map((ipcom) => ({
          ...ipcom,
          port: String(ipcom.port),
          ipcom_logo: this.sanitizer.bypassSecurityTrustUrl(ipcom.ipcom_logo),
          logoDataForStorage: ipcom.ipcom_logo,
        })),
        settings,
        regions: regions.map((r) => {
          const n = (r.api_host as string).indexOf('://');
          return { ...r, api_host_to_use: n !== -1 ? r.api_host.substr(n + 3) : r.api_host };
        }),
      } as Partial<TUser>);
      reactions.forEach((reaction) => this.reactions().ingestReaction(reaction));
      reactionGroups.forEach((group) => this.reactions().ingestReactionGroup(group));
    }
    this.l.log('Ingested user', 'UserService', { user: processedUser });
    this.users.set(user.id, processedUser);
    return processedUser;
  }

  public ingestDeviceUser(user?: SystemDeviceUserData): TDeviceUser | undefined {
    if (!user) return;
    const { keyboard_code, pin, canEditUsers: can_edit_users, can_see_events: canSeeEvents, enable_data, pgms, present, schedule_no, system_code_no, user_id, ...rest } = user;

    const processedUser: TDeviceUser = {
      code: keyboard_code ?? pin ?? '',
      zone_number: system_code_no !== undefined ? system_code_no : user_id !== undefined ? user_id : 0,
      enable_data: enable_data !== undefined ? enable_data : 0,
      schedule_no: schedule_no !== undefined ? schedule_no : 0,
      pgms: pgms !== undefined ? pgms : -1,
      can_edit_users,
      present: present ?? false,
      isOwner: false,
      ownerPermissions: undefined,
      canSeeEvents: canSeeEvents ?? false,
      ...rest,
    };
    if (!this.systemDeviceUsers.has(user.system_id)) this.systemDeviceUsers.set(user.system_id, new Set());
    this.systemDeviceUsers.get(user.system_id)?.add(user.id);
    this.deviceUsers.set(user.id, processedUser);

    return processedUser;
  }

  public ingestProtegusUser(user?: SystemProtegusUserData, system_id?: number): TUser | undefined {
    if (!user || !system_id) return;

    const { soc_account: IsSocialAccount, master: isMaster, ...rest } = user;

    const processedUser: TUser = {
      SocketToken: '',
      SocketPort: 0,
      ActivationToken: '',
      DateFormat: 0,
      passwordToken: '',
      IsSocialAccount,
      isMaster,
      reactions: [],
      reactionGroups: [],
      homeConfigurations: [],
      isPasswordSet: false,
      lastSeenReactions: [],
      logo: undefined,
      panicSettings: null,
      regions: [],
      canTransferDevice: false,
      ...rest,
    };
    if (!this.users.has(user.id)) {
      this.users.set(user.id, processedUser);
    }
    if (!this.systemUsers.has(system_id)) {
      this.systemUsers.set(system_id, new Set());
    }
    this.systemUsers.get(system_id)?.add(user.id);
    return processedUser;
  }

  public ingestUserLite(user: AllUsersUserData): TUser {
    if (!user) return;
    const { ...rest } = user;
    const processedUser: TUser = {
      ActivationToken: '',
      reactions: [],
      reactionGroups: [],
      homeConfigurations: [],
      isPasswordSet: false,
      lastSeenReactions: [],
      logo: undefined,
      panicSettings: null,
      regions: [],
      canTransferDevice: false,
      phone: '',
      IsSocialAccount: false,
      SocketToken: '',
      SocketPort: 0,
      DateFormat: 0,
      passwordToken: '',
      isMaster: false,
      company: '',
      company_id: 0,
      limits: {
        camera_count: 0,
        system_count: 0,
        default_camera_count: true,
        default_system_count: true,
      },
      ...rest,
    };
    if (!this.users.has(user.id)) {
      this.users.set(user.id, processedUser);
    }
    return processedUser;
  }

  private processHomeConfigurations(configurations?: any[]): HomeConfiguration[] {
    if (!configurations || !configurations.length) {
      return [defaultHomeConfiguration];
    }
    return configurations.map(({ element_order, system_id, areas, outputs, sensors, cameras, thermostats }) => {
      const elementOrder = (JSON.parse(element_order ?? 'null') ?? defaultHomeConfiguration.elementOrder) as HomeConfiguration['elementOrder'];
      if (!elementOrder.find((e) => e.type === 'cameras')) elementOrder.push(defaultHomeConfiguration.elementOrder[4]);
      if (!elementOrder.find((e) => e.type === 'thermostats')) elementOrder.push(defaultHomeConfiguration.elementOrder[5]);
      return {
        systemId: system_id,
        elementOrder,
        visibleAreas: JSON.parse(areas ?? 'null') ?? defaultHomeConfiguration.visibleAreas,
        visibleOutputs: JSON.parse(outputs ?? 'null') ?? defaultHomeConfiguration.visibleOutputs,
        visibleSensors: JSON.parse(sensors ?? 'null') ?? defaultHomeConfiguration.visibleSensors,
        visibleCameras: JSON.parse(cameras ?? 'null') ?? defaultHomeConfiguration.visibleCameras,
        visibleThermostats: JSON.parse(thermostats ?? 'null') ?? defaultHomeConfiguration.visibleThermostats,
      };
    });
  }

  public async loadAllUsers(): Promise<void> {
    const result = await this.req.user.getAllUsers({ all: true }).toPromise();
    if (result.success) {
      result.users.forEach((user) => this.ingestUserLite(user));
    }
  }

  public getUsersGetter(): DataTableGetter<TUser> {
    return async (current, columns, more) => {
      if (!more && current <= this.users.size) {
      } else {
        await this.loadAllUsers();
      }
      return [...this.users.values()].sort((a, b) => a.name.localeCompare(b.name)).filter((u) => u.active);
    };
  }
}
