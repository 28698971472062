import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { NewSystemService } from 'src/app/services/new-system.service';
import { TAreaData } from 'src/app/models/area-data';
import { Router } from '@angular/router';
import { TPgmData } from 'src/app/models/pgm-data';

@Component({
  selector: 'app-add-system-area',
  templateUrl: './add-system-area.component.html',
  styleUrls: ['./add-system-area.component.scss'],
})
export class AddSystemAreaComponent extends LanguageAware implements OnInit {
  public areaHasPgm = false;
  public showPgmData = false;
  public localArea: TAreaData = {
    name: '',
    queue_no: 1,
    zones: [],
    id: 0,
    canBypass: false,
    showKeypad: false,
    status: 0,
    statusTime: 0,
    alarmed: false,
    lastPerson: '',
    alarmTime: 0,
    alarmType: 0,
  };
  public localPgm: TPgmData = {
    id: 0,
    area_no: 0,
    control_area: false,
    enabled: false,
    icon: -1,
    name: '',
    queue_no: 0,
    on: false,
    pulseTime: 3,
    type: 'pulse',
    area_id: 0,
    pulse_activated_at: 0,
    io_ability: '',
    io_type: 0,
  };

  constructor(cdRef: ChangeDetectorRef, private ns: NewSystemService, private router: Router) {
    super(cdRef);
    if (this.ns.getCurrentArea() === 0) {
      this.headerBar.showHeader({
        headerText: this.trans('systems.titles.addNewArea'),
        backUrl: '/add-system-area-list',
      });
      const areas = this.ns.getAreas();
      let maxNumber = 0;
      for (const iArea of areas) {
        if (iArea.queue_no > maxNumber) {
          maxNumber = iArea.queue_no;
        }
      }
      this.localArea.queue_no = maxNumber + 1;
    } else {
      this.headerBar.showHeader({
        headerText: this.trans('systems.labels.editArea'),
        backUrl: '/add-system-area-list',
      });
      const pgms = this.ns.getPgms().filter((p) => p.enabled);
      let area = this.ns.getCurrentAreaObj();
      if (area === null) {
        area = this.ns.getArea(this.ns.getCurrentArea());
      }
      this.localArea.name = area.name;
      this.localArea.queue_no = area.queue_no;
      this.areaHasPgm = false;
      if (this.ns.getCurrentPgmObj() !== null) {
        this.localPgm = this.ns.getCurrentPgmObj();
        this.areaHasPgm = this.localPgm.area_no !== 0;
        this.showPgmData = this.localPgm.control_area && this.areaHasPgm;
      } else {
        for (const iPgm of pgms) {
          if (iPgm.control_area && iPgm.area_no === this.ns.getCurrentArea()) {
            this.localPgm.pulseTime = iPgm.pulseTime;
            this.localPgm.type = iPgm.type;
            this.localPgm.name = iPgm.name;
            this.localPgm.control_area = true;
            this.localPgm.area_no = iPgm.area_no;
            this.localPgm.queue_no = iPgm.queue_no;
            this.areaHasPgm = true;
            this.showPgmData = true;
            break;
          }
        }
      }
    }
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.router.navigate(['/add-system-area-list']);
    };
    this.footerBar.onButton2Click = () => {
      that.onNext();
    };
    this.background.setGray();
    this.progressBar.showProgress();
  }

  ngOnInit(): void {
    this.progressBar.setProgressData(5, 7);
  }

  public onNext() {
    if (this.localArea.queue_no < 1 || this.localArea.queue_no > 255) {
      this.toaster.postError(this.trans('systems.errors.badAreaNumber'));
      return;
    }
    let area: TAreaData = this.ns.getArea(this.localArea.queue_no);
    const isNew = area === undefined;
    if (this.localArea.name === '') {
      this.toaster.postError(this.trans('validation.required').replace(':attribute', this.trans('systems.labels.areaName')));
      return;
    }
    if (isNew) {
      area = this.localArea;
    } else {
      area.name = this.localArea.name;
      area.queue_no = this.localArea.queue_no;
    }
    if (this.areaHasPgm && !this.showPgmData) {
      // Nuzymeta varna
      const pgm = this.ns.getPgm(this.localPgm.queue_no);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      (pgm.control_area = false), (pgm.area_no = 0);
    } else if (this.areaHasPgm) {
      const pgms = this.ns.getPgms();
      for (const iPgm of pgms) {
        // nuresetinam visus esamus pgm, kurie priskirti sriciai.
        if (iPgm.area_no === this.localArea.queue_no) {
          iPgm.area_no = 0;
          iPgm.control_area = false;
        }
      }
      // o dabar priskiriam aktualu
      const pgm = this.ns.getPgm(this.localPgm.queue_no);
      pgm.pulseTime = this.localPgm.pulseTime;
      pgm.control_area = true;
      pgm.area_no = area.queue_no;
      pgm.type = this.localPgm.type;
    }

    if (isNew) {
      this.ns.addArea(area);
    }
    this.ns.setCurrentPgm(0);
    this.ns.setCurrentArea(0);
    this.router.navigate(['/add-system-area-list'], { replaceUrl: true });
  }

  public pgmModeSelect(mode: string) {
    this.localPgm.type = mode;
  }

  public selectPgm() {
    this.ns.setCurrentArea(this.localArea.queue_no, this.localArea);
    this.ns.setCurrentPgm(this.localPgm.queue_no, this.localPgm);
    this.router.navigate(['/add-system-area-pgms']);
  }
}
