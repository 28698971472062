import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { isNumeric } from 'src/shim';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEventData } from 'src/app/models/event-data';
import { CompanyEventsService } from 'src/app/services/company-events.service';
import { DateService } from 'src/app/services/date.service';
import { EventsService } from 'src/app/services/events.service';
import { IconsService } from 'src/app/services/icons.service';
import { WebSocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-cp-all-events',
  templateUrl: './cp-all-events.component.html',
  styleUrls: ['./cp-all-events.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CpAllEventsComponent extends LanguageAware implements OnInit, OnDestroy {
  @ViewChild('eventFilter') eventFilter;
  public eventsToShow = [];
  private refreshSubscriber = null;
  public headerText = '';
  private groupItem = null;
  public newEventFromId = -1;
  private eventSubscriber = null;
  private eventsSinceLastSave = -1;
  private eventSaveTimeout = null;

  constructor(
    cdRef: ChangeDetectorRef,
    private es: EventsService,
    public dt: DateService,
    public is: IconsService,
    ar: ActivatedRoute,
    ce: CompanyEventsService,
    ws: WebSocketService
  ) {
    super(cdRef);
    const groupNumber = ar.snapshot.paramMap.get('group');
    if (groupNumber !== null && isNumeric(groupNumber)) {
      const groupNumberInt = parseInt(groupNumber, 10);
      const found = this.us.currentUser.reactionGroups.find((rg) => rg.id === groupNumberInt);
      if (found !== undefined) {
        this.groupItem = found;
        this.newEventFromId = 0;
        const reactions = this.us.currentUser.reactions.filter((r) => r.group_id === this.groupItem.id);
        for (const iReaction of reactions) {
          const lastSeen = this.us.currentUser.lastSeenReactions.find((ls) => ls.reaction_id === iReaction.id);
          if (lastSeen !== undefined && this.newEventFromId < lastSeen.last_event_id) {
            this.newEventFromId = lastSeen.last_event_id;
          }
        }
      }
    } else {
      for (const iReaction of this.us.currentUser.reactions) {
        const lastSeen = this.us.currentUser.lastSeenReactions.find((ls) => ls.reaction_id === iReaction.id);
        if (lastSeen !== undefined && this.newEventFromId < lastSeen.last_event_id) {
          this.newEventFromId = lastSeen.last_event_id;
        }
      }
    }

    if (this.groupItem !== null) {
      this.headerText = this.groupItem.default_name ? this.trans('reactionNames.reaction_groups.' + this.groupItem.name) : this.groupItem.name;
    } else {
      this.headerText = this.trans('systems.menu.allEvents');
    }
    this.headerBar.showHeader({
      backUrl: '/company',
      showFilter: true,
    });
    this.background.setGray();
    this.loadEvents();
    this.refresher.enableRefresher();
    const that = this;
    this.refreshSubscriber = this.refresher.onRefresh.subscribe(() => {
      that.loadEvents();
    });
    if (this.groupItem !== null) {
      this.eventSubscriber = ce.onEventForward.subscribe((data) => {
        that.handleEvent(data.receivedEvent, data.reactionGroup);
      });
    } else {
      this.eventSubscriber = ws.onEventReceived.subscribe((event) => {
        that.handleEvent(event, undefined);
      });
    }
    this.eventSaveTimeout = setTimeout(() => {
      that.updateLastSeenEvents();
    }, 60000);
    this.headerBar.onActionButtonClicked = () => {
      that.showFilter();
    };
    this.headerBar.onXButtonClicked = () => {
      that.eventFilter.resetFields();
      that.loadEvents();
      that.headerBar.setXButtonVisibility(false);
    };
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.refreshSubscriber !== null) {
      this.refreshSubscriber.unsubscribe();
      this.refreshSubscriber = null;
    }
    if (this.eventSubscriber !== null) {
      this.eventSubscriber.unsubscribe();
      this.eventSubscriber = null;
    }
    if (this.eventSaveTimeout !== null) {
      clearTimeout(this.eventSaveTimeout);
      this.eventSaveTimeout = null;
    }
  }

  private loadEvents() {
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    const filtrationCriteria: {
      reactions?: number[];
    } = {};
    if (this.groupItem !== null) {
      const reactions = this.us.currentUser.reactions.filter((r) => r.group_id === this.groupItem.id);
      if (reactions.length === 0) {
        this.miniStatus.hide();
        return;
      }
      const reactionsToGet: number[] = [];
      for (const iReaction of reactions) {
        reactionsToGet.push(iReaction.id);
      }
      filtrationCriteria.reactions = reactionsToGet;
    }
    this.eventsSinceLastSave = -1;
    this.api.post('/all-events', filtrationCriteria, true).subscribe(
      (result) => {
        if (result.success) {
          const convertedEvents: TEventData[] = [];
          for (const iEvent of result.events) {
            const singleEvent = that.es.convertFromRaw(iEvent);
            const system = that.systems.getSystem(singleEvent.systemId);
            singleEvent.systemName = system === undefined ? '' : system.name;
            convertedEvents.push(singleEvent);
          }
          that.eventsToShow = that.es.groupEvents(convertedEvents, 0, 0);
          that.updateLastSeenEvents();
        } else {
          that.toaster.postError(result.error);
        }
        that.miniStatus.hide();
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }

  private updateLastSeenEvents() {
    if (this.eventsToShow.length === 0) {
      return;
    }
    const newEventId = this.eventsToShow[0].dayEvents[0].id;
    if (newEventId === this.newEventFromId) {
      return;
    }
    const firstTime = this.eventsSinceLastSave === -1;
    this.eventsSinceLastSave = 0;
    if (this.groupItem !== null) {
      this.updateLastSeenGroupEvents(newEventId, firstTime);
    } else {
      // visus
      this.updateLastSeenEventsAll(newEventId, firstTime);
    }
  }

  private updateLastSeenEventsAll(lastEventId: number, firstTime: boolean) {
    const that = this;
    this.api.post('/update-last-seen-reactions', { all: true, allEventId: lastEventId }, true).subscribe((result) => {
      if (!result.success) {
        return;
      }
      for (const iLastSeen of that.us.currentUser.lastSeenReactions) {
        iLastSeen.unseen_events = 0;
        iLastSeen.last_event_id = lastEventId;
        for (const iSystem of that.systems.systems) {
          if (iSystem.hasOwnProperty('activeGroupId')) {
            delete iSystem.activeGroupId;
            delete iSystem.activeGroupIcon;
          }
        }
      }
      if (!firstTime) {
        that.newEventFromId = lastEventId;
      }
    });
  }

  private updateLastSeenGroupEvents(lastEventId: number, firstTime: boolean) {
    const reactions = this.us.currentUser.reactions.filter((r) => r.group_id === this.groupItem.id);
    const reactionsToSend = [];
    for (const iReaction of reactions) {
      reactionsToSend.push({
        id: iReaction.id,
        event_id: lastEventId,
      });
    }
    const that = this;
    this.api.post('/update-last-seen-reactions', { reactions: reactionsToSend }, true).subscribe((result) => {
      if (!result.success) {
        return;
      }
      for (const iLastSeen of that.us.currentUser.lastSeenReactions) {
        for (const iReaction of reactions) {
          if (iLastSeen.reaction_id === iReaction.id) {
            iLastSeen.unseen_events = 0;
            iLastSeen.last_event_id = lastEventId;
            break;
          }
        }
      }
      if (!firstTime) {
        that.newEventFromId = lastEventId;
      }
      for (const iSystem of that.systems.systems) {
        if (iSystem.hasOwnProperty('activeGroupId') && iSystem.activeGroupId === that.groupItem.id) {
          delete iSystem.activeGroupId;
          delete iSystem.activeGroupIcon;
        }
      }
    });
  }

  private handleEvent(event: TEventData, reactionGroup: any) {
    if (this.groupItem !== null && (reactionGroup === undefined || reactionGroup.id !== this.groupItem.id)) {
      return;
    }
    const system = this.systems.getSystem(event.systemId);
    event.systemName = system === undefined ? '' : system.name;
    if (this.eventsToShow.length === 0) {
      this.eventsToShow = this.es.groupEvents([event], 0, 0);
    } else {
      this.eventsToShow[0].dayEvents.unshift(event);
    }
    if (this.eventsSinceLastSave === -1) {
      this.eventsSinceLastSave = 1;
    } else {
      this.eventsSinceLastSave++;
    }
  }

  public showFilter() {
    this.eventFilter.show();
  }

  public filtrationConfirmed(data: any) {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    this.api.post('/all-events', data, true).subscribe(
      (result) => {
        if (result.success) {
          const convertedEvents: TEventData[] = [];
          for (const iEvent of result.events) {
            const singleEvent = that.es.convertFromRaw(iEvent);
            const system = that.systems.getSystem(singleEvent.systemId);
            singleEvent.systemName = system === undefined ? '' : system.name;
            convertedEvents.push(singleEvent);
          }
          that.eventsToShow = that.es.groupEvents(convertedEvents, 0, 0);
          that.headerBar.setXButtonVisibility(true);
        } else {
          that.toaster.postError(result.error);
        }
        that.miniStatus.hide();
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }
}
