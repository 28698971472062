import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { NewSystemService } from 'src/app/services/new-system.service';
import { IconsService } from 'src/app/services/icons.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { SensorService } from 'src/app/services/sensor.service';
import { UserRole } from 'src/app/models/user-role';
import { BackBehaviourService } from 'src/app/services/back-behaviour.service';
import wifiAnimation from '../../../assets/animations/wifi.json';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeComponent extends LanguageAware implements OnInit, OnDestroy {
  private systemChangeSubscription = null;
  private refreshSubscription = null;

  constructor(
    cdRef: ChangeDetectorRef,
    ws: WebSocketService, // tik tam, kad butu uzkrauta
    private router: Router,
    private ns: NewSystemService,
    public is: IconsService,
    private senS: SensorService,
    private bh: BackBehaviourService
  ) {
    super(cdRef, false, false);
    this.tag = 'Home';
    if (this.systems.activeSystem !== null) {
      this.systems.setCurrentSystem(this.systems.activeSystem);
      this.headerBar.showHeader({
        showDotMenu: this.us.currentUser.role === UserRole.SimpleUser,
        showHamburger: true,
        showHome: this.us.currentUser.role !== UserRole.SimpleUser,
        showInstallerAssist: this.us.currentUser.role === UserRole.Company || this.us.currentUser.role === UserRole.SuperAdmin,
        showPlus: true,
      });
    } else {
      this.headerBar.showHeader({
        showHamburger: true,
      });
    }
    this.background.setUserSelected();
    const that = this;
    this.headerBar.setDotMenuItems([
      {
        name: this.trans('systems.menu.editHomeScreen'),
        action: '/home-configuration',
      },
      {
        name: this.trans('systems.menu.systemConfiguration'),
        action: '/system-configuration',
      },
    ]);
    this.headerBar.setHeaderBright();
    this.headerBar.onActionButtonClicked = () => {
      this.router.navigate(['/add-system']);
    };

    this.systemChangeSubscription = this.systems.onActiveSystemChange.subscribe(() => {
      that.handleSystemChange();
    });
    if (this.platform.isAndroid()) {
      if (this.us.currentUser.role === UserRole.SuperAdmin || this.us.currentUser.role === UserRole.Company) {
        this.bh.backUrl = this.g.getHomeUrl();
        this.bh.overrideBackBehaviour = true;
        this.platform.androidHandler().setBackButtonType('normal');
      } else {
        this.platform.androidHandler().setBackButtonType('close');
      }
    }
    this.refreshSubscription = this.refresher.onRefresh.subscribe(() => {
      that.doRefresh();
    });
    this.headerBar.onHomeButtonClicked = () => {
      that.systems.setCurrentSystem(null);
      that.router.navigate([that.g.getHomeUrl()]);
    };
    if (this.us.currentUser.role === UserRole.Company || this.us.currentUser.role === UserRole.SuperAdmin) {
      this.headerBar.onActionButtonClicked = () => {
        this.router.navigate([this.g.getHomeUrl() + '/request-assistance']);
      };
    }
    localStorage.setItem('ref', 'home');
    this.refresher.enableRefresher();
  }

  ngOnInit() {}

  ngOnDestroy() {
    if (this.systemChangeSubscription !== null) {
      this.systemChangeSubscription.unsubscribe();
      this.systemChangeSubscription = null;
    }
    if (this.refreshSubscription !== null) {
      this.refreshSubscription.unsubscribe();
      this.refreshSubscription = null;
    }
  }

  public newSystemClick() {
    this.ns.setDefaults();
    this.router.navigate(['/add-system']);
  }

  public hasSystem(): boolean {
    return this.systems.hasSystems() && this.systems.activeSystem !== null;
  }

  public systemNetState(): string {
    return this.systems.activeSystem.online ? this.trans('systems.statuses.online') : this.trans('systems.statuses.offline');
  }

  private handleSystemChange() {
    if (this.systems.activeSystem !== null) {
      if (this.us.currentUser.role === UserRole.SimpleUser) {
        this.headerBar.doShowDotMenu();
      }
      this.background.setUserSelected();
    }
  }

  public getPositionForType(type: string): string {
    const systemHomeConfig = this.us.currentUser.homeConfigurations.find((cf) => cf.systemId === this.systems.activeSystem.id);
    if (systemHomeConfig === undefined || systemHomeConfig.elementOrder.find((t) => t.type === type) === undefined) {
      return this.us.getDefaultHomeConfiguration().elementOrder.find((t) => t.type === type).position;
    }
    return systemHomeConfig.elementOrder.find((t) => t.type === type).position;
  }

  public shouldShowType(type: string) {
    if ([UserRole.SuperAdmin, UserRole.Installer, UserRole.Company].includes(this.us.currentUser.role)) {
      return true;
    }
    const systemHomeConfig = this.us.currentUser.homeConfigurations.find((cf) => cf.systemId === this.systems.activeSystem.id);
    if (systemHomeConfig === undefined) {
      return true;
    }
    const found = systemHomeConfig.elementOrder.find((t) => t.type === type);
    return found === undefined || found.visible;
  }

  public doRefresh() {
    this.log('Refresh');
    const that = this;
    this.senS.isWorking = true;
    this.api.get('/me', true, { systemId: this.systems.activeSystem === null ? 0 : this.systems.activeSystem.id }).subscribe(
      (result) => {
        if (result.success) {
          if (!that.api.hasToken()) {
            // Panašu, kad kol siuntė duomenis, vartotojas padarė logout.
            return;
          }
          that.us.setActiveUser(result);
          that.api.setToken(result.token);
          if (result.lastSystem !== undefined) {
            that.systems.setCurrentSystemFromRaw(result.lastSystem);
            if (that.systems.activeSystem !== null && that.systems.activeSystem.sensors.length > 0) {
              that.senS.watchSensors(true);
            }
          } else {
            that.toaster.postError(result.error);
          }
        }
      },
      (error) => {
        this.l.log('Nepavyko atnaujinti vartotojo duomenu', this.tag);
      }
    );
  }
}
