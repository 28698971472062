import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TEventData } from 'src/app/models/event-data';
import { UserRole } from 'src/app/models/user-role';
import { DateService } from 'src/app/services/date.service';
import { IconsService } from 'src/app/services/icons.service';
import { LanguageAware } from '../../language-aware';

@Component({
  selector: 'app-home-events',
  templateUrl: './home-events.component.html',
  styleUrls: ['./home-events.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeEventsComponent extends LanguageAware implements OnInit, OnDestroy {
  public visibleEvents: TEventData[] = [];
  private systemChangeListener = null;

  constructor(cdRef: ChangeDetectorRef, public dt: DateService, public is: IconsService, private router: Router) {
    super(cdRef, true, false);

    this.buildEvents();
    const that = this;
    this.systemChangeListener = this.systems.onActiveSystemChange.subscribe(() => {
      that.buildEvents();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.systemChangeListener !== null) {
      this.systemChangeListener.unsubscribe();
      this.systemChangeListener = null;
    }
  }

  private buildEvents() {
    let maxEventsToShow = 5;
    if (this.systems.activeSystem.hwType === 'G17F') {
      maxEventsToShow = 10;
    }
    this.visibleEvents = [];
    if (!this.systems.activeSystem.amIMaster && this.us.currentUser.role === UserRole.SimpleUser) {
      const thisUser = this.systems.activeSystem.device_users.find((u) => u.protegus_user_id === this.us.currentUser.id);
      if (thisUser !== undefined) {
        if (!thisUser.canSeeEvents) {
          return;
        }
      }
    }
    for (const iEvent of this.systems.activeSystem.events) {
      if (this.visibleEvents.length >= maxEventsToShow) {
        break;
      }
      if (this.systems.activeSystem.eventConfiguration.length === 0) {
        this.visibleEvents.push(iEvent);
      } else if (this.systems.activeSystem.eventConfiguration.indexOf(iEvent.reaction) !== -1) {
        this.visibleEvents.push(iEvent);
      }
    }
  }

  public openEvents() {
    this.router.navigate(['/events']);
  }
}
