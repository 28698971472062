<div id='apv_container'>
    <div id='apv_inner'>
        <div id='apv_name'>{{getTitle()}}</div>
        <div id='apv_old' *ngIf="oldVersion !== null"><span>{{trans('help.appOldVersionHad')}}</span>: v{{oldVersion.major}}.{{oldVersion.minor}} ({{oldVersion.build}})</div>
        <div id='apv_good' *ngIf="newerVersion === null">{{trans('help.appUpToDate')}}</div>
        <div id='apv_ver'><span>{{trans('help.appVersion').toLowerCase()}}</span> {{getCurrentVersion()}}</div>
        <div id='apv_build'><span>{{trans('help.appBuild').toLowerCase()}}</span> {{getBuild()}}</div>
        <div id='apv_date'>{{dt.formatDateTimeFromNumber(getDate() * 1000)}}</div>
        <div *ngIf="newerVersion !== null" id='apv_new'>{{trans('help.appNewVersionAvailable')}}</div>
        <div *ngIf="newerVersion !== null" id='apv_new_ver'>v{{newerVersion.major}}.{{newerVersion.minor}} ({{newerVersion.build}}) - {{dt.formatDateTimeFromNumber(newerVersion.date * 1000)}}</div>
        <div *ngIf="newerVersion !== null && platform.isMobile()" class="apv_update">{{trans('help.updateForMobile')}}</div>
        <div *ngIf="newerVersion !== null && platform.isDesktop()" class="apv_update">{{trans('help.updateForDesktop')}}</div>
    </div>
</div>