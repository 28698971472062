<div id="cpfu_header" class="padding16">
    <div id="cpfu_h_text">{{trans('systems.labels.searchResults')}}</div>
    <div id="cpfu_h_find" [inlineSVG]="'assets/images/find.svg'" (click)="openFind()"></div>
    <div id="cpfu_h_x" [inlineSVG]="'assets/images/close.svg'" (click)="close()"></div>
</div>
<div class="white_round" id="cpfu_users">
    <div *ngFor="let iUser of us.filteredUsers; let i = index">
        <div [ngClass]="{ 'button-double-line-arrow': true, 'button-double-line-arrow-border': i !== us.filteredUsers.length - 1 }" (click)="editUser(iUser.id)">
            <div class="dbla_text">
                <div>{{iUser.name}}<span class="cpfu_active" *ngIf="iUser.active === 1"><div [inlineSVG]="'assets/images/check.svg'"></div></span></div>
                <div>{{iUser.email}}</div>
            </div>
            <div class="dbla_arrow"><div [inlineSVG]="'assets/images/forward.svg'"></div></div>
        </div>
    </div>
</div>
<app-user-find-popup #searchPopup></app-user-find-popup>