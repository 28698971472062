<div class="account-switcher" [ngClass]="[forgetMode ? 'forgetMode' : '']">
  <div class="account" *ngFor="let a of availableAccounts" role="button" tabindex="0" (click)="click(a)" [ngClass]="a.active ? 'active' : ''">
    <span class="name">{{ a.name }}</span
    ><span class="email">{{ a.email }}</span
    ><span class="loginState">{{ a.token ? '' : trans('auth.labels.loggedOut') }}</span>
    <ng-container *ngIf="showAdvanced">
      <span class="region">{{ a.region.id | uppercase }}</span
      ><span class="role">{{ getRoleName(a.role) }}</span>
    </ng-container>
  </div>
</div>
