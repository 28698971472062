import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/login/login.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit {
  public readonly trans = (key: string) => this.ls.get(key);
  constructor(public login: LoginService, private ls: LanguageService) {}

  ngOnInit(): void {}
}
