<h4>User settings</h4>
<div id="userSettingsPanel" class="page">
  <app-string-input type="email" label="Email" [(value)]="vp.email" [hasError]="val.hasError('email')">
    <app-form-error *ngIf="val.hasError('email')">{{ val.getError('email') }}</app-form-error>
  </app-string-input>
  <app-string-input type="text" label="Name" [(value)]="vp.name" [hasError]="val.hasError('name')">
    <app-form-error *ngIf="val.hasError('name')">{{ val.getError('name') }}</app-form-error>
  </app-string-input>
  <app-string-input type="text" label="Phone" [(value)]="vp.phone" [hasError]="val.hasError('phone')">
    <app-form-error *ngIf="val.hasError('phone')">{{ val.getError('phone') }}</app-form-error>
  </app-string-input>
  <app-dropdown label="Country" [items]="countryList" [(value)]="vp.country" [hasError]="val.hasError('country')">
    <app-form-error *ngIf="val.hasError('country')">{{ val.getError('country') }}</app-form-error>
  </app-dropdown>
  <app-dropdown label="User role" [items]="shownRoleList" [(value)]="vp.role" [hasError]="val.hasError('role')">
    <app-form-error *ngIf="val.hasError('role')">{{ val.getError('role') }}</app-form-error>
  </app-dropdown>
  <app-boolean-input label="Active user" type="checkbox" [(value)]="vp.active"></app-boolean-input>
  <app-string-input *ngIf="isNew" type="password" label="Password" [(value)]="vp.password" [hasError]="val.hasError('password')">
    <app-form-error *ngIf="val.hasError('password')">{{ val.getError('password') }}</app-form-error>
  </app-string-input>
  <div class="mainButton">
    <a href="#" *ngIf="!isNew" (click)="$event.preventDefault(); popup.show()">Change password</a>
    <i *ngIf="!isNew && password && !val.hasError('password')">Password will be changed</i>
    <br />
    <app-button rounded="partial" (click)="onSubmit()">{{ isNew ? 'Create' : 'Save' }}</app-button>
  </div>
</div>
<app-popup #popup [content]="popupContent" (hidden)="vp.password = null"></app-popup>
<ng-template #popupContent let-hide="hide">
  <div class="passwordChange">
    <h5 (click)="hide()">Change password</h5>
    <app-string-input type="password" label="Password" [(value)]="vp.password" [hasError]="val.hasError('password')">
      <app-form-error *ngIf="val.hasError('password')">{{ val.getError('password') }}</app-form-error>
    </app-string-input>
    <div class="buttons">
      <app-button type="bare-secondary" (click)="hide()">Cancel</app-button>
      <app-button type="bare-secondary" (click)="hide(true)">Save</app-button>
    </div>
  </div>
</ng-template>
