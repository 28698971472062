import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopupType } from 'src/app/models/popup-type';
import { PopupService } from 'src/app/services/popup.service';
import { WifiService } from 'src/app/services/wifi.service';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-wifi-details',
  templateUrl: './wifi-details.component.html',
  styleUrls: ['./wifi-details.component.scss']
})
export class WifiDetailsComponent extends LanguageAware implements OnInit {
  constructor(
    cdRef: ChangeDetectorRef,
    public wfs: WifiService,
    private pp: PopupService,
    private router: Router,
  ) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.wifiConfiguration'),
      backUrl: '/add-system',
    });
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.wfs.stopWifiDiscovery();
      this.router.navigate(['/add-system']);
    };
    this.background.setGray();
  }

  ngOnInit(): void {
  }

  public wifiSelected(ssid: string) {
    this.wfs.selectOther(ssid);
    const that = this;
    this.pp.showPopup({
      headerText: this.trans('systems.labels.wifiPassword'),
      field1: {
        oldValue: '',
      },
      onSubmit: (res) => {
        that.nextStep(res);
      }
    }, PopupType.Password);
  }

  public enterSsid() {
    const that = this;
    this.pp.showPopup({
      headerText: this.trans('systems.labels.wifiName'),
      field1: {
        oldValue: this.wfs.getSelectedOtherNetwork() ?? '',
      },
      onSubmit: (res) => {
        that.wfs.selectOther(res);
        that.pp.showPopup({
          headerText: this.trans('systems.labels.wifiPassword'),
          field1: {
            oldValue: '',
          },
          onSubmit: (resPass) => {
            that.nextStep(resPass);
          }
        }, PopupType.Password);
      }
    }, PopupType.String);
  }

  private nextStep(password: string) {
    this.wfs.connectToDeviceWifi(this.wfs.getSelectedOtherNetwork(), password);
    this.router.navigate(['/device-ap-connect']);
  }
}
