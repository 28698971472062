<app-login-layout>
  <div class="mainContent">
    <div>
      <h2>{{ trans('auth.labels.resetPassword') }}</h2>
      <p>{{ trans('auth.labels.resetPasswordPrompt') }}</p>
    </div>
    <app-string-input [label]="trans('auth.fields.email')" type="email" name="email" autocomplete="email" [(value)]="login.email" [hasError]="val.hasError('email')">
      <app-form-error *ngIf="val.hasError('email')">{{ val.getError('email') }}</app-form-error>
    </app-string-input>
    <app-button class="mainButton" (click)="reset()">{{ trans('auth.labels.resetPasswordButton') }}</app-button>
  </div>
</app-login-layout>
