<div class="container" (blur)="show = false">
  <div class="current" role="button" [attr.aria-label]="trans('auth.selectLanguage')" (click)="show = true" (keyup.enter)="show = true" tabindex="0">
    {{ locale.locale | uppercase }}
  </div>
  <div class="dropdown" [style.display]="show ? 'inherit' : 'none'">
    <div class="item" *ngFor="let l of LanguageList" (click)="selectLanguage(l)" (keyup.enter)="selectLanguage(l)" tabindex="0" role="button">
      {{ l | uppercase }}<span>{{ locale.getLocaleName(l) }}</span>
    </div>
  </div>
</div>
<ng-template *ngIf="show" [appTeleport]="'body'">
  <div (click)="show = false" class="closeDiv"></div>
</ng-template>
