import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PhoneStateService {
  private onLogSource = new Subject<{ message: string; tag: string }>();
  public onLog = this.onLogSource.asObservable();
  private onFcmTokenSource = new Subject<{
    token: string;
    deviceId: string;
    appVersion: string;
  }>();
  public onFcmToken = this.onFcmTokenSource.asObservable();
  private onRequestIconPathsSource = new Subject<{
    type: string;
    version: string;
  }>();
  public onRequestIconPaths = this.onRequestIconPathsSource.asObservable();
  private onPhoneResumedSource = new Subject<void>();
  public onPhoneResumed = this.onPhoneResumedSource.asObservable();

  constructor() {
    window.phoneStateJsi = {
      log: (message: string, tag: string) => {
        this.onLogSource.next({ tag, message });
      },
      setFcmToken: (token: string, deviceId: string, appVersion: string) => {
        this.onFcmTokenSource.next({ token, deviceId, appVersion });
      },
      getIconPaths: (type: string, version: string) => {
        this.onRequestIconPathsSource.next({ type, version });
      },
      onResume: () => {
        this.onPhoneResumedSource.next();
      },
      onSuspend: () => {
        // nieko nedarom kol kas
      },
    };
  }
}
