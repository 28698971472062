<app-login-layout>
  <div class="mainContent">
    <div>
      <h2>{{ trans('auth.labels.createPassword') }}</h2>
      <p>{{ trans('auth.labels.createPasswordPrompt') }}</p>
    </div>
    <form (submit)="next()">
      <input type="hidden" [value]="login.email" />
      <app-string-input
        [label]="trans('auth.form.newpassword')"
        type="password"
        name="password"
        autocomplete="new-password"
        [(value)]="login.password"
        [hasError]="val.hasError('email')"
      >
        <app-form-error *ngIf="val.hasError('password')">{{ val.getError('password') }}</app-form-error>
      </app-string-input>
      <app-button class="mainButton">{{ trans('auth.loginNext') }}</app-button>
    </form>
  </div>
</app-login-layout>
