import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PopupType } from 'src/app/models/popup-type';
import { SlideoutItem } from 'src/app/models/slideout-item';
import { DateService } from 'src/app/services/date.service';
import { LanguageService } from 'src/app/services/language.service';
import { PopupService } from 'src/app/services/popup.service';
import { SystemsService } from 'src/app/services/systems.service';
import { UserService } from 'src/app/services/user.service';
import { DateInput, DateTime } from 'luxon';

@Component({
  selector: 'app-event-filter-popup',
  templateUrl: './event-filter-popup.component.html',
  styleUrls: ['./event-filter-popup.component.scss'],
})
export class EventFilterPopupComponent implements OnInit {
  @ViewChild('basePopup') basePopup;
  @Output() doFilter: EventEmitter<any> = new EventEmitter();
  public systemText = '';
  private selectedSystemId = -1;
  public dateFromText = '';
  public dateToText = '';
  public reactionText = '';
  private selectedReactionId = -1;
  public activeQuickButton = 0;

  constructor(public lang: LanguageService, private pp: PopupService, private ss: SystemsService, private dt: DateService, private us: UserService) {
    this.resetFields();
  }

  ngOnInit(): void {}

  public show() {
    this.basePopup.show();
  }

  public pickSystem() {
    const that = this;
    const systemList: SlideoutItem[] = [{ value: -1, text: this.lang.get('events.filter.systems.all') }];
    for (const iSystem of this.ss.systems) {
      systemList.push({
        value: iSystem.id,
        text: iSystem.name,
      });
    }
    this.pp.showSlideout(
      {
        headerText: this.lang.get('events.filter.systems.label'),
        items: systemList,
        onSubmit: (res) => {
          that.selectedSystemId = res.value;
          that.systemText = res.text;
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public pickReaction() {
    const that = this;
    const reactionList: SlideoutItem[] = [{ value: -1, text: this.lang.get('events.filter.type.options.all') }];
    for (const iReaction of this.us.currentUser.reactions) {
      reactionList.push({
        value: iReaction.id,
        text: iReaction.default_name ? this.lang.get('reactionNames.reactions.' + iReaction.name) : iReaction.name,
      });
    }
    this.pp.showSlideout(
      {
        headerText: this.lang.get('events.filter.type.label'),
        items: reactionList,
        onSubmit: (res) => {
          that.reactionText = res.text;
          that.selectedReactionId = res.value;
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public changeFromDate() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.lang.get('events.filter.date_from'),
        field1: {
          maxLen: 10,
          oldValue: this.dateFromText,
        },
        onSubmit: (res) => {
          that.dateFromText = res;
          that.activeQuickButton = 0;
        },
      },
      PopupType.String
    );
  }

  public changeToDate() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.lang.get('events.filter.date_to'),
        field1: {
          maxLen: 10,
          oldValue: this.dateToText,
        },
        onSubmit: (res) => {
          that.dateToText = res;
          that.activeQuickButton = 0;
        },
      },
      PopupType.String
    );
  }

  public quickButtonClicked(buttonNumber: number) {
    this.dateToText = this.dt.formatDate(new Date());
    if (buttonNumber === 1) {
      const dayAgo = DateTime.now().minus({ days: 1 });
      this.dateFromText = this.dt.formatDate(dayAgo.toJSDate());
    } else if (buttonNumber === 2) {
      const weekAgo = DateTime.now().minus({ weeks: 1 });
      this.dateFromText = this.dt.formatDate(weekAgo.toJSDate());
    } else if (buttonNumber === 3) {
      const mongthAgo = DateTime.now().minus({ months: 1 });
      this.dateFromText = this.dt.formatDate(mongthAgo.toJSDate());
    }
    this.activeQuickButton = buttonNumber;
  }

  public cancelClick() {
    this.basePopup.hidePopup();
  }

  public performFilter() {
    this.doFilter.emit({
      date_from: DateTime.fromRFC2822(this.dateFromText).toFormat('yyyy-LL-dd'),
      date_to: DateTime.fromRFC2822(this.dateToText).toFormat('yyyy-LL-dd'),
      system: this.selectedSystemId,
      type: this.selectedReactionId,
    } as any);
    this.basePopup.hidePopup();
  }

  public resetFields() {
    this.systemText = this.lang.get('events.filter.systems.all');
    this.reactionText = this.lang.get('events.filter.type.options.all');
    this.dateToText = this.dt.formatDate(new Date());
    const monthAgo = DateTime.now().minus({ months: 1 });
    this.dateFromText = this.dt.formatDate(monthAgo.toJSDate());
    this.activeQuickButton = 3;
    this.selectedReactionId = -1;
    this.selectedSystemId = -1;
  }
}
