<div class="page" [ngClass]="columns.length === 0 ? 'nocols' : ''">
  <div>
    <div class="header">
      <h4>Events</h4>
      <app-company-columns [items]="columnConfig" [(value)]="columns" #cols></app-company-columns>
    </div>
    <app-events-data-table [columns]="columns" [criteria]="criteria" (modifyColumns)="cols.show()"></app-events-data-table>
  </div>

  <div [style.display]="columns.length === 0 ? 'none' : 'inherit'">
    <app-company-events-filter (criteriaChanged)="criteria = $event"></app-company-events-filter>
  </div>
</div>
