import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { UserRole } from 'src/app/models/user-role';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-cp-edit-group',
  templateUrl: './cp-edit-group.component.html',
  styleUrls: ['./cp-edit-group.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CpEditGroupComponent extends LanguageAware implements OnInit {
  public groupItem = null;
  private originalAssignements = [];
  public fileToUploadUrl: any = null;
  private loadedIconSize = null;
  private fileToUpload: File | null = null;
  public iconChanged = false;

  constructor(cdRef: ChangeDetectorRef, private es: EditSystemService, private router: Router, private sanitizer: DomSanitizer) {
    super(cdRef);
    this.groupItem = this.es.getEditableComponent(TEditableComponent.ReactionGroup);
    this.headerBar.showHeader({
      headerText: this.groupItem.id === 0 ? this.trans('settings.reactions.labels.addGroup') : this.trans('settings.reactions.labels.editGroup'),
      backUrl: this.g.getHomeUrl() + '/edit-groups',
      actionButtonText: this.groupItem.id === 0 ? undefined : this.trans('general.delete'),
    });
    const that = this;
    this.headerBar.onActionButtonClicked = () => {
      that.doDelete();
      that.router.navigate([this.g.getHomeUrl() + '/edit-groups']);
    };
    this.headerBar.onBackClicked = () => {
      that.revert();
      that.es.endComponentEdit(TEditableComponent.ReactionGroup);
    };
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    this.footerBar.onButton1Click = () => {
      that.revert();
      that.es.endComponentEdit(TEditableComponent.ReactionGroup);
      that.router.navigate([this.g.getHomeUrl() + '/edit-groups']);
    };
    this.footerBar.onButton2Click = () => {
      that.doSave();
    };
    this.background.setGray();
    this.populateAssignements();
  }

  ngOnInit(): void {}

  private doDelete() {
    const that = this;
    const groupId = this.es.getId(TEditableComponent.ReactionGroup);
    this.us.currentUser.reactionGroups.splice(
      this.us.currentUser.reactionGroups.findIndex((gr) => gr.id === groupId),
      1
    );
    this.api.post('/delete/reaction-group', { group_id: this.groupItem.id }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.toaster.postError(result.error);
          that.revert();
        } else {
          const reactionsToUpdate = that.us.currentUser.reactions.filter((r) => r.group_id === groupId);
          for (const iReaction of reactionsToUpdate) {
            iReaction.group_id = 0;
          }
        }
        that.es.endComponentEdit(TEditableComponent.ReactionGroup);
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.revert();
        that.es.endComponentEdit(TEditableComponent.ReactionGroup);
      }
    );
  }

  private revert() {
    for (const iAssignement of this.originalAssignements) {
      const found = this.us.currentUser.reactions.find((r) => r.id === iAssignement.reactionId);
      if (found) {
        found.group_id = iAssignement.groupId;
      }
    }
    const groupId = this.es.getId(TEditableComponent.ReactionGroup);
    if (groupId !== 0) {
      const foundGroup = this.us.currentUser.reactionGroups.find((gr) => gr.id === groupId);
      if (foundGroup === undefined) {
        this.us.currentUser.reactionGroups.push(this.es.getComponentBeforeModification(TEditableComponent.ReactionGroup));
      } else {
        const original = this.es.getComponentBeforeModification(TEditableComponent.ReactionGroup);
        foundGroup.name = original.name;
        foundGroup.default_name = original.default_name;
        foundGroup.icon = original.icon;
      }
    } else {
      const indexFound = this.us.currentUser.reactionGroups.findIndex((gr) => gr.id === 0);
      if (indexFound === -1) {
        return;
      }
      this.us.currentUser.reactionGroups.splice(indexFound, 1);
    }
  }

  private doSave() {
    if (this.iconChanged) {
      if (this.loadedIconSize !== null) {
        if (this.loadedIconSize.width !== this.loadedIconSize.height) {
          this.toaster.postError(this.trans('settings.errors.incorrectImageSize'));
          return false;
        }
      }
      if (this.miniStatus.isVisible()) {
        this.miniStatus.flash();
        return;
      }
      this.miniStatus.show(this.trans('general.pleaseWait'));
    }

    const that = this;
    if (this.groupItem.id === 0) {
      this.us.currentUser.reactionGroups.push(that.groupItem);
    }
    const groupId = this.es.getId(TEditableComponent.ReactionGroup);
    const reactionsToAssign = this.us.currentUser.reactions.filter((r) => r.group_id === groupId).map((rr) => rr.id);
    that.groupItem.assignedReactions = reactionsToAssign;
    this.api.post('/edit/reaction-group', that.groupItem, true).subscribe(
      (result) => {
        if (result.success) {
          if (groupId === 0) {
            const groupToUpdate = this.us.currentUser.reactionGroups.find((gr) => gr.id === 0);
            groupToUpdate.id = result.id;
            const reactionsToUpdate = that.us.currentUser.reactions.filter((r) => r.group_id === 0);
            for (const iReaction of reactionsToUpdate) {
              iReaction.group_id = result.id;
            }
          }
          if (that.iconChanged) {
            that.uploadIcon();
          } else {
            that.es.endComponentEdit(TEditableComponent.ReactionGroup);
          }
        } else {
          that.toaster.postError(result.error);
          that.revert();
          that.miniStatus.hide();
          that.es.endComponentEdit(TEditableComponent.ReactionGroup);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
        that.revert();
        that.es.endComponentEdit(TEditableComponent.ReactionGroup);
      }
    );
    if (!this.iconChanged) {
      that.router.navigate([this.g.getHomeUrl() + '/edit-groups']);
    }
  }

  private uploadIcon() {
    const that = this;
    const headers = this.api.getAuthorizationHeader();
    const formData = new FormData();
    formData.append('id', this.groupItem.id);
    if (this.iconChanged) {
      headers.append('Content-type', 'multipart/form-data');
      formData.append('image', this.fileToUpload, this.fileToUpload.name);
    }
    this.api
      .httpSender()
      .post(this.api.getUrl('/reaction-group/icon'), formData, { headers })
      .subscribe(
        (result) => {
          if ((result as any).success) {
            that.groupItem.icon = that.helper.getBaseNoPath() + '/' + (result as any).icon;
            that.us.saveCurrentUser();
            that.es.endComponentEdit(TEditableComponent.ReactionGroup);
            that.router.navigate([this.g.getHomeUrl() + '/edit-groups']);
          }
          that.miniStatus.hide();
        },
        (error) => {
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
          that.miniStatus.hide();
        }
      );
  }

  public addReaction() {
    this.es.beginComponentEdit(TEditableComponent.Reaction, 0, {
      id: 0,
      monas_id: 0,
      name: '',
      active: false,
      custom_sound: 0,
      company_id: this.us.currentUser.role === UserRole.Company ? this.us.currentUser.id : 0,
      group_id: this.groupItem.id,
      image: '',
      image_png: '',
      default_name: this.us.currentUser.role === UserRole.Company ? false : true,
      redirect_to: 0,
    });
    this.router.navigate([this.g.getHomeUrl() + '/edit-reaction']);
  }

  public openReaction(reaction: any) {
    this.es.beginComponentEdit(TEditableComponent.Reaction, reaction.id, reaction);
    this.router.navigate([this.g.getHomeUrl() + '/edit-reaction']);
  }

  public reactionChecked(reaction: any) {
    if (reaction.group_id === this.groupItem.id) {
      reaction.group_id = 0;
    } else {
      reaction.group_id = this.groupItem.id;
    }
  }

  private populateAssignements() {
    this.originalAssignements = [];
    for (const iReaction of this.us.currentUser.reactions) {
      this.originalAssignements.push({
        reactionId: iReaction.id,
        groupId: iReaction.group_id,
      });
    }
  }

  public changeName(value: string) {
    this.groupItem.name = value;
    if (this.us.currentUser.role === UserRole.Company) {
      this.groupItem.default_name = false;
    }
  }

  public handleNewFile(fileArray: FileList) {
    const file = fileArray.item(0);
    const mimeType = file.type;
    if (!mimeType.startsWith('image/svg')) {
      this.toaster.postError(this.trans('settings.errors.notAnSvg'));
      return;
    }
    const that = this;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ($event) => {
      that.fileToUploadUrl = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
    };
    this.fileToUpload = file;
    this.iconChanged = true;
  }

  public imageLoaded(img) {
    this.loadedIconSize = {
      width: img.naturalWidth,
      height: img.naturalHeight,
    };
  }
}
