import { Environment, UrlProtocol } from './environment.types';

export const environment: Environment = {
  production: true,
  regions: [
    {
      id: 'be',
      backEndHost: 'beta2.protegus.app',
      port: '',
      protocol: UrlProtocol.https,
      gmtOffsetStart: 0,
      gmtOffsetEnd: 0,
    },
    {
      id: 'l',
      backEndHost: 'localhost',
      port: '8000',
      protocol: UrlProtocol.http,
      gmtOffsetStart: 0,
      gmtOffsetEnd: 0,
    },
  ],
  logToConsole: true,
  showLogger: true,
  showHelp: true,
  showAppVersion: true,
  appVersionMajor: 0,
  appVersionMinor: 0,
  appVersionBuild: 0,
  appVersionDate: 0,
  deviceTransferEnabled: true,
  deviceTransferToAppEnabled: true,
  limitationSystemEnabled: true,
  loadAllUsersForSU: true,
  loadAllSystemsForSU: false,
  canAutoCreateAccounts: true,
  onlySUcanAddRemoveUsers: false,
  googleLoginSupported: true,
  appleLoginSupported: false,
  title: 'Protegus v2 BETA',
  address: 'Draugystės g. 17, LT-51229 Kaunas, Lietuva',
  email: 'support@trikdis.lt',
  phone: '+370 37 408040',
  companyUrl: 'https://trikdis.lt',
  takeActionButtonDuration: 300,
  canDeleteUserFromList: false,
  canDeleteSystemFromList: false,
  showConfig: true,
  googleClientIdWeb: '131091336687-8kg1fhrbstoakft532hajc6kmke10ft3.apps.googleusercontent.com',
  sentry: {
    dsn: 'https://a752ebe5aaaa4ab2bc0b802920ebe10e@app.glitchtip.com/1885',
    environment: 'beta',
  },
  localReport: true,
  companyDesktopEnabled: true,
  greSiteKey: '6LeDpgcjAAAAAJp5l5I-K4_9ZyG3UEZiyjFRzxhc',
  wifiGateDevice37Name: 'WP17',
  primaryColor: '#0070A7',
  secondaryColor: '#EF476F',
};

export const AppSettings = environment;
