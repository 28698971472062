import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RequestService } from 'src/app/api/request.service';
import { LoginService } from '../login.service';

@Injectable({
  providedIn: 'root',
})
export class ActivationGuard implements CanActivate {
  constructor(private login: LoginService, private router: Router, private req: RequestService) {}
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const hash = route.paramMap.get('hash');
    if (hash) {
      const res = await this.req.login.activate({ hash }).toPromise();
      if (res.success === false) {
        // TODO: Show error message somewhere
        return this.router.parseUrl('/login');
      }
      this.login.passwordToken = res.password_hash;
      this.login.email = res.email;
      return this.router.parseUrl('/login/register/password');
    }
    return this.router.parseUrl('/login');
  }
}
