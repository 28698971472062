import { Injector, Type } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface UResolvableClass {
  resolve(injector: Injector, route: ActivatedRouteSnapshot): Promise<void> | Observable<void>;
}

export type UResolvable<C extends Type<unknown> & UResolvableClass> = InstanceType<C>;

export const isUResolvable = (value: any): value is UResolvableClass => 'resolve' in value && typeof value.resolve === 'function';
