import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { LanguageService } from './language.service';
import { TZoneData } from '../models/zone-data';
import { ToasterService } from './toaster.service';
import { SystemsService } from './systems.service';
import { TAreaData } from '../models/area-data';
import { MiniStatusService } from './mini-status.service';
import { LoggerService } from '../api/logger.service';

@Injectable({
  providedIn: 'root',
})
export class ZoneService {
  constructor(
    private rs: ApiRequestService,
    private ls: LanguageService,
    private toaster: ToasterService,
    private systems: SystemsService,
    private ms: MiniStatusService,
    private l: LoggerService
  ) {}

  public getZoneStatuses(areaNumber: number, systemId: number, callback: any) {
    const that = this;
    this.rs
      .get('/area-zones', true, {
        system_id: systemId,
        area_number: areaNumber,
      })
      .subscribe((result) => {
        this.l.log('gautos zonos', 'ZoneService', result);
        if (result.success && callback !== null) {
          return callback(result.zones);
        }
        that.toaster.postError(result.error);
      });
  }

  public performBypass(zone: TZoneData, pin?: string, rememberPin?: boolean, area?: TAreaData, callback?: any) {
    const areaToUse = area === undefined ? this.systems.activeArea : area;
    const oldValue = zone.bypassed;
    zone.bypassed = !zone.bypassed;
    const systemId = this.systems.activeSystem.id;
    const areaId = areaToUse.id;
    const oldRemember = !areaToUse.showKeypad;
    const zoneNumber = zone.queue_no;
    if (rememberPin !== undefined) {
      areaToUse.showKeypad = !rememberPin;
    }
    const that = this;
    this.rs
      .post(
        '/bypass-single-zone',
        {
          system_id: systemId,
          area_id: areaId,
          zone: zone.queue_no,
          bypassed: zone.bypassed,
          pin,
          remember_pin: rememberPin,
        },
        true
      )
      .subscribe(
        (result) => {
          that.ms.hide();
          if (!result.success) {
            that.toaster.postError(result.error);
            that.revertZoneBypass(systemId, zoneNumber, areaId, oldRemember, oldValue);
          } else {
            that.checkToSave(systemId, zoneNumber);
            if (callback !== undefined) {
              callback();
            }
          }
        },
        (error) => {
          that.ms.hide();
          that.revertZoneBypass(systemId, zoneNumber, areaId, oldRemember, oldValue);
        }
      );
  }

  private checkToSave(systemId: number, zoneNumber: number) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const zone = system.zones.find((z) => z.queue_no === zoneNumber);
    if (zone === undefined) {
      return;
    }
    if (system.panel >= 0x10 && system.panel < 0x20) {
      zone.bypassed = false;
    }
  }

  private revertZoneBypass(systemId: number, zoneNumber: number, areaId: number, rememberPin: boolean, oldValue: boolean) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const zone = system.zones.find((z) => z.queue_no === zoneNumber);
    if (zone === undefined) {
      return;
    }
    zone.bypassed = oldValue;
    const area = system.areas.find((a) => a.id === areaId);
    if (area === undefined) {
      return;
    }
    area.showKeypad = !rememberPin;
  }

  public performMultiBypass(systemId: number, areaId: number, zones: any, pin: string, rememberPin: boolean, onSuccess: any, onFailure: any) {
    const that = this;
    this.rs
      .post(
        '/bypass-multi-zones',
        {
          system_id: systemId,
          area_id: areaId,
          zones,
          pin,
          remember_pin: rememberPin,
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            if (onSuccess !== null) {
              onSuccess();
            }
          } else {
            that.toaster.postError(result.error);
            if (onFailure !== null) {
              onFailure();
            }
          }
        },
        (error) => {
          that.toaster.postError(that.ls.get('auth.errors.serverSideError'));
          if (onFailure !== null) {
            onFailure();
          }
        }
      );
  }
}
