<app-company-page-layout>
  <div class="header">
    <h4>Users</h4>
    <h5>TODO: Search here</h5>
    <app-icon-button label="User roles" icon="assets/images/company/userRoles.svg"></app-icon-button>
    <a routerLink="new"><app-icon-button label="Add new user" icon="assets/images/company/plus.svg"></app-icon-button></a>
    <app-company-columns [items]="columnConfig" [(value)]="columns" #cols></app-company-columns>
  </div>
  <app-users-data-table [columns]="columns" (modifyColumns)="cols.show()"></app-users-data-table>
</app-company-page-layout>
