<app-login-layout>
  <div class="mainContent">
    <div>
      <h2>{{ trans('auth.labels.registration') }}</h2>
      <p>{{ trans('auth.labels.registrationPrompt') }}</p>
    </div>
    <form (submit)="$event.preventDefault(); next()">
      <app-string-input [label]="trans('auth.fields.email')" type="email" name="email" autocomplete="email" [(value)]="login.email" [hasError]="val.hasError('email')">
        <app-form-error *ngIf="val.hasError('email')"
          ><app-tmapper
            [key]="val.getError('email')"
            [default]="defaultEmailError"
            [context]="{}"
            [map]="{ NO_PASSWORD: noPassword, LOCAL_USER: localUser, DISABLED_USER: disabledUser }"
          ></app-tmapper
        ></app-form-error>
      </app-string-input>
      <div class="social-login">
        <app-google-login></app-google-login>
      </div>
      <app-button class="mainButton">{{ trans('auth.loginNext') }}</app-button>
    </form>
  </div>
</app-login-layout>
<ng-template #defaultEmailError>
  <span>{{ val.getError('email') }}</span>
</ng-template>
<ng-template #noPassword>
  <span
    >{{ trans('auth.error.noPasswordSet') }} <a routerLink="/login/password-reset/email">{{ trans('auth.error.noPasswordSetLink') }}</a></span
  >
</ng-template>
<ng-template #localUser>
  <span
    >{{ trans('auth.error.userExists') }} <a routerLink="/login/password">{{ trans('auth.error.userExistsLink') }}</a></span
  >
</ng-template>
<ng-template #disabledUser>
  <span
    >{{ trans('auth.error.notActivated') }} <a routerLink="/login/register/activate/resend">{{ trans('auth.error.notActivatedLink') }}</a></span
  >
</ng-template>
<ng-template #socialGoogle>
  <span
    >{{ trans('auth.error.useGoogleLogin') }} <a href="javascript:void(0)" (click)="googleAuth.signIn()">{{ trans('auth.error.useGoogleLoginLink') }}</a></span
  >
</ng-template>
<ng-template #social>
  <span>{{ trans('auth.error.useSocialLogin') }}</span>
</ng-template>
