import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  private show = false;
  private button1text = '';
  private button2text = '';
  private sticky = false;
  private opaque = false;
  private confirmation = false;
  public onButton1Click = null;
  public onButton2Click = null;
  public onSumCorrect = null;
  public isWhite = false;

  constructor() {}

  public showFooter(button1text: string, button2text: string, sticky: boolean, opaque: boolean) {
    this.show = true;
    this.button1text = button1text;
    this.button2text = button2text;
    this.sticky = sticky;
    this.opaque = opaque;
    this.onButton1Click = null;
    this.onButton2Click = null;
    this.onSumCorrect = null;
    this.hideConfirmation();
    this.isWhite = false;
  }

  public hideFooter() {
    this.show = false;
    this.onButton1Click = null;
    this.onButton2Click = null;
    this.onSumCorrect = null;
  }

  public isSticky(): boolean {
    return this.sticky;
  }

  public getButton1Text(): string {
    return this.button1text;
  }

  public getButton2Text(): string {
    return this.button2text;
  }

  public isShowing(): boolean {
    return this.show;
  }

  public isOpaque(): boolean {
    return this.opaque;
  }

  public isConfirmationVisible(): boolean {
    return this.confirmation;
  }

  public showConfirmation() {
    this.confirmation = true;
  }

  public hideConfirmation() {
    this.confirmation = false;
  }

  public setWhite() {
    this.isWhite = true;
  }
}
