import { Observable } from 'rxjs';

export type RequestMethod = 'get' | 'post' | 'put' | 'patch' | 'delete';

export type ReturnTypeOptionValue = 'json' | 'blob' | 'text' | 'number' | 'void' | undefined;

export interface RequestOptionsInternal<TReturn extends ReturnTypeOptionValue | unknown> {
  auth: boolean | 'ifHas';
  method: RequestMethod;
  returntype: TReturn;
}

export type RequestOptions<TReturn extends ReturnTypeOptionValue> = Partial<RequestOptionsInternal<TReturn>>;

export const defaultOptions: RequestOptionsInternal<'json'> = {
  auth: true,
  method: 'get',
  returntype: 'json',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isBlob = (value: any): value is Blob => value instanceof Blob;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isFormData = (value: any): value is FormData => value instanceof FormData;

export type BodyType = string | number | FormData | URLSearchParams | Blob | undefined | Record<string, unknown> | any[];

export type ResponseGeneric<TOptions extends RequestOptions<TReturnTypeValue>, TReturnTypeValue extends ReturnTypeOptionValue> = TOptions extends {
  returntype: 'blob';
}
  ? Blob
  : TReturnTypeValue extends 'text'
  ? string
  : TReturnTypeValue extends 'number'
  ? number
  : TReturnTypeValue extends 'void'
  ? void
  : TReturnTypeValue extends 'json' | undefined
  ? unknown
  : TReturnTypeValue extends 'blob'
  ? Blob
  : never;

const doRequest = <
  TResponse extends ResponseGeneric<TOptions, TReturnTypeValue>,
  TBody extends BodyType,
  TOptions extends RequestOptions<TReturnTypeValue>,
  TReturnTypeValue extends ReturnTypeOptionValue = 'json'
>(
  input: RequestInfo | URL,
  body: TBody,
  options: TOptions,
  method: RequestMethod
): Observable<TResponse> => {
  throw new Error('Method not implemented.');
};

export const internals = {
  doRequest,
};

export const httpGet = <
  TResponse extends ResponseGeneric<TOptions, TReturnTypeValue>,
  TBody extends URLSearchParams = undefined,
  TReturnTypeValue extends ReturnTypeOptionValue = 'json' | undefined,
  TOptions extends RequestOptions<TReturnTypeValue> = RequestOptions<TReturnTypeValue> & {
    returntype?: TReturnTypeValue;
  }
>(
  url: string,
  body: TBody,
  options?: TOptions
): Observable<TResponse> => {
  return internals.doRequest(url, body, options ?? {}, 'get');
};

export const httpPost = <
  TResponse extends ResponseGeneric<TOptions, TReturnTypeValue>,
  TBody extends BodyType = undefined,
  TReturnTypeValue extends ReturnTypeOptionValue = 'json' | undefined,
  TOptions extends RequestOptions<TReturnTypeValue> = RequestOptions<TReturnTypeValue> & {
    returntype?: TReturnTypeValue;
  }
>(
  url: string,
  body: TBody,
  options?: TOptions
): Observable<TResponse> => {
  return internals.doRequest(url, body, options ?? {}, 'post');
};

export const httpPatch = <
  TResponse extends ResponseGeneric<TOptions, TReturnTypeValue>,
  TBody extends BodyType = undefined,
  TReturnTypeValue extends ReturnTypeOptionValue = 'json' | undefined,
  TOptions extends RequestOptions<TReturnTypeValue> = RequestOptions<TReturnTypeValue> & {
    returntype?: TReturnTypeValue;
  }
>(
  url: string,
  body: TBody,
  options?: TOptions
): Observable<TResponse> => {
  return internals.doRequest(url, body, options ?? {}, 'patch');
};

export const httpDelete = <
  TResponse extends ResponseGeneric<TOptions, TReturnTypeValue>,
  TBody extends BodyType = undefined,
  TReturnTypeValue extends ReturnTypeOptionValue = 'json' | undefined,
  TOptions extends RequestOptions<TReturnTypeValue> = RequestOptions<TReturnTypeValue> & {
    returntype?: TReturnTypeValue;
  }
>(
  url: string,
  body: TBody,
  options?: TOptions
): Observable<TResponse> => {
  return internals.doRequest(url, body, options ?? {}, 'delete');
};

export default {
  get: httpGet,
  post: httpPost,
  patch: httpPatch,
  delete: httpDelete,
};
