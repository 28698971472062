<div class="page">
  <app-language-switcher class="ls"></app-language-switcher>
  <h1>{{ trans('general.welcomeText') }} {{ title }}</h1>
  <ng-content></ng-content>
  <div class="social-login">
    <app-google-login></app-google-login>
    <app-portal [name]="ForgotPasswordTarget"></app-portal>
  </div>
  <div class="register">
    <a routerLink="/login/register">{{ trans('auth.labels.register') }}</a>
    <a routerLink="/login/register/company">{{ trans('auth.labels.registerCompany') }}</a>
  </div>
</div>
