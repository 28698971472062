import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from '../../language-aware';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.scss'],
})
export class TicketComponent extends LanguageAware implements OnInit {
  public formContent = '';
  public email = '';
  private TIME_BETWEEN_CLICKS = 5; // s
  private lastClickTime = null;

  constructor(cdRef: ChangeDetectorRef, router: Router) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('help.supportTicket'),
      backUrl: '/help',
    });
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('help.submit'), true, false);
    this.background.setGray();
    const that = this;
    this.footerBar.onButton1Click = () => {
      router.navigate(['/help']);
    };
    this.footerBar.onButton2Click = () => {
      that.sendReport();
    };
  }

  ngOnInit(): void {}

  private sendReport() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    if (this.formContent.trim() === '') {
      return;
    }
    if (this.lastClickTime !== null && new Date().getTime() - this.lastClickTime.getTime() <= this.TIME_BETWEEN_CLICKS * 1000) {
      return;
    }
    this.lastClickTime = new Date();
    if (this.us.currentUser.id !== 0) {
      this.email = this.us.currentUser.email;
    } else if ( this.email.trim() === '' ) {
      this.toaster.postError(this.trans('help.errors.emptyEmail'));
      this.lastClickTime = null;
      return;
    }
    if ( !/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.email) ) {
      this.toaster.postError(this.trans('validation.emailInvalid'));
      this.lastClickTime = null;
      return;
    }

    const that = this;
    this.miniStatus.show(this.trans('help.sending'));
    this.api
      .post(
        '/help',
        {
          content: this.formContent,
          email: this.email,
          user: this.us.currentUser.id,
        },
        true
      )
      .subscribe(
        (result) => {
          that.miniStatus.hide();
          if (result.success) {
            that.toaster.postSuccess(result.message);
            that.formContent = '';
          } else {
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.miniStatus.hide();
          that.toaster.postError(that.trans('help.errors.failedToSend'));
        }
      );
  }

  public emailSet(value: string) {
    this.email = value;
  }
}
