import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { TNotificationSetting } from 'src/app/models/notification-setting';

@Component({
  selector: 'app-system-notifications',
  templateUrl: './system-notifications.component.html',
  styleUrls: ['./system-notifications.component.scss'],
})
export class SystemNotificationsComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
    this.tag = 'Notifs';
    this.background.setGray();
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.notifications'),
      backUrl: '/system-configuration',
    });
  }

  ngOnInit(): void {}

  public toggleGlobal(value: boolean) {
    this.log('global notifications toggles:', value);
    this.systems.activeSystem.notificationsEnabled = value;
    const that = this;
    this.api.post('/system/notification/toggle/global-state', { systemId: this.systems.activeSystem.id, value }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.toaster.postError(result.error);
          that.systems.activeSystem.notificationsEnabled = !value;
        } else {
          that.systems.setCurrentSystem(that.systems.activeSystem);
        }
      },
      (error) => {
        that.toaster.postError(this.trans('auth.errors.serverSideError'));
        that.systems.activeSystem.notificationsEnabled = !value;
      }
    );
  }

  public toggleGlobalSound(value: boolean) {
    this.log('global sound toggles:', value);
    this.systems.activeSystem.notificationSoundsEnabled = value;
    const that = this;
    this.api.post('/system/notification/toggle/global-sound', { systemId: this.systems.activeSystem.id }, true).subscribe(
      (result) => {
        that.log('', result);
        if (!result.success) {
          that.toaster.postError(result.error);
          that.systems.activeSystem.notificationSoundsEnabled = !value;
        } else {
          that.systems.activeSystem.notificationSoundsEnabled = result.state;
          that.systems.setCurrentSystem(that.systems.activeSystem);
        }
      },
      (error) => {
        that.toaster.postError(this.trans('auth.errors.serverSideError'));
        that.systems.activeSystem.notificationSoundsEnabled = !value;
      }
    );
  }

  public toggleNotification(which: number, value: boolean) {
    this.log('toggle:', { which, value });
    const that = this;
    let currentNotification: TNotificationSetting = null;
    for (const iNotif of this.systems.activeSystem.notifications) {
      if (iNotif.id === which) {
        iNotif.enabled = value;
        currentNotification = iNotif;
      }
    }
    this.api.post('/system/notification/toggle/state', { systemId: this.systems.activeSystem.id, id: which }, true).subscribe(
      (result) => {
        that.log('', result);
        if (!result.success) {
          that.toaster.postError(result.error);
          currentNotification.enabled = !value;
        } else {
          currentNotification.enabled = result.state;
          that.systems.setCurrentSystem(that.systems.activeSystem);
        }
      },
      (error) => {
        that.toaster.postError(this.trans('auth.errors.serverSideError'));
        currentNotification.enabled = !value;
      }
    );
  }

  public toggleNotificationSound(which: number, value: boolean) {
    this.log('toggle sound:', { which, value });
    const that = this;
    let currentNotification: TNotificationSetting = null;
    for (const iNotif of this.systems.activeSystem.notifications) {
      if (iNotif.id === which) {
        iNotif.useAlertSound = value;
        currentNotification = iNotif;
      }
    }
    this.api.post('/system/notification/toggle/sound', { systemId: this.systems.activeSystem.id, id: which }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.toaster.postError(result.error);
          currentNotification.useAlertSound = !value;
        } else {
          currentNotification.useAlertSound = result.state;
          that.systems.setCurrentSystem(that.systems.activeSystem);
        }
      },
      (error) => {
        that.toaster.postError(this.trans('auth.errors.serverSideError'));
        currentNotification.useAlertSound = !value;
      }
    );
  }
}
