import { assign, createMachine } from 'xstate';

type LoginContext = {
  message: string;
  tryingToLogin: boolean;
};
type LoginEvent =
  | { type: 'EMAIL_CHANGED' }
  | { type: 'PASSWORD_CHANGED' }
  | { type: 'LOGIN' }
  | { type: 'HAS_USER' }
  | { type: 'NO_USER' }
  | { type: 'SOCIAL_USER' }
  | { type: 'ERROR'; message: string }
  | { type: 'SUCCESS' }
  | { type: 'FOCUS' };
type LoginState = {
  tags: ('loading' | 'emailLoading' | 'disablePassword' | 'disableEmail' | 'hasError')[];
  value:
    | 'clean'
    | 'email'
    | { email: 'pending' | 'wait' | 'valid' | 'failed' | { failed: 'error' | 'socialUser' | 'invalidEmail' } }
    | 'login'
    | { login: 'pending' | 'failed' | 'loggledIn' | { failed: 'error' } };
  context: LoginContext;
};

const isTryingToLogin = (context: LoginContext, event: LoginEvent) => context.tryingToLogin;

export const loginMachine =
  // eslint-disable-next-line max-len
  /** @xstate-layout N4IgpgJg5mDOIC5QBsD2UCWA7AdAY2TAEMsBiAUQFkBBASQBkB9AYQAlqA5AcXIBFFQAB1SwMAFwyosAkAA9EARgDsAThwAGTQoUBWABwAWdToMA2AEwKANCACeig3tM4Vr0yr3rXKi6YC+fjZomLhgALZEGMg4gmBYENhQpOwAyowAqinkAEoywqISUjLyCHoAzAYuhuoKhhZ6OmXWdg56Shqa6kpl5uWm6v6BIMHYOOGR0bHxiaQcAPIZWblIIPniktIrJXrmlR5GtWa9jc32CAplKgod6g1KTjqmtSoBQeij41ExcQlYSSlzZi0ahMTI5PIidZFLaIcp7aqHeonGxnBQGS43Mw6HRKMwKcw6V7Dd6hCJfKa-JI5bJzZZCSGFTagbZoqoHOrHJooxQVModfE6GqPCw6cxEkakiY4ABmE0gFBoDBY7G4fAhBQ2xUQBL0ODKAwMoraXicpm55y6Oj16gM5lMZWFBltYqGErGZOiADciMgMBBSPQ5lxaBx1VCmXJEKLzTtzDhBQNsb1tNoXW8Qu6pQB3SJiUgAMUBmTDjK1CEeVpUAwqOIUKgMuL05rRSjjOjc7cF9ZMZXFJMzXxz4lIslgYiIYjAOCI0snACcABT6zQASlIbs+0SHYhLmph5dMlerhqUdYbjmbtsq7Z8SlMT1MemUvdd-c3OG3CroTDYnB4-BWNZS33Aw6xcRpzH1e4tGMS8Bg0NwyjKU9aiaF901GN0KRmalaV3aFmUUBoXB6SDTFPHwCTNFoEAqNR62MQ9bUPO17T7DMsJ+GYUnSZhmHIFIUnwiMSnMG0cHRJQbScFQkJUHRm0ffkynvXo4UcQlXw4-tZSieUqG-ZU-zVQCGT3QjaPcHAH1uMwq1xMwynNQVnBg1tZN0JRVAMdjMJ0uV-QABWoQSAHVaV4IzVQA+kNQIyMEAsRT4IMVw2lPMon3UF8hiwVAIDgGQ3QIYgIyA8yEq8y9TxwcpkO6UxUpqUDfMlckuL+YSyykts7PcO9ux2S9yIQ2T9Qscw7x0BRWoHaJdMICAxjnOdUDnLr9wUdRIJcQ1kIue1XC6ZsmnaBMTAUFjXHQ4kM3fBbIBwWBUDwDAfXSWAwHW0y4pExRtr5bt9pUy57MvRp406DxGvGixZvugKcGwb1fQgcgPQ2iytp2oGMsOsGaIuYjBXxXZVFPJj4Y9GUAsxhLvF2xo8dB47Cd2K1zsmh8VG6KmpRRv06ZKHxKguO0TCccmVHMZt2zjS79Gl8xzA8Wo01uj5qe3IXEFtNQ9a8swnFS+TwbULwfHbW47QJPmoh1hBIMqcbDV6KToaSwnND5fVmPxTQGKUWbOOmTqfvDMtpebKTIa8K9k2UBtg-8vSlq+1bvtiiP9xU5wtoo-RDwppsaIdXUujEw1+mUbQbrdN0HogB2Knlro60L2tH3NXpXLx9mlEaPQXi0vztKgKBFtoMqzPikoLpwC4TGamTMpL1F7QkvQnydJ1pa6QYMNwCUHa7mirk37exPKKSniDkfp9+stembfWt8u+8vG6AZygCAIgA */
  createMachine<LoginContext, LoginEvent, LoginState>(
    {
      predictableActionArguments: true,
      id: 'login',
      context: {
        message: '',
        tryingToLogin: false,
      },
      initial: 'clean',
      states: {
        clean: {
          on: {
            EMAIL_CHANGED: {
              target: '#login.email.wait',
            },
            LOGIN: [
              {
                actions: 'enableTryingToLogin',
                target: '#login.email.pending',
              },
            ],
          },
        },
        email: {
          initial: 'pending',
          states: {
            pending: {
              tags: ['emailLoading'],
              entry: 'validateEmail',
              on: {
                HAS_USER: {
                  target: 'valid',
                },
                NO_USER: {
                  target: '#login.email.failed.invalidEmail',
                },
                SOCIAL_USER: {
                  target: '#login.email.failed.socialUser',
                },
                ERROR: {
                  target: '#login.email.failed.error',
                },
                LOGIN: {
                  actions: 'enableTryingToLogin',
                },
              },
            },
            failed: {
              tags: ['hasError'],
              initial: 'error',
              entry: ['getTranslation', 'disableTryingToLogin'],
              states: {
                error: {},
                socialUser: {},
                invalidEmail: {},
              },
              on: {
                EMAIL_CHANGED: {
                  target: 'wait',
                },
              },
            },
            valid: {
              always: {
                target: '#login.login.pending',
                cond: 'isTryingToLogin',
              },
              on: {
                LOGIN: {
                  target: '#login.login.pending',
                },
                EMAIL_CHANGED: { target: 'wait' },
              },
            },
            wait: {
              after: {
                '3000': {
                  target: 'pending',
                },
              },
              on: {
                FOCUS: {
                  target: 'pending',
                },
                EMAIL_CHANGED: {
                  target: 'wait',
                  internal: false,
                },
                LOGIN: {
                  target: 'pending',
                  actions: 'enableTryingToLogin',
                },
              },
            },
          },
        },
        login: {
          initial: 'pending',
          states: {
            pending: {
              tags: ['loading', 'disableEmail', 'disablePassword'],
              entry: 'login',
              on: {
                ERROR: {
                  target: '#login.login.failed.error',
                },
                SUCCESS: {
                  target: 'loggledIn',
                },
              },
            },
            failed: {
              tags: ['hasError'],
              entry: ['getTranslation', 'disableTryingToLogin'],
              states: {
                error: {},
              },
              on: {
                EMAIL_CHANGED: {
                  target: '#login.email.wait',
                },
                PASSWORD_CHANGED: {
                  target: '#login.email.valid',
                },
              },
            },
            loggledIn: {
              tags: ['disableEmail', 'disablePassword'],
              entry: 'loggedIn',
              type: 'final',
            },
          },
        },
      },
    },
    {
      actions: {
        enableTryingToLogin: () => assign({ tryingToLogin: true }),
        disableTryingToLogin: () => assign({ tryingToLogin: false }),
      },
      guards: {
        isTryingToLogin,
      },
    }
  );
