import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-language-list',
  templateUrl: './language-list.component.html',
  styleUrls: ['./language-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LanguageListComponent extends LanguageAware implements OnInit {
  public currentLanguage = 'en';
  public availableLanguages = [];
  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    this.tag = 'LanguageSelection';
    this.background.setGray();
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.doCancel();
    };
    this.footerBar.onButton2Click = () => {
      that.doSave();
    };
    this.headerBar.showHeader({
      backUrl: '/profile',
      headerText: this.trans('systems.titles.language'),
    });
    const savedLanguages = localStorage.getItem('language-list');
    if (savedLanguages !== null) {
      this.availableLanguages = JSON.parse(savedLanguages);
    }
    this.currentLanguage = this.ls.selectedLanguage.value;
    this.checkVersion();
  }

  ngOnInit(): void {}

  private doCancel() {
    this.router.navigate(['/profile']);
  }

  private doSave() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const found = this.availableLanguages.find((l) => l.code === this.currentLanguage);
    const that = this;
    this.miniStatus.show(this.trans('language.downloading'));
    this.api
      .post(
        '/translations',
        {
          language: found.code,
          version: '-1',
        },
        false
      )
      .subscribe(
        (data) => {
          if (data.success) {
            that.ls.selectedLanguage.value = data.code;
            that.ls.applyTranslations(data.translations, data.version, data.code);
            that.api.post('/language/set', { language: that.ls.selectedLanguage.value }, true).subscribe(
              (saveRes) => {
                that.miniStatus.hide();
                if (saveRes.success) {
                  if (that.platform.isAndroid()) {
                    that.platform.androidHandler().languageUpdated();
                  } else if (that.platform.isApple()) {
                    that.platform.appleHandler().languageUpdated.postMessage('');
                  }
                  that.router.navigate(['/profile']);
                } else {
                  that.toaster.post(data.error);
                }
              },
              (saveErr) => {
                that.miniStatus.hide();
                that.toaster.postError(that.trans('auth.errors.serverSideError'));
              }
            );
          } else {
            that.miniStatus.hide();
            that.l.log('Kazkodel nepavyko gauti vertimu', that.tag);
            that.toaster.post(data.error);
          }
        },
        (error) => {
          that.l.log('Kazkodel nepavyko gauti vertimu', that.tag, error);
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
          that.miniStatus.hide();
        }
      );
  }

  private checkVersion() {
    const currentVersion = localStorage.getItem('language-list-version');
    if (this.availableLanguages.length === 0) {
      this.miniStatus.show(this.trans('general.loading'));
    }
    this.performVersionCheck(currentVersion === null ? '' : currentVersion);
  }

  private performVersionCheck(currentVersion: string) {
    const that = this;
    this.api.get('/version', false, { keys: ['language-list'] }).subscribe(
      (result) => {
        if (result.success) {
          const values = result.result;
          if (values.length === 0) {
            that.loadLanguageList('');
          } else {
            if (values[0].version !== currentVersion) {
              that.loadLanguageList(values[0].version);
            } else {
              that.miniStatus.hide();
            }
          }
        } else {
          that.miniStatus.hide();
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
        that.toaster.postError(that.trans('auth.error.serverSideError'));
      }
    );
  }

  private loadLanguageList(newVersion: string) {
    this.log('load language list', newVersion);
    const that = this;
    this.api.get('/languages', false).subscribe(
      (result) => {
        const codes = Object.keys(result.list);
        that.availableLanguages = [];
        for (const iCode of codes) {
          const minusPos = iCode.lastIndexOf('-');
          const dashPos = iCode.lastIndexOf('_');
          const lastPos = Math.max(minusPos, dashPos);
          const subCodePart = lastPos === -1 ? iCode : iCode.substr(lastPos + 1);
          that.availableLanguages.push({
            code: iCode,
            name: result.list[iCode],
            version: result.versions[iCode],
            subCode: subCodePart.toUpperCase(),
          });
          localStorage.setItem('language-list-version', newVersion);
          localStorage.setItem('language-list-time', new Date().getTime().toString());
          localStorage.setItem('language-list', JSON.stringify(that.availableLanguages));
        }
        that.miniStatus.hide();
      },
      (error) => {
        that.miniStatus.hide();
        that.toaster.postError(that.trans('auth.error.serverSideError'));
      }
    );
  }

  public selected(code: string) {
    this.currentLanguage = code;
  }
}
