<div class="padding_sides16">
    <div class="padding_sides16">
        <div id='email-activate-header'>{{trans('auth.activation.confirmEmail')}}</div>
        <div id='email-activate-description' *ngIf="us.currentUser.ActivationToken !== null && us.currentUser.ActivationToken !== ''">{{trans('auth.activation.enterDigitsText')}}</div>
        <div id='email-activate-code'>
            <ng-container *ngIf="us.currentUser.ActivationToken !== null && us.currentUser.ActivationToken !== ''">
                <input type="text" class="digit-input" [(ngModel)]="digitOne" readonly/>
                <input type="text" class="digit-input" [(ngModel)]="digitTwo" readonly/>
                <input type="text" class="digit-input" [(ngModel)]="digitThree" readonly/>
            </ng-container>
        </div>
        <div id='email-activate-subinfo'>{{trans('auth.activation.tipText')}} <span (click)='resendClick()'>{{trans('auth.activation.resendEmail')}}</span></div>
    </div>
    <div class="numpad" *ngIf="us.currentUser.ActivationToken !== null && us.currentUser.ActivationToken !== ''">
        <div class="nums">
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 1 }" (touchstart)="onNumberDown(1)" (touchend)="onNumberUp()" (click)="onNumberClick('1')">1</div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 2 }" (touchstart)="onNumberDown(2)" (touchend)="onNumberUp()" (click)="onNumberClick('2')">2</div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 3 }" (touchstart)="onNumberDown(3)" (touchend)="onNumberUp()" (click)="onNumberClick('3')">3</div>
        </div>
        <div class="nums">
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 4 }" (touchstart)="onNumberDown(4)" (touchend)="onNumberUp()" (click)="onNumberClick('4')">4</div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 5 }" (touchstart)="onNumberDown(5)" (touchend)="onNumberUp()" (click)="onNumberClick('5')">5</div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 6 }" (touchstart)="onNumberDown(6)" (touchend)="onNumberUp()" (click)="onNumberClick('6')">6</div>
        </div>
        <div class="nums">
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 7 }" (touchstart)="onNumberDown(7)" (touchend)="onNumberUp()" (click)="onNumberClick('7')">7</div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 8 }" (touchstart)="onNumberDown(8)" (touchend)="onNumberUp()" (click)="onNumberClick('8')">8</div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 9 }" (touchstart)="onNumberDown(9)" (touchend)="onNumberUp()" (click)="onNumberClick('9')">9</div>
        </div>
        <div class="nums">
            <div class="numButton numButton-fake"></div>
            <div [ngClass]="{ 'numButton' : true, 'numButton-pressed' : pressedButton === 0 }" (touchstart)="onNumberDown(0)" (touchend)="onNumberUp()" (click)="onNumberClick('0')">0</div>
            <div [ngClass]="{ 'numButton' : true, 'backspaceIcon' : true, 'numButton-pressed' : pressedButton === 10 }" (touchstart)="onNumberDown(10)" (touchend)="onNumberUp()" (click)="onNumberClick('10')" [inlineSVG]="'assets/images/icon-backspace.svg'"></div>
        </div>
    </div>
    <div id='email-activate-gologin' class="padding_sides16">{{trans('auth.alreadyRegistered')}} <span (click)='loginClick()'>{{trans('auth.buttons.logIn')}}</span></div>
</div>