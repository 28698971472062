import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { UserRole } from 'src/app/models/user-role';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-cp-edit-groups',
  templateUrl: './cp-edit-groups.component.html',
  styleUrls: ['./cp-edit-groups.component.scss'],
})
export class CpEditGroupsComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private es: EditSystemService, private router: Router) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.reactionGroups'),
      showPlus: true,
      backUrl: this.g.getHomeUrl(),
    });
    const that = this;
    this.headerBar.onActionButtonClicked = () => {
      that.es.beginComponentEdit(TEditableComponent.ReactionGroup, 0, {
        id: 0,
        name: '',
        icon: '',
        default_name: this.us.currentUser.role === UserRole.Company ? false : true,
        company_id: this.us.currentUser.role === UserRole.Company ? this.us.currentUser.id : 0,
      });
      that.router.navigate([this.g.getHomeUrl() + '/edit-group']);
    };
    this.background.setGray();
  }

  ngOnInit(): void {}

  public editGroup(group: any) {
    this.es.beginComponentEdit(TEditableComponent.ReactionGroup, group.id, group);
    this.router.navigate([this.g.getHomeUrl() + '/edit-group']);
  }
}
