import { Component, OnInit } from '@angular/core';
import { ItemConfiguration } from '../../components/company-columns/company-columns.component';

@Component({
  templateUrl: './company-systems.component.html',
  styleUrls: ['./company-systems.component.scss'],
})
export class CompanySystemsComponent implements OnInit {
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: 'Icon', value: 'fake:icon' },
    { name: 'System name', value: 'name' },
    { name: 'Device', value: 'hwType' },
    { name: 'IMEI', value: 'imei' },
    { name: 'Installer', value: 'installer_id' },
    { name: 'Status', value: 'online' },
    { name: 'Delete', value: 'fake:delete' },
  ];
  public columns = ['fake:icon', 'name', 'hwType', 'imei', 'installer_id', 'online', 'fake:delete'];
  constructor() {}

  ngOnInit(): void {}
}
