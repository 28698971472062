import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageService } from 'src/app/services/language.service';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { SystemsService } from 'src/app/services/systems.service';
import { NewSystemService } from 'src/app/services/new-system.service';
import { LoggerService } from 'src/app/api/logger.service';
import { PlatformService } from 'src/app/api/platform.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { HeaderService } from 'src/app/services/header.service';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { AreaService } from 'src/app/services/area.service';
import { TSystemData } from 'src/app/models/system-data';
import { AppSettings } from 'src/app-settings';
import { UserService } from 'src/app/services/user.service';
import { UserRole } from 'src/app/models/user-role';
import { GlobalService } from 'src/app/services/global.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { IconsService } from 'src/app/services/icons.service';
import { PopupService } from 'src/app/services/popup.service';
import { PopupType } from 'src/app/models/popup-type';
import { RegionService } from 'src/app/api/region.service';
import { AppRegion } from 'src/environments/environment.types';
import { GoogleAuthService } from 'src/app/api/google-auth.service';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [trigger('spin', [state('1', style({ transform: 'rotate(0deg)' })), state('2', style({ transform: 'rotate(360deg)' })), transition('1 => 2', animate('0.2s'))])],
})
export class SidemenuComponent implements OnInit, OnDestroy {
  private translationSubscription = null;
  public systemListShowing = false;
  public controlsShowingForSystem = 0;
  public regionsEnabled = AppSettings.regionsEnabled;
  public currentRegion = this.region.ActiveRegion.backEndHost;
  public newVersionAvailable = false;
  public hasAccounts = this.rs.hasAccounts;
  isInProfile = false;
  @Input() menuPosition = -40;
  @Input() isDragging = false;

  constructor(
    public headerService: HeaderService,
    private cdRef: ChangeDetectorRef,
    public ls: LanguageService,
    private router: Router,
    private rs: ApiRequestService,
    public systems: SystemsService,
    private ns: NewSystemService,
    private l: LoggerService,
    public pl: PlatformService,
    private toaster: ToasterService,
    public areaService: AreaService,
    public us: UserService,
    public g: GlobalService,
    private ws: WebSocketService,
    private ic: IconsService,
    private pp: PopupService,
    private google: GoogleAuthService,
    private region: RegionService,
    private login: LoginService
  ) {
    const that = this;
    this.translationSubscription = this.ls.onTranslationsChange.subscribe(() => {
      that.translationChanged();
    });
    this.l.log('Turimas regionas: ' + this.currentRegion, 'SM');
    const savedState = localStorage.getItem('side_list_open');
    this.systemListShowing = !savedState || savedState === '1';
  }

  ngOnInit() {}

  public show() {
    this.l.log('Rodom', 'SideMenu');
    this.headerService.sideMenuOpen = true;
    const that = this;
    setTimeout(() => {
      that.menuPosition = 0;
      that.newVersionAvailable = that.g.isNewVersionAvailable();
    }, 50);
  }

  public hide() {
    this.l.log('Paslepiam', 'SideMenu');
    this.menuPosition = -50;
    const that = this;
    setTimeout(() => {
      that.headerService.sideMenuOpen = false;
      that.isInProfile = false;
    }, 300);
  }

  public toggleProfilePart() {
    this.isInProfile = !this.isInProfile;
  }

  ngOnDestroy() {
    this.translationSubscription.unsubscribe();
  }

  private translationChanged() {
    this.cdRef.markForCheck();
  }

  public trans(keyword) {
    return this.ls.get(keyword);
  }

  public loginClick() {
    this.hide();
    this.router.navigate(['/email']);
  }

  public isLoggedIn(): boolean {
    return this.rs.hasToken();
  }

  public helpClick() {
    this.hide();
    this.router.navigate(['/help']);
  }

  public profileClick() {
    this.hide();
    this.router.navigate(['/profile']);
  }

  public settingsClick() {
    if (this.pl.isAndroid()) {
      this.pl.androidHandler().openSettings();
    } else if (this.pl.isApple()) {
      this.pl.appleHandler().openSettings.postMessage('');
    }
  }

  public toggleSystemListView() {
    this.systemListShowing = !this.systemListShowing;
    localStorage.setItem('side_list_open', this.systemListShowing ? '1' : '0');
  }

  public systemClicked(systemId: number) {
    if (this.systems.activeSystem.id === systemId) {
      return;
    }
    this.systems.setCurrentSystem(this.systems.getSystem(systemId));
    this.hide();
    this.router.navigate(['/home']);
  }

  public systemSettingsClick() {
    this.hide();
    this.router.navigate(['/system-configuration']);
  }

  public eventsClick() {
    this.hide();
    this.router.navigate(['/events']);
  }

  public addSystemClick() {
    this.hide();
    this.ns.setDefaults();
    this.router.navigate(['/add-system']);
  }

  public usersClick() {
    this.hide();
    this.router.navigate(['/users']);
  }

  public sensorsClick() {
    this.hide();
    this.router.navigate(['/system-sensor-list']);
  }

  public controlClick() {
    this.hide();
    this.router.navigate(['/quick-view-outputs']);
  }

  public getStatusIconForSystem(system: TSystemData) {
    if (system.areas.length !== 1) {
      return '';
    }

    return this.areaService.getIconByAreaStatusEx(system.areas[0]);
  }

  public systemCamerasClick() {
    this.hide();
    this.router.navigate(['/quick-view-cameras']);
  }

  public isSystemDisarmed(system: TSystemData): boolean {
    if (system.areas.length !== 1) {
      return false;
    }

    return system.areas[0].status === 1;
  }

  public isSystemAlarmed(system: TSystemData): boolean {
    if (system.areas.length !== 1) {
      return false;
    }

    return system.areas[0].alarmed;
  }

  public showHelp(): boolean {
    return AppSettings.showHelp;
  }

  public showVersion(): boolean {
    return AppSettings.showAppVersion;
  }

  public goBackToCompany() {
    if (this.us.currentUser.role === UserRole.SimpleUser) {
      return;
    }
    this.hide();
    this.systems.setCurrentSystem(null);
    this.router.navigate([this.g.getHomeUrl()]);
  }

  public allEventsClicked() {
    if (this.us.currentUser.role !== UserRole.Company && this.us.currentUser.role !== UserRole.SuperAdmin) {
      return;
    }
    this.hide();
    this.router.navigate([this.g.getHomeUrl() + '/all-events']);
  }

  public allUsersClicked() {
    if (this.us.currentUser.role !== UserRole.Company && this.us.currentUser.role !== UserRole.SuperAdmin) {
      return;
    }
    this.hide();
    this.router.navigate([this.g.getHomeUrl() + '/all-users']);
  }

  public gotoGlobalSettings() {
    this.hide();
    this.router.navigate([this.g.getHomeUrl() + '/global-settings']);
  }

  public changeRegion(region: any) {
    if (region.region_version < 2) {
      this.toaster.postError('Sena versija');
      return;
    }
    const beforeRegion = this.region.ActiveRegion;

    this.currentRegion = region.api_host_to_use;
    this.l.log('Current region ' + this.currentRegion);

    const bestRegion = AppSettings.regions.find((r) => r.backEndHost === this.currentRegion);

    if (bestRegion) {
      this.region.useRegion(bestRegion);
    } else {
      this.region.useRegion({
        ...beforeRegion,
        id: region.api_host_to_use.substring(0, 5),
        backEndHost: this.currentRegion,
      });
    }
    if (this.us.userPassword === '') {
      const that = this;
      this.pp.showPopup(
        {
          headerText: this.trans('auth.form.password'),
          field1: {
            oldValue: '',
          },
          onSubmit: (res) => {
            that.us.userPassword = res;
            that.doRegionLogin(beforeRegion);
          },
        },
        PopupType.Password
      );
    } else {
      this.doRegionLogin(beforeRegion);
    }
  }

  private doRegionLogin(beforeRegion: AppRegion) {
    const that = this;
    this.rs
      .post(
        '/login',
        {
          email: this.us.currentUser.email,
          password: this.us.userPassword,
        },
        false
      )
      .subscribe(
        (response) => {
          if (response.success) {
            that.rs.setToken(response.token);
            that.us.setActiveUser(response);
            that.us.setLoginType('default');
            that.systems.loadSystems();
            that.ic.checkIcons();
            that.ws.websockInit();
          } else {
            this.toaster.postError(response.error);
            this.region.useRegion(beforeRegion);
            this.currentRegion = beforeRegion.backEndHost;
            this.us.userPassword = '';
          }
        },
        (error) => {
          this.toaster.postError(that.trans('auth.failed'));
          this.region.useRegion(beforeRegion);
          this.currentRegion = beforeRegion.backEndHost;
        }
      );
  }

  public openVersion($event) {
    $event.stopPropagation();
    this.hide();
    this.router.navigate(['/version']);
  }

  public addNewAccount() {
    localStorage.setItem('ref', 'addAccount');
    this.systems.clearSystems();
    this.rs.setToken('');
    this.ws.close();
    this.hide();
    if (this.pl.isDesktop()) {
      const loginType = this.us.getLoginType();
      if (loginType !== null && loginType === 'google') {
        this.google.signOut(true);
      }
    } else if (this.pl.isAndroid()) {
      this.pl.androidHandler().doLogOutGoogle();
    }
    this.router.navigate(['/login/email']);
  }

  public doLogout() {
    this.login.logout();
    this.router.navigate(['/login']);
  }
}
