<div class="arcs_container">
    <div class="arcs_inactive_ring"></div>
    <div class="arcs_active_ring">
        <svg *ngIf='readyToShowActivePart'>
            <circle id="arcs_active_circ" fill="transparent" [attr.stroke-dasharray]="circleCircum" [attr.stroke-dashoffset]="getCurrentActiveProgress()" stroke-width="10px" [attr.r]="circleRadius" [style.transform]="'translate(50%, 50%) rotate(164deg)'"></circle>
        </svg>
    </div>
    <div class="arcs_slider_container" [style.transform]="'translateY(-50%) rotate(' + percentToDeg() + 'deg)'">
        <div class="arcs_slider" 
            (mousedown)='ps.isDesktop() ? startDragging($event.clientX, $event.clientY) : true' 
            (mouseup)='ps.isDesktop() ? endDragging() : true' 
            (mouseleave)='ps.isDesktop() ? endDragging() : true' 
            (mousemove)='ps.isDesktop() ? stillDragging($event.clientX, $event.clientY): true'
            (touchstart)='!ps.isDesktop() ? startDragging($event.touches.item(0).clientX, $event.touches.item(0).clientY) : true'
            (touchmove)='!ps.isDesktop() ? stillDragging($event.touches.item(0).clientX, $event.touches.item(0).clientY) : true'
            (touchend)='!ps.isDesktop() ? endDragging() : true'>
        </div>
    </div>
    <div class="arcs_left_end"></div>
    <div class="arcs_right_end"></div>
</div>