<ng-container *ngIf="!platform.isApple()">
    <div id="wd_choose">{{trans('systems.labels.chooseWifi')}}</div>
    <div class="separator16"></div>
    <div class="padding_l16 section_header">{{trans('systems.labels.wifiConnections')}}</div>
    <div class="wd_wifi_connection_item" *ngFor="let iConnection of wfs.getOtherNetworks()">
        <div class="white_round">
            <app-button-single-line-arrow (click)="wifiSelected(iConnection.ssid)" [showArrow]="true" [icon]="'assets/images/wifi_' + iConnection.level + '.svg'">{{iConnection.ssid}}</app-button-single-line-arrow>
        </div>
    </div>
</ng-container>
<div class="separator8"></div>
<ng-container *ngIf="platform.isApple()">
    <div class="separator16"></div>
    <div class="separator16"></div>
</ng-container>
<div id="wd_manual" class="fsize16" (click)="enterSsid()">{{trans('systems.labels.enterWifiManualy')}}</div>
<div class="separator16"></div>
