<div class='home_therm_grid'>
    <div class="home_therm_header" (click)='openThermostat(thermostat)'>{{sensorName}}<span [inlineSVG]="'assets/images/forward.svg'"></span></div>
    <div *ngIf='!isInEditMode' [@actualFade]='actualTempState' class="home_therm_temp">{{getTaragetTemperature(thermostat)}}{{is.getSensorTypeObject(getThermostatType()).unit}}</div>
    <div *ngIf='systems.activeSystemHelper.can.edit.sensors()' class="home_therm_left">
        <div class="home_therm_button" [inlineSVG]="'assets/images/minus.svg'" (click)='minusClicked()'></div>
    </div>
    <div *ngIf='systems.activeSystemHelper.can.edit.sensors()' class="home_therm_right">
        <div class="home_therm_button" [inlineSVG]="'assets/images/plus.svg'" (click)='plusClicked()'></div>
    </div>
    <div *ngIf="!isInEditMode && !isOutputOn(thermostat)" class="home_therm_inactive">{{trans('sensors.labels.actualTemp')}}</div>
    <div *ngIf="!isInEditMode && isOutputOn(thermostat) && thermostat.action === 0" class="home_therm_active heat_color">{{trans('sensors.labels.heating')}}</div>
    <div *ngIf="!isInEditMode && isOutputOn(thermostat) && thermostat.action === 1" class="home_therm_active cool_color">{{trans('sensors.labels.cooling')}}</div>
    <div  *ngIf="isInEditMode"class="home_therm_inactive">{{trans('sensors.labels.setTemperature')}}</div>
    <div class="home_therm_value" (click)='openThermostat(thermostat)'>
        <div class="home_therm_icon" *ngIf='!isOutputOn(thermostat) || isInEditMode'>
            <div [inlineSVG]="'assets/images/sensors.svg'"></div>
        </div>
        <div class="home_therm_icon_action" *ngIf="isOutputOn(thermostat) && !isInEditMode && thermostat.action === 0">
            <div [inlineSVG]="'assets/images/heat.svg'"></div>
        </div>
        <div class="home_therm_icon_action" *ngIf="isOutputOn(thermostat) && !isInEditMode && thermostat.action === 1">
            <div [inlineSVG]="'assets/images/cool.svg'"></div>
        </div>
        <div *ngIf='!isInEditMode' [@actualFade]='actualTempState' [ngClass]="{ 'home_therm_number': true, 'heat_color': isOutputOn(thermostat) && thermostat.action === 0, 'cool_color': isOutputOn(thermostat) && thermostat.action === 1}">{{getSensorTemperatureBase(thermostat)}}<span class="home_therm_dec">.{{getSensorTemperatureDecimals(thermostat)}}</span>{{is.getSensorTypeObject(getThermostatType()).unit}}</div>
        <div *ngIf='isInEditMode' [@targetFade]='targetTempState' class="home_therm_number">{{getThermostatTemperatureBase(thermostat)}}<span class="home_therm_dec">.{{getThermostatTemperatureDecimals(thermostat)}}</span>{{is.getSensorTypeObject(getThermostatType()).unit}}</div>
    </div>
</div>