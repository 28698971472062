import { Injectable, Injector, OnDestroy } from '@angular/core';
import { SystemZoneData } from 'src/api/v3/common';
import { TZoneData } from 'src/app/models/zone-data';
import { autoinject } from 'src/shim';
import { AuthService } from '../auth.service';
import { AreaService } from './area.service';

@Injectable({
  providedIn: 'root',
})
export class ZoneService implements OnDestroy {
  public readonly zones = new Map<number, TZoneData>();
  public readonly zoneAreas = new Map<number, Set<number>>();
  public readonly systemZones = new Map<number, Set<number>>();

  private areaService = autoinject(this.injector, AreaService);

  private cleanUpSubscription = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.zones.clear();
    this.zoneAreas.clear();
    this.systemZones.clear();
  });
  constructor(private injector: Injector, private auth: AuthService) {}
  ngOnDestroy(): void {
    this.cleanUpSubscription.unsubscribe();
  }

  ingestZone(zone?: SystemZoneData): TZoneData | undefined {
    if (!zone) return;

    if (!this.areaService().areas.has(zone.area_id)) return;
    const { area_id, ...rest } = zone;

    if (!this.zones.has(zone.id)) {
      const processedZone: TZoneData = {
        areas: [],
        alarmed: false,
        bypassed: false,
        enabled: true,
        failed: false,
        ...rest,
      };
      Object.defineProperty(processedZone, 'areas', {
        get: () => [...this.zoneAreas.get(zone.id).values()],
      });
      this.zones.set(zone.id, processedZone);
    }

    if (!this.areaService().areaZones.has(zone.area_id)) {
      this.areaService().areaZones.set(zone.area_id, new Set());
    }
    this.areaService().areaZones.get(area_id)?.add(zone.id);
    if (!this.zoneAreas.has(zone.id)) {
      this.zoneAreas.set(zone.id, new Set());
    }
    this.zoneAreas.get(zone.id)?.add(zone.area_id);
    if (!this.systemZones.has(zone.system_id)) {
      this.systemZones.set(zone.system_id, new Set());
    }
    this.systemZones.get(zone.system_id)?.add(zone.id);

    return this.zones.get(zone.id);
  }
}
