<div class="grid system-zone-list-grid">
    <div id='szl-background' class="group-background"></div>
    <div id='szl-zone-list'>
        <div class="list-item-v1" *ngFor='let zone of getZones()' (click)='onZoneSelect(zone.queue_no)'>
            <div [inlineSVG]="'assets/images/zone-general-icon.svg'"></div>
            <div >{{zone.queue_no}}</div>
            <div>
                <div>{{zone.name}}</div>
                <div *ngIf="systems.activeSystemHelper.supports.areas()">{{getAssignedAreas(zone.queue_no)}}&nbsp;</div>
            </div>
            <div *ngIf='systems.activeSystemHelper.can.delete.zones()' [inlineSVG]="'assets/images/delete.svg'" (click)='onZoneDelete($event, zone.queue_no)'></div>
        </div>
    </div>
</div>
<div class="separator32"></div>
<app-plus-button *ngIf='systems.activeSystemHelper.can.add.zones()' (plusClicked)="onAddZone()"></app-plus-button>
