import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateTime } from 'luxon';
import { useUniqueId } from '../form-input/form-input.component';

@Component({
  selector: 'app-datetime-input',
  templateUrl: './datetime-input.component.html',
  styleUrls: ['./datetime-input.component.scss'],
})
export class DatetimeInputComponent implements OnInit {
  public formId = useUniqueId('form-input-');
  @Input() label: string;
  @Input() required = false;
  @Input() disabled = false;
  @Input() type?: 'date' | 'time' | 'datetime' = 'datetime';
  @Input() value: Date | DateTime | null;
  @Output() valueChange = new EventEmitter<DateTime>();
  @Output() Focus = new EventEmitter<FocusEvent>();
  @Output() Blur = new EventEmitter<FocusEvent>();
  @Output() Keydown = new EventEmitter<KeyboardEvent>();
  @Output() Keyup = new EventEmitter<KeyboardEvent>();
  @Output() Keypress = new EventEmitter<KeyboardEvent>();

  public get htmlInputType() {
    if (this.type === 'date') return 'date';
    if (this.type === 'time') return 'time';
    return 'datetime-local';
  }
  constructor() {}

  ngOnInit(): void {}

  public get inputProxy(): any {
    if (this.value instanceof DateTime) {
      switch (this.type) {
        case 'datetime':
          return this.value.set({ second: 0, millisecond: 0 }).toISO({
            includeOffset: false,
            suppressMilliseconds: true,
            suppressSeconds: true,
          });
        case 'date':
          return this.value.set({ second: 0, millisecond: 0 }).toISODate();
        case 'time':
          return this.value.set({ second: 0, millisecond: 0 }).toISOTime({
            includeOffset: false,
            suppressMilliseconds: true,
            suppressSeconds: true,
          });
        default:
          throw new Error('Invalid type');
      }
    } else if (this.value instanceof Date) {
      return this.value.toISOString();
    } else {
      return '';
    }
  }
  public set inputProxy(value: any) {
    const selected = DateTime.fromISO(value);
    this.valueChange.emit(selected);
  }
}
