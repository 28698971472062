import { Injectable, Injector, OnDestroy } from '@angular/core';
import { SystemPgmData } from 'src/api/v3/common';
import { TPgmData } from 'src/app/models/pgm-data';
import { autoinject } from 'src/shim';
import { AuthService } from '../auth.service';
import { AreaService } from './area.service';

@Injectable({
  providedIn: 'root',
})
export class PgmService implements OnDestroy {
  public readonly pgms = new Map<number, TPgmData>();
  public readonly systemPgms = new Map<number, Set<number>>();

  private readonly areaService = autoinject(this.injector, AreaService);

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.pgms.clear();
    this.systemPgms.clear();
  });
  constructor(private injector: Injector, private auth: AuthService) {}
  ngOnDestroy(): void {
    this.cleanupSubscribtion.unsubscribe();
  }

  ingestPgm(pgm?: SystemPgmData, system_id?: number): TPgmData | undefined {
    if (!pgm || !system_id) return;
    if ((pgm.io_type as number) === 3 && pgm.io_ability === 'W') return;
    const { pgm_as_area_ck: control_area, enabled, icon_number: icon, on, pulse_time_in_seconds: pulseTime, area_to_arm: area_id, pulse_start_time, ...rest } = pgm;

    const processedPgm: TPgmData = {
      area_no: 0,
      control_area: control_area !== 0,
      enabled: enabled !== 0,
      icon,
      on: on !== 0,
      pulseTime,
      area_id,
      pulse_activated_at: Number(pulse_start_time) ?? 0,

      ...rest,
    };
    if (this.areaService().areas.has(area_id)) {
      processedPgm.area_no = this.areaService().areas.get(area_id).queue_no ?? 0;
    }

    this.pgms.set(pgm.id, processedPgm);
    if (!this.systemPgms.has(system_id)) {
      this.systemPgms.set(system_id, new Set());
    }
    this.systemPgms.get(system_id)?.add(pgm.id);
    return processedPgm;
  }
}
