import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNumeric } from 'src/shim';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { UserRole } from 'src/app/models/user-role';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-change-installer',
  templateUrl: './change-installer.component.html',
  styleUrls: ['./change-installer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ChangeInstallerComponent extends LanguageAware implements OnInit {
  private chosenInstaller = 0;
  public targetEmail = '';
  public filteredPeople = [];

  constructor(cdRef: ChangeDetectorRef, ar: ActivatedRoute, private router: Router, private pp: PopupService) {
    super(cdRef);
    const installer = ar.snapshot.paramMap.get('installer');
    if (installer !== null && isNumeric(installer)) {
      this.chosenInstaller = parseInt(installer, 10);
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.labels.changeInstaller'),
      backUrl: this.g.getHomeUrl() + '/request-assistance/' + this.chosenInstaller,
    });
    this.background.setGray();
    if (this.us.companyInstallers.length === 0) {
      this.getUsers();
    }
    const that = this;
    this.footerBar.showFooter(this.trans('systems.buttons.changeInstaller'), '', true, false);
    this.footerBar.onButton1Click = () => {
      that.doChange();
    };
  }

  ngOnInit(): void {}

  private getUsers() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    this.api.post('/all-users', {}, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          that.us.companyInstallers = result.users.filter((u) => u.role === UserRole.Installer);
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }

  private doChange() {
    this.router.navigate([this.g.getHomeUrl() + '/request-assistance/' + this.chosenInstaller]);
  }

  public doFilter(value: string) {
    if (value === '') {
      this.filteredPeople = [];
      return;
    }
    this.targetEmail = value;
    this.filteredPeople = this.us.companyInstallers.filter((ci) => ci.email.indexOf(value) !== -1 || ci.name.indexOf(value) !== -1);
  }

  public selectPerson(installer: any) {
    this.targetEmail = installer.email;
    this.chosenInstaller = installer.id;
    this.filteredPeople = [];
  }

  public selectFromList() {
    const that = this;
    this.pp.showSlideout(
      {
        headerText: this.trans('systems.labels.listOfInstallers'),
        items: this.us.companyInstallers.map((ci) => ({
          value: ci.id,
          text: ci.email,
        })),
        onSubmit: (res) => {
          that.targetEmail = res.text;
          that.chosenInstaller = res.value;
        },
      },
      PopupType.SlideoutWithValue
    );
  }
}
