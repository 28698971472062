import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { TDeviceInfo } from '../models/device-info';
import { TDeviceStatus } from '../models/device-status';
import { TPgmData } from '../models/pgm-data';
import { TAreaData } from '../models/area-data';
import { TZoneData } from '../models/zone-data';
import { LanguageService } from './language.service';
import { TSensorData } from '../models/sensor-data';
import { TDeviceUser } from '../models/device-user';
import { TimezoneService } from './timezone.service';
import { LoggerService } from '../api/logger.service';
import { THomeTheme } from '../models/home-theme';

@Injectable({
  providedIn: 'root',
})
export class NewSystemService {
  private systemUid = '';
  private systemTimeZone = '';
  private systemName = '';
  private systemLocationAddress = '';
  private systemLocationCoords = '';
  private systemTheme: THomeTheme = {
    startColor: '#0070A7',
    endColor: '#79BAD9',
    fullBackground: '',
  };
  private mpass = '';
  private deviceInfo: TDeviceInfo = null;
  private deviceStatus: TDeviceStatus = null;
  private currentPgm = 0;
  private currentPgmObj: TPgmData = null;
  private currentArea = 0;
  private currentAreaObj: TAreaData = null;
  private currentZone = 0;
  private currentZoneObj: TZoneData = null;
  private directControl = false;
  private ipcom = 0;
  private objectId = '';
  public cgUsers: TDeviceUser[] = [];
  public systemMaxUsers = 0;
  public systemCharset = 0;
  public userListRead = false;
  public foreignSystem?: any;
  public foreignRegionName?: any;
  public ownRegion?: any;

  constructor(private rs: ApiRequestService, private ls: LanguageService, private ts: TimezoneService, private l: LoggerService) {
    this.systemTimeZone = this.ts.userTimeZone;
  }

  public setDefaults() {
    this.systemUid = '';
    this.systemTimeZone = this.ts.userTimeZone;
    this.systemName = '';
    this.systemLocationAddress = '';
    this.systemLocationCoords = '';
    this.systemTheme = {
      startColor: '#0070A7',
      endColor: '#79BAD9',
      fullBackground: '',
    };
    this.mpass = '123456';
    this.deviceInfo = null;
    this.deviceStatus = null;
    this.currentPgm = 0;
    this.currentArea = 0;
    this.currentZone = 0;
    this.currentPgmObj = null;
    this.currentAreaObj = null;
    this.currentZoneObj = null;
    this.cgUsers = [];
    this.userListRead = false;
    this.systemCharset = 0;
    this.systemMaxUsers = 0;
    this.ipcom = 0;
  }

  public get(): any {
    return {
      systemUid: this.systemUid,
      systemTimeZone: this.systemTimeZone,
      systemName: this.systemName,
      systemLocationAddress: this.systemLocationAddress,
      systemLocationCoords: this.systemLocationCoords,
      systemTheme: this.systemTheme,
      mpass: this.mpass,
      deviceInfo: this.deviceInfo,
      deviceStatus: this.deviceStatus,
      direct: this.directControl,
      cgUsers: this.cgUsers,
      maxUsers: this.systemMaxUsers,
      charset: this.systemCharset,
    };
  }

  public getUserTimeZone(): string {
    return this.ts.userTimeZone;
  }

  public getSystemTimeZone(): string {
    return this.systemTimeZone;
  }

  public setSystemTimeZone(value: string) {
    this.systemTimeZone = value;
  }

  public setUID(value: string) {
    this.systemUid = value;
  }

  public getUID(): string {
    return this.systemUid;
  }

  public setName(value: string) {
    this.systemName = value;
  }

  public getName(): string {
    return this.systemName;
  }

  public getAddress(): string {
    return this.systemLocationAddress;
  }

  public setAddress(value: string) {
    this.systemLocationAddress = value;
  }

  public getTheme(): THomeTheme {
    return this.systemTheme;
  }

  public setTheme(value: THomeTheme) {
    this.systemTheme = value;
  }

  public setMpass(value: string) {
    this.mpass = value;
  }

  public getMpass(): string {
    return this.mpass;
  }

  public setDeviceInfo(value: TDeviceInfo) {
    this.deviceInfo = value;
    if (this.deviceInfo.supported_commands.length !== 0) {
      this.deviceInfo.supported_commands = '.' + this.deviceInfo.supported_commands + '.';
    }
    this.systemUid = value.uid;
    if (this.systemUid.length < 12) {
      this.systemUid = this.systemUid.padStart(12, '0');
    }
  }

  public getDeviceInfo(): TDeviceInfo {
    return this.deviceInfo;
  }

  public setDeviceStatus(value: TDeviceStatus) {
    this.l.log('', 'NewSystem', JSON.parse(JSON.stringify(value)));
    this.deviceStatus = value;

    const pgms = value.pgms as any;
    const pgmNumbers = Object.keys(pgms);
    this.deviceStatus.pgms = [];
    for (const i of pgmNumbers) {
      const pgm = pgms[i];
      if (!pgm.enabled) {
        continue;
      }
      const pgmData: TPgmData = {
        queue_no: parseInt(i, 10),
        area_no: 0,
        control_area: false,
        enabled: pgm.enabled,
        on: pgm.on,
        name: 'PGM' + parseInt(i, 10),
        icon: -1,
        pulseTime: 3,
        type: ['24' /* GV17 Gator */, '37' /* WP17, CWI */].includes(this.deviceInfo.hwId) ? 'pulse' : 'level',
        id: 0,
        area_id: 0,
        pulse_activated_at: 0,
        io_ability: pgm.io_ability === undefined ? '' : pgm.io_ability,
        io_type: pgm.io_type === undefined ? 0 : pgm.io_type,
      };
      this.deviceStatus.pgms.push(pgmData);
    }
    const zones = value.zones;
    const zoneNumbers = Object.keys(zones);
    this.deviceStatus.zones = [];
    for (const i of zoneNumbers) {
      const zone = zones[i];
      const zoneData: TZoneData = {
        queue_no: parseInt(i, 10),
        alarmed: zone.alarm,
        areas: [],
        bypassed: zone.bypass,
        enabled: zone.enabled,
        failed: zone.failure,
        name: this.ls.get('systems.titles.zone').replace(':no', i),
        native: true,
        visible: true,
      };
      this.deviceStatus.zones.push(zoneData);
    }

    const sensors = value.sensors;
    const sensorNumbers = Object.keys(sensors);
    this.deviceStatus.sensors = [];
    for (const i of sensorNumbers) {
      const sensor = sensors[i];
      const sensorData: TSensorData = {
        active: sensor.active,
        alarmHigh: sensor.alarm_high,
        alarmLow: sensor.alarm_low,
        enabled: sensor.enabled,
        queue_no: parseInt(i, 10),
        temperature: sensor.temperature,
        name: this.ls.get('systems.titles.sensor').replace(':no', i),
        type: 0,
        notificationAboutHigh: true,
        notificationAboutLow: true,
        highValue: 0,
        lowValue: 0,
      };
      this.deviceStatus.sensors.push(sensorData);
    }

    const areas = value.areas;
    const areaNumbers = Object.keys(areas);
    this.deviceStatus.areas = [];
    for (const iArea of areaNumbers) {
      const area = areas[iArea];
      const areaData: TAreaData = {
        canBypass: false,
        id: 0,
        name: this.ls.get('events.titles.area').replace(':area', iArea),
        queue_no: parseInt(iArea, 10),
        showKeypad: false,
        status: this.convertAreaStatus(area),
        zones: [],
        statusTime: 0,
        alarmed: false,
        lastPerson: '',
        alarmTime: 0,
        alarmType: 0,
      };
      this.deviceStatus.areas.push(areaData);
    }
  }

  private convertAreaStatus(statusFromDevice: string): number {
    if (statusFromDevice === '00') {
      return 2;
    } // Armed
    if (statusFromDevice === '01') {
      return 5;
    } // Arming in progress
    if (statusFromDevice === '04') {
      return 1;
    } // Disarmed
    if (statusFromDevice === '0D') {
      return 4;
    } // Sleep
    if (statusFromDevice === '09') {
      return 3;
    } // Stay
    return 0;
  }

  public getDeviceStatus(): TDeviceStatus {
    return this.deviceStatus;
  }

  public setCurrentPgm(value: number, pgm?: TPgmData) {
    this.currentPgm = value;
    this.currentPgmObj = pgm === undefined ? null : pgm;
  }

  public getCurrentPgm(): number {
    return this.currentPgm;
  }

  public getCurrentPgmObj(): TPgmData {
    return this.currentPgmObj;
  }

  public getPgm(pgmNumber: number): TPgmData {
    let result: TPgmData = null;
    for (const i of this.deviceStatus.pgms) {
      if (i.queue_no === pgmNumber) {
        result = i;
        break;
      }
    }
    return result;
  }

  public getPgms(): TPgmData[] {
    return this.deviceStatus.pgms;
  }

  public setCurrentArea(value: number, obj?: TAreaData) {
    this.currentArea = value;
    this.currentAreaObj = obj === undefined ? null : obj;
  }

  public getCurrentArea(): number {
    return this.currentArea;
  }
  public getCurrentAreaObj(): TAreaData {
    return this.currentAreaObj;
  }

  public getArea(areaNumber: number) {
    return this.deviceStatus.areas.find((a) => a.queue_no === areaNumber);
  }

  public addArea(value: TAreaData) {
    this.deviceStatus.areas.push(value);
  }

  public deleteArea(value: number) {
    this.deviceStatus.areas = this.deviceStatus.areas.filter((a) => a.queue_no !== value);
  }

  public getAreas(): TAreaData[] {
    return this.deviceStatus.areas.sort((a1, a2) => a1.queue_no - a2.queue_no);
  }

  public setCurrentZone(zoneNumber: number, obj?: TZoneData) {
    this.currentZone = zoneNumber;
    this.currentZoneObj = obj === undefined ? null : obj;
  }

  public getCurrentZone(): number {
    return this.currentZone;
  }

  public getCurrentZoneObj(): TZoneData {
    return this.currentZoneObj;
  }

  public getZone(zoneNumber: number) {
    return this.deviceStatus.zones.find((z) => z.queue_no === zoneNumber);
  }

  public getZones(): TZoneData[] {
    return this.deviceStatus.zones.sort((z1, z2) => z1.queue_no - z2.queue_no);
  }

  public addZone(zone: TZoneData) {
    this.deviceStatus.zones.push(zone);
  }

  public deleteZone(value: number) {
    const zone = this.getZone(value);
    if (zone !== undefined) {
      if (zone.native) {
        zone.visible = false;
      } else {
        this.deviceStatus.zones = this.deviceStatus.zones.filter((z) => z.queue_no !== value);
      }
    }
  }

  public setDirectControl(value: boolean) {
    this.directControl = value;
  }

  public isDirect(): boolean {
    return this.directControl;
  }

  public setCoordinates(lat: number, lon: number) {
    this.systemLocationCoords = lat.toString() + ' ' + lon.toString();
  }

  public hasCoordinates(): boolean {
    return this.systemLocationCoords !== '';
  }

  public getCoordinates(): string {
    return this.systemLocationCoords;
  }

  public setIpcom(value: number) {
    this.ipcom = value;
  }

  public getIpcom(): number {
    return this.ipcom;
  }
}
