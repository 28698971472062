import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNumeric } from 'src/shim';
import { MessageboxComponent } from 'src/app/popups/messagebox/messagebox.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { SlideoutItem } from 'src/app/models/slideout-item';
import { PopupService } from 'src/app/services/popup.service';
import { AssistingInstallerComponent } from '../assisting-installer/assisting-installer.component';

@Component({
  selector: 'app-request-assistance',
  templateUrl: './request-assistance.component.html',
  styleUrls: ['./request-assistance.component.scss'],
})
export class RequestAssistanceComponent extends LanguageAware implements OnInit {
  @ViewChild('assistingInstaller') assistingInstaller;
  @ViewChild('systemToAssist') systemToAssist;
  @ViewChild('messageBox') messageBox: MessageboxComponent;
  public periodTime = 1;
  public formContent = '';
  private originalInstallerId = 0;
  private originalInstallerName = '';
  private originalInstallerEmail = '';
  public editMode = false;
  public hoursLeft = '';

  constructor(public cdRef: ChangeDetectorRef, private pp: PopupService, private router: Router, ar: ActivatedRoute) {
    super(cdRef);
    this.originalInstallerId = this.systems.activeSystem.installerId;
    this.originalInstallerEmail = this.systems.activeSystem.installerEmail;
    this.originalInstallerName = this.systems.activeSystem.installerName;
    if ('installerAssistance' in this.us) {
      if (this.us.installerAssistance) {
        this.periodTime = this.us.installerAssistance.time;
        this.formContent = this.us.installerAssistance.issue;
      }
      delete this.us.installerAssistance;
    }

    const installer = ar.snapshot.paramMap.get('installer');
    if (installer !== null && isNumeric(installer)) {
      const chosenInstaller = parseInt(installer, 10);
      const found = this.us.companyInstallers.find((i) => i.id === chosenInstaller);
      if (found !== undefined) {
        this.systems.activeSystem.installerId = chosenInstaller;
        this.systems.activeSystem.installerName = found.name;
        this.systems.activeSystem.installerEmail = found.email;
      }
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.assistance'),
      backUrl: '/home',
    });
    const that = this;
    this.headerBar.onBackClicked = () => {
      that.returnOriginalData();
    };
    this.background.setGray();
    if (this.systems.activeSystem.assistedById !== undefined) {
      this.getAssistanceData();
    }
  }

  ngOnInit(): void {}

  public changePeriod() {
    if (this.miniStatus.isVisible()) {
      return;
    }
    const hours: SlideoutItem[] = [{ value: 1, text: '1' }];
    for (let i = 1; i < 16; i++) {
      hours.push({
        value: i * 3,
        text: (i * 3).toString(),
      });
    }
    const that = this;
    this.pp.showSlideout(
      {
        headerText: this.trans('systems.labels.accessPeriod'),
        items: hours,
        onSubmit: (res) => {
          that.periodTime = res.value;
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public changeInstaller() {
    if (this.miniStatus.isVisible()) {
      return;
    }
    this.us.installerAssistance = {
      time: this.periodTime,
      issue: this.formContent,
    };
    const chosenInstaller = this.systems.activeSystem.installerId;
    this.returnOriginalData();
    this.router.navigate([this.g.getHomeUrl() + '/change-installer/' + chosenInstaller]);
  }

  public performRequest() {
    if (this.systems.activeSystem.installerId === 0) {
      return;
    }
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    this.api
      .post(
        '/installer-assistance',
        {
          id: this.systems.activeSystem.installerId,
          systemId: this.systems.activeSystem.id,
          accessPeriod: this.periodTime,
          describeIssue: this.formContent,
        },
        true
      )
      .subscribe(
        (result) => {
          that.editMode = false;
          if (result.success) {
            that.toaster.postSuccess(that.trans('systems.notifications.installerAccessGranted'));
            that.systems.activeSystem.assistedById = that.systems.activeSystem.installerId;
            that.systems.activeSystem.assistedByEmail = that.systems.activeSystem.installerEmail;
            that.rebuildHoursText(that.periodTime * 3600);
            that.returnOriginalData();
          } else {
            that.toaster.postError(result.error);
          }
          that.miniStatus.hide();
        },
        (error) => {
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
          that.miniStatus.hide();
        }
      );
  }

  private returnOriginalData() {
    this.systems.activeSystem.installerName = this.originalInstallerName;
    this.systems.activeSystem.installerId = this.originalInstallerId;
    this.systems.activeSystem.installerEmail = this.originalInstallerEmail;
    this.systemToAssist.rebuildInstallerName();
  }

  public initRevoke() {
    this.messageBox.show();
  }

  public performRevoke() {
    const asins = new AssistingInstallerComponent(this.cdRef);
    asins.installerId = this.systems.activeSystem.assistedById;
    asins.systemId = this.systems.activeSystem.id;
    asins.doRevoke();
    this.returnOriginalData();
  }

  public updateIssue() {
    this.editMode = true;
  }

  private getAssistanceData() {
    const that = this;
    this.api.get('/installer-assistance?systemId=' + this.systems.activeSystem.id, true).subscribe(
      (result) => {
        if (result.success && result.data !== null) {
          that.formContent = result.data.issue;
          that.rebuildHoursText(result.data.timeLeft);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
      }
    );
  }

  private rebuildHoursText(timeLeftInSeconds: number) {
    if (timeLeftInSeconds < 0) {
      this.hoursLeft = this.trans('users.labels.installerAssistanceExpiredHours');
      this.periodTime = 1;
    } else if (timeLeftInSeconds < 3600) {
      this.hoursLeft = '< 1' + this.trans('general.hoursShort');
      this.periodTime = 1;
    } else {
      this.hoursLeft = Math.ceil(timeLeftInSeconds / 3600) + this.trans('general.hoursShort');
      this.periodTime = Math.ceil(timeLeftInSeconds / 3600);
    }
  }
}
