import { Component, OnInit, OnDestroy } from '@angular/core';
import { FooterService } from 'src/app/services/footer.service';
import { PhoneKeyboardService } from 'src/app/services/phone-keyboard.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit, OnDestroy {
  public randomNumber1 = 0;
  public randomNumber2 = 0;
  public randomSums = [0, 0, 0];
  public sumOk = false;
  public sumButton1Bad = false;
  public sumButton2Bad = false;
  public sumButton3Bad = false;
  private keyboardSubscription = null;

  constructor(public footerService: FooterService, kb: PhoneKeyboardService) {
    const that = this;
    this.keyboardSubscription = kb.onKeyboardStateChange.subscribe((val) => {
      that.keyboardStateChanged(val);
    });
  }

  ngOnInit() {
    this.randomNumber1 = Math.floor(Math.random() * 10);
    this.randomNumber2 = Math.floor(Math.random() * 10);
    const correctSum = this.randomNumber1 + this.randomNumber2;
    this.randomSums[0] = Math.floor(Math.random() * 20);
    while (this.randomSums[0] === correctSum) {
      this.randomSums[0] = Math.floor(Math.random() * 20);
    }
    this.randomSums[1] = Math.floor(Math.random() * 20);
    while (this.randomSums[0] === this.randomSums[1] || this.randomSums[1] === correctSum) {
      this.randomSums[1] = Math.floor(Math.random() * 20);
    }
    this.randomSums[2] = Math.floor(Math.random() * 20);
    while (this.randomSums[0] === this.randomSums[2] || this.randomSums[1] === this.randomSums[2] || this.randomSums[2] === correctSum) {
      this.randomSums[2] = Math.floor(Math.random() * 20);
    }
    const correctIndex = Math.floor(Math.random() * 3);
    this.randomSums[correctIndex] = correctSum;
  }

  ngOnDestroy() {
    if (this.keyboardSubscription !== null) {
      this.keyboardSubscription.unsubscribe();
    }
  }

  public showButton1(): boolean {
    return this.footerService.getButton1Text() !== '';
  }

  public showButton2(): boolean {
    return this.footerService.getButton2Text() !== '';
  }

  public doButton1Click() {
    if (this.footerService.onButton1Click !== null) {
      this.footerService.onButton1Click();
    }
  }

  public doButton2Click() {
    if (this.footerService.onButton2Click !== null) {
      this.footerService.onButton2Click();
    }
  }

  public onSumClick(index: number) {
    const correctSum = this.randomNumber1 + this.randomNumber2;
    if (correctSum === this.randomSums[index]) {
      this.sumOk = true;
      if (this.footerService.onSumCorrect !== null) {
        this.footerService.onSumCorrect();
      }
    } else {
      if (index === 0) {
        this.sumButton1Bad = true;
      } else if (index === 1) {
        this.sumButton2Bad = true;
      } else if (index === 2) {
        this.sumButton3Bad = true;
      }
    }
  }

  private keyboardStateChanged(value: boolean) {}
}
