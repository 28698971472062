import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from 'src/app/api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoginEntryGuard implements CanActivate {
  private LoginEmailUrl = this.router.parseUrl('/login/email');
  private LoginAccountsUrl = this.router.parseUrl('/login/accounts');
  constructor(private auth: AuthService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.hasAccounts) {
      return this.LoginAccountsUrl;
    }
    return this.LoginEmailUrl;
  }
}
