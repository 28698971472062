import { httpGet } from './http';

export const a = {
  area_code: '0',
  organization_name: 'UAB Baltnetos komunikacijos',
  country_code: 'LT',
  country_code3: 'LTU',
  continent_code: 'EU',
  ip: '195.14.187.141',
  region: 'Kaunas',
  city: 'Kaunas',
  longitude: '23.8924',
  accuracy: 50,
  country: 'Lithuania',
  timezone: 'Europe/Vilnius',
  asn: 15440,
  organization: 'AS15440 UAB Baltnetos komunikacijos',
  latitude: '54.9038',
};

export const getGeoInfo = () =>
  httpGet<{
    area_code: string;
    organization_name: string;
    country_code: string;
    country_code3: string;
    continent_code: string;
    ip: string;
    region: string;
    city: string;
    longitude: string;
    accuracy: number;
    country: string;
    timezone: string;
    asn: number;
    organization: string;
    latitude: string;
  }>('https://get.geojs.io/v1/ip/geo.json', undefined, { auth: false });
