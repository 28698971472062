<div *ngIf="!hasConsented" class="consent-page">
  <div>
    <h2>Data we collect</h2>
    <ul>
      <li>{{ trans('privacyPolicy.p_email') }}</li>
      <li>{{ trans('privacyPolicy.p_name') }}</li>
      <li>{{ trans('privacyPolicy.p_cameraData') }}</li>
      <li>{{ trans('privacyPolicy.p_deviceId') }}</li>
      <li>{{ trans('privacyPolicy.p_push') }}</li>
    </ul>
    <a href="javascript:void(0);" (click)="$event.preventDefault(); consent(); (false)">
      <div class="button bare-secondary">Accept</div>
    </a>
  </div>
</div>
