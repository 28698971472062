import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss'],
})
export class PopupComponent implements OnInit {
  public isShown = false;
  @Input() public content?: TemplateRef<any>;
  @Input() public disableBgClick = false;
  @Output() public hidden = new EventEmitter<void>();
  @Output() public shown = new EventEmitter<void>();
  constructor() {}

  ngOnInit(): void {}

  public show = (eventless?: true) => {
    this.isShown = true;
    if (!eventless) this.shown.emit();
  };

  public hide = (eventless?: true) => {
    this.isShown = false;
    if (!eventless) this.hidden.emit();
  };

  public onBgClick($event: MouseEvent): void {
    if ($event.target === $event.currentTarget && !this.disableBgClick) {
      this.hide();
    }
  }
}
