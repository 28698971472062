import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from './button/button.component';
import { BooleanFormInputComponent, NumberFormInputComponent, StringFormInputComponent } from './form-input/form-input.component';
import { FormsModule } from '@angular/forms';
import { PopupComponent } from './popup/popup.component';
import { TeleportModule } from '../teleport/teleport.module';
import { IconButtonComponent } from './icon-button/icon-button.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { DatetimeInputComponent } from './datetime-input/datetime-input.component';
import { FormErrorComponent } from './form-error/form-error.component';
import { StepperComponent } from './stepper/stepper.component';

@NgModule({
  declarations: [
    ButtonComponent,
    StringFormInputComponent,
    NumberFormInputComponent,
    BooleanFormInputComponent,
    PopupComponent,
    IconButtonComponent,
    DropdownComponent,
    DatetimeInputComponent,
    FormErrorComponent,
    StepperComponent,
  ],
  imports: [CommonModule, FormsModule, TeleportModule],
  exports: [
    CommonModule,
    ButtonComponent,
    StringFormInputComponent,
    NumberFormInputComponent,
    BooleanFormInputComponent,
    PopupComponent,
    IconButtonComponent,
    DropdownComponent,
    DatetimeInputComponent,
    FormErrorComponent,
    StepperComponent,
  ],
})
export class UiModule {}
