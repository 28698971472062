<ng-container *ngIf="isShown">
  <ng-template appTeleport="body">
    <div class="popup" (click)="onBgClick($event)">
      <div class="content">
        <ng-container [ngTemplateOutlet]="content ?? voidTemplate" [ngTemplateOutletContext]="{ show, hide }"></ng-container>
      </div>
    </div>
  </ng-template>
</ng-container>
<ng-template #voidTemplate></ng-template>
