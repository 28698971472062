import { Injectable, TemplateRef } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TeleportService {
  public readonly teleports = new Map<any, Set<TemplateRef<any>>>();

  constructor() {}

  public activateTeleport(template: TemplateRef<any>, target: any) {
    if (!this.teleports.has(target)) {
      this.teleports.set(target, new Set());
    }
    this.teleports.get(target).add(template);
  }

  public deactivateTeleport(template: TemplateRef<any>, target: any) {
    this.teleports.get(target)?.delete(template);
  }
}
