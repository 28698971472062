import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent implements OnInit {
  @Input() public steps: string[] = ['Step 1', 'Step 2', 'Step 3'];
  @Input() public templates: TemplateRef<any>[] = [];
  @Input() public activeStep = 0;
  @Output() public updateActiveStep = new EventEmitter<number>();

  public get activeTemplate(): TemplateRef<any> {
    return this.templates[this.activeStep];
  }

  constructor() {}

  ngOnInit(): void {}

  public setStep(step: number) {
    this.activeStep = step;
    this.updateActiveStep.emit(step);
  }

  public nextStep() {
    this.activeStep++;
    this.updateActiveStep.emit(this.activeStep);
  }

  public prevStep() {
    this.activeStep--;
    this.updateActiveStep.emit(this.activeStep);
  }
}
