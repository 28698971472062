import { Component, OnInit, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-password-edit',
  templateUrl: './password-edit.component.html',
  styleUrls: ['./password-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PasswordEditComponent implements OnInit {
  public showPassword = false;
  public passwordIsFocused = false;
  @Input() value = '';
  @Input() maxCharacters = 255;
  @Input() doFocus = false;
  @Input() isReadOnly = false;
  @Input() initiallyShow = false;
  @Input() noOutline = false;
  @Output() enterClicked: EventEmitter<any> = new EventEmitter();
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();
  @Output() exitedField: EventEmitter<any> = new EventEmitter();

  constructor(public ls: LanguageService) {}

  ngOnInit(): void {
    this.showPassword = this.initiallyShow;
  }

  public onEnter() {
    this.enterClicked.emit();
  }

  public onKeyUp() {
    this.valueChanged.emit(this.value);
  }

  public onExitField() {
    this.exitedField.emit(this.value);
  }
}
