import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { TPgmData } from 'src/app/models/pgm-data';
import { LanguageAware } from 'src/app/general/language-aware';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { Router } from '@angular/router';
import { IconsService } from 'src/app/services/icons.service';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PopupService } from 'src/app/services/popup.service';
import { PopupType } from 'src/app/models/popup-type';
import { SlideoutItem } from 'src/app/models/slideout-item';
import { UserRole } from 'src/app/models/user-role';

@Component({
  selector: 'app-system-pgm',
  templateUrl: './system-pgm.component.html',
  styleUrls: ['./system-pgm.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SystemPgmComponent extends LanguageAware implements OnInit {
  public maxPgmLen = 100;
  public localPgm: TPgmData = {
    id: 0,
    area_id: 0,
    control_area: false,
    enabled: false,
    icon: -1,
    name: '',
    queue_no: 0,
    on: false,
    pulseTime: 3,
    type: 'pulse',
    area_no: 0,
    pulse_activated_at: 0,
    io_type: 0,
    io_ability: '',
  };
  private backUrl = '/system-pgm-list';
  public addingNew = false;

  constructor(cdRef: ChangeDetectorRef, private es: EditSystemService, private router: Router, public is: IconsService, private pp: PopupService) {
    super(cdRef);
    const that = this;
    const pgm = this.es.getEditableComponent(TEditableComponent.Output);
    if (pgm === undefined) {
      this.router.navigate([this.backUrl]);
      return;
    }
    this.addingNew = pgm.id === 0;
    this.localPgm = pgm;
    this.tag = 'PGM' + this.localPgm.queue_no;
    const refType = localStorage.getItem('ref');
    this.backUrl = refType !== null && refType === 'home' ? this.g.getHomeUrl() : '/system-pgm-list';
    this.headerBar.showHeader({
      headerText: pgm.name,
      backUrl: this.backUrl,
      actionButtonText: this.systems.activeSystemHelper.can.delete.outputs() && !this.addingNew ? this.trans('general.delete') : '',
    });
    this.background.setGray();
    const activeSystemId = this.systems.activeSystem.id;
    if (this.systems.activeSystemHelper.can.edit.outputs()) {
      this.footerBar.showFooter(this.trans('general.cancel'), this.addingNew ? this.trans('general.add') : this.trans('general.save'), true, false);
      this.footerBar.onButton1Click = () => {
        const old = that.es.getComponentBeforeModification(TEditableComponent.Output);
        that.revertChanges(activeSystemId, old);
        that.router.navigate([this.backUrl]);
      };
      this.footerBar.onButton2Click = () => {
        that.onDone();
      };
    }
    this.headerBar.onBackClicked = () => {
      const old = that.es.getComponentBeforeModification(TEditableComponent.Output);
      that.revertChanges(activeSystemId, old);
    };
    if (this.systems.activeSystemHelper.can.delete.outputs() && !this.addingNew) {
      this.headerBar.onActionButtonClicked = () => {
        that.deleteOutput();
      };
    }
    if (this.systems.activeSystem.hwType === 'SP3') {
      this.maxPgmLen = 31;
    }
  }

  ngOnInit(): void {}

  public onDone() {
    if (this.localPgm.id === 0) {
      return;
    }
    const that = this;
    const activeSystemId = this.systems.activeSystem.id;
    const pgm = this.es.getComponentBeforeModification(TEditableComponent.Output);
    this.log('Saugom pgm', this.localPgm);
    this.api.post('/edit/pgm', { pgm: this.localPgm }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.revertChanges(activeSystemId, pgm);
          that.toaster.postError(result.error);
        } else {
          that.systems.saveActiveSystem(activeSystemId);
        }
      },
      (error) => {
        that.revertChanges(activeSystemId, pgm);
        that.toaster.postError(that.trans('systems.errors.pgmSaveFailed'));
      }
    );
    this.router.navigate([this.backUrl]);
  }

  private revertChanges(systemID: number, pgm: TPgmData) {
    const system = this.systems.getSystem(systemID);
    if (system === undefined) {
      return;
    }

    const systemPgm = system.pgms.find((p) => p.id === pgm.id);
    if (systemPgm === undefined) {
      return;
    }

    systemPgm.name = pgm.name;
    systemPgm.pulseTime = pgm.pulseTime;
    systemPgm.type = pgm.type;
    systemPgm.icon = pgm.icon;
    systemPgm.enabled = pgm.enabled;
    systemPgm.io_type = pgm.io_type;

    if (systemID === this.systems.activeSystem.id) {
      this.systems.saveActiveSystem(system.id);
    }
  }

  public openIconSelection() {
    if (this.us.currentUser.role === UserRole.SimpleUser && !this.systems.activeSystem.amIMaster) {
      return;
    }
    this.router.navigate(['/pgm-icon-selection']);
  }

  public selectTerminal() {
    const that = this;
    const slideoutItems: SlideoutItem[] = [];
    for (const iPgm of this.systems.activeSystem.pgms) {
      if (iPgm.io_ability === 'I') {
        continue;
      }
      if (iPgm.enabled) {
        continue;
      }
      slideoutItems.push({
        text: iPgm.name,
        value: iPgm.id,
      });
    }
    this.pp.showSlideout(
      {
        headerText: this.trans('systems.labels.selectTerminal'),
        items: slideoutItems,
        onSubmit: (res) => {
          const selectedPgm = that.systems.activeSystem.pgms.find((p) => p.id === res.value);
          if (that.localPgm.id !== 0) {
            that.revertChanges(that.systems.activeSystem.id, that.es.getComponentBeforeModification(TEditableComponent.Output));
          }
          that.es.beginComponentEdit(TEditableComponent.Output, selectedPgm.id, selectedPgm);
          that.localPgm = selectedPgm;
          selectedPgm.enabled = true;
          selectedPgm.io_type = 2; // Output
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  private deleteOutput() {
    this.localPgm.enabled = false;
    this.localPgm.io_type = 0;
    this.onDone();
  }
}
