import { Injectable } from '@angular/core';
import { ErrorResponse } from 'src/api/v3/common';
import { AuthService } from 'src/app/api/auth.service';
import { LoggerService } from 'src/app/api/logger.service';
import { PhoneStateService } from 'src/app/api/phone-state.service';
import { PlatformService } from 'src/app/api/platform.service';
import { RequestService } from 'src/app/api/request.service';

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  private tag = 'FcmService';

  constructor(psjsi: PhoneStateService, private l: LoggerService, private ps: PlatformService, private req: RequestService, private auth: AuthService) {
    const that = this;
    psjsi.onFcmToken.subscribe((data) => {
      that.onFcmTokenReceived(data.token, data.deviceId, data.appVersion);
    });
  }

  private onFcmTokenReceived(token: string, deviceId: string, appVersion: string) {
    this.l.log('Gautas FCM token', this.tag, { token, deviceId, appVersion });
    if (this.auth.hasToken()) {
      this.l.log('Vartotojas prisijungęs, bandom siųsti tokeną į serverį', this.tag);
      const that = this;
      const platform = this.ps.isAndroid() ? 'android' : 'ios';
      this.req.systemNotification.setNotificationToken({ token, deviceId, appVersion, platform }).subscribe(
        (result) => {
          if (result.success) {
            that.l.log('FCM token sėkmingai nusiųstas į serverį', that.tag);
          } else {
            that.l.log('Nepavyko nusiųsti naujo FCM tokeno į serverį', that.tag, (result as ErrorResponse).error);
          }
        },
        () => {
          that.l.log('Nepavyko nusiųsti naujo FCM tokeno į serverį. Serverio klaida.', that.tag);
        }
      );
    } else {
      this.l.log('Vartotojas neprisijungęs, nieko nedarom su FCM tokenu.', this.tag);
    }
  }

  /**
   * Nurodo, kad bandytų kreiptis į native appsą ir gauti naują FCM tokeną.
   */
  public requestForToken() {
    if (this.ps.isDesktop()) {
      return;
    }
    this.l.log('Užklausiam telefono koks yra jo FCM token', this.tag);
    if (this.ps.isAndroid()) {
      this.ps.androidHandler().requestFcmToken();
    } else if (this.ps.isApple()) {
      this.ps.appleHandler().requestFcmToken.postMessage('');
    }
  }
}
