<div class='white_round padding_l16' *ngIf="!isUnauthorisedUser">
    <div class="user_details_container">
        <ng-container *ngIf="!isCg">
            <app-text-edit [inputType]="'email'" [value]='userToUse.email' (valueChanged)='emailValueChanged($event)'>{{trans('auth.form.email')}}</app-text-edit>
            <app-text-edit [value]='userToUse.name' (valueChanged)='userToUse.name = $event' [maxCharacters]="23">{{trans('auth.form.nameAuth')}}</app-text-edit>
        </ng-container>
        <ng-container *ngIf="isCg">
            <app-text-edit [value]='userToUse.name' (valueChanged)='userToUse.name = $event'>{{trans('systems.titles.deviceUserName')}}</app-text-edit>
        </ng-container>
        <app-text-edit [value]='userToUse.phone' (valueChanged)='userToUse.phone = $event'>{{trans(systems.activeSystemHelper.supports.gvTypeUsers() ? 'users.labels.' + (systems.activeSystem.hwType === 'CWI' ? 'rfidNumber' : 'phoneOrRfid') : 'auth.form.phoneField')}}</app-text-edit>
        <app-text-edit *ngIf='systems.activeSystemHelper.can.edit.userKeyboardCode()' [value]='userToUse.code' (valueChanged)='userToUse.code = $event' [maxCharacters]='getPinLength()' [inputType]='"number"'>{{trans('systems.titles.deviceUserAreaCode')}}</app-text-edit>
        <app-text-edit *ngIf='canShowZoneNumber' [value]='userToUse.zone_number' [maxCharacters]='3' (valueChanged)='userToUse.zone_number = $event' [inputType]='"number"'>{{trans('systems.labels.persons.system_code_no')}}</app-text-edit>   
    </div>
    <app-name-value [nameText]="trans('systems.titles.deviceUserCanEditList.long')" [isEnabled]="canEditUsersEnabled">
        <app-toggle-button [buttonId]="'sueul_toggle'" [initialState]="userToUse.can_edit_users" (toggled)="userToUse.can_edit_users = $event.checked"></app-toggle-button>
    </app-name-value>
    <app-name-value *ngIf="canShowMakeAdministrator" [nameText]="trans('users.labels.deviceAdmin')">
        <app-toggle-button #adminToggle [buttonId]="'suia_toggle'" [initialState]="userToUse.zone_number <= maxAdminCount" (toggled)="handleAdministratorChange($event.checked)"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('users.labels.canSeeEvents')" [isEnabled]="canSeeEventsEnabled" [showUnderline]="false">
        <app-toggle-button [initialState]="userToUse.canSeeEvents" (toggled)="userToUse.canSeeEvents = $event.checked"></app-toggle-button>
    </app-name-value>
</div>
<div class='white_round padding_l16' *ngIf="isUnauthorisedUser">
    <app-name-value [nameText]="trans('users.labels.enableUnauthorizedAccess')" [showUnderline]="false">
        <app-toggle-button [buttonId]="'suuu_toggle'" [initialState]="isUserEnabled" (toggled)="changeUserEnabledValue($event.checked)"></app-toggle-button>
    </app-name-value>
</div>
<div class='section_header padding_l16' *ngIf="(systems.activeSystemHelper.supports.areas()) || canControlOutputs">{{trans('users.labels.allowToControl')}}</div>
<div class="white_round padding_l16" *ngIf="systems.activeSystemHelper.supports.areas()">
    <app-name-value *ngFor="let iArea of systems.activeSystem.areas; let i = index" [nameText]="iArea.name" [showUnderline]="i < systems.activeSystem.areas.length - 1" [icon]="'assets/images/area-general-icon.svg'">
        <app-toggle-button [buttonId]="'sua_toggle_' + i" [initialState]="userCanControlArea(iArea.queue_no)" (toggled)="areaClick(iArea.queue_no, $event.checked)"></app-toggle-button>
    </app-name-value>
</div>
<div class="white_round padding_l16" *ngIf="canControlOutputs">
    <app-name-value *ngFor="let iOut of availableOutputs; let i = index" [nameText]="iOut.name" [showUnderline]="i < availableOutputs.length - 1" [icon]="ic.getPgmIcon(iOut)">
        <app-toggle-button [buttonId]="'suo_toggle_' + i" [initialState]="isOuputAssigned(iOut.queue_no)" (toggled)="clickPgm(iOut.queue_no, $event.checked)"></app-toggle-button>
    </app-name-value>
</div>
<div class="separator32"></div>