import http from '../http';
import { BasicSuccessResponse, ErrorResponse, objectToParams, ReactionBasicData } from './common';

type TranslationsRequest = {
  language: string;
  version?: string;
  config?: string;
};

type TranslationsResponse = BasicSuccessResponse & {
  /** JSON string */
  translations: string;
  version: string;
  code: string;
};

export const translations = (req: TranslationsRequest) => http.post<TranslationsResponse, TranslationsRequest>('/v3/api/translations', req, { auth: false });

type TimeZonesResponse = {
  success: true;
  timeZones: string[];
  userTimeZone: string;
};

export const timezones = () => http.get<TimeZonesResponse>('/v3/api/timezones', undefined, { auth: false });

type GetVersionRequest<TKey extends string> = {
  keys: TKey[];
};
type GetVersionResponse<TKey extends string> =
  | ErrorResponse
  | (BasicSuccessResponse & {
      result: {
        key: TKey;
        version: string;
      }[];
    });

export const getVersion = <TKey extends string>(req: GetVersionRequest<TKey>) =>
  http.post<GetVersionResponse<TKey>, GetVersionRequest<TKey>>('/v3/api/get-version', req, { auth: 'ifHas' });

type GetSwitchIconsResponse = BasicSuccessResponse & {
  icons: {
    id: number;
    on: string;
    off: string;
    disabled: '';
    undetermined: '';
    on_png: string;
    off_png: string;
    disabled_png: '';
    undetermined_png: '';
  }[];
};

export const getSwitchIcons = () =>
  http.post<GetSwitchIconsResponse>('/v3/api/get-pgm-icon-paths', undefined, {
    auth: false,
  });

type GetSensorTypesResponse = BasicSuccessResponse & {
  icons: {
    id: number;
    name: string;
    unit: string;
    icon: string;
    icon_png: string;
  }[];
};

export const getSensorTypes = () =>
  http.post<GetSensorTypesResponse>('/v3/api/get-sensor-types', undefined, {
    auth: false,
  });

type GetReactionsResponse = BasicSuccessResponse & {
  icons: ReactionBasicData[];
};
export const getReactions = () =>
  http.post<GetReactionsResponse>('/v3/api/get-reactions', undefined, {
    auth: 'ifHas',
  });

type ChangeLanguageRequest = {
  language: string;
};
type ChangeLanguageResponse = ErrorResponse | BasicSuccessResponse;

export const setLanguage = (req: ChangeLanguageRequest) => http.post<ChangeLanguageResponse, ChangeLanguageRequest>('/v3/api/language/set', req);

type SubmitHelpIssueRequest = {
  content: string;
  user?: number;
  email?: string;
};
type SubmitHelpIssueResponse = ErrorResponse | (BasicSuccessResponse & { message: string });
export const submitHelpIssue = (req: SubmitHelpIssueRequest) => http.post<SubmitHelpIssueResponse, SubmitHelpIssueRequest>('/v3/api/help', req, { auth: false });

type UpdateLastSeenReactionRequest = { all: true; allEventId: number } | { reactions: { id: number; event_id: number }[] };
type UpdateLastSeenReactionResponse = ErrorResponse | BasicSuccessResponse;

export const updateLastSeenReaction = (req: UpdateLastSeenReactionRequest) =>
  http.post<UpdateLastSeenReactionResponse, UpdateLastSeenReactionRequest>('/v3/api/update-last-seen-reactions', req);

type GetCountriesResponse = BasicSuccessResponse & {
  list: {
    code: string;
    name: string;
  }[];
};
export const getCountries = () => http.post<GetCountriesResponse>('/v3/api/countries', undefined);

type SaveGlobalSettingsRequest = { user_can_transfer_device?: boolean };
type SaveGlobalSettingsResponse = BasicSuccessResponse;

export const saveGlobalSettings = (req: SaveGlobalSettingsRequest) => http.post<SaveGlobalSettingsResponse, SaveGlobalSettingsRequest>('/v3/api/settings', req);

export const getLogo = (fileName: string) => http.get<Blob, undefined, 'blob'>(`/v3/api/installer-logo/${fileName}`, undefined, { returntype: 'blob', auth: false });

export const getReactionGroupIcon = (fileName: string) => http.get<Blob, undefined, 'blob'>(`/reaction-group-icon/${fileName}`, undefined, { returntype: 'blob', auth: false });

export const getReactionIcon = (fileName: string) =>
  http.get<Blob, undefined, 'blob'>(`/reaction-icon/${fileName}`, undefined, {
    returntype: 'blob',
    auth: false,
  });

type LanguagesRequest<TStringy extends undefined | true> = {
  stringy?: TStringy;
};

type LanguagesResponse<TStringy extends undefined | true> = BasicSuccessResponse & {
  list: TStringy extends true ? string : Record<string, string>;
  clientLanguage: string;
  versions: Record<string, number>;
};

export const languages = <TStringy extends undefined | true = undefined>(req?: LanguagesRequest<TStringy>) =>
  http.get<LanguagesResponse<TStringy>, URLSearchParams>('/v3/api/languages', objectToParams(req ?? {}), { auth: false });
