import { Injectable } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { ToasterService } from './toaster.service';
import { SystemsService } from './systems.service';
import { LoggerService } from '../api/logger.service';
import { RefreshService } from './refresh.service';
import { TPgmData } from '../models/pgm-data';

@Injectable({
  providedIn: 'root',
})
export class PgmService {
  private pgmQueue = [];
  private reenableRefresher = false;

  constructor(private rs: ApiRequestService, private toaster: ToasterService, private ss: SystemsService, private l: LoggerService, private refresher: RefreshService) {
    const that = this;
    this.refresher.onSilentRefreshComplete.subscribe(() => {
      that.checkQueue();
    });
  }

  private checkQueue() {
    if (this.pgmQueue.length === 0) {
      return;
    }
    this.performToggle(this.pgmQueue[0].pgmId, this.pgmQueue[0].pgmQueue);
  }

  // Iškvietimai ateina iš pgm mygtukų
  public togglePgm(pgmId: number, pgmQueue: number, onFinish?: any) {
    if (this.pgmQueue.length > 0) {
      this.pgmQueue.push({
        pgmId,
        pgmQueue,
        onFinish,
      });
      this.l.log('Ne pirmas, dedam i eile PGM-' + pgmQueue, 'PgmService');
      return;
    }
    this.pgmQueue.push({
      pgmId,
      pgmQueue,
      onFinish,
    });
    if (this.refresher.isRefreshingSilently()) {
      this.l.log('Refresheris siuo metu dirba. Reikia palaukti.', 'PgmService');
      return;
    }
    this.reenableRefresher = this.refresher.refresherIsEnabled;
    this.refresher.disableRefresher();
    this.performToggle(pgmId, pgmQueue);
  }

  private performToggle(pgmId: number, pgmQueue: number) {
    this.l.log('Vykdom komanda PGM-' + pgmQueue, 'PgmService');
    const that = this;
    this.rs.post('/toggle-pgm', { pgmId }, true).subscribe(
      (result) => {
        const job = that.pgmQueue.shift();
        that.l.log('Ivykdyta. Imam is eiles PGM-' + job.pgmQueue, 'PgmService');
        if (result.success) {
          if (job.onFinish !== undefined) {
            job.onFinish(result.pgm);
          }
          if (that.pgmQueue.length > 0) {
            that.performToggle(that.pgmQueue[0].pgmId, that.pgmQueue[0].pgmQueue);
          } else {
            if (that.reenableRefresher) {
              that.refresher.enableRefresher();
            }
          }
          return;
        }
        that.toaster.postError(result.error);
        if (job.onFinish !== undefined) {
          job.onFinish(undefined);
        }
        if (that.pgmQueue.length > 0) {
          that.performToggle(that.pgmQueue[0].pgmId, that.pgmQueue[0].pgmQueue);
        } else {
          if (that.reenableRefresher) {
            that.refresher.enableRefresher();
          }
        }
      },
      (error) => {
        const job = that.pgmQueue.shift();
        that.l.log('Klaida. Imam is eiles PGM-' + job.pgmQueue, 'PgmService');
        if (job.onFinish !== undefined) {
          job.onFinish(undefined);
        }
        if (that.pgmQueue.length > 0) {
          that.performToggle(that.pgmQueue[0].pgmId, that.pgmQueue[0].pgmQueue);
        } else {
          if (that.reenableRefresher) {
            that.refresher.enableRefresher();
          }
        }
      }
    );
  }

  public updatePgmData(newData: any) {
    if (newData === undefined) {
      return;
    }
    const system = this.ss.getSystem(newData.system_id);
    if (system === undefined) {
      return;
    }
    const found = system.pgms.find((p) => p.id === newData.id);
    if (found === undefined) {
      return;
    }
    let changed = false;
    if (newData.on !== undefined && found.on !== (newData.on === 1)) {
      found.on = newData.on === 1;
      changed = true;
    }
    if (newData.icon_number !== undefined && found.icon !== newData.icon_number) {
      found.icon = newData.icon_number;
      changed = true;
    }
    if (newData.enabled !== undefined && found.enabled !== (newData.enabled === 1)) {
      found.enabled = newData.enabled === 1;
      changed = true;
    }
    if (newData.pulse_start_time !== undefined && found.pulse_activated_at !== newData.pulse_start_time * 1000) {
      found.pulse_activated_at = newData.pulse_start_time * 1000;
      changed = true;
    }
    if (changed) {
      this.ss.saveActiveSystem(newData.system_id);
    }
  }

  public isPgmInQueue(pgmId: number) {
    return this.pgmQueue.find((p) => p.pgmId === pgmId) !== undefined;
  }

  public setNewCallbackFunction(pgmId: number, callback: any) {
    const queueItem = this.pgmQueue.find((p) => p.pgmId === pgmId);
    if (queueItem === undefined) {
      return;
    }
    queueItem.onFinish = callback;
  }

  public getPgms(): TPgmData[] {
    return this.ss.activeSystem.pgms.filter((p) => !p.control_area && p.enabled);
  }
}
