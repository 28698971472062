import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { TSensorData } from 'src/app/models/sensor-data';
import { TThermostatData } from 'src/app/models/thermostat-data';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { GlobalService } from 'src/app/services/global.service';
import { IconsService } from 'src/app/services/icons.service';

@Component({
  selector: 'app-system-thermostat',
  templateUrl: './system-thermostat.component.html',
  styleUrls: ['./system-thermostat.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SystemThermostatComponent extends LanguageAware implements OnInit, AfterViewInit {
  public thermostatObject: TThermostatData = null;
  public sensorObject: TSensorData = null;
  private buttonIsDown = false;
  private temperatureStep = 0.1;
  private stepsBeforeFaster = 0;
  private stepper = null;
  private stepsDone = 0;

  constructor(cdRef: ChangeDetectorRef, private es: EditSystemService, private router: Router, public is: IconsService) {
    super(cdRef);
    this.tag = 'thermostat';
    const that = this;
    this.thermostatObject = this.es.getEditableComponent(TEditableComponent.Thermostat);
    const activeSensor = this.thermostatObject.activeSensor - 1;
    this.sensorObject = this.systems.activeSystem.sensors.find((s) => s.queue_no === this.thermostatObject.assignedSensors[activeSensor]);
    const refType = localStorage.getItem('ref');
    const backUrl = refType === null || refType === 'home' ? this.g.getHomeUrl() : refType === 'sens' ? '/system-sensor-list' : this.g.getHomeUrl();
    this.headerBar.showHeader({
      backUrl,
      headerText: this.thermostatObject.name,
    });
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    this.headerBar.onBackClicked = () => {
      that.revertChanges(that.systems.activeSystem.id, that.thermostatObject.id, that.es.getComponentBeforeModification(TEditableComponent.Thermostat));
    };
    this.footerBar.onButton1Click = () => {
      that.revertChanges(that.systems.activeSystem.id, that.thermostatObject.id, that.es.getComponentBeforeModification(TEditableComponent.Thermostat));
      that.router.navigate([backUrl]);
    };
    this.footerBar.onButton2Click = () => {
      that.saveChanges();
      that.router.navigate([backUrl]);
    };
    this.background.setGray();
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    const that = this;
    setTimeout(() => {
      that.paintOuterRing();
    }, 100);
  }

  private revertChanges(systemId: number, thermostatId: number, before: TThermostatData) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const thermostat = system.thermostats.find((t) => t.id === thermostatId);
    if (thermostat === undefined) {
      return;
    }

    thermostat.name = before.name;
    thermostat.temperatures[thermostat.activeSensor - 1] = before.temperatures[before.activeSensor - 1];
    this.systems.saveActiveSystem(systemId);
  }

  private saveChanges() {
    const that = this;
    const activeSystemId = this.systems.activeSystem.id;
    const before = this.es.getComponentBeforeModification(TEditableComponent.Thermostat);
    this.log('Saugom termostata', this.thermostatObject);
    this.api
      .post(
        '/edit/thermostat',
        {
          systemId: activeSystemId,
          thermostatId: this.thermostatObject.id,
          name: this.thermostatObject.name,
          value: this.thermostatObject.temperatures[this.thermostatObject.activeSensor - 1],
        },
        true
      )
      .subscribe(
        (result) => {
          if (!result.success) {
            that.revertChanges(activeSystemId, before.id, before);
            that.toaster.postError(result.error);
          } else {
            that.systems.saveActiveSystem(activeSystemId);
            that.log('Sėkmingai išsaugota.');
          }
        },
        (error) => {
          that.revertChanges(activeSystemId, before.id, before);
          that.toaster.postError(that.trans('systems.errors.sensorSaveFailed'));
        }
      );
  }

  public getTargetTemperatureBase() {
    const rounded = Math.round(this.thermostatObject.temperatures[this.thermostatObject.activeSensor - 1] * 10) / 10;
    const stringTemp = rounded.toString();
    return stringTemp.includes('.') ? stringTemp.split('.')[0] : stringTemp;
  }
  public getTargetTemperatureDecimals() {
    const rounded = Math.round(this.thermostatObject.temperatures[this.thermostatObject.activeSensor - 1] * 10) / 10;
    const stringTemp = rounded.toString();
    return stringTemp.includes('.') ? stringTemp.split('.')[1] : '0';
  }

  private buttonUp() {
    this.stepsDone = 0;
    this.buttonIsDown = false;
    clearTimeout(this.stepper);
    this.stepper = null;
  }

  public plusDown() {
    this.buttonIsDown = true;
    this.temperatureStep = 0.1;
    this.changeTemperature(false);
    this.calculateStepsBeforeGoingFaster(true);
  }

  public plusUp() {
    if (!this.buttonIsDown) {
      return;
    }
    this.buttonUp();
  }

  public minusDown() {
    this.buttonIsDown = true;
    this.temperatureStep = -0.1;
    this.changeTemperature(false);
    this.calculateStepsBeforeGoingFaster(false);
  }

  public minusUp() {
    if (!this.buttonIsDown) {
      return;
    }
    this.buttonUp();
  }

  private changeTemperature(reduceSteps: boolean) {
    let currentTemp = this.thermostatObject.temperatures[this.thermostatObject.activeSensor - 1];
    currentTemp += this.temperatureStep;
    currentTemp = Math.round((currentTemp + Number.EPSILON) * 10) / 10;
    this.thermostatObject.temperatures[this.thermostatObject.activeSensor - 1] = currentTemp;
    if (reduceSteps && this.stepsBeforeFaster > 0) {
      this.stepsBeforeFaster--;
    }

    if (reduceSteps && this.stepsBeforeFaster === 0) {
      this.temperatureStep = this.temperatureStep < 0 ? (this.stepsDone > 20 ? -1 : -0.5) : this.stepsDone > 20 ? 1 : 0.5;
    }

    const that = this;
    this.paintOuterRing();
    if (currentTemp >= GlobalService.MAX_THERMOSTAT_TEMPERATURE || currentTemp <= GlobalService.MIN_THERMOSTAT_TEMPERATURE) {
      if (currentTemp > GlobalService.MAX_THERMOSTAT_TEMPERATURE) {
        this.thermostatObject.temperatures[this.thermostatObject.activeSensor - 1] = GlobalService.MAX_THERMOSTAT_TEMPERATURE;
      } else if (currentTemp < GlobalService.MIN_THERMOSTAT_TEMPERATURE) {
        this.thermostatObject.temperatures[this.thermostatObject.activeSensor - 1] = GlobalService.MIN_THERMOSTAT_TEMPERATURE;
      }
      this.buttonUp();
      return;
    }
    this.stepsDone++;
    this.stepper = setTimeout(
      () => {
        that.changeTemperature(true);
      },
      reduceSteps ? 200 : 1500
    );
  }

  private calculateStepsBeforeGoingFaster(goingUp: boolean) {
    let currentTemp = this.thermostatObject.temperatures[this.thermostatObject.activeSensor - 1];
    currentTemp = Math.round((currentTemp + Number.EPSILON) * 10) / 10;
    const rounded = Math.floor(currentTemp);
    let stepsLeft = 0;
    if (goingUp) {
      stepsLeft = Math.round((1 - (currentTemp - rounded)) / 0.1);
      if (stepsLeft > 8) {
        stepsLeft -= 5;
      }
    } else {
      stepsLeft = Math.round(Math.abs(currentTemp - rounded) / 0.1);
      if (stepsLeft > 8) {
        stepsLeft -= 5;
      }
    }
    this.stepsBeforeFaster = stepsLeft;
  }

  private paintOuterRing() {
    const dashes = document.querySelectorAll('.scale1_dash');
    const dashCount = dashes.length;
    const totalDegrees = (GlobalService.MAX_THERMOSTAT_TEMPERATURE - GlobalService.MIN_THERMOSTAT_TEMPERATURE) * 10;
    const currentTemp = this.thermostatObject.temperatures[this.thermostatObject.activeSensor - 1];
    const roundedTemp = GlobalService.MIN_THERMOSTAT_TEMPERATURE * -10 + Math.round(currentTemp * 10);
    const percent = roundedTemp / totalDegrees;
    const paintHowMany = Math.round(dashCount * percent);
    for (let i = 0; i < dashes.length; i++) {
      if (i > paintHowMany) {
        (dashes[i] as HTMLElement).classList.remove('scale1_dash_active');
      } else {
        (dashes[i] as HTMLElement).classList.add('scale1_dash_active');
      }
    }
  }

  public getMinValue(): number {
    return GlobalService.MIN_THERMOSTAT_TEMPERATURE;
  }
  public getMaxValue(): number {
    return GlobalService.MAX_THERMOSTAT_TEMPERATURE;
  }

  public setTemperature(value: number) {
    this.thermostatObject.temperatures[this.thermostatObject.activeSensor - 1] = value;
    this.paintOuterRing();
  }

  public getHeightForActivePart() {
    const ctrl: HTMLElement = document.querySelector('#ther_control');
    return ctrl.offsetHeight - 58 + 'px';
  }
}
