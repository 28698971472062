import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { TZoneData } from 'src/app/models/zone-data';

@Component({
  selector: 'app-show-zones',
  templateUrl: './show-zones.component.html',
  styleUrls: ['./show-zones.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShowZonesComponent extends LanguageAware implements OnInit {
  public hiddenZones: TZoneData[] = [];

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
    this.tag = 'ShowZones';
    this.headerBar.showHeader({
      headerText: this.trans('systems.buttons.addZone'),
      backUrl: '/system-zone-list',
    });
    this.background.setGray();

    this.hiddenZones = this.systems.activeSystem.zones.filter((z) => z.enabled && !z.visible && z.native);
  }

  ngOnInit(): void {}

  public zoneClick(zone: TZoneData) {
    zone.visible = !zone.visible;
    const zoneNumber = zone.queue_no;
    const zoneOldVisibility = !zone.visible;
    const systemId = this.systems.activeSystem.id;
    const that = this;
    if (zone.visible) {
      this.api.post('/show/zone', { system_id: systemId, queue_no: zoneNumber }, true).subscribe(
        (result) => {
          if (!result.success) {
            that.toaster.postError(result.error);
            that.revertVisibility(systemId, zoneNumber, zoneOldVisibility);
          }
        },
        (error) => {
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
          that.revertVisibility(systemId, zoneNumber, zoneOldVisibility);
        }
      );
    } else {
      this.api.post('/delete/zone', { system_id: systemId, zone_no: zoneNumber }, true).subscribe(
        (result) => {
          if (!result.success) {
            that.toaster.postError(result.error);
            that.revertVisibility(systemId, zoneNumber, zoneOldVisibility);
          }
        },
        (error) => {
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
          that.revertVisibility(systemId, zoneNumber, zoneOldVisibility);
        }
      );
    }
  }

  private revertVisibility(systemId: number, zoneNumber: number, oldValue: boolean) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const zone = system.zones.find((z) => z.queue_no === zoneNumber);
    if (zone === undefined) {
      return;
    }
    zone.visible = oldValue;
  }

  public getAssignedAreas(zoneNumber: number): string {
    let result = '';
    const zone = this.systems.activeSystem.zones.find((z) => z.queue_no === zoneNumber);
    const areas = zone.areas.sort();
    for (const iArea of areas) {
      const area = this.systems.activeSystem.areas.find((a) => a.queue_no === iArea);
      if (area === undefined) {
        continue;
      }
      if (result !== '') {
        result += ', ';
      }
      result += area.name;
    }
    return result;
  }
}
