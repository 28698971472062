<div class='popup_wrapper' *ngIf='popupVisible' (click)='cancelPopup()'>
    <div class="pp_holder" (click)="$event.stopPropagation()">
        <div class="pp_header">{{popupOptions.headerText}}</div>
        <div class="pp_content">
            <div *ngIf='currentPopup === PopupType.Password' class="pp_password {{field1IsFocused ? 'pp_field_active' : ''}}">
                <input *ngIf="!passwordInClearText" type="password" placeholder=" " [(ngModel)]="field1Value" (focus)="field1IsFocused = true" (blur)="field1IsFocused = false" (keydown.enter)='submitPopup()' maxlength="{{popupOptions.field1.maxLen !== undefined ? popupOptions.field1.maxLen : 99}}" [autofocus]='true'/>
                <input *ngIf="passwordInClearText" type="text" placeholder=" " [(ngModel)]="field1Value" autocomplete="off" (focus)="field1IsFocused = true" (blur)="field1IsFocused = false" (keydown.enter)='submitPopup()' maxlength="{{popupOptions.field1.maxLen !== undefined ? popupOptions.field1.maxLen : 99}}" [autofocus]='true'/>
                <div class='pp_pass_icon' *ngIf='!passwordInClearText' (click)='passwordInClearText = !passwordInClearText'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40.5 27">
                        <path d="M40.255,16.973A22.552,22.552,0,0,0,20.25,4.5,22.555,22.555,0,0,0,.245,16.974a2.275,2.275,0,0,0,0,2.052A22.552,22.552,0,0,0,20.25,31.5,22.555,22.555,0,0,0,40.255,19.026,2.275,2.275,0,0,0,40.255,16.973ZM20.25,28.125A10.125,10.125,0,1,1,30.375,18,10.125,10.125,0,0,1,20.25,28.125Zm0-16.875a6.7,6.7,0,0,0-1.78.266,3.364,3.364,0,0,1-4.7,4.7,6.735,6.735,0,1,0,6.484-4.97Z" transform="translate(0 -4.5)" fill="#666666"/>
                    </svg>
                </div>
                <div class='pp_pass_icon' *ngIf='passwordInClearText' (click)='passwordInClearText = !passwordInClearText'>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 36">
                        <path d="M22.5,28.125a10.087,10.087,0,0,1-10.048-9.359l-7.376-5.7a23.435,23.435,0,0,0-2.582,3.909,2.275,2.275,0,0,0,0,2.052A22.552,22.552,0,0,0,22.5,31.5a21.84,21.84,0,0,0,5.477-.735l-3.649-2.823a10.134,10.134,0,0,1-1.828.184ZM44.565,32.21,36.792,26.2a23.291,23.291,0,0,0,5.713-7.177,2.275,2.275,0,0,0,0-2.052A22.552,22.552,0,0,0,22.5,4.5,21.667,21.667,0,0,0,12.142,7.151L3.2.237a1.125,1.125,0,0,0-1.579.2L.237,2.211a1.125,1.125,0,0,0,.2,1.579L41.8,35.763a1.125,1.125,0,0,0,1.579-.2l1.381-1.777a1.125,1.125,0,0,0-.2-1.579ZM31.648,22.226,28.884,20.09a6.663,6.663,0,0,0-8.164-8.573,3.35,3.35,0,0,1,.655,1.984,3.279,3.279,0,0,1-.108.7l-5.176-4A10.006,10.006,0,0,1,22.5,7.875,10.119,10.119,0,0,1,32.625,18a9.885,9.885,0,0,1-.977,4.226Z" transform="translate(0 0)" fill="#666666"/>
                    </svg>
                </div>
            </div>

            <div *ngIf='currentPopup === PopupType.String' class="pp_string_edit {{field1IsFocused ? 'pp_field_active' : ''}}">
                <input class="{{popupOptions.field1.isHex !== undefined && popupOptions.field1.isHex ? 'pp_uppercase' : ''}}" type="text" placeholder=" " [(ngModel)]="field1Value" autocomplete="off" (focus)="field1IsFocused = true" (blur)="field1IsFocused = false" (keydown.enter)='submitPopup()' maxlength="{{popupOptions.field1.maxLen !== undefined ? popupOptions.field1.maxLen : 99}}" [autofocus]='true' (keypress)="analyzeKeyPressField($event, popupOptions.field1, 1)"/>
            </div>

            <div *ngIf='currentPopup === PopupType.Number' class="pp_number_edit">
                <div class="pp_number_tgl_text" *ngIf='popupOptions.toggle !== undefined'>{{popupOptions.toggle.caption}}</div>
                <app-toggle-button class="pp_number_tgl" *ngIf='popupOptions.toggle !== undefined' [initialState]="toggleValue" (toggled)="toggleValue = $event.checked" [buttonId]="'pp_number_toggle'"></app-toggle-button>
                <div class="pp_number_edit1 {{field1IsFocused ? 'pp_field_active' : ''}}">
                    <input class="{{popupOptions.field1.isHex !== undefined && popupOptions.field1.isHex ? 'pp_uppercase' : ''}}" type="text" placeholder=" " [(ngModel)]="field1Value" autocomplete="off" (focus)="field1IsFocused = true; field2IsFocused = false" (blur)="field1IsFocused = false" (keydown.enter)='submitPopup()' maxlength="{{popupOptions.field1.maxLen !== undefined ? popupOptions.field1.maxLen : 99}}" [autofocus]='true' (keypress)="analyzeKeyPressField($event, popupOptions.field1, 1)"/>
                </div>
                <div class="pp_field_units pp_units1" *ngIf='popupOptions.field1.units !== undefined'>{{popupOptions.field1.units}}</div>
                <div *ngIf='popupOptions.field2 !== undefined' class="pp_number_edit2 {{field2IsFocused ? 'pp_field_active' : ''}}">
                    <input class="{{popupOptions.field2.isHex !== undefined && popupOptions.field2.isHex ? 'pp_uppercase' : ''}}" type="text" placeholder=" " [(ngModel)]="field2Value" autocomplete="off" (focus)="field2IsFocused = true; field1IsFocused = false" (blur)="field2IsFocused = false" (keydown.enter)='submitPopup()' maxlength="{{popupOptions.field2.maxLen !== undefined ? popupOptions.field2.maxLen : 99}}" (keypress)="analyzeKeyPressField($event, popupOptions.field2, 2)"/>
                </div>
                <div class="pp_field_units pp_units2" *ngIf='popupOptions.field2 !== undefined && popupOptions.field2.units !== undefined'>{{popupOptions.field2.units}}</div>
            </div>

            <div *ngIf='currentPopup === PopupType.NumberWithUnitList' class="pp_number_edit">
                <div class="pp_number_tgl_text" *ngIf='popupOptions.toggle !== undefined'>{{popupOptions.toggle.caption}}</div>
                <app-toggle-button class="pp_number_tgl" *ngIf='popupOptions.toggle !== undefined' [initialState]="toggleValue" (toggled)="toggleValue = $event.checked" [buttonId]="'pp_number_toggle'"></app-toggle-button>
                <div class="pp_number_unit_edit {{field1IsFocused ? 'pp_field_active' : ''}}">
                    <input class="{{popupOptions.field1.isHex !== undefined && popupOptions.field1.isHex ? 'pp_uppercase' : ''}}" type="text" placeholder=" " [(ngModel)]="field1Value" autocomplete="off" (focus)="field1IsFocused = true; field2IsFocused = false" (blur)="field1IsFocused = false" (keydown.enter)='submitPopup()' maxlength="{{popupOptions.field1.maxLen !== undefined ? popupOptions.field1.maxLen : 99}}" [autofocus]='true' (keypress)="analyzeKeyPressField($event, popupOptions.field1, 1)"/>
                </div>
                <div class="pp_field_units pp_units_select" *ngIf='popupOptions.field2.unitList !== undefined'>
                    <select [(ngModel)]="field2Value">
                        <option *ngFor="let iUnit of popupOptions.field2.unitList; let i = index" value="{{i}}" [selected]="popupOptions.field2.oldValue === i.toString()">&nbsp;{{iUnit}}</option>
                    </select>
                </div>
            </div>

            <div *ngIf='currentPopup === PopupType.TimeZone' class="pp_time_zone">
                <div class="pptz_hrselect" (click)="selectTimeZoneHour()">GMT {{timeZoneHours[field1Value]}}<span class="pptz_arrow">
                    <svg width="8.13" height="4.612" viewBox="0 0 8.13 4.612">
                        <path d="M.856,5.25H7.891a.546.546,0,0,1,.386.932L4.761,9.7a.548.548,0,0,1-.774,0L.47,6.182A.546.546,0,0,1,.856,5.25Z" transform="translate(-0.309 -5.25)" fill="#666"/>
                    </svg></span>
                    <span class="pptz_hr pp_field_units">{{l.get('configurators.labels.hoursShort')}}</span>
                </div>                
                <div class="pptz_minutes {{field1IsFocused ? 'pp_field_active' : ''}}">
                    <input class="{{popupOptions.field2.isHex !== undefined && popupOptions.field2.isHex ? 'pp_uppercase' : ''}}" type="text" placeholder=" " [(ngModel)]="field2Value" autocomplete="off" (focus)="field2IsFocused = true; field1IsFocused = false" (blur)="field2IsFocused = false" (keydown.enter)='submitPopup()' maxlength="{{popupOptions.field2.maxLen !== undefined ? popupOptions.field2.maxLen : 99}}" [autofocus]='true' (keypress)="analyzeKeyPressField($event, popupOptions.field2, 2)"/>
                </div>
                <div class="pp_field_units pp_units1">{{l.get('configurators.labels.minutesShort')}}</div>
            </div>

            <div *ngIf='currentPopup === PopupType.IPAddress' class="pp_string_edit {{field1IsFocused ? 'pp_field_active' : ''}}">
                <input class="{{popupOptions.field1.isHex !== undefined && popupOptions.field1.isHex ? 'pp_uppercase' : ''}}" type="text" placeholder=" " [(ngModel)]="field1Value" autocomplete="off" (focus)="field1IsFocused = true" (blur)="field1IsFocused = false" (keydown.enter)='submitPopup()' maxlength="{{popupOptions.field1.maxLen !== undefined ? popupOptions.field1.maxLen : 99}}" [autofocus]='true' (keypress)="analyzeKeyPressField($event, popupOptions.field1, 1)"/>
            </div>
        </div>
        <div class="pp_close_btn pp_btn" (click)='cancelPopup()'><div>{{l.get('general.close')}}</div></div>
        <div class="pp_ok_btn pp_btn" (click)='submitPopup()'><div>{{l.get('general.ok')}}</div></div>
    </div>
</div>
<div class='popup_wrapper' *ngIf='slideOutVisible' (click)='cancelSlideout()'>
    <div class="pp_slholder" (click)="$event.stopPropagation()" [@showSlideout]='slideoutState'>
        <div class="pp_slheader_1" *ngIf="slideOutOptions.header1Text !== undefined" (click)='cancelSlideout()'>{{slideOutOptions.header1Text}}</div>
        <div class="pp_slheader_2" *ngIf="slideOutOptions.header2Text !== undefined" (click)='cancelSlideout()'>{{slideOutOptions.header2Text}}</div>
        <div class="pp_slheader_3" (click)='cancelSlideout()'>{{slideOutOptions.headerText}}</div>
        <div class="pp_slcontent" *ngIf="currentSlideout === PopupType.Slideout">
            <div class="pp_slitem" *ngFor="let iSlideoutItem of slideOutOptions.items; let iIndex = index" (click)="submitSlideout(iIndex)">{{iSlideoutItem}}</div>
        </div>
        <div class="pp_slcontent" *ngIf="currentSlideout === PopupType.SlideoutWithValue">
            <div class="pp_slitem" *ngFor="let iSlideoutItem of slideOutOptions.items; let iIndex = index" (click)="submitSlideoutValue(iSlideoutItem)">{{iSlideoutItem.text}}</div>
        </div>
    </div>
</div>