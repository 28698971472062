import { Component, OnInit, Input, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import { TPgmData } from 'src/app/models/pgm-data';
import { LoggerService } from 'src/app/api/logger.service';
import { PgmService } from 'src/app/services/pgm.service';
import { IconsService } from 'src/app/services/icons.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { LanguageService } from 'src/app/services/language.service';
import { PopupService } from 'src/app/services/popup.service';
import { Router } from '@angular/router';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { ElementResizePopupComponent } from 'src/app/popups/element-resize-popup/element-resize-popup.component';

@Component({
  selector: 'app-pgm-button',
  templateUrl: './pgm-button.component.html',
  styleUrls: ['./pgm-button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PgmButtonComponent implements OnInit, OnDestroy {
  @Input() nameTextColor = '';
  @Input() flatButton = false;
  @ViewChild('resizePgm') resizePgm: ElementResizePopupComponent;
  public currentProgress = -1;
  public secondsLeft = 0;
  @Input() pgm: TPgmData = null;
  private countDownTimer = null;
  private tag = 'PGM';
  private pgmListener = null;
  public enableComponents = true;
  public buttonSize = '11';

  constructor(
    private l: LoggerService,
    public pgms: PgmService,
    public ic: IconsService,
    ws: WebSocketService,
    public lang: LanguageService,
    public pp: PopupService,
    private router: Router,
    private es: EditSystemService
  ) {
    const that = this;
    this.pgmListener = ws.onPgmStatusChange.subscribe(() => {
      that.runTimer();
    });
  }

  ngOnInit(): void {
    this.tag = 'PGM-' + this.pgm.queue_no;
    this.runTimer();
    const setting = localStorage.getItem('home_pgm_button_size_' + this.pgm.id);
    if (setting !== null) {
      this.buttonSize = setting;
    }
    this.enableComponents = !this.pgms.isPgmInQueue(this.pgm.id);
    const that = this;
    if (!this.enableComponents) {
      this.pgms.setNewCallbackFunction(this.pgm.id, (pgm) => {
        that.l.log('Gautas ACK', that.tag, { result: pgm });
        that.enableComponents = true;
        that.pgms.updatePgmData(pgm);
      });
    }
  }

  ngOnDestroy() {
    if (this.countDownTimer !== null) {
      clearInterval(this.countDownTimer);
      this.countDownTimer = null;
    }
    if (this.pgmListener !== null) {
      this.pgmListener.unsubscribe();
      this.pgmListener = null;
    }
  }

  public showTimer(): boolean {
    if (this.pgm.type !== 'pulse') {
      return false;
    }
    if (this.pgm.pulse_activated_at === 0) {
      return false;
    }
    // this.l.log('Show timer?', 'PgmButton-' + this.pgm.queue_no, {
    //   current: new Date().getTime(),
    //   pulse: this.pgm.pulse_activated_at,
    //   diff: new Date().getTime() - this.pgm.pulse_activated_at,
    //   pulseTime: this.pgm.pulseTime
    // });
    const result = new Date().getTime() - this.pgm.pulse_activated_at < this.pgm.pulseTime * 1000 || this.currentProgress > 0;
    // this.l.log('Rezultatas ', 'PgmButton-' + this.pgm.queue_no, result);
    return result;
  }

  public onPgmClick() {
    if (!this.enableComponents) {
      return;
    }
    this.l.log('Spaudžiamas PGM', this.tag);
    this.enableComponents = false;
    const that = this;
    this.pgms.togglePgm(this.pgm.id, this.pgm.queue_no, (pgm) => {
      that.l.log('Gautas ACK', that.tag, { result: pgm });
      that.enableComponents = true;
      that.pgms.updatePgmData(pgm);
    });
  }

  private runTimer() {
    if (this.countDownTimer !== null) {
      return;
    }
    if (this.showTimer()) {
      this.secondsLeft = this.pgm.pulseTime - Math.floor((new Date().getTime() - this.pgm.pulse_activated_at) / 1000);
      this.l.log('Liko s:', 'PgmButton-' + this.pgm.queue_no, this.secondsLeft);
      const that = this;
      this.tickTimerProgress();
      this.countDownTimer = setInterval(() => {
        that.tickTimerProgress();
        // this.l.log('', that.tag, that.currentProgress);
      }, 1000);
    }
  }

  private tickTimerProgress() {
    if (this.secondsLeft <= 0) {
      clearInterval(this.countDownTimer);
      this.countDownTimer = null;
    }
    this.secondsLeft--;
    this.currentProgress = Math.floor((this.secondsLeft / this.pgm.pulseTime) * 100);
  }

  public editOutput() {
    this.es.beginComponentEdit(TEditableComponent.Output, this.pgm.id, this.pgm);
    this.router.navigate(['/system-pgm']);
  }

  public showResizePopup() {
    this.resizePgm.show();
  }

  public setNewSize(newSize: any) {
    this.buttonSize = newSize.width.toString() + newSize.height.toString();
    localStorage.setItem('home_pgm_button_size_' + this.pgm.id, this.buttonSize);
  }
}
