import http from '../http';
import { BasicSuccessResponse, ErrorResponse } from './common';

type CameraBasicData = {
  name: string;
  fullUrl: string;
  host: string;
  port: string;
  user: string;
  pass: string;
  path: string;
  assignedPgms: number[];
};

type AddCameraRequest = {
  system_id: number;
  camera: Partial<CameraBasicData>;
};
type AddCameraResponse = ErrorResponse | (BasicSuccessResponse & { camera_id: number });

export const addCamera = (req: AddCameraRequest) => http.post<AddCameraResponse, AddCameraRequest>('/v3/api/add/camera', req);

type EditCameraRequest = {
  system_id: number;
  camera: Partial<CameraBasicData> & { id: number };
};
type EditCameraResponse = ErrorResponse | BasicSuccessResponse;

export const editCamera = (req: EditCameraRequest) => http.post<EditCameraResponse, EditCameraRequest>('/v3/api/edit/camera', req);

type DeleteCameraRequest = {
  system_id: number;
  camera_id: number;
};
type DeleteCameraResponse = ErrorResponse | BasicSuccessResponse;

export const deleteCamera = (req: DeleteCameraRequest) => http.delete<DeleteCameraResponse, DeleteCameraRequest>('/v3/api/camera', req);
