import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app-settings';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEventData } from 'src/app/models/event-data';
import { UserRole } from 'src/app/models/user-role';
import { CompanyEventsService } from 'src/app/services/company-events.service';

@Component({
  selector: 'app-cp-home',
  templateUrl: './cp-home.component.html',
  styleUrls: ['./cp-home.component.scss'],
})
export class CpHomeComponent extends LanguageAware implements OnInit, OnDestroy {
  @ViewChild('searchPopup') systemFind;
  @ViewChild('fragmentLoader') fragmentLoader;
  public lastSeenGroups = [];
  private userDataSubscriber = null;
  private eventSubscriber = null;
  public that = this;

  constructor(cdRef: ChangeDetectorRef, private router: Router, ce: CompanyEventsService) {
    super(cdRef);
    this.headerBar.showHeader({
      showHamburger: true,
      showDotMenu: this.us.currentUser.role === UserRole.Company || this.us.currentUser.role === UserRole.SuperAdmin,
      showLogo: true,
    });
    this.background.setGray();
    if (this.systems.activeSystem !== null) {
      this.systems.setCurrentSystem(null);
    }
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().setBackButtonType('close');
    }

    if (this.us.currentUser.role === UserRole.Company || this.us.currentUser.role === UserRole.SuperAdmin) {
      const dotItems = [
        {
          name: this.trans('systems.menu.reactionGroups'),
          action: this.g.getHomeUrl() + '/edit-groups',
        },
        {
          name: this.trans('systems.menu.reactions'),
          action: this.g.getHomeUrl() + '/edit-reactions',
        },
        {
          name: this.trans('systems.menu.eventDescriptions'),
          action: this.g.getHomeUrl() + '/edit-events',
        },
        {
          name: this.trans('systems.menu.grg'),
          action: this.g.getHomeUrl() + '/panic-button',
        },
      ];
      if (this.us.currentUser.role === UserRole.SuperAdmin) {
        if (AppSettings.regionsEnabled) {
          dotItems.push({
            name: this.trans('settings.labels.regions'),
            action: this.g.getHomeUrl() + '/regions',
          });
        }
        dotItems.push({
          name: this.trans('systems.menu.ipcom'),
          action: this.g.getHomeUrl() + '/ipcoms',
        });
        if (AppSettings.deviceTransferEnabled) {
          dotItems.push({
            name: this.trans('systems.titles.transferDevice'),
            action: this.g.getHomeUrl() + '/transfer-device',
          });
        }
        dotItems.push({
          name: this.trans('settings.global.title'),
          action: this.g.getHomeUrl() + '/settings',
        });
      }
      this.headerBar.setDotMenuItems(dotItems);
      const that = this;
      this.userDataSubscriber = this.us.onUserDataChanged.subscribe(() => {
        that.updateData();
      });
      this.updateData();
      this.eventSubscriber = ce.onEventForward.subscribe((data) => {
        that.handleEvent(data.receivedEvent, data.reactionGroup);
      });
      this.helper.setCountForBottomButton(1, 1, 1, false, true, false, 'cph');
    } else if (this.us.currentUser.role === UserRole.Installer) {
      this.helper.setCountForBottomButton(1, 1, 1, false, true, false, 'cph');
    }
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.userDataSubscriber !== null) {
      this.userDataSubscriber.unsubscribe();
      this.userDataSubscriber = null;
    }
    if (this.eventSubscriber !== null) {
      this.eventSubscriber.unsubscribe();
      this.eventSubscriber = null;
    }
  }

  public gotoAllEvents() {
    this.router.navigate([this.g.getHomeUrl() + '/all-events']);
  }

  public openFind() {
    this.systemFind.show();
  }

  private updateData() {
    this.lastSeenGroups = [];
    for (const iGroup of this.us.currentUser.reactionGroups) {
      const reactions = this.us.currentUser.reactions.filter((r) => r.group_id === iGroup.id);
      let eventCount = 0;
      for (const iReaction of reactions) {
        const found = this.us.currentUser.lastSeenReactions.find((lsr) => lsr.reaction_id === iReaction.id);
        eventCount += found === undefined ? 0 : found.unseen_events;
      }
      this.lastSeenGroups.push({
        name: iGroup.default_name ? this.trans('reactionNames.reaction_groups.' + iGroup.name) : iGroup.name,
        events: eventCount,
        icon: iGroup.icon,
        id: iGroup.id,
      });
    }
  }

  private handleEvent(event: TEventData, reactionGroup: any) {
    const foundLastSeenGroup = this.lastSeenGroups.find((lg) => lg.id === reactionGroup.id);
    if (foundLastSeenGroup === undefined) {
      this.lastSeenGroups.push({
        name: reactionGroup.default_name ? this.trans('reactionNames.reaction_groups.' + reactionGroup.name) : reactionGroup.name,
        events: 1,
        icon: reactionGroup.icon,
        id: reactionGroup.id,
      });
    } else {
      foundLastSeenGroup.events++;
    }
  }

  public addSystem() {
    this.router.navigate(['/add-system']);
  }

  public openSystemHistory() {
    this.router.navigate([this.g.getHomeUrl() + '/history-systems']);
  }

  public fetchMoreSystems(context: any, callback?: any) {
    context.miniStatus.show(context.trans('general.pleaseWait'));
    context.systems.loadSystems(true, () => {
      context.miniStatus.hide();
      context.fragmentLoader.isEnabled = context.systems.length % context.us.eventPerPage === 0;
      if (callback !== undefined) {
        callback();
      }
    });
  }
}
