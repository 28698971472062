import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GlobalService } from 'src/app/services/global.service';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-advanced-settings',
  templateUrl: './advanced-settings.component.html',
  styleUrls: ['./advanced-settings.component.scss'],
})
export class AdvancedSettingsComponent extends LanguageAware implements OnInit {
  public doNotShow = false;
  public shouldShowContent = true;

  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    this.headerBar.showHeader({
      backUrl: '/system-configuration',
      headerText: this.trans('systems.menu.advancedSettings'),
    });
    this.background.setGray();
    const savedDoNowShow = localStorage.getItem('do_not_show_adv_' + this.us.currentUser.id + '_' + this.systems.activeSystem.id);
    this.shouldShowContent = savedDoNowShow === null;
    this.doNotShow = savedDoNowShow !== null;
    if (!this.shouldShowContent) {
      this.openConfigurator();
    }
  }

  ngOnInit(): void {}

  public openConfigurator() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    if (this.doNotShow) {
      localStorage.setItem('do_not_show_adv_' + this.us.currentUser.id + '_' + this.systems.activeSystem.id, '1');
    }
    const that = this;
    this.api
      .post(
        '/config/info',
        {
          systemUid: this.systems.activeSystem.imei,
          mpass: this.systems.activeSystem.mpass,
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            // !!! pridedant naujus modulius, juos reikia prideti ir i systems.service prie supports.advancedSettings
            if (result.data.hwId === '3B') {
              // SP3
              that.g.configurableDevice = result.data;
              that.g.configurableDevice.mpass = that.systems.activeSystem.mpass;
              that.performLanguageUpdate('sp3');
            } else if (result.data.hwId === '24' || result.data.hwId === '37') {
              that.g.configurableDevice = result.data;
              that.g.configurableDevice.mpass = that.systems.activeSystem.mpass;
              that.performLanguageUpdate('gv17');
            } else if (['3A', '3C', '42', '43', '44', '45', '46', '47', '48'].includes(result.data.hwId)) {
              that.g.configurableDevice = result.data;
              that.g.configurableDevice.mpass = that.systems.activeSystem.mpass;
              that.performLanguageUpdate('g16');
            } else {
              that.toaster.postError(that.trans('systems.titles.notSupported'));
            }
          } else {
            that.toaster.postError(result.error);
          }
          that.miniStatus.hide();
        },
        (error) => {
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
          that.miniStatus.hide();
        }
      );
  }

  private performLanguageUpdate(device: string) {
    const currentVersion = localStorage.getItem('lang_version');
    const currentVersionDev = localStorage.getItem('lang_version_' + device);
    if (currentVersion === currentVersionDev) {
      this.log(device + ' Config vertimu naujinti nereikia.');
      this.router.navigate(['/configure/' + device]);
      return;
    }
    this.log('Naujinam ' + device + ' vertimus...');
    const that = this;
    const currentLanguage = localStorage.getItem('lang');
    this.api
      .post(
        '/translations',
        {
          language: currentLanguage === null ? '' : currentLanguage,
          config: device,
          version: currentVersionDev,
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            that.ls.applyTranslations(result.translations, result.version, result.code);
            localStorage.setItem('lang_version_' + device, currentVersion);
            this.router.navigate(['/configure/' + device]);
          } else {
            that.log('Nepavyko atnaujinti vertimu. ' + result.error);
            this.router.navigate(['/configure/' + device]);
          }
        },
        (error) => {
          that.log('Nepavyko atnaujinti vertimu. Vidine serverio klaida.');
          this.router.navigate(['/configure/' + device]);
        }
      );
  }
}
