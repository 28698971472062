import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TSensorData } from 'src/app/models/sensor-data';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { IconsService } from 'src/app/services/icons.service';

@Component({
  selector: 'app-system-sensor',
  templateUrl: './system-sensor.component.html',
  styleUrls: ['./system-sensor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SystemSensorComponent extends LanguageAware implements OnInit {
  public maxSensorLen = 100;
  public allTypes = [];
  public localSensor: TSensorData = {
    active: false,
    alarmHigh: false,
    alarmLow: false,
    enabled: true,
    name: '',
    queue_no: 0,
    temperature: '',
    type: 0,
    highValue: 0,
    lowValue: 0,
    notificationAboutHigh: false,
    notificationAboutLow: false,
  };

  constructor(cdRef: ChangeDetectorRef, private router: Router, public is: IconsService, private es: EditSystemService) {
    super(cdRef);
    const that = this;
    if (this.es.getCurrentSensor() === 0) {
      this.router.navigate(['/system-sensor-list']);
      return;
    }
    const sensor = this.systems.activeSystem.sensors.find((s) => s.queue_no === this.es.getCurrentSensor());
    if (sensor === undefined) {
      this.router.navigate(['/system-sensor-list']);
      return;
    }
    this.background.setGray();
    this.headerBar.showHeader({
      headerText: this.trans('systems.labels.sensor'),
      backUrl: '/system-sensor-list',
    });
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('users.buttons.confirm'), true, false);
    this.footerBar.onButton1Click = () => {
      that.onCancel();
    };
    this.footerBar.onButton2Click = () => {
      that.onConfirm();
    };
    this.localSensor = sensor;
    this.tag = 'Sensor' + this.localSensor.queue_no;
    this.allTypes = is.getAllSensorIcons();
    if (this.systems.activeSystem.hwType === 'SP3') {
      this.maxSensorLen = 15;
    }
    if (this.localSensor.type === 0 && this.allTypes.length > 0) {
      this.localSensor.type = this.allTypes[0].id;
    }
  }

  ngOnInit(): void {}

  private onCancel() {
    this.es.setCurrentSensor(0);
    this.router.navigate(['/system-sensor-list']);
  }

  public onConfirm() {
    const that = this;
    const activeSystemId = this.systems.activeSystem.id;
    const sensor = this.es.getSensorBeforeModification();
    this.log('Saugom sensorių', this.localSensor);
    this.api.post('/edit/sensor', { systemId: activeSystemId, sensor: this.localSensor }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.revertChanges(activeSystemId, sensor);
          that.toaster.postError(result.error);
        } else {
          that.systems.saveActiveSystem(activeSystemId);
          that.log('Sėkmingai išsaugota.');
        }
      },
      (error) => {
        that.revertChanges(activeSystemId, sensor);
        that.toaster.postError(that.trans('systems.errors.sensorSaveFailed'));
      }
    );
    this.router.navigate(['/system-sensor-list']);
  }

  private revertChanges(systemId: number, sensor: TSensorData) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }

    const systemSensor = system.sensors.find((s) => s.queue_no === sensor.queue_no);
    if (systemSensor === undefined) {
      return;
    }

    this.log('Atstatomi sensoriaus duomenys, kadangi nepavyko išsaugoti.');
    systemSensor.name = sensor.name;
    systemSensor.type = sensor.type;
    systemSensor.notificationAboutHigh = sensor.notificationAboutHigh;
    systemSensor.notificationAboutLow = sensor.notificationAboutLow;
    systemSensor.highValue = sensor.highValue;
    systemSensor.lowValue = sensor.lowValue;

    if (systemId === this.systems.activeSystem.id) {
      this.systems.saveActiveSystem(system.id);
    }
  }

  public onTypeSelection(typeId: number) {
    this.localSensor.type = typeId;
    this.log('Nustatomas naujas tipas:', typeId);
  }

  public notificationsEnabled(): boolean {
    return this.localSensor.notificationAboutHigh && this.localSensor.notificationAboutLow;
  }

  public setNotificationsEnabled(value: boolean) {
    this.localSensor.notificationAboutHigh = value;
    this.localSensor.notificationAboutLow = value;
  }
}
