import { Directive, OnInit, ElementRef, Input } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[autofocus]',
})
export class AutofocusDirective implements OnInit {
  private focus = true;
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    if (this.focus) {
      window.setTimeout(() => {
        this.elementRef.nativeElement.focus();
      });
    }
  }

  @Input() set autofocus(condition: boolean) {
    this.focus = condition !== false;
  }
}
