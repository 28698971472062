<div class="white_round" id='ia_list'>
    <app-button-single-line-arrow [withPadding]="false" (click)="selectFromList()">{{trans('systems.labels.chooseFromList')}}</app-button-single-line-arrow>
</div>
<div class="separator16"></div>
<div class="white_round padding16">
    <app-text-edit id='ia_email' [value]='targetEmail' (enterClicked)="doFilter($event)" (valueChanged)="doFilter($event)">{{trans('auth.form.email')}}</app-text-edit>
    <div id='ia_people'>
        <app-button-double-line-arrow *ngFor='let iPerson of filteredPeople' [firstLine]="iPerson.name" [secondLine]="iPerson.email" (click)='selectPerson(iPerson)' [showArrow]="false"></app-button-double-line-arrow>
    </div>
</div>
<app-popup></app-popup>