import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DisableMobileDataPopupComponent } from 'src/app/popups/disable-mobile-data-popup/disable-mobile-data-popup.component';
import { DeviceService } from 'src/app/services/device.service';
import { WifiService } from 'src/app/services/wifi.service';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-nearby-device-found',
  templateUrl: './nearby-device-found.component.html',
  styleUrls: ['./nearby-device-found.component.scss'],
})
export class NearbyDeviceFoundComponent extends LanguageAware implements OnInit, OnDestroy {
  @ViewChild('mobileData') mobileData: DisableMobileDataPopupComponent;
  private deviceNetworksListener = null;
  public mobileDataIsOn = false;
  private networkStateListener = null;
  public deviceCount = 0;
  public singleDeviceWifiAP = '';

  constructor(cdRef: ChangeDetectorRef, private router: Router, public wfs: WifiService, public ds: DeviceService) {
    super(cdRef);
    this.background.setGray();
    const that = this;
    this.wfs.onDeviceNetworkFound.subscribe(() => {
      that.handleFoundDevices();
    });
    this.deviceNetworksListener = this.wfs.onDeviceNetworkFound.subscribe(() => {
      that.handleFoundDevices();
    });
    this.handleFoundDevices();
    this.headerBar.showHeader({
      headerText: this.trans('systems.labels.device'),
      backUrl: '/add-system',
    });
    this.headerBar.onBackClicked = () => {
      that.wfs.stopWifiDiscovery();
    };
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.deviceNetworksListener !== null) {
      this.deviceNetworksListener.unsubscribe();
    }
    if (this.networkStateListener !== null) {
      clearInterval(this.networkStateListener);
    }
  }

  private handleFoundDevices() {
    const that = this;
    this.deviceCount = this.wfs.getDeviceNetworks().length;
    if (this.wfs.getDeviceNetworks().length === 1) {
      this.footerBar.showFooter(this.trans('general.ok'), '', true, false);
      this.singleDeviceWifiAP = this.wfs.getDeviceNetworks()[0].ssid;
      this.wfs.selectDevice(this.singleDeviceWifiAP);
      this.footerBar.onButton1Click = () => {
        that.deviceSelected(that.singleDeviceWifiAP);
      };
    } else {
      //this.singleDeviceWifiAP = 'kazkas';
      this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
      this.footerBar.onButton1Click = () => {
        //that.deviceSelected(that.singleDeviceWifiAP);
        that.wfs.stopWifiDiscovery();
        that.router.navigate(['/add-system']);
      };
    }
  }

  private waitForMobileDataOff() {
    this.networkStateListener = setInterval(() => {
      const netStatus = window.jsi.getNetworkStatus();
      const statusObj: { mobile_data: boolean } = /*{ mobile_data: true };*/ JSON.parse(netStatus);
      if (statusObj.mobile_data !== undefined) {
        this.mobileDataIsOn = statusObj.mobile_data;
        if (!this.mobileDataIsOn) {
          this.mobileData.close();
          this.proceed();
        }
      }
    }, 2000);
  }

  public deviceSelected(ssid: string) {
    const netStatus = window.jsi.getNetworkStatus();
    const statusObj: { mobile_data: boolean } = /*{ mobile_data: false };*/ JSON.parse(netStatus);
    if (statusObj.mobile_data !== undefined) {
      this.mobileDataIsOn = statusObj.mobile_data;
    }
    this.wfs.selectDevice(ssid);
    if (this.mobileDataIsOn) {
      this.wfs.mobileDataIsOn(this.wfs.wasMobileDataOn() || this.mobileDataIsOn);
      this.mobileData.show();
      const that = this;
      this.mobileData.onCancel = () => {
        that.mobileData.close();
        that.wfs.stopWifiDiscovery();
        that.router.navigate(['/add-system']);
      };
      this.waitForMobileDataOff();
    } else {
      this.proceed();
    }
  }

  private proceed() {
    this.router.navigate(['/wifi-details']);
  }
}
