import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeBackgroundSelectionComponent } from '../general/home-background-selection/home-background-selection.component';
import { HomeComponent } from '../general/home/home.component';
import { QuickOutputsComponent } from '../general/quick-outputs/quick-outputs.component';
import { SystemZoneComponent } from '../general/system-zone/system-zone.component';
import { AreaListComponent } from '../system/area-list/area-list.component';
import { AreaViewComponent } from '../system/area-view/area-view.component';
import { AreaComponent } from '../system/area/area.component';
import { DeleteComponent } from '../system/delete/delete.component';
import { EventsComponent } from '../system/events/events.component';
import { PgmIconSelectComponent } from '../system/pgm-icon-select/pgm-icon-select.component';
import { RereadComponent } from '../system/reread/reread.component';
import { ResetFireSensorsComponent } from '../system/reset-fire-sensors/reset-fire-sensors.component';
import { SensorsListComponent } from '../system/sensors-list/sensors-list.component';
import { SystemConfigurationComponent } from '../system/system-configuration/system-configuration.component';
import { SystemInformationComponent } from '../system/system-information/system-information.component';
import { SystemPgmListComponent } from '../system/system-pgm-list/system-pgm-list.component';
import { SystemPgmComponent } from '../system/system-pgm/system-pgm.component';
import { SystemSensorComponent } from '../system/system-sensor/system-sensor.component';
import { SystemThermostatComponent } from '../system/system-thermostat/system-thermostat.component';
import { TransferFromListComponent } from '../system/transfer-from-list/transfer-from-list.component';
import { TransferComponent } from '../system/transfer/transfer.component';
import { UserListComponent } from '../system/user-list/user-list.component';
import { ZoneListComponent } from '../system/zone-list/zone-list.component';
import { ZoneStatusListComponent } from '../system/zone-status-list/zone-status-list.component';

import { CompanyComponent } from './company.component';
import { MobileToCompanyGuard } from './guards/mobile-to-company.guard';
import { CompanyUserEditComponent } from './pages/company-user-edit/company-user-edit.component';
import { CompanyEventsComponent } from './pages/company-events/company-events.component';
import { CompanyHomeComponent } from './pages/company-home/company-home.component';
import { CompanyLayoutComponent } from './pages/company-layout/company-layout.component';
import { CompanyLoginComponent } from './pages/company-login/company-login.component';
import { CompanyMobileHostComponent } from './pages/company-mobile-host/company-mobile-host.component';
import { CompanySystemsComponent } from './pages/company-systems/company-systems.component';
import { CompanyUsersComponent } from './pages/company-users/company-users.component';
import { NotfoundComponent } from './pages/notfound/notfound.component';
import { UResolver } from '../guards/uresolve.resolver';

export const companyRootRoute = '/d/company';

const routes: Routes = [
  {
    path: '', component: CompanyComponent,
    children: [
      { path: 'login', component: CompanyLoginComponent },
      { path: '', component: CompanyLayoutComponent,
        children: [
          { path: '', component: CompanyHomeComponent },
          { path: 'systems', component: CompanySystemsComponent },
          { path: 'events', component: CompanyEventsComponent, resolve: { u: UResolver} },
          { path: 'users', component: CompanyUsersComponent, children: [
            { path: 'new', component: CompanyUserEditComponent, resolve: { u: UResolver} },
            { path: 'edit/:userId', component: CompanyUserEditComponent, resolve: { u: UResolver} },
          ] },
          { path: 'systems/m/:systemId', component: CompanyMobileHostComponent, canDeactivate: [MobileToCompanyGuard],
            children: [
              { path: '', component: HomeComponent },
              { path: 'events', component: EventsComponent },
              { path: 'thermostat', component: SystemThermostatComponent },
              { path: 'area', component: AreaViewComponent },
              { path: 'area/zones', component: ZoneStatusListComponent },
              { path: 'users', component: UserListComponent },
              { path: 'sensors', component: SensorsListComponent },
              { path: 'sensor', component: SystemSensorComponent },
              { path: 'outputs', component: QuickOutputsComponent },
              { path: 'settings', component: SystemConfigurationComponent },
              { path: 'settings/info', component: SystemInformationComponent },
              { path: 'settings/info/reread', component: RereadComponent },
              { path: 'settings/info/background', component: HomeBackgroundSelectionComponent },
              //
              { path: 'settings/outputs', component: SystemPgmListComponent },
              { path: 'settings/outputs/edit', component: SystemPgmComponent },
              { path: 'settings/outputs/edit/icon', component: PgmIconSelectComponent },
              { path: 'settings/transfer', component: TransferComponent },
              { path: 'settings/transfer/list', component: TransferFromListComponent },
              { path: 'settings/advanced', component: NotfoundComponent },
              { path: 'settings/delete', component: DeleteComponent },
              { path: 'settings/areas', component: AreaListComponent },
              { path: 'settings/areas/edit', component: AreaComponent },
              { path: 'settings/zones', component: ZoneListComponent },
              { path: 'settings/zones/edit', component: SystemZoneComponent },
              { path: 'settings/fire-reset', component: ResetFireSensorsComponent },

              // { path: 'settings/advanced/sp3', loadChildren: () => import('src/app/configurators/sp3/src/app/app.module').then(m => m.AppModule) },
              // { path: 'settings/afvanced/gv17', loadChildren: () => import('src/app/configurators/gv17/src/app/app.module').then(m => m.AppModule) },
              // { path: 'settings/advanced/g16', loadChildren: () => import('src/app/configurators/g16/src/app/app.module').then(m => m.AppModule) },


            ]
          },
        ],
      },
      { path: '**', component: NotfoundComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CompanyRoutingModule {}
