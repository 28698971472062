<div class="grp_container">
    <div class="grp_header" (click)="toggleGroupbox()">
        <div class="grp_header_text">{{headerText}}</div>
        <div class="grp_arrow">
            <svg viewBox="0 0 14.286 8.168" *ngIf='isCollapsed'>
                <path d="M13.333,16.952l5.4-5.406a1.017,1.017,0,0,1,1.442,0,1.03,1.03,0,0,1,0,1.446l-6.121,6.125a1.019,1.019,0,0,1-1.408.03L6.485,13a1.021,1.021,0,0,1,1.442-1.446Z" transform="translate(-6.188 -11.246)" />
            </svg>
            <svg viewBox="0 0 14.697 8.402" *ngIf='!isCollapsed'>
                <path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(20.891 -11.251) rotate(90)"/>
            </svg>                            
        </div>
    </div>
    <div #contentGroupBox class="grp_content">
        <ng-content></ng-content>
    </div>
</div>