import { Injectable } from '@angular/core';
import { TEventData } from '../models/event-data';
import { LanguageService } from './language.service';
import { DateService } from './date.service';
import { DataTableLoader } from '../company/components/data-table/data-table.component';
import { RequestService } from '../api/request.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor(private ls: LanguageService, private dt: DateService, private req: RequestService) {}
  public allEvents: TEventData[] = [];

  /**
   * Pateiktus įvykius sugrupuoja pagal dienas.
   *
   * @param eventsToGroup Įvykių sąrašas, kurį naudoti grupavimui
   * @param lastKnownDate Paskutinė data, kuri jau buvo pradėta grupuoti.
   * ( Naudojama tuo atveju, jeigu įvykių sąrašas papildomas nauju ).
   * 0 reiškia nėra reikšmės, todėl bus grupuojama nuo pirmo įvykio dienos.
   * @param eventCountToProcess Nurodo maksimalų kiekį įvykių, kurie bus naudojami grupavimui.
   */
  public groupEvents(eventsToGroup: TEventData[], lastKnownDate: number, eventCountToProcess: number): any[] {
    if (eventCountToProcess === 0) {
      eventCountToProcess = 99999999;
    }

    const groupedEvents = [];
    let currentDayTitle = '';
    if (lastKnownDate !== 0) {
      const lastDate = new Date(lastKnownDate * 1000);
      currentDayTitle = this.dt.getDayTitle(lastDate);
      groupedEvents.push({
        dayTitle: currentDayTitle,
        dayEvents: [],
      });
    }

    for (const iEvent of eventsToGroup) {
      const eventDayTitle = this.dt.getDayTitle(new Date(iEvent.time * 1000));
      if (currentDayTitle !== eventDayTitle) {
        currentDayTitle = eventDayTitle;
        groupedEvents.push({
          dayTitle: currentDayTitle,
          dayEvents: [],
        });
      }
      groupedEvents[groupedEvents.length - 1].dayEvents.push(iEvent);
      eventCountToProcess--;
      if (eventCountToProcess === 0) {
        break;
      }
    }

    return groupedEvents;
  }

  public convertFromRaw(eventData: any): TEventData {
    const event: TEventData = {
      id: eventData.id,
      reaction: eventData.reaction,
      time: eventData.time,
      title: eventData.title,
      areaText: eventData.subTitle,
      areaId: eventData.area,
      isAreaEvent: eventData.area_event,
      systemId: eventData.system_id,
    };

    return event;
  }
}
