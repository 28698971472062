import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanDeactivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerService } from 'src/app/api/logger.service';
import { FooterService } from 'src/app/services/footer.service';
import { companyRootRoute } from '../company-routing.module';

@Injectable({
  providedIn: 'root',
})
export class MobileToCompanyGuard implements CanDeactivate<unknown> {
  private readonly routeMapping = new Map<string, (systemId?: string) => UrlTree>([
    ['/', () => this.router.createUrlTree([companyRootRoute])],
    ['/home', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId])],
    ['/events', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'events'])],
    ['/system-thermostat', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'thermostat'])],
    ['/area-view', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'area'])],
    ['/zone-status-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'area', 'zones'])],
    ['/users', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'users'])],
    ['/system-sensor-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'sensors'])],
    ['/system-sensor', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'sensor'])],
    ['/system-configuration', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings'])],
    ['/system-information', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'info'])],
    ['/reread', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'info', 'reread'])],
    ['/quick-view-outputs', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'outputs'])],
    ['/system-pgm-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'outputs'])],
    ['/system-pgm', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'outputs', 'edit'])],
    ['/pgm-icon-selection', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'outputs', 'edit', 'icon'])],
    ['/system-transfer', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'transfer'])],
    ['/transfer-from-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'transfer', 'list'])],
    ['/advanced-settings', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'advanced'])],
    ['/system-delete', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'delete'])],
    ['/system-area-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'areas'])],
    ['/system-area', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'areas', 'edit'])],
    ['/system-zone-list', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'zones'])],
    ['/system-zone', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'zones', 'edit'])],
    ['/reset-fire-sensors', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'fire-reset'])],
    ['/home-background-selection', (systemId) => this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'info', 'background'])],
  ]);
  constructor(private router: Router, private l: LoggerService, private footerService: FooterService) {}

  canDeactivate(
    component: unknown,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (nextState) {
      const url = nextState.url;
      if (url.startsWith(companyRootRoute)) {
        return true;
      }
      this.l.log('Bandoma palikti company', 'MobileToCompanyGuard', { url });
      const systemId = currentRoute.paramMap.get('systemId');
      // const configurator = this.handleConfigurators(url, systemId);
      // if (configurator) {
      //   this.l.log('Nukreipiama į configurator', 'MobileToCompanyGuard', { url, newUrl: configurator.toString() });
      //   return configurator;
      // }
      if (this.routeMapping.has(url)) {
        const newUrl = this.routeMapping.get(url)(systemId);
        this.l.log('Nukreipiama atgal į company', 'MobileToCompanyGuard', { newUrl: newUrl.toString() });
        this.footerService.hideFooter(); // This is a hack.
        return newUrl;
      }
    }
    return true;
  }

  // private handleConfigurators(url: string, systemId: string): UrlTree | undefined {
  //   if (url.startsWith('/configure')) {
  //     const i = url.indexOf('/', 9);
  //     const remainder = url.substring(i + 1).split('/');
  //     return this.router.createUrlTree([companyRootRoute, 'systems', 'm', systemId, 'settings', 'advanced', ...remainder]);
  //   }
  // }
}
