import { Component, Injector, OnInit } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { EventFilterCriteria, EventService } from 'src/app/api/system/event.service';
import { SystemService } from 'src/app/api/system/system.service';
import { ItemConfiguration } from '../../components/company-columns/company-columns.component';
import { UResolvable } from '../../../guards/UResolvable';

@Component({
  templateUrl: './company-events.component.html',
  styleUrls: ['./company-events.component.scss'],
})
export class CompanyEventsComponent implements OnInit, UResolvable<typeof CompanyEventsComponent> {
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: 'Icon', value: 'fake:icon' },
    { name: 'Event', value: 'title' },
    { name: 'Area', value: 'areaText' },
    { name: 'System', value: 'systemId' },
    { name: 'Tag', value: 'fake:tag' },
    { name: 'Time/Date', value: 'time' },
  ];
  public columns = ['fake:icon', 'title', 'areaText', 'systemId', 'fake:tag', 'time'];
  public criteria: EventFilterCriteria = {
    systemId: -1,
    reactionId: -1,
  };
  constructor() {}

  ngOnInit(): void {}

  public static async resolve(injector: Injector, route: ActivatedRouteSnapshot): Promise<void> {
    const events = injector.get(EventService);
    const systems = injector.get(SystemService);
    if (events.events.size === 0) {
      await Promise.all([events.getEvents(), systems.loadPartialSystemData()]);
    }
  }
}
