import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiRequestService } from '../services/api-request.service';
import { GlobalService } from '../services/global.service';
import { Location } from '@angular/common';
import { UserService } from '../services/user.service';
import { UserRole } from '../models/user-role';

@Injectable({
  providedIn: 'root',
})
export class AuthGuardService implements CanActivate {
  /**
   * Į naršyklę įvesti galima tik šias nuorodas, kitu atveju einam į Home, kadangi prisijungę
   * tipai:
   * 0 - path prasideda nurodytu tekstu.
   * 1 - visas path lygus nurodytam tekstui.
   */
  private whitlistedUrls = [
    { type: 1, path: '/home' },
    { type: 1, path: '/systems' },
    { type: 1, path: '/add-system' },
    { type: 0, path: '/system/view/' },
    { type: 0, path: '/system/events/' },
    { type: 1, path: '/configure/sp3' },
  ];

  constructor(private router: Router, private rs: ApiRequestService, private g: GlobalService, private l: Location, private us: UserService) {}

  private belongsToWhitelist(path: string): boolean {
    let result = false;
    this.whitlistedUrls.forEach((element) => {
      if (element.type === 0) {
        if (path.startsWith(element.path)) {
          result = true;
        }
      } else if (element.type === 1) {
        if (path === element.path) {
          result = true;
        }
      }
    });
    return result;
  }

  canActivate() {
    if (!this.rs.hasToken()) {
      if (this.g.isLoaded()) {
        this.router.navigate(['/login']);
      } else {
        this.g.setRedirectionURL('/login');
        this.router.navigate(['/']);
      }
    } else {
      if (this.g.isLoaded()) {
        return true;
      } else {
        const url = this.l.path();
        if (this.belongsToWhitelist(url)) {
          if (this.rs.hasToken() && this.us.currentUser.id === 0) {
            this.us.loadUserData();
          }
          if (this.us.currentUser.role === UserRole.Company) {
            this.g.setRedirectionURL(this.g.getHomeUrl());
          } else {
            this.g.setRedirectionURL(url);
          }
        } else {
          this.g.setRedirectionURL(this.g.getHomeUrl());
        }
        this.router.navigate(['/']);
      }
    }
  }
}
