import { ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MessageboxComponent } from 'src/app/popups/messagebox/messagebox.component';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-assisting-installer',
  templateUrl: './assisting-installer.component.html',
  styleUrls: ['./assisting-installer.component.scss'],
})
export class AssistingInstallerComponent extends LanguageAware implements OnInit {
  @Input() installerId = 0;
  @Input() installerEmail = '';
  @Input() systemId = 0;
  @Input() showRevokeButton = true;
  @ViewChild('messageBox') messageBox: MessageboxComponent;

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef, true);
  }

  ngOnInit(): void {}

  public doRevoke() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    this.api
      .post(
        '/revoke-installer',
        {
          id: this.installerId,
          systemId: this.systemId,
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            that.toaster.postSuccess(that.trans('systems.notifications.installerAccessRevoked'));
            const system = that.systems.getSystem(that.systemId);
            if (system !== undefined) {
              system.assistedById = undefined;
              system.assistedByEmail = undefined;
            }
          } else {
            that.toaster.postError(result.error);
          }
          that.miniStatus.hide();
        },
        (error) => {
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
          that.miniStatus.hide();
        }
      );
  }

  public initRevoke() {
    this.messageBox.show();
  }
}
