<div class="grid ssne-grid">  
    <div class="group-background" id="ssne-background"></div>
    <app-text-edit id='ssne-queue' [value]="localSensor.queue_no" [isReadOnly]="true">{{trans('systems.labels.sensorNumber')}}</app-text-edit>
    <app-text-edit id='ssne-name' [value]="localSensor.name" (valueChanged)="localSensor.name = $event" (enterClicked)="onConfirm()" [maxCharacters]="maxSensorLen">{{trans('systems.labels.sensorName')}}</app-text-edit>
    <div id='ssne-type-list'>
        <div class='list-item-v2 ssne-type-item' *ngFor="let iType of allTypes">
            <div>
                <app-radio-button class="ssne-radio" [checkValue]="localSensor.type === iType.id" (valueChanged)="$event ? localSensor.type = iType.id :''"></app-radio-button>
            </div>
            <div [inlineSVG]="iType.icon" (click)="onTypeSelection(iType.id)"></div>
            <div></div>
            <div (click)="onTypeSelection(iType.id)">
                <div>{{trans('sensors.types.' + iType.name)}}</div>
                <div>{{trans('sensors.units.' + iType.unit)}}</div>
            </div>
        </div>
    </div>
    <div id='ssne_notif_back' class="group-background"></div>
    <ng-container *ngIf='systems.activeSystemHelper.supports.sensorNotifications()'>
        <div id='ssne_notif_toggle_text'>{{trans('sensors.labels.notifications')}}</div>
        <app-toggle-button id='ssne_notif_toggle' [initialState]='notificationsEnabled()' (toggled)="setNotificationsEnabled($event.checked)"></app-toggle-button>
        <app-text-edit id='ssne_notif_high' *ngIf='notificationsEnabled()' [inputType]='"float"' [value]="localSensor.highValue" (valueChanged)="localSensor.highValue = $event" [maxNumber]="500" [minNumber]="-55">{{trans('sensors.labels.notifyHigh')}}</app-text-edit>
        <app-text-edit id='ssne_notif_low' *ngIf='notificationsEnabled()' [inputType]='"float"' [value]="localSensor.lowValue" (valueChanged)="localSensor.lowValue = $event" [maxNumber]="500" [minNumber]="-55">{{trans('sensors.labels.notifyLow')}}</app-text-edit>
    </ng-container>
</div>