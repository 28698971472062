import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserRole } from 'src/api/v3/common';
import { GoogleLoginRequest } from 'src/api/v3/login';
import { AppSettings } from 'src/app-settings';
import { autoinject } from 'src/shim';
import { AuthService } from '../api/auth.service';
import { GoogleAuthService } from '../api/google-auth.service';
import { LoggerService } from '../api/logger.service';
import { RequestService } from '../api/request.service';
import { RtcService } from '../api/rtc/rtc.service';
import { SystemService } from '../api/system/system.service';
import { UserService } from '../api/user.service';
import { companyRootRoute } from '../company/company-routing.module';
import { SystemsService } from '../services/systems.service';
import { UserService as USO } from '../services/user.service';

type EmailCheckResponseNoUser = {
  result: 'no_user';
};

type EmailCheckResponseLocal = {
  result: 'local';
  noPassword?: true;
};

type EmailCheckResponseSocial = {
  result: 'social';
  type: 'google' | string;
};

type EmailCheckResponseDisabled = {
  result: 'disabled';
};

export const ForgotPasswordTarget = Symbol('ForgotPasswordTarget');

type EmailCheckResponse = EmailCheckResponseNoUser | EmailCheckResponseLocal | EmailCheckResponseSocial | EmailCheckResponseDisabled;
/**
 * A service for common auth tasks.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginService {
  public email = '';
  public password = '';

  public activationToken?: string;
  public passwordToken?: string;

  public gauth = autoinject(this.injector, GoogleAuthService);

  constructor(
    private auth: AuthService,
    private req: RequestService,
    private rtc: RtcService,
    private system: SystemService,
    private systems: SystemsService,
    private user: UserService,
    private l: LoggerService,
    private router: Router,
    private injector: Injector,
    private us: USO
  ) {}

  public clearLoginState() {
    this.email = '';
    this.password = '';
    this.activationToken = undefined;
  }

  public checkEmail(email: string): Observable<EmailCheckResponse> {
    return this.req.login.checkEmail(email).pipe(
      map((res) => {
        if (res.success) {
          if (res.isNew) {
            return { result: 'no_user' };
          } else if (!(res as { isActive: boolean }).isActive) {
            return { result: 'disabled' };
          } else if (!res.isNew && (res as { isSoc: boolean }).isSoc) {
            return { result: 'social', type: (res as { soc_type: string }).soc_type };
          } else if ((res as { isPasswordSet: boolean }).isPasswordSet) {
            return { result: 'local' };
          } else {
            return { result: 'local', noPassword: true };
          }
        } else if ('error' in res) {
          throw new Error(res.error);
        } else {
          throw new Error('Unknown login error');
        }
      })
    );
  }

  public login(email: string, password: string) {
    return this.req.login.login(email, password).pipe(
      map((res) => {
        if (res.success) {
          const { success, lastSystem, ...user } = res;
          this.rtc.close();
          this.us.clearUserData();
          this.auth.setToken(user.token);
          this.user.ingestUser(user);
          this.us.setActiveUser(user);
          if (lastSystem) this.system.ingestSystem(lastSystem);
          this.systems.loadSystems();
          this.rtc.connect();
        }
        return res;
      })
    );
  }

  public determineLoginDestination() {
    switch (this.user.user?.role) {
      case UserRole.SuperAdmin:
        if (AppSettings.companyDesktopEnabled) return [companyRootRoute];
        return ['/sadmin'];
      case UserRole.Company:
        if (AppSettings.companyDesktopEnabled) return [companyRootRoute];
        return ['/company'];
      case UserRole.Installer:
        return ['/installer'];
      case UserRole.SimpleUser:
        return ['/home'];
      default:
        throw new Error('Unknown user role');
    }
  }

  public logout() {
    this.auth.logOutFromSwitcher(this.auth.GetUserId());
    this.auth.setToken(null);
    this.rtc.close();
    this.gauth().signOut(false);
  }

  public async handleGoogleLoginSuccess(id_token: string, ios: boolean = false, ac?: string) {
    const params: GoogleLoginRequest = { id_token };
    if (ios) params.ios = true;
    if (ac) params.ac = ac;
    const res = await this.req.login.googleLogin(params).toPromise();
    if (res.success) {
      const { success, ...user } = res;
      this.rtc.close();
      this.us.clearUserData();
      this.auth.setToken(user.token);
      this.user.ingestUser(user);
      this.rtc.connect();
      this.router.navigate(this.determineLoginDestination());
    } else {
      this.l.log('Google login failed', 'Login', { res, id_token, ac });
    }
  }
}
