import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { Template } from 'webpack';
import { TeleportService } from '../teleport.service';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
})
export class PortalComponent implements OnInit {
  public get teleports(): TemplateRef<any>[] {
    return [...(this.teleport.teleports.get(this.name)?.values() ?? [])];
  }
  @Input() name: any;
  @Input() public context: any;
  constructor(private teleport: TeleportService) {}

  ngOnInit(): void {}
}
