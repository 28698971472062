import { Injectable, Injector } from '@angular/core';
import { GoogleAuthMobileService } from './google-auth-mobile.service';
import { GoogleAuthWebService } from './google-auth-web.service';
import { PlatformService } from './platform.service';

@Injectable()
export abstract class GoogleAuthService {
  public abstract signIn(): Promise<any>;
  public abstract signOut(arg0?: boolean): Promise<any>;
  public readonly supportsOneTap;
  constructor(injector: Injector) {}

  public abstract whenReady(): Promise<void>;
  public abstract whenReady(callback: () => void): void;

  public abstract renderButton(container: HTMLElement);
}

export const googleAuthFactory = (platform: PlatformService, injector: Injector): GoogleAuthService => {
  if (platform.isAndroid() || platform.isApple()) {
    return new GoogleAuthMobileService(injector);
  }
  return new GoogleAuthWebService(injector);
};
