<div class="login-box">
  <h2>{{ trans('auth.labels.login') }}</h2>
  <p>{{ trans('auth.labels.loginPrompt') }}</p>
  <form (submit)="$event.preventDefault(); next()">
    <app-string-input label="Email" type="email" name="email" autocomplete="email" [(value)]="login.email" [hasError]="val.hasError('email')">
      <app-form-error *ngIf="val.hasError('email')"
        ><app-tmapper
          [key]="val.getError('email')"
          [default]="defaultEmailError"
          [context]="{}"
          [map]="{
            NO_PASSWORD: noPassword,
            DISABLED_USER: disabledUser,
            NO_USER: noUser,
            SOCIAL: social,
            SOCIAL_GOOGLE: socialGoogle,
            EMAIL_REQUIRED: emailRequired,
            EMAIL_INVALID: emailInvalid
          }"
        ></app-tmapper
      ></app-form-error>
    </app-string-input>
    <input style="display: none" type="password" name="password" id="password" [(value)]="login.password" autocomplete="current-password" />
    <app-button>{{ trans('auth.loginNext') }}</app-button>
  </form>
</div>
<ng-template #defaultEmailError>
  <span>{{ val.getError('email') }}</span>
</ng-template>
<ng-template #noPassword>
  <span
    >{{ trans('auth.error.noPasswordSet') }} <a routerLink="/login/password-reset/email">{{ trans('auth.error.noPasswordSetLink') }}</a></span
  >
</ng-template>
<ng-template #disabledUser>
  <span
    >{{ trans('auth.error.notActivated') }} <a routerLink="/login/register/activate/resend">{{ trans('auth.error.notActivatedLink') }}</a></span
  >
</ng-template>
<ng-template #social>
  <span>{{ trans('auth.error.useSocialLogin') }}</span>
</ng-template>
<ng-template #noUser>
  <span
    >{{ trans('auth.error.noUser') }} <a routerLink="/login/register">{{ trans('auth.error.noUserLink') }}</a></span
  >
</ng-template>
<ng-template #socialGoogle>
  <span
    >{{ trans('auth.error.useGoogleLogin') }} <a href="javascript:void(0)" (click)="googleAuth.signIn()">{{ trans('auth.error.useGoogleLoginLink') }}</a></span
  >
</ng-template>
<ng-template #emailInvalid>
  <span>{{ trans('auth.error.invalidEmail') }}</span>
</ng-template>
<ng-template #emailRequired>
  <span>{{ trans('auth.error.requiredEmail') }}</span>
</ng-template>
