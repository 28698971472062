<div *ngIf='!footerService.isConfirmationVisible()' class='footer-button-holder'>
    <div *ngIf='showButton1()' [ngClass]="{ 'footer-button' : true, 'footer-button-opaque' : footerService.isOpaque(), 'footer-button-white': footerService.isWhite }" (click)='doButton1Click()'>{{footerService.getButton1Text()}}</div>
    <div *ngIf='showButton2()' [ngClass]="{ 'footer-button' : true, 'footer-button-opaque' : footerService.isOpaque(), 'footer-button-white': footerService.isWhite }" (click)='doButton2Click()'>{{footerService.getButton2Text()}}</div>
</div>
<div *ngIf='!sumOk && footerService.isConfirmationVisible()' id='sum-container'>
    <div id='sum-text'>{{this.randomNumber1}} + {{this.randomNumber2}} = ?</div>
    <div id='sum-1' [ngClass]="{ 'sum-button' : true, 'sum-button-red' : sumButton1Bad }" (click)='onSumClick(0)'>{{randomSums[0]}}</div>
    <div id='sum-2' [ngClass]="{ 'sum-button' : true, 'sum-button-red' : sumButton2Bad }" (click)='onSumClick(1)'>{{randomSums[1]}}</div>
    <div id='sum-3' [ngClass]="{ 'sum-button' : true, 'sum-button-red' : sumButton3Bad }" (click)='onSumClick(2)'>{{randomSums[2]}}</div>
</div>
