import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-cp-panic-button',
  templateUrl: './cp-panic-button.component.html',
  styleUrls: ['./cp-panic-button.component.scss'],
})
export class CpPanicButtonComponent extends LanguageAware implements OnInit {
  public classificatorText = '';
  public codeText = '';
  private original = null;

  constructor(cdRef: ChangeDetectorRef, private pp: PopupService, private router: Router) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.grg'),
      backUrl: this.g.getHomeUrl(),
    });
    this.generateClassificatorCodeTexts();
    this.original = JSON.stringify(this.us.currentUser.panicSettings);
    this.background.setGray();
    const that = this;
    this.headerBar.onBackClicked = () => {
      that.revert();
    };
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    this.footerBar.onButton1Click = () => {
      that.revert();
      that.router.navigate([that.g.getHomeUrl()]);
    };
    this.footerBar.onButton2Click = () => {
      that.save();
    };
  }

  ngOnInit(): void {}

  private revert() {
    this.us.currentUser.panicSettings = JSON.parse(this.original);
  }

  private generateClassificatorCodeTexts() {
    const code = this.us.currentUser.panicSettings.grg_code;
    if (code === '') {
      this.classificatorText = 'E';
      this.codeText = '';
      return;
    }
    if (code[0] !== 'E' && code[0] !== 'R') {
      this.classificatorText = 'E';
      this.codeText = code.substr(0, 3);
    } else {
      this.classificatorText = code[0];
      this.codeText = code.substr(1, 3);
    }
    if (this.codeText.length < 3) {
      this.codeText = this.codeText.padStart(3, '0');
    }
  }

  public changeIp() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.trans('settings.grg.label.host'),
        field1: {
          maxLen: 15,
          oldValue: this.us.currentUser.panicSettings.grg_host,
        },
        onSubmit: (res) => {
          this.us.currentUser.panicSettings.grg_host = res;
        },
      },
      PopupType.IPAddress
    );
  }

  public changePort() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.trans('settings.grg.label.port'),
        field1: {
          maxLen: 5,
          oldValue: this.us.currentUser.panicSettings.grg_port,
        },
        onSubmit: (res) => {
          this.us.currentUser.panicSettings.grg_port = res.value1;
        },
      },
      PopupType.Number
    );
  }

  public changeClassificator() {
    const that = this;
    this.pp.showSlideout(
      {
        headerText: this.trans('settings.grg.label.classificator'),
        items: ['E', 'R'],
        onSubmit: (res) => {
          that.us.currentUser.panicSettings.grg_code = (res === 0 ? 'E' : 'R') + that.codeText;
          that.generateClassificatorCodeTexts();
        },
      },
      PopupType.Slideout
    );
  }

  public changeCode() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.trans('settings.grg.label.code'),
        field1: {
          isHex: true,
          maxLen: 3,
          oldValue: this.codeText,
        },
        onSubmit: (res) => {
          that.us.currentUser.panicSettings.grg_code = that.classificatorText + res;
          that.generateClassificatorCodeTexts();
        },
      },
      PopupType.String
    );
  }

  private save() {
    const that = this;
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/edit/panic-button', this.us.currentUser.panicSettings, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          that.router.navigate([that.g.getHomeUrl()]);
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
      }
    );
  }

  public changeZone() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.trans('settings.grg.label.zone'),
        field1: {
          isHex: true,
          maxLen: 3,
          oldValue: this.us.currentUser.panicSettings.grg_zone,
        },
        onSubmit: (res) => {
          if (res.length < 3) {
            res = res.padStart(3, '0');
          }
          that.us.currentUser.panicSettings.grg_zone = res;
        },
      },
      PopupType.String
    );
  }
}
