import { Component, OnInit } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.scss'],
})
export class AccountsComponent implements OnInit {
  public forgetMode = false;
  public readonly trans = (key: string) => this.ls.get(key);
  constructor(private ls: LanguageService) {}

  ngOnInit(): void {}
}
