import { Injectable } from '@angular/core';
import { AppSettings } from 'src/app-settings';
import { ApiRequestService } from './api-request.service';
import { LanguageService } from './language.service';

type WifDevice = {
  prettyName: string;
  wifiName: string;
  uglyName: string;
};

const wifiDevices: Record<number, WifDevice> = {
  0x49: { prettyName: 'FLEXi HUB', uglyName: 'hub', wifiName: 'HUB' },
  0x37: { prettyName: AppSettings.wifiGateDevice37Name, uglyName: 'wp17', wifiName: AppSettings.wifiGateDevice37Name },
  0x3b: { prettyName: 'SP3 FLEXi', uglyName: 'sp3', wifiName: 'SP3' },
};

@Injectable({
  providedIn: 'root',
})
export class DeviceService {
  private REREAD_TIMEOUT = 3 * 60; // sekundemis
  private hwId: number;
  private waiter = null;
  private startOfReread = new Date();

  constructor(private api: ApiRequestService, private lang: LanguageService) {}

  public getDeviceNameById(hwId?: number) {
    if (!hwId) {
      hwId = this.hwId;
    }
    const found = wifiDevices[hwId];
    return found?.prettyName ?? 'N/A';
  }

  public getDeviceTypeById(hwId?: number) {
    if (!hwId) {
      hwId = this.hwId;
    }
    const found = wifiDevices[hwId];
    return found?.uglyName ?? 'N/A';
  }

  public getDeviceWifiById(hwId?: number) {
    if (!hwId) {
      hwId = this.hwId;
    }
    const found = wifiDevices[hwId];
    return found?.wifiName ?? 'N/A';
  }

  public setHwId(hwId: number) {
    this.hwId = hwId;
  }

  public getHwId(): number {
    return this.hwId;
  }

  public performReRead(
    systemId: number,
    onSuccess: (status: string) => void,
    onError: (err: string) => void,
    onStatus: (s: string, progress: { current: number; max: number }) => void
  ) {
    const that = this;
    this.startOfReread = new Date();
    this.api.post('/reread/start', { systemId }, true).subscribe(
      (result) => {
        if (result.success) {
          onStatus(result.nextStatus, result.progress);
          that.waiter = setTimeout(() => {
            that.waitForCompletion(systemId, result.rereadId, onSuccess, onError, onStatus);
          }, 1000);
        } else {
          onError(result.error);
        }
      },
      (error) => {
        onError('');
      }
    );
  }

  public stopReRead() {
    this.stopWaiter();
  }

  private stopWaiter() {
    if (this.waiter !== null) {
      clearTimeout(this.waiter);
      this.waiter = null;
    }
  }

  private waitForCompletion(
    systemId: number,
    rereadId: number,
    onSuccess: (status: string) => void,
    onError: (err: string) => void,
    onStatus: (s: string, progress: { current: number; max: number }) => void
  ) {
    const that = this;
    this.api.post('/reread/check', { systemId, rereadId }, true).subscribe(
      (result) => {
        if (result.success) {
          if (result.completed) {
            onSuccess(result.nextStatus);
            that.stopWaiter();
          } else {
            if (new Date().getTime() - that.startOfReread.getTime() > this.REREAD_TIMEOUT * 1000) {
              onError(that.lang.get('systems.errors.rereadTooLong'));
              that.stopWaiter();
            } else {
              onStatus(result.nextStatus, result.progress);
              that.waiter = setTimeout(() => {
                that.waitForCompletion(systemId, rereadId, onSuccess, onError, onStatus);
              }, 2000);
            }
          }
        } else {
          if (result.error === undefined) {
            onError(result.nextStatus);
          } else {
            onError(result.error);
          }
          that.stopWaiter();
        }
      },
      (error) => {
        onError('');
        that.stopWaiter();
      }
    );
  }

  public revisionStartsWith(revision: string, startsWith: string | string[]): boolean {
    const numberPart = revision.split('_')[1];
    if (!numberPart) {
      return false;
    }
    if (typeof startsWith === 'string') {
      return numberPart.startsWith(startsWith);
    }
    for (const iStart of startsWith) {
      if (numberPart.startsWith(iStart)) {
        return true;
      }
    }
    return false;
  }
}
