import { Injectable, Injector, NgZone, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { TUser } from 'src/app/models/user';
import { autoinject, useDebouncedFunction } from 'src/shim';
import { AuthService } from '../auth.service';
import { LoggerService } from '../logger.service';
import { UserService } from '../user.service';
import {
  RtcMessageAreaStatus,
  RtcMessageConfigurationEvent,
  RtcMessageEvent,
  RtcMessagePgm,
  RtcMessageSystemStatusReload,
  RtcMessageUserAccessControl,
  RtcMessageUserSystem,
  RtcMessageVersionUpdate,
  RtcService,
} from './rtc.service';

@Injectable()
export class AndroidRtcService implements RtcService, OnDestroy {
  private systemStatusChangeSubject = new Subject<RtcMessageSystemStatusReload>();
  private versionUpdateSubject = new Subject<RtcMessageVersionUpdate>();
  private eventsSubject = new Subject<RtcMessageEvent>();
  private areaStatusSubject = new Subject<RtcMessageAreaStatus>();
  private pgmSubject = new Subject<RtcMessagePgm>();
  private userSystemSubject = new Subject<RtcMessageUserSystem>();
  private configurationEventSubject = new Subject<RtcMessageConfigurationEvent>();
  private userAccessControlSubject = new Subject<RtcMessageUserAccessControl>();

  public systemStatusChange: Observable<RtcMessageSystemStatusReload> = this.systemStatusChangeSubject.asObservable();
  public versionUpdate: Observable<RtcMessageVersionUpdate> = this.versionUpdateSubject.asObservable();
  public events: Observable<RtcMessageEvent> = this.eventsSubject.asObservable();
  public areaStatus: Observable<RtcMessageAreaStatus> = this.areaStatusSubject.asObservable();
  public pgm: Observable<RtcMessagePgm> = this.pgmSubject.asObservable();
  public userSystem: Observable<RtcMessageUserSystem> = this.userSystemSubject.asObservable();
  public configurationEvent: Observable<RtcMessageConfigurationEvent> = this.configurationEventSubject.asObservable();
  public userAccessControl: Observable<RtcMessageUserAccessControl> = this.userAccessControlSubject.asObservable();
  private zone = this.injector.get(NgZone);

  private user = autoinject(this.injector, UserService);
  private auth = this.injector.get(AuthService);
  private l = this.injector.get(LoggerService);

  private lastIntrests: number[] = [];

  constructor(private injector: Injector) {
    window.statusJsi = {
      onSystem: (data: string[]) => {
        this.zone.run(() => this.systemStatusChangeSubject.next(JSON.parse(data[0])));
      },
      onArea: (data: string[]) => {
        this.zone.run(() => this.areaStatusSubject.next(JSON.parse(data[0])));
      },
      onPgm: (data: string[]) => {
        this.zone.run(() => this.pgmSubject.next(JSON.parse(data[0])));
      },
      onUserSystemEvent: (data: string[]) => {
        this.zone.run(() => this.userSystemSubject.next(JSON.parse(data[0])));
      },
      onEvent: (data: string[]) => {
        this.zone.run(() => this.eventsSubject.next(JSON.parse(data[0])));
      },
      onUserAccess: (data: string[]) => {
        this.zone.run(() => this.userAccessControlSubject.next(JSON.parse(data[0])));
      },
      onConfiguration: (data: string[]) => {
        this.zone.run(() => this.configurationEventSubject.next(JSON.parse(data[0])));
      },
      onVersion: (data: string[]) => {
        this.zone.run(() => this.versionUpdateSubject.next(JSON.parse(data[0])));
      },
    };
    const [updateIntrests] = useDebouncedFunction(() => this.updateIntrestsInternal(), 1000);
    this.updateIntrests = (systemIds: number[]) => {
      this.lastIntrests = systemIds;
      updateIntrests();
    };
  }

  ngOnDestroy(): void {
    this.close();
  }

  public close(): void {
    if (window.jsi !== undefined) {
      window.jsi.onSocketClose();
    }
  }

  public connect(): void {
    this.updateIntrests([]);
  }
  public updateIntrests: (systemIds: number[]) => void;

  private async updateIntrestsInternal(): Promise<void> {
    if (window.jsi !== undefined) {
      if (!this.auth.hasToken()) {
        this.l.log('Vartotojas neprisijungę, nabandome prisijungti.', 'AndroidRtcService');
        return;
      }
      let user: TUser = this.user().user;
      if (!user?.SocketToken) {
        this.l.log('Vartotojas neturi socket tokeno, bandome gauti tokeną ir tada bandysime vėl..', 'AndroidRtcService');
        user = await this.auth.loadUserData(false);
      }
      this.l.log('Prisijungiama prie socketo...', 'AndroidRtcService');
      // Šita sritis telefonams
      window.jsi.onSocketParamsGot(
        JSON.stringify({
          port: user.SocketPort,
          token: user.SocketToken,
          clientOptions: {
            version: 2,
            systems: this.lastIntrests,
            user: user.id,
          },
        })
      );
    }
  }
}
