import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNumeric } from 'src/shim';
import { AppSettings } from 'src/app-settings';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { SlideoutItem } from 'src/app/models/slideout-item';
import { TUser } from 'src/app/models/user';
import { UserRole } from 'src/app/models/user-role';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-cp-edit-user',
  templateUrl: './cp-edit-user.component.html',
  styleUrls: ['./cp-edit-user.component.scss'],
})
export class CpEditUserComponent extends LanguageAware implements OnInit {
  @ViewChild('pickInstaller') pickInstaller;
  public userItem: TUser = null;
  private userBeforeModifications = '';
  public countryText = '';
  private passwordChanged = false;
  public newPassword = '';
  public selectedInstallerId = -1;
  public installersToPickFrom = [];
  private returnToUrl = '/all-users';

  constructor(cdRef: ChangeDetectorRef, ar: ActivatedRoute, private router: Router, private pp: PopupService) {
    super(cdRef);
    const userId = ar.snapshot.paramMap.get('num');
    const returnTo = ar.snapshot.paramMap.get('from');
    if (returnTo !== null) {
      if (returnTo === 'filter') {
        this.returnToUrl = '/filtered-users';
      }
    }
    let headerT = this.trans('systems.titles.addNewUser');
    if (userId !== null) {
      headerT = this.trans('systems.titles.editUser');
      if (!isNumeric(userId)) {
        this.router.navigate([this.g.getHomeUrl() + this.returnToUrl]);
        return;
      }
      const userIdInt = parseInt(userId, 10);
      let found = this.us.companyInstallers.find((ci) => ci.id === userIdInt);
      if (found === undefined) {
        found = this.us.simpleUsers.find((si) => si.id === userIdInt);
      }
      if (this.us.currentUser.role === UserRole.SuperAdmin) {
        if (found === undefined) {
          found = this.us.superAdmins.find((sui) => sui.id === userIdInt);
        }
        if (found === undefined) {
          found = this.us.companies.find((cpi) => cpi.id === userIdInt);
        }
      }
      if ([UserRole.Company, UserRole.SuperAdmin].includes(this.us.currentUser.role) && found === undefined) {
        found = this.us.filteredUsers.find((fu) => fu.id === userIdInt);
      }
      if (found === undefined) {
        this.router.navigate([this.g.getHomeUrl() + this.returnToUrl]);
        return;
      }
      this.userItem = found;
      this.userBeforeModifications = JSON.stringify(found);
    } else {
      this.userItem = {
        name: '',
        email: '',
        ActivationToken: '',
        DateFormat: 0,
        id: 0,
        IsSocialAccount: false,
        phone: '',
        role: this.us.currentUser.role === UserRole.Company ? 2 : 1,
        SocketPort: 0,
        SocketToken: '',
        homeConfigurations: [],
        isMaster: false,
        isPasswordSet: false,
        lastSeenReactions: [],
        passwordToken: '',
        reactionGroups: [],
        reactions: [],
        country: 'US',
        active: 1,
        company: '',
        logo: '',
        company_id: 0,
        panicSettings: null,
        regions: [],
        limits: null,
        canTransferDevice: false,
      };
    }
    let canShowDelete =
      this.userItem.id !== 0 &&
      ((this.userItem.role === UserRole.Installer && this.us.currentUser.role === UserRole.Company) ||
        (this.userItem.id !== this.us.currentUser.id && this.us.currentUser.role === UserRole.SuperAdmin));
    if (AppSettings.onlySUcanAddRemoveUsers && this.us.currentUser.role !== UserRole.SuperAdmin) {
      canShowDelete = false;
    }
    this.headerBar.showHeader({
      headerText: headerT,
      backUrl: this.g.getHomeUrl() + this.returnToUrl,
      actionButtonText: canShowDelete ? this.trans('general.delete') : undefined,
    });
    this.background.setGray();
    const that = this;
    this.headerBar.onBackClicked = () => {
      that.revert();
    };
    if (this.userItem.id > 0) {
      this.headerBar.onActionButtonClicked = () => {
        that.deleteUser();
      };
      this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    } else {
      this.footerBar.showFooter(this.trans('general.cancel'), this.trans('systems.buttons.addUser'), true, false);
    }
    this.footerBar.onButton1Click = () => {
      that.revert();
      that.router.navigate([this.g.getHomeUrl() + this.returnToUrl]);
    };
    this.footerBar.onButton2Click = () => {
      that.doSave();
    };

    const foundCountry = this.us.countries.find((c) => c.code === this.userItem.country);
    if (foundCountry !== undefined) {
      this.countryText = foundCountry.name;
    }
  }

  ngOnInit(): void {}

  private deleteUser() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    const dataToSend: {
      user_id: number;
      replace?: number;
    } = {
      user_id: this.userItem.id,
    };
    if (this.selectedInstallerId !== -1) {
      dataToSend.replace = this.selectedInstallerId;
    } else if (this.systems.systems.find((s) => s.installerId === this.userItem.id) !== undefined) {
      if (this.installersToPickFrom.length === 0) {
        this.installersToPickFrom = this.us.companyInstallers.filter((ci) => ci.id !== this.userItem.id);
        this.installersToPickFrom.unshift({
          id: 0,
          name: this.trans('users.labels.noneInstaller'),
          email: '',
        });
      }
      this.selectedInstallerId = 0;
      this.pickInstaller.show();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.delete('/cuser', true, dataToSend).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          if (that.userItem.role === UserRole.Installer) {
            that.us.companyInstallers = that.us.companyInstallers.filter((ci) => ci.id !== that.userItem.id);
          } else if (that.userItem.role === UserRole.SuperAdmin) {
            that.us.superAdmins = that.us.superAdmins.filter((su) => su.id !== that.userItem.id);
          } else if (that.userItem.role === UserRole.Company) {
            that.us.companies = that.us.companies.filter((cp) => cp.id !== that.userItem.id);
          } else if (that.userItem.role === UserRole.SimpleUser) {
            that.us.simpleUsers = that.us.simpleUsers.filter((cp) => cp.id !== that.userItem.id);
          }
          that.router.navigate([this.g.getHomeUrl() + this.returnToUrl]);
        } else {
          that.toaster.postError(result.error);
          that.revert();
        }
      },
      (error) => {
        that.revert();
      }
    );
  }

  public installlerSelectionConfirmed() {
    this.pickInstaller.hidePopup();
    this.deleteUser();
  }

  private doSave() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('general.saving'));
    const data: Pick<TUser, 'id' | 'name' | 'email' | 'country' | 'active' | 'phone' | 'role'> & { changePassword?: 'on'; newPass?: string; password?: string } = {
      id: this.userItem.id,
      name: this.userItem.name,
      email: this.userItem.email,
      country: this.userItem.country,
      active: this.userItem.active,
      phone: this.userItem.phone,
      role: this.us.currentUser.role === UserRole.Company ? UserRole.Installer : this.userItem.role,
    };
    if (this.passwordChanged) {
      data.changePassword = 'on';
      data.newPass = this.newPassword;
    }
    if (this.userItem.id === 0) {
      data.password = this.newPassword;
    }
    this.api.post('/edit/cuser', data, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          if (that.userItem.id === 0) {
            that.userItem.id = result.newId;
            if (that.userItem.role === UserRole.Installer) {
              that.us.companyInstallers.push(that.userItem);
            } else if (that.userItem.role === UserRole.SuperAdmin) {
              that.us.superAdmins.push(that.userItem);
            } else if (that.userItem.role === UserRole.Company) {
              that.us.companies.push(that.userItem);
            } else if (that.userItem.role === UserRole.SimpleUser) {
              that.us.simpleUsers.push(that.userItem);
            }
          } else {
            that.checkAndMove();
          }
          that.router.navigate([this.g.getHomeUrl() + this.returnToUrl]);
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.error.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }

  private revert() {
    if (this.userItem.id === 0) {
      return;
    }
    const original = JSON.parse(this.userBeforeModifications);
    this.userItem.role = original.role;
    this.userItem.email = original.email;
    this.userItem.name = original.name;
    this.userItem.country = original.country;
    this.userItem.phone = original.phone;
    this.userItem.active = original.active;
  }

  public changeCountry() {
    const that = this;
    const itemsC: SlideoutItem[] = [];
    for (const iCountry of this.us.countries) {
      itemsC.push({
        text: iCountry.name,
        value: iCountry.code,
      });
    }
    this.pp.showSlideout(
      {
        headerText: this.trans('users.labels.country'),
        items: itemsC,
        onSubmit: (res) => {
          that.userItem.country = res.value;
          const found = this.us.countries.find((c) => c.code === res.value);
          that.countryText = found.name;
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public changeRole() {
    const that = this;
    const roles: SlideoutItem[] = [];
    if (this.us.currentUser.role === UserRole.SuperAdmin) {
      roles.push({
        value: 0,
        text: this.trans('users.roles.0'),
      });
      roles.push({
        value: 3,
        text: this.trans('users.roles.3'),
      });
      roles.push({
        value: 1,
        text: this.trans('users.roles.1'),
      });
    }
    roles.push({
      value: 2,
      text: this.trans('users.roles.2'),
    });
    this.pp.showSlideout(
      {
        headerText: this.trans('users.labels.role'),
        items: roles,
        onSubmit: (res) => {
          that.userItem.role = res.value;
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public changePassword() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.trans('users.labels.passwordNew'),
        field1: {
          maxLen: 60,
        },
        onSubmit: (res) => {
          that.newPassword = res;
          if (that.userItem.id !== 0) {
            that.passwordChanged = true;
          }
        },
      },
      PopupType.Password
    );
  }

  public checkAndMove() {
    const original = JSON.parse(this.userBeforeModifications);
    if (original.role === this.userItem.role) {
      return;
    }

    if (original.role === UserRole.SimpleUser) {
      this.us.simpleUsers = this.us.simpleUsers.filter((simu) => simu.id !== this.userItem.id);
    } else if (original.role === UserRole.Installer) {
      this.us.companyInstallers = this.us.companyInstallers.filter((ci) => ci.id !== this.userItem.id);
    } else if (original.role === UserRole.Company) {
      this.us.companies = this.us.companies.filter((cu) => cu.id !== this.userItem.id);
    } else if (original.role === UserRole.SuperAdmin) {
      this.us.superAdmins = this.us.superAdmins.filter((su) => su.id !== this.userItem.id);
    }

    if (this.userItem.role === UserRole.SimpleUser) {
      this.us.simpleUsers.push(this.userItem);
    } else if (this.userItem.role === UserRole.Installer) {
      this.us.companyInstallers.push(this.userItem);
    } else if (this.userItem.role === UserRole.Company) {
      this.us.companies.push(this.userItem);
    } else if (this.userItem.role === UserRole.SuperAdmin) {
      this.us.superAdmins.push(this.userItem);
    }
  }
}
