import { Injectable } from '@angular/core';
import { SystemsService } from './systems.service';
import { ToasterService } from './toaster.service';
import { MiniStatusService } from './mini-status.service';
import { ApiRequestService } from './api-request.service';
import { LanguageService } from './language.service';
import { TAreaStatusOperation } from '../models/area-status-operation';
import { TAreaData } from '../models/area-data';
import { RefreshService } from './refresh.service';
import { Subject } from 'rxjs';
import { ZoneService } from './zone.service';
import { WebSocketService } from './websocket.service';
import { GeneralSettings } from 'src/general-settings';
import { LoggerService } from '../api/logger.service';

@Injectable({
  providedIn: 'root',
})
export class AreaService {
  private controlsEnabled = true;
  private areaStatusesInProgress = [];
  private areaStatusWorker = null;
  private tag = 'AreaService';
  public alarmedActionInProgress = false;
  private onAreaOperationCompletedSource = new Subject<void>();
  public onAreaOperationCompleted = this.onAreaOperationCompletedSource.asObservable();
  private onTakeActionButtonVisibilityChangedSource = new Subject<any>();
  public onTakeActionButtonVisibilityChanged = this.onTakeActionButtonVisibilityChangedSource.asObservable();
  private takeActionAreas = [];

  public busyArea: TAreaStatusOperation = null;

  constructor(
    private systems: SystemsService,
    private toaster: ToasterService,
    private miniStatus: MiniStatusService,
    private api: ApiRequestService,
    private ls: LanguageService,
    private l: LoggerService,
    private r: RefreshService,
    private zs: ZoneService,
    ws: WebSocketService
  ) {
    this.l.log('+', this.tag);
    const that = this;
    this.systems.addAreaStatusWork = (systemId, areaId, statusTime) => {
      that.addAreaStatusInProgress(systemId, areaId, statusTime);
    };
    ws.onAreaStatusChange.subscribe((area: any) => {
      that.addOrUpdateAreaForTakeAction(area);
    });
  }

  public changeAreaStateTo() {
    if (!this.controlsEnabled) {
      return;
    }
    this.controlsEnabled = false;
    const that = this;
    this.api
      .post(
        '/set-area-status',
        {
          area_id: this.busyArea.areaId,
          new_state: this.busyArea.newStatus,
          pin: this.busyArea.pinToUse,
          remember_pin: this.busyArea.newRememberPin,
        },
        true
      )
      .subscribe(
        (result) => {
          that.l.log('', that.tag, result);
          that.controlsEnabled = true;
          that.miniStatus.hide();
          that.alarmedActionInProgress = false;
          that.onAreaOperationCompletedSource.next();
          if (result.success) {
            if (result.state !== undefined) {
              that.saveNewStatus(result.state);
              that.systems.setCurrentSystem(that.systems.activeSystem);
            } else {
              that.busyArea = null;
              that.r.enableRefresher();
            }
          } else if (result.failedZones !== undefined && Object.keys(result.failedZones).length > 0) {
            that.failedToChangeStatus(result);
          } else if (result.error !== undefined) {
            that.toaster.postError(result.error);
            that.failedToChangeStatus();
          } else {
            that.failedToChangeStatus();
          }
        },
        (error) => {
          that.alarmedActionInProgress = false;
          that.onAreaOperationCompletedSource.next();
          that.toaster.postError(that.ls.get('auth.errors.serverSideError'));
          that.miniStatus.hide();
          that.controlsEnabled = true;
          that.revertAreaStatus();
        }
      );
  }

  private failedToChangeStatus(result?: any) {
    if (result !== undefined && result.failedZones !== undefined) {
      const zoneNumbers = Object.keys(result.failedZones);
      this.busyArea.zonesToBypass = [];
      for (const iNumber of zoneNumbers) {
        this.busyArea.zonesToBypass.push({
          isChecked: false,
          // eslint-disable-next-line id-blacklist
          number: iNumber,
          title: result.failedZones[iNumber],
        });
      }
      this.busyArea.showZoneBypass = true;
      this.l.log('', this.tag, this.busyArea.zonesToBypass);
    } else {
      this.revertAreaStatus();
    }
  }

  public showButtons(areaId: number): boolean {
    return this.systems.activeSystem.directControl || this.isOutputAssigned(areaId);
  }

  public isOutputAssigned(areaId: number): boolean {
    const area = this.systems.activeSystem.areas.find((a) => a.id === areaId);
    if (area === undefined) {
      return false;
    }
    for (const iPgm of this.systems.activeSystem.pgms) {
      if (iPgm.area_no === area.queue_no) {
        return true;
      }
    }

    return false;
  }

  public addAreaStatusInProgress(systemId: number, areaId: number, statusTime: number) {
    let found = false;
    for (const iItem of this.areaStatusesInProgress) {
      if (iItem.areaId === areaId) {
        iItem.statusTime = new Date(statusTime * 1000);
        found = true;
        break;
      }
    }
    if (!found) {
      this.areaStatusesInProgress.push({
        systemId,
        areaId,
        statusTime: new Date(statusTime * 1000),
      });
    }
    if (this.areaStatusWorker === null) {
      const that = this;
      this.areaStatusWorker = setInterval(() => {
        that.areaStatusCheckTick();
      }, 5000);
    }
  }

  private areaStatusCheckTick() {
    for (const iItem of this.areaStatusesInProgress) {
      const system = this.systems.getSystem(iItem.systemId);
      if (system === undefined) {
        this.l.log('sistema daugiau neegzistuoja. salinam srities irasa.', this.tag);
        this.areaStatusesInProgress = this.areaStatusesInProgress.filter((i) => i.systemId !== iItem.systemId);
        return; // Neinam per kitus. Po 5 sekundžių vėl gįšim ir patikrinsim juos.
      }
      const area = system.areas.find((a) => a.id === iItem.areaId);
      if (area === undefined) {
        this.l.log('Sritis daugiau neegzistuoja. salinam srities irasa.', this.tag);
        this.areaStatusesInProgress = this.areaStatusesInProgress.filter((i) => i.areaId !== iItem.areaId);
        return; // Neinam per kitus. Po 5 sekundžių vėl gįšim ir patikrinsim juos.
      }
      if (area.status !== 5) {
        this.l.log('srities busena jau nebe "changing", galima pasalinti.', this.tag);
        this.areaStatusesInProgress = this.areaStatusesInProgress.filter((i) => i.areaId !== iItem.areaId);
        return;
      }

      const now = new Date();
      if (now.getTime() - iItem.statusTime.getTime() >= 120000) {
        this.l.log('srities busena nepasikeite per nustatyta laika', this.tag);
        this.l.log('ERROR ^^', this.tag);
        area.status = 0;
        this.areaStatusesInProgress = this.areaStatusesInProgress.filter((i) => i.areaId !== iItem.areaId);
        // this.toaster.postError(this.ls.get('systems.errors.areaStatusTimeout'));
        return; // Neinam per kitus. Po 5 sekundžių vėl gįšim ir patikrinsim juos.
      }
    }
  }

  public saveNewStatus(stateFromBackend: number) {
    const system = this.systems.getSystem(this.busyArea.systemId);
    if (system === undefined) {
      this.busyArea = null;
      this.r.enableRefresher();
      return;
    }
    const area = system.areas.find((a) => a.id === this.busyArea.areaId);
    if (area === undefined) {
      this.busyArea = null;
      this.r.enableRefresher();
      return;
    }
    if (!system.directControl) {
      area.status = stateFromBackend;
    } else {
      area.status = this.busyArea.newStatus;
    }
    if (this.busyArea.oldRememberPin !== this.busyArea.newRememberPin) {
      area.showKeypad = !this.busyArea.newRememberPin;
    }
    if (this.systems.activeSystem.id === this.busyArea.systemId) {
      this.systems.saveActiveSystem();
    }
    this.busyArea = null;
    this.r.enableRefresher();
  }

  public revertAreaStatus() {
    const system = this.systems.getSystem(this.busyArea.systemId);
    if (system === undefined) {
      this.busyArea = null;
      this.r.enableRefresher();
      return;
    }
    const area = system.areas.find((a) => a.id === this.busyArea.areaId);
    if (area === undefined) {
      this.busyArea = null;
      this.r.enableRefresher();
      return;
    }
    area.status = this.busyArea.oldStatus;
    area.showKeypad = !this.busyArea.oldRememberPin;
    if (this.systems.activeSystem.id === this.busyArea.systemId) {
      this.systems.saveActiveSystem();
    }
    this.busyArea = null;
    this.r.enableRefresher();
  }

  public isBusy(): boolean {
    return !this.controlsEnabled || this.busyArea !== null;
  }

  public getIconByAreaStatus(status: number, alarmed: boolean) {
    if (status === 1) {
      return 'assets/images/disarm.svg';
    } else if (status === 2 || status === 3 || status === 4) {
      if (alarmed) {
        return 'assets/images/area_alarmed.svg';
      } else {
        return 'assets/images/arm.svg';
      }
    }

    return '';
  }

  public getIconByAreaStatusEx(area: TAreaData) {
    if (area.status === 1) {
      if (area.alarmed) {
        return 'assets/images/disarm_alarm.svg';
      } else {
        return 'assets/images/disarm.svg';
      }
    } else if (area.status === 2) {
      if (area.alarmed) {
        return 'assets/images/arm_alarm.svg';
      } else {
        return 'assets/images/arm.svg';
      }
    } else if (area.status === 3) {
      if (area.alarmed) {
        return 'assets/images/stay_alarm.svg';
      } else {
        return 'assets/images/stay.svg';
      }
    } else if (area.status === 4) {
      if (area.alarmed) {
        return 'assets/images/sleep_alarm.svg';
      } else {
        return 'assets/images/sleep.svg';
      }
    }

    return '';
  }

  public getDescriptionForStatus(status: number, alarmed: boolean, area?: TAreaData) {
    if (area === undefined || status === area.status) {
      if (status === 0) {
        return this.ls.get('systems.statuses.area.unknown');
      } else if (status === 1) {
        return this.ls.get('systems.statuses.area.off');
      } else if (alarmed) {
        return this.ls.get('systems.statuses.area.alarm');
      } else if (status === 2) {
        return this.ls.get('systems.statuses.area.arm');
      } else if (status === 3) {
        return this.ls.get('systems.statuses.area.stay');
      } else if (status === 4 && !this.systems.activeSystemHelper.supports.away()) {
        return this.ls.get('systems.statuses.area.sleep');
      } else if (status === 4) {
        return this.ls.get('systems.statuses.area.away');
      } else if (status === 5) {
        return this.ls.get('systems.statuses.area.armInProgress');
      }
    } else {
      if (area.status === 5 && this.busyArea !== null && this.busyArea.newStatus === status) {
        return '';
      }
      if (status === 0) {
        return this.ls.get('systems.statuses.area.unknown');
      } else if (status === 1) {
        return this.ls.get('systems.statuses.area.setOff');
      } else if (status === 2) {
        return this.ls.get('systems.statuses.area.setArm');
      } else if (status === 3) {
        return this.ls.get('systems.statuses.area.setStay');
      } else if (status === 4 && !this.systems.activeSystemHelper.supports.away()) {
        return this.ls.get('systems.statuses.area.setSleep');
      } else if (status === 4) {
        return this.ls.get('systems.statuses.area.setAway');
      }
    }
  }

  public shouldShowLoading(status: number, area: TAreaData) {
    if (this.busyArea === null) {
      return false;
    }
    if (this.busyArea.areaId !== area.id) {
      return false;
    }

    return this.busyArea.newStatus === status && area.status === 5;
  }

  public loadingIsShown(area: TAreaData) {
    if (this.busyArea === null) {
      return false;
    }
    if (this.busyArea.areaId !== area.id) {
      return false;
    }

    return this.busyArea.newStatus !== 0 && area.status === 5;
  }

  public keypadCancelled() {
    if (this.busyArea === null) {
      return;
    }
    this.l.log('PIN lange paspausta Cancel', this.tag);
    this.busyArea = null;
    this.r.enableRefresher();
    this.alarmedActionInProgress = false;
  }

  public pinConfirmed(pin: string, remember: boolean) {
    if (this.busyArea === null) {
      return;
    }
    this.l.log('Pin patvirtintas', this.tag);
    this.busyArea.pinToUse = pin;
    this.busyArea.newRememberPin = remember;
    this.busyArea.showPinKeypad = false;
    this.performStatusChange();
  }

  public performStatusChange() {
    if (this.busyArea === null) {
      return;
    }
    const system = this.systems.getSystem(this.busyArea.systemId);
    if (system === undefined) {
      this.busyArea = null;
      this.r.enableRefresher();
      this.alarmedActionInProgress = false;
      return;
    }
    const area = system.areas.find((a) => a.id === this.busyArea.areaId);
    if (area === undefined) {
      this.busyArea = null;
      this.r.enableRefresher();
      this.alarmedActionInProgress = false;
      return;
    }
    area.status = 5;
    const that = this;
    this.addAreaStatusInProgress(this.busyArea.systemId, this.busyArea.areaId, new Date().getTime() / 1000);
    this.changeAreaStateTo();
  }

  public zoneBypassCancelled() {
    if (this.busyArea === null) {
      return;
    }
    this.l.log('Zonų bypass lange paspausta Cancel', this.tag);
    this.revertAreaStatus();
    this.alarmedActionInProgress = false;
  }

  public zoneBypassConfirmed() {
    if (this.busyArea === null) {
      return;
    }
    this.l.log('Zonų bypass bandomas vykdyti', this.tag);
    this.busyArea.showZoneBypass = false;
    const that = this;
    const zones = [];
    for (const iZone of this.busyArea.zonesToBypass) {
      if (!iZone.isChecked) {
        continue;
      }
      zones.push(iZone.number);
    }
    this.zs.performMultiBypass(
      this.busyArea.systemId,
      this.busyArea.areaId,
      zones,
      this.busyArea.pinToUse,
      this.busyArea.newRememberPin,
      () => {
        that.performStatusChange();
      },
      () => {
        that.revertAreaStatus();
        that.alarmedActionInProgress = false;
      }
    );
  }

  /**
   * Prideda arba atnaujina srities duomenis susijusius su Take Action funkcionalumu. Taip pat paleidžiamas taimeris.
   *
   * @param area Area iš web socket arba TAreaData
   */
  public addOrUpdateAreaForTakeAction(area: any | TAreaData) {
    let found = this.takeActionAreas.find((ta) => ta.id === area.id);
    const originatesFromWebsocket = area.hasOwnProperty('alarm_time');
    if (found === undefined) {
      this.l.log('Sukuriam "Take action" duomenis sriciai ' + area.id);
      found = {
        id: area.id,
        alarmTime: originatesFromWebsocket ? area.alarm_time : area.alarmTime,
        alarmed: originatesFromWebsocket ? area.alarm_in_memory : area.alarmed,
        hideTakeActionTimer: null,
      };
      this.takeActionAreas.push(found);
    } else {
      this.l.log('Atnaujinam "Take action" duomenis sriciai ' + area.id);
      found.alarmTime = originatesFromWebsocket ? area.alarm_time : area.alarmTime;
      found.alarmed = originatesFromWebsocket ? area.alarm_in_memory : area.alarmed;
      if (found.hideTakeActionTimer !== null) {
        clearTimeout(found.hideTakeActionTimer);
        found.hideTakeActionTimer = null;
      }
    }
    this.checkIfAlarmButtonShouldBeShown(found);
  }

  private checkIfAlarmButtonShouldBeShown(takeActionArea: any) {
    if (takeActionArea.alarmTime === 0) {
      this.l.log('"Take action" nerodomas ' + takeActionArea.id + ', nes nera duomenu apie aliarma.');
      this.onTakeActionButtonVisibilityChangedSource.next({
        id: takeActionArea.id,
        visible: false,
      });
      return;
    }
    const nowTime = new Date().getTime();
    const alarmTime = takeActionArea.alarmTime * 1000;
    const showAlarmButton =
      takeActionArea.alarmed &&
      (this.systems.activeSystemHelper.supports.silenceSiren() || this.systems.activeSystemHelper.supports.cancelAlarm()) &&
      nowTime - alarmTime <= GeneralSettings.takeActionButtonDuration * 1000;
    if (!showAlarmButton) {
      this.l.log('"Take action" ' + takeActionArea.id + ' neturi buti rodomas.');
      this.onTakeActionButtonVisibilityChangedSource.next({
        id: takeActionArea.id,
        visible: false,
      });
      this.stopAlarmButtonTimer(takeActionArea);
    } else {
      this.l.log('Rodom "Take action" ' + takeActionArea.id + ' ir paleidziam taimeri paslepimui.');
      this.onTakeActionButtonVisibilityChangedSource.next({
        id: takeActionArea.id,
        visible: true,
      });
      this.stopAlarmButtonTimer(takeActionArea);
      const timeLeft = GeneralSettings.takeActionButtonDuration * 1000 - (nowTime - alarmTime);
      const that = this;
      takeActionArea.hideTakeActionTimer = setTimeout(() => {
        that.l.log('Atejo laikas paslepti "Take action" ' + takeActionArea.id + ' mygtuka');
        that.onTakeActionButtonVisibilityChangedSource.next({
          id: takeActionArea.id,
          visible: false,
        });
        that.stopAlarmButtonTimer(takeActionArea);
      }, timeLeft);
    }
    this.l.log('Praėjo laiko nuo paskutinės būsenos ' + takeActionArea.id + ': ' + (nowTime - alarmTime));
  }

  private stopAlarmButtonTimer(takeActionArea: any) {
    if (takeActionArea.hideTakeActionTimer !== null) {
      clearTimeout(takeActionArea.hideTakeActionTimer);
      takeActionArea.hideTakeActionTimer = null;
    }
  }
}
