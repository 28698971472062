<div
  [ngClass]="['formGroup', inputType === 'checkbox' ? (ngType === 'switch' ? 'hasSwitch' : 'hasCheckbox') : '', hasError ? 'hasError' : '']"
  (click)="$event.preventDefault(); focus()"
>
  <label
    [ngClass]="[
      'label',
      isEmpty ? 'empty' : '',
      inputType === 'textarea' ? 'labelTextarea' : '',
      inputType === 'checkbox' ? (ngType === 'switch' ? 'labelSwitch' : 'labelCheckbox') : ''
    ]"
    [for]="formId"
    >{{ label }}</label
  >
  <textarea
    *ngIf="inputType === 'textarea'; else input"
    [id]="formId"
    [name]="formId"
    [value]="inputProxy"
    class="textarea"
    [disabled]="disabled"
    (input)="inputProxy = $event.target.value"
    (focus)="Focus.emit($event)"
  ></textarea>
  <ng-template #input>
    <input
      #inputElement
      [id]="formId"
      [name]="formId"
      [(ngModel)]="inputProxy"
      [ngClass]="['input', inputType === 'checkbox' ? (ngType === 'switch' ? 'switch' : 'checkbox') : '']"
      [type]="inputType"
      [max]="max"
      [min]="min"
      [step]="step"
      [required]="required"
      [disabled]="disabled"
      [checked]="inputProxy"
      [autocomplete]="autocomplete"
      (change)="onChange($event)"
      (focus)="Focus.emit($event)"
    />
  </ng-template>

  <ng-content></ng-content>
</div>
