<div class="grid add-system-area-grid">
    <div class="group-background" id='asa-details-background'></div>
    <app-text-edit id='asa-details-area-number' [inputType]="'number'" [maxCharacters]='3' [value]='localArea.queue_no' (enterClicked)='onNext()' (valueChanged)='localArea.queue_no = $event'>{{trans('systems.labels.enterAreaNumber')}}</app-text-edit>
    <app-text-edit id='asa-details-area-name' [value]='localArea.name'(enterClicked)='onNext()' (valueChanged)='localArea.name = $event' [doFocus]='true' >{{trans('systems.labels.areaName')}}</app-text-edit>
</div>
<div class="separator16"></div>
<div class="white_round padding_sides16">
    <app-name-value [nameText]="trans('systems.labels.controlWithCloud')" [showUnderline]="showPgmData">
        <app-toggle-button (toggled)='showPgmData = $event.checked' [initialState]='showPgmData' [buttonId]="'arm-with-pgm'"></app-toggle-button>
    </app-name-value>
    <app-button-double-line-arrow *ngIf='showPgmData'
        [firstLine]="trans('systems.labels.assignOutput')"
        [secondLine]="areaHasPgm ? localPgm.name : trans('systems.labels.notAssigned')"
        (click)='selectPgm()'
        [withBorder]="showPgmData && areaHasPgm"
    ></app-button-double-line-arrow>
    <div *ngIf='showPgmData && areaHasPgm' class="padding_t16 padding_b16">
        <app-radio-button *ngIf='showPgmData && areaHasPgm' id='asa-pgm-control-pulse' (valueChanged)="pgmModeSelect('pulse')" [checkValue]="localPgm.type === 'pulse'" [groupName]="'pgm-type'">{{trans('systems.labels.pulse')}}</app-radio-button>
        <app-text-edit *ngIf='showPgmData && areaHasPgm' (enterClicked)='onNext()' [inputType]="'number'" [maxCharacters]='3' [value]="localPgm.pulseTime" (valueChanged)="localPgm.pulseTime = $event">{{trans('systems.labels.pulse_interval')}}</app-text-edit>
        <app-radio-button *ngIf='showPgmData && areaHasPgm' id='asa-pgm-control-level' [checkValue]="localPgm.type === 'level'" (valueChanged)="pgmModeSelect('level')" [groupName]="'pgm-type'">{{trans('systems.labels.level')}}</app-radio-button>
    </div>
</div>