import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { TZoneData } from 'src/app/models/zone-data';
import { Router, ActivatedRoute } from '@angular/router';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TEditableComponent } from 'src/app/models/editable-component';

@Component({
  selector: 'app-zone-list',
  templateUrl: './zone-list.component.html',
  styleUrls: ['./zone-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ZoneListComponent extends LanguageAware implements OnInit, AfterViewInit {
  public windowName = 'zone-list';

  constructor(cdRef: ChangeDetectorRef, private router: Router, private es: EditSystemService, private route: ActivatedRoute) {
    super(cdRef);
    const destination = this.route.snapshot.paramMap.get('destination');
    const backUrl = destination === null ? '/system-configuration' : '/zone-status-list';
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.zones'),
      backUrl,
    });
    this.background.setGray();
    this.es.endComponentEdit(TEditableComponent.Zone);
  }

  ngOnInit(): void {}

  ngAfterViewInit() {}

  public getZones(): TZoneData[] {
    return this.systems.activeSystem.zones.filter((z) => z.enabled && z.visible);
  }

  public getAssignedAreas(zoneNumber: number): string {
    let result = '';
    const zone = this.systems.activeSystem.zones.find((z) => z.queue_no === zoneNumber);
    const areas = zone.areas.sort();
    for (const iArea of areas) {
      const area = this.systems.activeSystem.areas.find((a) => a.queue_no === iArea);
      if (area === undefined) {
        continue;
      }
      if (result !== '') {
        result += ', ';
      }
      result += area.name;
    }
    return result;
  }

  public onZoneDelete(event, value: number) {
    event.stopPropagation();
    this.deleteZone(value);
    const zoneList = document.querySelector('#szl-zone-list') as HTMLElement;
    this.helper.shouldShowFakeElement(zoneList.offsetHeight, this.windowName);
  }

  private deleteZone(zoneNumber: number) {
    const zone = this.systems.activeSystem.zones.find((z) => z.queue_no === zoneNumber);
    const activeSystemId = this.systems.activeSystem.id;
    if (zone.native) {
      zone.visible = false;
    } else {
      this.systems.activeSystem.zones = this.systems.activeSystem.zones.filter((z) => z.queue_no !== zoneNumber);
    }
    this.systems.setCurrentSystem(this.systems.activeSystem);
    const that = this;
    this.api.post('/delete/zone', { system_id: activeSystemId, zone_no: zone.queue_no }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.revertDeletion(zone, activeSystemId);
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.revertDeletion(zone, activeSystemId);
        that.toaster.postError(this.trans('systems.errors.zoneSaveFailed'));
      }
    );
  }

  private revertDeletion(zone: TZoneData, systemId: number) {
    let systemToUse = this.systems.activeSystem;
    let isActiveSystem = true;
    if (this.systems.activeSystem.id !== systemId) {
      systemToUse = this.systems.getSystem(systemId);
      isActiveSystem = false;
    }
    if (systemToUse === null) {
      return;
    }

    if (zone.native) {
      zone.visible = true;
    } else {
      systemToUse.zones.push(zone);
    }
    if (isActiveSystem) {
      this.systems.setCurrentSystem(this.systems.activeSystem);
    }
  }

  public onAddZone() {
    if (this.systems.activeSystem.zones.find((z) => z.enabled && !z.visible && z.native) !== undefined) {
      this.router.navigate(['/show-zone']);
    } else {
      this.router.navigate(['/system-zone']);
    }
  }

  public onZoneSelect(value: number) {
    const zone = this.systems.activeSystem.zones.find((z) => z.queue_no === value);
    this.es.beginComponentEdit(TEditableComponent.Zone, value, zone);
    this.router.navigate(['/system-zone']);
  }
}
