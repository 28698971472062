import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, NavigationStart, ResolveEnd, ResolveStart, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public isLoading = true;
  private routerEventsSubscribtion = this.router.events.subscribe((event) => {
    if (event instanceof ResolveStart && event.id === 1) {
      this.isLoading = true;
    } else if (event instanceof ResolveEnd) {
      if (event.url !== '/') this.isLoading = false;
    } else if (event instanceof NavigationStart && event.id === 1) {
      this.isLoading = true;
    } else if (event instanceof NavigationEnd) {
      if (event.url !== '/') this.isLoading = false;
    }
  });
  constructor(private router: Router) {}
  ngOnDestroy(): void {
    this.routerEventsSubscribtion.unsubscribe();
  }
}
