<div *ngIf="addingNew">
    <div id="spgm_terminal" class="white_round">
        <app-button-single-line-arrow (click)="selectTerminal()">{{localPgm.id === 0 ? trans('systems.labels.selectTerminal') : localPgm.name}}</app-button-single-line-arrow>
    </div>
    <div class="separator16"></div>
</div>
<div id='spgm-queue' *ngIf="localPgm.queue_no !== 0">
    <app-text-edit [value]="localPgm.queue_no" [isReadOnly]="true">{{trans('systems.labels.outputNumber')}}</app-text-edit>
</div>
<div class="white_round padding16">
    <div id="spgm_name_container">
        <app-text-edit id='spgm-name' [value]="localPgm.name" (valueChanged)="localPgm.name = $event" (enterClicked)="onDone()" [isReadOnly]='!systems.activeSystemHelper.can.edit.outputs()' [maxCharacters]="maxPgmLen">{{trans('systems.labels.pgmName')}}</app-text-edit>
        <div id='spgm-icon' (click)='openIconSelection()'><div [inlineSVG]="is.getPgmIconById(localPgm.icon)"></div></div>
    </div>
    <div class="separator16"></div>
    <app-radio-button id='spgm-control-pulse' [checkValue]="localPgm.type === 'pulse'"  (valueChanged)="localPgm.type ='pulse'" [groupName]="'pgm-type'">{{trans('systems.labels.pulse')}}</app-radio-button>
    <app-text-edit id='spgm-control-seconds' (enterClicked)='onDone()' [inputType]="'number'" [maxCharacters]='3' [value]="localPgm.pulseTime" (valueChanged)="localPgm.pulseTime = $event" [isReadOnly]='!systems.activeSystemHelper.can.edit.outputs()'>{{trans('systems.labels.pulse_interval')}}</app-text-edit>
    <app-radio-button id='spgm-control-level' [checkValue]="localPgm.type === 'level'" (valueChanged)="localPgm.type = 'level'" [groupName]="'pgm-type'">{{trans('systems.labels.level')}}</app-radio-button>    
</div>