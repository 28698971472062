import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { IconsService } from 'src/app/services/icons.service';

@Component({
  selector: 'app-pgm-icon-select',
  templateUrl: './pgm-icon-select.component.html',
  styleUrls: ['./pgm-icon-select.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PgmIconSelectComponent extends LanguageAware implements OnInit {
  public currentIcon = 0;
  public availableIcons = [];

  constructor(cdRef: ChangeDetectorRef, private es: EditSystemService, private router: Router, is: IconsService) {
    super(cdRef);
    this.tag = 'PgmIcon';
    this.background.setGray();
    const pgm = this.es.getEditableComponent(TEditableComponent.Output);
    if (pgm === null) {
      this.log('Bandyta įeiti į ikonų langą ne iš PGM lango.');
      this.router.navigate(['/system-configuration']);
      return;
    }
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.pickIconSet'),
      backUrl: '/system-pgm',
    });
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.footerBar.onButton1Click = () => {
      this.router.navigate(['/system-pgm']);
    };
    this.currentIcon = pgm.icon;
    this.availableIcons = is.getAllPgmIcons();
  }

  ngOnInit(): void {}

  public onIconSelected(iconId: number) {
    const pgm = this.es.getEditableComponent(TEditableComponent.Output);
    if (pgm === null) {
      this.log('Neaišku kodėl, bet pasirinkus ikoną PGM jau neegzistuoja');
    } else {
      pgm.icon = iconId;
    }
    this.router.navigate(['/system-pgm']);
  }
}
