import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-cp-filtered-systems',
  templateUrl: './cp-filtered-systems.component.html',
  styleUrls: ['./cp-filtered-systems.component.scss'],
})
export class CpFilteredSystemsComponent extends LanguageAware implements OnInit {
  @ViewChild('searchPopup') systemFind;

  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    this.headerBar.showHeader({
      showLogo: true,
      backUrl: this.g.getHomeUrl(),
    });
    this.background.setGray();
    const that = this;
    this.headerBar.onBackClicked = () => {
      that.systems.filteredSystems = [];
    };
  }

  ngOnInit(): void {}

  public openFind() {
    this.systemFind.show();
  }

  public close() {
    this.systems.filteredSystems = [];
    this.router.navigate([this.g.getHomeUrl()]);
  }
}
