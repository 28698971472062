<div class="white_round padding16">
    <app-name-value [nameText]="trans('settings.grg.label.enabled')">
        <app-toggle-button [buttonId]="'cppb'" [initialState]="us.currentUser.panicSettings.grg_enabled" (toggled)="us.currentUser.panicSettings.grg_enabled = $event.checked"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('settings.grg.label.host')" (clicked)="changeIp()">{{us.currentUser.panicSettings.grg_host}}</app-name-value>
    <app-name-value [nameText]="trans('settings.grg.label.port')" (clicked)="changePort()">{{us.currentUser.panicSettings.grg_port}}</app-name-value>
    <app-name-value [nameText]="trans('settings.grg.label.classificator')" (clicked)="changeClassificator()">{{classificatorText}}</app-name-value>
    <app-name-value [nameText]="trans('settings.grg.label.code')" (clicked)="changeCode()">{{codeText}}</app-name-value>
    <app-name-value [nameText]="trans('settings.grg.label.zone')" (clicked)="changeZone()">{{us.currentUser.panicSettings.grg_zone}}</app-name-value>
</div>
<app-popup></app-popup>