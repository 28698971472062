import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PopupType } from 'src/app/models/popup-type';
import { SlideoutItem } from 'src/app/models/slideout-item';
import { UserRole } from 'src/app/models/user-role';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-cp-edit-reaction',
  templateUrl: './cp-edit-reaction.component.html',
  styleUrls: ['./cp-edit-reaction.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CpEditReactionComponent extends LanguageAware implements OnInit {
  public reactionItem = null;
  private originalAssignements = [];
  public groupText = '';
  private backUrl = '';
  public fileToUploadUrl: any = null;
  private loadedIconSize = null;
  private fileToUpload: File | null = null;
  public iconChanged = false;

  constructor(cdRef: ChangeDetectorRef, private router: Router, ar: ActivatedRoute, private pp: PopupService, private es: EditSystemService, private sanitizer: DomSanitizer) {
    super(cdRef);
    this.reactionItem = this.es.getEditableComponent(TEditableComponent.Reaction);
    const groupItem = this.es.getEditableComponent(TEditableComponent.ReactionGroup);
    this.backUrl = groupItem === null ? this.g.getHomeUrl() + '/edit-reactions' : this.g.getHomeUrl() + '/edit-group';
    if (this.reactionItem === null) {
      this.router.navigate([this.backUrl]);
      return;
    }
    this.groupText = this.trans('reactionNames.reaction_groups.other');
    if (this.reactionItem.group_id !== 0) {
      const group = this.us.currentUser.reactionGroups.find((gr) => gr.id === this.reactionItem.group_id);
      this.groupText =
        group !== undefined ? (group.default_name ? this.trans('reactionNames.reaction_groups.' + group.name) : group.name) : this.trans('reactionNames.reaction_groups.other');
    }

    this.headerBar.showHeader({
      headerText: this.trans('events.settings.labels.reaction'),
      backUrl: this.backUrl,
      actionButtonText: this.reactionItem.id === 0 ? undefined : this.trans('general.delete'),
    });
    const that = this;
    this.headerBar.onActionButtonClicked = () => {
      that.doDelete();
      that.router.navigate([this.backUrl]);
    };
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    this.footerBar.onButton1Click = () => {
      that.revert();
      that.es.endComponentEdit(TEditableComponent.Reaction);
      that.router.navigate([this.backUrl]);
    };
    this.footerBar.onButton2Click = () => {
      that.doSave();
    };
    this.headerBar.onBackClicked = () => {
      that.revert();
      that.es.endComponentEdit(TEditableComponent.Reaction);
    };
    this.background.setGray();
    this.loadDescriptions();
  }

  ngOnInit(): void {}

  private loadDescriptions() {
    if (this.us.eventDescriptions.length > 0) {
      if (this.reactionItem.id !== 0) {
        this.populateAssignements();
      }
      return;
    }
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.api.post('/get-event-descriptions', {}, true).subscribe(
      (result) => {
        if (result.success) {
          that.us.eventDescriptions = result.list;
          if (that.reactionItem.id !== 0) {
            that.populateAssignements();
          }
        } else {
          that.toaster.postError(result.error);
        }
        that.miniStatus.hide();
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }

  private populateAssignements() {
    this.originalAssignements = [];
    for (const iDesc of this.us.eventDescriptions) {
      this.originalAssignements.push({
        descId: iDesc.id,
        reactionId: iDesc.reaction_id,
      });
    }
  }

  private doDelete() {
    const that = this;
    const reactionId = this.es.getId(TEditableComponent.Reaction);
    this.us.currentUser.reactions.splice(
      this.us.currentUser.reactions.findIndex((r) => r.id === reactionId),
      1
    );
    this.api.post('/delete/reaction', { reaction_id: reactionId }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.toaster.postError(result.error);
          that.revert();
        } else {
          const descToUpdate = this.us.eventDescriptions.filter((ed) => ed.reaction_id === reactionId);
          for (const iDesc of descToUpdate) {
            iDesc.reaction_id = 0;
          }
        }
        that.es.endComponentEdit(TEditableComponent.Reaction);
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.revert();
        that.es.endComponentEdit(TEditableComponent.Reaction);
      }
    );
  }

  private doSave() {
    if (this.iconChanged) {
      if (this.loadedIconSize !== null) {
        if (this.loadedIconSize.width !== this.loadedIconSize.height) {
          this.toaster.postError(this.trans('settings.errors.incorrectImageSize'));
          return false;
        }
      }
      if (this.miniStatus.isVisible()) {
        this.miniStatus.flash();
        return;
      }
      this.miniStatus.show(this.trans('general.pleaseWait'));
    }

    const that = this;
    if (this.reactionItem.id === 0) {
      this.us.currentUser.reactions.push(that.reactionItem);
    }
    const reactionId = this.es.getId(TEditableComponent.Reaction);
    const assignedDescriptions = this.us.eventDescriptions.filter((ed) => ed.reaction_id === reactionId).map((edd) => edd.id);
    this.reactionItem.assignedDescriptions = assignedDescriptions;
    this.api.post('/edit/reaction', that.reactionItem, true).subscribe(
      (result) => {
        if (result.success) {
          if (reactionId === 0) {
            const reactionToUpdate = that.us.currentUser.reactions.find((r) => r.id === 0);
            reactionToUpdate.id = result.id;
            const descToUpdate = that.us.eventDescriptions.filter((ed) => ed.reaction_id === 0);
            for (const iDesc of descToUpdate) {
              iDesc.reaction_id = result.id;
            }
          }
          if (that.iconChanged) {
            that.uploadIcon();
          } else {
            that.es.endComponentEdit(TEditableComponent.Reaction);
          }
        } else {
          that.toaster.postError(result.error);
          that.revert();
          this.miniStatus.hide();
          that.es.endComponentEdit(TEditableComponent.Reaction);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
        that.revert();
        that.es.endComponentEdit(TEditableComponent.Reaction);
      }
    );
    if (!this.iconChanged) {
      that.router.navigate([this.backUrl]);
    }
  }

  private uploadIcon() {
    const that = this;
    const headers = this.api.getAuthorizationHeader();
    const formData = new FormData();
    formData.append('id', this.reactionItem.id);
    if (this.iconChanged) {
      headers.append('Content-type', 'multipart/form-data');
      formData.append('image', this.fileToUpload, this.fileToUpload.name);
    }
    this.api
      .httpSender()
      .post(this.api.getUrl('/reaction/icon'), formData, { headers })
      .subscribe(
        (result) => {
          if ((result as any).success) {
            that.reactionItem.image = that.helper.getBaseNoPath() + '/' + (result as any).icon;
            that.us.saveCurrentUser();
            that.es.endComponentEdit(TEditableComponent.Reaction);
            that.router.navigate([this.backUrl]);
          }
          that.miniStatus.hide();
        },
        (error) => {
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
          that.miniStatus.hide();
        }
      );
  }

  private revert() {
    for (const iAssignement of this.originalAssignements) {
      const found = this.us.eventDescriptions.find((ed) => ed.id === iAssignement.descId);
      if (found) {
        found.reaction_id = iAssignement.reactionId;
      }
    }
    const reactionId = this.es.getId(TEditableComponent.Reaction);
    if (reactionId !== 0) {
      const foundReaction = this.us.currentUser.reactions.find((r) => r.id === reactionId);
      if (foundReaction === undefined) {
        this.us.currentUser.reactions.push(this.es.getComponentBeforeModification(TEditableComponent.Reaction));
      } else {
        const original = this.es.getComponentBeforeModification(TEditableComponent.Reaction);
        foundReaction.name = original.name;
        foundReaction.default_name = original.default_name;
        foundReaction.custom_sound = original.custom_sound;
        foundReaction.active = original.active;
        foundReaction.group_id = original.group_id;
      }
    } else {
      const indexFound = this.us.currentUser.reactions.findIndex((r) => r.id === reactionId);
      if (indexFound === -1) {
        return;
      }
      this.us.currentUser.reactions.splice(indexFound, 1);
    }
  }

  public eventChecked(ed: any) {
    if (ed.reaction_id === this.reactionItem.id) {
      ed.reaction_id = 0;
    } else {
      ed.reaction_id = this.reactionItem.id;
    }
  }

  public openEvent(event: any) {
    this.es.beginComponentEdit(TEditableComponent.EventDescription, event.id, event);
    this.router.navigate([this.g.getHomeUrl() + '/edit-event']);
  }

  public changeGroup() {
    const that = this;
    const groups: SlideoutItem[] = [
      {
        text: this.trans('reactionNames.reaction_groups.other'),
        value: 0,
      },
    ];
    for (const iGroup of this.us.currentUser.reactionGroups) {
      groups.push({
        text: iGroup.default_name ? this.trans('reactionNames.reaction_groups.' + iGroup.name) : iGroup.name,
        value: iGroup.id,
      });
    }
    this.pp.showSlideout(
      {
        headerText: this.trans('settings.reactions.labels.group'),
        items: groups,
        onSubmit: (res) => {
          that.reactionItem.group_id = res.value;
          that.groupText = res.text;
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  public addEvent() {
    this.es.beginComponentEdit(TEditableComponent.EventDescription, 0, {
      default_name: '',
      id: 0,
      qualifier: 'E',
      event_code: '000',
      reaction_id: this.reactionItem.id,
      active: false,
      company_id: this.us.currentUser.role === UserRole.Company ? this.us.currentUser.id : 0,
      area_event: true,
    });
    this.router.navigate([this.g.getHomeUrl() + '/edit-event']);
  }

  public setName(value: string) {
    this.reactionItem.name = value;
    if (this.us.currentUser.role === UserRole.Company) {
      this.reactionItem.default_name = false;
    }
  }

  public handleNewFile(fileArray: FileList) {
    const file = fileArray.item(0);
    const mimeType = file.type;
    if (!mimeType.startsWith('image/svg')) {
      this.toaster.postError(this.trans('settings.errors.notAnSvg'));
      return;
    }
    const that = this;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = ($event) => {
      that.fileToUploadUrl = this.sanitizer.bypassSecurityTrustUrl(reader.result as string);
    };
    this.fileToUpload = file;
    this.iconChanged = true;
  }

  public imageLoaded(img) {
    this.loadedIconSize = {
      width: img.naturalWidth,
      height: img.naturalHeight,
    };
  }
}
