<div class="grid header-grid {{ headerService.isHeaderWhite ? 'header-grid-white' : '' }}">
  <div
    *ngIf="headerService.textIsVisible() && !ms.isVisible() && !ts.isVisible()"
    [ngClass]="{ 'header-text': true, 'header-dark': headerService.headerDarkMode(), 'header-bright': !headerService.headerDarkMode() }"
  >
    {{ headerService.getText() }}
  </div>
  <div *ngIf="!ms.isVisible() && !ts.isVisible() && !headerService.dotMenuIsVisible()" id="header_action_buttons">
    <div id="hab_inner">
      <div *ngIf="headerService.actionButtonIsVisible()" id="hab_textual" (click)="onAction()">
        <span>{{ headerService.getActionButtonText() }}</span>
      </div>
      <div *ngIf="headerService.findButtonIsVisible()" id="hab_find" (click)="onAction()">
        <span
          ><svg xmlns="http://www.w3.org/2000/svg" width="17.999" height="18.002" viewBox="0 0 17.999 18.002">
            <path
              d="M17.754,15.564l-3.505-3.505a.843.843,0,0,0-.6-.246h-.573a7.309,7.309,0,1,0-1.266,1.266v.573a.843.843,0,0,0,.246.6l3.505,3.505a.84.84,0,0,0,1.192,0l.995-.995a.848.848,0,0,0,0-1.2ZM7.313,11.813a4.5,4.5,0,1,1,4.5-4.5A4.5,4.5,0,0,1,7.313,11.813Z"
              fill="#666"
            /></svg
        ></span>
      </div>
      <div *ngIf="headerService.plusButtonIsVisible()" id="hab_plus" (click)="onAction()">
        <span
          ><svg xmlns="http://www.w3.org/2000/svg" width="15.851" height="15.851" viewBox="0 0 15.851 15.851">
            <g transform="translate(-6.5 -6.5)">
              <path d="M18,7.5V21.351" transform="translate(-3.575)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
              <path d="M7.5,18H21.351" transform="translate(0 -3.575)" fill="none" stroke="#666" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" />
            </g></svg
        ></span>
      </div>
      <div *ngIf="headerService.filterButtonIsVisible()" id="hab_filter" (click)="onAction()">
        <span
          ><svg xmlns="http://www.w3.org/2000/svg" width="15.199" height="15.199" viewBox="0 0 15.199 15.199">
            <path
              d="M14.486,0H.713a.713.713,0,0,0-.5,1.216L5.7,6.707v6.117a.713.713,0,0,0,.3.584l2.375,1.662A.713.713,0,0,0,9.5,14.486V6.707l5.49-5.491A.713.713,0,0,0,14.486,0Z"
              transform="translate(0)"
              fill="#666"
            /></svg
        ></span>
      </div>
      <div *ngIf="headerService.settingsButtonIsVisible()" id="hab_settings" (click)="onAction()">
        <span
          ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.448 21.448">
            <path
              d="M20.344,8.341H18.337a.77.77,0,0,1-.545-1.315l1.419-1.418a1.105,1.105,0,0,0,0-1.562L17.4,2.237a1.131,1.131,0,0,0-1.562,0L14.422,3.655a.745.745,0,0,1-.537.228.8.8,0,0,1-.542-.214.744.744,0,0,1-.236-.558V1.1A1.105,1.105,0,0,0,12,0H9.445a1.105,1.105,0,0,0-1.1,1.1V3.111a.768.768,0,0,1-.779.773.747.747,0,0,1-.537-.228L5.607,2.237a1.131,1.131,0,0,0-1.562,0L2.237,4.045a1.105,1.105,0,0,0,0,1.562L3.655,7.026a.77.77,0,0,1-.545,1.315H1.1A1.105,1.105,0,0,0,0,9.445V12a1.106,1.106,0,0,0,1.1,1.1H3.111a.77.77,0,0,1,.545,1.315L2.237,15.84a1.105,1.105,0,0,0,0,1.562l1.808,1.808a1.13,1.13,0,0,0,1.562,0l1.418-1.418a.745.745,0,0,1,.537-.228.768.768,0,0,1,.778.773v2.006a1.105,1.105,0,0,0,1.1,1.1H12a1.106,1.106,0,0,0,1.1-1.1V18.337a.768.768,0,0,1,.778-.773.748.748,0,0,1,.537.228l1.418,1.418a1.131,1.131,0,0,0,1.562,0L19.211,17.4a1.105,1.105,0,0,0,0-1.562l-1.419-1.418a.77.77,0,0,1,.545-1.315h2.007a1.106,1.106,0,0,0,1.1-1.1V9.445A1.105,1.105,0,0,0,20.344,8.341ZM13.5,10.724a2.78,2.78,0,1,1-2.78-2.78A2.784,2.784,0,0,1,13.5,10.724Z"
              fill="#666"
            /></svg
        ></span>
      </div>
      <div *ngIf="headerService.xButtonIsVisible()" id="hab_x" (click)="onXAction()">
        <span
          ><svg xmlns="http://www.w3.org/2000/svg" width="15.199" height="15.199" viewBox="0 0 15.199 15.199">
            <path
              d="M22.7,9.031,21.168,7.5,15.1,13.569,9.031,7.5,7.5,9.031,13.569,15.1,7.5,21.168,9.031,22.7,15.1,16.63,21.168,22.7,22.7,21.168,16.63,15.1Z"
              transform="translate(-7.5 -7.5)"
              fill="#666"
            /></svg
        ></span>
      </div>
      <div *ngIf="headerService.installerAssistButtonIsVisible()" id="hab_instassist" (click)="onAction()">
        <span
          ><svg xmlns="http://www.w3.org/2000/svg" width="18.152" height="18.152" viewBox="0 0 18.152 18.152">
            <path
              d="M15.884,0,11.345,3.4V5.6L8.4,8.548a3.491,3.491,0,0,1,1.2,1.2l2.944-2.944h2.2l3.4-4.538ZM4.538,9.877.387,14.028a1.322,1.322,0,0,0,0,1.869l1.868,1.868a1.321,1.321,0,0,0,1.869,0l4.151-4.151A2.643,2.643,0,0,0,4.538,9.877Z"
              transform="translate(-0.001)"
              fill="#fff"
            /></svg
        ></span>
      </div>
    </div>
  </div>
  <div *ngIf="ms.isVisible()" [@flashMinistatus]="ms.getFlashState()" id="mini-status-background"></div>
  <div *ngIf="ms.isVisible() && !ts.isVisible() && !ps.isApple()" id="mini-status-icon-container">
    <div id="mini-status-icon" [@spin]="spinState" (@spin.done)="doRepeat($event)" [inlineSVG]="'assets/images/emblem.svg'"></div>
  </div>
  <div *ngIf="ms.isVisible() && !ts.isVisible() && ms.timerIsVisible()" id="mini-status-timer" [ngClass]="{ 'mini-status-timer-1': ps.isApple() }">{{ ms.getTime() }}</div>
  <div
    *ngIf="ms.isVisible()"
    id="mini-status-text"
    [ngClass]="{
      'mini-status-text-1': !ps.isApple() && !ms.timerIsVisible(),
      'mini-status-text-2': !ps.isApple() && ms.timerIsVisible(),
      'mini-status-text-3': ps.isApple() && !ms.timerIsVisible(),
      'mini-status-text-4': ps.isApple() && ms.timerIsVisible()
    }"
  >
    {{ ms.getMessage() }}
  </div>
  <app-context-menu
    *ngIf="headerService.dotMenuIsVisible() && !ms.isVisible()"
    id="header-dot-menu"
    [id]="'hd'"
    [ngClass]="{ 'header-dot-menu': true, 'header-dark': headerService.headerDarkMode(), 'header-bright': !headerService.headerDarkMode() }"
  >
    <div *ngFor="let iMenuItem of getDotMenuItems()" class="context-menu-item" (click)="contextClick(iMenuItem.action)">
      <div class="context-menu-item-text">{{ iMenuItem.name }}</div>
    </div>
  </app-context-menu>
</div>
