import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TDeviceUser } from 'src/app/models/device-user';
import { MessageboxComponent } from 'src/app/popups/messagebox/messagebox.component';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-replace-device-admin',
  templateUrl: './replace-device-admin.component.html',
  styleUrls: ['./replace-device-admin.component.scss'],
})
export class ReplaceDeviceAdminComponent extends LanguageAware implements OnInit, OnDestroy {
  @ViewChild('comfirmReplace') comfirmReplace: MessageboxComponent;
  @ViewChild('comfirmOverwrite') comfirmOverwrite: MessageboxComponent;
  public admins: TDeviceUser[] = [];
  private activeSystemSubscriber = null;
  public selectedText = '';
  private selectedAdmin: TDeviceUser = null;
  public isLoading = false;
  public loaderText = '';

  constructor(cdRef: ChangeDetectorRef, private es: EditSystemService, private router: Router) {
    super(cdRef);
    this.background.setGray();
    this.headerBar.showHeader({
      headerText: this.trans('users.labels.deviceAdmins'),
      backUrl: '/users',
    });
    const that = this;
    this.admins = this.getAdmins();
    this.activeSystemSubscriber = this.systems.onActiveSystemChange.subscribe(() => {
      that.admins = that.getAdmins();
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.activeSystemSubscriber !== null) {
      this.activeSystemSubscriber.unsubscribe();
      this.activeSystemSubscriber = null;
    }
  }

  private getAdmins(): TDeviceUser[] {
    return this.systems.activeSystem.device_users.filter((du) => du.zone_number < 9 && (du.name !== '' || du.email !== '' || du.phone !== ''));
  }

  public adminPicked(admin: TDeviceUser) {
    this.selectedAdmin = admin;
    if (admin.name === '' && admin.email !== '') {
      this.selectedText = admin.email + (admin.phone !== '' ? ' (' + admin.phone + ')' : '');
    } else if (admin.name !== '' && admin.email === '') {
      this.selectedText = admin.name + (admin.phone !== '' ? ' (' + admin.phone + ')' : '');
    } else if (admin.name === '' && admin.email === '') {
      this.selectedText = admin.phone;
    } else {
      this.selectedText = admin.name + (admin.email !== '' ? ' (' + admin.email + ')' : '');
    }
    this.selectedText = this.trans('users.labels.adminReplaceComfirmationInfo') + ': ' + this.selectedText;
    this.comfirmReplace.show();
  }

  public selectionComfirmed() {
    const minimalNumber = this.systems.activeSystemHelper.supports.gvTypeUsers() ? 11 : 9;
    const newZoneNumber = this.es.getAvailableZoneNumber(minimalNumber, minimalNumber, this.selectedAdmin.id);
    if (newZoneNumber === 0 && !this.systems.activeSystemHelper.supports.simpleUsers()) {
      this.overwriteComfirmed();
      return;
    } else if (newZoneNumber === 0) {
      this.comfirmOverwrite.show();
      return;
    }
    const oldAdminNumber = this.selectedAdmin.zone_number;
    this.selectedAdmin.zone_number = newZoneNumber;
    this.loaderText = this.trans('users.labels.replacingAdmin');
    this.isLoading = true;
    const that = this;
    const systemId = this.systems.activeSystem.id;
    const userId = this.selectedAdmin.id;
    this.api
      .post(
        '/edit/user',
        {
          user: this.selectedAdmin,
          system_id: this.systems.activeSystem.id,
        },
        true
      )
      .subscribe(
        (result) => {
          if (!result.success) {
            that.selectedAdmin.zone_number = oldAdminNumber;
            that.toaster.postError(result.error);
            that.isLoading = false;
            return;
          }
          const system = that.systems.getSystem(systemId);
          if (system === undefined) {
            return;
          }
          const user = system.device_users.find((u) => u.id === userId);
          if (user === undefined) {
            return;
          }
          if (result.protegus_user_id !== undefined && result.protegus_user_id !== 0) {
            user.protegus_user_id = result.protegus_user_id;
          }
          if (result.id !== undefined && result.id !== 0) {
            user.id = result.id;
          }
          that.systems.saveActiveSystem(systemId);
          that.performAdd(oldAdminNumber);
        },
        (error) => {
          that.selectedAdmin.zone_number = oldAdminNumber;
          that.isLoading = false;
        }
      );
  }

  private performAdd(zoneNumberToUse: number) {
    let enabledAreas = '';
    let enabledOutputs = 0;
    if (this.systems.activeSystemHelper.supports.cgTypeUsers()) {
      enabledAreas = ''.padStart(this.systems.activeSystem.areas.length, '1');
    } else if (this.systems.activeSystemHelper.supports.gvTypeUsers()) {
      const pgm3 = this.systems.activeSystem.pgms.find((p) => p.queue_no === 3);
      const pgm4 = this.systems.activeSystem.pgms.find((p) => p.queue_no === 4);
      const pgm5 = this.systems.activeSystem.pgms.find((p) => p.queue_no === 5);
      const pgm1 = this.systems.activeSystem.pgms.find((p) => p.queue_no === 1);
      const pgm2 = this.systems.activeSystem.pgms.find((p) => p.queue_no === 2);
      enabledOutputs =
        (pgm3 === undefined || !pgm3.enabled ? 0 : 0x01) +
        (pgm4 === undefined || !pgm4.enabled ? 0 : 0x02) +
        (pgm5 === undefined || !pgm5.enabled ? 0 : 0x04) +
        (pgm1 === undefined || !pgm1.enabled ? 0 : 0x08) +
        (pgm2 === undefined || !pgm2.enabled ? 0 : 0x10);
    }
    const newUser: TDeviceUser = {
      id: 0,
      areas: enabledAreas,
      can_edit_users: true,
      code: '',
      email: this.us.currentUser.email,
      enable_data: 1,
      name: this.us.currentUser.name,
      pgms: enabledOutputs,
      phone: this.us.currentUser.phone,
      present: false,
      protegus_user_id: this.us.currentUser.id,
      schedule_no: 0,
      zone_number: zoneNumberToUse,
      isOwner: false,
      ownerPermissions: {},
      canSeeEvents: true,
    };
    const that = this;
    const systemId = this.systems.activeSystem.id;
    this.systems.activeSystem.device_users.push(newUser);
    this.api
      .post(
        '/edit/user',
        {
          user: newUser,
          system_id: this.systems.activeSystem.id,
        },
        true
      )
      .subscribe(
        (result) => {
          that.isLoading = false;
          if (!result.success) {
            that.toaster.postError(result.error);
            return;
          }
          const system = that.systems.getSystem(systemId);
          if (system === undefined) {
            return;
          }
          const user = system.device_users.find((u) => u.id === 0);
          if (user === undefined) {
            return;
          }
          if (result.protegus_user_id !== undefined && result.protegus_user_id !== 0) {
            user.protegus_user_id = result.protegus_user_id;
          }
          if (result.id !== undefined && result.id !== 0) {
            user.id = result.id;
          }
          that.systems.saveActiveSystem(systemId);
          that.router.navigate(['/users']);
        },
        (error) => {
          that.isLoading = false;
          const system = that.systems.getSystem(systemId);
          if (system === undefined) {
            return;
          }
          const user = system.device_users.find((u) => u.id === 0);
          if (user === undefined) {
            return;
          }
          system.device_users = system.device_users.filter((u) => u.id !== 0);
        }
      );
  }

  public overwriteComfirmed() {
    this.loaderText = this.trans('general.overwriting');
    this.isLoading = true;
    const that = this;
    const systemId = this.systems.activeSystem.id;
    this.systems.activeSystem.device_users = this.systems.activeSystem.device_users.filter((u) => u.id !== this.selectedAdmin.id);
    const userToReadd = JSON.stringify(this.selectedAdmin);
    const zoneNumberForMyself = this.selectedAdmin.zone_number;
    this.api.delete('/user?system_id=' + systemId + '&user_id=' + this.selectedAdmin.id, true).subscribe(
      (result) => {
        if (!result.success) {
          that.isLoading = false;
          that.reverDeletion(systemId, userToReadd);
          that.toaster.postError(result.error);
          return;
        }
        that.systems.saveActiveSystem(systemId);
        that.performAdd(zoneNumberForMyself);
      },
      (error) => {
        that.isLoading = false;
        that.reverDeletion(systemId, userToReadd);
      }
    );
  }

  private reverDeletion(systemId: number, userToReadd: string) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    system.device_users.push(JSON.parse(userToReadd));
  }
}
