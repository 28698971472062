import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { PopupType } from 'src/app/models/popup-type';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private pp: PopupService) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('settings.global.title'),
      backUrl: this.g.getHomeUrl(),
    });
    this.background.setGray();
  }

  ngOnInit(): void {}

  public saveToggle(field: string, value: boolean) {
    const that = this;
    const data = {};
    data[field] = value;
    this.api.post('/settings', data, true).subscribe((result) => {
      if (!result.success) {
        that.toaster.postError(result.error);
        const found = that.us.currentUser.settings.togglable.find((t) => t.field === field);
        if (found !== undefined) {
          found.value = !value;
        }
      }
    });
  }

  public saveTextual(field: string, name: string, value: string) {
    const that = this;
    this.pp.showPopup(
      {
        headerText: name,
        field1: {
          maxLen: 255,
          oldValue: value,
        },
        onSubmit: (res) => {
          const found = that.us.currentUser.settings.textual.find((t) => t.field === field);
          if (found !== undefined) {
            found.value = res;

            const data = {};
            data[found.field] = found.value;
            that.api.post('/settings', data, true).subscribe((result) => {
              if (!result.success) {
                that.toaster.postError(result.error);
                found.value = value;
              }
            });
          }
        },
      },
      PopupType.String
    );
  }
}
