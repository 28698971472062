<div class="">
    <div id='e-header'>{{trans('events.titles.eventList')}}</div>
    <div id='e-sync'>{{lastSynced}}</div>
    <app-fragmenter #fragmentLoader [chunkFetcher]="loadEventChunk" [context]="that">
        <div class="e-day-item" *ngFor='let iDay of groupedEvents'>
            <div class="e-day-header grid"><div>{{iDay.dayTitle}}</div></div>
            <div class="e-events-container">
                <div class="e-event-item grid" *ngFor='let iEvent of iDay.dayEvents'>
                    <div class="e-event-icon" [inlineSVG]="is.getReactionIcon(iEvent.reaction)"></div>
                    <div [ngClass]="{ 'e-event-name': true, 'e-event-name-no-area': iEvent.areaId === 0 }">{{iEvent.title}}</div>
                    <div class="e-event-area" *ngIf='iEvent.areaId !== 0'>{{iEvent.areaText}}</div>
                    <div class="e-event-time">{{dt.formatTimeFromNumber(iEvent.time)}}</div>
                </div>
            </div>
        </div>
        <div class="separator32" *ngIf="!fragmentLoader.isEnabled"></div>
    </app-fragmenter>
</div>