<div [ngClass]="{ 'dds-container': true, 'dds-container-active': fieldIsFocused }">
    <label for="dds-select" class="dds-label">{{header}}</label>
    <div *ngIf='isReadOnly'>
        <input *ngIf="isReadOnly" class="dds-input" [readonly]="true" id="dds-seledct" type="text" [(ngModel)]="value" (focus)='fieldIsFocused = true' (blur)='fieldIsFocused = false'/>
    </div>
    <div *ngIf="!isReadOnly">
        <select class="dds-select" id="dds-select" [(ngModel)]="value" (change)='newValueSelected()'>
            <option *ngFor="let iItem of dataSource" [ngValue]="iItem.value">{{iItem.title}}</option>
        </select>
    </div>
</div>