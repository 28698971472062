import { Injectable, OnDestroy } from '@angular/core';
import { SystemSensorData } from 'src/api/v3/common';
import { TSensorData } from 'src/app/models/sensor-data';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class SensorService implements OnDestroy {
  public readonly sensors = new Map<number, TSensorData>();
  public readonly systemSensors = new Map<number, Set<number>>();

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.sensors.clear();
    this.systemSensors.clear();
  });
  constructor(private auth: AuthService) {}
  ngOnDestroy(): void {
    this.cleanupSubscribtion.unsubscribe();
  }

  ingestSensor(sensor?: SystemSensorData): TSensorData | undefined {
    if (!sensor) return;
    const { alarm_high: notificationAboutHigh, alarm_low: notificationAboutLow, enabled, high_value: highValue, low_value: lowValue, system_id, ...rest } = sensor;
    const systemId = Number(system_id);
    const processedSensor: TSensorData = {
      alarmHigh: false,
      alarmLow: false,
      notificationAboutHigh,
      notificationAboutLow,
      active: !!enabled,
      enabled: !!enabled,
      highValue,
      lowValue,
      temperature: '',
      ...rest,
    };

    this.sensors.set(sensor.id, processedSensor);
    if (!this.systemSensors.has(systemId)) {
      this.systemSensors.set(systemId, new Set());
    }
    this.systemSensors.get(systemId)?.add(sensor.id);
    return processedSensor;
  }
}
