import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoginRoutingModule } from './login-routing.module';
import { LoginComponent } from './login.component';
import { UiModule } from '../ui/ui.module';
import { FormsModule } from '@angular/forms';
import { TeleportModule } from '../teleport/teleport.module';
import { EmailComponent } from './pages/email/email.component';
import { LoginLayoutComponent } from './login-layout.component';
import { EmailComponent as RegisterEmailComponent } from './pages/register/email/email.component';
import { PasswordComponent } from './pages/password/password.component';
import { SetPasswordComponent } from './pages/register/set-password/set-password.component';
import { SetNameComponent } from './pages/register/set-name/set-name.component';
import { LoginLayoutSofaComponent } from './login-layout-sofa.component';
import { LoginEmailComponent } from './components/login-email/login-email.component';
import { GoogleLoginComponent } from './components/google-login/google-login.component';
import { EmailComponent as PasswordResetEmailComponent } from './pages/reset-password/email/email.component';
import { SetPasswordComponent as PasswirdResetComponent } from './pages/reset-password/set-password/set-password.component';
import { CompanyRegisterComponent } from './pages/company-register/company-register.component';
import { CodeComponent } from './pages/register/code/code.component';
import { VerifyComponent } from './pages/reset-password/verify/verify.component';
import { VoidComponent } from './void.component';
import { VarDirective } from '../directives/var.directive';
import { AppCommonModule } from '../app-common/app-common.module';
import { AccountsComponent } from './pages/accounts/accounts.component';
import { AccountSwitcherComponent } from './components/account-switcher/account-switcher.component';
import { LanguageSwitcherComponent } from './components/language-switcher/language-switcher.component';

@NgModule({
  declarations: [
    LoginComponent,
    EmailComponent,
    LoginLayoutComponent,
    RegisterEmailComponent,
    PasswordComponent,
    SetPasswordComponent,
    SetNameComponent,
    LoginLayoutSofaComponent,
    LoginEmailComponent,
    GoogleLoginComponent,
    PasswordResetEmailComponent,
    CompanyRegisterComponent,
    CodeComponent,
    VerifyComponent,
    VoidComponent,
    VarDirective,
    PasswirdResetComponent,
    AccountsComponent,
    AccountSwitcherComponent,
    LanguageSwitcherComponent,
  ],
  imports: [CommonModule, LoginRoutingModule, UiModule, TeleportModule, FormsModule, AppCommonModule],
  exports: [AccountSwitcherComponent],
})
export class LoginModule {}
