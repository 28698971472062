import http from '../http';
import { BasicSuccessResponse, ErrorResponse } from './common';

type ChangeNotificationStatusRequest = {
  systemId: number;
  id: number;
  state?: boolean;
};
type ChangeNotificationStatusResponse = ErrorResponse | { success: true; state: boolean };

export const changeNotificationStatus = (req: ChangeNotificationStatusRequest) =>
  http.post<ChangeNotificationStatusResponse, ChangeNotificationStatusRequest>('/v3/api/system/notification/toggle/state', req);

type ChangeNotificationSoundStatusRequest = {
  systemId: number;
  id: number;
  sound?: boolean;
};
type ChangeNotificationSoundStatusResponse = ErrorResponse | { success: true; state: boolean };

export const changeNotificationSoundStatus = (req: ChangeNotificationSoundStatusRequest) =>
  http.post<ChangeNotificationSoundStatusResponse, ChangeNotificationSoundStatusRequest>('/v3/api/system/notification/toggle/sound', req);

type ToggleSoundSettingRequest = {
  systemId: number;
};
type ToggleSoundSettingResponse = ErrorResponse | { success: true; state: boolean };

export const toggleGblobalSoundSetting = (systemId: ToggleSoundSettingRequest['systemId']) =>
  http.post<ToggleSoundSettingResponse, ToggleSoundSettingRequest>('/v3/api/system/notification/toggle/global-sound', { systemId });

type ChangeGlobalNotificationSettingsSelfRequest = {
  systemId: number;
  value: boolean;
};
type ChangeGlobalNotificationSettingsSelfResponse = ErrorResponse | BasicSuccessResponse;

export const changeGlobalNotificationSettingsSelf = (
  systemId: ChangeGlobalNotificationSettingsSelfRequest['systemId'],
  value: ChangeGlobalNotificationSettingsSelfRequest['value']
) => http.post<ChangeGlobalNotificationSettingsSelfResponse, ChangeGlobalNotificationSettingsSelfRequest>('/v3/api/system/notification/toggle/global-state', { systemId, value });

type ToggleNotificationRequest = {
  systemId: number;
  id: number;
  state: ChangeNotificationStatusRequest['state'];
  sound: ChangeNotificationSoundStatusRequest['sound'];
};
type ToggleNotificationResponse = ErrorResponse | BasicSuccessResponse;

export const toggleNotification = (req: ToggleNotificationRequest) => http.post<ToggleNotificationResponse, ToggleNotificationRequest>('/v3/api/system/notification/toggle', req);

type SetNotificationTokenRequest = {
  token: string;
  deviceId: string;
  appVersion: string;
  platform: 'ios' | 'android' | string;
};
type SetNotificationTokenResponse = ErrorResponse | BasicSuccessResponse;

export const setNotificationToken = (req: SetNotificationTokenRequest) => http.post<SetNotificationTokenResponse, SetNotificationTokenRequest>('/v3/api/update-token', req);
