import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-transfer-type',
  templateUrl: './transfer-type.component.html',
  styleUrls: ['./transfer-type.component.scss'],
})
export class TransferTypeComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.transferType'),
      backUrl: '/system-configuration',
    });
    this.background.setGray();
  }

  ngOnInit(): void {}

  public goToSystemTransfer() {
    this.router.navigate(['/system-transfer']);
  }

  public goToDeviceTransfer() {
    this.router.navigate(['/transfer-device']);
  }
}
