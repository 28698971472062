<div class="white_round padding_sides16">
    <app-text-edit (valueChanged)="systemUid = $event" (exitedField)="systemUid = $event" [value]='systemUid' [maxCharacters]='16' (enterClicked)='systemUid = $event'>{{trans('systems.labels.imei')}}</app-text-edit>
    <app-name-value [nameText]="trans('users.labels.targetIpcom')" (clicked)="changeIpcom()">{{ipcomText}}</app-name-value>
    <app-name-value [nameText]="trans('systems.labels.mpass')" (clicked)="changeMpass()">{{mpass}}</app-name-value>
    <app-name-value *ngIf="selectedIpcom === 0" [nameText]="trans('users.labels.customHost')" (clicked)="changeHost()">{{customHostText}}</app-name-value>
    <app-name-value [nameText]="trans('users.labels.customPort')" (clicked)="changePort()" [showUnderline]="false">{{customPortText}}</app-name-value>
</div>
<div class="separator32"></div>
<div class="padding_sides16">
    <div class="button" (click)="performTransfer()">{{trans('systems.buttons.transfer')}}</div>
</div>