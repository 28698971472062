import http from '../http';
import { BasicSuccessResponse, ErrorResponse, objectToFormData, objectToParams } from './common';

type UploadCompanyLogoResponse = ErrorResponse | (BasicSuccessResponse & { logoUrl: string });

export const saveUserLogo = (logo: Blob) => http.post<UploadCompanyLogoResponse, FormData>('/v3/api/profile/logo', objectToFormData({ logo_image: logo }));

export type EventDescriptionData = {
  id: number;
  qualifier: 'E' | 'R';
  event_code: string;
  default_name: string;
  reaction_id: number;
  active: 0 | 1;
  company_id: number;
  area_event: boolean;
  created_at: string;
  updated_at: string;
};

type GetEventDescriptionsResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      list: EventDescriptionData[];
    });

export const getEventDescriptions = () => http.post<GetEventDescriptionsResponse>('/v3/api/get-event-descriptions', undefined);

type SetEventDescriptionRequest = {
  qualifier: 'E' | 'R';
  code: string;
  name: string;
  reaction: number;
  active: number;
  area_event: boolean;
  id?: number;
};

type SetEventDescriptionResponse = ErrorResponse | BasicSuccessResponse | (BasicSuccessResponse & { newId: number });

export const setEventDescription = (req: SetEventDescriptionRequest) => http.post<SetEventDescriptionResponse, SetEventDescriptionRequest>('/v3/api/edit/event-description', req);

type DeleteEventDescriptionRequest = {
  id: number;
};
type DeleteEventDescriptionResponse = ErrorResponse | BasicSuccessResponse;

export const deleteEventDescription = (req: DeleteEventDescriptionRequest) =>
  http.post<DeleteEventDescriptionResponse, DeleteEventDescriptionRequest>('/v3/api/delete/event-description', req);

type SetPanicButtonSettingsRequest = {
  grg_host: string;
  grg_port: number;
  grg_code: string;
  grg_zone: string;
  grg_enabled: boolean | 'on' | 'off';
};
type SetPanicButtonSettingsResponse = ErrorResponse | BasicSuccessResponse;

export const setPanicButtonSettings = (req: SetPanicButtonSettingsRequest) =>
  http.post<SetPanicButtonSettingsResponse, SetPanicButtonSettingsRequest>('/v3/api/edit/panic-button', req);

type EditIpComRequest = {
  id: number | 0;
  peer_name: string;
  host: string;
  port: number;
  user_name: string;
  password: string;
  own_ip: boolean;
  region: number;
};

type EditIpComResponse = ErrorResponse | (BasicSuccessResponse & { id: number });

export const editIpCom = (req: EditIpComRequest) => http.post<EditIpComResponse, EditIpComRequest>('/v3/api/edit/ipcom', req);

type DeleteIpComRequest = { id: number };
type DeleteIpComResponse = ErrorResponse | BasicSuccessResponse;

export const deleteIpCom = (req: DeleteIpComRequest) => http.post<DeleteIpComResponse, DeleteIpComRequest>('/v3/api/delete/ipcom', req);

type SetIpComLogoRequest = { id: number; logo: Blob };
type SetIpComLogoResponse = ErrorResponse | (BasicSuccessResponse & { logoUrl: string });

export const setIpComLogo = (req: SetIpComLogoRequest) => http.post<SetIpComLogoResponse, FormData>('/v3/api/edit/ipcom/logo', objectToFormData(req));

type RemoveIpComLogoRequest = { id: number };
type RemoveIpComLogoResponse = ErrorResponse | BasicSuccessResponse;

export const removeIpComLogo = (req: RemoveIpComLogoRequest) => http.post<RemoveIpComLogoResponse, RemoveIpComLogoRequest>('/v3/api/delete/ipcom/logo', req);
type TestIpcomRequest = {
  peer_name: string;
  host: string;
  port: number;
  user_name: string;
  password: string;
};
type TestIpcomResponse = ErrorResponse | (BasicSuccessResponse & { status: boolean });

export const testIpcom = (req: TestIpcomRequest) => http.post<TestIpcomResponse, TestIpcomRequest>('/v3/api/test-ipcom', req);

type RequestAssistanceRequest = {
  accessPeriod: number; // Hours
  describeIssue: string;
  id: number; // Installer ID
  systemId: number;
};
type RequestAssistanceResponse = ErrorResponse | BasicSuccessResponse;

export const requestAssistance = (req: RequestAssistanceRequest) => http.post<RequestAssistanceResponse, RequestAssistanceRequest>('/v3/api/installer-assistance', req);

type GetAssistanceDataRequest = { systemId: number };
type GetAssistanceDataResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      data: {
        installer_id: number;
        system_id: number;
        transferred: boolean;
        access_until: string;
        issue: string;
      }[];
    });

export const getAssistanceData = (req: GetAssistanceDataRequest) => http.get<GetAssistanceDataResponse, URLSearchParams>('/v3/api/installer-assistance', objectToParams(req));

type RevokeAssistanceRequest = {
  id: number; // Installer ID
  systemId: number;
};
type RevokeAssistanceResponse = ErrorResponse | BasicSuccessResponse;

export const revokeAssistance = (req: RevokeAssistanceRequest) => http.post<RevokeAssistanceResponse, RevokeAssistanceRequest>('/v3/api/revoke-installer', req);
