import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './company-layout.component.html',
  styleUrls: ['./company-layout.component.scss'],
})
export class CompanyLayoutComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
