import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/services/events.service';
import { DateService } from 'src/app/services/date.service';
import { AreaService } from 'src/app/services/area.service';
import { ZoneService } from 'src/app/services/zone.service';
import { IconsService } from 'src/app/services/icons.service';
import { WebSocketService } from 'src/app/services/websocket.service';
import { TAreaData } from 'src/app/models/area-data';
import { GeneralSettings } from 'src/general-settings';

@Component({
  selector: 'app-area-view',
  templateUrl: './area-view.component.html',
  styleUrls: ['./area-view.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AreaViewComponent extends LanguageAware implements OnInit, OnDestroy {
  public areaEvents: any[] = [];
  private eventSubscription = null;
  private activeSystemSubscription = null;
  public alarmedAreaClicked: TAreaData = null;
  private areaOperationSubscriber = null;
  public showForgetPin = false;
  public showAlarmButton = false;
  private takeActionButtonVisibilitySubscriber = null;

  constructor(
    cdRef: ChangeDetectorRef,
    private router: Router,
    private ev: EventsService,
    public dt: DateService,
    public areaService: AreaService,
    private zs: ZoneService,
    public is: IconsService,
    ws: WebSocketService
  ) {
    super(cdRef);
    if (this.systems.activeArea === null) {
      this.router.navigate(['/home']);
      return;
    }
    this.background.setGray();
    this.headerBar.showHeader({
      headerText: this.systems.activeArea.name,
      backUrl: '/home',
    });
    this.showForgetPin = !this.systems.activeArea.showKeypad && this.systems.activeSystem.directControl;
    this.areaEvents = this.getEvents();
    const that = this;
    this.eventSubscription = ws.onEventReceived.subscribe(() => {
      that.areaEvents = that.getEvents();
    });
    this.activeSystemSubscription = this.systems.onActiveSystemChange.subscribe(() => {
      that.showForgetPin = !that.systems.activeArea.showKeypad && that.systems.activeSystem.directControl;
    });
    this.areaOperationSubscriber = this.areaService.onAreaOperationCompleted.subscribe(() => {
      that.areaService.alarmedActionInProgress = false;
    });
    this.takeActionButtonVisibilitySubscriber = this.areaService.onTakeActionButtonVisibilityChanged.subscribe((data: any) => {
      if (that.systems.activeArea.id !== data.id) {
        return;
      }
      that.showAlarmButton = data.visible;
    });
    this.areaService.addOrUpdateAreaForTakeAction(this.systems.activeArea);
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.eventSubscription !== null) {
      this.eventSubscription.unsubscribe();
      this.eventSubscription = null;
    }
    if (this.activeSystemSubscription !== null) {
      this.activeSystemSubscription.unsubscribe();
      this.activeSystemSubscription = null;
    }
    if (this.areaOperationSubscriber !== null) {
      this.areaOperationSubscriber.unsubscribe();
      this.areaOperationSubscriber = null;
    }
    if (this.takeActionButtonVisibilitySubscriber !== null) {
      this.takeActionButtonVisibilitySubscriber.unsubscribe();
      this.takeActionButtonVisibilitySubscriber = null;
    }
  }

  public getEvents(): any[] {
    const events = this.systems.activeSystem.events.filter((e) => e.areaId === this.systems.activeArea.id && e.isAreaEvent);
    const finalEvents = [];
    for (const iEvent of events) {
      if (this.systems.activeSystem.eventConfiguration.length === 0) {
        finalEvents.push(iEvent);
      } else if (this.systems.activeSystem.eventConfiguration.indexOf(iEvent.reaction) !== -1) {
        finalEvents.push(iEvent);
      }
    }
    return this.ev.groupEvents(finalEvents, 0, 4);
  }

  public changeAreaStateTo(newState: number) {
    if (!this.systems.activeSystemHelper.can.control.area(this.systems.activeArea.queue_no)) {
      this.toaster.postError(this.trans('systems.errors.operationNotAllowed'));
      return;
    }
    if (this.areaService.isBusy()) {
      this.toaster.postError(this.trans('systems.errors.busyArea'));
      return;
    }
    if (newState === this.systems.activeArea.status) {
      return;
    }
    this.areaService.busyArea = {
      systemId: this.systems.activeSystem.id,
      areaId: this.systems.activeArea.id,
      oldRememberPin: !this.systems.activeArea.showKeypad,
      newRememberPin: !this.systems.activeArea.showKeypad,
      showPinKeypad: this.systems.activeArea.showKeypad,
      oldStatus: this.systems.activeArea.status,
      newStatus: newState,
      pinToUse: '',
      zonesToBypass: [],
      showZoneBypass: false,
    };
    if (!this.systems.activeSystem.directControl || !this.areaService.busyArea.showPinKeypad) {
      this.areaService.busyArea.showPinKeypad = false;
      this.areaService.performStatusChange();
    }
  }

  public goToSettings() {
    this.router.navigate(['/system-area-list']);
  }

  public goToEvents() {
    this.router.navigate(['/events']);
  }

  public forgetPin() {
    const that = this;
    const systemId = this.systems.activeSystem.id;
    const areaId = this.systems.activeArea.id;
    const oldValue = this.systems.activeArea.showKeypad;
    this.systems.activeArea.showKeypad = true;
    this.log('Paspausta pamiršti srities ' + this.systems.activeArea.id + ' PIN');
    this.headerBar.showHeader({
      headerText: this.systems.activeArea.name,
      backUrl: '/home',
    });
    this.api.post('/forget-pin', { system_id: systemId, area_id: areaId }, true).subscribe(
      (result) => {
        if (result.success) {
          that.systems.saveActiveSystem(systemId);
          that.log('PIN pamirštas sėkmingai');
        } else {
          that.toaster.postError(result.error);
          that.rollbackForgetPin(systemId, areaId, oldValue);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.rollbackForgetPin(systemId, areaId, oldValue);
      }
    );
  }

  private rollbackForgetPin(systemId: number, areaId: number, oldValue: boolean) {
    this.log('Nepavyko pamiršti PIN');
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const area = system.areas.find((a) => a.id === areaId);
    if (area === undefined) {
      return;
    }
    area.showKeypad = oldValue;
    this.systems.saveActiveSystem(systemId);
  }
}
