import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SystemService } from 'src/app/api/system/system.service';
import { SystemsService } from 'src/app/services/systems.service';
import { UserService } from 'src/app/services/user.service';
import { UserService as NewUserService } from 'src/app/api/user.service';
import { AreaService } from 'src/app/services/area.service';
import { BackgroundService } from 'src/app/services/background.service';
import { TBackgroundType } from 'src/app/models/background-types';
import { FooterService } from 'src/app/services/footer.service';

@Component({
  templateUrl: './company-mobile-host.component.html',
  styleUrls: ['./company-mobile-host.component.scss'],
})
export class CompanyMobileHostComponent implements OnInit, OnDestroy {
  private routeSubscription = this.route.paramMap.subscribe((params) => {
    this.currentSystemId = parseInt(params.get('systemId'), 36);
  });
  private _currentSystemId = 0;
  public get currentSystemId() {
    return this._currentSystemId;
  }
  public set currentSystemId(value) {
    this._currentSystemId = value;
    this.setSystem(value);
  }
  public get backgroundClass(): string {
    switch (this.bg.currentType) {
      case TBackgroundType.Gradient:
        return '';
      case TBackgroundType.White:
        return 'bg-white';
      case TBackgroundType.Gray:
        return 'bg-gray';
    }
  }
  private get bgStart() {
    return this.systems.activeSystem?.theme.startColor;
  }
  private get bgEnd() {
    return this.systems.activeSystem?.theme.endColor;
  }

  public get backgroundStyle() {
    return {
      '--home-background-start': this.bgStart,
      '--home-background-end': this.bgEnd,
    };
  }
  constructor(
    private route: ActivatedRoute,
    private systems: SystemsService,
    private newSystems: SystemService,
    private users: UserService,
    private newUsers: NewUserService,
    public areaService: AreaService,
    private bg: BackgroundService,
    public footerService: FooterService,
    private cdRef: ChangeDetectorRef
  ) {
    this.currentSystemId = parseInt(this.route.snapshot.paramMap.get('systemId'), 36);
  }
  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
  }

  ngOnInit(): void {}

  private async setSystem(id: number) {
    const sys = await this.newSystems.getSystem(id);
    if (this.newUsers.user) this.users.currentUser = this.newUsers.user;
    this.systems.setCurrentSystem(sys);
    this.cdRef.detectChanges();
  }

  public showButton1(): boolean {
    return this.footerService.getButton1Text() !== '';
  }

  public showButton2(): boolean {
    return this.footerService.getButton2Text() !== '';
  }

  public doButton1Click() {
    if (this.footerService.onButton1Click !== null) {
      this.footerService.onButton1Click();
    }
  }

  public doButton2Click() {
    if (this.footerService.onButton2Click !== null) {
      this.footerService.onButton2Click();
    }
  }
}
