import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiRequestService } from '../services/api-request.service';
import { Location } from '@angular/common';
import { GlobalService } from '../services/global.service';
import { LoggerService } from '../api/logger.service';

@Injectable({
  providedIn: 'root',
})
export class GuestGuardService implements CanActivate {
  private whitelistedUrls = ['/login', '/email', '/activate-email', '/password/reset', '/activate', '/register', '/reset'];

  constructor(private router: Router, private rs: ApiRequestService, private g: GlobalService, private loc: Location, private l: LoggerService) {}

  private belongsToWhitelist(path: string): boolean {
    let result = false;
    this.whitelistedUrls.forEach((element) => {
      if (path.startsWith(element)) {
        result = true;
      }
    });
    this.l.log('baigem', 'GuestGuard', result);
    return result;
  }

  canActivate() {
    if (!this.rs.hasToken()) {
      if (this.g.isLoaded()) {
        return true;
      } else {
        const url = this.loc.path();
        if (this.belongsToWhitelist(url)) {
          this.g.setRedirectionURL(url);
        } else {
          this.g.setRedirectionURL('/login');
        }
        this.router.navigate(['/']);
      }
    } else {
      if (this.g.isLoaded()) {
        this.router.navigate([this.g.getHomeUrl()]);
      } else {
        this.g.setRedirectionURL(this.g.getHomeUrl());
        this.router.navigate(['/']);
      }
    }
    return true;
  }
}
