<div class="white_round padding16">
    <div id='system-information-imei' (click)='copyImei()'>
        <div>{{trans('systems.labels.imei')}}</div>
        <div>{{systems.activeSystem.imei}}</div>
    </div>
    <app-text-edit id='system-information-name-container' value='{{systems.activeSystem.name}}' (enterClicked)='onOk()' (valueChanged)='systems.activeSystem.name = $event.trim()' [isReadOnly]='!systems.activeSystemHelper.can.edit.information()'>{{trans('systems.labels.nameSystem')}}</app-text-edit>
    <app-text-edit *ngIf='systems.activeSystemHelper.can.edit.information()' id='system-information-mpass-container' inputType='number' value='{{systems.activeSystem.mpass}}' (enterClicked)='onOk()' (valueChanged)='systems.activeSystem.mpass = $event' [maxCharacters]='6'>{{trans('systems.labels.mpass')}}</app-text-edit>
    <app-time-zone-select [isReadOnly]='!systems.activeSystemHelper.can.edit.information()' id='system-information-timezone' (valueChanged)='systems.activeSystem.timeZone = $event' [initialValue]='systems.activeSystem.timeZone'></app-time-zone-select>
    <div id='system-information-background-selection'>
        <div>{{trans('systems.labels.background')}}</div>
        <div id="system-information-background-button" (click)='backgroundClick()'></div>
    </div>
</div>
<div class='separator32'></div>
<div *ngIf='systems.activeSystemHelper.can.edit.information()' class="white_round" id='sinf_sync_button'>
    <app-button-single-line-arrow [icon]="'assets/images/question_round.svg'" [routerLink]="'/reread'">{{trans('systems.titles.outOfSync')}}</app-button-single-line-arrow>
</div>
<div *ngIf="!systems.activeSystemHelper.can.see.users()">
    <div class="section_header padding_l16">{{trans('systems.labels.systemMasters')}}</div>
    <div class="white_round">
        <ng-container *ngFor="let iMaster of ownerList">
            <div class="si_owner_container">
                <div class="si_owner_details">
                    <div class="si_owner_name" *ngIf="iMaster.name !== undefined">{{iMaster.name}}</div>
                    <div class="si_owner_email" *ngIf="iMaster.email !== undefined">{{iMaster.email}}</div>
                </div>
                <div class="si_owner_phone" *ngIf="iMaster.phone_number !== undefined">{{iMaster.phone_number}}</div>
            </div>
        </ng-container>
    </div>
</div>