import { environment as env } from 'src/environments/environment';
import { AppRegion } from 'src/environments/environment.types';

export class AppSettings {
  public static readonly production = env.production;
  public static showLogger = env.showLogger;
  /** Nurodo ar vykdyti loginimą į "console" */
  public static logToConsole = env.logToConsole;
  /** Web aplikacijos pavadinimas, kuris bus rodomas naršyklių tab'e. pvz "Protegus" */
  public static title = env.title;
  /** Fizinis įmonės adresas */
  public static address = env.address;
  /** Įmonės email, kuriuo galima susisiekti */
  public static email = env.email;
  /** Įmonės telefonas, kuriuo galima paskambinti */
  public static phone = env.phone;
  /** Įmonės web puslapis (tai nėra web app!) */
  public static companyUrl = env.companyUrl;
  /** Nurodo ar rodyti google login mygtuka */
  public static googleLoginSupported = env.googleLoginSupported;
  /** Nurodo ar rodyti apple login mygtuka */
  public static appleLoginSupported = env.googleLoginSupported;
  /** Nurodo ar soniniame meniu bus matoma Help dalis */
  public static showHelp = env.showHelp;
  /** Nurodo ar rodyti Advanced meniu */
  public static showConfig = env.showConfig;
  /** Nurodo ar reikalingas regioninis peradresavimas */
  public static regionsEnabled = env.regions.length > 1;
  /** Palaikomi regionai */
  public static regions: AppRegion[] = env.regions;
  /** Nurodo ar galima kurti vartotojus, kurie dar neegzistuoja */
  public static canAutoCreateAccounts = env.canAutoCreateAccounts;
  /** Nurodo ar super adminui reikia krauti pilną vartotojų sąrašą */
  public static loadAllUsersForSU = env.loadAllUsersForSU;
  /** Nurodo ar super adminui reikia krauti pilną sistemų sąrašą */
  public static loadAllSystemsForSU = env.loadAllSystemsForSU;
  /** Nurodo ar tik Super admin gali pridėti/ištrinti vartotojus. Esant false tą gali daryti visi. */
  public static onlySUcanAddRemoveUsers = env.onlySUcanAddRemoveUsers;
  /** Nurodo ar galima trinti sistemą esant sistemų sąraše */
  public static canDeleteSystemFromList = env.canDeleteSystemFromList;
  /** Nurodo ar galima trinti vartotojus esant vartotojų sąraše */
  public static canDeleteUserFromList = env.canDeleteUserFromList;
  /** Nurodo ar turi veikti ribojimų sistema. (Kamerų kiekis, sistemų kiekis ir t.t.) */
  public static limitationSystemEnabled = env.limitationSystemEnabled;
  /** Nurodo ar reikia rodyti versiją */
  public static showAppVersion = env.showAppVersion;
  public static appVersionMajor = env.appVersionMajor;
  public static appVersionMinor = env.appVersionMinor;
  public static appVersionBuild = env.appVersionBuild;
  public static appVersionDate = env.appVersionDate;
  /** Nurodo ar super admin gali peradresuoti modulius tarp regionų */
  public static deviceTransferEnabled = env.deviceTransferEnabled;
  /** Nurodo ar vartotojai gali peradresuoti modulius tarp v1 ir v2 versijų */
  public static deviceTransferToAppEnabled = env.deviceTransferToAppEnabled;
  public static companyDesktopEnabled = env.companyDesktopEnabled;
  public static googleClientIdWeb = env.googleClientIdWeb;
  public static sentry = env.sentry;
  public static localReport = env.localReport;
  public static greSiteKey = env.greSiteKey;
  public static primaryColor = env.primaryColor;
  public static secondaryColor = env.secondaryColor;
  /** Nurodo kokį pavadinimą reikės rodyti Wifi GV moduliams */
  public static wifiGateDevice37Name = env.wifiGateDevice37Name;
}

export const environment = AppSettings;
