import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app-settings';
import { LanguageAware } from 'src/app/general/language-aware';
import { UserRole } from 'src/app/models/user-role';

@Component({
  selector: 'app-system-configuration',
  templateUrl: './system-configuration.component.html',
  styleUrls: ['./system-configuration.component.scss'],
})
export class SystemConfigurationComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    this.background.setGray();
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.configureSystem') + ' ' + this.systems.activeSystem.name,
      backUrl: '/home',
    });
    localStorage.setItem('ref', 'conf');
  }

  ngOnInit(): void {}

  public showAdvanced(): boolean {
    const globalValue = AppSettings.showConfig;
    const masterValue = this.systems.activeSystem.amIMaster && this.systems.activeSystem.company_id === 0;
    const companyValue = this.systems.activeSystem.company_id !== 0 && this.us.currentUser.role !== UserRole.SimpleUser;
    const suValue = this.us.currentUser.role === UserRole.SuperAdmin;
    return globalValue && (masterValue || companyValue || suValue);
  }

  public goToTransfer() {
    const supportsDeviceTransfer =
      this.systems.activeSystem.supported_commands.indexOf('.18.') !== -1 ||
      this.systems.activeSystem.supported_commands.endsWith('.18') ||
      this.systems.activeSystem.supported_commands.indexOf('.22.') !== -1 ||
      this.systems.activeSystem.supported_commands.endsWith('.22');
    if (AppSettings.deviceTransferToAppEnabled && supportsDeviceTransfer && this.us.currentUser.canTransferDevice) {
      this.router.navigate(['/transfer-type']);
    } else {
      this.router.navigate(['/system-transfer']);
    }
  }
}
