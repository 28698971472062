import { Injectable, NgZone } from '@angular/core';
import { ApiRequestService } from './api-request.service';
import { LanguageService } from './language.service';
import { LoggerService } from '../api/logger.service';
import { MiniStatusService } from './mini-status.service';
import { PlatformService } from '../api/platform.service';
import { GoogleAuthService } from '../api/google-auth.service';

@Injectable({
  providedIn: 'root',
})
export class GoogleService {
  private callbackWhenSuccess = null;
  private callbackWhenFailure = null;

  constructor(
    private zone: NgZone,
    private miniStatus: MiniStatusService,
    private ps: PlatformService,
    private lang: LanguageService,
    private ggl: GoogleAuthService,
    private l: LoggerService,
    private api: ApiRequestService
  ) {
    if (window.loginJsi !== null && window.loginJsi !== undefined) {
      window.loginJsi.onGoogleCheck = (idToken: string, accessToken: string) => {
        zone.run(() => {
          this.onGoogleLogin(idToken, accessToken);
        });
      };
    } else {
      window.loginJsi = {
        onGoogleCheck: (idToken: string, accessToken: string) => {
          zone.run(() => {
            this.onGoogleLogin(idToken, accessToken);
          });
        },
      };
    }
  }

  public performLogin(goodCallback, badCallback) {
    this.callbackWhenSuccess = goodCallback;
    this.callbackWhenFailure = badCallback;
    this.miniStatus.show(this.lang.get('auth.loggingIn'));

    if (this.ps.isDesktop()) {
      const that = this;
      this.ggl.signIn().then(
        (googleUser) => {
          if (googleUser === null) {
            that.miniStatus.hide();
            that.callbackWhenFailure(that.lang.get('auth.errors.googleFailed'));
          } else {
            if (googleUser.email == null || googleUser.email === undefined || googleUser.email === '') {
              that.miniStatus.hide();
              that.callbackWhenFailure(that.lang.get('auth.errors.googleNoEmail'));
            } else if (googleUser.idToken == null || googleUser.idToken === undefined || googleUser.idToken === '') {
              that.miniStatus.hide();
              that.callbackWhenFailure(that.lang.get('auth.errors.googleNoToken'));
            } else {
              that.onGoogleLogin(googleUser.idToken, googleUser.authToken);
            }
          }
        },
        (reason) => {
          that.l.log('Fail', 'GoogleLogin', reason);
          that.miniStatus.hide();
          that.callbackWhenFailure();
        }
      );
    } else if (this.ps.isAndroid()) {
      this.ps.androidHandler().doGoogleLogin();
    } else if (this.ps.isApple()) {
      this.ps.appleHandler().doGoogleLogin.postMessage('');
    }
  }

  /** Siunčiami Google login duomenys, bei bandoma prisijungti */
  /** @param idToken Iš Google gautas vartotojo ID token. */
  /** @param accessToken Iš Google gautas vartotojo access token. */
  private onGoogleLogin(idToken: string, accessToken: string) {
    const that = this;
    let data = null;
    const lastSystemId = localStorage.getItem('last_system');
    if (this.ps.isDesktop() || this.ps.isAndroid()) {
      data = {
        id_token: idToken,
        ac: accessToken,
        last_system: lastSystemId,
      };
    } else if (this.ps.isApple()) {
      data = {
        id_token: idToken,
        ac: accessToken,
        ios: true,
        last_system: lastSystemId,
      };
    }
    if (idToken === '' && accessToken === '') {
      that.miniStatus.hide();
      that.callbackWhenFailure(that.lang.get('auth.errors.googleNoToken'));
      return;
    }
    this.api.post('/check/google', data, false).subscribe(
      (result) => {
        if (result.success) {
          that.zone.run(() => {
            that.miniStatus.hide();
            that.callbackWhenSuccess(result);
          });
        } else {
          that.miniStatus.hide();
          that.callbackWhenFailure(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
        that.callbackWhenFailure(that.lang.get('auth.errors.googleFailed'));
      }
    );
  }
}
