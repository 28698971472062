import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app-settings';
import { LanguageAware } from 'src/app/general/language-aware';
import { DeviceService } from 'src/app/services/device.service';
import { NewSystemService } from 'src/app/services/new-system.service';

@Component({
  selector: 'app-new-system-offline',
  templateUrl: './new-system-offline.component.html',
  styleUrls: ['./new-system-offline.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NewSystemOfflineComponent extends LanguageAware implements OnInit {
  public AppSettings = AppSettings;

  constructor(cdRef: ChangeDetectorRef, private router: Router, public ns: NewSystemService, private ds: DeviceService) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.checkDevice'),
      backUrl: '/add-system',
    });
    this.background.setGray();
    const that = this;
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    this.footerBar.onButton1Click = () => {
      router.navigate(['/add-system']);
    };
  }

  ngOnInit(): void {}

  public retry() {
    const that = this;
    this.miniStatus.show(this.trans('general.checking'));
    this.api.get('/online?checkAll=true&imei=' + this.ns.getUID(), true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success && result.online) {
          that.router.navigate(['/add-system-details']);
        } else if (!result.success) {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
      }
    );
  }

  public startWifiPath(hwId: number) {
    this.ds.setHwId(hwId);
    this.router.navigate(['/scan-nearby-devices']);
  }
}
