<div class="grid" id='zsv-grid'>
    <div *ngIf='showWarning' id='zsv-warn-background' class="group-background"></div>
    <div *ngIf='showWarning' id='zsv-warn-text'><strong>{{trans('systems.labels.note')}}</strong> {{trans('systems.labels.dscZoneNote')}}</div>
    <div id='zsv-bypass' *ngIf='systems.activeSystemHelper.can.bypassZone()'>{{trans('systems.buttons.bypassZone')}}</div>
    <div id='zsv-background' class="group-background"></div>
    <div id='zsv-container'>
        <div class="list-item-v1" *ngFor='let zone of getZones()' (click)='doBypass(zone, $event)'>
            <div *ngIf='!zone.alarmed && !zone.bypassed && !zone.failed' [inlineSVG]="'assets/images/zone-general-icon.svg'"></div>
            <div *ngIf='zone.bypassed' [inlineSVG]="'assets/images/bypass.svg'"></div>
            <div *ngIf='!zone.bypassed && zone.failed' [inlineSVG]="'assets/images/zone-general-icon.svg'"></div>
            <div *ngIf='!zone.bypassed && zone.alarmed' [inlineSVG]="'assets/images/zone-alarmed.svg'"></div>
            <div >{{zone.queue_no}}</div>
            <div>
                <div>{{zone.name}}</div>
            </div>
            <app-toggle-button *ngIf='systems.activeSystemHelper.can.bypassZone()' [buttonId]='"zsv-" + zone.queue_no' [initialState]='zone.bypassed' (toggled)="doBypass(zone, undefined)"></app-toggle-button>
        </div>
    </div>
</div>
<app-area-keypad *ngIf='showKeypad' [keypadType]='"slideout"' (cancelClicked)='keypadCancelled()' [maxLength]='6' [minLength]='4' (confirmClicked)='pinConfirmed($event.pin, $event.remember_pin)'></app-area-keypad>