<div id="advs_grid" class='grid' *ngIf="shouldShowContent">
    <div id="advs_outer_ring"><div></div></div>
    <div id="advs_inner_ring"><div></div></div>
    <div id="advs_asterisk">
        <svg viewBox="0 0 57.373 57.373">
            <path d="M31.257,7.307a23.308,23.308,0,1,0,16.481,6.827A23.155,23.155,0,0,0,31.257,7.307Zm0-5.379A28.686,28.686,0,1,1,2.571,30.614,28.686,28.686,0,0,1,31.257,1.928ZM27.671,41.372h7.172v7.172H27.671Zm0-28.686h7.172V34.2H27.671Z" transform="translate(-2.571 -1.928)" fill="#ef476f"/>
        </svg>
    </div>
    <div id="advs_riskh">{{trans('systems.titles.useAtOwnRisk')}}</div>
    <div id="advs_text">{{trans('systems.labels.atOwnRiskDesc')}}</div>
    <div id="advs_check" (click)="doNotShow = !doNotShow">
        <div>
            <app-checkbox [checkValue]="doNotShow" (valueChanged)="doNotShow = $event" [isRound]="false" [isSecondaryColor]="true"></app-checkbox>
        </div>
        <div>{{trans('general.doNotShowThisAgain')}}</div>
    </div>
</div>
<div id="advs_agree" *ngIf="shouldShowContent">
    <div class="button" (click)='openConfigurator()'>{{trans('systems.buttons.acceptAdvancedSettings')}}</div>
</div>
<div *ngIf="!shouldShowContent">
    <app-loader-round [displayText]="trans('general.pleaseWait')" [isVisible]="miniStatus.isVisible()" [overlayIsGray]="true"></app-loader-round>
</div>