import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';

@Component({
  selector: 'app-su-global-settings',
  templateUrl: './su-global-settings.component.html',
  styleUrls: ['./su-global-settings.component.scss'],
})
export class SuGlobalSettingsComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('settings.global.title'),
      backUrl: '/sadmin',
    });
    this.background.setGray();
  }

  ngOnInit(): void {}
}
