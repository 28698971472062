import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { Router } from '@angular/router';
import { TSystemData } from 'src/app/models/system-data';
import { UserRole } from 'src/app/models/user-role';
import { ToastType } from 'src/app/models/toaster-type';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-delete',
  templateUrl: './delete.component.html',
  styleUrls: ['./delete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeleteComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private router: Router, private es: EditSystemService) {
    super(cdRef);
    const that = this;
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.removeSystem'),
      backUrl: '/system-configuration',
    });
    this.footerBar.showFooter(this.trans('general.cancel'), '', false, false);
    this.footerBar.onButton1Click = () => {
      that.router.navigate(['/system-configuration']);
    };
    this.background.setGray();
  }

  ngOnInit(): void {}

  public onDelete() {
    this.es.deleteSystem();
    this.systems.setCurrentSystemFromInternalList(false);
    this.router.navigate([this.g.getHomeUrl()]);
  }

  public canDeleteWholeSystem(): boolean {
    if (this.us.currentUser.role === UserRole.Company || this.us.currentUser.role === UserRole.SuperAdmin) {
      return true;
    }
    return this.systems.activeSystem.amIMaster;
  }
}
