<div class="white_round">
    <app-name-value [nameText]="trans('events.settings.labels.nameEvent')" (clicked)="changeName()">{{eventDescItem.default_name}}</app-name-value>
    <app-name-value [nameText]="trans('general.enabled')">
        <app-toggle-button [buttonId]="'cpese_enable'" [initialState]="eventDescItem.active === 1" (toggled)="eventDescItem.active = $event.checked ? 1 : 0"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('events.settings.labels.areaEvent')">
        <app-toggle-button [buttonId]="'cpese_areae'" [initialState]="eventDescItem.area_event" (toggled)="eventDescItem.area_event = $event.checked"></app-toggle-button>
    </app-name-value>
    
    <div id="cpese_qual_container" (click)="changeQualifier()">
        <div id="cpese_qual">{{trans('events.settings.labels.qualifier')}}</div>
        <div *ngIf="eventDescItem.qualifier !== '-'" id="cpese_qualt"><span>{{trans('events.settings.qualifier.' + eventDescItem.qualifier)}}</span></div>
        <div id="cpese_arrow"><svg viewBox="0 0 8.402 14.697"><path d="M13.783,13.54l5.561-5.557A1.05,1.05,0,1,0,17.857,6.5l-6.3,6.3a1.048,1.048,0,0,0-.031,1.448l6.327,6.34A1.05,1.05,0,0,0,19.34,19.1Z" transform="translate(19.653 20.891) rotate(180)"/></svg></div>
    </div>
    <app-name-value [nameText]="trans('events.settings.labels.code')" (clicked)="changeCidCode()">{{eventDescItem.event_code}}</app-name-value>
    <app-name-value [nameText]="trans('events.settings.labels.reaction')" (clicked)="changeReaction()" [showUnderline]="false">{{reactionText}}</app-name-value>
</div>
<app-popup></app-popup>