<div class="bold_header" *ngIf="us.currentUser.role === 3">
    {{trans('systems.labels.eventDash')}}
</div>
<div class="white_round" *ngIf="us.currentUser.role === 3">
    <div id="cph_reactions_container">
        <app-dash-group-item *ngFor="let iGroup of lastSeenGroups" [groupItem]='iGroup'></app-dash-group-item>
    </div>
    <div id="cph_line"></div>
    <div id="cph_all_events" (click)="gotoAllEvents()">{{trans('systems.menu.allEvents')}}</div>
</div>
<div class="bold_header">
    <div id="cph_sh_text">{{trans('systems.titles.systems')}}</div>
    <div id="cph_sh_find" [inlineSVG]="'assets/images/find.svg'" (click)="openFind()"></div>
</div>
<div class="white_round padding16" id="cph_total_systems" *ngIf="us.currentUser.role === 0">{{trans('systems.labels.totalSystems')}}: {{systems.getSystemCount()}}</div>
<div class="separator16" *ngIf="us.currentUser.role === 0"></div>
<div class="white_round" id="cph_systems" *ngIf="systems.systems.length > 0 && us.currentUser.role !== 0">
    <app-cp-system-list-item *ngFor="let iSystem of systems.systems; let i = index" [systemItem]='iSystem' [showInstaller]='us.currentUser.role === 3' [showBorder]="systems.systems.length-1 !== i" [showDelete]="true"></app-cp-system-list-item>
</div>
<div class="white_round" id="cph_systems" *ngIf="systems.systems.length > 0 && us.currentUser.role === 0">
    <app-fragmenter #fragmentLoader [context]="that" [chunkFetcher]="fetchMoreSystems" [loaderBackground]="'#ffffff'">
        <app-cp-system-list-item *ngFor="let iSystem of systems.systems; let i = index" [systemItem]='iSystem' [showInstaller]='true' [showBorder]="systems.systems.length-1 !== i" [showDelete]="true"></app-cp-system-list-item>
    </app-fragmenter>
</div>
<div id="cph_sys_history" *ngIf="us.currentUser.role === 2" (click)="openSystemHistory()">{{trans('systems.titles.history')}}</div>
<app-system-find-popup #searchPopup></app-system-find-popup>
<app-plus-button (plusClicked)="addSystem()" *ngIf="us.currentUser.role === 2 || us.currentUser.role === 3"></app-plus-button>