/* eslint-disable no-shadow */
/* eslint-disable id-blacklist */
export enum PopupType {
  None,
  Password,
  String,
  Number,
  NumberWithUnitList,
  TimeZone,
  IPAddress,
  Slideout,
  SlideoutWithValue,
}
