import { Inject, Injectable, Injector, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { SystemData } from 'src/api/v3/common';
import { DbSystemData } from 'src/api/v3/system';
import { DataTableGetter } from 'src/app/company/components/data-table/data-table.component';
import { TSystemData } from 'src/app/models/system-data';
import { autoinject } from 'src/shim';
import { AuthService } from '../auth.service';
import { RequestService } from '../request.service';
import { RtcService } from '../rtc/rtc.service';
import { UserService } from '../user.service';
import { AreaService } from './area.service';
import { CameraService } from './camera.service';
import { EventService } from './event.service';
import { PgmService } from './pgm.service';
import { SensorService } from './sensor.service';
import { ThermostatService } from './thermostat.service';
import { ZoneService } from './zone.service';

@Injectable({
  providedIn: 'root',
})
export class SystemService implements OnDestroy {
  public readonly systems = new Map<number, TSystemData>();
  public readonly partialSystems = new Map<number, Partial<DbSystemData>>();
  public readonly pendingSystemms = new Map<number, Promise<TSystemData>>();

  private eventService = autoinject(this.injector, EventService);
  private areaService = autoinject(this.injector, AreaService);
  private zoneService = autoinject(this.injector, ZoneService);
  private pgmService = autoinject(this.injector, PgmService);
  private sensorService = autoinject(this.injector, SensorService);
  private thermostatService = autoinject(this.injector, ThermostatService);
  private cameraService = autoinject(this.injector, CameraService);
  private userService = autoinject(this.injector, UserService);

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.systems.clear();
    this.pendingSystemms.clear();
  });
  constructor(private auth: AuthService, private req: RequestService, private sanitzer: DomSanitizer, private injector: Injector, private rtc: RtcService) {}
  ngOnDestroy(): void {
    this.cleanupSubscribtion.unsubscribe();
  }

  public ingestSystem(system?: SystemData | null): TSystemData | undefined {
    if (!system) return;

    // TODO: Ingest Areas

    system.events.events.forEach((event) => this.eventService().ingestEvent(event, system.id));
    system.areas.forEach((area) => this.areaService().ingestArea(area));
    system.zones.forEach((zone) => this.zoneService().ingestZone(zone));
    system.pgms.forEach((pgm) => this.pgmService().ingestPgm(pgm, system.id));
    system.sensors.forEach((sensor) => this.sensorService().ingestSensor(sensor));
    system.thermostats.forEach((thermostat) => this.thermostatService().ingestThermostat(thermostat));
    system.cameras.forEach((camera) => this.cameraService().ingestCamera(camera));
    system.protegus_users.forEach((user) => this.userService().ingestProtegusUser(user, system.id));
    system.device_users.forEach((user) => this.userService().ingestDeviceUser(user));

    const processsedSystem: TSystemData = {
      id: system.id,
      areas: [],
      pgms: [],
      notifications: [], // TODO: Ingest Notifications
      sensors: [],
      zones: [],
      events: [],
      protegus_users: [], // TODO: Ingest Protegus Users
      device_users: [], // TODO: Ingest Device Users
      cameras: [],
      thermostats: [],
      name: system.name,
      online: system.online,
      supported_commands: system.supported_commands,
      amIMaster: true, // TODO: Remove
      mpass: system.mpass,
      address: system.address,
      timeZone: system.timeZone,
      imei: system.imei,
      // notificationSoundsEnabled: system.notificationSoundsEnabled,
      notificationSoundsEnabled: true,
      // notificationsEnabled: system.notificationsEnabled,
      notificationsEnabled: true,
      signalLevel: system.signalLevel,
      hwType: system.hwType,
      canBypassZone: system.canBypassZone, // TODO: Maybe Remove?
      canUnbypassZone: system.canUnbypassZone, // TODO: Maybe Remove?
      directControl: !!system.direct,
      noSleepStay: system.noSleepStay,
      maxDeviceUsers: system.maxDeviceUsers,
      panel: system.centralPanel,
      coordinates: system.coordinates,
      eventConfiguration: JSON.parse(system.eventConfiguration),
      canEditUsers: system.canEditUsers, // TODO: Remove
      theme: {
        startColor: system.theme?.background_start,
        endColor: system.theme?.background_end,
        fullBackground: system.theme?.full_background,
      },
      installerId: system.installer_id,
      installerEmail: '', // TODO: lookup installer email
      installerName: '', // TODO: lookup installer name
      // logo: system.logo ? this.sanitzer.bypassSecurityTrustUrl(system.logo) : '',
      logo: '',
      company_id: system.company_id,
      assistedById: undefined, // TODO: load assistance data
      assistedByEmail: '', // TODO: load assistance data
      supportsFireReset: system.supportsFireReset, // TODO: Remove
      amIWorking: system.amIWorking ?? false, // What's this used for?
      privacyOfOwners: system.privacyOfOwners ?? [],
      owners: system.owners ?? [],
      deviceId: system.device_id ?? 0,
    };
    Object.defineProperties(processsedSystem, {
      events: {
        get: () => [...(this.eventService().systemEvents.get(processsedSystem.id)?.values() ?? [])].map((id) => this.eventService().events.get(id)),
      },
      areas: {
        get: () => [...(this.areaService().systemAreas.get(processsedSystem.id)?.values() ?? [])].map((id) => this.areaService().areas.get(id)),
      },
      zones: {
        get: () => [...(this.zoneService().systemZones.get(processsedSystem.id)?.values() ?? [])].map((id) => this.zoneService().zones.get(id)),
      },
      pgms: {
        get: () => [...(this.pgmService().systemPgms.get(processsedSystem.id)?.values() ?? [])].map((id) => this.pgmService().pgms.get(id)),
      },
      sensors: {
        get: () => [...(this.sensorService().systemSensors.get(processsedSystem.id)?.values() ?? [])].map((id) => this.sensorService().sensors.get(id)),
      },
      thermostats: {
        get: () => [...(this.thermostatService().systemThermostats.get(processsedSystem.id)?.values() ?? [])].map((id) => this.thermostatService().thermostats.get(id)),
      },
      cameras: {
        get: () => [...(this.cameraService().systemCameras.get(processsedSystem.id)?.values() ?? [])].map((id) => this.cameraService().cameras.get(id)),
      },
      protegus_users: {
        get: () => [...(this.userService().systemUsers.get(processsedSystem.id)?.values() ?? [])].map((id) => this.userService().users.get(id)),
      },
      device_users: {
        get: () => [...(this.userService().systemDeviceUsers.get(processsedSystem.id)?.values() ?? [])].map((id) => this.userService().deviceUsers.get(id)),
      },
    });
    this.systems.set(processsedSystem.id, processsedSystem);
    this.rtc.updateIntrests([...new Set([...this.systems.keys(), ...this.partialSystems.keys()])]);
    return processsedSystem;
  }

  public async getSystem(id: number): Promise<TSystemData> {
    if (this.systems.has(id)) {
      return this.systems.get(id);
    }
    if (this.pendingSystemms.has(id)) {
      return await this.pendingSystemms.get(id);
    }
    const promise = new Promise<TSystemData>(async (resolve) => {
      const res = await this.req.system.getSystem({ system_id: id }).toPromise();
      if (res.success) {
        return resolve(this.ingestSystem(res.system));
      }
    });
    this.pendingSystemms.set(id, promise);
    const system = await promise;
    this.pendingSystemms.delete(id);
    return system;
  }

  public async loadPartialSystemData(): Promise<void> {
    const res = await this.req.system.getSystemsLite({ columns: ['id', 'imei', 'name'], limit: 100 }).toPromise();
    if (res.success) {
      const allSystems = res.totalSystems;

      res.systems.forEach((system) => this.partialSystems.set(system.id, system));
      while (this.partialSystems.size < allSystems) {
        const resl = await this.req.system.getSystemsLite({ offset: this.partialSystems.size, columns: ['id', 'imei', 'name'], limit: 100 }).toPromise();
        if (resl.success) {
          resl.systems.forEach((system) => this.partialSystems.set(system.id, system));
        }
      }
      this.rtc.updateIntrests([...new Set([...this.systems.keys(), ...this.partialSystems.keys()])]);
    }
  }

  public getSystemName(id: number): string {
    if (this.partialSystems.has(id)) {
      return this.partialSystems.get(id).name;
    }
    if (this.systems.has(id)) {
      return this.systems.get(id).name;
    }
    return 'Unknown';
  }

  public getSystemsGetter(): DataTableGetter<TSystemData> {
    return async (current, columns, more) => {
      if (!more && current <= this.systems.size) {
        return [...this.systems.values()].sort((a, b) => a.name.localeCompare(b.name));
      }
      await this.req.system
        .getSystems({
          offsetCount: current,
        })
        .pipe(
          map((result) => {
            if (result.success) {
              const systems = result.systems.filter((s) => s.hwType !== '').map((s) => this.ingestSystem(s));
              return systems;
            }
            return [];
          })
        )
        .toPromise();
      return [...this.systems.values()].sort((a, b) => a.name.localeCompare(b.name));
    };
  }
}
