import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TimezoneService } from 'src/app/services/timezone.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-time-zone-select',
  templateUrl: './time-zone-select.component.html',
  styleUrls: ['./time-zone-select.component.scss'],
})
export class TimeZoneSelectComponent implements OnInit {
  public timeZone = 'UTC';
  public fieldIsFocused = false;
  @Input() isReadOnly = false;
  @Input() initialValue = 'UTC';
  @Output() valueChanged: EventEmitter<any> = new EventEmitter();

  constructor(public ts: TimezoneService, public ls: LanguageService) {}

  ngOnInit(): void {
    this.timeZone = this.initialValue;
  }

  public newValueSelected() {
    this.valueChanged.emit(this.timeZone);
  }
}
