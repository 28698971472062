import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { GlobalService } from 'src/app/services/global.service';
import { LanguageService } from 'src/app/services/language.service';
import { MiniStatusService } from 'src/app/services/mini-status.service';
import { SystemsService } from 'src/app/services/systems.service';
import { ToasterService } from 'src/app/services/toaster.service';

@Component({
  selector: 'app-system-find-popup',
  templateUrl: './system-find-popup.component.html',
  styleUrls: ['./system-find-popup.component.scss'],
})
export class SystemFindPopupComponent implements OnInit {
  @ViewChild('basePopup') basePopup;
  @ViewChild('phraseEdit') phraseEdit;
  public systemToFind = '';

  constructor(
    public lang: LanguageService,
    private rs: ApiRequestService,
    private ms: MiniStatusService,
    private t: ToasterService,
    private router: Router,
    private ss: SystemsService,
    private g: GlobalService
  ) {}

  ngOnInit(): void {}

  public confirmSearch() {
    this.basePopup.hidePopup();
    this.ms.show(this.lang.get('general.pleaseWait'));
    this.router.navigate([this.g.getHomeUrl() + '/filtered-systems']);
    const that = this;
    this.rs.post('/filter-systems', { phrase: this.systemToFind }, true).subscribe(
      (result) => {
        that.ms.hide();
        if (!result.success) {
          that.t.postError(result.error);
        } else {
          that.ss.filteredSystems = [];
          for (const iSystem of result.list) {
            that.ss.filteredSystems.push(that.ss.convertSystemFromRaw(iSystem));
          }
        }
      },
      (error) => {
        that.t.postError(that.lang.get('auth.errors.serverSideError'));
        that.ms.hide();
      }
    );
  }

  public show() {
    this.basePopup.show();
    this.phraseEdit.focusEdit();
  }
}
