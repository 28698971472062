<div>
    <div class="padding16">
        <app-text-edit id='pr-email' [isReadOnly]='true' [value]='userToUse.email' [inputType]="'email'">{{trans('auth.form.email')}}</app-text-edit>
    </div>
    <div class="white_round padding16">
        <app-text-edit id='pr-name' [value]='userToUse.name' (valueChanged)="userToUse.name = $event; userDataChanged = true" [isReadOnly]="!myOwnProfile()">{{trans('users.labels.nameProfile')}}</app-text-edit>
        <app-text-edit id='pr-phone' [value]='userToUse.phone' (valueChanged)="userToUse.phone = $event; userDataChanged = true" [isReadOnly]="!myOwnProfile()">{{trans('users.labels.phoneNumber')}}</app-text-edit>
        <app-text-edit *ngIf='myOwnProfile() && us.currentUser.role === 3' [value]="userToUse.company" (valueChanged)="userToUse.company = $event; userDataChanged = true">{{trans('users.labels.company')}}</app-text-edit>
        <app-name-value [nameText]="trans('users.labels.company')" *ngIf='us.currentUser.role === 2'>{{us.currentUser.company}}</app-name-value>
        <app-name-value *ngIf="myOwnProfile()" [nameText]="trans('users.labels.dateTimeFormat')" (clicked)="changeDateFormat()">{{dateFormatText}}</app-name-value>
        <app-button-single-line-arrow *ngIf='myOwnProfile() && (us.currentUser.role !== 2 || us.currentUser.company_id === 0)' id='pr-pass' (click)='goChangePass()'>{{trans('users.buttons.changePassword')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf='myOwnProfile() && us.currentUser.role !== 0' id='pr-download' (click)='doDownloadAccount()'>{{trans('users.buttons.downloadAccount')}}</app-button-single-line-arrow>
        <app-button-single-line-arrow *ngIf='myOwnProfile() && us.currentUser.role !== 0 && (us.currentUser.role !== 2 || us.currentUser.company_id === 0)' id='pr-delete' (click)='goDeleteAccount()'>{{trans('users.labels.deleteProfile')}}</app-button-single-line-arrow>
        <div *ngIf="us.currentUser.role === 3" id="pr_cp_logo">
            <input id="pr_cp_input" type="file" name="cpicon" accept="image/" (change)="handleNewFile($event.target.files)">
            <label for="pr_cp_input">
                <div id="pr_cp_upload">
                    <img id="pr_cp_preview" [src]="fileToUploadUrl" *ngIf="fileToUploadUrl !== null" (load)="imageLoaded($event.target)">
                    <img id="pr_cp_preview" [src]="us.currentUser.logo" *ngIf="us.currentUser.logo !== '' && fileToUploadUrl === null">
                    <div id="pr_cp_icon" *ngIf="fileToUploadUrl === null && us.currentUser.logo === ''" [inlineSVG]="'assets/images/upload.svg'"></div>
                </div>
            </label>
            <div id="pr_cp_text">{{trans('settings.company.logo')}}</div>
        </div>
    </div>
    <div class="separator16"></div>
    <div class="white_round" id='pr-lang'>
        <app-button-single-line-arrow *ngIf='myOwnProfile()' (click)='languageClick()'>{{trans('systems.titles.language')}} (<span id='pr-l-subcode'>{{getLangSubCode()}}</span>)</app-button-single-line-arrow>
    </div>
</div>