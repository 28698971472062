import { ChangeDetectorRef, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings } from 'src/app-settings';
import { LanguageAware } from 'src/app/general/language-aware';
import { UserRole } from 'src/app/models/user-role';

@Component({
  selector: 'app-su-all-users',
  templateUrl: './su-all-users.component.html',
  styleUrls: ['./su-all-users.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SuAllUsersComponent extends LanguageAware implements OnInit {
  @ViewChild('searchPopup') userFind;
  public canShowDelete = AppSettings.canDeleteUserFromList;

  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.users'),
      backUrl: '/sadmin',
      showFind: true,
    });
    this.background.setGray();

    if (this.us.superAdmins.length === 0) {
      this.getUsers();
    }
    this.helper.setCountForBottomButton(1, 1, 1, false, true, false, 'suau');
    if (this.us.countries.length === 0) {
      this.loadCountries();
    }
    this.headerBar.onActionButtonClicked = () => {
      this.userFind.show();
    };
  }

  ngOnInit(): void {}

  public goToProfile() {
    this.router.navigate(['/profile']);
  }

  private getUsers() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    this.api.post('/all-users', { all: AppSettings.loadAllUsersForSU }, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          that.us.superAdmins = result.users.filter((u) => u.role === UserRole.SuperAdmin);
          that.us.companies = result.users.filter((u) => u.role === UserRole.Company);
          that.us.companyInstallers = result.users.filter((u) => u.role === UserRole.Installer);
          that.us.simpleUsers = result.users.filter((u) => u.role === UserRole.SimpleUser);
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
      }
    );
  }

  public addUser() {
    this.router.navigate(['/sadmin/add-user']);
  }

  public editUser(userId: number) {
    this.router.navigate(['/sadmin/edit-user/' + userId]);
  }

  private loadCountries() {
    const that = this;
    this.api.post('/countries', {}, true).subscribe(
      (result) => {
        if (result.success) {
          that.us.countries = result.list;
        }
      },
      (error) => {
        // nieko nedarom
      }
    );
  }

  public deleteUser(user, event) {
    event.stopPropagation();
    const that = this;
    const userToHole = JSON.stringify(user);
    if (user.role === UserRole.Installer) {
      that.us.companyInstallers = that.us.companyInstallers.filter((ci) => ci.id !== user.id);
    } else if (user.role === UserRole.SuperAdmin) {
      that.us.superAdmins = that.us.superAdmins.filter((su) => su.id !== user.id);
    } else if (user.role === UserRole.Company) {
      that.us.companies = that.us.companies.filter((cp) => cp.id !== user.id);
    } else if (user.role === UserRole.SimpleUser) {
      that.us.simpleUsers = that.us.simpleUsers.filter((cp) => cp.id !== user.id);
    }
    this.api.post('/delete/cuser', { user_id: user.id }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.toaster.postError(result.error);
          that.revertDeletion(userToHole);
        }
      },
      (error) => {
        that.toaster.postError(that.trans('auth.error.serverSideError'));
        that.revertDeletion(userToHole);
      }
    );
  }

  private revertDeletion(userBefore: any) {
    const user = JSON.parse(userBefore);
    if (user.role === UserRole.Installer && this.us.companyInstallers.find((ci) => ci.id === user.id) === undefined) {
      this.us.companyInstallers.push(user);
    } else if (user.role === UserRole.SuperAdmin && this.us.superAdmins.find((su) => su.id === user.id) === undefined) {
      this.us.superAdmins.push(user);
    } else if (user.role === UserRole.Company && this.us.companies.find((cp) => cp.id === user.id) === undefined) {
      this.us.companies.push(user);
    } else if (user.role === UserRole.SimpleUser && this.us.simpleUsers.find((cp) => cp.id === user.id) === undefined) {
      this.us.simpleUsers.push(user);
    }
  }
}
