import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { NewSystemService } from 'src/app/services/new-system.service';

@Component({
  selector: 'app-wiring',
  templateUrl: './wiring.component.html',
  styleUrls: ['./wiring.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class WiringComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private ns: NewSystemService) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.wizzard.wiringDiagram'),
      backUrl: '/add-system-details',
    });
    this.background.setGray();
  }

  ngOnInit(): void {}
}
