import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { LanguageService } from 'src/app/services/language.service';
import { BasePopupComponent } from '../base-popup/base-popup.component';
import { MessageBoxButtons } from './messagebox-buttons';

@Component({
  selector: 'app-messagebox',
  templateUrl: './messagebox.component.html',
  styleUrls: ['./messagebox.component.scss'],
})
export class MessageboxComponent implements OnInit {
  public MessageBoxButtons = MessageBoxButtons;
  @Input() buttons: MessageBoxButtons = MessageBoxButtons.Ok;
  @Input() button1Text = '';
  @Input() button2Text = '';
  @Input() showCloseButton = true;
  @Input() canCloseByOverlay = false;
  @Input() messageContent = '';
  @Input() headerText = '';
  @Output() okClicked: EventEmitter<any> = new EventEmitter();
  @Output() yesClicked: EventEmitter<any> = new EventEmitter();
  @Output() noClicked: EventEmitter<any> = new EventEmitter();
  @Output() cancelClicked: EventEmitter<any> = new EventEmitter();
  @Output() button1Clicked: EventEmitter<any> = new EventEmitter();
  @Output() button2Clicked: EventEmitter<any> = new EventEmitter();

  @ViewChild('basePopup') basePopup: BasePopupComponent;

  constructor(public l: LanguageService) {}

  ngOnInit(): void {}

  public clickOk() {
    this.okClicked.emit();
    this.basePopup.hidePopup();
  }

  public clickYes() {
    this.yesClicked.emit();
    this.basePopup.hidePopup();
  }

  public clickNo() {
    this.noClicked.emit();
    this.basePopup.hidePopup();
  }

  public clickCancel() {
    this.cancelClicked.emit();
    this.basePopup.hidePopup();
  }

  public clickButton1() {
    this.button1Clicked.emit();
    this.basePopup.hidePopup();
  }

  public clickButton2() {
    this.button2Clicked.emit();
    this.basePopup.hidePopup();
  }

  public basePopupCancelled() {
    this.cancelClicked.emit();
  }

  public show() {
    this.basePopup.show();
  }
}
