import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { UserRole } from 'src/api/v3/common';
import { AuthService } from 'src/app/api/auth.service';
import { LocaleService } from 'src/app/api/locale.service';
import { RequestService } from 'src/app/api/request.service';
import { UserService } from 'src/app/api/user.service';
import { DropdownItem } from 'src/app/ui/dropdown/dropdown.component';
import { customPasswordValidatorBuilder, ValidatorBuilder } from 'src/app/ui/validator';
import { countryList } from 'src/environments/countrylist';
import { UResolvable } from '../../../guards/UResolvable';

const validation = new ValidatorBuilder<{
  name: string;
  email: string;
  phone: string;
  country: string;
  role: UserRole;
  active: boolean;
  password: string | null;
}>()
  .required('email', 'Email is required')
  .regex('email', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Email is invalid')
  .regex('email', /^.{1,255}$/, 'Email is too long')
  .regex('name', /^.{1,23}$/, 'Name is too long. Max 23 characters')
  .regex('phone', /^\+?[0-9]{6,15}$/, 'Phone is invalid')
  .required('country', 'Country is required')
  .inList('country', countryList, 'Invalid country')
  .inList('role', [UserRole.SuperAdmin, UserRole.Company, UserRole.Installer, UserRole.SimpleUser], 'Role is required')
  .custom('password', customPasswordValidatorBuilder({}))
  .required('password', 'Password is required')
  .useOnlyWhen((component: CompanyUserEditComponent) => component.isNew);

@Component({
  templateUrl: './company-user-edit.component.html',
  styleUrls: ['./company-user-edit.component.scss'],
})
export class CompanyUserEditComponent implements OnInit, OnDestroy, UResolvable<typeof CompanyUserEditComponent> {
  public email = '';
  public name = '';
  public phone = '';
  public country = 'US';
  public role = UserRole.SimpleUser;
  public active = false;
  public password = null;

  public val = validation.build().bindContext(this);
  public vp = this.val.proxify(this, ['name', 'email', 'phone', 'country', 'role', 'active', 'password']);

  public countryList: DropdownItem<string>[];
  public roleList: DropdownItem<UserRole>[] = [
    {
      label: 'User',
      value: UserRole.SimpleUser,
      default: true,
    },
    {
      label: 'Installer',
      value: UserRole.Installer,
    },
    {
      label: 'Company Admin',
      value: UserRole.Company,
    },
    {
      label: 'Super Admin',
      value: UserRole.SuperAdmin,
    },
  ];
  public readonly isNew = this.route.routeConfig.path === 'new';

  public get shownRoleList(): DropdownItem<UserRole>[] {
    switch (this.user.user?.role) {
      case UserRole.SuperAdmin:
        return this.roleList;
      case UserRole.Company:
        return [this.roleList[0], this.roleList[1]];
      case UserRole.Installer:
        return [this.roleList[0]];
      default:
        return this.roleList; // Duodam visas roles nes šiuo atvėju paprastas user neturėtų būti čia.
    }
  }

  private userIdChangeSubscribtion = this.route.params.subscribe((params) => {
    const userId = Number(params.userId);
    if (userId && !isNaN(userId)) {
      const user = this.user.users.get(userId);
      if (!user) {
        throw new Error('User not found');
      }
      this.email = user.email;
      this.name = user.name;
      this.phone = user.phone;
      this.country = user.country;
      this.role = user.role;
      this.active = !!user.active;
      this.val.validate(this);
    }
  });

  constructor(private route: ActivatedRoute, private locale: LocaleService, private user: UserService, private req: RequestService) {
    const labaler = (code: string) => this.locale.getCountryName(code);
    this.countryList = countryList.map((code) => ({
      value: code,
      label: labaler,
    }));
    this.countryList.unshift({
      value: '',
      label: 'Select country',
      default: true,
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.userIdChangeSubscribtion.unsubscribe();
  }

  public static async resolve(injector: Injector, route: ActivatedRouteSnapshot): Promise<void> {
    const users = injector.get(UserService);
    const auth = injector.get(AuthService);
    const promises: Promise<any>[] = [];
    if (!users.user) {
      promises.push(auth.loadUserData());
    }
    if (route.routeConfig.path !== 'new' && !users.users.size) {
      // promises.push(users.loadUser(userId));
      promises.push(users.loadAllUsers()); // Neturime veno user get api.
    }
    await Promise.all(promises);
  }

  public async onSubmit(): Promise<void> {
    if (!this.val.validate(this)) {
      if (this.val.hasError('password') && !this.isNew) {
        this.vp.password = null;
        if (!this.val.validate(this)) return;
      }
      return;
    }
    const userId = Number(this.route.snapshot.params.userId);
    if (this.isNew) {
      const res = await this.req.user
        .editUser({
          id: 0,
          email: this.email,
          name: this.name,
          phone: this.phone,
          country: this.country,
          role: this.role,
          active: this.active ? 1 : 0,
          password: this.password,
        })
        .toPromise();
      if (res.success) {
        this.user.loadAllUsers();
      }
    } else {
      const res = await this.req.user
        .editUser({
          id: userId,
          name: this.name,
          email: this.email,
          phone: this.phone,
          country: this.country,
          role: this.role,
          active: this.active ? 1 : 0,
          changePassword: this.password ? 'on' : undefined,
          newPass: this.password ? this.password : undefined,
        })
        .toPromise();

      if (res.success) {
        const localUser = this.user.users.get(userId);
        if (localUser) {
          localUser.name = this.name;
          localUser.email = this.email;
          localUser.phone = this.phone;
          localUser.country = this.country;
          localUser.role = this.role;
          localUser.active = this.active ? 1 : 0;
        }
      }
    }
  }
}
