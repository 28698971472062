import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Location } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-password-new',
  templateUrl: './password-new.component.html',
  styleUrls: ['./password-new.component.scss'],
})
export class PasswordNewComponent extends LanguageAware implements OnInit {
  public isRegistration = false;
  public isReset = false;
  private resetHash = '';
  public password = '';
  private controlsEnabled = true;

  constructor(cdRef: ChangeDetectorRef, private route: ActivatedRoute, l: Location, private router: Router) {
    super(cdRef);
    this.tag = 'PassNew';
    this.headerBar.showHeader({
      backUrl: '/email',
      showLogo: true,
    });
    const hash = this.route.snapshot.paramMap.get('hash');
    const that = this;
    if (l.path() === '/password-register') {
      this.footerBar.showFooter(this.trans('auth.buttons.next'), '', true, false);
      this.progressBar.showProgress();
      this.isRegistration = true;
      this.footerBar.onButton1Click = () => {
        that.onNext();
      };
    } else if (hash !== null) {
      this.resetHash = hash;
      this.isReset = true;
      this.footerBar.showFooter(this.trans('auth.buttons.reset'), '', true, false);
      this.footerBar.onButton1Click = () => {
        that.resetClick();
      };
    }
  }

  ngOnInit() {
    if (this.isRegistration) {
      this.progressBar.setProgressData(3, 4);
    }
  }

  onNext() {
    if (!this.controlsEnabled) {
      if (this.miniStatus.isVisible()) {
        this.miniStatus.flash();
      }
      return;
    }
    this.password = this.password.trim();
    if (this.password === null || this.password === '') {
      this.toaster.postError(this.trans('validation.required').replace(':attribute', this.trans('auth.form.password')));
      return;
    }
    if (this.password.length < 8) {
      this.toaster.postError(this.trans('validation.min.string').replace(':attribute', this.trans('validation.attributes.password')).replace(':min', '8'));
      return;
    }
    const that = this;
    this.controlsEnabled = false;

    this.miniStatus.show(this.trans('auth.activation.creating'));
    this.api
      .post(
        '/password-create',
        {
          password: this.password,
          passHash: this.us.currentUser.passwordToken,
          email: this.us.currentUser.email,
        },
        false
      )
      .subscribe(
        (result) => {
          that.miniStatus.hide();
          that.controlsEnabled = true;
          if (result.success) {
            that.doLogin();
          } else {
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
  }

  loginClick() {
    this.router.navigate(['/login']);
  }

  resetClick() {
    if (!this.controlsEnabled) {
      if (this.miniStatus.isVisible()) {
        this.miniStatus.flash();
      }
      return;
    }
    this.password = this.password.trim();
    if (this.password === null || this.password === '') {
      this.toaster.postError(this.trans('validation.required').replace(':attribute', this.trans('auth.form.password')));
      return;
    }
    if (this.password.length < 8) {
      this.toaster.postError(this.trans('validation.min.string').replace(':attribute', this.trans('validation.attributes.password')).replace(':min', '8'));
      return;
    }
    const that = this;
    this.controlsEnabled = false;
    this.miniStatus.show(this.trans('auth.reseting'));
    this.api
      .post(
        '/reset/complete',
        {
          lang: this.ls.selectedLanguage.value,
          token: this.resetHash,
          password: this.password,
        },
        false
      )
      .subscribe(
        (result) => {
          that.controlsEnabled = true;
          that.miniStatus.hide();
          if (result.success) {
            that.toaster.postSuccess(result.message);
            that.router.navigate(['/email']);
          } else {
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.log('', error);
          that.miniStatus.hide();
          that.controlsEnabled = true;
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
        }
      );
  }

  public passwordSubmit() {
    this.footerBar.onButton1Click();
  }

  private doLogin() {
    const that = this;
    this.controlsEnabled = false;
    this.miniStatus.show(this.trans('auth.loggingIn'));
    this.api
      .post(
        '/login',
        {
          email: this.us.currentUser.email,
          password: this.password,
          remember: false,
        },
        false
      )
      .subscribe(
        (response) => {
          if (response.success) {
            that.api.setToken(response.token);
            that.us.setActiveUser(response);
            that.us.setLoginType('default');
            that.systems.loadSystems();
            that.router.navigate(['/name-register']);
          } else {
            that.toaster.postError(response.error);
          }
          that.controlsEnabled = true;
          that.miniStatus.hide();
        },
        (error) => {
          that.controlsEnabled = true;
          that.log('', error);
          that.toaster.postError(that.trans('auth.failed'));
          that.miniStatus.hide();
        }
      );
  }
}
