<div class="long_press_menu_button" #button (mousedown)="startLongPress()" (touchstart)="startLongPress()" (mouseup)="endLongPress()" (touchend)="endLongPress()" (mouseleave)="leaveComponent()" (contextmenu)="showContextMenu($event)">
    <ng-content select="[slot=content]"></ng-content>
</div>
<div #container>
    <div class="long_press_menu_overlay" #overlay (click)="hideMenu(); $event.stopPropagation()">
        <div class="long_press_menu_popup" #popup>
            <div [ngClass]="{ 'pgm_tooltip': true, 'pgm_tooltip_visible': shouldFadeIn }">
                <div class="pgm_tooltip_bottom" #popupButton>
                    <ng-content select="[slot=menu]"></ng-content>
                </div>
                <i #tip></i>
            </div>
        </div>
    </div>
</div>