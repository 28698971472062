<div id="nso_title">{{trans('systems.titles.deviceOffline')}}</div>
<div id="nso_uid">{{trans('systems.labels.imei')}}:{{' ' + ns.getUID()}}</div>
<div class="nso_step" [innerHtml]="trans('systems.labels.deviceCheckSteps.step1')"></div>
<div class="nso_step" [innerHtml]="trans('systems.labels.deviceCheckSteps.step2')"></div>
<div class="nso_step" [innerHtml]="trans('systems.labels.deviceCheckSteps.step3')"></div>
<div id="nso_retry_container">
    <div class="button" (click)="retry()"><div [inlineSVG]="'assets/images/refresh.svg'"></div>{{trans('general.retry')}}</div>
</div>
<div id="nso_or">{{trans('general.or')}}</div>
<div class="white_round">
    <app-button-double-line-arrow [firstLine]="trans('systems.labels.startWifiConfiguration')" secondLine="{{AppSettings.wifiGateDevice37Name}}" [icon]="'assets/images/wifi.svg'" [showArrow]="true" (click)="startWifiPath(55)" [withBorder]="true"></app-button-double-line-arrow>
    <app-button-double-line-arrow [firstLine]="trans('systems.labels.startWifiConfiguration')" secondLine="SP3" [icon]="'assets/images/wifi.svg'" [showArrow]="true" (click)="startWifiPath(59)"></app-button-double-line-arrow>
</div>
<app-loader-round [isVisible]="miniStatus.isVisible()" [overlayIsGray]="true" [displayText]="trans('general.checking')"></app-loader-round>