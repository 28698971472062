import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { NewSystemService } from 'src/app/services/new-system.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-add-system-complete',
  templateUrl: './add-system-complete.component.html',
  styleUrls: ['./add-system-complete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddSystemCompleteComponent extends LanguageAware implements OnInit, AfterViewInit {
  public showCustomization = false;

  constructor(cdRef: ChangeDetectorRef, private ns: NewSystemService, private router: Router) {
    super(cdRef);
    if (!this.ns.isDirect() && this.systems.activeSystem.hwType !== 'GV17') {
      this.footerBar.showFooter(this.trans('auth.buttons.finish'), '', false, false);
    } else {
      this.showCustomization = true;
      this.footerBar.showFooter(this.trans('general.skip'), '', false, false);
    }
    this.background.setGray();
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.onDone();
    };
    this.progressBar.showProgress();
  }

  ngOnInit(): void {
    this.progressBar.setProgressData(7, 7);
  }

  ngAfterViewInit() {}

  private onDone() {
    this.ns.setDefaults();
    this.router.navigate([this.g.getHomeUrl()]);
  }

  public doCustomize() {
    if (this.systems.activeSystem.hwType === 'GV17') {
      localStorage.setItem('ref', 'new');
      this.router.navigate(['/customize-pgms']);
    } else {
      this.router.navigate(['/customize-areas']);
    }
  }
}
