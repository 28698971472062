import { Component, OnInit, ChangeDetectorRef, HostListener, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-activate-email',
  templateUrl: './activate-email.component.html',
  styleUrls: ['./activate-email.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ActivateEmailComponent extends LanguageAware implements OnInit {
  public digitOne = '';
  public digitTwo = '';
  public digitThree = '';
  public pressedButton = -1;

  constructor(cdRef: ChangeDetectorRef, private router: Router, private route: ActivatedRoute) {
    super(cdRef);
    this.tag = 'EmailActivate';
    this.progressBar.showProgress();
    this.footerBar.showFooter(this.trans('auth.buttons.next'), '', true, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.onNext();
    };

    const hash = this.route.snapshot.paramMap.get('hash');
    const reghash = this.route.snapshot.paramMap.get('reghash');
    if (hash) {
      this.headerBar.showHeader({
        showLogo: true,
      });
      this.log('turim aktyvacijos hash');
      this.activateByHash(hash);
    } else if (reghash !== null) {
      this.headerBar.showHeader({
        showLogo: true,
      });
      this.log('turim registracijos hash');
      this.activateByHash(reghash);
    } else {
      this.headerBar.showHeader({
        backUrl: '/email',
        showLogo: true,
      });
    }
  }

  @HostListener('document:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key >= '0' && event.key <= '9') {
      this.enterDigit(event.key);
    } else if (event.key === 'Enter') {
      this.onNext();
    }
  }

  @HostListener('document:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    if (event.keyCode !== undefined && (event.keyCode === 8 || event.keyCode === 46)) {
      this.enterDigit('10');
      event.preventDefault();
      return false;
    } else if (event.key === 'Delete' || event.key === 'Backspace') {
      // backspace or delete
      this.enterDigit('10');
      event.preventDefault();
      return false;
    }
  }

  ngOnInit() {
    this.progressBar.setProgressData(2, 4);
  }

  public loginClick() {
    this.router.navigate(['/login']);
  }

  public resendClick() {
    const that = this;
    this.miniStatus.show(this.trans('general.sending'));
    this.api
      .post(
        '/resend-activation',
        {
          pinHash: this.us.currentUser.ActivationToken,
          lang: this.ls.selectedLanguage.value,
          email: this.us.currentUser.email,
        },
        false
      )
      .subscribe(
        (result) => {
          that.miniStatus.hide();
          if (result.success) {
            that.log('resend success');
            that.toaster.postSuccess(result.message);
            if (this.us.currentUser.ActivationToken === '' && result.hash !== undefined) {
              this.us.currentUser.ActivationToken = result.hash;
            }
          } else {
            that.log('resend fail');
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.miniStatus.hide();
          that.log(error);
          that.toaster.postError(that.trans('auth.errors.resendActivationMailError'));
        }
      );
  }

  public onNext() {
    if (this.digitOne === '' || this.digitTwo === '' || this.digitThree === '') {
      return;
    }
    const that = this;
    const digitCode = this.digitOne[0] + this.digitTwo[0] + this.digitThree[0];
    this.miniStatus.show(this.trans('auth.activation.activating'));
    this.api
      .post(
        '/activate',
        {
          digitCode,
          pinHash: that.us.currentUser.ActivationToken,
          lang: that.ls.selectedLanguage.value,
        },
        false
      )
      .subscribe(
        (result) => {
          that.miniStatus.hide();
          if (result.success) {
            that.log('Viskas ok');
            that.us.currentUser.passwordToken = result.password_hash;
            that.router.navigate(['/password-register']);
          } else {
            if (result.error !== undefined) {
              that.toaster.postError(result.error);
            }
            that.log('Netiko kodas');
          }
        },
        (error) => {
          that.miniStatus.hide();
          that.log(error);
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
        }
      );
  }

  private activateByHash(hash: string) {
    const that = this;
    this.miniStatus.show(this.trans('auth.activation.activating'));
    this.api
      .post(
        '/activate',
        {
          hash,
          lang: that.ls.selectedLanguage.value,
        },
        false
      )
      .subscribe(
        (result) => {
          that.miniStatus.hide();
          if (result.success) {
            that.log('Viskas ok');
            that.us.currentUser.passwordToken = result.password_hash;
            that.us.currentUser.email = result.email;
            that.router.navigate(['/password-register']);
          } else {
            if (result.error !== undefined) {
              that.toaster.postError(result.error);
            }
            that.log('Netiko kodas');
          }
        },
        (error) => {
          that.miniStatus.hide();
          that.toaster.postError(this.trans('auth.errors.serverSideError'));
        }
      );
  }

  private enterDigit(digit: string) {
    if (digit >= '0' && digit <= '9' && digit !== '10') {
      if (this.digitOne === '') {
        this.digitOne = digit;
      } else if (this.digitTwo === '') {
        this.digitTwo = digit;
      } else if (this.digitThree === '') {
        this.digitThree = digit;
      }
    } else if (digit === '10') {
      // backspace or delete
      if (this.digitThree !== '') {
        this.digitThree = '';
      } else if (this.digitTwo !== '') {
        this.digitTwo = '';
      } else if (this.digitOne !== '') {
        this.digitOne = '';
      }
    }
  }

  onNumberDown(num: number) {
    this.pressedButton = num;
  }

  onNumberUp() {
    this.pressedButton = -1;
  }

  onNumberClick(num: string) {
    this.enterDigit(num);
  }
}
