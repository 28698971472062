import { Component, OnInit } from '@angular/core';
import { LoggerService } from 'src/app/api/logger.service';
import { RtcService } from 'src/app/api/rtc/rtc.service';
import { ItemConfiguration } from '../../components/company-columns/company-columns.component';

@Component({
  templateUrl: './company-home.component.html',
  styleUrls: ['./company-home.component.scss'],
})
export class CompanyHomeComponent implements OnInit {
  public readonly columnConfig: ItemConfiguration<string>[] = [
    { name: 'Icon', value: 'fake:icon' },
    { name: 'System name', value: 'name' },
    { name: 'Device', value: 'hwType' },
    { name: 'IMEI', value: 'imei' },
    { name: 'Installer', value: 'installer_id' },
    { name: 'Status', value: 'online' },
    { name: 'Delete', value: 'fake:delete' },
  ];
  public columns = ['fake:icon', 'name', 'hwType', 'imei', 'installer_id', 'online', 'fake:delete'];
  constructor(private rtc: RtcService, private l: LoggerService) {
    this.rtc.areaStatus.subscribe((area) => {
      this.l.log('area', 'Test', area);
    });
    this.rtc.configurationEvent.subscribe((event) => {
      this.l.log('config', 'Test', event);
    });
    this.rtc.events.subscribe((event) => {
      this.l.log('event', 'Test', event);
    });
    this.rtc.pgm.subscribe((event) => {
      this.l.log('pgm', 'Test', event);
    });
    this.rtc.systemStatusChange.subscribe((event) => {
      this.l.log('system', 'Test', event);
    });
    this.rtc.userAccessControl.subscribe((event) => {
      this.l.log('access', 'Test', event);
    });
    this.rtc.userSystem.subscribe((event) => {
      this.l.log('user', 'Test', event);
    });
    this.rtc.versionUpdate.subscribe((event) => {
      this.l.log('version', 'Test', event);
    });
  }

  ngOnInit(): void {}
}
