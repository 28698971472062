<div class="grid add-system-complete-grid">
    <div id='asc-header'>{{trans('systems.notifications.systemAdded')}}</div>
    <div id='asc-image' class="{{showCustomization ? 'asc_image_extended' : ''}}"><svg viewBox="0 0 318 264" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
        <g transform="matrix(1,0,0,1,-282,-122.601)">
            <g transform="matrix(1,0,0,1,505.762,182.247)">
                <path d="M3.867,1.934C3.867,0.866 3.001,0 1.934,0C0.866,0 0,0.866 0,1.934L0,29.938C0,31.006 0.866,31.872 1.934,31.872C3.001,31.872 3.867,31.006 3.867,29.938L3.867,1.934Z" style="fill:rgb(63,61,86);"/>
            </g>
            <g transform="matrix(1,0,0,1,378.473,122.601)">
                <path d="M129.375,14C129.375,6.273 123.102,0 115.375,0L14,0C6.273,0 0,6.273 0,14L0,248.936C0,256.663 6.273,262.936 14,262.936L115.375,262.936C123.102,262.936 129.375,256.663 129.375,248.936L129.375,14Z" style="fill:rgb(63,61,86);"/>
            </g>
            <g transform="matrix(1,0,0,1,-78.008,-69.55)">
                <path d="M567.849,199L553.009,199L553.009,200.785C553.009,205.425 549.19,209.244 544.55,209.244C544.548,209.244 544.547,209.244 544.545,209.244L497.345,209.244C497.343,209.244 497.342,209.244 497.34,209.244C492.7,209.244 488.881,205.425 488.881,200.785L488.881,199L474.981,199C469.344,199 464.706,203.638 464.706,209.275L464.706,437.981C464.706,443.618 469.344,448.256 474.981,448.256L567.841,448.256C573.478,448.256 578.116,443.618 578.116,437.981L578.116,209.293C578.116,209.287 578.116,209.281 578.116,209.275C578.116,203.641 573.483,199.004 567.849,199Z" style="fill:white;fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,430.681,130.453)">
                <path d="M18.066,1.833C18.066,0.821 17.245,0 16.233,0L1.833,0C0.821,0 0,0.821 0,1.833L0,1.834C0,2.846 0.821,3.667 1.833,3.667L16.233,3.667C17.245,3.667 18.066,2.846 18.066,1.834L18.066,1.833Z" style="fill:rgb(230,232,236);"/>
            </g>
            <g transform="matrix(1,0,0,1,451.48,130.205)">
                <circle cx="2.082" cy="2.082" r="2.082" style="fill:rgb(230,232,236);"/>
            </g>
            <g transform="matrix(1,0,0,1,419.477,232.823)">
                <circle cx="24.474" cy="24.474" r="24.474" style="fill:white;"/>
            </g>
            <g transform="matrix(1,0,0,1,-109.49,-188.442)">
                <path d="M585.631,442.321C585.63,460.265 570.865,475.03 552.921,475.03C534.977,475.03 520.211,460.264 520.211,442.32C520.211,424.376 534.976,409.611 552.92,409.61C552.92,409.61 552.921,409.61 552.921,409.61C570.865,409.61 585.631,424.376 585.631,442.32C585.631,442.32 585.631,442.321 585.631,442.321ZM549.14,459.636L573.4,435.372C573.797,434.977 574.02,434.439 574.02,433.879C574.02,433.319 573.797,432.781 573.4,432.386L570.415,429.422C570.021,429.025 569.484,428.801 568.925,428.801C568.365,428.801 567.828,429.025 567.434,429.422L547.644,449.212L538.404,439.95C537.584,439.134 536.238,439.134 535.418,439.95L532.433,442.95C531.617,443.77 531.617,445.116 532.433,445.936L546.152,459.65C546.547,460.047 547.085,460.27 547.645,460.27C548.205,460.27 548.743,460.047 549.138,459.65L549.14,459.636Z"/>
            </g>
            <g transform="matrix(1,0,0,1,381.38,174.437)">
                <path d="M1.95,0.975C1.95,0.437 1.513,0 0.975,0C0.437,0 0,0.437 0,0.975L0,8.457C0,8.995 0.437,9.432 0.975,9.432C1.513,9.432 1.95,8.995 1.95,8.457L1.95,0.975Z" style="fill:rgb(63,61,86);"/>
            </g>
            <g transform="matrix(1,0,0,1,381.309,191.708)">
                <path d="M2.197,1.098C2.197,0.492 1.705,0 1.099,0C0.492,0 0,0.492 0,1.098L0,15.318C0,15.924 0.492,16.416 1.099,16.416C1.705,16.416 2.197,15.924 2.197,15.318L2.197,1.098Z" style="fill:rgb(63,61,86);"/>
            </g>
            <g transform="matrix(1,0,0,1,381.344,213.868)">
                <path d="M2.091,1.045C2.091,0.468 1.623,0 1.046,0C0.468,0 0,0.468 0,1.045L0,15.512C0,16.089 0.468,16.557 1.046,16.557C1.623,16.557 2.091,16.089 2.091,15.512L2.091,1.045Z" style="fill:rgb(63,61,86);"/>
            </g>
            <g transform="matrix(1,0,0,1,-22.03,-217.229)">
                <path d="M377.46,474.88C377.574,474.991 377.749,475.013 377.888,474.935C377.743,474.937 377.599,474.919 377.46,474.88Z" style="fill:url(#_Linear1);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,-12.385,-227.025)">
                <path d="M383.984,491L383.562,493.3L383.236,495.059L382.644,498.276L376.9,502.3C376.9,502.3 376.382,501.857 375.528,501.158C372.068,498.247 368.415,495.575 364.593,493.158C363.658,492.562 362.647,492.096 361.587,491.773L383.984,491Z" style="fill:rgb(254,185,185);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,-5.183,-219.873)">
                <path d="M372.567,485.559C372.567,485.559 379.267,486.709 379.657,483.848C380.047,480.987 376.21,479.442 372.957,479.248C369.704,479.054 350.173,480.398 350.173,480.398C350.173,480.398 346.728,484.993 359.173,485.762L372.567,485.559Z" style="fill:rgb(63,61,86);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,346.994,171.302)">
                <rect x="0" y="0" width="35.037" height="41.546" style="fill:rgb(47,46,65);"/>
            </g>
            <g transform="matrix(1,0,0,1,346.994,171.491)">
                <rect x="0" y="0" width="35.037" height="41.546" style="fill-opacity:0.1;"/>
            </g>
            <g transform="matrix(1,0,0,1,75.298,-188.299)">
                <path d="M230.633,430.722C229.813,431.051 229.082,431.569 228.499,432.233C224.968,435.733 219.91,443.933 219.91,443.933C213.444,436.233 220.599,430.146 223.649,428.054C224.391,427.548 224.889,427.269 224.889,427.269L230.633,430.722Z" style="fill:rgb(254,185,185);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,-17.716,-103.805)">
                <path d="M388.93,288.22C388.93,288.22 390.654,300.281 396.588,303.153C402.522,306.025 388.93,311.392 388.93,311.392L370.36,304.492C370.36,304.492 380.891,301.045 378.784,290.892L388.93,288.22Z" style="fill:rgb(254,185,185);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,19.078,-216.184)">
                <path d="M332.224,473.16L331.804,475.451L331.463,477.217L330.871,480.434L325.127,484.452C325.127,484.452 324.609,484.013 323.755,483.314C320.294,480.402 316.639,477.729 312.815,475.314C311.881,474.718 310.869,474.252 309.809,473.929L332.224,473.16Z" style="fill:rgb(254,185,185);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,26.392,-209.035)">
                <path d="M319.941,468.836C319.941,468.836 325.952,468.879 326.313,466.01C326.674,463.141 323.037,461.604 319.941,461.41C316.845,461.216 298.029,462.649 298.029,462.649C298.029,462.649 295.36,468.066 307.195,468.834L319.941,468.836Z" style="fill:rgb(47,46,65);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,-1.429,-209.088)">
                <path d="M389.906,513.95C389.643,513.821 389.38,513.687 389.121,513.558L388.415,512.667L379.152,500.92L379.152,501.12C379.089,503.082 378.422,518.627 373.22,520.063C367.668,521.593 360.971,521.978 357.92,511.639C354.869,501.3 349.288,478.709 343.56,477.367L352.082,470.379C352.082,470.379 357.724,476.979 360.023,483.295C359.937,482.903 356.041,464.695 359.238,461.477L387.195,461.477C387.195,461.477 391.511,473.769 394.469,479.859C396.769,484.587 400.229,495.765 402.454,503.329C402.699,504.156 402.823,505.015 402.823,505.878C402.823,510.804 398.769,514.859 393.842,514.859C392.478,514.859 391.132,514.548 389.906,513.95Z" style="fill:rgb(63,61,86);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,-23.69,-172.889)">
                <path d="M411.371,428.715C407.055,431.269 388.109,430.229 381.945,429.815L380.266,429.693C380.086,429.693 380.293,429.593 380.293,429.593L380.466,427.855L385.061,416.179L409.661,401.913C409.128,404.014 408.918,406.184 409.037,408.348C409.15,416.8 411.371,428.715 411.371,428.715Z" style="fill-opacity:0.1;fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,-23.373,-172.014)">
                <path d="M411.053,427.264C406.737,429.818 387.79,428.778 381.627,428.364L379.948,428.246L379.669,428.222L379.975,428.144L381.489,427.752L384.742,414.734L409.342,400.472C408.81,402.573 408.599,404.743 408.718,406.907C408.833,415.356 411.053,427.264 411.053,427.264Z" style="fill:rgb(239,239,239);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,-23.78,-172.014)">
                <path d="M409.11,406.9C404.11,408.053 393.098,411.438 388.869,419.612C386.048,425.054 383.674,427.388 382.019,428.365L380.34,428.247C380.351,428.214 380.36,428.181 380.367,428.147L381.881,427.755L385.134,414.737L409.734,400.475C409.204,402.573 408.994,404.739 409.11,406.9Z" style="fill-opacity:0.1;fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,65.446,-188.311)">
                <path d="M234.741,427.29L240.485,430.735C239.665,431.064 238.934,431.582 238.351,432.246C237.872,430.846 234.868,428.899 233.501,428.068C234.242,427.569 234.741,427.29 234.741,427.29Z" style="fill-opacity:0.1;fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,-21.587,-215.291)">
                <path d="M377.122,471.745C376.993,471.812 376.835,471.79 376.73,471.69C376.857,471.727 376.989,471.746 377.122,471.745Z" style="fill-opacity:0.1;fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,65.63,-80.399)">
                <path d="M302.9,278.981C299.634,279.213 296.422,278.026 294.092,275.725C291.762,273.424 278.792,280.131 273.607,290.085C268.422,300.039 233.2,319.569 233.2,319.569C233.2,319.569 239.329,323.014 238.752,324.736L261.92,311.336C261.92,311.336 270.159,305.404 272.447,304.062C274.735,302.72 287.384,294.489 290.064,316.888C292.744,339.287 290.064,336.607 290.064,336.607C290.064,336.607 293.893,337.949 299.445,327.226C304.997,316.503 322.225,314.015 322.225,314.015L323.182,302.723C323.182,302.723 326.599,295.237 323.967,292.13C323.169,291.21 322.639,290.089 322.433,288.889C321.997,286.021 322.433,281.242 328.533,277.272C337.914,271.143 341.551,266.172 341.551,266.172C341.551,266.172 341.743,262.531 335.999,260.808C330.255,259.085 306.899,249.708 306.899,249.708L306.114,252.964L324.543,265.97C324.543,265.97 315.354,273.424 308.064,273.244C308.06,273.237 309.414,278.789 302.9,278.981Z" />
            </g>
            <g transform="matrix(1,0,0,1,-30.106,-104.693)">
                <path d="M402.187,294.357C401.734,292.822 401.381,291.26 401.128,289.68L390.982,292.361C391.429,294.298 391.35,296.32 390.751,298.215C394.984,299.344 399.503,297.819 402.187,294.357Z" style="fill-opacity:0.1;fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,352.742,171.495)">
                <circle cx="10.911" cy="10.911" r="10.911" style="fill:rgb(254,185,185);"/>
            </g>
            <g transform="matrix(1,0,0,1,-53.148,-236.79)">
                <path d="M428.67,507.07C428.67,507.07 434.987,519.515 433.07,523.341" style="fill-opacity:0.1;fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,2.236,-61.537)">
                <path d="M367.22,218.742C370.249,219.087 373.12,220.386 376.107,221.096C376.852,221.328 377.646,221.357 378.407,221.182C379.564,220.825 380.507,219.613 381.707,219.648C382.463,219.767 383.162,220.119 383.707,220.656C384.682,221.402 385.6,222.221 386.453,223.104C388.509,225.336 389.784,228.342 391.659,230.771C393.534,233.2 396.209,236.048 398.873,234.934C397.367,241.507 391.711,246.362 384.986,246.854C382.424,247.03 379.799,246.497 377.319,247.246C376.701,247.499 376.049,247.658 375.384,247.717C373.956,247.717 372.806,246.407 371.916,245.131C369.829,242.131 368.177,238.731 365.827,235.997C365.15,235.049 364.163,234.366 363.037,234.067C361.943,234.022 360.863,234.326 359.953,234.934C358.262,235.682 356.731,236.748 355.441,238.073C354.08,239.551 353.513,241.598 353.919,243.566C354.355,244.548 354.667,245.582 354.845,246.642C354.869,248.384 353.331,249.655 353.028,251.35C352.96,252.062 352.935,252.777 352.953,253.492C352.82,255.814 351.3,257.713 349.717,259.177C349.018,259.686 348.498,260.405 348.234,261.229C348.112,261.829 348.234,262.46 348.156,263.069C347.925,264.576 346.422,265.341 345.092,265.537C343.538,265.767 341.95,265.579 340.492,264.992C342.462,263.867 343.502,261.597 343.066,259.37C342.474,256.262 339.351,254.03 339.619,250.87C339.765,249.899 340.056,248.955 340.482,248.07C341.162,246.361 341.657,244.584 341.957,242.77C342.265,241.375 342.235,239.926 341.867,238.545C341.388,237.389 340.746,236.307 339.96,235.332C339.018,233.933 338.275,232.411 337.751,230.808C337.561,230.332 337.489,229.818 337.54,229.308C337.697,228.707 338.032,228.166 338.501,227.758C340.208,225.964 342.032,224.101 344.32,223.587C345.41,223.406 346.519,223.364 347.62,223.461C349.537,223.521 351.455,223.44 353.36,223.218C354.929,223.034 357.581,223.057 358.912,222.041C360.152,221.091 360.85,219.899 362.376,219.295C363.921,218.722 365.582,218.533 367.217,218.742L367.22,218.742Z" style="fill:rgb(47,46,65);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,556.372,379.53)">
                <ellipse cx="15.828" cy="2.677" rx="15.828" ry="2.677" />
            </g>
            <g transform="matrix(1,0,0,1,-315.785,-452.093)">
                <path d="M894.531,829.923C895.252,829.333 895.778,828.539 896.039,827.644C896.275,826.722 895.834,825.753 894.984,825.325C894.016,824.955 892.979,825.625 892.197,826.305C891.415,826.985 890.512,827.758 889.485,827.612C890.56,826.641 891.047,825.172 890.766,823.751C890.72,823.461 890.596,823.189 890.408,822.964C889.873,822.389 888.895,822.633 888.255,823.09C886.208,824.527 885.637,827.311 885.625,829.809C885.512,828.895 885.499,827.971 885.586,827.054C885.667,826.116 885.278,825.196 884.547,824.602C884.064,824.343 883.52,824.215 882.972,824.232C882.049,824.099 881.112,824.365 880.398,824.964C879.699,825.906 879.741,827.217 880.498,828.113C881.258,828.971 882.159,829.693 883.163,830.247C883.925,830.701 884.574,831.322 885.063,832.062C885.122,832.166 885.169,832.275 885.204,832.389L890.955,832.389C892.252,831.733 893.454,830.905 894.529,829.927L894.531,829.923Z" />
            </g>
            <g transform="matrix(1,0,0,1,346.557,382.531)">
                <ellipse cx="10.446" cy="1.767" rx="10.446" ry="1.767" style="fill:rgb(239,239,239);"/>
            </g>
            <g transform="matrix(1,0,0,1,-528.228,-446.032)">
                <path d="M889.551,827.442C890.026,827.054 890.373,826.531 890.546,825.942C890.701,825.333 890.408,824.693 889.846,824.412C889.207,824.168 888.524,824.612 888.007,825.059C887.559,825.586 886.908,825.899 886.217,825.922C886.926,825.281 887.247,824.312 887.061,823.374C887.031,823.183 886.949,823.003 886.825,822.854C886.473,822.475 885.825,822.636 885.404,822.937C884.053,823.887 883.676,825.722 883.669,827.371C883.594,826.768 883.585,826.158 883.643,825.553C883.696,824.934 883.439,824.327 882.957,823.935C882.638,823.764 882.28,823.68 881.918,823.691C881.309,823.603 880.69,823.779 880.218,824.174C879.757,824.796 879.786,825.661 880.286,826.252C880.787,826.819 881.382,827.294 882.045,827.659C882.548,827.96 882.977,828.37 883.3,828.859C883.339,828.927 883.371,829 883.394,829.075L887.194,829.075C888.05,828.642 888.843,828.095 889.553,827.449L889.551,827.442Z" style="fill:rgb(239,239,239);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,287.483,382.531)">
                <ellipse cx="10.446" cy="1.767" rx="10.446" ry="1.767" style="fill:rgb(239,239,239);"/>
            </g>
            <g transform="matrix(1,0,0,1,-587.302,-446.032)">
                <path d="M889.551,827.442C890.026,827.054 890.373,826.531 890.546,825.942C890.701,825.333 890.408,824.693 889.846,824.412C889.207,824.168 888.524,824.612 888.007,825.059C887.559,825.586 886.908,825.899 886.217,825.922C886.926,825.281 887.247,824.312 887.061,823.374C887.031,823.183 886.949,823.003 886.825,822.854C886.473,822.475 885.825,822.636 885.404,822.937C884.053,823.887 883.676,825.722 883.669,827.371C883.594,826.768 883.585,826.158 883.643,825.553C883.696,824.934 883.439,824.327 882.957,823.935C882.638,823.764 882.28,823.68 881.918,823.691C881.309,823.603 880.69,823.779 880.218,824.174C879.757,824.796 879.786,825.661 880.286,826.252C880.787,826.819 881.382,827.294 882.045,827.659C882.548,827.96 882.977,828.37 883.3,828.859C883.339,828.927 883.371,829 883.394,829.075L887.194,829.075C888.05,828.642 888.843,828.095 889.553,827.449L889.551,827.442Z" style="fill:rgb(239,239,239);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,-215.895,-16.489)">
                <path d="M799.9,156.863C799.9,156.863 766.755,154.863 770.66,178.088C770.241,180.499 771.436,182.921 773.604,184.055C775.772,185.189 773.663,182.335 777.004,182.921C778.193,183.118 779.401,183.176 780.604,183.094C782.227,182.987 783.772,182.355 785.004,181.294C786.236,180.233 794.321,177.444 797.943,162.218C797.943,162.218 800.624,158.896 800.517,158.041L794.924,160.43C794.924,160.43 796.837,164.465 795.318,167.817C795.318,167.817 795.137,160.574 794.062,160.732C793.846,160.764 791.162,162.132 791.162,162.132C791.162,162.132 794.449,169.158 791.969,174.268C791.969,174.268 792.91,165.608 790.135,162.64L786.199,164.94C786.199,164.94 790.041,172.199 787.435,178.123C787.435,178.123 788.103,169.038 785.368,165.499L781.802,168.282C781.802,168.282 785.411,175.434 783.211,180.348C783.211,180.348 782.924,169.775 781.03,168.976C781.03,168.976 777.908,171.731 777.43,172.857C777.43,172.857 779.902,178.057 778.367,180.793C778.367,180.793 777.426,173.747 776.654,173.708C776.654,173.708 773.544,178.376 773.221,181.581C773.374,178.623 774.298,175.757 775.902,173.267C774.109,173.607 772.453,174.462 771.139,175.729C769.825,176.996 771.623,172.429 776.67,172.139C776.67,172.139 779.24,168.596 779.925,168.384C779.925,168.384 774.91,167.962 771.867,169.313C771.867,169.313 774.544,166.199 780.846,167.613L784.369,164.735C784.369,164.735 777.764,163.835 774.961,164.83C774.961,164.83 778.185,162.075 785.318,164.082L789.152,161.791C789.152,161.791 783.519,160.578 780.165,161.004C780.165,161.004 783.708,159.095 790.277,161.165L793.032,159.934C793.032,159.934 788.907,159.123 787.698,158.993C786.489,158.863 786.427,158.533 786.427,158.533C789.04,158.097 791.724,158.396 794.178,159.395C796.632,160.394 800,157.23 799.9,156.863Z"/>
            </g>
            <g transform="matrix(1,0,0,1,-199.906,-2.586)">
                <path d="M738.495,130.664C738.495,130.664 723.537,129.764 725.284,140.257C725.095,141.347 725.634,142.442 726.614,142.957C727.594,143.472 726.642,142.17 728.153,142.441C728.69,142.531 729.236,142.558 729.779,142.52C730.513,142.471 731.212,142.184 731.767,141.701C732.322,141.218 735.979,139.961 737.616,133.08C737.616,133.08 738.824,131.58 738.777,131.194L736.25,132.273C736.734,133.321 736.8,134.516 736.435,135.611C736.07,136.706 736.352,132.339 735.868,132.411C735.768,132.411 734.553,133.044 734.553,133.044C734.553,133.044 736.041,136.221 734.919,138.527C734.919,138.527 735.344,134.614 734.088,133.272L732.309,134.327C732.309,134.327 734.045,137.606 732.868,140.283C732.868,140.283 733.168,136.177 731.935,134.583L730.325,135.843C730.325,135.843 731.955,139.075 730.963,141.295C730.963,141.295 730.829,136.516 729.975,136.154C729.364,136.671 728.818,137.261 728.349,137.91C727.88,138.559 729.467,140.272 728.774,141.496C728.774,141.496 728.349,138.311 727.987,138.296C727.257,139.372 726.731,140.573 726.436,141.839C726.506,140.503 726.924,139.209 727.648,138.084C726.837,138.238 726.089,138.625 725.495,139.198C724.901,139.771 725.716,137.706 727.995,137.576C728.425,136.96 728.918,136.39 729.467,135.876C728.237,135.772 726.999,135.915 725.826,136.298C724.653,136.681 727.034,134.893 729.881,135.53L731.456,134.23C730.045,134.019 728.61,134.033 727.203,134.271C725.796,134.509 728.659,133.027 731.883,133.933L733.615,132.898C732.282,132.606 730.917,132.488 729.553,132.546C728.189,132.604 731.153,131.684 734.123,132.616L735.363,132.061C735.363,132.061 733.499,131.695 732.95,131.636C732.401,131.577 732.375,131.427 732.375,131.427C733.556,131.231 734.768,131.368 735.875,131.821C736.781,131.513 737.658,131.126 738.495,130.664Z" style="fill:rgb(63,61,86);fill-rule:nonzero;"/>
            </g>
            <g transform="matrix(1,0,0,1,-430.579,157.625)">
                <path d="M738.495,130.664C738.495,130.664 723.537,129.764 725.284,140.257C725.095,141.347 725.634,142.442 726.614,142.957C727.594,143.472 726.642,142.17 728.153,142.441C728.69,142.531 729.236,142.558 729.779,142.52C730.513,142.471 731.212,142.184 731.767,141.701C732.322,141.218 735.979,139.961 737.616,133.08C737.616,133.08 738.824,131.58 738.777,131.194L736.25,132.273C736.734,133.321 736.8,134.516 736.435,135.611C736.07,136.706 736.352,132.339 735.868,132.411C735.768,132.411 734.553,133.044 734.553,133.044C734.553,133.044 736.041,136.221 734.919,138.527C734.919,138.527 735.344,134.614 734.088,133.272L732.309,134.327C732.309,134.327 734.045,137.606 732.868,140.283C732.868,140.283 733.168,136.177 731.935,134.583L730.325,135.843C730.325,135.843 731.955,139.075 730.963,141.295C730.963,141.295 730.829,136.516 729.975,136.154C729.364,136.671 728.818,137.261 728.349,137.91C727.88,138.559 729.467,140.272 728.774,141.496C728.774,141.496 728.349,138.311 727.987,138.296C727.257,139.372 726.731,140.573 726.436,141.839C726.506,140.503 726.924,139.209 727.648,138.084C726.837,138.238 726.089,138.625 725.495,139.198C724.901,139.771 725.716,137.706 727.995,137.576C728.425,136.96 728.918,136.39 729.467,135.876C728.237,135.772 726.999,135.915 725.826,136.298C724.653,136.681 727.034,134.893 729.881,135.53L731.456,134.23C730.045,134.019 728.61,134.033 727.203,134.271C725.796,134.509 728.659,133.027 731.883,133.933L733.615,132.898C732.282,132.606 730.917,132.488 729.553,132.546C728.189,132.604 731.153,131.684 734.123,132.616L735.363,132.061C735.363,132.061 733.499,131.695 732.95,131.636C732.401,131.577 732.375,131.427 732.375,131.427C733.556,131.231 734.768,131.368 735.875,131.821C736.781,131.513 737.658,131.126 738.495,130.664Z" style="fill:rgb(63,61,86);fill-rule:nonzero;"/>
            </g>
        </g>
        <defs>
            <linearGradient x1="0" y1="0" x2="1" y2="0" gradientUnits="userSpaceOnUse" gradientTransform="matrix(6.2433e-18,-0.101961,0.101961,6.2433e-18,377.676,474.982)"><stop offset="0" style="stop-color:rgb(128,128,128);stop-opacity:0.25"/><stop offset="0.54" style="stop-color:rgb(128,128,128);stop-opacity:0.12"/><stop offset="1" style="stop-color:rgb(128,128,128);stop-opacity:0.1"/></linearGradient>
        </defs>
    </svg>
    </div>
    <div *ngIf='showCustomization' id='asc-info-row' [inlineSVG]="'assets/images/info-v2.svg'"></div>
    <div *ngIf='showCustomization' id='asc-info-text'>{{trans('systems.labels.customizeInfoText')}}</div>
    <div *ngIf='showCustomization' id='asc-customize'><div class="button" (click)='doCustomize()'>{{trans('systems.buttons.personalize')}}</div></div>
</div>