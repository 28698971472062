import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PersistenceService } from 'src/app/api/persistence.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-consent',
  templateUrl: './consent.component.html',
  styleUrls: ['./consent.component.scss'],
})
export class ConsentComponent implements OnInit, OnDestroy {
  public trans = (key: string) => this.ls.get(key);
  private translationSubscription = this.ls.onTranslationsChange.subscribe(() => {
    this.cdRef.markForCheck();
  });
  public hasConsented = this.pers.get('privacy_consent');
  private consentSubscribtion = this.pers.subscribe('privacy_consent').subscribe((consent) => {
    this.hasConsented = consent;
    this.cdRef.markForCheck();
  });
  constructor(private ls: LanguageService, private cdRef: ChangeDetectorRef, private pers: PersistenceService) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.translationSubscription.unsubscribe();
    this.consentSubscribtion.unsubscribe();
  }

  public consent() {
    console.log('consent');
    this.pers.set('privacy_consent', true);
  }
}
