import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { Router } from '@angular/router';
import { TAreaData } from 'src/app/models/area-data';
import { TEditableComponent } from 'src/app/models/editable-component';

@Component({
  selector: 'app-area-list',
  templateUrl: './area-list.component.html',
  styleUrls: ['./area-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AreaListComponent extends LanguageAware implements OnInit {
  public windowName = 'area-list';

  constructor(cdRef: ChangeDetectorRef, private router: Router, private es: EditSystemService) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.partitions'),
      backUrl: '/system-configuration',
    });
    this.background.setGray();
  }

  ngOnInit(): void {}

  public onAddArea() {
    this.es.setDefaults();
    this.router.navigate(['/system-area']);
  }

  public onAreaSelect(areaId: number) {
    this.es.setDefaults();
    const area = this.systems.activeSystem.areas.find((a) => a.id === areaId);
    this.es.beginComponentEdit(TEditableComponent.Area, area.id, area);
    this.router.navigate(['/system-area']);
  }

  public getAreas(): TAreaData[] {
    return this.systems.activeSystem.areas.sort((a1, a2) => a1.queue_no - a2.queue_no);
  }

  public getAssignedPgmName(forArea: number): string {
    for (const iPgm of this.systems.activeSystem.pgms) {
      if (iPgm.control_area && iPgm.area_no === forArea) {
        return this.trans('systems.labels.controlledWith') + ': ' + iPgm.name;
      }
    }

    return '';
  }

  public onAreaDelete(event, areaId: number) {
    event.stopPropagation();
    this.deleteArea(areaId);
    const areaList = document.querySelector('#sal-area-list') as HTMLElement;
    this.helper.shouldShowFakeElement(areaList.offsetHeight, this.windowName);
  }

  private deleteArea(areaId: number) {
    const area = this.systems.activeSystem.areas.find((a) => a.id === areaId); // Pasilaikom tam, kad pridetume atgal, jeigu bus klaida.
    const activeSystemId = this.systems.activeSystem.id;
    let controllingPgm = 0;
    this.systems.activeSystem.areas = this.systems.activeSystem.areas.filter((a) => a.id !== areaId);

    for (const iPgm of this.systems.activeSystem.pgms) {
      if (iPgm.area_no === area.queue_no) {
        iPgm.area_id = 0;
        iPgm.area_no = 0;
        iPgm.control_area = false;
        controllingPgm = iPgm.id;
      }
    }

    this.systems.setCurrentSystem(this.systems.activeSystem);

    const that = this;
    this.api.post('/delete/area', { area_id: areaId, system_id: this.systems.activeSystem.id }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.revertDeletion(area, activeSystemId, controllingPgm);
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.revertDeletion(area, activeSystemId, controllingPgm);
        that.toaster.postError(this.trans('systems.errors.areaSaveFailed'));
      }
    );
  }

  private revertDeletion(area: TAreaData, systemId: number, pgmId: number) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }

    system.areas.push(area);
    if (pgmId === 0) {
      if (this.systems.activeSystem.id === system.id) {
        this.systems.setCurrentSystem(system);
      }
      return;
    }

    for (const iPgm of system.pgms) {
      if (iPgm.id === pgmId) {
        iPgm.area_id = area.id;
        iPgm.control_area = true;
      }
    }
    if (this.systems.activeSystem.id === system.id) {
      this.systems.setCurrentSystem(system);
    }
  }
}
