<div class="formGroup">
  <label class="label" [for]="formId">{{ label }}</label>
  <input
    [id]="formId"
    [(ngModel)]="inputProxy"
    class="input"
    [type]="htmlInputType"
    [required]="required"
    [disabled]="disabled"
    (focus)="Focus.emit($event)"
    (blur)="Blur.emit($event)"
    (keydown)="Keydown.emit($event)"
    (keyup)="Keyup.emit($event)"
    (keypress)="Keypress.emit($event)"
  />

  <ng-content></ng-content>
</div>
