import { Injectable, Injector } from '@angular/core';
import { autoinject } from 'src/shim';
import { GlobalService } from '../services/global.service';
import { LanguageService } from '../services/language.service';
import { TimezoneService } from '../services/timezone.service';
import { AuthService } from './auth.service';
import { GoogleAuthService } from './google-auth.service';
import { LoggerService } from './logger.service';
import { PlatformService } from './platform.service';
import { RequestService } from './request.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalResolverService {
  private readonly auth = autoinject(this.injector, AuthService);
  private readonly user = autoinject(this.injector, UserService);
  private readonly googleAuth = autoinject(this.injector, GoogleAuthService);
  private readonly global = autoinject(this.injector, GlobalService);
  private readonly ls = autoinject(this.injector, LanguageService);
  private readonly ps = autoinject(this.injector, PlatformService);
  private readonly req = autoinject(this.injector, RequestService);
  private readonly ts = autoinject(this.injector, TimezoneService);
  private nativeLanguageLoadedCallbacks: (() => void)[] = [];
  constructor(private injector: Injector, private l: LoggerService) {
    // Setup Language JSI
    window.languageJsi = {
      applyLanguage: (data, version, code) => {
        this.l.log('Apply language :' + data.length, 'gLoad');
        this.ls().applyTranslations(data, version, code);
      },
      finishApplyingLanguage: () => {
        this.finishApplyingLanguage();
      },
    };
  }

  private finishApplyingLanguage() {
    this.global().setLoaded();
    if (this.ps().isAndroid()) {
      this.ps().androidHandler().languageUpdated();
    } else if (this.ps().isApple()) {
      this.ps().appleHandler().languageUpdated.postMessage('');
    }
    const tmp = [...this.nativeLanguageLoadedCallbacks];
    this.nativeLanguageLoadedCallbacks = [];
    tmp.forEach((cb) => cb());
  }

  public async resolve(): Promise<void> {
    await Promise.all([this.doUserThing(), this.googleAuth().whenReady(), this.loadLanguage()]);
    await this.informOfLoaded();
  }

  public async informOfLoaded(): Promise<void> {
    this.ts().loadTimeZones();
    if (this.ps().isAndroid()) {
      this.ps().androidHandler().loaded();
    } else if (this.ps().isApple()) {
      this.ps().appleHandler().loaded.postMessage('');
    }
  }

  public async doUserThing(): Promise<void> {
    if (this.auth().hasToken()) {
      const decoded = this.auth().getTokenData();
      this.l.log('Turim tokena: ', 'gLoad', new Date(decoded.exp * 1000));
      const nowTime = Date.now() / 1000;
      this.l.log('tikrinam token: (now)(decoded)', 'gLoad', {
        nowTime,
        exp: decoded.exp,
      });
      if (nowTime > decoded.exp) {
        this.auth().setToken('');
      } else {
        if (!this.user().user) {
          await this.auth().loadUserData();
        }
      }
    }
  }

  public async loadLanguage(): Promise<void> {
    const currentVersion = localStorage.getItem('lang_version');
    const currentLanguage = localStorage.getItem('lang');
    this.ls().selectedLanguage.value = currentLanguage;
    this.l.log('current language: ', 'gLoad', currentLanguage);
    const languageLoadPromise = new Promise<void>((resolve) => this.nativeLanguageLoadedCallbacks.push(resolve));
    try {
      const data = await this.req()
        .misc.translations({
          language: currentLanguage === null ? '' : currentLanguage,
          version: currentVersion,
        })
        .toPromise();
      if (data.success) {
        if (data.version === currentVersion) {
          this.l.log('Kalbu versija nepasikeite, nieko nedarom.', 'gLoad');
          this.global().setLoaded();
          return;
        } else {
          this.l.log('Gauti nauji vertimai', 'gLoad');
          this.ls().applyTranslations(data.translations, data.version, data.code);
          this.finishApplyingLanguage();
        }
      } else {
        this.l.log('Kazkodel nepavyko gauti vertimu', 'gLoad', { data });
        this.global().setLoaded();
        return;
      }
    } catch (error) {
      this.l.log('Kazkodel nepavyko gauti vertimu', 'gLoad', { error });
      this.global().setLoaded();
      return;
    }
    await languageLoadPromise;
  }
}
