<div class="white_round padding16">
    <div class="suipi_header">{{trans('systems.menu.ipcom')}}</div>
    <app-text-edit [value]="ipcomItem.name" (exitedField)="ipcomItem.name = $event; ipcomDataChanged = true">{{trans('settings.labels.nameIpcom')}}</app-text-edit>
    <app-text-edit [value]="ipcomItem.peer_name" (exitedField)="ipcomItem.peer_name = $event; ipcomDataChanged = true">{{trans('settings.labels.peerName')}}</app-text-edit>
    <app-text-edit [value]="ipcomItem.host" (exitedField)="ipcomItem.host = $event; ipcomDataChanged = true">{{trans('settings.labels.host')}}</app-text-edit>
    <app-text-edit [value]="ipcomItem.port" (exitedField)="ipcomItem.port = $event; ipcomDataChanged = true" [maxCharacters]="5">{{trans('settings.labels.port')}}</app-text-edit>
    <app-name-value [nameText]="trans('settings.labels.own')">
        <app-toggle-button [buttonId]="'suipi_own'" [initialState]="ipcomItem.own_ipcom" (toggled)="ipcomItem.own_ipcom = $event.checked; ipcomDataChanged = true"></app-toggle-button>
    </app-name-value>
    <app-name-value [nameText]="trans('settings.ipcom.region')" (clicked)="changeRegion()">{{regionText}}</app-name-value>
    <div class="suipi_header">{{trans('settings.ipcom.userSettings')}}</div>
    <app-text-edit [value]="ipcomItem.user_name" (exitedField)="ipcomItem.user_name = $event; ipcomDataChanged = true">{{trans('settings.labels.userName')}}</app-text-edit>
    <app-text-edit [value]="ipcomItem.password" (exitedField)="ipcomItem.password = $event; ipcomDataChanged = true">{{trans('settings.labels.password')}}</app-text-edit>
    <div class="suipi_header">{{trans('settings.ipcom.deviceSettings')}}</div>
    <app-text-edit [value]="ipcomItem.host_for_devices" (exitedField)="ipcomItem.host_for_devices = $event; ipcomDataChanged = true">{{trans('settings.labels.hostForDevices')}}</app-text-edit>
    <app-text-edit [value]="ipcomItem.port_for_devices" (exitedField)="ipcomItem.port_for_devices = $event; ipcomDataChanged = true" [maxCharacters]="5">{{trans('settings.labels.portForDevices')}}</app-text-edit>
    <app-password-edit [value]="ipcomItem.encryption_for_devices" (exitedField)="ipcomItem.encryption_for_devices = $event; ipcomDataChanged = true" [maxCharacters]="6">{{trans('settings.labels.encryptionForDevices')}}</app-password-edit>
    <div class="suipi_header">{{trans('settings.ipcom.companySettings')}}</div>
    <app-name-value [nameText]="trans('settings.ipcom.companySelect')" (clicked)="changeCompany()">{{companyText}}</app-name-value>
    <div id="suipi_logo_h">{{trans('settings.ipcom.uploadLogo')}}</div>
    <div id="suipi_logo_h_desc">{{trans('settings.ipcom.uploadLogoDesc')}}</div>
    <div id="suipi_logo">
        <input id="suipi_input" type="file" name="cpicon" accept="image/" (change)="handleNewFile($event.target.files)">
        <label for="suipi_input">
            <div id="suipi_upload">
                <img id="suipi_preview" [src]="fileToUploadUrl" *ngIf="fileToUploadUrl !== null" (load)="imageLoaded($event.target)">
                <img id="suipi_preview" [src]="ipcomItem.ipcom_logo" *ngIf="ipcomItem.ipcom_logo !== null && fileToUploadUrl === null">
                <div id="suipi_icon" *ngIf="fileToUploadUrl === null && ipcomItem.ipcom_logo === null" [inlineSVG]="'assets/images/upload.svg'"></div>
            </div>
        </label>
        <div id="suipi_text">{{trans('settings.ipcom.logo')}}</div>
    </div>
    <div class="button" *ngIf="ipcomItem.ipcom_logo !== null || fileToUploadUrl !== null" (click)="removeLogo()">{{trans('settings.ipcom.removeLogo')}}</div>
    <div class="suipi_header">{{trans('settings.labels.description')}}</div>
    <textarea id="suipi_desc" rows="4" (change)="ipcomDataChanged = true" [(ngModel)]="ipcomItem.description"></textarea>
    <div class="suipi_header">{{trans('settings.ipcom.testHeader')}}</div>
    <div class="button" (click)="testConnection()">{{trans('settings.buttons.testConnection')}}</div>
</div>
<app-popup></app-popup>
<app-status-message #statusMessage></app-status-message>