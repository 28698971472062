import { Component, Input, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'app-tmapper',
  templateUrl: './tmapper.component.html',
  styleUrls: ['./tmapper.component.scss'],
})
export class TmapperComponent implements OnInit {
  @Input() public default: TemplateRef<unknown>;
  @Input() public map: Record<string, TemplateRef<unknown>>;
  @Input() public key: string;
  @Input() public context: unknown;

  constructor() {}

  ngOnInit(): void {}
}
