import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GroupboxService } from '../../services/groupbox.service';

@Component({
  selector: 'app-groupbox',
  templateUrl: './groupbox.component.html',
  styleUrls: ['./groupbox.component.scss'],
})
export class GroupboxComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() headerText = '';
  public isCollapsed = false;
  private refreshSubscriber = null;
  @ViewChild('contentGroupBox') contentGroupbox: ElementRef;

  constructor(grps: GroupboxService) {
    const that = this;
    this.refreshSubscriber = grps.onRefresh.subscribe(() => {
      that.recalculate();
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    if (this.contentGroupbox !== undefined) {
      this.contentGroupbox.nativeElement.style.maxHeight = this.contentGroupbox.nativeElement.scrollHeight + 'px';
    }
  }

  ngOnDestroy() {
    if (this.refreshSubscriber !== null) {
      this.refreshSubscriber.unsubscribe();
      this.refreshSubscriber = null;
    }
  }

  public toggleGroupbox() {
    this.isCollapsed = !this.isCollapsed;
    this.recalculate();
  }

  private recalculate() {
    if (this.isCollapsed) {
      this.contentGroupbox.nativeElement.style.maxHeight = '0px';
    } else {
      this.contentGroupbox.nativeElement.style.maxHeight = this.contentGroupbox.nativeElement.scrollHeight + 'px';
    }
  }
}
