<div *ngIf="!hasSystem()" class="grid grid-home-empty">
  <div id="home-empty-logo" *ngIf="!us.currentUser.logo" [inlineSVG]="'assets/images/logo_text_subtext.svg'"></div>
  <div id="home-empty-logo" *ngIf="us.currentUser.logo"><img [src]="us.currentUser.logo" /></div>
  <div id="home-empty-plus" *ngIf="[1, 2].includes(us.currentUser.role)" (click)="newSystemClick()"><div [inlineSVG]="'assets/images/plus.svg'"></div></div>
  <div id="home-empty-subtext" *ngIf="[1, 2].includes(us.currentUser.role)">{{ trans('systems.titles.clickPlusButton') }}</div>
</div>
<div *ngIf="hasSystem()" class="grid grid-home">
  <div id="home-system-name">{{ systems.activeSystem.name }}</div>
  <div id="home-system-net-status">
    {{ systemNetState() }}
    <span *ngIf="this.systems.activeSystem.online"><app-signal-level-v2 [width]="17" [height]="14" [currentLevel]="systems.activeSystem.signalLevel"></app-signal-level-v2></span>
  </div>
  <app-assisting-installer
    id="home_row_assistance"
    *ngIf="systems.activeSystem.assistedById !== undefined"
    [installerId]="systems.activeSystem.assistedById"
    [installerEmail]="systems.activeSystem.assistedByEmail"
    [systemId]="systems.activeSystem.id"
    [showRevokeButton]="true"
  ></app-assisting-installer>
  <app-home-events
    *ngIf="systems.activeSystemHelper.supports.events() && shouldShowType('events') && systems.activeSystem.events.length > 0"
    [ngClass]="'home-row-' + getPositionForType('events') + ' home_section'"
  ></app-home-events>
  <app-home-areas
    *ngIf="systems.activeSystemHelper.supports.areas() && shouldShowType('areas') && systems.activeSystem.areas.length > 0"
    [ngClass]="'home-row-' + getPositionForType('areas') + ' home_section'"
  ></app-home-areas>
  <app-home-outputs
    *ngIf="systems.activeSystemHelper.can.see.outputs() && shouldShowType('outputs') && systems.activeSystem.pgms.length > 0"
    [ngClass]="'home-row-' + getPositionForType('outputs') + ' home_section'"
  ></app-home-outputs>
  <app-home-sensors
    *ngIf="systems.activeSystemHelper.supports.sensors() && shouldShowType('sensors') && systems.activeSystem.sensors.length > 0"
    [ngClass]="'home-row-' + getPositionForType('sensors') + ' home_section'"
  ></app-home-sensors>
  <app-home-cameras
    *ngIf="systems.activeSystemHelper.can.see.cameras() && shouldShowType('cameras') && systems.activeSystem.cameras.length > 0"
    [ngClass]="'home-row-' + getPositionForType('cameras') + ' home_section'"
  ></app-home-cameras>
  <app-home-thermostats
    *ngIf="systems.activeSystemHelper.supports.thermostats() && shouldShowType('thermostats') && systems.activeSystem.thermostats.length > 0"
    [ngClass]="'home-row-' + getPositionForType('thermostats') + ' home_section'"
  ></app-home-thermostats>
</div>
