import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-dots-v2',
  templateUrl: './loader-dots-v2.component.html',
  styleUrls: ['./loader-dots-v2.component.scss'],
})
export class LoaderDotsV2Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
