import { Injectable, NgZone } from '@angular/core';
import { SystemsService } from './systems.service';
import { ApiRequestService } from './api-request.service';
import { UserService } from './user.service';
import { Subject } from 'rxjs';
import { EventsService } from './events.service';
import { LoggerService } from '../api/logger.service';
import { PgmService } from './pgm.service';
import { Router } from '@angular/router';
import { GlobalService } from './global.service';
import { TEventData } from '../models/event-data';
import { RtcService } from '../api/rtc/rtc.service';
import { UserService as USN } from '../api/user.service';
import { AuthService } from '../api/auth.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private onSystemStatusChangeSource = new Subject<void>();
  private onAreaStatusChangeSource = new Subject<void>();
  private onPgmStatusChangeSource = new Subject<void>();
  private onSystemAddedSource = new Subject<void>();
  private onSystemRemovedSource = new Subject<void>();
  private onEventSource = new Subject<TEventData>();
  private onUserAccessSource = new Subject<void>();
  private onConfigurationSource = new Subject<void>();
  private onNewVersionSource = new Subject<void>();
  /** Praneša apie tai, jog pasikeitė sistemos būsena.
   *
   * @param system Sistemos duomenų objektas
   * > { __system_id__: _number_, __system_status__: _'online'_ arba _'offline'_, __signal_level__: _number_ }
   */
  public onSystemStatusChange = this.onSystemStatusChangeSource.asObservable();
  /** Praneša apie tai, jog pasikeitė srities būsena.
   *
   * @param area Srites duomenų objektas
   * > { __id__: _number_, __system_id__: _number_, __name__: _string_, __queue_no__: _number_, __status__: _number_, __alarm_in_memory__: 0 arba 1 }
   */
  public onAreaStatusChange = this.onAreaStatusChangeSource.asObservable();
  /** Praneša apie tai, jog pasikeitė pgm būsena.
   *
   * @param pgm PGM duomenų objektas
   * > { __system_id__: _number_, __id__: _number_, __queue_no__: _number_, __enabled__: 0 arba 1, __on__: 0 arba 1, __icon_number__: _number_ }
   */
  public onPgmStatusChange = this.onPgmStatusChangeSource.asObservable();
  /**Praneša apie tai, jog aktyviam vartotojui buvo pridėta nauja sistema.
   * @param system Sistemos duomenų objektas
   */
  public onSystemAdded = this.onSystemAddedSource.asObservable();
  /**Praneša apie tai, jog aktyviam vartotojui buvo pašalinta sistema.
   * @param systemId Pašalintos sistemos ID
   */
  public onSystemRemoved = this.onSystemRemovedSource.asObservable();
  /**
   * Praneša apie tai, jog gautas naujas įvykis.
   *
   * @param event Objektas.
   * > { __system_id__: _number_, __event_id__: _number_ }
   */
  public onEventReceived = this.onEventSource.asObservable();
  /**
   * Praneša apie tai, jog gautas vartotojo praėjimo kontrolės įvykis.
   *
   * @param event Objektas.
   * _user_id_ nurodo vartotojo numerį modulyje
   * _protegus_user_id_ nurodo vartotojo ID protegus sistemoje
   * > { __system_id__ : _number_, __present__ : _boolean_, __user_id__ : _number_, __protegus_user_id__ : _number_ }
   */
  public onUserAccessReceived = this.onUserAccessSource.asObservable();

  public onConfigurationEventReceived = this.onConfigurationSource.asObservable();

  public onNewVesrionReceived = this.onNewVersionSource.asObservable();

  private tag = 'Websock';

  private areaStatusSubscription = this.rtc.areaStatus.subscribe((data) => this.internalOnAreaStatus(data));
  private configurationSubscription = this.rtc.configurationEvent.subscribe((data) => this.internalOnConfigurationEvent(data));
  private eventsSubscription = this.rtc.events.subscribe((data) => this.internalOnEvent(data));
  private pgmStatusSubscription = this.rtc.pgm.subscribe((data) => this.internalOnPgmStatus(data));
  private systemStatusSubscription = this.rtc.systemStatusChange.subscribe((data) => this.internalOnSystemStatus(data));
  private accessControlSubscription = this.rtc.userAccessControl.subscribe((data) => this.internalOnUserAccess(data));
  private systemAddedSubscription = this.rtc.userSystem.subscribe((data) => this.internalOnUserSystemEvent(data));
  private newVersionSubscription = this.rtc.versionUpdate.subscribe((data) => this.internalOnNewVersion(data));

  private systemChangeSubscription = this.ss.onActiveSystemChange.subscribe(() => this.updateIntrests());

  constructor(
    private ss: SystemsService,
    private rs: ApiRequestService,
    private us: UserService,
    private zone: NgZone,
    private es: EventsService,
    private l: LoggerService,
    private pgmS: PgmService,
    private router: Router,
    private g: GlobalService,
    private rtc: RtcService,
    private n: USN,
    private auth: AuthService
  ) {
    this.l.log('+', this.tag);
    this.rtc.updateIntrests([]);
    if (this.auth.hasToken()) {
      n.loadAllUsers();

      auth.loadUserData().then(() => {
        this.websockInit();
      });
    }
  }

  // Online/Offline/signalo lygio apdorojimas
  private internalOnSystemStatus(system) {
    this.l.log('', this.tag, system);
    const that = this;
    this.zone.run(() => {
      const found = that.ss.getSystem(system.system_id);
      if (found !== undefined) {
        found.online = system.system_status === 'online';
        found.signalLevel = system.signal_level;
      }
      that.onSystemStatusChangeSource.next(system);
    });
  }

  // object tipo srities tolesnis apdorojimas.
  private internalOnAreaStatus(area) {
    const that = this;
    this.l.log('', this.tag, area);
    this.zone.run(() => {
      const system = that.ss.getSystem(area.system_id);
      if (system !== undefined) {
        const areaLocal = system.areas.find((a) => a.id === area.id);
        if (areaLocal !== undefined) {
          areaLocal.status = area.status;
          areaLocal.statusTime = area.status_time;
          areaLocal.alarmed = area.alarm_in_memory;
          areaLocal.lastPerson = area.last_person_name;
          areaLocal.alarmTime = area.alarm_time;
          areaLocal.alarmType = area.alarm_type;
          that.ss.saveActiveSystem(area.system_id);
        }
      }
      that.onAreaStatusChangeSource.next(area);
    });
  }
  // object tipo PGM tolesnis apdorojimas.
  private internalOnPgmStatus(pgm) {
    const that = this;
    this.zone.run(() => {
      that.l.log('PGM duomenys', that.tag, pgm);
      that.pgmS.updatePgmData(pgm);
      that.onPgmStatusChangeSource.next(pgm);
    });
  }

  private internalOnUserSystemEvent(event) {
    const that = this;
    if (!this.rs.hasToken()) {
      return;
    }
    if (event.system_data !== undefined) {
      // Prideta nauja sistema.
      if (event.system_data.hwType === '') {
        this.l.log('Sistema priskirta, bet dar nesukurta iki galo.', this.tag);
        return;
      }
      this.zone.run(() => {
        this.l.log('Sistema prideta. ', this.tag, event.system_id);
        that.ss.updateOrAddSystem(that.ss.convertSystemFromRaw(event.system_data));
        this.websockInit();
        that.onSystemAddedSource.next(event.system_data);
      });
    } else if (event.system_reread !== undefined) {
      this.l.log('Gautas sistemos reread. Nuskaitom naujus duomenis.', this.tag, event.system_id);
      this.zone.run(() => {
        that.rs.post('/get-system', { system_id: event.system_id }, true).subscribe(
          (result) => {
            if (result.success) {
              this.l.log('Duomenys gauti. Atnaujinam.', this.tag);
              that.ss.updateOrAddSystem(that.ss.convertSystemFromRaw(result.system));
            }
          },
          (error) => {
            // nieko nedarom
          }
        );
      });
    } else {
      // Pasalinta sistema.
      this.zone.run(() => {
        that.ss.removeSystem(event.system_id);
        if (!that.ss.hasSystems()) {
          that.router.navigate([that.g.getHomeUrl()]);
        }
        this.websockInit();
        that.onSystemRemovedSource.next(event.system_id);
      });
    }
  }

  private internalOnEvent(event) {
    const that = this;
    if (!this.rs.hasToken()) {
      return;
    }

    this.rs.post('/event', event, true).subscribe(
      (data) => {
        if (data.success) {
          that.l.log('', that.tag, data);
          const system = that.ss.getSystem(event.system_id);
          if (system === undefined) {
            return;
          }
          const convertedEvent = that.es.convertFromRaw(data.event);
          convertedEvent.systemId = event.system_id;
          system.events.unshift(convertedEvent);
          that.ss.saveActiveSystem(event.system_id);
          that.zone.run(() => {
            that.onEventSource.next(convertedEvent);
          });
        } else {
          that.l.log(data.error, that.tag);
        }
      },
      (error) => {
        // nieko nedarom.
      }
    );
  }

  private internalOnUserAccess(event) {
    this.l.log('User access', this.tag, event);
    const that = this;
    this.zone.run(() => {
      that.ss.updateAccessData(event);
      that.onUserAccessSource.next(event);
    });
  }

  private internalOnConfigurationEvent(event) {
    const that = this;
    this.zone.run(() => {
      that.onConfigurationSource.next(event);
    });
  }
  private internalOnNewVersion(event) {
    const that = this;
    this.zone.run(() => {
      that.us.appVersionDetails = event;
      that.onNewVersionSource.next();
    });
  }

  public websockInit() {
    this.l.log('Websocket init', this.tag);
    this.rtc.updateIntrests([...this.ss.systems.map((s) => s.id), this.ss.activeSystem?.id]);
  }

  public close() {
    this.rtc.close();
  }

  private updateIntrests() {
    this.rtc.updateIntrests([...this.ss.systems.map((s) => s.id), this.ss.activeSystem?.id]);
  }
}
