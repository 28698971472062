import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, OnDestroy, ViewChild, AfterViewInit } from '@angular/core';
import { FragmenterComponent } from 'src/app/components/fragmenter/fragmenter.component';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEventData } from 'src/app/models/event-data';
import { UserRole } from 'src/app/models/user-role';
import { DateService } from 'src/app/services/date.service';
import { EventsService } from 'src/app/services/events.service';
import { IconsService } from 'src/app/services/icons.service';
import { WebSocketService } from 'src/app/services/websocket.service';

@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EventsComponent extends LanguageAware implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('fragmentLoader') fragmentLoader: FragmenterComponent;
  public lastSynced = '';
  public groupedEvents: any[] = [];
  private eventSubscription = null;
  private refreshSubscriber = null;
  public that = this;
  private eventCountBeforeGrouping = 0;

  constructor(cdRef: ChangeDetectorRef, public dt: DateService, private ev: EventsService, public is: IconsService, ws: WebSocketService, private es: EventsService) {
    super(cdRef);
    this.background.setGray();
    const backUrl = this.systems.activeArea === null ? '/home' : '/area-view';
    this.headerBar.showHeader({
      backUrl,
      showDotMenu: true,
    });
    this.headerBar.setDotMenuItems([
      {
        name: this.trans('systems.menu.eventsHideUnhide'),
        action: '/event-control',
      },
    ]);
    this.groupedEvents = this.getGroupedEvents();
    const that = this;
    this.eventSubscription = ws.onEventReceived.subscribe(() => {
      that.groupedEvents = that.getGroupedEvents();
    });
    this.refresher.enableRefresher();
    this.refreshSubscriber = this.refresher.onRefresh.subscribe(() => {
      that.loadEvents();
    });
    if (this.us.currentUser.role === UserRole.SuperAdmin && this.systems.activeSystem.events.length < 2) {
      this.loadEvents();
    }
  }

  ngOnInit(): void {
    this.lastSynced = this.trans('events.titles.syncTime') + ': ' + this.dt.formatDateTime(new Date());
  }

  ngAfterViewInit(): void {
    this.fragmentLoader.isEnabled = this.eventCountBeforeGrouping % this.us.eventPerPage === 0;
  }

  ngOnDestroy() {
    if (this.refreshSubscriber !== null) {
      this.refreshSubscriber.unsubscribe();
      this.refreshSubscriber = null;
    }
    if (this.eventSubscription !== null) {
      this.eventSubscription.unsubscribe();
      this.eventSubscription = null;
    }
  }

  public getGroupedEvents(): any[] {
    const that = this;
    let eventListToUse = [];
    if (this.systems.activeSystem.eventConfiguration.length === 0) {
      eventListToUse = this.systems.activeSystem.events;
    } else {
      eventListToUse = this.systems.activeSystem.events.filter((e) => that.systems.activeSystem.eventConfiguration.indexOf(e.reaction) !== -1);
    }
    if (this.fragmentLoader !== undefined) {
      this.fragmentLoader.isEnabled = eventListToUse.length % this.us.eventPerPage === 0;
    }
    this.eventCountBeforeGrouping = eventListToUse.length;

    return this.ev.groupEvents(eventListToUse, 0, 0);
  }

  private loadEvents() {
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    const systemId = this.systems.activeSystem.id;
    this.api.post('/events', { system_id: systemId }, true).subscribe(
      (result) => {
        if (result.success) {
          const system = that.systems.getSystem(systemId);
          if (system === undefined) {
            that.miniStatus.hide();
            return;
          }
          const convertedEvents: TEventData[] = [];
          for (const iEvent of result.events) {
            const singleEvent = that.es.convertFromRaw(iEvent);
            convertedEvents.push(singleEvent);
          }
          system.events = convertedEvents;
          that.groupedEvents = that.getGroupedEvents();
        } else {
          that.toaster.postError(result.error);
        }
        that.miniStatus.hide();
      },
      (error) => {
        that.miniStatus.hide();
      }
    );
  }

  public loadEventChunk(context: any, callback?: any) {
    context.miniStatus.show(context.trans('general.pleaseWait'));
    const systemId = context.systems.activeSystem.id;
    const lastEventId = context.systems.activeSystem.events[context.systems.activeSystem.events.length - 1].id;
    context.api.post('/events', { system_id: systemId, offsetEvents: lastEventId, forApi: true }, true).subscribe(
      (result) => {
        if (result.success) {
          const system = context.systems.getSystem(systemId);
          if (system === undefined) {
            context.miniStatus.hide();
            if (callback !== undefined) {
              callback();
            }
            return;
          }
          const convertedEvents: TEventData[] = [];
          for (const iEvent of result.events) {
            const singleEvent = context.es.convertFromRaw(iEvent);
            convertedEvents.push(singleEvent);
          }
          system.events.push(...convertedEvents);
          context.groupedEvents = context.getGroupedEvents();
        } else {
          context.toaster.postError(result.error);
        }
        context.miniStatus.hide();
        if (callback !== undefined) {
          callback();
        }
      },
      (error) => {
        context.miniStatus.hide();
        if (callback !== undefined) {
          callback();
        }
      }
    );
  }
}
