import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
/* Deja kol kas nesugalvoju, kaip padaryt, kad groupbox aukstis pasikeistu iskart, kai tik atsiranda, ar dingsta komponentai.
 * todel tenka naudot toki servisa, kuris visiems groupboxams pasako persiskaiciuot aukscius.
 */
export class GroupboxService {
  private refreshSource = new Subject<any>();
  public onRefresh = this.refreshSource.asObservable();

  constructor() {}

  public doRefresh() {
    setTimeout(() => {
      this.refreshSource.next();
    }, 100);
  }
}
