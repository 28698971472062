<div [ngClass]="{ 'notif_item_grid': true, 'notif_item_disabled': !isEnabled}">
    <div #reactionName class="ni_title {{canBeExpanded && !isExpanded ? 'ni_title_expandable' : ''}} {{isExpanded ? 'ni_title_expanded' : ''}}" (click)="toggleExpanded()">{{trans('reactionNames.reactions.' + notificationItem.name)}}</div>
    <div class="ni_sound_icon">
        <div *ngIf='!notificationItem.enabled' [inlineSVG]="'assets/images/notif_sound_disabled.svg'"></div>
        <div *ngIf='notificationItem.enabled && !notificationItem.useAlertSound && systems.activeSystem.notificationSoundsEnabled' class="ni_normal" [inlineSVG]="'assets/images/notif_sound_normal.svg'"></div>
        <div *ngIf='notificationItem.enabled && !notificationItem.useAlertSound && !systems.activeSystem.notificationSoundsEnabled' class="ni_normal notif_mute" [inlineSVG]="'assets/images/notif_sound_mute.svg'"></div>
        <div *ngIf='notificationItem.enabled && notificationItem.useAlertSound' class="ni_alarm" [inlineSVG]="'assets/images/notif_sound_alert.svg'"></div>
    </div>
    <div class="ni_mode_desc"><div>{{!notificationItem.enabled ? trans('systems.labels.notificationDisabled') : notificationItem.useAlertSound ? trans('systems.labels.notificationAlarm') : trans('systems.labels.notificationEnabled')}}</div></div>
    <div #dotBar class="ni_slider_back {{notificationItem.enabled ? notificationItem.useAlertSound ? 'ni_alarm_bk' : 'ni_normal_bk' : ''}}"></div>
    <div class="ni_slider_dot1" (click)='disableClick()'><div class="ni_slider_dot"></div></div>
    <div class="ni_slider_dot2" (click)='enableClick()'><div class="ni_slider_dot"></div></div>
    <div class="ni_slider_dot3" (click)='alarmClick()'><div class="ni_slider_dot"></div></div>
    <div class="ni_slider {{notificationItem.enabled ? notificationItem.useAlertSound ? 'ni_alarm_br ni_slider_right' : 'ni_normal_br ni_slider_middle' : ''}}" (mousedown)='startDragging($event)' (touchstart)='startDragging($event, true)'></div>
</div>