import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { TSystemData } from 'src/app/models/system-data';
import { GoogleAuthService } from 'src/app/api/google-auth.service';

@Component({
  selector: 'app-account-delete',
  templateUrl: './account-delete.component.html',
  styleUrls: ['./account-delete.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AccountDeleteComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private router: Router, private google: GoogleAuthService) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('users.labels.deleteProfile'),
      backUrl: '/profile',
    });
    this.footerBar.showFooter(this.trans('users.labels.deleteProfile'), '', false, false);
    this.background.setGray();
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.doDelete();
    };
    this.footerBar.onSumCorrect = () => {
      that.confirmedDeletion();
    };
  }

  ngOnInit(): void {}

  private doDelete() {
    this.footerBar.showConfirmation();
  }

  private confirmedDeletion() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('users.labels.deleting'));
    this.api.delete('/account?sum=true&acknowledged=true', true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          that.systems.clearSystems();
          that.api.setToken('');
          if (that.platform.isDesktop()) {
            const loginType = that.us.getLoginType();
            if (loginType !== null && loginType === 'google') {
              that.google.signOut(true);
            }
          } else if (that.platform.isAndroid()) {
            that.platform.androidHandler().doLogOutGoogle();
          }
          localStorage.removeItem('last_system');
          localStorage.removeItem('last_system_data');
          that.router.navigate(['/login']);
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
      }
    );
  }

  public getOwnedSystems(): TSystemData[] {
    return this.systems.systems.filter((s) => s.amIMaster);
  }
}
