import { Injectable } from '@angular/core';
import { TPgmData } from '../models/pgm-data';
import { TSensorData } from '../models/sensor-data';
import { ApiRequestService } from './api-request.service';
import { LanguageService } from './language.service';
import { LoggerService } from '../api/logger.service';
import { PhoneStateService } from '../api/phone-state.service';
import { PlatformService } from '../api/platform.service';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  private tag = 'IconsService';
  public pgmIcons = [];
  public sensorIcons = [];
  private defaultPgmIcon = 'assets/images/pgm-general-icon.svg';
  private defaultSensorIcon = 'assets/images/sensor_temp.svg';

  constructor(private rs: ApiRequestService, private l: LoggerService, private lang: LanguageService, private ps: PlatformService, psjsi: PhoneStateService) {
    const that = this;
    psjsi.onRequestIconPaths.subscribe((data) => {
      that.retrieveIcons(data.type, data.version);
    });
  }

  public checkIcons() {
    this.l.log('Laikas tikrinti ikonu versijas.', 'Icons');
    const that = this;
    this.rs.get('/version', true, { keys: ['pgm-icons', 'sensor-icons', 'reactions'] }).subscribe(
      (result) => {
        if (result.success) {
          for (const iResult of result.result) {
            let localVersion = iResult.key.indexOf('-icons') === -1 ? localStorage.getItem(iResult.key + '-icons-version') : localStorage.getItem(iResult.key + '-version');
            if (localVersion === null) {
              localVersion = '';
            }
            if (localVersion !== iResult.version) {
              that.l.log(iResult.key + ' versija nesutampa. Reikia iš naujo atsisiųsti', that.tag, { esama: localVersion, nauja: iResult.version });
              if (iResult.key.indexOf('pgm') !== -1) {
                that.download('pgm', '/get-pgm-icon-paths', iResult.version);
              } else if (iResult.key.indexOf('sensor') !== -1) {
                that.download('sensor', '/get-sensor-types', iResult.version);
              } else if (iResult.key.indexOf('reactions') !== -1) {
                that.download('reactions', '/get-reactions', iResult.version);
              }
            } else {
              that.l.log(iResult.key + ' versija sutampa, nieko nedarom.', that.tag);
            }
          }
        } else {
          that.l.log(result.error, that.tag);
        }
      },
      (error) => {
        that.l.log('Klaida bandant gauti ikonų versiją.', that.tag, error);
      }
    );
  }

  private download(type: string, url: string, newVersion: string, companyId?: number) {
    const that = this;
    this.rs.post(url, companyId === undefined ? {} : { companyId }, true).subscribe(
      (result) => {
        if (result.success) {
          that.l.log('Gavom ikonas', that.tag, result);
          const availableIcons = [];
          for (const iIcon of result.icons) {
            localStorage.setItem(type + '-icon-' + iIcon.id, JSON.stringify(iIcon));
            availableIcons.push(iIcon.id);
          }
          localStorage.setItem(type + '-icons-available', JSON.stringify(availableIcons));
          localStorage.setItem(type + '-icons-version', newVersion);
          that.l.log('Įrašyta ikonų: ', that.tag, {
            kiekis: result.icons.length,
            versija: newVersion,
          });
          if (that.ps.isAndroid()) {
            that.ps.androidHandler().iconsReceived(type, newVersion, JSON.stringify(result.icons));
          }
        } else {
          that.l.log(result.error, that.tag);
        }
      },
      (error) => {
        that.l.log('Klaida bandant gauti ' + type + ' ikonų url', that.tag, error);
      }
    );
  }

  public getPgmIcon(pgm: TPgmData): string {
    if (pgm === null) {
      return this.defaultPgmIcon;
    }
    const iconConfig = this.getIconConfig(pgm.icon, 'pgm');
    if (iconConfig === null) {
      return this.defaultPgmIcon;
    }
    let iconPath = pgm.on ? iconConfig.on : iconConfig.off;
    if (!pgm.enabled) {
      iconPath = iconConfig.disabled;
    }
    if (iconPath === '') {
      return this.defaultPgmIcon;
    }

    return iconPath;
  }

  public getPgmIconById(iconId: number) {
    const iconConfig = this.getIconConfig(iconId, 'pgm');
    if (iconConfig === null) {
      return this.defaultPgmIcon;
    }
    if (iconConfig.on === '') {
      return this.defaultPgmIcon;
    }
    return iconConfig.on;
  }

  public getAllPgmIcons(): any {
    const availableIconsJson = localStorage.getItem('pgm-icons-available');
    if (availableIconsJson === null) {
      return { id: 0, path: this.defaultPgmIcon };
    }
    const availableIcons: Array<number> = JSON.parse(availableIconsJson);
    const result = [];
    for (const iNumber of availableIcons) {
      const iconConfigJson = localStorage.getItem('pgm-icon-' + iNumber);
      if (iconConfigJson === null) {
        continue;
      }
      const iconConfig = JSON.parse(iconConfigJson);
      result.push({
        id: iconConfig.id,
        path: iconConfig.on,
      });
    }
    return result;
  }

  public getSensorIcon(sensor: TSensorData): string {
    if (sensor === null) {
      return this.defaultSensorIcon;
    }
    const iconConfig = this.getIconConfig(sensor.type, 'sensor');
    if (iconConfig === null) {
      return this.defaultSensorIcon;
    }

    return iconConfig.icon;
  }

  public getSensorIconByType(sensorType: number): string {
    const iconConfig = this.getIconConfig(sensorType, 'sensor');
    if (iconConfig === null) {
      return this.defaultSensorIcon;
    }
    return iconConfig.icon;
  }

  public getSensorTypeObject(sensorType: number): any {
    const iconConfig = this.getIconConfig(sensorType, 'sensor');
    if (iconConfig === null) {
      return {
        id: 0,
        icon: this.defaultSensorIcon,
        name: this.lang.get('sensors.types.temperature'),
        unit: '°C',
      };
    }
    return {
      id: iconConfig.id,
      icon: iconConfig.icon,
      name: this.lang.get('sensors.types.' + iconConfig.name),
      unit: this.lang.get('sensors.units.' + iconConfig.unit),
    };
  }

  public getAllSensorIcons(): any {
    const availableIconsJson = localStorage.getItem('sensor-icons-available');
    if (availableIconsJson === null) {
      return {
        id: 0,
        icon: this.defaultSensorIcon,
        name: this.lang.get('sensors.types.temperature'),
        unit: '°C',
      };
    }
    const availableIcons: Array<number> = JSON.parse(availableIconsJson);
    const result = [];
    for (const iNumber of availableIcons) {
      const iconConfigJson = localStorage.getItem('sensor-icon-' + iNumber);
      if (iconConfigJson === null) {
        continue;
      }
      const iconConfig = JSON.parse(iconConfigJson);
      result.push(iconConfig);
    }
    return result;
  }

  private getIconConfig(iconId: number, type: string): any {
    if (iconId === -1) {
      return null;
    }
    const availableIconsJson = localStorage.getItem(type + '-icons-available');
    if (availableIconsJson === null) {
      return null;
    }
    const availableIcons: Array<number> = JSON.parse(availableIconsJson);
    if (!availableIcons.includes(iconId)) {
      return null;
    }
    const iconConfigJson = localStorage.getItem(type + '-icon-' + iconId);
    if (iconConfigJson === null) {
      return null;
    }
    const iconConfig = JSON.parse(iconConfigJson);
    return iconConfig;
  }

  public getReactionIcon(reactionId: number): string {
    const iconConfig = this.getIconConfig(reactionId, 'reactions');
    if (iconConfig === null) {
      return '';
    }
    return iconConfig.iconPath;
  }

  public getAllReactions(): any {
    const availableIconsJson = localStorage.getItem('reactions-icons-available');
    if (availableIconsJson === null) {
      return [];
    }
    const availableIcons: Array<number> = JSON.parse(availableIconsJson);
    const result = [];
    for (const iNumber of availableIcons) {
      const iconConfigJson = localStorage.getItem('reactions-icon-' + iNumber);
      if (iconConfigJson === null) {
        continue;
      }
      const iconConfig = JSON.parse(iconConfigJson);
      result.push(iconConfig);
    }
    return result;
  }

  /**
   * Šią funkciją iškviečia native apsai norėdami pasitikrinti ir atsinaujinti ikonas
   *
   * @param type pgm, sensor, arba reactions
   * @param version versija, kokią turi native apsas
   */
  retrieveIcons(type: string, version: string) {
    this.l.log('Gaunami ikonu adresai', this.tag, { type, version });
    const localVersion = localStorage.getItem(type + '-icons-version');
    if (localVersion === version) {
      this.l.log('Versijos vienodos, nieko nedarom.', this.tag);
      return;
    }
    const availableIconsJson = localStorage.getItem(type + '-icons-available');
    if (availableIconsJson === null) {
      return null;
    }
    const availableIcons: Array<number> = JSON.parse(availableIconsJson);
    const result = [];
    for (const iNumber of availableIcons) {
      const iconConfigJson = localStorage.getItem(type + '-icon-' + iNumber);
      if (iconConfigJson === null) {
        continue;
      }
      const iconConfig = JSON.parse(iconConfigJson);
      result.push(iconConfig);
    }
    if (this.ps.isAndroid()) {
      this.ps.androidHandler().iconsReceived(type, localVersion, JSON.stringify(result));
    } else if (this.ps.isApple()) {
      this.ps.appleHandler().iconsReceived.postMessage({
        type,
        localVersion,
        json: JSON.stringify(result),
      });
    }
  }

  getSystemSignalLevelIcon(signalLevel: number): string {
    if (signalLevel <= 2) {
      return 'assets/images/icon-level_1-2.svg';
    } else if (signalLevel > 2 && signalLevel <= 4) {
      return 'assets/images/icon-level_3-4.svg';
    } else if (signalLevel > 4 && signalLevel <= 6) {
      return 'assets/images/icon-level_5-6.svg';
    } else if (signalLevel > 6 && signalLevel <= 8) {
      return 'assets/images/icon-level_7-8.svg';
    } else if (signalLevel > 8) {
      return 'assets/images/icon-level_9-10.svg';
    }
  }
}
