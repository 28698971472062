<div class="host" [ngClass]="backgroundClass" [style]="backgroundStyle">
  <div class="sidebar">
    <app-company-mobile-sidebar></app-company-mobile-sidebar>
  </div>
  <div class="mobile-app">
    <div class="warning">Warning!!! This section is under construction and may not work as expected.</div>
    <app-company-mobile-header></app-company-mobile-header>
    <router-outlet></router-outlet>
    <div *ngIf="!footerService.isConfirmationVisible()" class="footer-button-holder">
      <div
        *ngIf="showButton1()"
        [ngClass]="{ 'footer-button': true, 'footer-button-opaque': footerService.isOpaque(), 'footer-button-white': footerService.isWhite }"
        (click)="doButton1Click()"
      >
        {{ footerService.getButton1Text() }}
      </div>
      <div
        *ngIf="showButton2()"
        [ngClass]="{ 'footer-button': true, 'footer-button-opaque': footerService.isOpaque(), 'footer-button-white': footerService.isWhite }"
        (click)="doButton2Click()"
      >
        {{ footerService.getButton2Text() }}
      </div>
    </div>
    <app-area-keypad *ngIf="areaService.busyArea !== null && areaService.busyArea.showPinKeypad" [keypadType]="'slideout'" [maxLength]="6" [minLength]="4"></app-area-keypad>
    <app-bypass-zones *ngIf="areaService.busyArea !== null && areaService.busyArea.showZoneBypass" [dataSource]="areaService.busyArea.zonesToBypass"></app-bypass-zones>
  </div>
</div>
