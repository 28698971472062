<div [ngClass]="{ 'tzs-container': true, 'tzs-container-active': fieldIsFocused }">
    <label for="timeZone" class="tzs-label">{{ls.get('systems.labels.timeZone')}}</label>
    <div *ngIf='isReadOnly'>
        <input *ngIf="isReadOnly" class="tzs-input" [readonly]="true" id="timeZone" type="text" name="timeZone" [(ngModel)]="timeZone" (focus)='fieldIsFocused = true' (blur)='fieldIsFocused = false'/>
    </div>
    <div *ngIf="!isReadOnly">
        <select class="tzs-select" id="timeZone" name="timeZone" [(ngModel)]="timeZone" (change)='newValueSelected()'>
            <option *ngFor="let iZone of ts.availableTimeZones" [ngValue]="iZone">{{iZone}}</option>
        </select>
    </div>
</div>