import { Injectable, OnDestroy } from '@angular/core';
import { SystemCameraData } from 'src/api/v3/common';
import { TCamera } from 'src/app/models/camera';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class CameraService implements OnDestroy {
  public readonly cameras = new Map<number, TCamera>();
  public readonly systemCameras = new Map<number, Set<number>>();

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.cameras.clear();
    this.systemCameras.clear();
  });
  constructor(private auth: AuthService) {}
  ngOnDestroy(): void {
    this.cleanupSubscribtion.unsubscribe();
  }

  ingestCamera(camera: SystemCameraData): TCamera {
    const { system_id: systemId, full_url: fullUrl, assigned_pgms, ...rest } = camera;
    const processedCamera: TCamera = {
      fullUrl,
      assignedPgms: assigned_pgms ?? [],
      ...rest,
    };

    this.cameras.set(camera.id, processedCamera);
    if (!this.systemCameras.has(systemId)) {
      this.systemCameras.set(systemId, new Set());
    }
    this.systemCameras.get(systemId)?.add(camera.id);
    return processedCamera;
  }
}
