import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { DeviceService } from 'src/app/services/device.service';
import { NewSystemService } from 'src/app/services/new-system.service';
import { WifiService } from 'src/app/services/wifi.service';
import { LanguageAware } from '../language-aware';
import wp17animation from '../../../assets/animations/wp17_pair.json';
import { replaceProtegusColorWithBrandColor } from '../../general/lottie-helper';
import { AppSettings } from 'src/app-settings';

@Component({
  selector: 'app-scan-nearby-devices',
  templateUrl: './scan-nearby-devices.component.html',
  styleUrls: ['./scan-nearby-devices.component.scss'],
})
export class ScanNearbyDevicesComponent extends LanguageAware implements OnInit, OnDestroy {
  public AppSettings = AppSettings;
  private troubleshootTimer = null;
  public showTroubleshoot = false;
  public deviceNameText = '';
  private hwId = 0;
  public deviceType = '';
  private deviceNetworksListener = null;
  public wifiAnimationOptions: AnimationOptions = {
    animationData: replaceProtegusColorWithBrandColor(wp17animation),
  };
  private wifiErrorSubscriber = null;

  constructor(cdRef: ChangeDetectorRef, private router: Router, private ns: NewSystemService, private ds: DeviceService, private wfs: WifiService) {
    super(cdRef);
    if (ns.getUID() === '') {
      this.router.navigate(['/add-system']);
    }

    this.headerBar.showHeader({
      headerText: this.platform.isApple() ? '' : this.trans('systems.titles.scanNearbyDevices'),
      backUrl: '/new-system-offline',
    });
    this.footerBar.showFooter(this.trans('general.cancel'), '', true, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.wfs.stopWifiDiscovery();
      that.router.navigate(['/add-system']);
    };
    this.headerBar.onBackClicked = () => {
      that.wfs.stopWifiDiscovery();
    };
    that.wfs.startWifiDiscovery();
    if (!this.platform.isApple()) {
      this.troubleshootTimer = setTimeout(() => {
        that.showTroubleshoot = true;
        that.stopTroubleshootTimer();
      }, 4500);
    } else {
      this.showTroubleshoot = true;
      this.miniStatus.hide();
    }

    this.hwId = this.ds.getHwId();
    this.deviceType = this.ds.getDeviceTypeById(this.hwId);
    this.deviceNameText = this.ds.getDeviceNameById(this.hwId);
    this.background.setGray();
    this.deviceNetworksListener = this.wfs.onDeviceNetworkFound.subscribe(() => {
      that.router.navigate(['/nearby-device-found']);
    });
    this.wifiErrorSubscriber = this.wfs.onWifiError.subscribe(() => {
      this.wfs.stopWifiDiscovery();
      this.router.navigate(['/new-system-offline']);
    });
  }

  ngOnInit(): void {}

  private stopTroubleshootTimer() {
    if (this.troubleshootTimer === null) {
      return;
    }
    clearTimeout(this.troubleshootTimer);
    this.troubleshootTimer = null;
  }

  ngOnDestroy() {
    if (this.deviceNetworksListener === null) {
      return;
    }
    this.deviceNetworksListener.unsubscribe();
    if (this.deviceNetworksListener) {
      this.deviceNetworksListener.unsubscribe();
    }
    if (this.wifiErrorSubscriber) {
      this.wifiErrorSubscriber.unsubscribe();
    }
  }

  public openAppleWifiSettings() {
    this.platform.appleHandler().openWifiWindow.postMessage('');
  }

  public performCommunicationWithDevice() {
    this.router.navigate(['/wifi-details']);
  }
}
