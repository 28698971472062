<div class="section_header padding_l16">{{trans('systems.labels.systemMasters')}}</div>
<div class="white_round padding_l16">
    <app-button-double-line-arrow *ngFor='let iMaster of masters; let i = index'
        [firstLine]="getMasterName(iMaster)"
        [secondLine]="getMaterEmail(iMaster)"
        (click)='openOwnerSettings(iMaster)'
        [showArrow]='iMaster.email === us.currentUser.email || us.currentUser.role === 0' [withBorder]="masters.length > 1 && i !== masters.length-1"
    ></app-button-double-line-arrow>
</div>
<div *ngIf="!masterInTheList && supportsAdmins">
    <div class="section_header" id="sul_master_text">{{trans('users.labels.masterNotInDevice')}}</div>
    <div id="sul_master_button">
        <div class="button" (click)="addMaster()">{{trans('users.labels.saveMaterToDevice')}}</div>
    </div>
</div>
<div *ngIf="systems.activeSystem.hwType === 'GV17' || systems.activeSystem.hwType === 'CWI'">
    <div class="section_header padding_l16">{{trans('users.labels.unauthorizedAccess')}}</div>
    <div class="white_round padding_l16" (click)="deviceUserClick(unauthorizedUser.id)">
        <app-button-double-line-arrow [firstLine]="trans('users.labels.unauthorizedUsers')" [secondLine]="unauthorizedUser.enable_data === 1 ? trans('general.enabled') : trans('general.disabled')"></app-button-double-line-arrow>
    </div>
</div>
<div *ngIf="supportsAdmins" class="section_header padding_l16">{{trans('users.labels.deviceAdmins')}}</div>
<div *ngIf="supportsAdmins" class="white_round padding_l16">
    <app-button-double-line-arrow *ngFor='let iAdmin of admins; let i = index'
        [firstLine]="iAdmin.name === ''
            ? (iAdmin.phone !== ''
                ? (iAdmin.isOwner && !systems.activeSystem.amIMaster
                    ? ((iAdmin.ownerPermissions.show_phone_admins && currentUserIsAdmin) || (iAdmin.ownerPermissions.show_phone_users && !currentUserIsAdmin)
                        ? iAdmin.phone
                        : '<' + trans('general.hidden').toLowerCase() + '>')
                    : iAdmin.phone)
                : trans('users.labels.noName') + ' ' + trans('users.labels.noPhone'))
            : (iAdmin.isOwner && !systems.activeSystem.amIMaster
                ? ((iAdmin.ownerPermissions.show_name_admins && currentUserIsAdmin) || (iAdmin.ownerPermissions.show_name_users && !currentUserIsAdmin)
                    ? iAdmin.name
                    : '<' + trans('general.hidden').toLowerCase() + '>')
                : iAdmin.name)"
        [secondLine]="isCg ? '' : (iAdmin.email === ''
            ? trans('users.labels.noEmail')
            : (iAdmin.isOwner && !systems.activeSystem.amIMaster
                ? ((iAdmin.ownerPermissions.show_email_admins && currentUserIsAdmin) || (iAdmin.ownerPermissions.show_email_users && !currentUserIsAdmin)
                    ? iAdmin.email
                    : '<' + trans('general.hidden').toLowerCase() + '>')
                : iAdmin.email)
            )"
        (click)='deviceUserClick(iAdmin.id)' [showArrow]='systems.activeSystem.amIMaster || (currentUserIsAdmin && !iAdmin.isOwner)'
        [withBorder]="admins.length > 1 && i !== admins.length-1"
    ></app-button-double-line-arrow>
</div>
<app-section-with-filter *ngIf="!isCg" [sectionText]="trans('systems.labels.systemUsers')" [sectionName]="'user-list'" [sortableFields]="[{field: 'name', name: trans('auth.form.nameAuth')}, {field: 'email', name: trans('auth.form.email')}]" [dataSource]="simple" [filterableFields]="['name','email','phone']"></app-section-with-filter>
<div class="white_round padding_l16" *ngIf="!fs.hasFilterFor('user-list')">
    <app-button-double-line-arrow *ngFor='let iUser of simple; let i = index'
        [firstLine]="iUser.name === ''
            ? (iUser.phone !== ''
                ? (iUser.isOwner && !systems.activeSystem.amIMaster
                    ? ((iUser.ownerPermissions.show_phone_admins && currentUserIsAdmin) || (iUser.ownerPermissions.show_phone_users && !currentUserIsAdmin)
                        ? iUser.phone
                        : '<' + trans('general.hidden').toLowerCase() + '>')
                    : iUser.phone)
                : trans('users.labels.noName') + ' ' + trans('users.labels.noPhone'))
            : (iUser.isOwner && !systems.activeSystem.amIMaster
                ? ((iUser.ownerPermissions.show_name_admins && currentUserIsAdmin) || (iUser.ownerPermissions.show_name_users && !currentUserIsAdmin)
                    ? iUser.name
                    : '<' + trans('general.hidden').toLowerCase() + '>')
                : iUser.name)"
        [secondLine]="iUser.email === ''
            ? trans('users.labels.noEmail')
            : (iUser.isOwner && !systems.activeSystem.amIMaster
                ? ((iUser.ownerPermissions.show_email_admins && currentUserIsAdmin) || (iUser.ownerPermissions.show_email_users && !currentUserIsAdmin)
                    ? iUser.email
                    : '<' + trans('general.hidden').toLowerCase() + '>')
                : iUser.email)"
        (click)='deviceUserClick(iUser.id)'
        [withBorder]="simple.length > 1 && i !== simple.length-1"
    ></app-button-double-line-arrow>
</div>
<div class="white_round padding_l16" *ngIf="fs.hasFilterFor('user-list')">
    <app-button-double-line-arrow *ngFor="let iUser of fs.getFilteredItemsFor('user-list'); let i = index"
        [firstLine]="iUser.name === ''
            ? (iUser.phone !== ''
                ? (iUser.isOwner && !systems.activeSystem.amIMaster
                    ? ((iUser.ownerPermissions.show_phone_admins && currentUserIsAdmin) || (iUser.ownerPermissions.show_phone_users && !currentUserIsAdmin)
                        ? iUser.phone
                        : '<' + trans('general.hidden').toLowerCase() + '>')
                    : iUser.phone)
                : trans('users.labels.noName') + ' ' + trans('users.labels.noPhone'))
            : (iUser.isOwner && !systems.activeSystem.amIMaster
                ? ((iUser.ownerPermissions.show_name_admins && currentUserIsAdmin) || (iUser.ownerPermissions.show_name_users && !currentUserIsAdmin)
                    ? iUser.name
                    : '<' + trans('general.hidden').toLowerCase() + '>')
                : iUser.name)"
        [secondLine]="iUser.email === ''
            ? trans('users.labels.noEmail')
            : (iUser.isOwner && !systems.activeSystem.amIMaster
                ? ((iUser.ownerPermissions.show_email_admins && currentUserIsAdmin) || (iUser.ownerPermissions.show_email_users && !currentUserIsAdmin)
                    ? iUser.email
                    : '<' + trans('general.hidden').toLowerCase() + '>')
                : iUser.email)"
        (click)='deviceUserClick(iUser.id)'
        [withBorder]="simple.length > 1 && i !== simple.length-1"
    ></app-button-double-line-arrow>
</div>
<div class="separator32" *ngIf="simple.length !== 0 || admins.length !== 0"></div>
<app-plus-button *ngIf='systems.activeSystemHelper.can.add.users()' (plusClicked)="onAddUser()" [showLimiter]="systems.activeSystem.maxDeviceUsers !== 0" [currentCount]="simple.length + admins.length" [maxLimit]="systems.activeSystem.maxDeviceUsers"></app-plus-button>
<app-messagebox #adminListFull [headerText]="trans('users.errors.deviceAdminListFull')" [messageContent]="systems.activeSystemHelper.supports.simpleUsers() ? trans('users.labels.adminToReplaceInfo') : trans('users.labels.adminToReplaceNoUsersInfo')" [buttons]="g.MessageBoxButtons.CustomButton1" [button1Text]="trans('users.buttons.selectAdmin')" (button1Clicked)="gotoReplaceAdmin()"></app-messagebox>