import { Component, OnInit, OnDestroy } from '@angular/core';
import { ProgressService } from 'src/app/services/progress.service';

@Component({
  selector: 'app-progress',
  templateUrl: './progress.component.html',
  styleUrls: ['./progress.component.scss'],
})
export class ProgressComponent implements OnInit, OnDestroy {
  private subscription = null;
  constructor(public pb: ProgressService) {
    const that = this;
    this.subscription = pb.onProgressChange.subscribe((percent) => {
      that.updateView(percent);
    });
  }

  ngOnInit() {
    this.updateView(0);
  }

  ngOnDestroy() {
    if (this.subscription !== null) {
      this.subscription.unsubscribe();
    }
  }

  private updateView(progressPercent: number) {
    const bar: HTMLElement = document.querySelector('#progress-current-value');
    bar.style.width = progressPercent.toString() + 'vw';
  }
}
