/* eslint-disable no-shadow */
export enum MessageBoxButtons {
  Ok,
  YesNo,
  OkCancel,
  YesNoCancel,
  CustomButton1,
  CustomButton1Button2,
  CustomButton1Cancel,
}
