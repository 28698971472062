<div class='pb-container {{centerButton ? "pb-container-middle" : "pb-container-bottom"}} {{aboveFooter ? "pb_above_footer" : ""}}'>
    <div [ngClass]="{ 'pb-button': true, 'pb-button-bottom': !centerButton }" (click)='pliusButtonClick()'>
        <div class="pb-plus" *ngIf="!showLimiter || currentCount !== maxLimit"><div [inlineSVG]="'assets/images/plus.svg'"></div></div>
        <div *ngIf='centerButton && (!showLimiter || currentCount !== maxLimit)' class='plus-button-subtext'>{{buttonText}}</div>
        <div *ngIf='centerButton && showLimiter && currentCount === maxLimit' class='plus-button-subtext'>{{lang.get('general.limitReached')}}</div>
        <div *ngIf="showLimiter && !centerButton" class="pb_limiter_container">
            <span class="pb_limiter_current">{{currentCount}}</span>
            <span> {{lang.get('general.of')}} </span>
            <span class="pb_limiter_max">{{maxLimit}}</span>
        </div>
    </div>
</div>