import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserRole } from '../models/user-role';
import { ApiRequestService } from '../services/api-request.service';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class InstallerRoleGuardService implements CanActivate {
  constructor(private us: UserService, private rs: ApiRequestService, private router: Router, private g: GlobalService) {}

  canActivate() {
    if (this.rs.hasToken() && this.us.currentUser.id === 0) {
      this.us.loadUserData();
    }
    if (this.us.currentUser.role !== UserRole.Installer) {
      this.router.navigate([this.g.getHomeUrl()]);
      return false;
    }
    return true;
  }
}
