<div class="page">
  <div>
    <span>Awww... Don't worry.</span>
    <span>It's just a</span>
    <h1>404 error</h1>
  </div>

  <ng-lottie class="animation" [options]="animationOptions"></ng-lottie>

  <p>What you are looking for may have been misplaced in Long Term Memory.</p>

  <app-button>Go back</app-button>
</div>
