import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from 'src/app/api/user.service';
import { LoginService } from 'src/app/login/login.service';

@Component({
  selector: 'app-loading-screen',
  template: '',
})
export class LoadingScreenComponent implements OnInit {
  constructor(private login: LoginService, private user: UserService, private router: Router) {}

  ngOnInit() {
    if (this.user.user) {
      this.router.navigate(this.login.determineLoginDestination(), { replaceUrl: true });
    } else {
      this.router.navigate(['/login'], { replaceUrl: true });
    }
  }
}
