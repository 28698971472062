import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { TPgmData } from 'src/app/models/pgm-data';
import { NewSystemService } from 'src/app/services/new-system.service';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { TEditableComponent } from 'src/app/models/editable-component';

@Component({
  selector: 'app-area-pgms',
  templateUrl: './area-pgms.component.html',
  styleUrls: ['./area-pgms.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AreaPgmsComponent extends LanguageAware implements OnInit {
  private editingCurrent = false;

  constructor(cdRef: ChangeDetectorRef, private router: Router, private ns: NewSystemService, private es: EditSystemService) {
    super(cdRef);
    this.tag = 'AreaPgms';
    this.editingCurrent = this.ns.getCurrentArea() === 0 && this.es.getEditableComponent(TEditableComponent.Area) !== null;
    const backUrl = this.editingCurrent ? '/system-area' : '/add-system-area';
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.pickPgmToControl'),
      backUrl,
    });
    const havePgm = (this.editingCurrent ? this.es.getId(TEditableComponent.Output) : this.ns.getCurrentPgm()) !== 0;
    this.footerBar.showFooter(havePgm ? this.trans('systems.labels.deselectCurrent') : this.trans('systems.labels.none'), '', false, false);
    this.background.setGray();
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.onNone();
    };
    if (!this.editingCurrent && this.ns.getCurrentArea() === 0) {
      this.router.navigate(['/add-system-area-list']);
    } else if (this.editingCurrent && this.es.getEditableComponent(TEditableComponent.Area) === null) {
      this.router.navigate(['/system-area-list']);
    }
  }

  ngOnInit(): void {}

  private onNone() {
    if (!this.editingCurrent && this.ns.getCurrentPgm() !== 0) {
      this.log('atjungiam pgm ', this.ns.getCurrentPgm());
      const pgm = this.ns.getPgm(this.ns.getCurrentPgm());
      pgm.control_area = false;
      pgm.area_no = 0;
      this.ns.setCurrentPgm(0);
    }
    if (this.editingCurrent && this.es.getId(TEditableComponent.Output) !== 0) {
      const pgm = this.systems.activeSystem.pgms.find((p) => p.queue_no === this.es.getEditableComponent(TEditableComponent.Output).queue_no);
      pgm.control_area = false;
      pgm.area_no = 0;
      this.es.endComponentEdit(TEditableComponent.Output);
    }
    this.router.navigate([this.editingCurrent ? '/system-area' : '/add-system-area']);
  }

  public getPgms(): TPgmData[] {
    const pgms = this.editingCurrent ? this.systems.activeSystem.pgms : this.ns.getDeviceStatus().pgms;
    const result: TPgmData[] = [];
    for (const i of pgms) {
      if (i.area_no === 0 && i.enabled) {
        // imam tik nepriskirtus sritims ir aktyvius
        result.push(i);
      }
    }
    return result;
  }

  public pgmClick(pgmNumber: number) {
    if (this.editingCurrent && this.es.getEditableComponent(TEditableComponent.Area) === null) {
      this.router.navigate(['/system-area-list']);
    } else if (!this.editingCurrent && this.ns.getCurrentArea() === 0) {
      this.router.navigate(['/add-system-area-list']);
    }

    let currentPgm: TPgmData = null; // Čia tas localPgm iš srities lango komponento.
    let pgm: TPgmData = null; // Čia iš sistemos pgm sąrašo ištrauktas pgm.
    let areaNo = 0;
    if (this.editingCurrent) {
      currentPgm = this.es.getEditableComponent(TEditableComponent.Output);
      pgm = this.systems.activeSystem.pgms.find((p) => p.queue_no === pgmNumber);
      areaNo = this.es.getEditableComponent(TEditableComponent.Area).queue_no;
    } else {
      currentPgm = this.ns.getCurrentPgmObj();
      pgm = this.ns.getPgm(pgmNumber);
      areaNo = this.ns.getCurrentArea();
    }
    currentPgm.queue_no = pgmNumber;
    currentPgm.area_no = areaNo;
    currentPgm.control_area = true;
    currentPgm.pulseTime = pgm.pulseTime;
    currentPgm.type = pgm.type;
    currentPgm.name = pgm.name;
    this.router.navigate([this.editingCurrent ? '/system-area' : '/add-system-area']);
  }
}
