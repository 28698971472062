import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { NewSystemService } from 'src/app/services/new-system.service';
import { THomeTheme } from 'src/app/models/home-theme';

@Component({
  selector: 'app-home-background-selection',
  templateUrl: './home-background-selection.component.html',
  styleUrls: ['./home-background-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HomeBackgroundSelectionComponent extends LanguageAware implements OnInit {
  public selectedTheme = 0;
  private workingWithActiveSystem = false;
  public availableThemes = [
    { start: '#0070A7', end: '#79BAD9', full: '' },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #00C9FF 0%, #92FE9D 100%)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #1CB5E0 0%, #000851 100%)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #FC466B 0%, #3F5EFB 100%)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #4b134f, #c94b4b)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #b20a2c, #fffbd5)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #24243e, #302b63, #0f0c29)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #96c93d, #00b09b)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #f7b733, #fc4a1a)',
    },
    {
      start: '#0070A7',
      end: '#79BAD9',
      full: 'linear-gradient(180deg, #ACB6E5, #74ebd5)',
    },
  ];

  constructor(cdRef: ChangeDetectorRef, private ns: NewSystemService, private router: Router) {
    super(cdRef);
    this.tag = 'ThemeSelection';
    this.workingWithActiveSystem = this.ns.getUID() === '';
    if (this.workingWithActiveSystem) {
      this.headerBar.showHeader({
        headerText: this.trans('systems.titles.homeBackgrond'),
        backUrl: '/system-information',
      });
    } else {
      this.headerBar.showHeader({
        headerText: this.trans('systems.titles.homeBackgrond'),
        backUrl: '/add-system-details',
      });
    }
    this.footerBar.showFooter('', '', false, false);
    this.background.setGray();
    const that = this;
    let theme: THomeTheme = null;
    if (this.workingWithActiveSystem) {
      theme = this.systems.activeSystem.theme;
    } else {
      theme = this.ns.getTheme();
    }
    for (let i = 0; i < this.availableThemes.length; i++) {
      if (this.availableThemes[i].start === theme.startColor && this.availableThemes[i].end === theme.endColor && this.availableThemes[i].full === theme.fullBackground) {
        this.selectedTheme = i;
      }
    }
  }

  ngOnInit() {}

  private themeConfirm() {
    if (this.workingWithActiveSystem) {
      this.systems.activeSystem.theme = {
        startColor: this.availableThemes[this.selectedTheme].start,
        endColor: this.availableThemes[this.selectedTheme].end,
        fullBackground: this.availableThemes[this.selectedTheme].full,
      };
      this.router.navigate(['/system-information']);
    } else {
      this.ns.setTheme({
        startColor: this.availableThemes[this.selectedTheme].start,
        endColor: this.availableThemes[this.selectedTheme].end,
        fullBackground: this.availableThemes[this.selectedTheme].full,
      });
      this.router.navigate(['/add-system-details']);
    }
  }

  public themeClick(themeNumber: number) {
    this.log('therme click, ', themeNumber);
    this.selectedTheme = themeNumber;
    const theme = this.availableThemes[this.selectedTheme];

    if (theme.full !== '') {
      document.body.style.background = theme.full;
    } else {
      document.body.style.background = 'linear-gradient(180deg, ' + theme.start + ' 0%, ' + theme.end + ' 100%)';
    }
    this.themeConfirm();
  }

  public getSelectedTheme(): number {
    return this.selectedTheme;
  }
}
