import { isBlob } from '../http';

export type ErrorResponse = { success: false; error: string };
export type ExErrorResponse = ErrorResponse & { error_code: number };
export type BasicSuccessResponse = { success: true };
export type WithLang = { lang?: string };
export type WithWarning = { warning: string };

type FlatFormData = [string, string | Blob][];
type BasicFormDataValues = string | number | bigint | boolean | Blob;
type RecursiveFormDataSolverValue = BasicFormDataValues | { [key: string]: RecursiveFormDataSolverValue } | RecursiveFormDataSolverValue[];

const prefixError = (): never => {
  throw new Error("Can't have nameless top values");
};

const recursiveFormDataSolver = (value: RecursiveFormDataSolverValue, prefix?: string): FlatFormData => {
  const name = () => prefix ?? prefixError();
  switch (typeof value) {
    case 'string':
      return [[name(), value]];
    case 'bigint':
    case 'number':
    case 'boolean':
      return [[name(), value.toString()]];
    case 'object':
      if (Array.isArray(value)) {
        return value.flatMap((v, i) => recursiveFormDataSolver(v, `${prefix ?? ''}[{${i}}]`));
      }
      if (isBlob(value)) {
        return [[name(), value]];
      }
      return Object.entries(value).flatMap(([n, v]) => recursiveFormDataSolver(v, `${prefix ?? ''}.${n}`));
    default:
      return value;
  }
};

export const objectToFormData = <TValues extends RecursiveFormDataSolverValue, TObject extends { [key: string]: TValues }>(data: TObject): FormData => {
  const fd = new FormData();
  recursiveFormDataSolver(data, '').forEach(([n, v]) => fd.append(n, v));
  return fd;
};

// Convert js object to query params compatible with php arrays
export const objectToParams = <TValues extends RecursiveFormDataSolverValue, TObject extends { [key: string]: TValues }>(data: TObject): URLSearchParams => {
  const params = new URLSearchParams();
  if (data) {
    recursiveFormDataSolver(data, '')
      .filter(([, v]) => !isBlob(v))
      .forEach(([n, v]) => params.append(n, v as string));
  }
  return params;
};

// eslint-disable-next-line no-shadow
export enum SystemCommand {
  Capabilities = '0001',
  Status = '0002',
  SetOutput = '0003',
  SetTime = '0004',
  Reset = '0005',
  BypassZone = '0006',
  GetTime = '0007',
  GetSensorValues = '0008',
  SetAreaStatus = '0009', // ARM, DISARM, STAY, SLEEP
  DoOutputPulse = '000A',
  SetTimeSilent = '000B',
  SetPingPeriod = '000C',
  StatusNew = '000D',
  GetAreaSates = '000E',
  GetFaults = '000F',
  ResetFireSensors = '0010',
  GetUserList = '0011',
  WriteUserList = '0012',
  AddUsersToList = '0013',
  GetUserListCG17 = '0014',
  WriteUserListCG17 = '0015',
  BypassAreaZones = '0016',
  SetObjectId = '0017',
  SetChannelConfig = '0018',
  SetIOConfig = '0019',
  GetSchedule = '001A',
  SetSchedule = '001B',
  GetCheckSum = '001C',
  SetTimezone = '001D',
  SetUserWorkMode = '001E',
  GetPanelStatus = '001F',
  GetRFData = '0020',
  ReadConfig16x = '0021', // G16x // E16x
  WriteConfig16x = '0022', // G16x // E16x
  CancelOrSilenceSiren = '0023',
  TriggerEvent = '0024', // Triggers panic, medical or fire event
}

// eslint-disable-next-line no-shadow
export enum UserRole {
  SuperAdmin = 0,
  SimpleUser = 1,
  Installer = 2,
  Company = 3,
}

export type BaseUserData = {
  name: string;
  email: string;
  role: UserRole;
  socket_token: string;
  socket_port: number;
  hasOneSystem: boolean;
  hasManySystems: boolean;
  systemHwId: string;
  systemId: number;
  id: number;
  token: string;
  phone: string;
  date_format: number;
  time_format: string;
  is_social_account: boolean;
  isPasswordSet: boolean;
  homeConfigurations: object[]; // TODO: better type
  active: number;
  country: string;
  appVersion: {
    major: number;
    minor: number;
    build: number;
    date: number;
  };
  limits: {
    default_system_count: boolean;
    system_count: number;
    default_camera_count: boolean;
    camera_count: number;
  };
  user_can_transfer_device: 0 | 1;
};

type WithPanicData = {
  panic_settings: object; //TODO: better type
};

type WithCompanyCommonData = {
  company: string;
  logo: string;
};

export type ReactionBasicData = {
  id: number;
  name: string;
  iconPath: string;
  iconPathPng: string;
  companyId: number;
  group: string;
};

export type ReactionData = {
  id: number;
  name: string;
  active: boolean;
  company_id: number;
  custom_sound: number;
  default_name: boolean;
  group_id: number;
  image: string;
  image_png: string;
  monas_id: number;
  redirect_to: number;
};

export type ReactionGroupData = {
  id: number;
  company_id: number;
  name: string;
  icon: string;
  default_name: boolean;
};

export type WithReactionsData = {
  reactions: ReactionData[];
  reactionGroups: ReactionGroupData[];
};

export type CompanyUserData = BaseUserData & {
  role: UserRole.Company;
  lastSeenReactions: object[]; //TODO: better type
} & WithCompanyCommonData &
  WithPanicData &
  WithReactionsData;

export type InstallerUserData = BaseUserData & {
  role: UserRole.Installer;
  company_id: number;
} & WithCompanyCommonData;

export type SuperUserData = BaseUserData & {
  role: UserRole.SuperAdmin;
  ipcoms: {
    company_id: number;
    description: string;
    encryption_for_devices: string;
    host: string;
    host_for_devices: string;
    id: number;
    ipcom_logo: string | null;
    name: string;
    own_ipcom: boolean;
    password: string;
    peer_name: string;
    port: number;
    port_for_devices: string;
    priority_logo: boolean;
    region: number;
    user_name: string;
  }[];
  regions: any[]; //TODO: better type
  settings: {
    textual: {
      field: string;
      value: string;
    }[];
    togglable: {
      field: string;
      name: string;
      value: boolean;
    }[];
  };
} & WithPanicData &
  WithReactionsData;

export type UserData = BaseUserData | CompanyUserData | InstallerUserData | SuperUserData;

export type SystemEventData = {
  id: number;
  title: string;
  subTitle: string;
  time: number;
  area: number;
  reaction: number;
  area_event: boolean;
};

type SystemEventSettingsData = {
  eventTypes?: [{ name: 'All types'; company: -1 }];
  events: SystemEventData[];
  dailyEvents?: [];
  filters?: {
    date_from: null;
    date_to: null;
    area: null;
    system: null;
    type: null;
    company: -1;
    returnEventCount: true;
    forApi: true;
    offsetEvents: 0;
    offsetCount: 0;
    userName: '';
    reactions: null;
  };
};

type SystemThemeData = {
  background_start: string;
  background_end: string;
  full_background: string;
};

type SystemNotificationData =
  | ErrorResponse
  | {
      success: true;
      sounds_on: boolean;
      notifications: {
        id: number;
        monas_id: number;
        name: string;
        on: boolean;
        alert_sound_on: boolean;
      }[];
      global_value: boolean;
    };

export type SystemSensorData = {
  id: number;
  name: string;
  system_id: string;
  queue_no: number;
  enabled: number; // 0 ir 1?
  type: number;
  alarm_high: boolean;
  alarm_low: boolean;
  high_value: number;
  low_value: number;
};

export type SystemZoneData = {
  id: number;
  name: string;
  area_id: number;
  queue_no: number;
  system_id: number;
  visible: boolean;
  native: boolean;
};

export type SystemCameraData = {
  id: number;
  name: string;
  full_url: string;
  host: string;
  port: string;
  user: string;
  pass: string;
  path: string;
  system_id: number;
  assigned_pgms: number[];
};

type SystemOwnerData = {
  id: number;
  name: string;
  email: string;
  phone_number: null; // String?
  pivot: { system_id: number; user_id: number }; // Laravel artifaktas
};

// eslint-disable-next-line no-shadow
export enum IoType {
  Disabled = 0,
  Input = 1,
  Output = 2,
}

export type SystemPgmData = {
  id: number;
  name: string;
  icon_number: number;
  queue_no: number;
  enabled: 0 | 1;
  on: 0 | 1;
  pgm_as_area_ck: 0 | 1;
  type: 'level' | 'pulse';
  pulse_time_in_seconds: number;
  pulse_start_time: string | null;
  schedule_no: number;
  io_type: IoType;
  io_ability: '' | 'I' | 'O' | 'M' | 'W';
  area_to_arm: null; // TODO: Better type
};

export type SystemAreaData = {
  id: number;
  system_id: number;
  name: string;
  queue_no: number;
  monas_id: null;
  status: number; // TODO: Better type
  alarm_in_memory: boolean;
  status_time: number;
  last_person_name: string;
  alarm_time: number;
  alarm_type: number; // TODO: Better type
  show_keypad: boolean;
  can_bypass: boolean;
  zones: SystemZoneData[];
};

export type SystemThermostatData = {
  id: number;
  name: string;
  queue_no: number;
  system_id: number;
  temperatures: string; // JSON number[]
  assigned_sensors: string; // JSON number[]
  assigned_output: number;
  active_sensor: number;
  action: number;
};

export type SystemDeviceUserData = {
  id: number;
  name: string;
  email: string;
  phone: string;
  address: string;
  key: string;
  queue_no: number;
  system_code_no?: number;
  area_id: number;
  keyboard_code?: string;
  pin?: string;
  protegus_user_id: number;
  system_id: number;
  areas: string;
  can_see_events: boolean;
  canEditUsers: boolean;
  enable_data?: number;
  pgms?: number;
  present?: boolean;
  schedule_no?: number;
  user_id?: number;
};

export type SystemProtegusUserData = {
  id: number;
  name: string;
  email: string;
  role: UserRole;
  active: 0 | 1;
  created_at: string;
  phone_number: string | null;
  company: string | null;
  dashboard_order: string;
  privacy_policy_accepted: boolean;
  soc_account: boolean;
  social_id: string;
  soc_type: string;
  company_id: number;
  master: boolean;
  phone: string | null;
  country: string | null;
  limits: {
    default_system_count: boolean;
    system_count: number;
    default_camera_count: boolean;
    camera_count: number;
  };
};

export type SystemData = {
  id: number;
  name: string;
  hwType: string;
  supported_commands: string;
  address: string;
  amIMaster: boolean;
  online: boolean;
  canEditUsers: boolean;
  imei: string;
  mpass: string;
  timeZone: string;
  pgms: SystemPgmData[];
  events: SystemEventSettingsData;
  direct: number;
  areas: SystemAreaData[];
  noSleepStay: boolean;
  hasRealSensors: boolean;
  centralPanel: number;
  coordinates: string;
  theme: SystemThemeData;
  notifications: SystemNotificationData;
  sensors: SystemSensorData[];
  signalLevel: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9; // 10?
  zones: SystemZoneData[];
  canBypassZone: boolean;
  canUnbypassZone: boolean;
  protegus_users: SystemProtegusUserData[];
  device_users: SystemDeviceUserData[];
  maxDeviceUsers: number;
  eventConfiguration: string;
  cameras: SystemCameraData[];
  thermostats: SystemThermostatData[];
  installer_id: 0 | number;
  company_id: 0 | number;
  supportsFireReset: boolean;
  amIWorking: boolean; // False?
  canISeeEvents: boolean; // False?
  device_id: number; // 59 per tris sistemas?
  privacyOfOwners: object[]; //TODO: better type
  owners?: SystemOwnerData[];
};

export type WithLastSystem = {
  lastSystem: SystemData | null;
};
