import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from '../language-aware';

@Component({
  selector: 'app-quick-outputs',
  templateUrl: './quick-outputs.component.html',
  styleUrls: ['./quick-outputs.component.scss'],
})
export class QuickOutputsComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private router: Router) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.outputs'),
      backUrl: this.g.getHomeUrl(),
      showSettings: true,
    });
    this.background.setGray();
    const that = this;
    localStorage.setItem('ref', 'quick');
    this.headerBar.onActionButtonClicked = () => {
      that.router.navigate(['/system-pgm-list']);
    };
  }

  ngOnInit(): void {}
}
