<div class="checkbox_master_container">
  <div
    [ngClass]="{
      checkbox_container: true,
      checkbox_big: isBig,
      round_checkbox: isRound,
      checkbox_checked: valueFromArray === null ? checkValue : valueFromArray[indexInArray],
      checkbox_secondary: isSecondaryColor,
      checkbox_secondary_checked: isSecondaryColor && (valueFromArray === null ? checkValue : valueFromArray[indexInArray])
    }"
    (click)="changeValue()"
  >
    <svg *ngIf="valueFromArray === null ? checkValue : valueFromArray[indexInArray]" viewBox="0 0 14.135 10.222">
      <path d="M10.444,18.471,6.469,14.5,5.115,15.84l5.329,5.329,8.806-8.878-1.344-1.344Z" transform="translate(-5.115 -10.947)" />
    </svg>
  </div>
  <div class="checkbox_text" (click)="changeValue()">
    <ng-content></ng-content>
  </div>
</div>
