import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNumeric } from 'src/shim';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PopupType } from 'src/app/models/popup-type';
import { SlideoutItem } from 'src/app/models/slideout-item';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-cp-edit-event',
  templateUrl: './cp-edit-event.component.html',
  styleUrls: ['./cp-edit-event.component.scss'],
})
export class CpEditEventComponent extends LanguageAware implements OnInit {
  public eventDescItem = null;
  public reactionText = '';
  private backUrl = this.g.getHomeUrl() + '/edit-events';
  private canDelete = true;

  constructor(cdRef: ChangeDetectorRef, private router: Router, ar: ActivatedRoute, private pp: PopupService, private es: EditSystemService) {
    super(cdRef);
    this.eventDescItem = this.es.getEditableComponent(TEditableComponent.EventDescription);
    const reactionItem = this.es.getEditableComponent(TEditableComponent.Reaction);
    if (reactionItem !== null) {
      this.backUrl = this.g.getHomeUrl() + '/edit-reaction';
      this.canDelete = false;
    }
    const textForHeader = this.eventDescItem.id === 0 ? this.trans('events.settings.titleAdd') : this.eventDescItem.default_name;
    this.rebuildReactionText();
    this.headerBar.showHeader({
      headerText: textForHeader,
      backUrl: this.backUrl,
      actionButtonText: this.eventDescItem.id === 0 || !this.canDelete ? undefined : this.trans('general.delete'),
    });
    this.background.setGray();
    const that = this;
    this.headerBar.onBackClicked = () => {
      that.revert();
      that.es.endComponentEdit(TEditableComponent.EventDescription);
    };
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    this.footerBar.onButton1Click = () => {
      that.revert();
      that.es.endComponentEdit(TEditableComponent.EventDescription);
      that.router.navigate([this.backUrl]);
    };
    this.footerBar.onButton2Click = () => {
      that.performSave();
    };
    if (this.eventDescItem.id !== 0) {
      this.headerBar.onActionButtonClicked = () => {
        that.doDelete();
      };
    }
  }

  ngOnInit(): void {}

  private rebuildReactionText() {
    const reaction = this.us.currentUser.reactions.find((r) => r.id === this.eventDescItem.reaction_id);
    if (reaction === undefined) {
      this.reactionText = this.us.currentUser.reactions[0].default_name
        ? this.trans('reactionNames.reactions.' + this.us.currentUser.reactions[0].name)
        : this.us.currentUser.reactions[0].name;
    } else {
      this.reactionText = reaction.default_name ? this.trans('reactionNames.reactions.' + reaction.name) : reaction.name;
    }
  }

  private performSave() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    const that = this;
    this.miniStatus.show(this.trans('general.saving'));
    this.api
      .post(
        '/edit/event-description',
        {
          id: this.eventDescItem.id,
          code: this.eventDescItem.event_code,
          name: this.eventDescItem.default_name,
          reaction: this.eventDescItem.reaction_id,
          active: this.eventDescItem.active,
          qualifier: this.eventDescItem.qualifier,
          area_event: this.eventDescItem.area_event,
        },
        true
      )
      .subscribe(
        (result) => {
          that.miniStatus.hide();
          if (result.success) {
            if (that.eventDescItem.id === 0) {
              that.eventDescItem.id = result.newId;
              that.us.eventDescriptions.push(that.eventDescItem);
            }
            that.es.endComponentEdit(TEditableComponent.EventDescription);
            that.router.navigate([this.backUrl]);
          } else {
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.toaster.postError(that.trans('auth.errors.serverSideError'));
          that.miniStatus.hide();
        }
      );
  }

  private revert() {
    const original = this.es.getComponentBeforeModification(TEditableComponent.EventDescription);
    if (original === null) {
      return;
    }
    const found = this.us.eventDescriptions.find((ed) => ed.id === original.id);
    if (found === undefined) {
      this.us.eventDescriptions.push(original);
    } else {
      found.default_name = original.default_name;
      found.area_event = original.area_event;
      found.qualifier = original.qualifier;
      found.event_code = original.event_code;
      found.active = original.active;
      found.reaction_id = original.reaction_id;
    }
  }

  public changeName() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.trans('events.settings.labels.nameEvent'),
        field1: {
          maxLen: 255,
          oldValue: this.eventDescItem.default_name,
        },
        onSubmit: (res) => {
          that.eventDescItem.default_name = res;
        },
      },
      PopupType.String
    );
  }

  public changeQualifier() {
    const that = this;
    this.pp.showSlideout(
      {
        headerText: this.trans('events.settings.labels.qualifier'),
        items: [this.trans('events.settings.qualifier.E'), this.trans('events.settings.qualifier.R')],
        onSubmit: (res) => {
          that.eventDescItem.qualifier = res === 0 ? 'E' : 'R';
        },
      },
      PopupType.Slideout
    );
  }

  public changeCidCode() {
    const that = this;
    this.pp.showPopup(
      {
        headerText: this.trans('events.settings.labels.code'),
        field1: {
          isHex: true,
          maxLen: 3,
          oldValue: this.eventDescItem.event_code,
        },
        onSubmit: (res) => {
          that.eventDescItem.event_code = res;
        },
      },
      PopupType.String
    );
  }

  public changeReaction() {
    const that = this;
    const reactions: SlideoutItem[] = [];
    for (const iReaction of this.us.currentUser.reactions) {
      reactions.push({
        text: iReaction.default_name ? this.trans('reactionNames.reactions.' + iReaction.name) : iReaction.name,
        value: iReaction.id,
      });
    }
    this.pp.showSlideout(
      {
        headerText: this.trans('events.settings.labels.reaction'),
        items: reactions,
        onSubmit: (res) => {
          that.eventDescItem.reaction_id = res.value;
          that.rebuildReactionText();
        },
      },
      PopupType.SlideoutWithValue
    );
  }

  private doDelete() {
    const that = this;
    const desc = this.us.eventDescriptions.find((ed) => ed.id === this.eventDescItem.id);
    if (desc === undefined) {
      return;
    }
    this.us.eventDescriptions.splice(this.us.eventDescriptions.indexOf(desc), 1);
    this.api.post('/delete/event-description', { id: this.eventDescItem.id }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.toaster.postError(result.error);
          that.revert();
        }
        that.miniStatus.hide();
        that.es.endComponentEdit(TEditableComponent.EventDescription);
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.miniStatus.hide();
        that.revert();
        that.es.endComponentEdit(TEditableComponent.EventDescription);
      }
    );
    this.router.navigate([this.backUrl]);
  }
}
