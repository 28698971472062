import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AnimationOptions } from 'ngx-lottie';
import handAnimation from 'src/lottie/notfound-hand.json';

@Component({
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
})
export class NotfoundComponent implements OnInit {
  public readonly animationOptions: AnimationOptions = {
    animationData: handAnimation,
  };
  constructor(@Inject(DOCUMENT) private document: Document) {}

  ngOnInit(): void {}
}
