<div class="grid wiring-grid">
    <div id='wiring-content-background'></div>
    <div id='wiring-header'>{{trans('systems.titles.wiringTitle')}}</div>
    <div id='wiring-description'>{{trans('systems.labels.selectPanel')}}</div>
    <div id='wiring-content'>
        <div class='wiring-selection-button'>
            <div class='wiring-selection-icon'><span class="wiring-helper"></span><img src='assets/images/dsc-logo.png' alt="DSC"/></div>
            <div class='wiring-selection-arrow' [inlineSVG]="'assets/images/forward.svg'"></div>
        </div>
        <div class='wiring-selection-button'>
            <div class='wiring-selection-icon'><span class="wiring-helper"></span><img src='assets/images/paradox-logo.png' alt="PARADOX"/></div>
            <div class='wiring-selection-arrow' [inlineSVG]="'assets/images/forward.svg'"></div>
        </div>
        <div class='wiring-selection-button'>
            <div class='wiring-selection-icon'><span class="wiring-helper"></span><img src='assets/images/interlogix-logo.png' alt="INTERLOGIX"/></div>
            <div class='wiring-selection-arrow' [inlineSVG]="'assets/images/forward.svg'"></div>
        </div>
        <div class='wiring-selection-button'>
            <div class='wiring-selection-icon'><span class="wiring-helper"></span><img src='assets/images/texecom-logo.png' alt="TEXECOM"/></div>
            <div class='wiring-selection-arrow' [inlineSVG]="'assets/images/forward.svg'"></div>
        </div>
        <div class='wiring-selection-button'>
            <div class='wiring-selection-icon'><span class="wiring-helper"></span><img src='assets/images/inner-range-logo.png' alt="Inner range"/></div>
            <div class='wiring-selection-arrow' [inlineSVG]="'assets/images/forward.svg'"></div>
        </div>
        <div class='wiring-selection-button'>
            <div class='wiring-selection-icon'><span class="wiring-helper"></span><img src='assets/images/honeywell-logo.png' alt="HONEYWELL"/></div>
            <div class='wiring-selection-arrow' [inlineSVG]="'assets/images/forward.svg'"></div>
        </div>
        <div class='wiring-selection-button'>
            <div class='wiring-selection-icon'><span class="wiring-helper"></span><img src='assets/images/crow-logo.png' alt="CROW"/></div>
            <div class='wiring-selection-arrow' [inlineSVG]="'assets/images/forward.svg'"></div>
        </div>
    </div>
</div>