import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { GoogleAuthService } from 'src/app/api/google-auth.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-google-login',
  templateUrl: './google-login.component.html',
  styleUrls: ['./google-login.component.scss'],
})
export class GoogleLoginComponent implements OnInit {
  public readonly trans = (key: string) => this.ls.get(key);
  constructor(public gauth: GoogleAuthService, private el: ElementRef<HTMLDivElement>, private ls: LanguageService) {}

  ngOnInit(): void {
    if (this.gauth.supportsOneTap) {
      this.gauth.whenReady(() => this.gauth.renderButton(this.el.nativeElement.firstChild as HTMLElement));
    }
  }
}
