import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ValueOf } from 'src/rxjs-tools';
import { AccountStore } from './auth.service';

export type LocalStorageValues = {
  privacy_consent: boolean;
  globalAccounts: AccountStore;
  token: string;
};

export type LocalStorageKeys = keyof LocalStorageValues;

@Injectable({
  providedIn: 'root',
})
export class PersistenceService {
  private observableCache = new Map<LocalStorageKeys, Observable<ValueOf<LocalStorageValues>>>();
  private localChnageSubject = new Subject<{ key: LocalStorageKeys; newValue: ValueOf<LocalStorageValues> }>();
  constructor() {}

  public set<K extends LocalStorageKeys, T extends LocalStorageValues[K]>(key: K, value: T) {
    localStorage.setItem(key, JSON.stringify(value));
    this.localChnageSubject.next({ key, newValue: value });
  }

  public get<K extends LocalStorageKeys, T extends LocalStorageValues[K], TDef extends T | undefined>(
    key: K,
    default_value: TDef = undefined
  ): TDef extends undefined ? T | null : T {
    const value = localStorage.getItem(key);
    if (value === null) {
      if (default_value !== undefined) this.set(key, default_value);
      return default_value ?? null;
    }
    return JSON.parse(value) as T;
  }

  public subscribe<K extends LocalStorageKeys, T extends LocalStorageValues[K]>(key: K): Observable<T> {
    if (this.observableCache.has(key)) return this.observableCache.get(key) as Observable<T>;
    const observable = new Observable<T>((subscriber) => {
      const handler = (event: StorageEvent) => {
        if (event.key === key) {
          if (event.newValue === null) {
            subscriber.next(null);
          } else {
            subscriber.next(JSON.parse(event.newValue) as T);
          }
        }
      };
      window.addEventListener('storage', handler);
      const localSubscription = this.localChnageSubject.subscribe((event) => {
        if (event.key === key) {
          subscriber.next(event.newValue as T);
        }
      });
      return () => {
        window.removeEventListener('storage', handler);
        localSubscription.unsubscribe();
      };
    });
    this.observableCache.set(key, observable);
    return observable;
  }
}
