import { Injectable } from '@angular/core';
import { EventEmitter } from 'protractor';
import { Subject } from 'rxjs';
import { ApiRequestService } from './api-request.service';
import { SystemsService } from './systems.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  private onRefreshSource = new Subject<any>();
  /** Vykdoma, kai vartotojas paleidžia pirštą, kai jau ištempė refresherį iš viršaus. NESUSIJĘ su silent refresh. */
  public onRefresh = this.onRefreshSource.asObservable();
  public refresherIsEnabled = false;
  private silentRefreshInProgress = false;
  private onSilentRefreshCompleteSource = new Subject<any>();
  public onSilentRefreshComplete = this.onSilentRefreshCompleteSource.asObservable();

  constructor(private api: ApiRequestService, private systems: SystemsService, private us: UserService) {}

  public doRefresh() {
    this.onRefreshSource.next();
  }

  public enableRefresher() {
    this.refresherIsEnabled = true;
  }

  public disableRefresher() {
    this.refresherIsEnabled = false;
  }

  public doSilentRefresh(refreshCompleteCallback?: any, failureCallback?: any) {
    if (!this.api.hasToken()) {
      return;
    }
    this.silentRefreshInProgress = true;
    this.api.get('/me', true, { systemId: this.systems.activeSystem === null ? 0 : this.systems.activeSystem.id }).subscribe(
      (result) => {
        if (result.success) {
          if (!this.api.hasToken()) {
            // Panašu, kad kol siuntė duomenis, vartotojas padarė logout.
            this.silentRefreshInProgress = false;
            return;
          }
          this.us.setActiveUser(result);
          this.api.setToken(result.token);
          if (result.lastSystem !== undefined) {
            this.systems.setCurrentSystemFromRaw(result.lastSystem);
          }
          if (refreshCompleteCallback !== undefined) {
            refreshCompleteCallback();
          }
          this.silentRefreshInProgress = false;
          this.onSilentRefreshCompleteSource.next();
        }
      },
      () => {
        if (failureCallback !== undefined) {
          failureCallback();
        }
        this.silentRefreshInProgress = false;
        this.onSilentRefreshCompleteSource.next();
      }
    );
  }

  public isRefreshingSilently(): boolean {
    return this.silentRefreshInProgress;
  }
}
