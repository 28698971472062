<app-form-input
  class="dropwdown-input"
  [label]="label"
  [hasError]="hasError"
  type="text"
  [(value)]="textBox"
  autocomplete="off"
  (keydown.arrowdown)="$event.preventDefault(); updateCurrentActive(1)"
  (keydown.arrowup)="$event.preventDefault(); updateCurrentActive(-1)"
  (keydown.enter)="$event.preventDefault(); selectItem(currentActive)"
  (Focus)="onFocus()"
  (click)="onFocus()"
>
  <span class="triangle"></span>
  <div *ngIf="shown" class="dropdown">
    <div class="item" *ngFor="let item of shownItems; let i = index" [ngClass]="i === currentActive ? 'active' : ''" (touchend)="selectItem(i)" (mouseup)="selectItem(i)">
      {{ collapseLabel(item.label, item.value) }}
    </div>
    <div *ngIf="!shownItems?.length" class="item"><i>No results</i></div>
  </div>
  <ng-content></ng-content>
</app-form-input>
