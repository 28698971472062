import http from '../http';
import { BasicSuccessResponse, ErrorResponse, ExErrorResponse } from './common';

type GetSensorValuesRequest<SystemId extends number> = { systemId: SystemId };
type GetSensorValuesResponse<SystemId extends number> =
  | ErrorResponse
  | (BasicSuccessResponse & {
      sensors: {
        id: number;
        name: string;
        system_id: SystemId;
        queue_no: number;
        enabled: 1 | 0;
        type: number;
        alarm_high: boolean;
        alarm_low: boolean;
        high_value: number;
        low_value: number;
        status: number;
        warn: boolean;
      }[];
    });

export const getSensorValues = <SystemId extends number>(req: GetSensorValuesRequest<SystemId>) =>
  http.post<GetSensorValuesResponse<SystemId>, GetSensorValuesRequest<SystemId>>('/v3/api/get-sensor-values', req);

type EditSensorRequest = {
  systemId: number;
  sensor: {
    name: string;
    type: number;
    queue_no: number;
    notificationAboutHigh?: boolean;
    notificationAboutLow?: boolean;
    highValue?: number;
    lowValue?: number;
  };
};
type EditSensorResponse = ErrorResponse | ExErrorResponse | BasicSuccessResponse;

export const editSensor = (req: EditSensorRequest) => http.post<EditSensorResponse, EditSensorRequest>('/v3/api/edit/sensor', req);
