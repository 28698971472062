import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { Router } from '@angular/router';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { UserRole } from 'src/app/models/user-role';
import { AppSettings } from 'src/app-settings';

@Component({
  selector: 'app-transfer',
  templateUrl: './transfer.component.html',
  styleUrls: ['./transfer.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TransferComponent extends LanguageAware implements OnInit {
  public controlsEnabled = true;
  public targetEmail = '';
  public filteredPeople = [];

  constructor(cdRef: ChangeDetectorRef, private router: Router, private es: EditSystemService) {
    super(cdRef);
    const that = this;
    let backurl = '/system-configuration';
    const supportsDeviceTransfer =
      this.systems.activeSystem.supported_commands.indexOf('.18.') !== -1 ||
      this.systems.activeSystem.supported_commands.endsWith('.18') ||
      this.systems.activeSystem.supported_commands.indexOf('.22.') !== -1 ||
      this.systems.activeSystem.supported_commands.endsWith('.22');
    if (AppSettings.deviceTransferToAppEnabled && supportsDeviceTransfer && this.us.currentUser.canTransferDevice) {
      backurl = '/transfer-type';
    }

    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.transferSystem'),
      backUrl: backurl,
    });
    this.footerBar.showFooter(this.trans('systems.buttons.transfer'), '', true, false);
    this.footerBar.onButton1Click = () => {
      that.onConfirm();
    };
    this.background.setGray();
    this.filteredPeople = this.getPeople();
    if (this.es.emailToTransferTo !== '') {
      this.targetEmail = this.es.emailToTransferTo;
      this.es.emailToTransferTo = '';
    }
  }

  ngOnInit(): void {}

  public onConfirm() {
    if (!this.controlsEnabled) {
      this.miniStatus.flash();
      return;
    }
    this.targetEmail = this.targetEmail.trim();
    if (this.targetEmail === null || this.targetEmail === '') {
      this.toaster.postError(this.trans('validation.required').replace(':attribute', this.trans('auth.form.email')));
      this.controlsEnabled = true;
      this.miniStatus.hide();
      return;
    }
    this.targetEmail = this.targetEmail.toLowerCase();
    if (!/^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.targetEmail)) {
      this.toaster.postError(this.trans('validation.emailInvalid'));
      this.controlsEnabled = true;
      this.miniStatus.hide();
      return;
    }
    const that = this;
    this.controlsEnabled = false;
    this.miniStatus.show(this.trans('systems.statuses.transfering'));
    const activeSystemId = this.systems.activeSystem.id;
    this.api
      .post(
        '/transfer',
        {
          system_id: this.systems.activeSystem.id,
          transfer_to_email: this.targetEmail,
        },
        true
      )
      .subscribe(
        (result) => {
          that.controlsEnabled = true;
          that.miniStatus.hide();
          if (result.success) {
            if (that.us.currentUser.role === UserRole.Installer && that.systems.activeSystem.installerId === that.us.currentUser.id) {
              if (that.us.currentUser.historySystems === undefined) {
                that.us.currentUser.historySystems = [];
              }
              that.us.currentUser.historySystems.push({
                name: that.systems.activeSystem.name,
                address: that.systems.activeSystem.address,
              });
            }
            that.systems.removeSystem(activeSystemId);
            if (that.systems.hasSystems()) {
              that.systems.setCurrentSystemFromInternalList(false);
            } else {
              that.systems.setCurrentSystem(null);
            }
            that.router.navigate([this.g.getHomeUrl()]);
          } else {
            that.toaster.postError(result.error);
          }
        },
        (error) => {
          that.miniStatus.hide();
          that.controlsEnabled = true;
        }
      );
  }

  private getPeople(filter?: string): any[] {
    if (filter === undefined || filter === '') {
      return [];
    }

    return this.systems.activeSystem.device_users.filter(
      (u) => u.protegus_user_id !== 0 && u.email !== this.us.currentUser.email && (u.email.indexOf(filter) !== -1 || u.name.indexOf(filter) !== -1)
    );
  }

  public doFilter(value: string) {
    this.targetEmail = value;
    this.filteredPeople = this.getPeople(value);
  }

  public selectPerson(email: string) {
    this.targetEmail = email;
  }
}
