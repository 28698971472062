import { Injectable } from '@angular/core';
import { TPlusButtonData } from '../models/plus-button-data';
import { LoggerService } from '../api/logger.service';
import { AppSettings } from 'src/app-settings';
import { PlatformService } from '../api/platform.service';
import { RegionService } from '../api/region.service';

@Injectable({
  providedIn: 'root',
})
export class HelperService {
  private buttonsData: TPlusButtonData[] = [];
  public progressHeight = 0;
  public headerHeight = 0;
  public footerHeight = 0;

  constructor(private l: LoggerService, private ps: PlatformService, private region: RegionService) {}

  public plusIsAtBottomNow(forWindow: string): boolean {
    const found = this.buttonsData.find((b) => b.windowName === forWindow);
    if (found === undefined) {
      return false;
    }
    return found.buttonIsAtBottom;
  }

  public fakeElementIsVisibleNow(forWindow: string) {
    const found = this.buttonsData.find((b) => b.windowName === forWindow);
    if (found === undefined) {
      return false;
    }
    return found.fakeElementIsVisible;
  }

  public shouldShowAddButtonAtBottom(dataCount: number, forWindow: string) {
    const found = this.buttonsData.find((b) => b.windowName === forWindow);
    if (found === undefined) {
      return;
    }
    if (found.countForShowingAtBottom <= dataCount) {
      found.buttonIsAtBottom = true;
    } else {
      found.buttonIsAtBottom = false;
    }
  }

  public setContentRowHeight(countProgressBar: boolean, countHeader: boolean, countFooter: boolean, forWindow: string) {
    const progressHeight = getComputedStyle(document.documentElement).getPropertyValue('--height-progress').replace('px', '');
    const headerHeight = getComputedStyle(document.documentElement).getPropertyValue('--height-header').replace('px', '');
    const footerHeight = getComputedStyle(document.documentElement).getPropertyValue('--height-footer').replace('px', '');
    this.progressHeight = parseInt(progressHeight, 10);
    this.headerHeight = parseInt(headerHeight, 10);
    this.footerHeight = parseInt(footerHeight, 10);
    let deduceHeight = 0;
    if (countProgressBar) {
      deduceHeight += this.progressHeight;
    }
    if (countHeader) {
      deduceHeight += this.headerHeight;
    }
    if (countFooter) {
      deduceHeight += this.footerHeight;
    }
    let found: TPlusButtonData = this.buttonsData.find((b) => b.windowName === forWindow);
    if (found === undefined) {
      found = {
        windowName: forWindow,
        buttonIsAtBottom: false,
        contentRowHeight: 0,
        countForShowingAtBottom: 99,
        fakeElementIsVisible: false,
      };
      this.buttonsData.push(found);
    }
    const mc = document.getElementById('master-container');
    found.contentRowHeight = mc.getBoundingClientRect().height - deduceHeight;
  }

  public setCountForBottomButton(
    dataCount: number,
    plusTop: number,
    backgroundHeight: number,
    countProgress: boolean,
    countHeader: boolean,
    countFooter: boolean,
    forWindow: string
  ) {
    let found: TPlusButtonData = this.buttonsData.find((b) => b.windowName === forWindow);
    if (found === undefined) {
      found = {
        windowName: forWindow,
        buttonIsAtBottom: false,
        contentRowHeight: 0,
        countForShowingAtBottom: 9999,
        fakeElementIsVisible: false,
      };
      this.buttonsData.push(found);
    }
    let totalElementHeight = backgroundHeight;
    if (countProgress) {
      totalElementHeight += this.progressHeight;
    }
    if (countHeader) {
      totalElementHeight += this.headerHeight;
    }
    if (countFooter) {
      totalElementHeight += this.footerHeight;
    }
    if (totalElementHeight + 10 >= plusTop) {
      this.l.log('pereinam prie bottom view.', 'Helper');
      found.countForShowingAtBottom = dataCount;
      setTimeout(() => {
        found.buttonIsAtBottom = true;
      }, 100);
    }
  }

  public shouldShowFakeElement(listElementHeight: number, forWindow: string) {
    const found = this.buttonsData.find((b) => b.windowName === forWindow);
    if (found === undefined) {
      return;
    }
    if (found.contentRowHeight < listElementHeight) {
      setTimeout(() => {
        found.fakeElementIsVisible = true;
      }, 100);
    }
  }

  public getEventIconByPurpose(purpose: number) {
    if (purpose === 3) {
      return 'assets/images/arm.svg';
    } else if (purpose === 4) {
      return 'assets/images/disarm.svg';
    }

    return '';
  }

  public getContentRowHeight(forWindow: string): number {
    const found = this.buttonsData.find((b) => b.windowName === forWindow);
    if (found === undefined) {
      return 0;
    }
    return found.contentRowHeight;
  }

  public getBaseNoPath() {
    return this.region.regionBaseUrl;
  }
}
