import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-name-value',
  templateUrl: './name-value.component.html',
  styleUrls: ['./name-value.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NameValueComponent implements OnInit {
  @Input() nameText = '';
  @Input() showUnderline = true;
  @Input() isEnabled = true;
  @Input() icon = '';
  @Output() clicked: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  public doClick() {
    if (this.isEnabled) {
      this.clicked.emit();
    }
  }
}
