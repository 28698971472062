import { Injectable, OnDestroy } from '@angular/core';
import { SystemThermostatData } from 'src/api/v3/common';
import { TThermostatData } from 'src/app/models/thermostat-data';
import { AuthService } from '../auth.service';

@Injectable({
  providedIn: 'root',
})
export class ThermostatService implements OnDestroy {
  public readonly thermostats = new Map<number, TThermostatData>();
  public readonly systemThermostats = new Map<number, Set<number>>();

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.thermostats.clear();
    this.systemThermostats.clear();
  });
  constructor(private auth: AuthService) {}
  ngOnDestroy(): void {
    this.cleanupSubscribtion.unsubscribe();
  }

  ingestThermostat(thermostat?: SystemThermostatData): TThermostatData | undefined {
    if (!thermostat) return;
    const { system_id: systemId, queue_no: queueNo, temperatures, assigned_sensors, assigned_output: assignedOutput, active_sensor: activeSensor, ...rest } = thermostat;

    const processedThermostat: TThermostatData = {
      activeSensor,
      assignedOutput,
      assignedSensors: JSON.parse(assigned_sensors) ?? [],
      queueNo,
      systemId,
      temperatures: JSON.parse(temperatures) ?? [],
      ...rest,
    };

    this.thermostats.set(thermostat.id, processedThermostat);
    if (!this.systemThermostats.has(systemId)) {
      this.systemThermostats.set(systemId, new Set());
    }
    this.systemThermostats.get(systemId)?.add(thermostat.id);
    return processedThermostat;
  }
}
