<div class="">
    <div id='cm-content' class="white_round padding_sides16">
        <form>
            <app-text-edit id='cm_name' [value]='cameraObject.name' (valueChanged)='cameraObject.name = $event'>{{trans('systems.labels.cameraName')}}</app-text-edit>
            <div id='cm_settings'>{{trans('systems.labels.connectionSettings')}}</div>
            <div id='cm_desc'>{{trans('systems.labels.cameraInfo')}}</div>
            <div id='cm_url'>
                <div id='cm_rtsp_header'>{{trans('systems.labels.rtspLink')}}</div>
                <div id='cm_rtsp'>rtsp://</div>
                <app-text-edit id='cm_host' [value]='cameraObject.host' (valueChanged)='handleInput($event, "host")'>{{trans('systems.labels.cameraUrl')}}</app-text-edit>
                <div id='cm_colon'><div>:</div></div>
                <app-text-edit id='cm_port' [value]='cameraObject.port' [maxCharacters]="5" (valueChanged)='handleInput($event, "port")'>{{trans('systems.labels.cameraPort')}}</app-text-edit>
            </div>
            <app-text-edit id='cm_username' [value]='cameraObject.user' (valueChanged)='handleInput($event, "user")'>{{trans('systems.labels.cameraUser')}}</app-text-edit>
            <app-password-edit id='cm_pass' [value]='cameraObject.pass' (valueChanged)='handleInput($event, "pass")'>{{trans('systems.labels.cameraPass')}}</app-password-edit>
            <app-text-edit id='cm_path' [value]='cameraObject.path' (valueChanged)='handleInput($event, "path")'>{{trans('systems.labels.cameraStreamPath')}}</app-text-edit>
        </form>
    </div>
    <div class="separator32"></div>
    <app-groupbox [headerText]="trans('systems.labels.addButtonToCamera')">
        <div class="separator8"></div>
        <div class="padding_sides16">{{trans('systems.labels.addButtonToCameraDesc')}}</div>
        <div class="separator8"></div>
        <div class="padding_sides16">
            <div *ngFor='let pgm of pgmList; let i = index'>
                <app-name-value [nameText]="pgm.name" [icon]="is.getPgmIcon(pgm)" [showUnderline]="i < pgmList.length - 1" [isEnabled]="!pgmLimitReached || isPgmAssigned(pgm.queue_no)">
                    <app-toggle-button [buttonId]="'cm_pgm_' + pgm.id" [initialState]="isPgmAssigned(pgm.queue_no)" (toggled)="onPgmSelect(pgm.queue_no, $event.checked)"></app-toggle-button>
                </app-name-value>
            </div>
        </div>
    </app-groupbox>
    <div class="separator32"></div>
</div>