<div class="grid system-area-list-grid">
    <div id='sal-area-back' class="group-background"></div>
    <div id='sal-area-list'>
        <div class="list-item-v1" *ngFor='let area of getAreas()' (click)='onAreaSelect(area.id)'>
            <div [inlineSVG]="'assets/images/area-general-icon.svg'"></div>
            <div >{{area.queue_no}}</div>
            <div>
                <div>{{area.name}}</div>
                <div *ngIf='systems.activeSystemHelper.supports.areaControlByPgm()'>{{getAssignedPgmName(area.queue_no)}}&nbsp;</div>
            </div>
            <div *ngIf='systems.activeSystemHelper.can.delete.areas()' [inlineSVG]="'assets/images/delete.svg'" (click)='onAreaDelete($event, area.id)'></div>
        </div>
    </div>
</div>
<app-plus-button *ngIf='systems.activeSystemHelper.can.delete.areas() && !systems.activeSystem.directControl' (plusClicked)="onAddArea()"></app-plus-button>