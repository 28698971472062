import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

/**
 * Passsword Backward Compatibility Reset Guard, just does advanced redirection.
 */
@Injectable({
  providedIn: 'root',
})
export class LoginBwcResetGuard implements CanActivate {
  constructor(private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const hash = route.paramMap.get('hash');
    return this.router.parseUrl(`/login/password-reset/${hash}`);
  }
}
