import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { NewSystemService } from 'src/app/services/new-system.service';

@Component({
  selector: 'app-customize-areas',
  templateUrl: './customize-areas.component.html',
  styleUrls: ['./customize-areas.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomizeAreasComponent extends LanguageAware implements OnInit {
  public maxAreaLen = 100;

  constructor(cdRef: ChangeDetectorRef, private router: Router, public ns: NewSystemService) {
    super(cdRef);
    this.tag = 'CustomArea';
    this.headerBar.showHeader({
      headerText: this.trans('systems.labels.nameTheAreas'),
      backUrl: '/add-system-complete',
    });
    this.footerBar.showFooter(this.trans('general.skip'), this.trans('general.save'), false, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.onSkip();
    };
    this.footerBar.onButton2Click = () => {
      that.onNext();
    };
    this.background.setGray();
    if (this.systems.activeSystem.zones.length !== 0) {
      this.progressBar.showProgress();
    }
    if (this.systems.activeSystem.hwType === 'SP3') {
      this.maxAreaLen = 23;
    }
  }

  ngOnInit(): void {
    this.progressBar.setProgressData(1, 2, 2);
  }

  private onSkip() {
    this.router.navigate([this.g.getHomeUrl()]);
  }

  private onNext() {
    if (this.systems.activeSystem.zones.length === 0) {
      this.router.navigate([this.g.getHomeUrl()]);
    } else {
      this.router.navigate(['/customize-zones']);
    }
  }

  public submitNewName(areaId: number, areaName: string) {
    if (areaName === this.systems.activeSystem.areas.find((a) => a.id === areaId).name) {
      return;
    }
    if (areaName === '') {
      this.toaster.postError(this.trans('systems.errors.noAreaName'));
      return;
    }

    const that = this;
    const systemId = this.systems.activeSystem.id;
    const oldName = this.systems.activeSystem.areas.find((a) => a.id === areaId).name;
    this.api.post('/change-area-name', { system_id: systemId, area_id: areaId, area_name: areaName }, true).subscribe(
      (result) => {
        if (!result.success) {
          that.toaster.postError(result.error);
          that.revertNameChange(systemId, areaId, oldName);
          return;
        }
        that.saveName(systemId, areaId, areaName);
        that.log('gut');
      },
      (error) => {
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
        that.revertNameChange(systemId, areaId, oldName);
      }
    );
  }

  private revertNameChange(systemId: number, areaId: number, oldName: string) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const area = system.areas.find((a) => a.id === areaId);
    if (area === undefined) {
      return;
    }
    area.name = oldName;
    this.systems.saveActiveSystem();
  }

  private saveName(systemId: number, areaId: number, newName: string) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const area = system.areas.find((a) => a.id === areaId);
    if (area === undefined) {
      return;
    }
    area.name = newName;
  }
}
