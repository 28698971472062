import { Component, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-status-message',
  templateUrl: './status-message.component.html',
  styleUrls: ['./status-message.component.scss'],
})
export class StatusMessageComponent implements OnInit {
  @ViewChild('basePopup') basePopup;
  @ViewChild('loaderComponent') loaderComponent;
  public isSuccess = true;
  public headerText = '';
  public statusText = '';

  constructor() {}

  ngOnInit(): void {}

  public showLoader(textToShow: string) {
    this.loaderComponent.displayText = textToShow;
    this.loaderComponent.isVisible = true;
    this.basePopup.show();
  }

  public showSuccess(headerText: string, statusText: string) {
    this.headerText = headerText;
    this.statusText = statusText;
    this.loaderComponent.isVisible = false;
    this.isSuccess = true;
    this.basePopup.show();
  }

  public showFailure(headerText: string, statusText: string) {
    this.headerText = headerText;
    this.statusText = statusText;
    this.loaderComponent.isVisible = false;
    this.isSuccess = false;
    this.basePopup.show();
  }

  public close() {
    this.basePopup.hidePopup();
  }
}
