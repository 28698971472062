import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { UserRole } from 'src/app/models/user-role';
import { EditSystemService } from 'src/app/services/edit-system.service';

@Component({
  selector: 'app-cp-edit-reactions',
  templateUrl: './cp-edit-reactions.component.html',
  styleUrls: ['./cp-edit-reactions.component.scss'],
})
export class CpEditReactionsComponent extends LanguageAware implements OnInit {
  constructor(cdRef: ChangeDetectorRef, private router: Router, private es: EditSystemService) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.menu.reactions'),
      backUrl: this.g.getHomeUrl(),
      showPlus: true,
    });
    this.background.setGray();
    const that = this;
    this.headerBar.onActionButtonClicked = () => {
      that.es.beginComponentEdit(TEditableComponent.Reaction, 0, {
        id: 0,
        monas_id: 0,
        name: '',
        active: false,
        custom_sound: 0,
        company_id: that.us.currentUser.role === UserRole.Company ? that.us.currentUser.id : 0,
        group_id: 0,
        image: '',
        image_png: '',
        default_name: that.us.currentUser.role === UserRole.Company ? false : true,
        redirect_to: 0,
      });
      that.router.navigate([this.g.getHomeUrl() + '/edit-reaction']);
    };
  }

  ngOnInit(): void {}

  public editReaction(reaction: any) {
    this.es.beginComponentEdit(TEditableComponent.Reaction, reaction.id, reaction);
    this.router.navigate([this.g.getHomeUrl() + '/edit-reaction']);
  }
}
