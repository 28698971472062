import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ApiRequestService } from 'src/app/services/api-request.service';
import { GlobalService } from 'src/app/services/global.service';
import { LanguageService } from 'src/app/services/language.service';
import { MiniStatusService } from 'src/app/services/mini-status.service';
import { ToasterService } from 'src/app/services/toaster.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-user-find-popup',
  templateUrl: './user-find-popup.component.html',
  styleUrls: ['./user-find-popup.component.scss'],
})
export class UserFindPopupComponent implements OnInit {
  @ViewChild('basePopup') basePopup;
  @ViewChild('phraseEdit') phraseEdit;
  public userToFind = '';

  constructor(
    public lang: LanguageService,
    private rs: ApiRequestService,
    private ms: MiniStatusService,
    private t: ToasterService,
    private router: Router,
    private g: GlobalService,
    private us: UserService
  ) {}

  ngOnInit(): void {}

  public confirmSearch() {
    this.basePopup.hidePopup();
    this.ms.show(this.lang.get('general.pleaseWait'));
    this.router.navigate([this.g.getHomeUrl() + '/filtered-users']);
    const that = this;
    this.rs
      .post(
        '/filter-users',
        {
          active: 'on',
          inactive: 'on',
          invited: 'on',
          role: -1,
          sort: 0,
          search: this.userToFind,
        },
        true
      )
      .subscribe(
        (result) => {
          that.ms.hide();
          if (!result.success) {
            that.t.postError(result.error);
          } else {
            that.us.filteredUsers = [];
            for (const iUser of result.list.data) {
              that.us.filteredUsers.push(iUser);
            }
          }
        },
        (error) => {
          that.t.postError(that.lang.get('auth.errors.serverSideError'));
          that.ms.hide();
        }
      );
  }

  public show() {
    this.basePopup.show();
    this.phraseEdit.focusEdit();
  }
}
