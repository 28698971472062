import { Component, OnInit, ChangeDetectorRef, ViewEncapsulation, NgZone, ViewChild } from '@angular/core';
import { LanguageAware } from '../language-aware';
import { Router } from '@angular/router';
import { NewSystemService } from 'src/app/services/new-system.service';
import { MessageboxComponent } from 'src/app/popups/messagebox/messagebox.component';
import { PopupService } from 'src/app/services/popup.service';
import { PopupType } from 'src/app/models/popup-type';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-add-system-uid',
  templateUrl: './add-system-uid.component.html',
  styleUrls: ['./add-system-uid.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddSystemUidComponent extends LanguageAware implements OnInit {
  @ViewChild('messageBox') messageBox: MessageboxComponent;
  public systemUid = '';
  public errorMessageForUnsupportedDevice = '';

  constructor(cdRef: ChangeDetectorRef, private router: Router, private zone: NgZone, private ns: NewSystemService, private pp: PopupService, private ds: DeviceService) {
    super(cdRef);
    this.tag = 'UID';
    this.progressBar.showProgress();
    this.headerBar.showHeader({
      headerText: this.platform.isDesktop() ? this.trans('systems.labels.imei') : this.trans('systems.labels.scanQr'),
      backUrl: this.g.getHomeUrl(),
    });
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('auth.buttons.next'), true, false);
    this.background.setGray();
    this.footerBar.onButton1Click = () => {
      this.cancelClick();
    };
    this.footerBar.onButton2Click = () => {
      this.nextClick();
    };
    this.headerBar.onBackClicked = () => {
      this.ns.setUID('');
    };
    window.qrJsi = {
      onQrCodeScanned: (code) => {
        this.zone.run(() => {
          this.handleQrCode(code);
        });
      },
    };
    this.systemUid = this.ns.getUID();
  }

  ngOnInit() {
    this.progressBar.setProgressData(1, 7);
  }

  private cancelClick() {
    this.toaster.clear();
    this.ns.setUID('');
    this.router.navigate([this.g.getHomeUrl()]);
  }

  public nextClick() {
    this.toaster.clear();
    this.systemUid = this.systemUid.trim().toUpperCase();
    this.systemUid = this.systemUid.replace(/^0+/, '');
    if (this.systemUid === '') {
      this.toaster.postError(this.trans('systems.errors.imeiIsRequired'));
      return;
    }
    if (this.systemUid.length < 13 && !/[0-9A-F]{1,12}/.test(this.systemUid)) {
      this.toaster.postError(this.trans('validation.imeiInvalid'));
      return;
    }
    if (this.systemUid.length > 12 && !/[0-9]{15,16}/.test(this.systemUid)) {
      this.toaster.postError(this.trans('validation.imeiInvalid'));
      return;
    }
    if (this.systemUid.length < 12) {
      this.systemUid = this.systemUid.padStart(12, '0');
    }
    this.log('Įvestas UID: ' + this.systemUid);
    this.ns.setUID(this.systemUid);

    this.findDevice(false);
  }

  private findDevice(checkAll: boolean) {
    const that = this;
    this.miniStatus.show(this.trans('general.checking'));
    this.api.get('/online?checkAll=' + (checkAll ? 'true' : 'false') + '&imei=' + this.systemUid + '&mpass=' + this.ns.getMpass(), true).subscribe(
      (result) => {
        if (result.success && result.online) {
          that.checkIfInForeign(result.srv);
        } else if (result.success && !checkAll) {
          that.findDevice(true);
        } else if (!result.success) {
          that.miniStatus.hide();
          that.toaster.postError(result.error);
        } else if (result.errorType !== undefined && result.errorType === 'MPASS_IS_INCORRECT') {
          that.pp.showPopup(
            {
              headerText: that.trans('systems.labels.mpass'),
              field1: {
                maxLen: 6,
                oldValue: '',
              },
              onSubmit: (res) => {
                that.ns.setMpass(res.value1);
                that.nextClick();
              },
              onCancel: () => {
                that.miniStatus.hide();
              },
            },
            PopupType.Number
          );
        } else if (result.hwId && result.hwId > 0) {
          if (result.hwId === 0x37) {
            this.ds.setHwId(result.hwId);
            this.router.navigate(['/scan-nearby-devices']);
          } else if (result.hwId === 0x3b && result.revision && this.ds.revisionStartsWith(result.revision, ['1', '2', '3E', '52'])) {
            this.ds.setHwId(result.hwId);
            this.router.navigate(['/scan-nearby-devices']);
          } else {
            that.miniStatus.hide();
            that.router.navigate(['/new-system-offline']);
          }
        } else {
          that.miniStatus.hide();
          that.router.navigate(['/new-system-offline']);
        }
      },
      (error) => {
        that.miniStatus.hide();
      }
    );
  }

  public qrScanClick() {
    if (this.platform.isAndroid()) {
      this.platform.androidHandler().showQr();
    } else if (this.platform.isApple()) {
      this.platform.appleHandler().showQr.postMessage('');
    } else {
      this.log('neegzistuoja jsi');
    }
  }

  private handleQrCode(code: string) {
    this.log('We have received QR code ' + code);
    const codeLen = code.length;
    if (code !== this.systemUid && (codeLen === 12 || codeLen === 15 || codeLen === 16)) {
      this.systemUid = code;
      this.log('imei value is: ' + this.systemUid);
      this.refreshWindow();
      if (this.platform.isAndroid()) {
        this.platform.androidHandler().onQrAccepted();
      } else if (this.platform.isApple()) {
        this.platform.appleHandler().onQrAccepted.postMessage('');
      }
    }
  }

  private checkIfInForeign(ipcom: number | undefined) {
    if (ipcom !== undefined) {
      this.ns.setIpcom(ipcom);
    }
    this.api
      .post(
        '/get-system-info-new',
        {
          systemUid: this.ns.getUID(),
          mpass: this.ns.getMpass() === '' ? '123456' : this.ns.getMpass(),
          srv: ipcom === undefined ? 0 : ipcom,
        },
        true
      )
      .subscribe(
        (result) => {
          if (result.success) {
            this.miniStatus.hide();
            if (result.data.isInForeignRegion) {
              this.log('Modulis kitame regione. Tikrinam ar galima perardersuot.');
              const foreignRegionName = result.data.foreignRegion;
              if (
                result.data.supported_commands.indexOf('.18.') === -1 &&
                !result.data.supported_commands.endsWith('.18') &&
                result.data.supported_commands.indexOf('.22.') === -1 &&
                !result.data.supported_commands.endsWith('.22')
              ) {
                this.log('Modulis nepalaiko reikiamos komandos');
                this.miniStatus.hide();
                this.errorMessageForUnsupportedDevice = this.trans('systems.errors.devRegUnsupported').replace(':region', foreignRegionName);
                this.messageBox.show();
              } else {
                const hwIdInt = parseInt(result.data.hwId, 16);
                // if ( ![0x3B, 0x24, 0x37, ].includes(hwIdInt) ) {
                //   this.log('Modulis palaiko komandą bet šiuo metu nėra palaikomas.');
                //   this.errorMessageForUnsupportedDevice = this.trans('systems.errors.devTempUnsupported');
                //   this.messageBox.show();
                // } else
                if (result.foreignSystem !== null && result.ownRegion !== null && result.foreignSystem.length > 0) {
                  this.log('Modulis palaiko komandą, bet sename regione rasta sistema.');
                  this.ns.foreignSystem = result.foreignSystem[0];
                  this.ns.foreignRegionName = foreignRegionName;
                  this.ns.ownRegion = result.ownRegion;
                  this.router.navigate(['/confirm-device-transfer']);
                } else {
                  this.log('Modulis palaiko komandą. Einam toliau.');
                  this.router.navigate(['/add-system-details']);
                }
              }
            } else {
              this.log('Modulis musu regione, einam toliau');
              this.router.navigate(['/add-system-details']);
            }
          } else {
            this.miniStatus.hide();
            this.toaster.postError(result.error);
          }
        },
        (error) => {
          this.miniStatus.hide();
        }
      );
  }
}
