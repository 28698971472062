import http from '../http';
import { BaseUserData, BasicSuccessResponse, ErrorResponse, UserData, UserRole, WithLastSystem } from './common';

type NameCreateRequest = { name: string };
type NameCreateResponse = ErrorResponse | BasicSuccessResponse;

export const nameCreate = (name: NameCreateRequest['name']) =>
  http.post<NameCreateResponse, NameCreateRequest>('/v3/api/name-create', {
    name,
  });

type MeRequest = { systemId?: number };
type MeResponse = ErrorResponse | (BasicSuccessResponse & UserData & WithLastSystem);

export const me = (systemId?: MeRequest['systemId']) => http.post<MeResponse, MeRequest>('/v3/api/me', { systemId });

type UpdateProfileRequest = {
  name?: string;
  phone?: string;
  dateFormat?: number;
  timeFormat?: string;
  company?: string; // Company name
  country?: string;
};

type UpdateProfileResponse = BasicSuccessResponse;

export const updateProfile = (req: UpdateProfileRequest) => http.post<UpdateProfileResponse, UpdateProfileRequest>('/v3/api/profile', req);

type ChangePasswordRequest = {
  newPassword: string;
  repeatPassword: string; // Duplicate of newPassword
};

type ChangePasswordResponse =
  | ErrorResponse
  | (ErrorResponse & { errorType: 'newPassword' })
  | (BasicSuccessResponse & {
      message: string;
    });
export const changePassword = (req: ChangePasswordRequest) => http.post<ChangePasswordResponse, ChangePasswordRequest>('/v3/api/change-password', req);

type DeleteAccountRequest = { sum: true; acknowledged: true };
type DeleteAccountResponse = ErrorResponse | (BasicSuccessResponse & { message: string });

export const deleteAccount = () =>
  http.delete<DeleteAccountResponse, DeleteAccountRequest>('/v3/api/account', {
    sum: true,
    acknowledged: true,
  });

type DownloadAccountResponse = BasicSuccessResponse;

export const downloadAccount = () => http.post<DownloadAccountResponse>('/v3/api/download-account', undefined);

type DownloadAccountFileRequest = {
  u: string; // Token
  an?: true;
};

export const downloadAccountFile = (req: DownloadAccountFileRequest) =>
  http.get<Blob, URLSearchParams, 'blob'>(
    '/v3/api/download-account-file',
    new URLSearchParams({
      u: req.u,
      an: req.an === undefined ? undefined : req.an.toString(),
    } as Record<string, string>),
    { returntype: 'blob' }
  );

type GetAllUsersRequest = {
  all?: boolean;
};
export type AllUsersUserData = Pick<BaseUserData, 'id' | 'name' | 'active' | 'email' | 'role' | 'country'>;
type GetAllUsersResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      users: AllUsersUserData[];
    });

export const getAllUsers = (req: GetAllUsersRequest) => http.post<GetAllUsersResponse, GetAllUsersRequest>('/v3/api/all-users', req);

type EditUserRequest<TId extends number = 0, TChangePassword extends 'on' | '' | undefined = undefined> = {
  id: TId;
  name: string;
  email: string;
  country: string;
  role: UserRole;
  active: number;
  phone: string;
} & (TId extends 0 ? { password: string } : TChangePassword extends 'on' ? { changePassword: TChangePassword; newPass: string } : { changePassword: TChangePassword });
type EditUserResponse = ErrorResponse | (BasicSuccessResponse & ({ info: string } | { newId: number }));

export const editUser = <TId extends number = 0, TChangePassword extends 'on' | '' | undefined = undefined>(req: EditUserRequest<TId, TChangePassword>) =>
  http.post<EditUserResponse, EditUserRequest<TId, TChangePassword>>('/v3/api/edit/cuser', req);
type DeleteUserRequest = { user_id: number; replace?: number };
type DeleteUserResponse = ErrorResponse | BasicSuccessResponse;

export const deleteUser = (req: DeleteUserRequest) => http.delete<DeleteUserResponse, DeleteUserRequest>('/v3/api/cuser', req);

type FilterUsersRequest = {
  active: 'on' | 'off';
  inactive: 'on' | 'off';
  invited: 'on' | 'off';
  role: UserRole | -1;
  search: string;
  sort: 0 | 1 | 2 | 3;
};
type FilterUsersResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      list: {
        total: number;
        per_page: number;
        current_page: number;
        last_page: number;
        next_page_url: string | null;
        prev_page_url: string | null;
        from: number;
        to: number;
        data: {
          id: number;
          name: string;
          email: string;
          role: UserRole;
          active: 0 | 1;
          created_at: string;
          phone_number: string | null;
          company: null | string;
          country: string | null;
          dashboard_order: string;
          privacy_policy_accepted: boolean;
          soc_account: boolean;
          social_id: string;
          soc_type: string;
          company_id: number | 0;
        }[];
      };
    });

export const filterUsers = (req: FilterUsersRequest) => http.post<FilterUsersResponse, FilterUsersRequest>('/v3/api/filter-users', req);

type SetUserAccessRequest = {
  system_id: number;
  present: boolean;
} & ({ protegus_user_id: number } | { user_id: number });
type SetUserAccessResponse = ErrorResponse | BasicSuccessResponse;

export const setUserAccess = (req: SetUserAccessRequest) => http.post<SetUserAccessResponse, SetUserAccessRequest>('/v3/api/set-user-access', req);

type SetOwnerPrivacyRequest = {
  system_id: number;
  user_id: number;
  privacy_settings: {
    show_email_admins: boolean;
    show_name_admins: boolean;
    show_phone_admins: boolean;
    show_email_users: boolean;
    show_name_users: boolean;
    show_phone_users: boolean;
  };
};
type SetOwnerPrivacyResponse = ErrorResponse | BasicSuccessResponse;

export const setOwnerPrivacy = (req: SetOwnerPrivacyRequest) => http.post<SetOwnerPrivacyResponse, SetOwnerPrivacyRequest>('/v3/api/owner-privacy', req);
