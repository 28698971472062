<div [ngClass]="{ 'sidemenu-grid': true, 'sidemenu-grid-dragging': isDragging }" *ngIf="headerService.sideMenuOpen" [style.transform]="'translateX(' + menuPosition + '%)'">
  <div id="sidemenu-header-row">
    <div id="sidemenu-header-logo-container">
      <div
        class="shl {{ [1, 2].includes(us.currentUser.role) ? 'shl_u' : '' }}"
        *ngIf="!us.currentUser.logo && (systems.activeSystem === null || systems.activeSystem.logo === '')"
        [inlineSVG]="'assets/images/logo_emblem_text.svg'"
      ></div>
      <div class="shl {{ [1, 2].includes(us.currentUser.role) ? 'shl_u' : '' }}" *ngIf="us.currentUser.logo"><img [src]="us.currentUser.logo" /></div>
      <div class="shl shl_u" *ngIf="us.currentUser.role === 1 && systems.activeSystem !== null && systems.activeSystem.logo !== ''"><img [src]="systems.activeSystem.logo" /></div>
      <div id="sm_header_buttons">
        <div
          id="sidemenu-header-profile"
          *ngIf="isLoggedIn() && [1, 2].includes(us.currentUser.role)"
          [inlineSVG]="'assets/images/profile.svg'"
          (click)="toggleProfilePart()"
        ></div>
        <div
          id="sidemenu-header-profile-arrow"
          [class.active]="isInProfile"
          *ngIf="isLoggedIn() && [1, 2].includes(us.currentUser.role)"
          [inlineSVG]="'assets/images/forward.svg'"
          (click)="toggleProfilePart()"
        ></div>
      </div>
      <div id="sm_company_header_buttons" *ngIf="isLoggedIn() && [0, 3].includes(us.currentUser.role)">
        <div [inlineSVG]="'assets/images/all_events.svg'" (click)="allEventsClicked()"></div>
        <div [inlineSVG]="'assets/images/profile.svg'" (click)="toggleProfilePart()"></div>
        <div [inlineSVG]="'assets/images/all_users.svg'" (click)="allUsersClicked()"></div>
      </div>
      <div id="sidemenu-header-separator"></div>
    </div>
  </div>
  <div id="sidemenu-content-row" class="sidemenu-profile-section" *ngIf="isInProfile && isLoggedIn()">
    <app-button-single-line-arrow class="sidemenu-button-item" [icon]="'assets/images/profile.svg'" (click)="profileClick()">{{
      trans('users.buttons.settings')
    }}</app-button-single-line-arrow>
    <span class="accountsw-Title">{{ trans('users.labels.changeAccount') }}</span>
    <app-account-switcher (selected)="hide()"></app-account-switcher>
    <div class="add-account" (click)="addNewAccount()">
      <div class="plus-icon" [inlineSVG]="'assets/images/plus-circle.svg'"></div>
      {{ trans('users.buttons.addAccount') }}
      <div class="arrow-icon" [inlineSVG]="'assets/images/forward.svg'"></div>
    </div>
    <div id="sidemenu-login-row">
      <div class="button" id="pr-logout" (click)="doLogout()">{{ trans('systems.buttons.logout') }}</div>
    </div>
  </div>
  <div id="sidemenu-content-row" *ngIf="!isInProfile && isLoggedIn()">
    <div *ngIf="us.currentUser.role !== 1 && systems.activeSystem !== null">
      <div id="sidemenu_exit_system" (click)="goBackToCompany()">
        <div id="smes_text">{{ ls.get('systems.labels.exitSystem') }}</div>
        <div id="smes_icon" [inlineSVG]="'assets/images/exit.svg'"></div>
      </div>
      <div id="smas_container">
        <app-cp-system-list-item
          [showInstaller]="us.currentUser.role !== 2"
          [showAddress]="true"
          [showBorder]="false"
          [showArrow]="false"
          [systemItem]="systems.activeSystem"
        ></app-cp-system-list-item>
      </div>
    </div>
    <div *ngIf="systems.activeSystem !== null && us.currentUser.role === 1" class="sidemenu-system-item" (click)="toggleSystemListView()">
      <div
        *ngIf="systems.activeSystem.theme.fullBackground === ''"
        class="ssi-icon"
        [ngStyle]="{ background: 'linear-gradient(180deg, ' + systems.activeSystem.theme.startColor + ' 0%, ' + systems.activeSystem.theme.endColor + ' 100%)' }"
      >
        <div
          *ngIf="systems.activeSystem.areas.length === 1 && systems.activeSystemHelper.supports.areas()"
          [ngClass]="{ 'ssi-subicon': true, 'ssi-subicon-disarmed': isSystemDisarmed(systems.activeSystem), 'ssi-subicon-alarmed': isSystemAlarmed(systems.activeSystem) }"
        >
          <div [inlineSVG]="getStatusIconForSystem(systems.activeSystem)"></div>
        </div>
      </div>
      <div *ngIf="systems.activeSystem.theme.fullBackground !== ''" class="ssi-icon" [ngStyle]="{ background: systems.activeSystem.theme.fullBackground }">
        <div
          *ngIf="systems.activeSystem.areas.length === 1 && systems.activeSystemHelper.supports.areas()"
          [ngClass]="{ 'ssi-subicon': true, 'ssi-subicon-disarmed': isSystemDisarmed(systems.activeSystem), 'ssi-subicon-alarmed': isSystemAlarmed(systems.activeSystem) }"
        >
          <div [inlineSVG]="getStatusIconForSystem(systems.activeSystem)"></div>
        </div>
      </div>
      <div class="ssi-name">
        <div>{{ systems.activeSystem.name }}</div>
        <div [ngClass]="{ 'ssi-arrow': true, 'ssi-arrow-toggled': systemListShowing }" [inlineSVG]="'assets/images/arrow-down.svg'"></div>
      </div>
    </div>
    <div *ngIf="systemListShowing && us.currentUser.role !== 0" id="sidemenu-system-select">
      <app-system-list-item *ngFor="let iSystem of systems.systems" [systemItem]="iSystem" (click)="systemClicked(iSystem.id)"></app-system-list-item>
      <div class="sidemenu-system-item" (click)="addSystemClick()">
        <div class="ssi-add-system" [inlineSVG]="'assets/images/plus.svg'"></div>
        <div class="ssi-name">{{ trans('systems.titles.addSystem') }}</div>
      </div>
    </div>
    <div *ngIf="us.currentUser.role === 0" id="sidemenu-system-select">
      <div class="sidemenu-system-item" (click)="addSystemClick()">
        <div class="ssi-add-system" [inlineSVG]="'assets/images/plus.svg'"></div>
        <div class="ssi-name">{{ trans('systems.titles.addSystem') }}</div>
      </div>
    </div>
    <div *ngIf="systems.hasSystems() && systems.activeSystem !== null" class="sidemenu-buttons-container">
      <app-button-single-line-arrow class="sidemenu-button-item" *ngIf="systems.activeSystemHelper.can.see.events()" [icon]="'assets/images/events.svg'" (click)="eventsClick()">{{
        trans('events.titles.eventList')
      }}</app-button-single-line-arrow>
      <app-button-single-line-arrow class="sidemenu-button-item" *ngIf="systems.activeSystemHelper.can.see.users()" [icon]="'assets/images/users.svg'" (click)="usersClick()">{{
        trans('systems.titles.users')
      }}</app-button-single-line-arrow>
      <app-button-single-line-arrow
        class="sidemenu-button-item"
        *ngIf="systems.activeSystemHelper.can.see.outputs() && systems.activeSystemHelper.hasEnabledOutputs()"
        [icon]="'assets/images/control.svg'"
        (click)="controlClick()"
        >{{ trans('systems.menu.outputs') }}</app-button-single-line-arrow
      >
      <app-button-single-line-arrow
        class="sidemenu-button-item"
        *ngIf="systems.activeSystemHelper.can.see.sensors()"
        [icon]="'assets/images/sensors.svg'"
        (click)="sensorsClick()"
        >{{ trans('systems.titles.sensors') }}</app-button-single-line-arrow
      >
      <app-button-single-line-arrow class="sidemenu-button-item" [icon]="'assets/images/settings.svg'" (click)="systemSettingsClick()">{{
        trans('systems.menu.settings')
      }}</app-button-single-line-arrow>
      <app-button-single-line-arrow
        class="sidemenu-button-item"
        *ngIf="systems.activeSystemHelper.can.see.cameras()"
        [icon]="'assets/images/camera2.svg'"
        (click)="systemCamerasClick()"
        >{{ trans('systems.menu.myCameras') }}</app-button-single-line-arrow
      >
    </div>
    <div *ngIf="us.currentUser.role === 3 && systems.activeSystem === null && systems.systemBookmarks.length > 0">
      <div id="sm_quick_systems_h">{{ trans('systems.labels.quickAccess') }}</div>
      <div id="sm_quick_systems">
        <app-cp-system-list-item
          *ngFor="let iBookmark of systems.systemBookmarks; let i = index"
          [systemItem]="systems.getSystem(iBookmark)"
          [showBorder]="systems.systemBookmarks.length - 1 !== i"
          [showBookmark]="false"
        ></app-cp-system-list-item>
      </div>
    </div>
    <div *ngIf="us.currentUser.role === 0 && regionsEnabled">
      <div id="smrg_header">{{ trans('settings.labels.regions') }}</div>
      <div id="smrg_container">
        <div
          class="smrg_item {{ iRegion.api_host_to_use === currentRegion ? 'smrg_item_active' : '' }}"
          *ngFor="let iRegion of us.currentUser.regions"
          (click)="changeRegion(iRegion)"
        >
          <div class="smrg_name">{{ iRegion.name }}</div>
        </div>
      </div>
    </div>
  </div>
  <div id="sidemenu-content-row" class="loggedout-row" *ngIf="!isLoggedIn()">
    <span class="accountsw-Title">{{ trans('users.labels.changeAccount') }}</span>
    <app-account-switcher *ngIf="hasAccounts" (selected)="hide()"></app-account-switcher>
  </div>
  <div id="sidemenu-login-row" *ngIf="!isLoggedIn()">
    <div class="button" (click)="loginClick()">{{ trans('auth.buttons.logIn') }}</div>
  </div>
  <div id="sidemenu-footer-row">
    <div id="sidemenu-footer-container">
      <div id="sidemenu-footer-separator" *ngIf="showHelp() || showVersion() || [1, 2].includes(us.currentUser.role)"></div>
      <div class="sidemenu-help" *ngIf="showHelp()" (click)="helpClick()">
        <div class="help-icon" [inlineSVG]="'assets/images/help.svg'"></div>
        <div class="help-text">{{ trans('general.help') }}</div>
      </div>
      <div class="sidemenu-app-settings" *ngIf="(pl.isAndroid() || pl.isApple()) && isLoggedIn() && [1, 2].includes(us.currentUser.role)" (click)="settingsClick()">
        <div class="settings-icon" [inlineSVG]="'assets/images/phone_settings.svg'"></div>
        <div class="settings-text">{{ trans('general.buttons.appSettings') }}</div>
      </div>
      <div class="sidemenu-version" *ngIf="showVersion()" (click)="openVersion($event)">
        <div class="version-text">v{{ g.getAppVersion() }}</div>
        <div class="version-new" *ngIf="newVersionAvailable"><app-dot></app-dot></div>
      </div>
    </div>
  </div>
  <div id="sidemenu-faded-part" [style.opacity]="(menuPosition + 50) / 50" (click)="hide()"></div>
</div>
<app-popup></app-popup>
