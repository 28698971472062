import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { TNotificationSetting } from 'src/app/models/notification-setting';
import { TSwipeCoordinate } from 'src/app/models/swipe-coordinate';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-system-notifications-item',
  templateUrl: './system-notifications-item.component.html',
  styleUrls: ['./system-notifications-item.component.scss'],
})
export class SystemNotificationsItemComponent extends LanguageAware implements OnInit, OnDestroy, AfterViewInit {
  @Input() notificationItem: TNotificationSetting = null;
  @Input() isEnabled = true;
  @ViewChild('dotBar') dotBar;
  @ViewChild('reactionName') reactionNameElement: ElementRef;
  private dragStartingPosition = null;
  private touchMoveListener = null;
  private touchEndListener = null;
  private dotBarLength = 0;
  private dotBar25Length = 0;
  private dotBar50Length = 0;
  private notificationItemBefore: string = null;
  public isExpanded = false;
  public canBeExpanded = false;

  constructor(cdRef: ChangeDetectorRef, g: GlobalService) {
    super(cdRef, true);
    const that = this;
    this.touchMoveListener = g.onSwipeContinue.subscribe((coords: TSwipeCoordinate) => {
      that.draggingInProgress(coords);
    });
    this.touchEndListener = g.onSwipeEnded.subscribe((coords: TSwipeCoordinate) => {
      that.endDragging(coords);
    });
  }

  ngOnInit(): void {}

  ngOnDestroy() {
    if (this.touchEndListener !== null) {
      this.touchEndListener.unsubscribe();
    }
    if (this.touchMoveListener !== null) {
      this.touchMoveListener.unsubscribe();
    }
  }

  ngAfterViewInit() {
    if (this.reactionNameElement === undefined || this.reactionNameElement === null) {
      return;
    }
    setTimeout(() => {
      this.canBeExpanded = this.reactionNameElement.nativeElement.offsetHeight < this.reactionNameElement.nativeElement.scrollHeight;
    }, 20);
  }

  public disableClick(save: boolean = true) {
    if (!this.isEnabled) {
      return;
    }
    this.notificationItem.enabled = false;
    if (save) {
      this.performSave();
    }
  }

  public enableClick(save: boolean = true) {
    if (!this.isEnabled) {
      return;
    }
    this.notificationItem.enabled = true;
    this.notificationItem.useAlertSound = false;
    if (save) {
      this.performSave();
    }
  }

  public alarmClick(save: boolean = true) {
    if (!this.isEnabled) {
      return;
    }
    this.notificationItem.enabled = true;
    this.notificationItem.useAlertSound = true;
    if (save) {
      this.performSave();
    }
  }

  private performSave() {
    if (!this.isEnabled) {
      return;
    }
    const that = this;
    const systemId = this.systems.activeSystem.id;
    const before = this.notificationItemBefore;
    this.api
      .post(
        '/system/notification/toggle',
        {
          systemId,
          id: this.notificationItem.id,
          sound: this.notificationItem.useAlertSound,
          state: this.notificationItem.enabled,
        },
        true
      )
      .subscribe(
        (result) => {
          that.log('', result);
          if (!result.success) {
            that.toaster.postError(result.error);
            that.revertSettings(systemId, before);
          } else {
            that.systems.setCurrentSystem(that.systems.activeSystem);
          }
        },
        (error) => {
          that.toaster.postError(this.trans('auth.errors.serverSideError'));
          that.revertSettings(systemId, before);
        }
      );
  }

  private revertSettings(systemId: number, notificationBefore: string) {
    const system = this.systems.getSystem(systemId);
    if (system === undefined) {
      return;
    }
    const before: TNotificationSetting = JSON.parse(notificationBefore);
    const notif = system.notifications.find((n) => n.id === before.id);
    if (notif === undefined) {
      return;
    }
    notif.enabled = before.enabled;
    notif.useAlertSound = before.useAlertSound;
  }

  public startDragging(theEvent: any, isTouch: boolean = false) {
    if (!this.isEnabled) {
      return;
    }
    this.headerBar.disableSideMenu();
    this.dragStartingPosition = isTouch ? theEvent.touches[0].clientX : theEvent.clientX;
    this.dotBarLength = this.dotBar.nativeElement.offsetWidth;
    this.dotBar25Length = this.dotBarLength * 0.25;
    this.dotBar50Length = this.dotBarLength * 0.5;
    this.notificationItemBefore = JSON.stringify(this.notificationItem);
  }

  private endDragging(coords: TSwipeCoordinate) {
    if (!this.isEnabled) {
      return;
    }
    this.headerBar.enableSideMenu();
    if (this.dragStartingPosition === null) {
      return;
    }
    this.dragStartingPosition = null;
    const before: TNotificationSetting = JSON.parse(this.notificationItemBefore);
    if (before.enabled !== this.notificationItem.enabled || before.useAlertSound !== this.notificationItem.useAlertSound) {
      this.performSave();
    }
  }

  private draggingInProgress(coords: TSwipeCoordinate) {
    if (this.dragStartingPosition === null) {
      return;
    }
    const diff = this.dragStartingPosition - coords.x;
    if (!this.notificationItem.enabled && diff < 0 && Math.abs(diff) > this.dotBar25Length) {
      this.notificationItem.enabled = true;
      this.notificationItem.useAlertSound = false;
      this.dragStartingPosition += this.dotBar50Length;
    } else if (this.notificationItem.enabled && !this.notificationItem.useAlertSound && diff < 0 && Math.abs(diff) > this.dotBar25Length) {
      this.notificationItem.useAlertSound = true;
      this.dragStartingPosition += this.dotBar50Length;
    } else if (this.notificationItem.enabled && this.notificationItem.useAlertSound && diff > this.dotBar25Length) {
      this.notificationItem.useAlertSound = false;
      this.dragStartingPosition -= this.dotBar50Length;
    } else if (this.notificationItem.enabled && diff > this.dotBar25Length) {
      this.notificationItem.enabled = false;
      this.dragStartingPosition -= this.dotBar50Length;
    }
  }

  public toggleExpanded() {
    if (this.reactionNameElement === undefined || this.reactionNameElement === null) {
      return;
    }
    if (!this.isExpanded && this.reactionNameElement.nativeElement.offsetHeight < this.reactionNameElement.nativeElement.scrollHeight) {
      this.isExpanded = true;
    } else {
      this.isExpanded = false;
    }
  }
}
