<div id="hcf-container">
    <div class="hcf-placeholder" *ngIf="draggingType !== ''" [style.order]="getTypePosition(draggingType)"></div>
    <div id='hcf-events' *ngIf='!systems.activeSystemHelper.supports.events()' [style.order]="getTypePosition('events')"></div>
    <div id='hcf-events' *ngIf='systems.activeSystemHelper.supports.events()' [ngClass]="{ 'hcf-element': true, 'hcf-dragging': draggingType === 'events' }" [style.order]="getTypePosition('events')">
        <div class='hcf-check'><app-checkbox [isRound]='false' [isSecondaryColor]='true' [isBig]='true' [checkValue]="isTypeChecked('events')" (valueChanged)="typeChecked('events', $event)"></app-checkbox></div>
        <div class='hcf-text'><span>{{trans('systems.titles.events')}}</span></div>
        <div class='hcf-icon'>
            <div [inlineSVG]="'assets/images/up_down.svg'"></div>
        </div>
    </div>
    <div id='hcf-areas' *ngIf='!systems.activeSystemHelper.supports.areas()' [style.order]="getTypePosition('areas')"></div>
    <div id='hcf-areas' *ngIf='systems.activeSystemHelper.supports.areas()' [ngClass]="{ 'hcf-element': true, 'hcf-expanded': expandedType === 'areas', 'hcf-dragging': draggingType === 'areas'}"  [style.order]="getTypePosition('areas')">
        <div class='hcf-check'><app-checkbox [isRound]='false' [isSecondaryColor]='true' [isBig]='true' [checkValue]="isTypeChecked('areas')" (valueChanged)="typeChecked('areas', $event)"></app-checkbox></div>
        <div class='hcf-text' (click)="expandType('areas')">
            <span>{{trans('systems.menu.areas')}} {{areaCount()}}</span>
            <span *ngIf="expandedType !== 'areas'" [inlineSVG]="'assets/images/forward.svg'"></span>
            <span *ngIf="expandedType === 'areas'" [inlineSVG]="'assets/images/arrow-down.svg'"></span>
        </div>
        <div class='hcf-icon' *ngIf="expandedType !== 'areas'">
            <div [inlineSVG]="'assets/images/up_down.svg'"></div>
        </div>
        <div class="hcf-expanded-area" *ngIf="expandedType === 'areas'">
            <div class="hcf-expanded-area-info">{{trans('systems.labels.homeConfigAreaInfo')}}</div>
            <div class="hcf-expanded-area-item" *ngFor="let iArea of allowedAreas">
                <app-checkbox class="hcf-expanded-area-item-check" [isSecondaryColor]='true' [checkValue]='iArea.visible' (valueChanged)='checkArea(iArea.queue_no, $event)'></app-checkbox>
                <div class="hcf-expanded-area-item-icon" (click)="checkArea(iArea.queue_no)" [inlineSVG]="'assets/images/area-general-icon.svg'"></div>
                <div class="hcf-expanded-area-item-name" (click)="checkArea(iArea.queue_no)">{{iArea.name}}</div>
            </div>
        </div>
    </div>
    <div id='hcf-outputs' *ngIf='!systems.activeSystemHelper.supports.outputs()' [style.order]="getTypePosition('outputs')"></div>
    <div id='hcf-outputs' *ngIf='systems.activeSystemHelper.supports.outputs()' [ngClass]="{ 'hcf-element': true, 'hcf-expanded': expandedType === 'outputs', 'hcf-dragging': draggingType === 'outputs'}"  [style.order]="getTypePosition('outputs')">
        <div class='hcf-check'><app-checkbox [isRound]='false' [isSecondaryColor]='true' [isBig]='true' [checkValue]="isTypeChecked('outputs')" (valueChanged)="typeChecked('outputs', $event)"></app-checkbox></div>
        <div class='hcf-text' (click)="expandType('outputs')">
            <span>{{trans('systems.titles.pgmOutputs')}} {{pgmCount()}}</span>
            <span *ngIf="expandedType !== 'outputs'" [inlineSVG]="'assets/images/forward.svg'"></span>
            <span *ngIf="expandedType === 'outputs'" [inlineSVG]="'assets/images/arrow-down.svg'"></span>
        </div>
        <div class='hcf-icon' *ngIf="expandedType !== 'outputs'">
            <div *ngIf="expandedType !== 'outputs'" [inlineSVG]="'assets/images/up_down.svg'"></div>
        </div>
        <div class="hcf-expanded-area" *ngIf="expandedType === 'outputs'">
            <div class="hcf-expanded-area-info">{{trans('systems.labels.homeConfigPgmInfo')}}</div>
            <div class="hcf-expanded-area-item" *ngFor="let iPgm of allowedOutputs">
                <app-checkbox class="hcf-expanded-area-item-check" [isSecondaryColor]='true' [checkValue]='iPgm.visible' (valueChanged)='checkPgm(iPgm.queue_no, $event)'></app-checkbox>
                <div class="hcf-expanded-area-item-icon" (click)="checkPgm(iPgm.queue_no)" [inlineSVG]="iPgm.iconPath"></div>
                <div class="hcf-expanded-area-item-name" (click)="checkPgm(iPgm.queue_no)">{{iPgm.name}}</div>
            </div>
        </div>
    </div>
    <div id='hcf-sensors' *ngIf='!systems.activeSystemHelper.supports.sensors()' [style.order]="getTypePosition('sensors')"></div>
    <div id='hcf-sensors' *ngIf='systems.activeSystemHelper.supports.sensors()' [ngClass]="{ 'hcf-element': true, 'hcf-expanded': expandedType === 'sensors', 'hcf-dragging': draggingType === 'sensors'}"  [style.order]="getTypePosition('sensors')">
        <div class='hcf-check'><app-checkbox [isRound]='false' [isSecondaryColor]='true' [isBig]='true' [checkValue]="isTypeChecked('sensors')" (valueChanged)="typeChecked('sensors', $event)"></app-checkbox></div>
        <div class='hcf-text' (click)="expandType('sensors')">
            <span>{{trans('systems.titles.sensors')}} {{sensorCount()}}</span>
            <span *ngIf="expandedType !== 'sensors'" [inlineSVG]="'assets/images/forward.svg'"></span>
            <span *ngIf="expandedType === 'sensors'" [inlineSVG]="'assets/images/arrow-down.svg'"></span>
        </div>
        <div class='hcf-icon' *ngIf="expandedType !== 'sensors'">
            <div *ngIf="expandedType !== 'sensors'" [inlineSVG]="'assets/images/up_down.svg'"></div>
        </div>
        <div class="hcf-expanded-area" *ngIf="expandedType === 'sensors'">
            <div class="hcf-expanded-area-info">{{trans('systems.labels.homeConfigSensorInfo')}}</div>
            <div class="hcf-expanded-area-item" *ngFor="let iSensor of allowedSensors">
                <app-checkbox class="hcf-expanded-area-item-check" [isSecondaryColor]='true' [checkValue]='iSensor.visible' (valueChanged)='checkSensor(iSensor.queue_no, $event)'></app-checkbox>
                <div class="hcf-expanded-area-item-icon" (click)="checkSensor(iSensor.queue_no)" [inlineSVG]="iSensor.iconPath"></div>
                <div class="hcf-expanded-area-item-name" (click)="checkSensor(iSensor.queue_no)">{{iSensor.name}}</div>
            </div>
        </div>
    </div>
    
    <div id='hcf-cameras' *ngIf='!systems.activeSystemHelper.can.see.cameras()' [style.order]="getTypePosition('cameras')"></div>
    <div id='hcf-cameras' *ngIf='systems.activeSystemHelper.can.see.cameras()' [ngClass]="{ 'hcf-element': true, 'hcf-expanded': expandedType === 'cameras', 'hcf-dragging': draggingType === 'cameras'}"  [style.order]="getTypePosition('cameras')">
        <div class='hcf-check'><app-checkbox [isRound]='false' [isSecondaryColor]='true' [isBig]='true' [checkValue]="isTypeChecked('cameras')" (valueChanged)="typeChecked('cameras', $event)"></app-checkbox></div>
        <div class='hcf-text' (click)="expandType('cameras')">
            <span>{{trans('systems.titles.systemCameras')}} {{cameraCount()}}</span>
            <span *ngIf="expandedType !== 'cameras'" [inlineSVG]="'assets/images/forward.svg'"></span>
            <span *ngIf="expandedType === 'cameras'" [inlineSVG]="'assets/images/arrow-down.svg'"></span>
        </div>
        <div class='hcf-icon' *ngIf="expandedType !== 'cameras'">
            <div *ngIf="expandedType !== 'cameras'" [inlineSVG]="'assets/images/up_down.svg'"></div>
        </div>
        <div class="hcf-expanded-area" *ngIf="expandedType === 'cameras'">
            <div class="hcf-expanded-area-info">{{trans('systems.labels.homeConfigCameraInfo')}}</div>
            <div class="hcf-expanded-area-item" *ngFor="let iCamera of allowedCameras">
                <app-checkbox class="hcf-expanded-area-item-check" [isSecondaryColor]='true' [checkValue]='iCamera.visible' (valueChanged)='checkCamera(iCamera.id, $event)'></app-checkbox>
                <div class="hcf-expanded-area-item-icon" (click)="checkCamera(iCamera.id)" [inlineSVG]="iCamera.iconPath"></div>
                <div class="hcf-expanded-area-item-name" (click)="checkCamera(iCamera.id)">{{iCamera.name}}</div>
            </div>
        </div>
    </div>

    <div id='hcf-thermostats' *ngIf='!systems.activeSystemHelper.supports.thermostats()' [style.order]="getTypePosition('thermostats')"></div>
    <div id='hcf-thermostats' *ngIf='systems.activeSystemHelper.supports.thermostats()' [ngClass]="{ 'hcf-element': true, 'hcf-expanded': expandedType === 'thermostats', 'hcf-dragging': draggingType === 'thermostats'}"  [style.order]="getTypePosition('thermostats')">
        <div class='hcf-check'><app-checkbox [isRound]='false' [isSecondaryColor]='true' [isBig]='true' [checkValue]="isTypeChecked('thermostats')" (valueChanged)="typeChecked('thermostats', $event)"></app-checkbox></div>
        <div class='hcf-text' (click)="expandType('thermostats')">
            <span>{{trans('systems.titles.thermostats')}} {{thermostatCount()}}</span>
            <span *ngIf="expandedType !== 'thermostats'" [inlineSVG]="'assets/images/forward.svg'"></span>
            <span *ngIf="expandedType === 'thermostats'" [inlineSVG]="'assets/images/arrow-down.svg'"></span>
        </div>
        <div class='hcf-icon' *ngIf="expandedType !== 'thermostats'">
            <div *ngIf="expandedType !== 'thermostats'" [inlineSVG]="'assets/images/up_down.svg'"></div>
        </div>
        <div class="hcf-expanded-area" *ngIf="expandedType === 'thermostats'">
            <div class="hcf-expanded-area-info">{{trans('systems.labels.homeConfigThermostatInfo')}}</div>
            <div class="hcf-expanded-area-item" *ngFor="let iThermostat of allowedThermostats">
                <app-checkbox class="hcf-expanded-area-item-check" [isSecondaryColor]='true' [checkValue]='iThermostat.visible' (valueChanged)='checkThermostat(iThermostat.id, $event)'></app-checkbox>
                <div class="hcf-expanded-area-item-icon" (click)="checkThermostat(iThermostat.id)" [inlineSVG]="iThermostat.iconPath"></div>
                <div class="hcf-expanded-area-item-name" (click)="checkThermostat(iThermostat.id)">{{iThermostat.name}}</div>
            </div>
        </div>
    </div>
</div>
<div class="separator32"></div>