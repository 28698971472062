import { Injectable, Injector, OnDestroy } from '@angular/core';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SystemEventData } from 'src/api/v3/common';
import { DataTableGetter } from 'src/app/company/components/data-table/data-table.component';
import { TEventData } from 'src/app/models/event-data';
import { AuthService } from '../auth.service';
import { RequestService } from '../request.service';
import { RtcService } from '../rtc/rtc.service';

export type EventFilterCriteria = {
  systemId?: number;
  reactionId?: number;
  startDate?: string;
  endDate?: string;
};

export type ProcessesdEventFilterCriteria = Omit<EventFilterCriteria, 'startDate' | 'endDate'> & {
  startDate?: DateTime;
  endDate?: DateTime;
};

const ProccessCriteria = (criteria: EventFilterCriteria) => ({
  ...criteria,
  startDate: criteria.startDate ? DateTime.fromISO(criteria.startDate) : undefined,
  endDate: criteria.endDate ? DateTime.fromISO(criteria.endDate) : undefined,
});

const DoesEventMatchCriteria = (event: TEventData, criteria: ProcessesdEventFilterCriteria) => {
  if (criteria.systemId && criteria.systemId !== -1 && event.systemId !== criteria.systemId) return false;
  if (criteria.reactionId && criteria.reactionId !== -1 && event.reaction !== criteria.reactionId) return false;
  const eventTime = DateTime.fromSeconds(event.time);
  if (criteria.startDate && eventTime < criteria.startDate) return false;
  if (criteria.endDate && eventTime > criteria.endDate) return false;
  return true;
};

@Injectable({
  providedIn: 'root',
})
export class EventService implements OnDestroy {
  public readonly events = new Map<number, TEventData>();
  public readonly systemEvents = new Map<number, Set<number>>();

  private _onEventsChanged = new Subject<void>();
  public readonly onEventsChanged = this._onEventsChanged.pipe(filter(() => !this.suspendEventChange));

  private _onNewEvent = new Subject<TEventData>();

  public getOnNewEvent(criteria?: EventFilterCriteria) {
    if (criteria) {
      const processedCriteria = ProccessCriteria(criteria);
      return this._onNewEvent.pipe(filter((event) => DoesEventMatchCriteria(event, processedCriteria)));
    }
    return this._onNewEvent.asObservable();
  }

  private suspendEventChange = false;

  private cleanupSubscribtion = this.auth.onAccountOrRegionChnage.subscribe(() => {
    this.events.clear();
    this.systemEvents.clear();
    this._onEventsChanged.next();
  });

  private rtcSubscribtion = this.rtc.events.subscribe(async (event) => {
    const newEvent = await this.req.event.getEvent({ event_id: event.event_id }).toPromise();
    if (newEvent.success) {
      this.ingestEvent(newEvent.event, event.system_id);
    } else {
      console.error('Failed to get new event', newEvent);
    }
  });
  constructor(private injector: Injector, private req: RequestService, private auth: AuthService, private rtc: RtcService) {}

  ngOnDestroy(): void {
    this.cleanupSubscribtion.unsubscribe();
    this.rtcSubscribtion.unsubscribe();
  }

  public ingestEvent(event?: SystemEventData | null, system_id?: number): TEventData | undefined {
    if (!event) return;

    const { id, subTitle: areaText, area: areaId, area_event: isAreaEvent, ...rest } = event;

    const processedEvent: TEventData = {
      id,
      systemId: system_id,
      areaText,
      areaId,
      isAreaEvent,
      ...rest,
    };
    const isNew = !this.events.has(id);
    this.events.set(id, processedEvent);
    if (!this.systemEvents.has(system_id)) {
      this.systemEvents.set(system_id, new Set());
    }
    this.systemEvents.get(system_id)?.add(id);
    this._onEventsChanged.next();
    if (isNew) this._onNewEvent.next(processedEvent);
    return processedEvent;
  }

  public async getEvents(offset?: number, criteria?: EventFilterCriteria) {
    const reactions = criteria?.reactionId && criteria?.reactionId !== -1 ? [criteria.reactionId] : undefined;
    return await this.req.event
      .getAllEvents({ offsetCount: offset, date_from: criteria?.startDate, date_to: criteria?.endDate, system: criteria?.systemId, reactions })
      .pipe(
        map((result) => {
          if (result.success) {
            this.suspendEventChange = true;
            result.events.map(({ system_id, ...event }) => this.ingestEvent(event, system_id));
            this.suspendEventChange = false;
            this._onEventsChanged.next();
          }
          if (criteria) return this.filterEventsByCriteria(criteria);
          return this.allEventsSortedByTime;
        })
      )
      .toPromise();
  }

  public getEventsGetter(criteria?: EventFilterCriteria): DataTableGetter<TEventData> {
    if (criteria) {
      let loadedByCriteria = 0;
      return async (current, columns, more) => {
        if (!more && current < loadedByCriteria) {
          return this.filterEventsByCriteria(criteria);
        }
        const loadedEvents = await this.getEvents(loadedByCriteria, criteria);
        loadedByCriteria = loadedEvents.length;
        return loadedEvents;
      };
    }
    let loaded = 0;
    return async (current, columns, more) => {
      if (!more && current < loaded) {
        return this.allEventsSortedByTime;
      }
      const loadedEvents = await this.getEvents(loaded);
      loaded = loadedEvents.length;
      return loadedEvents;
    };
  }

  private get allEventsSortedByTime() {
    return [...this.events.values()].sort((a, b) => b.time - a.time);
  }

  private filterEventsByCriteria(criteria: EventFilterCriteria): TEventData[] {
    const processedCriteria = ProccessCriteria(criteria);
    return [...this.events.values()].filter((event) => DoesEventMatchCriteria(event, processedCriteria)).sort((a, b) => b.time - a.time);
  }
}
