import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { LanguageAware } from 'src/app/general/language-aware';
import { MessageboxComponent } from 'src/app/popups/messagebox/messagebox.component';

@Component({
  selector: 'app-transfer-device',
  templateUrl: './transfer-device.component.html',
  styleUrls: ['./transfer-device.component.scss'],
})
export class TransferDeviceComponent extends LanguageAware implements OnInit {
  @ViewChild('confirmBox') confirmBox: MessageboxComponent;
  public dataReceived = false;
  public message = '';
  public confirmationMessage = '';
  public onlineResult = null;
  private deviceTransferInProgress = false;
  private deviceTransferTimeout = null;

  constructor(cdRef: ChangeDetectorRef) {
    super(cdRef);
    this.headerBar.showHeader({
      headerText: this.trans('systems.titles.transferDevice'),
      backUrl: '/transfer-type',
    });
    this.background.setGray();
    this.footerBar.showFooter(this.trans('systems.buttons.transfer'), '', true, false);
    const that = this;
    this.footerBar.onButton1Click = () => {
      that.reconfirmTransfer();
    };

    this.miniStatus.show(this.trans('general.pleaseWait'));
    this.findDevice();
  }

  ngOnInit(): void {}

  private findDevice() {
    const that = this;
    this.api.get(`/online?imei=${this.systems.activeSystem.imei}&checkAll=true`, true).subscribe((result) => {
      that.onlineResult = null;
      that.miniStatus.hide();
      if (result.success) {
        if (result.local_version === undefined || (!result.own_region && result.remote_version === undefined)) {
          that.message = that.trans('systems.labels.cannotTransferDevice');
        } else if (!result.online) {
          that.message = that.trans('systems.titles.deviceOffline');
        } else {
          that.onlineResult = result;
          if (result.own_region && result.remote_version === undefined) {
            that.onlineResult.remote_version = 1;
          }
          that.message = that.trans('systems.labels.deviceStatus') + ': ' + (result.own_region ? result.local_version : result.remote_version);
        }
        that.dataReceived = true;
      } else {
        that.toaster.postError(result.error);
      }
    });
  }

  private reconfirmTransfer() {
    if (this.onlineResult === null) {
      return;
    }
    if (!this.onlineResult.online) {
      return;
    }
    this.confirmationMessage = this.trans('systems.labels.deviceTransferConfirmation')
      .replace(':appversion', this.onlineResult.own_region ? this.onlineResult.remote_version : this.onlineResult.local_version)
      .replace(':oldversion', this.onlineResult.own_region ? this.onlineResult.local_version : this.onlineResult.remote_version);
    this.confirmBox.show();
  }

  public doTransfer() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('systems.statuses.transfering'));
    const that = this;
    this.api
      .post(
        '/transfer-device',
        {
          systemUid: this.systems.activeSystem.imei,
          mpass: this.systems.activeSystem.mpass,
          srv: this.onlineResult.srv,
          toVersion: this.onlineResult.own_region ? this.onlineResult.remote_version : this.onlineResult.local_version,
        },
        true
      )
      .subscribe((result) => {
        that.miniStatus.hide();
        if (!result.success) {
          that.toaster.postError(result.error);
        } else {
          that.toaster.postSuccess('OK');
        }
      });
  }
}
