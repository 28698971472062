<div id="snd_container">
    <div id="snd_wifi" class="{{showTroubleshoot ? 'snd_wifi_small' : 'snd_wifi_big'}}"><svg viewBox="0 0 583 412" xml:space="preserve">
        <path [class.wifi_arc3]="!platform.isApple()" d="M451.475,32.371c48.926,20.665 93.378,50.633 130.883,88.237l-41.596,41.596c-66.168,-66.168 -156.007,-103.381 -249.583,-103.381c-93.576,-0 -183.415,37.213 -249.583,103.381l-41.596,-41.591c118.315,-118.218 297.357,-153.21 451.475,-88.238l-0,-0.004Z" />
        <path [class.wifi_arc2]="!platform.isApple()" d="M83.196,203.804c55.14,-55.14 130.005,-86.151 207.985,-86.151c77.98,-0 152.846,31.011 207.986,86.151l-41.596,41.596c-91.278,-91.277 -241.497,-91.277 -332.775,0l-41.6,-41.596Z" />
        <path [class.wifi_arc1]="!platform.isApple()" d="M291.179,235.308c46.788,-0.029 91.708,18.608 124.733,51.75l-41.579,41.58c-22.027,-22.079 -51.966,-34.5 -83.154,-34.5c-31.188,-0 -61.126,12.421 -83.154,34.5l-41.579,-41.58c33.025,-33.142 77.945,-51.779 124.733,-51.75Z" />
        <path d="M261.767,382.371c-0,-16.135 13.277,-29.413 29.412,-29.413c16.135,0 29.413,13.278 29.413,29.413c-0,16.135 -13.278,29.412 -29.413,29.412c-16.133,0 -29.41,-13.274 -29.412,-29.408l-0,-0.004Z" />
        </svg>
    </div>
    <div id="snd_scan_nearby" *ngIf="!platform.isApple()">{{trans('systems.labels.scanningNearby') + ' ' + deviceNameText}}</div>
    <div *ngIf="deviceType === 'hub' && showTroubleshoot">
        <div id="snd_leds_text">{{trans('systems.labels.checkPairing')}}</div>
        <div id="snd_leds">
            <svg viewBox="0 0 3508 2481" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <path d="M704.414,703.206l76.971,618.063l757.07,619.213c-0,-0 6.36,4.551 12.384,3.798c6.023,-0.753 9.034,0.376 9.034,0.376l6.4,-9.034l28.61,-2.635l1.129,9.411l355.715,9.931l3.388,-5.647l28.233,-1.882l5.647,8.281c0,0 12.799,4.141 16.94,0.753c4.141,-3.388 781.169,-573.822 781.169,-573.822l101.852,-626.159c-0,0 4.607,-9.936 -9.424,-14.099c-14.031,-4.164 -1070.84,-223.458 -1070.84,-223.458c-0,-0 -13.306,-3.22 -28.558,-1.274c-18.156,2.317 -1056.44,154.762 -1056.44,154.762c-16.092,8.12 -23.264,19.028 -19.28,33.422Z" style="fill:#939598;stroke:#231f20;stroke-width:1px;"/>
                <path d="M2003.38,1954.93l32.533,-610.58c11.799,-147.143 79.861,-255.034 197.045,-328.653l646.617,-257.22c4.768,-1.02 7.686,-5.369 9.164,-12.31l-101.638,633.847l-783.721,574.916Zm-407.371,-12.53l1.17,-640.816c1.356,-148.891 -65.143,-257.974 -197.633,-328.342l-682.932,-288.896c-0.045,-4.191 2.406,-6.927 6.853,-8.498l1053.79,-153.481c9.272,-1.668 21.054,-2.851 27.93,-0.027l1057.6,218.764c6.605,3.198 6.221,6.396 1.714,9.594l-677.425,245.283c-121.913,57.15 -191.466,163.951 -209.871,319.254l-22.094,631.449l-3.388,5.647l-355.715,-9.931Zm-268.035,-971.611c127.469,82.097 198.194,184.354 206.456,308.801l10.46,664.024c-3.073,-0.706 -5.834,-2.506 -8.459,-4.783l-755.049,-617.56l-76.971,-618.063c-1.953,-5.607 -1.931,-11.073 0.721,-16.351c-0.73,3.909 0.838,6.477 4.055,8.083l618.787,275.849Z" style="fill:#e4e4e4;stroke:#231f20;stroke-width:1px;"/>
                <path d="M1818.76,1336.32l-55.446,-1.315l-1.315,55.446l55.446,1.315l1.315,-55.446Z" style="fill:#fff;"/>
                <path id="snd_flashing_led" d="M1816.7,1422.81l-55.446,-1.315l-1.315,55.447l55.446,1.315l1.315,-55.447Z"/>
                <path d="M1814.65,1509.3l-55.446,-1.315l-1.315,55.446l55.446,1.315l1.315,-55.446Z" style="fill:#fff;"/>
                <path d="M1812.6,1595.79l-55.447,-1.315l-1.314,55.446l55.446,1.315l1.315,-55.446Z" style="fill:#fff;"/></svg>
        </div>
        <div id="snd_pairing_text">{{trans('systems.labels.turnOnPairing')}}</div>
        <div id="snd_pairing" [inlineSVG]="'assets/images/flexi_pairing.svg'"></div>
    </div>

    <ng-template #devices>
        <div *ngIf="deviceType === 'wp17' && showTroubleshoot">
            <div id="snd_leds_text">{{trans('systems.labels.checkPairing')}}</div>
            <div id="snd_leds">
                <ng-lottie [options]="wifiAnimationOptions"></ng-lottie>
            </div>
            <div *ngIf="platform.isApple()" id="apple-info-text">{{trans('systems.labels.wifiAppleScanText').replace(':network', AppSettings.wifiGateDevice37Name)}}</div>
        </div>
        <div *ngIf="deviceType === 'sp3' && showTroubleshoot">
            <div id="snd_leds_text">{{trans('systems.labels.checkPairing')}}</div>
            <div id="snd_leds">
                <ng-lottie [options]="wifiAnimationOptions"></ng-lottie>
            </div>
            <div *ngIf="platform.isApple()" id="apple-info-text">{{trans('systems.labels.wifiAppleScanText').replace(':network', 'SP3')}}</div>
        </div>
    </ng-template>
    <ng-container *ngIf="platform.isApple()">
        <ng-container *ngTemplateOutlet="devices"></ng-container>
        <div class="button" id="apple-wifi-button" (click)="openAppleWifiSettings()">{{trans('systems.buttons.openWifi')}}</div>
        <div class="button" id="apple-next-button" (click)="performCommunicationWithDevice()">{{trans('systems.buttons.next')}}</div>
    </ng-container>
    <ng-container *ngIf="!platform.isApple()">
        <ng-container *ngTemplateOutlet="devices"></ng-container>
    </ng-container>
</div>