import { Environment } from './environment.types';
import { environment as beta } from './environment.beta';

export const environment: Environment = {
  ...beta,
  production: false,
  googleClientIdWeb: '345873215586-r9drhbdrmip2c7qkgifkh9v9uluocspg.apps.googleusercontent.com',
  localReport: false,
};

export const AppSettings = environment;
