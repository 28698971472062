import { enableProdMode } from '@angular/core';
import { async } from '@angular/core/testing';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { AppSettings } from './app-settings';
import * as Sentry from '@sentry/browser';

if (AppSettings.sentry) {
  Sentry.init({
    ...AppSettings.sentry,
    release: `${AppSettings.appVersionMajor}.${AppSettings.appVersionMinor}.${AppSettings.appVersionBuild}.${AppSettings.appVersionDate}`,
    autoSessionTracking: false,
  });
}

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then((ref) => {
    // Ensure Angular destroys itself on hot reloads.
    if (window.ngRef) {
      window.ngRef.destroy();
    }
    window.ngRef = ref;

    const elements = document.getElementsByTagName('TITLE');
    if (elements.length > 0) {
      elements[0].innerHTML = environment.title;
    }
  })
  .catch((err) => console.error(err));
