import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { MessageBoxButtons } from '../popups/messagebox/messagebox-buttons';
import { TSwipeCoordinate } from '../models/swipe-coordinate';
import { UserRole } from '../models/user-role';
import { UserService } from './user.service';
import { AppSettings } from 'src/app-settings';

@Injectable({
  providedIn: 'root',
})
export class GlobalService {
  public static MIN_THERMOSTAT_TEMPERATURE = -20; // reali modulio galimybe -55
  public static MAX_THERMOSTAT_TEMPERATURE = 50; // reali modulio galimybe +125
  private loaded = false;
  private redirectionURL = '';
  public onSwipeStartedSource = new Subject<TSwipeCoordinate>();
  public configurableDevice = null;
  public MessageBoxButtons = MessageBoxButtons;
  /**
   * Nurodo jog pradėtas tempimas pirštu.
   *
   * @param koordinatės TSwipeCoordinate
   */
  public onSwipeStarted = this.onSwipeStartedSource.asObservable();

  public onSwipeEndedSource = new Subject<TSwipeCoordinate>();
  /**
   * Nurodo jog baigtas tempimas pirštu. Pirštas pakeltas.
   *
   * @param koordinatės TSwipeCoordinate
   */
  public onSwipeEnded = this.onSwipeEndedSource.asObservable();

  public onSwipeContinueSource = new Subject<TSwipeCoordinate>();
  /**
   * Nurodo jog vis dar vykdomas tempimas pirštu.
   *
   * @param koordinatės TSwipeCoordinate
   */
  public onSwipeContinue = this.onSwipeContinueSource.asObservable();

  public onScrolledToBottomSource = new Subject<void>();
  public onScrolledToBottom = this.onScrolledToBottomSource.asObservable();

  constructor(private us: UserService) {}

  public isLoaded(): boolean {
    return this.loaded;
  }

  public setLoaded() {
    this.loaded = true;
  }

  public setRedirectionURL(url: string) {
    this.redirectionURL = url;
  }

  public hasRedirectionURL(): boolean {
    return this.redirectionURL.length > 0;
  }

  public getRedirectionURL(): string {
    return this.redirectionURL;
  }

  public getHomeUrl(): string {
    if (this.us.currentUser.role === UserRole.Company) {
      return '/company';
    } else if (this.us.currentUser.role === UserRole.Installer) {
      return '/installer';
    } else if (this.us.currentUser.role === UserRole.SuperAdmin) {
      return '/sadmin';
    }
    return '/home';
  }

  public getAppVersion(): string {
    return AppSettings.appVersionMajor.toString() + '.' + AppSettings.appVersionMinor.toString() + '(' + AppSettings.appVersionBuild.toString() + ')';
  }

  public isNewVersionAvailable(): boolean {
    if (this.us.appVersionDetails === null) {
      return false;
    }
    return this.us.appVersionDetails.build > AppSettings.appVersionBuild;
  }
}
