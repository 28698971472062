<div class="grid add-system-area-list-grid">
    <div id='asal-background' class="group-background"></div>
    <div id='asal-area-list'>
        <div class="list-item-v1" *ngFor='let area of getAreas()' (click)='onAreaSelect(area)'>
            <div [inlineSVG]="'assets/images/area-general-icon.svg'"></div>
            <div >{{area.queue_no}}</div>
            <div>
                <div>{{area.name}}</div>
                <div>{{getAssignedPgmName(area)}}&nbsp;</div>
            </div>
            <div [inlineSVG]="'assets/images/delete.svg'" (click)='onAreaDelete($event, area)'></div>
        </div>
    </div>
</div>
<div [ngClass]=" { 'asal-add-system-container': true, 'asal-add-system-container-middle': !helper.plusIsAtBottomNow(windowName), 'asal-add-system-container-bottom': helper.plusIsAtBottomNow(windowName) }">
    <div id='asal-add-system-button' [ngClass]="{ 'asal-add-system-button': true, 'asal-add-system-button-bottom': helper.plusIsAtBottomNow(windowName) }" (click)='onAddArea()'><div [inlineSVG]="'assets/images/plus.svg'"></div></div>
    <div *ngIf='!helper.plusIsAtBottomNow(windowName)' class='plus-button-subtext'>{{trans('systems.titles.clickPlusArea')}}</div>
</div>