import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import http from '../http';
import { BasicSuccessResponse, ErrorResponse, ExErrorResponse, SystemPgmData } from './common';

type EditPgmRequest = {
  pgm: Pick<SystemPgmData, 'queue_no' | 'name' | 'id' | 'type' | 'io_type'> & {
    pulseTime: number;
    icon: number;
  };
};

type EditPgmResponse = ErrorResponse | ExErrorResponse | BasicSuccessResponse;

export const editPgm = (req: EditPgmRequest) => http.post<EditPgmResponse, EditPgmRequest>('/v3/api/edit/pgm', req);

type TogglePgmRequest = { pgmId: number };
type TogglePgmResponse =
  | ErrorResponse
  | (BasicSuccessResponse & {
      pgm: any; // TODO: better type
    });

export const togglePgm = (req: TogglePgmRequest) => http.post<TogglePgmResponse, TogglePgmRequest>('/v3/api/toggle-pgm', req);

type GetUserPgmsResponseInternal = { success: false; isLoggedIn: false } | (BasicSuccessResponse & { pgms: string });

type GetUserPgmsResponse =
  | { success: false; isLoggedIn: false }
  | (BasicSuccessResponse & {
      pgms: {
        system_id: number;
        is_on: boolean;
        icon_number: number;
        pgm_id: number;
        system_name: string;
        item_name: string;
      }[];
    });

export const getUserPgms = (): Observable<GetUserPgmsResponse> =>
  http.get<GetUserPgmsResponseInternal>('/v3/api/get-pgm-list', undefined).pipe(map((res) => (res.success ? { ...res, pgms: JSON.parse(res.pgms) } : res)));

type GetCurrentPgmStatusRequest = { pgmId: number };
type GetCurrentPgmStatusResponse =
  | ErrorResponse
  | (ErrorResponse & { isLoggedIn: boolean })
  | (BasicSuccessResponse & {
      on: boolean;
      enabled: boolean;
      name: string;
      icon: number;
      pgm_id: number;
      system_id: number;
    });

/** Widget API */
export const getPgmStatus = (req: GetCurrentPgmStatusRequest) => http.post<GetCurrentPgmStatusResponse, GetCurrentPgmStatusRequest>('/v3/api/get-pgm-status', req);
