import { AppSettings } from 'src/app-settings';

export const replaceProtegusColorWithBrandColor = (lottieJson: any): string => {
  const brandColor = AppSettings.secondaryColor;
  const red = parseInt(brandColor.substring(1, 3), 16);
  const green = parseInt(brandColor.substring(3, 5), 16);
  const blue = parseInt(brandColor.substring(5, 7), 16);
  const fractionedRed = Math.round((red / 255) * 10000) / 10000;
  const fractionedGreen = Math.round((green / 255) * 10000) / 10000;
  const fractionedBlue = Math.round((blue / 255) * 10000) / 10000;

  // Protegus secondary color yra #EF476F, atitinka ihardcodinta string.
  return JSON.parse(JSON.stringify(lottieJson).replace(/"k":\[0\.9373,0\.2784,0\.4353,1\]/g, `"k":[${fractionedRed},${fractionedGreen},${fractionedBlue},1]`));
};
