import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LanguageAware } from 'src/app/general/language-aware';
import { TEditableComponent } from 'src/app/models/editable-component';
import { PopupType } from 'src/app/models/popup-type';
import { EditSystemService } from 'src/app/services/edit-system.service';
import { PopupService } from 'src/app/services/popup.service';

@Component({
  selector: 'app-su-region',
  templateUrl: './su-region.component.html',
  styleUrls: ['./su-region.component.scss'],
})
export class SuRegionComponent extends LanguageAware implements OnInit {
  public regionItem = null;

  constructor(cdRef: ChangeDetectorRef, private es: EditSystemService, private router: Router, private pp: PopupService) {
    super(cdRef);
    this.regionItem = this.es.getEditableComponent(TEditableComponent.Region);
    this.headerBar.showHeader({
      headerText: this.regionItem === null ? this.trans('settings.labels.addRegion') : this.trans('settings.labels.editRegion'),
      backUrl: this.g.getHomeUrl() + '/regions',
      actionButtonText: this.regionItem === null ? '' : this.trans('general.delete'),
    });
    const that = this;
    if (this.regionItem !== null) {
      this.headerBar.onActionButtonClicked = () => {
        that.delete();
      };
    }
    if (this.regionItem === null) {
      this.regionItem = {
        id: undefined,
        name: '',
        api_host: '',
        api_path: '',
        region_version: 1,
        api_host_to_use: '',
      };
    }
    this.footerBar.showFooter(this.trans('general.cancel'), this.trans('general.save'), true, false);
    this.headerBar.onBackClicked = () => {
      that.revert();
    };
    this.footerBar.onButton1Click = () => {
      if (that.miniStatus.isVisible()) {
        that.miniStatus.flash();
        return;
      }
      that.revert();
      that.router.navigate([this.g.getHomeUrl() + '/regions']);
    };
    this.footerBar.onButton2Click = () => {
      that.doSave();
    };
    this.background.setGray();
  }

  ngOnInit(): void {}

  private delete() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    this.api.delete('/region?id=' + this.regionItem.id, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          that.us.currentUser.regions = that.us.currentUser.regions.filter((r) => r.id !== that.regionItem.id);
          that.router.navigate([this.g.getHomeUrl() + '/regions']);
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
      }
    );
  }

  private revert() {
    if (this.regionItem.id === undefined) {
      return;
    }
    const original = this.es.getComponentBeforeModification(TEditableComponent.Region);
    this.regionItem.name = original.name;
    this.regionItem.api_host = original.api_host;
    this.regionItem.api_path = original.api_path;
    this.regionItem.region_version = original.region_version;
    this.regionItem.api_host_to_use = original.api_host_to_use;
  }

  private doSave() {
    if (this.miniStatus.isVisible()) {
      this.miniStatus.flash();
      return;
    }
    this.miniStatus.show(this.trans('general.pleaseWait'));
    const that = this;
    this.api.post('/region', this.regionItem, true).subscribe(
      (result) => {
        that.miniStatus.hide();
        if (result.success) {
          if (that.regionItem.id === undefined) {
            that.regionItem.id = result.id;
            if (that.us.currentUser.regions === undefined) {
              that.us.currentUser.regions = [];
            }
            that.us.currentUser.regions.push(that.regionItem);
          }
          that.router.navigate([this.g.getHomeUrl() + '/regions']);
        } else {
          that.toaster.postError(result.error);
        }
      },
      (error) => {
        that.miniStatus.hide();
        that.toaster.postError(that.trans('auth.errors.serverSideError'));
      }
    );
  }

  public changeVersion() {
    const that = this;
    this.pp.showSlideout(
      {
        headerText: this.trans('settings.labels.regionVersion'),
        items: ['1', '2'],
        onSubmit: (res) => {
          that.regionItem.region_version = res + 1;
        },
      },
      PopupType.Slideout
    );
  }
}
