import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { RequestService } from 'src/app/api/request.service';
import { symbolName } from 'typescript';
import { LoginService } from '../login.service';

@Injectable({
  providedIn: 'root',
})
export class PasswordResetGuard implements CanActivate {
  constructor(private login: LoginService, private router: Router) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const hash = route.paramMap.get('hash');
    if (hash) {
      this.login.passwordToken = hash;
      return this.router.parseUrl('/login/password-reset');
    }
    return this.router.parseUrl('/login/password-reset/email');
  }
}
